<section class="tile">
    <div class="tile-header">
        <h6>Sales Summary</h6>
    </div>
    <div class="tile-body">
        <div class="row ">
            <div class="col-6 col-lg-6 text-center">
                <small>
          THURSDAY
        </small>
                <h3>
                    <img src="/assets/images/icons/profit-icon.png" class="h15" alt="icon"> 120.6K
                </h3>
                <small class="text-profit">BEST SELLING</small>
            </div>
            <div class="col-6 col-lg-6 text-center">
                <small>
          SATURDAY
        </small>
                <h3>
                    <img src="/assets/images/icons/loss-icon.png" class="h15" alt="icon"> 120.6K
                </h3>
                <small class="text-loss">LOW SELLING</small>
            </div>
        </div>
        <!-- [responsive]="salesSummaryBarOptions.responsive" -->
        <apx-chart [series]="salesSummaryBarOptions.series" [chart]="salesSummaryBarOptions.chart" [colors]="salesSummaryBarOptions.colors" [plotOptions]="salesSummaryBarOptions.plotOptions" [dataLabels]="salesSummaryBarOptions.dataLabels" [xaxis]="salesSummaryBarOptions.xaxis"
            [yaxis]="salesSummaryBarOptions.yaxis" [grid]="salesSummaryBarOptions.grid" [fill]="salesSummaryBarOptions.fill" [legend]="salesSummaryBarOptions.legend"></apx-chart>
    </div>
</section>