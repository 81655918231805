<form [formGroup]="recipeForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Recipe' :SelectedName) | translate}} </h4>
    <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Inventory','Inventory Setup','Recipes', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Name' | translate }}</label>
              <input type="text" class="form-control" id=name placeholder="" formControlName="name">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is required' | translate }} .
                </div>
              </div>
            </div>
          </div>
          <div class="recipe-box form-group">
            <ng-select [items]="Ingredients" class="form-control recipe-box-autocomplete" placeholder="{{'Add Ingredients' | translate}}" bindLabel="name" (change)="addRow($event)">
            </ng-select>
            <div class="row p-2" *ngFor="let obj of arrRecipeIngerdients">
              <div class="col-6">
                <h6 for="name" class="black pt-3">{{obj.name}}</h6>
              </div>
              <div class="col-4">
                <div class="input-group">
                  <input type="number" [(ngModel)]="obj.quantity" [ngModelOptions]="{standalone: true}" class="form-control rounded-0" placeholder="" aria-label="" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-0" id="basic-addon2"><label for="unit" class="unit">{{obj.unit}}</label></span>
                  </div>
                </div>
              </div>
              <div class="col-2 text-center">
                <a><img class=" pt-3" src="/assets/images/delete-icon.png" (click)="deleteRow(obj)" /></a>
              </div>
            </div>
            <div class="row p-1" *ngIf="arrRecipeIngerdients.length==0">
              <div class="col-12 text-center">
                <h6 for="name" class="pt-3">{{'No Ingredient Added!' | translate }}</h6>
              </div>
            </div>
          </div>
          <div class="tiltle-bar form-group">
            <div class="row">
              <div class="col-8">
                <label for="Item Status">{{'Automatically Prepared' | translate }}</label>
              </div>
              <div class="col-4 text-right">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="autoPrepared" (change)="chkAutoPrepared()" formControlName="autoPrepared">
                  <label class="form-check-label" for="autoPrepared"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!isAutoPrepared">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="">{{'When it’s Pre-prepared, what’s the Life Period of the Prep?' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" aria-label="Text input with dropdown button" formControlName="life">
                <select class="ingredient-period" formControlName="lifePeriod">
                  <option selected value="1">{{'Minutes' | translate }}</option>
                  <option value="2">{{'Hours' | translate }}</option>
                  <option value="3">{{'Days' | translate }}</option>
                  <option value="4">{{'Month' | translate }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!isAutoPrepared">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="">{{'SKU' | translate }}</label>
              <div class="input-group mb-3 section-sku">
                <input type="text" class="form-control" placeholder="SKU-000" formControlName="sku" aria-label="" aria-describedby="basic-addon2" [readonly]="SelectedID > 0" (input)="SelectedID === 0 && manualSKUChangeByUser =true">
                <div class="input-group-append">
                  <span class="input-group-text button-generate" id="basic-addon2" [ngClass]="{'SKU-Disable': !enableSKU}" (click)="enableSKU && generateSKU()">{{'Generate' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-lg-6 text-left">
              <h6 class="font-weight-bold ">{{'Approx Cost' | translate }}</h6>
            </div>
            <div class="col-lg-6 text-right">
              <h6 class="font-weight-bold ">
                {{getCost() | number : '1.2-2'}} {{brandCurrency | translate }}
              </h6>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
