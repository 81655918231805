import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { ModifierService } from '../../../_services/modifier.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-modifier-uplifter',
  templateUrl: './modifier-uplifter.component.html',
  styleUrls: ['./modifier-uplifter.component.css']
})
export class ModifierUplifterComponent implements OnInit {
  SaveButtonText = "Continue";
  showFileUpload = true;
  showFileOptions = false;
  showError = false;
  loading = false;
  showTemplate = true;
  modifierUploadForm: UntypedFormGroup;
  FileOptionList: Array<any> = [
    { value: "Name", name: 'Name' },
    { value: "ArabicName", name: 'Arabic Name' },
    { value: "Price", name: 'Price' },
    { value: "Unit", name: 'Unit' },
    { value: "GroupModifier", name: 'Group Modifier' },
    { value: "ArabicGroupModifier", name: 'Arabic Group Modifier' },
    { value: "MinModifierItem", name: 'Min Modifier Items' },
    { value: "MaxModifierItem", name: 'Max Modifier Items' }
  ];
  errorList: any[];
  fileData: any;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modifierService: ModifierService) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.dismiss();
  }

  get f() { return this.modifierUploadForm.controls; }

  private createForm() {
    this.modifierUploadForm = this.formBuilder.group({
      name: [Validators.required],
      arabicName: [Validators.required],
      price: [Validators.required],
      unit: [Validators.required],
      groupName: [Validators.required],
      arabicGroupName: [Validators.required],
      min: [Validators.required],
      max: [Validators.required]
    });
  }
  ValidateSheet(sheet) {
    let sheetFormat = true;
    sheet.filter(function (item) {
      if (Object.keys(item).length < 8)
        sheetFormat = false;
      if (item[Object.keys(item)[0]] === null || item[Object.keys(item)[0]] === undefined || item[Object.keys(item)[0]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[1]] === null || item[Object.keys(item)[1]] === undefined || item[Object.keys(item)[1]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[2]] === null || item[Object.keys(item)[2]] === undefined || item[Object.keys(item)[2]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[3]] === null || item[Object.keys(item)[3]] === undefined || item[Object.keys(item)[3]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[4]] === null || item[Object.keys(item)[4]] === undefined || item[Object.keys(item)[4]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[5]] === null || item[Object.keys(item)[5]] === undefined || item[Object.keys(item)[5]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[6]] === null || item[Object.keys(item)[6]] === undefined || item[Object.keys(item)[6]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[7]] === null || item[Object.keys(item)[7]] === undefined || item[Object.keys(item)[7]] === '')
        sheetFormat = false;

    });
    return sheetFormat;
  }
  onSubmit() {
    if (this.SaveButtonText === "Continue") {
      if (this.fileData !== null && this.fileData !== undefined) {
        if (this.ValidateSheet(this.fileData)) {
          this.showFileUpload = false;
          this.showTemplate = false;
          this.showFileOptions = true;
          this.SaveButtonText = "Upload";
        }
        else
          this.alertService.error("Attached sheet is not valid.");
      }
      else {
        this.showFileUpload = true;
        this.showTemplate = true;
        this.showFileOptions = false;
        this.alertService.error("File not found or invalid file.");
      }
    }
    else {

      let name = this.f.name.value === this.f.arabicName.value || this.f.name.value === this.f.arabicGroupName.value || this.f.name.value === this.f.groupName.value || this.f.name.value === this.f.max.value || this.f.name.value === this.f.min.value || this.f.name.value === this.f.price.value || this.f.name.value === this.f.unit.value || this.f.name.value === this.f.price.value;
      let arabicName = this.f.arabicName.value === this.f.name.value || this.f.arabicName.value === this.f.arabicGroupName.value || this.f.arabicName.value === this.f.groupName.value || this.f.arabicName.value === this.f.max.value || this.f.arabicName.value === this.f.min.value || this.f.arabicName.value === this.f.price.value || this.f.arabicName.value === this.f.unit.value || this.f.arabicName.value === this.f.price.value;
      let arabicGroupName = this.f.arabicGroupName.value === this.f.arabicName.value || this.f.arabicGroupName.value === this.f.name.value || this.f.arabicGroupName.value === this.f.groupName.value || this.f.arabicGroupName.value === this.f.max.value || this.f.arabicGroupName.value === this.f.min.value || this.f.arabicGroupName.value === this.f.price.value || this.f.arabicGroupName.value === this.f.unit.value || this.f.arabicName.value === this.f.price.value;
      let groupName = this.f.groupName.value === this.f.arabicName.value || this.f.groupName.value === this.f.arabicGroupName.value || this.f.groupName.value === this.f.name.value || this.f.groupName.value === this.f.max.value || this.f.groupName.value === this.f.min.value || this.f.groupName.value === this.f.price.value || this.f.groupName.value === this.f.unit.value || this.f.arabicName.value === this.f.price.value;
      let max = this.f.max.value === this.f.arabicName.value || this.f.max.value === this.f.arabicGroupName.value || this.f.max.value === this.f.groupName.value || this.f.max.value === this.f.name.value || this.f.max.value === this.f.min.value || this.f.max.value === this.f.price.value || this.f.max.value === this.f.unit.value || this.f.arabicName.value === this.f.price.value;
      let min = this.f.min.value === this.f.arabicName.value || this.f.min.value === this.f.arabicGroupName.value || this.f.min.value === this.f.groupName.value || this.f.min.value === this.f.max.value || this.f.min.value === this.f.name.value || this.f.min.value === this.f.price.value || this.f.min.value === this.f.unit.value || this.f.arabicName.value === this.f.price.value;
      let price = this.f.price.value === this.f.arabicName.value || this.f.price.value === this.f.arabicGroupName.value || this.f.price.value === this.f.groupName.value || this.f.price.value === this.f.max.value || this.f.price.value === this.f.min.value || this.f.price.value === this.f.name.value || this.f.price.value === this.f.unit.value || this.f.arabicName.value === this.f.price.value;
      let unit = this.f.unit.value === this.f.arabicName.value || this.f.unit.value === this.f.arabicGroupName.value || this.f.unit.value === this.f.groupName.value || this.f.unit.value === this.f.max.value || this.f.unit.value === this.f.min.value || this.f.unit.value === this.f.price.value || this.f.unit.value === this.f.name.value || this.f.arabicName.value === this.f.price.value;

      if (name || arabicName || arabicGroupName || groupName || max || min || price || unit) {
        this.modifierUploadForm.setErrors({ 'invalid': true });
      }

      this.modifierUploadForm.markAllAsTouched();
      this.alertService.clear();
      if (this.modifierUploadForm.invalid) {
        return;
      }
      this.loading = true;
      let uploadData = [];
      let brandID = this.localStorageService.getSelectedBrand().BrandID;
      let fc = this.f;
      let funcKey = this.getKey;
      this.fileData.filter(function (item) {
        var data =
        {

          Name: item[funcKey(item, fc.name.value)].toString().trim(),
          ArabicName: item[funcKey(item, fc.arabicName.value)].toString().trim(),
          Unit: item[funcKey(item, fc.unit.value)].toString().trim(),
          GroupModifier: item[funcKey(item, fc.groupName.value)].toString().trim(),
          ArabicGroupModifier: item[funcKey(item, fc.arabicGroupName.value)].toString().trim(),
          MinModifier: item[funcKey(item, fc.min.value)],
          MaxModifier: item[funcKey(item, fc.max.value)],
          Price: item[funcKey(item, fc.price.value)],
          BrandID: brandID
        };
        uploadData.push(data);
        return true;
      });
      this.modifierService.uploadItems(uploadData).subscribe(data => {
        if (data != null) {
          if (data.result.status === 1) {
            this.alertService.success("Sheet has been saved");
            this.activeModal.dismiss();
          } else {
            this.showFileUpload = false;
            this.showFileOptions = false;
            this.showError = true;
            this.errorList = data.messages;
          }
        }
        this.loading = false;
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }
  setKeys(obj) {
    if (obj.length > 0) {
      let keys = Object.keys(obj[0]);
      //this.FileOptionList = null; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
      let fileOption = [];
      keys.filter(function (item) {
        let data = { value: item, name: item };
        fileOption.push(data);
      });
      //this.FileOptionList = fileOption; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
    }
  }
  async onFileChange(ev) {
    let file = ev.target.files[0];
    //this.fileData = await this.excelService.ReadExcelFile(file);

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const _file = file;
    if (ev.target.files[0].size <= 5000000) {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          let format = this.validateFileHeaders(initial);
          if (format === true) {
            this.fileData = initial;
            this.setKeys(initial);
          }
        }, {});
      }
      reader.readAsBinaryString(_file);
    }
    else {
      this.alertService.error("File size is larger than 5 MB.");
    }
  }

  download() {
    var data =
      [
        {
          Name: '', ArabicName: '', Price: '', Unit: '', GroupModifier: '', ArabicGroupModifier: '', MinModifierItem: '', MaxModifierItem: ''
        }
      ];
    this.excelService.exportAsExcelFile(data, 'ModifiersTemplate', 'xlsx');
  }
  getKey(item, formValue) {
    let key = '';
    Object.keys(item).forEach((val) => {
      if (formValue === val)
        key = val;
    });
    return key;
  }
  validateFileHeaders(sheet: any) {
    let sheetFormat = true;
    if (this.getKey(sheet[0], 'Name') !== 'Name')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'ArabicName') !== 'ArabicName')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Price') !== 'Price')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Unit') !== 'Unit')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'GroupModifier') !== 'GroupModifier')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'ArabicGroupModifier') !== 'ArabicGroupModifier')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'MinModifierItem') !== 'MinModifierItem')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'MaxModifierItem') !== 'MaxModifierItem')
      sheetFormat = false;

    return sheetFormat;

  }
}
