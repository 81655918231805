<div class="modal-header">
    <h5 class="modal-title" id="modal-title">{{'App Uninstall' | translate}}</h5>
    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <p><strong>{{'Are you sure you want to uninstall?' | translate}}</strong></p>
  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">{{'Cancel' | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="accept()">{{'Yes' | translate}} </button>
  </div>