<form [formGroup]="categoryUploadForm" class="form">
  <div class="modal-header">
    <h4 class="modal-title">{{'Import Categories' | translate}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div *ngIf="showFileUpload">
            <div class="form-group files">
              <input type="file" (change)="onFileChange($event)" class="form-control" accept=".xls, .xlsx" #itemFile (click)="itemFile.value = null" value="" />
            </div>
          </div>
          <div *ngIf="showFileOptions">
            <div class="form-group col-lg-12 col-sm-7">
              <div class="row pb-4">
                <div class="col-lg-4">
                  <strong> <label style="color:black">{{'Column Name' | translate }}</label></strong>
                </div>
                <div class="col-lg-6">
                  <strong> <label style="color:black">{{'Map to field' | translate }}</label></strong>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Category Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Juice' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="categoryName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.categoryName.value === f.categoryArabicName.value || f.categoryName.value === f.categoryDisplayOrders.value || f.categoryName.value === f.subCategoryName.value || f.categoryName.value === f.subCategoryArabicName.value ||
                     f.categoryName.value === f.subCategoryDisplayOrders.value || f.categoryName.value === f.subCategoryName.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Category Arabic Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'إسبرسو' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="categoryArabicName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.categoryArabicName.value === f.categoryName.value || f.categoryArabicName.value === f.categoryDisplayOrders.value || f.categoryArabicName.value === f.subCategoryName.value || f.categoryArabicName.value === f.subCategoryArabicName.value ||
                     f.categoryArabicName.value === f.subCategoryDisplayOrders.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Category Orders' | translate }}</label>
                  <p>{{'Sample' | translate }}: 12</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="categoryDisplayOrders">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.categoryDisplayOrders.value === f.categoryArabicName.value || f.categoryDisplayOrders.value === f.categoryName.value || f.categoryDisplayOrders.value === f.subCategoryName.value || f.categoryDisplayOrders.value === f.subCategoryArabicName.value ||
                     f.categoryDisplayOrders.value === f.subCategoryDisplayOrders.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Sub-Category Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Juice' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="subCategoryName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.subCategoryName.value === f.categoryArabicName.value || f.subCategoryName.value === f.categoryDisplayOrders.value || f.subCategoryName.value === f.categoryName.value || f.subCategoryName.value === f.subCategoryArabicName.value ||
                     f.subCategoryName.value === f.subCategoryDisplayOrders.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Sub-Category Arabic Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Juice' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="subCategoryArabicName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.subCategoryArabicName.value === f.categoryArabicName.value || f.subCategoryArabicName.value === f.categoryDisplayOrders.value || f.subCategoryArabicName.value === f.subCategoryName.value || f.subCategoryArabicName.value === f.categoryName.value ||
                     f.subCategoryArabicName.value === f.subCategoryDisplayOrders.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Sub-Category Orders' | translate }}</label>
                  <p>
                    {{'Sample' | translate }}: 15
                  </p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="subCategoryDisplayOrders">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.subCategoryDisplayOrders.value === f.categoryArabicName.value || f.subCategoryDisplayOrders.value === f.categoryDisplayOrders.value || f.subCategoryDisplayOrders.value === f.subCategoryName.value || f.subCategoryDisplayOrders.value === f.subCategoryArabicName.value ||
                     f.subCategoryDisplayOrders.value === f.categoryName.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showError">
            <ul>
              <li *ngFor="let option of errorList">
                <span class="caret">{{'Error at Row No' | translate}} <b>{{option.rowNo | translate}} </b></span>
                <ul class="nested">
                  <li> {{option.message | translate}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <hr />
          <div style="justify-content:normal" *ngIf="showError === false">
            <div class="row">
              <div class="col-lg-8">
                <a *ngIf="showTemplate"> <strong><label (click)="download()" style="color: #3754b7;cursor:pointer">{{'Get Template' | translate}}</label></strong></a>
              </div>
              <div class="col-lg-4">
                <button class="btn btn-primary cutom-button export-continue" (click)="onSubmit()" [disabled]="loading">
                  <span *ngIf="loading" class="spinner-bsubCategoryDisplayOrders spinner-bsubCategoryDisplayOrders-sm mr-1"></span>{{SaveButtonText | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
