<form [formGroup]="modifierUploadForm" class="form">
  <div class="modal-header">
    <h4 class="modal-title">{{'Import Modifiers' | translate}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div *ngIf="showFileUpload">
            <div class="form-group files">
              <input type="file" (change)="onFileChange($event)" class="form-control" accept=".xls, .xlsx" #itemFile (click)="itemFile.value = null" value="" />
            </div>
          </div>
          <div *ngIf="showFileOptions">
            <div class="form-group col-lg-12 col-sm-7">
              <div class="row pb-4">
                <div class="col-lg-4">
                  <strong> <label style="color:black">{{'Column Name' | translate }}</label></strong>
                </div>
                <div class="col-lg-6">
                  <strong> <label style="color:black">{{'Map to field' | translate }}</label></strong>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Juice' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="name">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.name.value === f.arabicName.value || f.name.value === f.price.value || f.name.value === f.unit.value || f.name.value === f.groupName.value ||
                     f.name.value === f.arabicGroupName.value || f.name.value === f.min.value || f.name.value === f.unit.value || f.name.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Arabic Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'إسبرسو' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="arabicName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.arabicName.value === f.name.value || f.arabicName.value === f.price.value || f.arabicName.value === f.unit.value || f.arabicName.value === f.groupName.value ||
                     f.arabicName.value === f.arabicGroupName.value || f.arabicName.value === f.min.value || f.arabicName.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Price' | translate }}</label>
                  <p>{{'Sample' | translate }}: 12</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="price">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.price.value === f.arabicName.value || f.price.value === f.name.value || f.price.value === f.unit.value || f.price.value === f.groupName.value ||
                     f.price.value === f.arabicGroupName.value || f.price.value === f.min.value || f.price.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Unit' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'KG' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="unit">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.unit.value === f.arabicName.value || f.unit.value === f.price.value || f.unit.value === f.name.value || f.unit.value === f.groupName.value ||
                     f.unit.value === f.arabicGroupName.value || f.unit.value === f.min.value ||  f.unit.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Group Modifier' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Juice' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="groupName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.groupName.value === f.arabicName.value || f.groupName.value === f.price.value || f.groupName.value === f.unit.value || f.groupName.value === f.name.value ||
                     f.groupName.value === f.arabicGroupName.value || f.groupName.value === f.min.value || f.groupName.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Arabic Group' | translate }}</label>
                  <p>
                    {{'Sample' | translate }}: {{'عصير برتقال' | translate}}
                  </p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="arabicGroupName">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.arabicGroupName.value === f.arabicName.value || f.arabicGroupName.value === f.price.value || f.arabicGroupName.value === f.unit.value || f.arabicGroupName.value === f.groupName.value ||
                     f.arabicGroupName.value === f.name.value || f.arabicGroupName.value === f.min.value  || f.arabicGroupName.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Min Modifier' | translate }}</label>
                  <p>{{'Sample' | translate }}: 1</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="min">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.min.value === f.arabicName.value || f.min.value === f.price.value || f.min.value === f.unit.value || f.min.value === f.groupName.value ||
                     f.min.value === f.arabicGroupName.value || f.min.value === f.name.value || f.min.value === f.max.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Max Modifier' | translate }}</label>
                  <p>{{'Sample' | translate }}: 3</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="max">
                    <option [ngValue]="option.value"
                            selected="option.value === value" *ngFor="let option of FileOptionList">
                      {{option.name | translate}}
                    </option>
                  </select>
                  <div class="alert alert-danger" *ngIf="f.max.value === f.arabicName.value || f.max.value === f.price.value || f.max.value === f.unit.value || f.max.value === f.groupName.value ||
                     f.max.value === f.arabicGroupName.value || f.max.value === f.name.value  || f.max.value === f.min.value">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showError">
            <ul>
              <li *ngFor="let option of errorList">
                <span class="caret">{{'Error at Row No' | translate}} <b>{{option.rowNo | translate}} </b></span>
                <ul class="nested">
                  <li> {{option.message | translate}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <hr />
          <div style="justify-content:normal" *ngIf="showError === false">
            <div class="row">
              <div class="col-lg-8">
                <a *ngIf="showTemplate"> <strong><label (click)="download()" style="color: #3754b7;cursor:pointer">{{'Get Template' | translate}}</label></strong></a>
              </div>
              <div class="col-lg-4">
                <button class="btn btn-primary cutom-button export-continue" (click)="onSubmit()" [disabled]="loading">
                  <span *ngIf="loading" class="spinner-barabicGroupName spinner-barabicGroupName-sm mr-1"></span>{{SaveButtonText | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
