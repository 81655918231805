<div class="modal-header">
  <h4 class="modal-title">{{title | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHTML]="message"></div>
  <div class="row" *ngIf="deleteDialogue">
    <div class="form-group col-lg-12 col-sm-12">
      <label for="fullName">{{'Void Reason' | translate }}</label>
      <input type="text" class="form-control" id="fullName" placeholder="{{'Reason' | translate }}" [(ngModel)]="reason" />
      <div *ngIf="deleteDialogue && !reason" class="alert alert-danger">
        {{'Reason is required' | translate }}.
      </div>
      <div *ngIf="deleteDialogue && reason && (reason.length < 3 || reason.length > 140)" class="alert alert-danger">
        {{'Reason must greater than 3 and less then 140 text length' | translate }}.
      </div>
    </div>
  </div>
</div>
<div class="modal-footer m-0">
  <button type="button" class="btn btn-cancel" (click)="decline()">{{'Cancel' | translate }}</button>
  <button class="btn float-end" [ngClass]="{'btn-danger btn-uninstall': deleteDialogue,'btn-primary cutom-button custom-create-btn' :!deleteDialogue}" (click)="accept()">{{buttonText | translate }}</button>
</div>
