import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_services/alert.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { LocationIntegrationZATCAService } from 'src/app/_services/location-integration-zatca.service';
import { LocationService } from 'src/app/_services/location.service';
import { ConfirmationZatcaModalComponent } from './confirmation-zatca-modal/confirmation-zatca-modal.component';


@Component({
  selector: 'app-location-integration',
  templateUrl: './location-integration.component.html',
  styleUrl: './location-integration.component.css'
})
export class LocationIntegrationComponent {


  locationKey = "POS-87RCHB-SA-4080-1";
  loadingLocation = false;

  otp: string = '';
  formatOTP() {
    this.otp = this.otp.replace(/\D/g, '').slice(0, 4);
  }

  constructor(private _service: LocationIntegrationZATCAService,
    private _modalService:NgbModal,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private locationService: LocationService) {

    this.locationService.locationIdChange.subscribe((id) => {
      if (id !== undefined && id !== 0 && id !== null) {
        this.get();
      }
    });
  }

  ngOnInit() {
    this.get();
  }

  ZATCAComplianceData : any;
  get() {
    let brandID = parseInt(this.localStorageService.getSelectedBrand().BrandID);
    this._service.getZATCACompliance(brandID, LocationService.locationId).subscribe((data: any) => {
      this.ZATCAComplianceData = data;

      if (this.ZATCAComplianceData?.terminals) {
        this.ZATCAComplianceData.terminals.forEach((item: any) => {
          item.otp = '';
        });
      }

      console.log("ZATCA Compliance Data", this.ZATCAComplianceData);
    });
  }


  loading = false;
  terminalComplience(item: any) {
    if(item.otp.length ===6 && item.zatcaComplianced ===false){
      this.verifyDevice(item);
    }else{
      this.alertService.error('Please enter OTP');
    }
  }

  refreshterminalComplience(item: any) {
    if(item.otp.length ===6 && item.zatcaComplianced ===true){
      this.verifyDevice(item);
    }else{
      this.alertService.error('Please enter OTP');
    }
  }


  verifyDevice(item){
    this.loading =true;
    this._service.ComplianceDeviceWithOTP(item).subscribe(
      (response) => {
        this.loading = false;
        // Handle the successful response
        console.log(response);

        if(response.status === 200 || response.status === 1) {
          this.alertService.success("Successfully complience device");
        }
        else if(response.status === 500) {
          this.alertService.error(JSON.parse(response?.message).message);
        }else{
          this.alertService.error(JSON.parse(response?.message).message);
        }


        this.get();
      },
      (error: HttpErrorResponse) => {
        this.alertService.error("Failed to complience device");
        this.loading = false;
        if (error.status === 400) {
          // Display an alert message for 404 error
          alert('Resource not found');
        } else {
          // Handle other errors
          console.error(error);
        }
      }
    );
  }

  openConfirmation(item: any) {
    const modalRef = this._modalService.open(ConfirmationZatcaModalComponent, { centered: true, windowClass: 'uninstall-modal',modalDialogClass: 'uninstall-modal-custom' });
    modalRef.result.then((result) => {
      if (result) {
        this.refreshterminalComplience(item);
        }
      });;
  }
}


