
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { Supplier } from 'src/app/_models/Supplier';
import { SupplierService } from 'src/app/_services/supplier.service';
import { AddsupplierComponent } from './addsupplier/addsupplier.component';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TrackEventsService } from '../../_services/track-events.service';


@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  providers: [ExcelService]
})
export class SupplierComponent implements OnInit {
  data$: Observable<Supplier[]>;
  oldData: Supplier[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "supplier";
  submit: boolean;
  excelData = [];
  StatusList = [];
  CityList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: SupplierService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Suppliers',type);    
  }
  getBrandData() {
    this.service.headerFilter = { city: [''], status: [''] };
    this.service.getSupplier(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      this.CityList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(val => val.city)).forEach(e => {
          this.CityList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddsupplierComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Supplier");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(supplier) {
    
    const modalRef = this.modalService.open(AddsupplierComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Supplier");
    modalRef.componentInstance.SelectedID = supplier.supplierID;
    modalRef.componentInstance.SelectedName = supplier.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Supplier has been deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Supplier has been deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(supplier) {
    this.service.getById(supplier.supplierID, supplier.brandID).subscribe((res: any) => {
      res.statusID = supplier.statusID === 1 ? 2 : 1;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Supplier has been " + (supplier.statusID === 1 ? "Deactived" : "Active"));
        this.getBrandData();
      }, error => {
        this.alertService.error(error);
      });
    });
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, supplier) {
    this.service.suppliers.forEach((item, index) => {
      if (item.supplierID == supplier.supplierID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {
          
          this.excelData.splice(index, 1);
        }
      }
    });
  }
  
  private getExportData() {
    let selected: Supplier[];

    selected = [];
    this.service.suppliers.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.suppliers : selected;
    return selected;
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Supplier has been deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';
  SelectedCity = "All Cities";
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      city: (this.SelectedCity == 'All Cities') ? this.CityList.map(function (k) { return k.name }) : [this.SelectedCity]
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}

