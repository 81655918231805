import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Brand } from '../_models/Brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private http: HttpClient) { }



  public get( BrandID) {
    return this.http.get<Brand[]>(`api/brand/${BrandID}`);
  }
  insert(data) {
    return this.http.post(`api/brand`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  update(data) {
    return this.http.put(`api/brand`, data)
      .pipe(map(res => {
        return res;
      }));
  }
  createRequestNewBrand(){

    return this.http.get<Brand[]>(`api/brand/createrequest`);
  }
}
