import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { LocationItem } from '../../../_models/LocationItem';
import { partnerItems } from '../../../_models/partnerItems';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationItemSettingService } from '../../../_services/location-item-setting.service';

@Component({
  selector: 'app-location-item-setting',
  templateUrl: './location-item-setting.component.html',
  styleUrls: ['./location-item-setting.component.css']
})
export class LocationItemSettingComponent implements OnInit {
  @Input() Item:LocationItem;
  @Input() LocationId;
  @Input() BrandId;
  loading = false;
  selectedLang = 'en';
  data: partnerItems[];

  constructor(public activeModal: NgbActiveModal,
    public service: LocationItemSettingService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService) {
    this.selectedLang = this.localStorageService.getSelectedLanguage();
  }

  ngOnInit(): void {
    this.getPartners();
  }
  getPartners() {
    this.service.get(this.BrandId, this.LocationId, this.Item.itemID).subscribe(res => {
      if (this.Item.partnerLocationItems) {
        this.Item.partnerLocationItems.forEach(e => {
          e.tempPrice = e.price;
          if (e.statusID === 1) {
            e.status = true;
          }
          else {
            e.status = false;
          }
        });
        this.data = this.Item.partnerLocationItems;
      } else {
        res.forEach(e => {
          e.tempPrice = e.price;
          if (e.statusID === 1) {
            e.status = true;
          }
          else {
            e.status = false;
          }
        });
        this.data = res;
      }
    });
  }
  StatusChange(e,itemPartner) {
    itemPartner.statusID = e;
  }
  SaveItem() {
    var hasError: boolean = false;
    var hasStatusEnabled: boolean = false;
    this.data.forEach(e => {
      if (e.status) {
        e.statusID = 1
        hasStatusEnabled = true;
      }
      else {
        e.statusID = 2
      }
      if (e.tempPrice && e.tempPrice <= 0) {
        hasError = true;
      } else { 
        e.price = e.tempPrice;
      }
    });
    if (hasError) {
      this.alertService.error('Please correct item price');
      return;
    }
    if (hasStatusEnabled) {
      this.Item.partnerStatusID = 15;
    }
    else {
      this.Item.partnerStatusID = 2;
    }
    this.Item.partnerLocationItems= this.data;
    this.activeModal.close(this.Item);
  }
}
