import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private http: HttpClient) {
  }

  getCities(Code) {
    return this.http.get<any[]>(`api/city/all/${Code}`);
  }
  
}
