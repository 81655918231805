import { Component } from '@angular/core';

@Component({
  selector: 'app-user-announcement',
  templateUrl: './user-announcement.component.html',
  styleUrl: './user-announcement.component.css'
})
export class UserAnnouncementComponent {
  openLink() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScZoH3f06F8Fo95roV5h8UBlyU7u9ycoCB2mrEpcW-SbA3pfA/viewform', '_blank');
  }
}
