<app-login-header></app-login-header>

<main class="login justify-content-center d-flex flex-column justify-content-center align-items-center">
    <!-- .row -->
    <img class="marn-logo mb-per-15" src="/assets/images/marn-logo.svg" alt="Marn" />
  
    <div class="row login-container"  >
      <div class="col-12">


        <!-- Form -->
        <form [formGroup]="resetForm" @fadeSlideInOut *ngIf="!done" class="forgotpassword">
          <h1>Reset Password</h1>
          <p class="mute mb-per-10">Please create new password, 8 characters long.</p>
          <div id="forgotpassword">
            <!-- Email address -->
            <div class="form-group">
              <!-- Input -->
              <input type="password" formControlName="password" id="res-pass" name="res-pass" class="form-control form-control-appended" placeholder="New Password" />
              <div *ngIf="f.password.invalid && (f.password.dirty)" class="alert alert-danger">
                <div *ngIf="f.password.errors.required">
                  Password is required.
                </div>
              </div>
            </div>


            <div class="form-group">
              <!-- Input -->
              <input type="password" formControlName="confirmpassword" id="res-pass" name="res-pass" class="form-control form-control-appended" placeholder="Confirm Password" />
              <div *ngIf="f.confirmpassword.invalid && (f.confirmpassword.dirty)" class="alert alert-danger">
                <div *ngIf="f.confirmpassword.errors.required">
                  Confirm is required.
                </div>
              </div>
            </div>

          </div>

          <!-- Submit -->
          <button type="button" [disabled]="loading || f.password.invalid || f.confirmpassword.invalid"
                  class="btn btn-primary btn-lg btn-block  mb-per-10" (click)="ResetPassword()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Reset password
          </button>

        </form>
    

        <form *ngIf="done" @fadeSlideInOut class="forgotpassword">

          <h1>Reset Successful</h1>
          <p class="mute">Your password has been successfully reset.</p>

          <button type="button" class="btn btn-primary btn-lg btn-block  mb-per-10" (click)="continue()">
            Continue
          </button>
        </form>

      </div>
    </div>
    <!-- / .row -->
  
  
  
  
   
  </main>
  


    
