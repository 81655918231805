import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root'
})
export class Utility {
  static setDateFormatforAPI(date: Date) {
    const newDate = date.getFullYear() + '-' +
      ("0" + (date.getMonth() + 1)).slice(-2) + '-' +
      ("0" + date.getDate()).slice(-2) + '-' +
      ("0" + date.getHours()).slice(-2) + '-' +
      ("0" + date.getMinutes()).slice(-2) + '-' +
      ("0" + date.getSeconds()).slice(-2);
    return newDate;
  }
}
