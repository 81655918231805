import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbdSortableHeader } from '../../_directives/sortable.directive';
import { AlertService } from '../../_services/alert.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { PartnerSummaryService } from '../../_services/partner-summar.service';
import { PrintService } from '../../_services/print.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-print-partner-daily-summary',
  templateUrl: './print-partner-daily-summary.component.html',
  styleUrls: ['./print-partner-daily-summary.component.css']
})
export class PrintPartnerDailySummaryComponent implements OnInit {

  loading$: Observable<boolean>;
  selectedReport: any;
  selectedBrand: any;
  dailySales: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: PartnerSummaryService,
    private router: Router,
    private printService: PrintService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService) {
    this.selectedReport = history.state;// this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
  }


  ngOnInit(): void {
    if (this.selectedReport !== null && this.selectedReport !== undefined) {
      this.getReportData(this.selectedReport.dataURL);
      this.selectedBrand = this.localStorageService.getSelectedBrand();
    }
  }

  getReportData(dataURL) {

    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataURL,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {
          _this.dailySales = res;
          console.log(_this.dailySales);
          _this.service.sumOrderAverage = 0;
          _this.service.sumCard = 0;
          _this.service.sumCash = 0;
          _this.service.sumAggregator = 0;
          _this.service.sumDiscount = 0;
          _this.service.sumComplimentary = 0;
          _this.service.sumVoid = 0;
          _this.service.sumHold = 0;
          _this.service.sumRefund = 0;
          _this.service.sumSales = 0;
          _this.service.sumGrandTotal = 0;
          _this.service.sumTax = 0;

          _this.dailySales.forEach(item => {
            _this.service.sumValues('sumOrderAverage', item.OrderAverage)
            _this.service.sumValues('sumCard', item.Card)
            _this.service.sumValues('sumCash', item.Cash)
            _this.service.sumValues('sumAggregator', item.Aggregator)
            _this.service.sumValues('sumDiscount', item.Discount)
            _this.service.sumValues('sumComplimentary', item.Complimentary)
            _this.service.sumValues('sumVoid', item.Void)
            _this.service.sumValues('sumHold', item.Hold)
            _this.service.sumValues('sumRefund', item.Refund)
            _this.service.sumValues('sumSales', item.Sales)
            _this.service.sumValues('sumGrandTotal', item.GrandTotal)
            _this.service.sumValues('sumTax', item.Tax)
          });
          _this.printService.onDataReady();

        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })



  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }

}
