import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { AlertService } from '../../../../_services/alert.service';
import { ExcelService } from '../../../../_services/excel.service';
import { LocalStorageService } from '../../../../_services/local-storage.service';

@Component({
  selector: 'app-upload-inventory-count',
  templateUrl: './upload-inventory-count.component.html',
  styleUrl: './upload-inventory-count.component.css'
})
export class UploadInventoryCountComponent {
  @Input() Items = null;
  SaveButtonText = "Save";
  showError = false;
  loading = false;
  showFileUpload = true;
  itemsUploadForm: UntypedFormGroup;
  FileOptionList: Array<any> = [
    { value: "Name", name: 'Name' },
    { value: "SKU", name: 'SKU' },
    { value: "Quantity", name: 'Quantity' }
  ];
  errorList: any[];
  finalData = [];
  fileData: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService) {
    this.createForm();
  }
  get f() { return this.itemsUploadForm.controls; }

  private createForm() {
    this.itemsUploadForm = this.formBuilder.group({
      Name: [Validators.required],
      SKU: [Validators.required],
      Quantity: [Validators.required]
    });
  }
  ngOnInit() {
  }
  close() {
    this.activeModal.close(this.finalData);
  }

  async onFileChange(ev) {
    let file = ev.target.files[0];

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const _file = file;
    if (ev.target.files[0].size <= 5000000) {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          let format = this.validateFileHeaders(initial);
          if (format === true) {
            this.fileData = initial;
            this.setKeys(initial);
          }
        }, {});
      }
      reader.readAsBinaryString(_file);
    }
    else {
      this.alertService.error("File size is larger than 5 MB.");
    }
  }
  setKeys(obj) {
    if (obj.length > 0) {
      let keys = Object.keys(obj[0]);
      //this.FileOptionList = null; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
      let fileOption = [];
      keys.filter(function (item) {
        let data = { value: item, name: item };
        fileOption.push(data);
      });
      //this.FileOptionList = fileOption; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
    }
  }
  download() {
    var data =
      [
        { Name: '', SKU: '', Quantity: '' }
      ];
    this.excelService.exportAsExcelFile(data, 'ItemCountTemplate', 'xlsx');
  }
  getKey(item, formValue) {
    let key = '';
    Object.keys(item).forEach((val) => {
      if (formValue === val)
        key = val;
    });
    return key;
  }
  validateFileHeaders(sheet: any) {
    let sheetFormat = true;
    if (this.getKey(sheet[0], 'Name') !== 'Name')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'SKU') !== 'SKU')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Quantity') !== 'Quantity')
      sheetFormat = false;

    return sheetFormat;

  }

  ValidateSheet(sheet) {
    let sheetFormat = true;
    sheet.filter(function (item) {
      if (Object.keys(item).length < 3)
        sheetFormat = false;
      if (item[Object.keys(item)[0]] === null || item[Object.keys(item)[0]] === undefined || item[Object.keys(item)[0]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[1]] === null || item[Object.keys(item)[1]] === undefined || item[Object.keys(item)[1]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[2]] === null || item[Object.keys(item)[2]] === undefined || item[Object.keys(item)[2]] === '')
        sheetFormat = false;

    });
    return sheetFormat;
  }
  onSubmit() {

    if (this.fileData) {
      if (!this.ValidateSheet(this.fileData)) {
        this.alertService.error("Attached sheet is not valid.");
        return;
      }
    }
    else {
      this.alertService.error("File not found or invalid file.");
      return;
    }
    let uploadData = [];
    let missingData = [];
    let items = this.Items;

    this.fileData.filter(function (item) {
      let i = 2;
      var data =
      {
        Name: item['Name'].toString().trim(),
        SKU: item['SKU'].toString().trim(),
        Quantity: item['Quantity'].toString().trim(),
      };
      if (items.some(e => e.name === data.Name && e.sku === data.SKU)) {
        let t = items.find(e => e.name === data.Name && e.sku === data.SKU);
        t.quantity = +data.Quantity;
        uploadData.push(t);
      }
      else {
        missingData.push({
          rowNo: i,
          message: 'Name and SKU is not matching or Invalid Quantity'
        });
      }
      i++;
      return true;
    });
    this.finalData = uploadData;
    if (missingData.length > 0) {
      this.showFileUpload = false;
      this.showError = true;
      this.errorList = missingData;
    }
    else {
      this.activeModal.close(this.finalData);
    }
  }
}
