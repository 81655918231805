<form [formGroup]="stcForm" class="form msegat" >
    <div class="modal-header">
 
      <div class="d-flex" style="width: 100%;">
          
        <div class="d-flex align-items-start justify-content-start flex-1">
          <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
   
   
        <div class="d-flex align-items-center justify-content-center flex-1">
          <h4 class="modal-title">
            {{'STC Pay' | translate }}
          </h4>
        </div>
   
 
        <div class="d-flex align-items-end justify-content-end flex-1"> 
          <a class="btn-default btn-grey" href="https://stcpay.com.sa" target="_blank">
            {{'Learn More' | translate }}
          </a>
          <button [disabled]="loading" class="btn-primary btn-save" [hidden]="f.rowID.value >0"  (click)="onSubmit()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ButtonText | translate}}
          </button>
        </div>
   
  </div>

    </div>



    
    <div class="modal-body integration">
      <div class="container">
        <div class="row">
  
          <div class="col-lg-12" style="margin-top: 40px;">




            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="logo-placeholder">
                  <img class="" src="assets/images/marketplace/stc.png" alt="Msegat">
                </div>
              </div>
            </div>

            <div class="line"></div>

            <h6 class="heading"> {{'Install' | translate }}</h6>

            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="merchantID"> {{'Add Merchant ID' | translate }}</label>
                <input type="text" class="form-control" id="merchantID" placeholder="" formControlName="merchantID">
                <div *ngIf="f.merchantID.invalid && (f.merchantID.dirty || f.merchantID.touched)" class="alert alert-danger">
                  <div *ngIf="f.merchantID.errors.required">
                    {{'Merchant ID is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>




            <ng-container *hasPermission="['Marketplace','Uninstall']">
              <div class="line" *ngIf="f.rowID.value >0" style="margin-top: 30px;"></div>

              <h6 class="heading" *ngIf="f.rowID.value >0"> {{'Danger zone' | translate }}</h6>

              <div class="row" style="margin-top: 30px;" *ngIf="f.rowID.value >0">
                <div class="col-6">
                  <label> {{'Uninstall this App' | translate }} </label>
                </div>
                <div class="col-6 text-right">
                  <button [disabled]="loading" class="btn btn-danger btn-uninstall" (click)="openConformation()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{'Uninstall' | translate }}
                  </button>
                </div>

              </div>
            </ng-container>
          </div>
   
        </div>
      </div>
    </div>
  </form>
