<main class="views container">
  <div class="page">
    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Stock Movement' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header align-items-center">
            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,allData$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',allData$)">Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body position-relative">
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.dateRange}}</div>
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive hrzScroll salesOrderDetail">
          <div style="width:fit-content">
            <div class="preview-filter-data" dir="ltr">{{selectedReport?.locations}}</div>
            <table class="table table-striped">
              <thead>
                <tr class="table-header">
                  <th>
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Branch' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllLocations" (ngModelChange)="selectAllFilters($event,locations)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,locations)">
                            {{'All Branches' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let location of locations">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="location.isChecked" (ngModelChange)="filter()">
                            <label class="form-check-label" (click)="filter()">
                              {{location.name}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>



                  <th sortable="Name" (sort)="onSort($event)">{{'Item' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="Unit" (sort)="onSort($event)">{{'Unit' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="SKU" (sort)="onSort($event)">{{'SKU' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="InitialQTY" (sort)="onSort($event)">
                    {{'Initial Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Initial Qty Tool Tip' | translate}}" />
                    &nbsp;
                    <img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="Cost" (sort)="onSort($event)">
                    {{'Initial Qty Cost' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Initial Qty Cost Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="PurchasedQTY" (sort)="onSort($event)">
                    {{'Purchased Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Purchased Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="ReturnedPurchasedQTY" (sort)="onSort($event)">
                    {{'Returned Purchase Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Returned Purchase Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="TransferQTY" (sort)="onSort($event)">
                    {{'Transfer in Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Transfer In Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="ReturnedTransferQTY" (sort)="onSort($event)">
                    {{'Returned Transfer in Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Returned Transfer In Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="TransferOutQTY" (sort)="onSort($event)">
                    {{'Transfer out Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Transfer Out Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="ReturnedTransferOutQTY" (sort)="onSort($event)">
                    {{'Return Transfer out Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Returned Transfer Out Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="PrepConsumption" (sort)="onSort($event)">
                    {{'Prep Consumption' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Prep Consumption Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="PrepConsumption" (sort)="onSort($event)">
                    {{'Prep Received' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Prep Received Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="SalesConsumption" (sort)="onSort($event)">
                    {{'Sales Consumption' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Sales Consumption Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="SalesReturn" (sort)="onSort($event)">
                    {{'Sales Returns' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Sales Returns Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="ReconciliationQTY" (sort)="onSort($event)">
                    {{'Reconciliation Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Reconciliation Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="StockDifference" (sort)="onSort($event)">
                    {{'Inventory Count Difference' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Quantity of the differences made in inventory count operations' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <!--<th sortable="ExpiredQTY" (sort)="onSort($event)">
    {{'Expired QTty' | translate }}
    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Expired Qty Tool Tip' | translate}}" />
    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
  </th>-->
                  <th sortable="FinalQuantity" (sort)="onSort($event)">
                    {{'Final Qty' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Final Qty Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th sortable="FinalQTYCost" (sort)="onSort($event)">
                    {{'Final Qty Cost' | translate }}
                    <img src="../../../assets/images/Info.svg" class="" placement="bottom" ngbTooltip="{{'Final Qty Cost Tool Tip' | translate}}" />
                    &nbsp;<img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data$ | async ">
                  <td> {{item.Location }} </td>
                  <td> {{(selectedLang == 'en')?item.Name:item.AlternateName }} </td>
                  <td> {{item.Unit }} </td>
                  <td> {{item.SKU }} </td>
                  <td> {{item.InitialQTY | number:'1.2-2'}} </td>
                  <td> {{item.Cost | number:'1.2-2'}} </td>
                  <td> {{item.PurchasedQTY | number:'1.2-2'}} </td>
                  <td> -{{item.ReturnedPurchasedQTY | number:'1.2-2'}} </td>
                  <td> {{item.TransferQTY | number:'1.2-2'}} </td>
                  <td> -{{item.ReturnedTransferQTY | number:'1.2-2'}} </td>
                  <td> -{{item.TransferOutQTY | number:'1.2-2'}} </td>
                  <td> {{item.ReturnedTransferOutQTY | number:'1.2-2'}} </td>
                  <td> -{{item.PrepConsumption | number:'1.2-2'}} </td>
                  <td> {{item.PrepReceived | number:'1.2-2'}} </td>
                  <td> -{{item.SalesConsumption | number:'1.2-2'}} </td>
                  <td> {{item.SalesReturn | number:'1.2-2'}} </td>
                  <td> -{{item.ReconciliationQTY | number:'1.2-2'}} </td>
                  <td> {{item.StockDifference | number:'1.2-2'}} </td>
                  <!--<td> -{{item.ExpiredQTY | number:'1.2-2'}} </td>-->
                  <td> {{item.FinalQuantity | number:'1.2-2'}} </td>
                  <td> {{item.FinalQTYCost | number:'1.2-2'}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div class="row mt-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            {{'Showing' | translate }}
            <strong>{{(service.pageSize * service.page)-199}}</strong> {{'to' | translate }}
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>&nbsp;{{'of' | translate }}
            <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</main>

