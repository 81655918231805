<div class="modal-header">
  <h4 class="modal-title-lg">
    {{ 'Assign Coordinates' | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-12 form-group">
      <label for="Table Title">{{'Search for address' | translate }}</label>
      <input type="text" class="form-control" id="search" [(ngModel)]="marker.label" #search
             (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="false" >
    </div>
    <div class="col-lg-12 form-group">

      
      <google-map height="300px" width="470px" [center]="location?.position"
                  (mapInitialized)="onMapReady($event)"
                  [zoom]="zoom">
        <map-marker *ngIf="marker.position.lat" [clickable]="true"
                    [position]="marker.position" [label]="marker.label"
                    [title]="marker.label">
        </map-marker>
      </google-map>

  
      <!--<agm-map [latitude]="location?.latitude" [longitude]="location?.longitude" [zoom]="zoom"
           [disableDefaultUI]="false"
           [zoomControl]="false"
           (mapReady)="mapReadyHandler($event)"
           style="height: 300px;">

    <agm-marker [latitude]="marker?.latitude"
                [longitude]="marker?.longitude"
                [markerDraggable]="false">
      <agm-info-window>
        <strong>{{marker?.label | translate}}</strong>
      </agm-info-window>
    </agm-marker>
  </agm-map>-->
    </div>
    <div class="col-lg-12">
      <div class="grayBox">
        <span>{{marker?.label}}</span>
        <br />
        <a style="color: #3754b7;cursor: pointer;text-decoration:underline">{{marker?.position.lat}}{{(marker?.position.lat >0)?', ':''}}{{marker?.position.lng}}</a>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary cutom-button float-end" (click)="save()">
      {{ButtonText | translate}}
  </button>
</div>

