import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountSummaryService {

  constructor(private http: HttpClient) { }

  GetIncomeStatement(brandId, locationId, fromDate, toDate) {
    return this.http.get<any>(`api/AccountSummary/GetIncomeStatement/${fromDate}/${toDate}/${locationId}/${brandId}`);
  }
  GetVATDeclaration(brandId, locationId, fromDate, toDate) {
    return this.http.get<any>(`api/AccountSummary/GetVATDeclaration/${fromDate}/${toDate}/${locationId}/${brandId}`);
  }

}
