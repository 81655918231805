import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationService } from '../../../_services/location.service';
import { ReasonService } from '../../../_services/reason.service';

@Component({
  selector: 'app-create-reason',
  templateUrl: './create-reason.component.html',
  styleUrls: ['./create-reason.component.css']
})
export class CreateReasonComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() reasonType = 0;
  @Input() SelectedName = '';
  ButtonText = "Save";
  reasonForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  LocationsList = [];
  selectedLocationIds: string[];
  ReasonType: any;
  constructor(private location: LocationService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private reasonService: ReasonService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService  ) {
    this.createForm();
    this.loadLocations();
    this.loadTypes();
  }

  ngOnInit() {
    this.setSelectedReason();
  }

  get f() { return this.reasonForm.controls; }
  private createForm() {

    this.reasonForm = this.formBuilder.group({
      description: ['', Validators.required],
      reasonTypeID: [0 , Validators.required],
      reasonID: 0,
      users: null,
      rowVersion: 0,
      locations: [],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      statusID: 1,
      location: ''
    });
  }
  close() {
    this.activeModal.dismiss();
  }
  loadLocations() {
    this.location.getLocations();

    this.location.locations$.subscribe((res: any) => {
      this.LocationsList = res;
    });
  }
  private loadTypes() {
    this.reasonService.getTypes().subscribe((res: any) => {
      this.ReasonType = res;
    });
  }

  setSelectedReason() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loading = true;

      this.reasonService.get(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set
        console.log(res)
        this.editForm(res);
        this.loading = false;
      });
    }
    else {
      this.ButtonText = "Save";
      this.f.reasonTypeID.setValue(this.reasonType);
    }
  }
  editForm(reason)
  {
    this.f.description.setValue(reason.description);
    this.f.reasonID.setValue(reason.reasonID);
    this.f.rowVersion.setValue(reason.rowVersion);
    this.f.reasonTypeID.setValue(reason.reasonTypeID);
    if (reason.locations !== "") {
      const stringToConvert = reason.locations;
      this.selectedLocationIds = stringToConvert.split(',').map(Number);
      this.f.locations.setValue(reason.locations);
    }
  }
  public onSelectAll(control) {
     if (control == 'location') {
      const _selectedLocations = this.LocationsList.map(item => item.locationID);
      this.reasonForm.get('location').patchValue(_selectedLocations);
    }

  }
  onSubmit() {
    this.reasonForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.reasonForm.invalid) { return; }
    this.loading = true;
    this.f.locations.setValue(this.selectedLocationIds === undefined ? "" : this.selectedLocationIds.toString());

    if (parseInt(this.f.reasonID.value) === 0) {
      //Insert item
      console.log(JSON.stringify(this.reasonForm.value));
      this.reasonService.insert(this.reasonForm.value).subscribe(data => {
        this.alertService.success("Reason has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update item
      this.reasonService.update(this.reasonForm.value).subscribe(data => {
        this.alertService.success("Reason has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }
}
