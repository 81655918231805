<div class="row form location-integration">
  <div class="row frmlocation">
    <div class="col-lg-8 col-md-7 col-sm-12">
      <section class="tile" >
        <div class="tile-header">
          <h6>{{ "ZATCA Integration" | translate }}</h6>
        </div>
        <div class="tile-body"   [ngStyle]="{'pointer-events': loading ? 'none' : 'unset'  }" >
          <div class="row">
            <div class="col-12">
              <table class="table p-0">
                <tbody>
                  <tr class="border-bottom">
                    <td>{{'VAT ID' | translate}}</td>

                    <td
                      class="text-right font-weight-bold"
                      [ngClass]="{
                        'text-danger':
                          ZATCAComplianceData?.location.vatid == null
                      }"
                    >
                      {{
                        ZATCAComplianceData?.location.vatid == null ? "Not Added" : ZATCAComplianceData?.location.vatid  | translate
                      }}
                    </td>
                  </tr>
                  <tr class="border-bottom">
                    <td>{{'CRN' | translate}}</td>

                    <td
                      class="text-right font-weight-bold"
                      [ngClass]="{
                        'text-danger': ZATCAComplianceData?.location.crn == null
                      }"
                    >
                      {{
                        ZATCAComplianceData?.location.crn == null ? "Not Added" : ZATCAComplianceData?.location.crn  | translate
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td>{{'Address' | translate}}</td>

                    <td
                      class="text-right font-weight-bold"
                      [ngClass]="{
                        'text-danger':
                          ZATCAComplianceData?.location.address == null
                      }"
                    >
                      {{
                        ZATCAComplianceData?.location.crn == null? "Not Added" : ZATCAComplianceData?.location.address | translate
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row" *ngIf="ZATCAComplianceData !==undefined && ZATCAComplianceData?.location.crn == null">
            <div class="col-12">
              <div
                class="form-group- ng-star-inserted"
                style="margin-top: 20px"
              >
                <div class="row high-safety-mode">
                  <div class="col-12">
                    <label for="highSafety" class="label-bold">
                      <!-- <img  src="/assets/images/icons/Featured_Icon.svg" > -->
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        class="me-1 mt-3"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g style="mix-blend-mode: multiply">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                            fill="#FEE3E1"
                          />
                        </g>
                        <g clip-path="url(#clip0_11750_656)">
                          <path
                            d="M20 16V20M20 24H20.01M30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20Z"
                            stroke="#CE281C"
                            stroke-width="2.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_11750_656">
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              transform="translate(8 8)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {{'Wrong Information' | translate}}
                    </label>
                  </div>
                  <div class="form-group- col-lg-12 col-sm-12">
                    <label
                      style="font-size: 12px !important; color: #545f75"
                      class="ms-5"
                    >
                      {{'Make sure the above information are added correctly before connecting devices' | translate}}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label
                for="highSafety"
                class="label-bold"
                style="margin: 23px 5px"
              >
                {{'Verify Devices' | translate}}

                <!-- <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-left: 10px"
                >
                  <path
                    _ngcontent-ng-cli-universal-c865050581=""
                    d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z"
                    fill="#545F75"
                  ></path>
                </svg> -->
              </label>
              <img src="../../../assets/images/Info.svg" placement="right" [ngbTooltip]="tooltipZatca" />

              <ng-template #tooltipZatca>
                <ng-container >
                  <label class="text-light font-white text-left p-1">{{'Add the device OTP generated from Fatoora portal to verify.' | translate}}</label>
                </ng-container>
              </ng-template>

            </div>
          </div>

          <div class="row">
            <div class="col=12">
              <section class="tile">
                <div class="tile-body table-responsive p-0 text-nowrap">
                  <table
                    class="table table-striped location table-custom text-start p-0"
                  >
                    <thead>
                      <tr class="table-header">
                        <th
                          width="20%"
                          scope="col"
                          sortable="name"
                          class="text-left"
                        >
                          {{ "Device Name" | translate }}
                        </th>
                        <th width="20%" scope="col" class="text-left">
                          {{ "Status" | translate }}
                        </th>
                        <th
                          width="20%"
                          scope="col"
                          sortable="OTP"
                          class="text-left"
                        >
                          {{ "OTP" | translate }}
                        </th>
                        <th
                          width="20%"
                          scope="col"
                          sortable="Action"
                          class="text-left"
                        >
                          {{ "Action" | translate }}
                        </th>
                        <th width="10%" scope="col">
                          {{ "" }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of ZATCAComplianceData?.terminals">
                        <td class="name-blue terminal">
                          <h6>{{ item.terminalName }}</h6>
                          <a>{{ item.serialNumber }}</a>
                        </td>

                        <td class="text-left">
                          <ngb-highlight
                            *ngIf="item?.statusID === 1"
                            class="btn btn-sm btn-active bullet-button-2-"
                            [term]=""
                            result="{{ 'Active' | translate }}"
                          >
                          </ngb-highlight>
                          <ngb-highlight
                            *ngIf="item?.statusID === 2"
                            class="btn btn-sm btn-inactive bullet-button-2-"
                            [term]=""
                            result="{{ 'Inactive' | translate }}"
                          >
                          </ngb-highlight>
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            class="form-control otp-input"
                            placeholder="------"
                            maxlength="6"
                            [(ngModel)]="item.otp"
                            (ngModelChange)="formatOTP()"

                          />
                        </td>
                        <td class="text-left">
                          <label class="text-success ms-3 text-center w-100" *ngIf="item?.zatcaComplianced == true">Verified</label>
                          <button (click)="terminalComplience(item)"
                          type="button"
                          *ngIf="item?.zatcaComplianced == false"
                          class="btn ms-3"
                          [ngClass]="{
                            'btn-secondary':
                              item?.zatcaComplianced == false &&
                              item?.otp.length < 6,
                            'btn-primary cutom-button':
                              item?.otp.length === 6 &&  item?.zatcaComplianced == false
                          }"
                        >  {{'Verify' | translate}} </button>
                        </td>
                        <td>
                          <a *ngIf="item?.zatcaComplianced == true" (click)="openConfirmation(item)">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g style="mix-blend-mode: multiply">
                                <path
                                  d="M7.66602 14.3333C7.66602 14.3333 9.33683 12.0568 10.6942 10.6985C12.0516 9.34022 13.9273 8.5 15.9993 8.5C20.1415 8.5 23.4993 11.8579 23.4993 16C23.4993 20.1421 20.1415 23.5 15.9993 23.5C12.5801 23.5 9.69527 21.2119 8.79249 18.0833M7.66602 14.3333V9.33333M7.66602 14.3333H12.666"
                                  stroke="#020617"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </svg>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="col-lg-4 col-md-5 col-sm-12">
      <!-- Integration start -->
      <section class="tile">
        <div class="tile-header">
          <h6>{{ "Integration" | translate }}</h6>
        </div>
        <div class="tile-body">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{ "Location Key" | translate }} </label>
              <img
                src="../../../../../assets/images/icons/v2/Info.svg"
                class="mx-1"
              />
              <input
                type="text"
                class="form-control disabled"
                id="LocationKey"
                placeholder=""
                [value]="ZATCAComplianceData?.location.locationKey"
                readonly="readonly"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- Integration end -->
    </div>
  </div>
</div>
