<div class="row">
  <div class="col-lg-12 col-md-6 col-sm-12">
    <section class="tile">
      <div class="tile-header  pt-0">
        <!--<div class="row">
          <div class="col-lg-8 col-md-7 hide-mobile">
            <h6>{{'Location Items' | translate}}</h6>
          </div>
          <div class="col-lg-4 col-md-5 col-sm-12  position-relative">
            <span class="show-filter-icon float-left">
              <img class="dots-list" src="assets/images/search.svg" />
            </span>
            <input class="form-control search-filter" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
          </div>
        </div>-->


        <div class="d-flex header-list-topbar">
          <div class="hide-mobile me-auto p-2">  <h6 class="h6line-height">{{'Location Items' | translate}}</h6></div>
          <div class="p-2  position-relative">
            <span class="show-filter-icon float-left">
              <img class="dots-list" src="assets/images/search.svg" />
            </span>
            <input class="form-control search-filter" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
          </div>

        </div>

      </div>
      <div class="tile-body table-responsive text-nowrap">
        <!-- <div class="form-group form-inline" style="float: right; margin-top: 10px;  margin-right: 10px;">
          <input class="form-control ml-2" placeholder="Search" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </div> -->
        <table class="table table-striped">
          <thead>
            <tr class="table-header">
              <th width="5%">
                <!--<div [formGroup]="locationItemForm">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" formControlName="checkAll" />
                </div>-->
                <div class="form">
                  <div class="form-check form-switch float-start">
                    <input type="checkbox" class="form-check-input" [checked]="f.checkAll.value" (change)="bulkSelection($event)">
                  </div>
                </div>
              </th>
              <th width="5%"></th>
              <!--<th width="10%" sortable="category" (sort)="onSort($event)">{{'Category' | translate}} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>-->
              <th width="20%" sortable="name" (sort)="onSort($event)">{{'Name' | translate}} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%" sortable="nameOnReceipt" (sort)="onSort($event)">{{'Arabic Name' | translate}} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%">
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" ngbDropdownToggle>
                    <span>{{'SubCategory' | translate}}</span>
                    <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                       class="dropdown-header-filter-body overflow-scroll" style="height:300px;">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllsubCategory" (ngModelChange)="selectAllFilters($event,subCategoryList)">
                      <label class="form-check-label font-blue" (click)="selectAllFilters($event,subCategoryList)">
                        {{'All SubCategory' | translate}}
                      </label>
                    </div>
                    <div *ngFor="let category of subCategoryList">
                      <span> {{category.parentName}}</span>
                      <div *ngFor="let subCategory of category.subCat">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input"
                                 [(ngModel)]="subCategory.isChecked" (ngModelChange)="filters()">
                          <label class="form-check-label" (click)="filters()">
                            {{subCategory.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </th>
              <th width="15%" sortable="barcode" (sort)="onSort($event)">{{'Barcode' | translate}} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%" sortable="price" (sort)="onSort($event)">{{'Cashier Price' | translate}} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="10%">
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                    <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':(SelectedStatus == '2' ?'Inactive':'Customized'))) | translate}} </span>
                    <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                             name="statusOption" id="dropdownStatus_all" (change)="selectAllFilters($event,partnerStatusList)">
                      <label class="form-check-label font-blue" for="dropdownStatus_all"
                             (click)="selectAllFilters($event,partnerStatusList)">
                        {{'All Status' | translate}}
                      </label>
                    </div>
                    <div *ngFor="let item of partnerStatusList; let i = index">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                        <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                          <!-- {{item.name}} -->
                          <ngb-highlight *ngIf="item.name === 1"
                                         class="btn btn-sm btn-active" [result]="(item.name == 1 ? 'Active': item.name == 2?'Inactive':'Customized') | translate">
                          </ngb-highlight>
                          <ngb-highlight *ngIf="item.name === 2"
                                         class="btn btn-sm btn-inactive" [result]="(item.name == 1 ? 'Active': item.name == 2?'Inactive':'Customized') | translate">
                          </ngb-highlight>
                          <ngb-highlight *ngIf="item.name === 15"
                                         class="btn btn-sm btn-active btn-badge-purple" [result]="(item.name == 1 ? 'Active': item.name == 2?'Inactive':'Customized') | translate">
                          </ngb-highlight>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data$ | async ">
              <td>
                <!--<input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />-->
                <div class="form">
                  <div class="form-check form-switch float-start">
                    <input type="checkbox" class="form-check-input" [checked]="item.statusID === 1" (change)="toggleValue(item)">
                  </div>
                </div>
              </td>
              <td>
                <img *ngIf="item.itemImage !== ''" [src]="item.itemImage" class="table-img" alt="" />
                <div *ngIf="item.itemImage === ''" class="image-replace"></div>
              </td>
              <!--<td>
                <ngb-highlight *ngIf="selectedLang == 'en'" [result]="item.category" [term]="service.category"></ngb-highlight>
                <ngb-highlight *ngIf="selectedLang != 'en'" [result]="item.alternateCategoryName" [term]="service.alternateCategoryName"></ngb-highlight>
              </td>-->

              <td>
                <ngb-highlight [result]="item.name" [term]="service.name"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="item.nameOnReceipt" [term]="service.nameOnReceipt"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight *ngIf="selectedLang == 'en'" [result]="item.subCategory" [term]="service.subCategory"></ngb-highlight>
                <ngb-highlight *ngIf="selectedLang != 'en'" [result]="item.alternateSubCategoryName" [term]="service.alternateSubCategoryName"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="item.barcode" [term]="service.barcode"></ngb-highlight>
              </td>
              <td>
                <div class="d-flex justify-content-center">
                  <div>
                    <!--<span *ngIf="item.price !== null" class="original-price">{{item.originalPrice}}</span>-->
                    <input type="number" [disabled]="!item.selected" (keyup)="priceChange(item, $event)" pattern="/^\d*\.?\d{0,2}$/g" class="form-control-price float-start"
                           placeholder="{{item.originalPrice}}" [(ngModel)]="item.price" *ngIf="canPriceChange === true" />
                    <span class="px-2">
                      <img class="d-inline my-2 " role="button" src="assets/images/icons/v2/locationItemPriceIcon.svg" (click)="item.price=null" />
                    </span>
                    <!--[class.original-price]="item.price === null" name="name" [value]="item.price === null ? item.originalPrice : item.price"-->
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-center">
                  <img *ngIf="item.partnerStatusID === 1" class="d-inline" [ngClass]="{'opacity-50':!item.selected}" role="button" src="assets/images/icons/v2/enabledSetting.svg" (click)="item.selected && openSettings(item)" />
                  <img *ngIf="item.partnerStatusID === 2" class="d-inline" role="button" src="assets/images/icons/v2/disabledSetting.svg" (click)="item.selected && openSettings(item)"/>
                  <img *ngIf="item.partnerStatusID === 15" class="d-inline" [ngClass]="{'opacity-50':!item.selected}" role="button" src="assets/images/icons/v2/customizedSetting.svg" (click)="item.selected && openSettings(item)" />
                </div>
              </td>
            </tr>
            <!--<tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <img src="/assets/images/marn-logo.svg" alt="" />
              </td>
              <td>Burgers</td>
              <td>Beef</td>
              <td>Double Beef Burger</td>
              <td>Double Beef Burger</td>
              <td>
                <input type="text" class="form-control-price original-price" name="name" value="16.00" />
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <div class="image-replace"></div>
              </td>
              <td>Burgers</td>
              <td>Beef</td>
              <td>Double Beef Burger</td>
              <td>Double Beef Burger</td>
              <td>
                <span class="original-price">14.00</span>
                <input type="text" class="form-control-price" name="name" value="16.00" />
              </td>
            </tr>-->
          </tbody>
        </table>
      </div>
    </section>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 p-1">
        <button [disabled]="submit" class="btn btn-save" (click)="saveItems()" *hasPermission="['Locations','Items', 'Save']">
          <span *ngIf="submit" class="spinner-border spinner-border-sm mr-1"></span>
          {{'Save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
