import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare function ActiveBot(): any;
declare function RemoveBot(): any;
import * as CryptoJS from 'crypto-js';

declare const Intercom: any;

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {

  constructor() { }
  public LoadChatBot() {
    let scriptExists: boolean = false;
    let url = 'assets/chat_bot/salesiq.js';
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src === `${window.location.protocol}//${window.location.host}/${url}`)
        scriptExists = true;
    }
    if (scriptExists === false) {
      console.log('preparing to load chat bot...')
      let node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
    else {
      ActiveBot();
    }
  }
  public RemoveChatBot() {
    RemoveBot();
    //document.getElementsByClassName('zsiq_custommain')[0].classList.add('d-none');
  }




  generateHash(secretKey: string, userIdentifier: string): string {
    const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey);
    return CryptoJS.enc.Hex.stringify(hash);
  }
  public loadInterCom(name, email, contact, brand, location, noLocation, industry, usertype, companycode, url, userid) {
    
    const secretKey = 'MTJUDvjxug8RIB-KYPPrSALV5JvmqYxHo5OQa__4';
    const userIdentifier = userid.toString(); 
 
    Intercom('boot', {
      app_id: environment.intercom.appId,
      api_base: "https://api-iam.intercom.io",
      user_hash: this.generateHash(secretKey, userIdentifier),
      UserID:userid,
      user_id: userid,
      name: name,
      email: email,
      Mobile: contact,
      Brand: brand,
      Location: location,
      NoOfLocations: noLocation,
      Industry: industry,
      UserType: usertype,
      CompanyCode: companycode,
      CurrentUrl: url,
    });
  }
}
