import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from '../../_helpers/globals';
import { LocalStorageService } from '../../_services/local-storage.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { ImportManagerComponent } from '../import-manager/import-manager.component';
import { PaymentLinkProvidersComponent } from '../payment-link-providers/payment-link-providers.component';
import { ReceiptComponent } from '../receipt/receipt.component';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {

  constructor(
    private localStorageService: LocalStorageService,
    private trackEvents: TrackEventsService,
    private modalService: NgbModal,
  ) { }

  get industryURLName() {
    return myGlobals.industryName(this.localStorageService);
  }

  Setups = [
    {
      title: "General",
      isAvailable: 1,
      list: [
        {
          title: "Devices",
          detail: "Create and Manage POS, KDS, and CDS Devices",
          icon: "assets/images/icons/setups/device.svg",
          color: "#DFF1F2",
          url: "branddevices",
          permission: ['Setup', 'Devices'],
          isAvailable: 1,
          isFormPopup: false
        },
        {
          title: "Users",
          detail: "Add your team and manage Roles & Permissions",
          icon: "assets/images/icons/setups/users.svg",
          color: "#BAD9B8",
          url: "userroles",
          permission: ['Setup', 'Users'],
          isAvailable: 1,
          isFormPopup: false
        },
        {
          title: "Receipts",
          detail: "Design the custom Receipts of your locations",
          icon: "assets/images/icons/setups/receipt.svg",
          color: "#FEF4CB",
          url: "receipt",
          permission: ['Setup', 'Receipt'],
          isAvailable: 1,
          isFormPopup: true
        },
        {
          title: "Taxes",
          detail: "Apply Taxes on locations or Items",
          icon: "assets/images/icons/setups/tax.svg",
          color: "#BFCFDD",
          url: "tax/setup",
          permission: ['Setup', 'Taxes'],
          isAvailable: 1,
          isFormPopup: false
        },
        {
          title: "Custom Order Types",
          detail: "Create more orders and it's Types",
          icon: "assets/images/icons/setups/ordertypes.svg",
          color: "#CAB5D8",
          url: "orderType",
          permission: ['Setup', 'Order Types'],
          isAvailable: 1,
          isFormPopup: false
        },
        {
          title: "Payment Link",
          detail: "Manage your payment link providers",
          icon: "assets/images/icons/setups/offline_share.svg",
          color: "#9BCFD2",
          url: "paymentLink",
          permission: ['Setup', 'Payment Link'],
          isAvailable: 1,
          isFormPopup: true
        }

      ]
    },
    {
      title: "Advanced",
      isAvailable: 1,
      list: [
        {
          title: "Reasons",
          detail: "Create custom Reasons to track them on reports",
          icon: "assets/images/icons/setups/reason.svg",
          color: "#FCE7D5",
          url: "reasons",
          permission: ['Setup', 'Reasons'],
          isAvailable: 1,
          isFormPopup: false
        },
        {
          title: "Discounts",
          detail: "Create intuitive Discounts for your brand and items",
          icon: "assets/images/icons/setups/discount.svg",
          color: "#BFCFDD",
          url: "setup/discounts",
          permission: ['Quick Actions', 'Discounts'],
          isAvailable: 0,
          isFormPopup: false
        },
        {
          title: "Tags",
          detail: "Attach a tag to your items and orders to make custom reports",
          icon: "assets/images/icons/setups/tags.svg",
          color: "#EB9995",
          url: "",
          permission: ['Setup', 'Tags'],
          isAvailable: 0,
          isFormPopup: false
        },
        {
          title: "Marn Now",
          detail: "Request delivery on demand for your delivery orders",
          icon: "assets/images/icons/setups/marnnow.svg",
          color: "#BAD9B8",
          url: "",
          permission: ['Setup', 'Marn Now'],
          isAvailable: 0,
          isFormPopup: false
        },
        {
          title: "Universal Items",
          detail: "Keep your product catalog up to date",
          icon: "assets/images/icons/setups/uitems.svg",
          color: "#9BCED1",
          url: "",
          permission: ['Setup', 'Universal Items'],
          isAvailable: 0,
          isFormPopup: false
        },
        {
          title: "Import Manager",
          detail: "Import all of your menu items catalog at once using AI",
          icon: "assets/images/icons/setups/import_manager.svg",
          color: "#9BCED1",
          url: "importManager",
          permission: ['Setup', 'Import Manager'],
          isAvailable: 1,
          isFormPopup: true
        }

      ]
    }

  ];

  ngOnInit() {
  }


  openForm(data) {
    switch (data.url) {
      case 'receipt':
        const receipt = this.modalService.open(ReceiptComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
        this.trackEvents.Log_Screen("View Receipt");
        receipt.result.then((result) => {
        }, (reason) => {
        });
        break;

      case 'paymentLink':
        const payLink = this.modalService.open(PaymentLinkProvidersComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
        this.trackEvents.Log_Screen("View Payment Link");
        payLink.result.then((result) => {
        }, (reason) => {
        });
        break;
      case 'importManager':
        const importManager = this.modalService.open(ImportManagerComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
        this.trackEvents.Log_Screen("Import Manager");
        importManager.result.then((result) => {
        }, (reason) => {
        });
        break;
    }
    
  }
}
