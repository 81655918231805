import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JahezServiceService {

  constructor(private http: HttpClient) {
  }


  activateIntegration(data) {
    return this.http.post(`api/JAHEZ/V1/activateIntegration`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  syncPorducts(data) {
    return this.http.post(`api/JAHEZ/V1/products/pushMENU_JAHEZ_REWRITE`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  getIntegration(brandId: number, partnerId: number) {
    return this.http.get(`api/JAHEZ/brand/${brandId}/partner/${partnerId}`);
  }

  Uninstall(id) {
    return this.http.patch(`api/JAHEZ/uninstall/${id}`, null);

  }
}
