import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { ReasonDto } from '../../../_models/Reasons';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { ReasonService } from '../../../_services/reason.service';
import { TrackEventsService } from '../../../_services/track-events.service';
import { CreateReasonComponent } from '../create-reason/create-reason.component';

@Component({
  selector: 'app-void-reason-list',
  templateUrl: './void-reason-list.component.html',
  styleUrls: ['./void-reason-list.component.css']
})
export class VoidReasonListComponent implements OnInit {

  data$: Observable<ReasonDto[]>;
  submit: boolean;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private modalService: NgbModal,
    public service: ReasonService,
    private alertService: AlertService,
    private local: LocalStorageService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.loading$ = service.loading$;
    this.submit = false;
    this.getData();
  }

  ngOnInit() {
  }
  getData() {
    var brandID = this.local.getSelectedBrand().BrandID;
    this.service.headerFilter = { status: [''] };
    this.service.getAll(brandID, 3);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];

      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


      }
    });
  }

  openReason() {
    const modalRef = this.modalService.open(CreateReasonComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Reason");
    modalRef.componentInstance.SelectedID = 0
    modalRef.componentInstance.SelectedName = '';
    modalRef.componentInstance.reasonType = 3;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }
  delete(reason) {
    this.service.delete(parseInt(reason.reasonID), reason.rowVersion).subscribe((res: any) => {
      this.alertService.success("Reason has been Deleted");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  activate(reason) {
    let status = 1;
    let message = 'Reason has been Active';
    if (reason.statusID === 1) {
      status = 2;
      message = 'Reason has been Deactive';
    }
    else {
      status = 1;
      message = 'Reason has been Active';
    }
    this.service.activate(parseInt(reason.reasonID), status, reason.rowVersion).subscribe((res: any) => {
      this.alertService.success(message);
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription !== undefined)
      this.locationSubscription.unsubscribe();
  }
  getReason(reason) {
    const modalRef = this.modalService.open(CreateReasonComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Reason");
    modalRef.componentInstance.SelectedID = reason.reasonID;
    modalRef.componentInstance.SelectedName = reason.description;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  public openConfirmationDialog(reason) {
    this.confirmationDialogService.confirm('Please confirm..', reason.description)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(reason.reasonID), reason.rowVersion).subscribe((res: any) => {
            this.alertService.success("Reason has been Deleted");
            this.getData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';

  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]

    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}
