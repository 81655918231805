<form [formGroup]="msegatForm" class="form msegat" >

    <div class="modal-header" >
      <div class="d-flex" style="width: 100%;">

            <div class="d-flex align-items-start justify-content-start flex-1">
              <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>


            <div class="d-flex align-items-center justify-content-center flex-1">
              <h4 class="modal-title">
                {{'Msegat' | translate }}
              </h4>
            </div>


            <div class="d-flex align-items-end justify-content-end flex-1">
              <a class="btn-default btn-grey" href="https://www.msegat.com/" target="_blank">
                {{'Learn More' | translate }}
              </a>
              <button [disabled]="loading" class="btn-primary btn-save" [hidden]="f.msegatID.value >0" (click)="onSubmit()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ButtonText  | translate }}
              </button>
            </div>

      </div>
    </div>



    <div class="modal-body integration">
      <div class="container">
        <div class="row">

          <div class="col-lg-12" style="margin-top: 40px;">




            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="logo-placeholder">
                  <img class="" src="assets/images/marketplace/msegat.png" alt="Msegat">
                </div>
              </div>
            </div>

            <div class="line"></div>

            <h6 class="heading">{{'Setup' | translate }}</h6>

            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="Username">{{'Username' | translate }}</label>
                <input type="text" class="form-control" id=Username placeholder="" formControlName="Username">
                <div *ngIf="f.Username.invalid && (f.Username.dirty || f.Username.touched)" class="alert alert-danger">
                  <div *ngIf="f.Username.errors.required">
                    {{'Username is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="APIKey">{{'API Key' | translate }}</label>
                <input type="text" class="form-control" id=APIKey placeholder="" formControlName="APIKey">
                <div *ngIf="f.APIKey.invalid && (f.APIKey.dirty || f.APIKey.touched)" class="alert alert-danger">
                  <div *ngIf="f.APIKey.errors.required">
                    {{'APIKey is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="SenderName">{{'Sender Name' | translate }} </label>
                <input type="text" class="form-control" id=SenderName placeholder="" formControlName="SenderName">
                <div *ngIf="f.SenderName.invalid && (f.SenderName.dirty || f.SenderName.touched)" class="alert alert-danger">
                  <div *ngIf="f.SenderName.errors.required">
                    {{'Sender Name is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>


            <div class="line"></div>

            <h6 class="heading">{{'Content' | translate }}</h6>
            <div class="row">
              <div class="col-9">
                <label class="greeting-title"> {{'Customer Greeting' | translate }}</label>
              </div>
              <div class="col-3 text-right">
                <div class="custom-control custom-switch custom-switch-md">
                  <input type="checkbox" class="custom-control-input" id="isCustomerPurchase" formControlName="isCustomerPurchase">
                  <label class="custom-control-label" for="isCustomerPurchase"></label>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="form-group col-lg-9 col-sm-12" >
                <p class="greeting-desc">
                  {{'Send Message when a new order is issued for the customer' | translate }}
                </p>
              </div>
            </div>


            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="CustomerPurchaseMsg">{{'Description' | translate }} </label>
                <textarea type="text" rows="3" style="height: unset;" class="form-control message-format" id=CustomerPurchaseMsg placeholder="" formControlName="CustomerPurchaseMsg">
                  </textarea>
                <div *ngIf="f.CustomerPurchaseMsg.invalid && (f.v.dirty || f.CustomerPurchaseMsg.touched)" class="alert alert-danger">
                  <div *ngIf="f.CustomerPurchaseMsg.errors.required">
                    {{'Sender Name is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>


            <div class="row" >
              <div class="form-group col-lg-12 col-sm-12">
                <p class="greeting-desc" style="font-size: 12px;">
                  {{textSample | translate}}
                </p>
              </div>
            </div>






            <ng-container *hasPermission="['Marketplace','Uninstall']">
              <div class="line" *ngIf="f.integrationID.value >0"></div>

              <h6 class="heading" *ngIf="f.integrationID.value >0">{{'Danger zone' | translate }}</h6>

              <div class="row" style="margin-top: 30px;" *ngIf="f.integrationID.value >0">
                <div class="col-6">
                  <label>{{'Uninstall this App' | translate }} </label>
                </div>
                <div class="col-6 text-right">
                  <a class="btn btn-danger btn-uninstall" (click)="openConformation()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{'Uninstall' | translate }}
                  </a>
                </div>

              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </form>
