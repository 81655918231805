<form [formGroup]="expenseForm" class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" *ngIf="f.expenseID.value == 0"> {{ 'New Expense' | translate }} </h4>
    <h4 class="modal-title" *ngIf="f.expenseID.value != 0">{{ f.code.value}} </h4>
    <ng-container *ngIf="f.expenseID.value == 0">
      <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" (click)="onSubmit()" *hasPermission="['Analytics','Accounting','Expense','Create']">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ButtonText | translate}}
      </button>
    </ng-container>
    <ng-container *hasPermission="['Analytics','Accounting','Expense','Create']">
      <div [ngbPopover]="popContent" *ngIf="f.expenseID.value != 0 && f.statusID.value !== 3">
        <button class="btn btn-light m-0 me-4">
          <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
        </button>
      </div>
      <ng-template #popContent> 
        <a (click)="openPDF()">{{'Print' | translate }}</a>
        <a (click)="void()" class="selected-inner-text color-danger border-0 m-0 py-2">{{'Void' | translate }}</a>
      </ng-template>
    </ng-container>
  </div>
  <div class="modal-body">
    <div class="row error-red-background align-items-center" *ngIf="f.statusID.value === 3" style="height:70px">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <span class="fw-bold">
          <img src="/assets/images/icons/VoidStatus.svg" />
          {{'Expense is Void' | translate }}
        </span>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Expense Name' | translate }}</label>
              <input type="text" class="form-control" id=name placeholder="" formControlName="name" [ngClass]="{'disabled': f.expenseID.value != 0}"
                     [readonly]="f.expenseID.value != 0" pattern="^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){3,140}$">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is Required' | translate }}
                </div>
                <div *ngIf="f.name.invalid">{{'Name format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="expDate">{{'Due Date' | translate }}</label>
              <ngbd-datepicker-adapter [AcceptPreviousDate]="true" [AllowEmptyDate]="true" [AcceptFutureDate]="true" #maturityDate [Disable]="f.expenseID.value != 0 ? true : false"></ngbd-datepicker-adapter>
              <div *ngIf="f.referenceNo.touched && !maturityDate.model" class="alert alert-danger">
                {{'Date is Required' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="expDate">{{'Generation Date' | translate }}</label>
              <ngbd-datepicker-adapter [AcceptPreviousDate]="true" [AcceptFutureDate]="false" #generationDate [Disable]="f.expenseID.value != 0 ? true : false"></ngbd-datepicker-adapter>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="expenseTo">{{'Expense To' | translate }}</label>
              <input type="text" class="form-control" id="expenseTo" placeholder="" formControlName="expenseTo" [ngClass]="{'disabled': f.expenseID.value != 0}" [readonly]="f.expenseID.value != 0"
                     pattern="^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,140}$">
              <div *ngIf="f.expenseTo.invalid && (f.expenseTo.dirty || f.expenseTo.touched)" class="alert alert-danger">
                <div *ngIf="f.expenseTo.errors.required">
                  {{'Expense To is Required' | translate }}
                </div>
                <div *ngIf="f.expenseTo.invalid">{{'Expense To format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="referenceNo">{{'Reference Number' | translate }}</label>
              <input type="text" class="form-control" id="referenceNo" placeholder="" formControlName="referenceNo" [ngClass]="{'disabled': f.expenseID.value != 0}" [readonly]="f.expenseID.value != 0"
                     pattern="^.{0,140}$">
              <div *ngIf="f.referenceNo.invalid && (f.referenceNo.dirty || f.referenceNo.touched)" class="alert alert-danger">
                <div *ngIf="f.referenceNo.errors.required">
                  {{'Reference No is Required' | translate }}
                </div>
                <div *ngIf="f.referenceNo.invalid">{{'Reference Number format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'Location' | translate }}</label>
              <ng-select #select [items]="Locations" bindLabel="name" bindValue="locationID" [multiple]="true" placeholder="" [disabled]="f.expenseID.value != 0"
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationIds">

                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('locations')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>


                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>

                </ng-template>
              </ng-select>

            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Notes">{{'Notes' | translate }}</label>
              <textarea class="form-control mh-80 h-75" formControlName="note" [ngClass]="{'disabled': f.expenseID.value != 0}" [readonly]="f.expenseID.value != 0"></textarea>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-12 col-sm-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <label for="Conversion">{{'Add Expense Item' | translate }}</label>
          </div>
          <section class="tile" style="border:none;box-shadow:none">
            <div class="p-0 table-responsive ">
              <table class="table table-striped po-item">
                <thead>
                  <tr class="table-header">
                    <th scope="col" width="35%" class="border-radius-topleft-10">{{'Expense Item' | translate }}</th>
                    <th scope="col" width="20%" class="border-start-width-0 border-end-width-0">{{'Quantity' | translate }}</th>
                    <th scope="col" width="20%" class="border-end-width-0">{{'Amount' | translate }}</th>
                    <th scope="col" width="5%" class="border-start-width-0 border-end-width-0"></th>
                    <th scope="col" width="20%" class="border-end-width-0">{{'Total Amount' | translate }}</th>
                    <th scope="col" width="3%" class="border-start-width-0 border-end-width-0"></th>
                    <th scope="col" width="5%" class=""></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of arrItems">
                    <td scope="row" class="font-weight-bold">
                      <input type="text" [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}" [readonly]="f.expenseID.value != 0"
                             (ngModelChange)="updateRow(item)" class="form-control" [ngClass]="{'border-red' : expenseForm.touched && item.name == '','disabled': f.expenseID.value != 0}">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}" [readonly]="f.expenseID.value != 0"
                             (ngModelChange)="updateRow(item)" class="form-control-price w-75" [ngClass]="{red : expenseForm.touched && item.quantity <= 0,'disabled': f.expenseID.value != 0}">
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="item.amount" [ngModelOptions]="{standalone: true}" [readonly]="f.expenseID.value != 0"
                             (ngModelChange)="updateRow(item)" class="form-control-price  w-75" [ngClass]="{red : expenseForm.touched && item.amount <= 0,'disabled': f.expenseID.value != 0}">
                    </td>
                    <td>
                      <span>{{brandCurrency | translate }}</span>
                    </td>
                    <td>
                      <span class="inv-amountbox float-end w-75">{{item.totalAmount | number : '1.2-2'}}</span>
                    </td>
                    <td>
                      {{brandCurrency | translate }}
                    </td>
                    <td>
                      <a *ngIf="f.expenseID.value == 0"><img src="/assets/images/icons/v2/Delete.svg" (click)="deleteRow(item)" /></a>
                    </td>
                  </tr>
                  <tr *ngIf="arrItems.length === 0">
                    <td colspan="10">
                      <div class="row p-1">
                        <div class="col-12 text-center">
                          <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <div class="input-group form-group grayBox rounded" role="button" *ngIf="f.expenseID.value == 0">
            <div class="input-group-append w-100" (click)="addItem()">
              {{'+ Add Expense' | translate }}
            </div>
          </div>

          <div class="row grayBox m-1" *ngIf="arrItems.length > 0">
            <div class="form-group col-lg-12 col-sm-12">
              <div class="bg-summary row align-items-center h-25">
                <div class="col-lg-9 col-sm-9 col-9 fw-bold">
                  {{'Subtotal' | translate }}
                </div>
                <div class="col-lg-3 col-sm-3 col-3 fw-bold">
                  <span class="float-end">
                    {{f.subTotal.value | number : '1.2-2'}} {{brandCurrency | translate }}
                  </span>
                </div>
              </div>
              <ng-container>
                <div class=" bg-summary row align-items-center" *ngFor="let tax of arrtaxes">
                  <div class="col-lg-9 col-sm-9 col-9">
                    <div class="row align-items-center pb-1">
                      <div class="col-sm-4 col-4">
                        {{tax.tax | translate }} ({{tax.percentage}}%)
                      </div>
                      <div class="col-sm-8 col-8">
                        <select class="custom-select form-control" (change)="CalculateSummary()" [ngModelOptions]="{standalone: true}" [(ngModel)]="tax.type" [ngClass]="{'disabled': f.expenseID.value != 0}" [disabled]="f.expenseID.value != 0">
                          <option [ngValue]="option.id" *ngFor="let option of taxTypes">
                            {{option.name  | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-3 col-3 float-end">
                    <label for="total" [ngClass]="{'text-decoration-line-through': tax.type == '1'}" class="float-end">
                      {{tax.amount | number : '1.2-2'}} {{brandCurrency | translate }}
                    </label>
                  </div>
                </div>
              </ng-container>
              <div class="bg-summary row align-items-center" style="background: #EFF3F5">
                <div class="col-lg-9 col-sm-9 col-9 fw-bold">
                  {{'Total' | translate }}
                </div>
                <div class="col-lg-3 col-sm-3 col-3 float-end fw-bold">
                  <span class="float-end">
                    {{f.grandTotal.value | number : '1.2-2'}} {{brandCurrency | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-3" [hidden]="f.expenseID.value != 0">
            <div class="form-group col-lg-12 col-sm-12">
              <label>{{'Attachment' | translate }}</label>
              <app-upload-file [Disable]="f.expenseID.value != 0"></app-upload-file>
            </div>
          </div>
          <div class="row pt-3 mx-1" *ngIf="f.attachment.value && f.expenseID.value != 0">
            <label>{{'Attachment' | translate }}</label>
            <div class="form-group col-lg-6 col-sm-6">
              <div class="row align-items-center border rounded p-2">
                <div class="col-lg-2 col-sm-2">
                  <img src="assets/images/icons/File_upload_icon.svg" class="float-start" *ngIf="f.attachment.value.split('/').pop().split('.').pop().toLowerCase() == 'pdf'"/>
                  <img src="assets/images/icons/Image_upload_icon.svg" class="float-start" *ngIf="f.attachment.value.split('/').pop().split('.').pop().toLowerCase() !== 'pdf'"/>
                </div>
                <div class="col-lg-8 col-sm-8">
                  <label>{{f.attachment.value.split('/').pop()}}</label>
                </div>
                <div class="col-lg-2 col-sm-2">
                  <a [href]="f.attachment.value" role="button" [target]="_blank">
                    <img src="assets/images/icons/v2/export.svg" class="float-end rotate-180" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="f.expenseID.value != 0">
            <hr />
            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="fw-bold text-center">{{'Logs' | translate }}</div>
                <p class="text-center">{{'Activities on this Expense' | translate }}</p>
              </div>
            </div>
            <div class="row" *ngFor="let log of f.logs.value">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="row g-0 d-flex">
                  <div class="col-lg-1 col-sm-1 col">
                    <img src="../../../assets/images/icons/LogCreated.svg" class="pe-1" *ngIf="!log.action.includes('voided')" />
                    <img src="../../../assets/images/icons/Void_Sign.svg" class="pe-1" *ngIf="log.action.includes('voided')" />
                  </div>
                  <div class="col-6 text-break" [ngClass]="log.action.includes('voided') ? 'col-lg-8 col-sm-8' : 'col-lg-6 col-sm-6'">
                    <span class="fw-bold">{{log.action | translate}}</span>&nbsp;
                    <span>{{log.user}}&nbsp;</span>
                    <span class="fw-bold m-0" *ngIf="log.action.includes('voided')"> {{'Due to' | translate}}</span>&nbsp;
                    <span *ngIf="log.action.includes('voided')">{{log.voidReason}}</span>
                  </div>
                  <!--<div class="fw-bold" [ngClass]="log.action.includes('voided') ? 'col-lg-2 col-sm-2 col' : 'col-lg-4 col-sm-4 col'">
                    <label class="m-0">{{log.user}}</label>
                  </div>
                  <div class="fw-bold col-lg-1 col-sm-1 col" *ngIf="log.action.includes('voided')">
                    {{'Due to' | translate}}
                  </div>
                  <div class="col-lg-3 col-sm-3 col text-break" *ngIf="log.action.includes('voided')">
                    {{log.voidReason}}
                  </div>-->
                  <div [ngClass]="log.action.includes('voided') ? 'col-lg-3 col-sm-3 col' : 'col-lg-5 col-sm-5 col'">
                    <span class="float-end">
                      {{log.date | date:"dd MMM yyyy  hh:mm a"}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </div>
</form>

<app-expense-pdf *ngIf="export === true" [Data]="PDFExpenseData" (html)="receive($event)"></app-expense-pdf>
