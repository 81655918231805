<form [formGroup]="conversionForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title-lg">
      {{ 'Conversion Calculator' | translate}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-10">
        <div class="col-lg-10 form-group text-center">
          <label for="bulkQuantity">{{'How many Bulks' | translate }}</label>
          <input type="number" class="form-control" id="bulkQuantity" placeholder="" formControlName="bulkQuantity">
          <div *ngIf="f.bulkQuantity.invalid && (f.bulkQuantity.dirty || f.bulkQuantity.touched)" class="alert alert-danger">
            <div *ngIf="f.bulkQuantity.errors.required">
              {{'Required' | translate }}.
            </div>
          </div>
        </div>
        <div class="col-lg-10 form-group text-center">
          <label for="itemBulk">{{'Items Per Bulk' | translate}}</label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" formControlName="itemBulk" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            <div class="input-group-append">
              <span class="input-group-text" id="inputGroup-sizing-default">{{selectedItem.unit | translate}}</span>
            </div>
          </div>
          <div *ngIf="f.itemBulk.invalid && (f.itemBulk.dirty || f.itemBulk.touched)" class="alert alert-danger">
            <div *ngIf="f.itemBulk.errors.required">{{'Required' | translate}}. </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
      <hr />
      <div style="justify-content:normal">
        <div class="row align-items-center">
          <div class="col-lg-6 col-4 px-4">
            <strong><label>{{'Subtotal' | translate}}: {{(selectedItem.cost !== null && selectedItem.cost !== undefined) ? selectedItem.cost *(f.bulkQuantity.value * f.itemBulk.value): f.bulkQuantity.value * f.itemBulk.value}} {{'SAR' | translate}}</label></strong>
          </div>
          <div class="col-lg-2 col-3 px-0">
            <button type="button" class="btn btn-cancel" (click)="activeModal.close('Close click')">{{'Dismiss' | translate }}</button>
          </div>
          <div class="col-lg-4 col-5">
            <button class="btn btn-primary cutom-button custom-create-btn w-100 float-end">
              {{'Save'  | translate }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
