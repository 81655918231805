import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../_services/alert.service';
import { LocalStorageService } from '../../../../_services/local-storage.service';
import { TillService } from '../../../../_services/till.service';

@Component({
  selector: 'app-till-account-view',
  templateUrl: './till-account-view.component.html',
  styleUrls: ['./till-account-view.component.css']
})
export class TillAccountViewComponent implements OnInit {
  data: any;
  @Input() SelectedID = 0;
  loading = false;
  selctedLang = 'en';
  constructor(private alertService: AlertService,
    public service: TillService,
    public activeModal: NgbActiveModal,
    public localStorageService: LocalStorageService  ) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
  }

  ngOnInit(): void {
    this.get();
  }
  get() {
    if (this.SelectedID !== 0) {
      this.loading = true;
      this.service.get(this.SelectedID).subscribe(res => {
        this.data = res;
        this.loading = false;
      });
    }
  }
}
