import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { AlertService } from 'src/app/_services/alert.service';
import { InventoryLogReportService } from 'src/app/_services/inventorylog.report.service';
import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import * as $ from 'jquery';
import { SalesDetailReportService } from '../../_services/sales-detail-report.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { DailySalesService } from '../../_services/daily-sales.service';

@Component({
  selector: 'app-printdailysales',
  templateUrl: './printdailysales.component.html',
  styleUrls: ['./printdailysales.component.css']
})
export class PrintdailysalesComponent implements OnInit {
  loading$: Observable<boolean>;
  selectedReport: any;
  selectedBrand: any;
  dailySales: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: DailySalesService,
    private router: Router,
    private printService: PrintService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService) {
    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
  }


  ngOnInit(): void {
    if (this.selectedReport !== null && this.selectedReport !== undefined) {
      this.getReportData(this.selectedReport.dataURL);
      this.selectedBrand = this.localStorageService.getSelectedBrand();
    }
  }

  getReportData(dataURL) {

    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataURL,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {
          _this.dailySales = res;
          _this.dailySales.forEach(e => {
            e.Sales = e.GrandTotal - e.Tax
          });
          console.log(_this.dailySales);
          _this.printService.onDataReady();

        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })



  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }
}
