<form [formGroup]="supplierForm" class="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Supplier' :SelectedName) | translate}} </h4>
        <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Inventory','Inventory Setup','Supplier', (SelectedID > 0) ? 'Edit' : 'Insert']">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate}}
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4 mt-5">
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="name">{{'Name' | translate }} </label>
                      <input type="text" class="form-control" id=name placeholder="" formControlName="name">
                      <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched) " class="alert alert-danger">
                        <div *ngIf="f.name.errors.required">
                          {{'Name is required' | translate }}.
                        </div>
                        <!-- <div *ngIf="f.name.invalid"> Specical characters not allowed.</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="email">{{'Email' | translate }} </label>
                      <input type="text" class="form-control" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" id=email placeholder="" formControlName="email">
                      <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger">
                        <div *ngIf="f.email.invalid"> {{'Email Address is invalid' | translate }}. </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="phone">{{'Phone Number' | translate }} </label>
                      <input type="text" (keypress)="onlyNumberKey($event)" class="form-control" placeholder="" formControlName="contactNo">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="License">{{'Country' | translate }} </label>
                      <select class="custom-select form-control" formControlName="countryID" (change)="loadCity()">

                        <option [ngValue]="option.code" [disabled]=option.maped
                                selected="option.code == countryID" *ngFor="let option of Countries">
                          {{option.name}}
                        </option>
                      </select>
                      <div *ngIf="f.countryID.invalid && (f.countryID.dirty || f.countryID.touched) " class="alert alert-danger">
                        <div *ngIf="f.countryID.errors.required">
                          {{'Country is required' | translate }}.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="License">{{'City' | translate }} </label>
                      <select class="custom-select form-control" formControlName="cityID">

                        <option [ngValue]="option.id" [disabled]=option.maped
                                selected="option.id == cityID" *ngFor="let option of Cities">
                          {{option.name}}
                        </option>
                      </select>
                      <div *ngIf="f.cityID.invalid && (f.cityID.dirty || f.cityID.touched) " class="alert alert-danger">
                        <div *ngIf="f.cityID.errors.required">
                          {{'City is required' | translate }}.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12 col-sm-12">
                      <label for="Notes">{{'Notes' | translate }} </label>
                      <textarea class="form-control mh-80 h-75" formControlName="notes"></textarea>
                    </div>
                  </div>
                  <!--<div class="row">
      <div class="col-lg-12 col-sm-12">
        <label for="Item Status">{{'Supplier Status' | translate }} </label>
          <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" checked id="statusID" formControlName="statusID">
              <label class="form-check-label" for="statusID"></label>
          </div>
      </div>
  </div>-->
                  <div class="row">
                    <div class="form-group  col-lg-12">
                      <div class="status-activebar">
                        <label for="Status">

                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          {{'Supplier Status.' | translate }}
                        </label>

                        <div class="form-check form-switch">
                          <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                          <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4"></div>
            </div>
        </div>
    </div>
</form>
