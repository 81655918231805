import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionConfirmationComponent } from '../action-confirmation/action-confirmation.component';
import { ConfirmationDialogueComponent } from '../confirmation-dialogue/confirmation-dialogue.component';

@Injectable({
  providedIn: 'root'
})
export class ActionConfirmationService {


  constructor(private modalService: NgbModal) { }

  public confirm(title: string, name: string, dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ActionConfirmationComponent, { size: dialogSize, windowClass: 'small-modal', centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.name = name;
    return modalRef.result;
  }
  public confirmationDialogue(title: string, message: string, buttonText: string, deleteDialogue :boolean, dialogSize: 'sm' | 'lg' = 'lg'): Promise<any> {
    const modalRef = this.modalService.open(ConfirmationDialogueComponent, { size: dialogSize, windowClass: 'small-modal', centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.buttonText = buttonText;
    modalRef.componentInstance.deleteDialogue = deleteDialogue;
    return modalRef.result;
  }
}
