<form [formGroup]="reasonForm" class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Reason' :SelectedName) | translate }}  </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" (click)="onSubmit()" *hasPermission="['Setup','Reasons',(reasonType === 1) ? 'Refund' : (reasonType === 2) ? 'Complementary' :'Void', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="roleName">{{'Reason Name' | translate }}</label>
              <textarea class="form-control mh-80" formControlName="description"></textarea>
              <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)" class="alert alert-danger">
                <div *ngIf="f.description.errors.required">
                  {{'Reason is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'Reason Type' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="reasonTypeID">
                <option [ngValue]="option.id" selected="option.id == id" *ngFor="let option of ReasonType">
                  {{option.name | translate}}
                </option>
              </select>
              <div *ngIf="f.reasonTypeID.invalid && (f.reasonTypeID.dirty || f.reasonTypeID.touched)" class="alert alert-danger">
                <div *ngIf="f.reasonTypeID.errors.required">
                  {{'Reason type is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Applicable on' | translate }}</label>
              <ng-select [items]="LocationsList" bindLabel="name" bindValue="locationID" [closeOnSelect]="false" [multiple]="true" placeholder=""
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedLocationIds" formControlName="location">
                <ng-template ng-header-tmp>
                  <button class="dropdown-item"
                          (click)="onSelectAll('location')">
                    {{'Select All' | translate }}
                  </button>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item"
                             let-index="index" let-item$="item$">
                  <input type="checkbox" class="form-check-inline" [checked]="item$.selected" />
                  <label>
                    {{item.name}}
                  </label>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
