  <div class="modal-header">
    <h4 class="modal-title">{{'Import Inventory' | translate}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group files" *ngIf="showError === false">
            <input type="file" (change)="onFileChange($event)" class="form-control" accept=".xls, .xlsx" #itemFile (click)="itemFile.value = null" value="" />
          </div>
          <div *ngIf="showError">
            <ul>
              <li *ngFor="let option of errorList">
                <span class="caret">{{'Error at Row No' | translate}} <b>{{option.rowNo | translate}} </b></span>
                <ul class="nested">
                  <li> {{option.message | translate}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <hr />
          <div style="justify-content:normal" *ngIf="showError === false">
            <div class="row">
              <div class="col-lg-9">
                <span *ngIf="invalidFile === true" class="text-danger">{{'Invalid file extension' | translate}}. </span>
              </div>
              <div class="col-lg-3">
                <button class="btn btn-primary cutom-button" (click)="onSubmit()" [disabled]="loading || invalidFile">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'Upload' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
