<form class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()" [ngClass]="selctedLang == 'en' ? 'me-5' : 'ms-5'">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{'Till Record' | translate }} ({{'Device id:' + data?.deviceID}})
    </h4>
  </div>
  <div class="modal-body">
    <div class="container till-preview">
      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <div class="tiltle-bar mt-5">
            <div class="col-lg-12">
              <label class="highlighted-text device-name">{{data?.terminalName}}</label>
              <span class="main-text">
                {{data?.refNumber}}
              </span>
            </div>
          </div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text">{{'Record Code' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text">{{data?.code}} </label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text fw-bold">{{'Started By' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text fw-bold">{{data?.startedBy}} </label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text">{{'Start Time' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text">{{data?.starting | date:'MM/dd/yyyy - h:mm a'}} </label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text">{{'Starting Cash' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text" *ngIf="data?.startingCash">{{data?.startingCash | number:'1.2-2' }}</label>
              <label class="main-text" *ngIf="!data?.startingCash">{{'-' }}</label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text">{{'Cash Sales' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text" *ngIf="data?.cashSales">{{data?.cashSales | number:'1.2-2' }}</label>
              <label class="main-text" *ngIf="!data?.cashSales">{{'-' }}</label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text">{{'Pay In' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text" *ngIf="data?.payIn">({{data?.payInCount}}) {{data?.payIn | number:'1.2-2' }}</label>
              <label class="main-text" *ngIf="!data?.payIn">{{'-' }}</label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text text-danger">{{'Cash Refunds' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text text-danger" *ngIf="data?.cashRefund">{{data?.cashRefund | number:'1.2-2' }}</label>
              <label class="main-text text-danger" *ngIf="!data?.cashRefund">{{'-' }}</label>
            </div>
          </div>
          <div class="line-seprator"></div>
          <div class="row py-1 mt-2">
            <div class="col-lg-6 col-5">
              <label class="main-text text-danger">{{'Pay Out' | translate}}</label>
            </div>
            <div class="col-lg-6 col-7 move-to-end">
              <label class="main-text text-danger" *ngIf="data?.payOut">({{data?.payOutCount}}) {{data?.payOut | number:'1.2-2' }}</label>
              <label class="main-text text-danger" *ngIf="!data?.payOut">{{'-' }}</label>
            </div>
          </div>

          <!-- When Record is Closed -->
          <ng-container *ngIf="data?.statusID === 902">
            <div class="line-seprator"></div>
            <div class="row py-1 mt-2">
              <div class="col-lg-6 col-6">
                <label class="main-text">{{'Expected in Drawer' | translate}}</label>
              </div>
              <div class="col-lg-6 col-6 move-to-end">
                <label class="main-text" *ngIf="data?.expectedCash">{{data?.expectedCash | number:'1.2-2' }}</label>
                <label class="main-text" *ngIf="!data?.expectedCash">{{'-' }}</label>
              </div>
            </div>
            <div class="line-seprator"></div>
            <div class="row py-1 mt-2">
              <div class="col-lg-6 col-5">
                <label class="main-text">{{'Actual in Drawer' | translate}}</label>
              </div>
              <div class="col-lg-6 col-7 move-to-end">
                <label class="main-text" *ngIf="data?.actualCash">{{data?.actualCash | number:'1.2-2' }}</label>
                <label class="main-text" *ngIf="!data?.actualCash">{{'-' }}</label>
              </div>
            </div>
            <div class="line-seprator"></div>
            <div class="row py-1 mt-2">
              <div class="col-lg-6 col-5">
                <label class="main-text fw-bold">{{'Difference' | translate}}</label>
              </div>
              <div class="col-lg-6 col-7 move-to-end">
                <label class="main-text fw-bold" *ngIf="data?.difference">{{data?.difference | number:'1.2-2' }}</label>
                <label class="main-text fw-bold" *ngIf="!data?.difference">{{'-' }}</label>
              </div>
            </div>
            <div class="line-seprator"></div>
            <div class="row py-1 mt-2">
              <div class="col-lg-6 col-5">
                <label class="main-text fw-bold">{{'Closed By' | translate}}</label>
              </div>
              <div class="col-lg-6 col-7 move-to-end">
                <label class="main-text fw-bold">{{data?.closedBy}} </label>
              </div>
            </div>
            <div class="line-seprator"></div>
            <div class="row py-1 mt-2">
              <div class="col-lg-6 col-5">
                <label class="main-text fw-bold">{{'Closed Time' | translate}}</label>
              </div>
              <div class="col-lg-6 col-7 move-to-end">
                <label class="main-text fw-bold">{{data?.ending | date:'MM/dd/yyyy - h:mm a'}} </label>
              </div>
            </div>
          </ng-container>
          <!-- When Record is Closed -->
          <ng-container>
            <h2 class="tiltle-bar">
              {{'Till Record Movement' | translate}}
            </h2>
            <div class="row py-1 mt-2" *ngFor="let item of data?.activity">
              <div class="col-lg-8 col-12">
                  <label class="highlighted-text username">
                    {{item?.createdBy}}
                    <span class="btn btn-sm w-auto btn-badge-purple {{item?.cssClass}}" style="border-radius: 6px;">{{item?.groupName }}</span>
                  </label>
                  <label class="main-text pt-1">{{item?.dateTime | date:'MM/dd/yyyy - h:mm a'}}</label>
                  <ngb-highlight *ngIf="item?.note && item?.note !== ''" class="btn-badge-yellow btn btn-sm rounded my-2" [result]="'Note: '+item?.note">
                  </ngb-highlight>
              </div>
              <div class="col-lg-4 col-12 move-to-end">
                <label class="main-text p-1" *ngIf="item?.amount">{{(item?.typeID === 13) ?'+' :(item?.typeID === 14)?'-':''}} {{item?.amount | number:'1.2-2' }} SAR</label>
                <label class="main-text p-1" *ngIf="!item?.amount">{{'-' }}</label>
                <ngb-highlight *ngIf="item?.typeID === 13" class="btn-active btn btn-sm m-0" [result]="'Pay In' | translate">
                </ngb-highlight>
                <ngb-highlight *ngIf="item?.typeID === 14" class="btn-inactive btn btn-sm m-0" [result]="'Pay Out' | translate">
                </ngb-highlight>
                <ngb-highlight *ngIf="item?.typeID === 11" class="btn-badge-yellow btn btn-sm m-0" [result]="'Opening' | translate">
                </ngb-highlight>
                <ngb-highlight *ngIf="item?.typeID === 12" class="btn-badge-blue btn btn-sm m-0" [result]="'Close' | translate">
                </ngb-highlight>
              </div>
              <div class="line-seprator mt-1"></div>
            </div>
          </ng-container>
        </div>
        <div class="col-sm-4"></div>
      </div>

    </div>
  </div>
</form>
