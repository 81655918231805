import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserinfoService {

  static SelectedBrand :any;
  static SelectedLocation :any;

   getUserInfo() {
    var promise = new Promise((resolve, reject) => {
      
     let userInfo = JSON.parse(sessionStorage.getItem("currentUser"));
      if(userInfo !==null){
        userInfo = JSON.parse(userInfo.data);
        resolve(userInfo);
      }else{
        reject("No Data Found");
      }
      
    });
    return promise;
  }
}


