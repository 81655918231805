import { Directive, Input } from '@angular/core';
import { TrackEventsService } from '../_services/track-events.service';

@Directive({
  selector: '[LogEvent]'
})
export class LogEventDirective {
  eventType: string = null;
  name: string = null;
  constructor(private trackEvents: TrackEventsService) { }

  @Input()
  set LogEventType(eventType: string) {
    this.eventType = eventType;
  }
  @Input()
  set LogEvent(name: string) {
    this.name = name;
    this.SaveEvent();
  }
  SaveEvent() {
    if (this.eventType && this.name && this.eventType === 'screen')
      this.trackEvents.Log_Screen(this.name);
  }

}
