import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { LoginComponent } from './login/login.component';
import { PasswordDirective } from './password.directive';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RetailComponent } from './retail/retail.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AuthGuard } from './_helpers/auth.guard';
import { AlertComponent } from './alert/alert.component';
import { ToastComponent } from './toast/toast.component';
import { SalesAnalyticsComponent } from './dashboard/sales-analytics/sales-analytics.component';
import { SalesSummaryComponent } from './sales-summary/sales-summary.component';
import { TodaySummaryComponent } from './dashboard/today-summary/today-summary.component';
import { StickyHeaderDirective } from './_directives/sticky-header.directive';
import { LocationComponent } from './setup/location/location.component';
import { AddlocationComponent } from './setup/location/addlocation/addlocation.component';
import { LocationItemsComponent } from './setup/location-items/location-items.component';
import { DevicesComponent } from './setup/devices/devices.component';
import { AdddeviceComponent } from './setup/devices/adddevice/adddevice.component';
import { FloorComponent } from './setup/floor/floor.component';
import { AddfloorComponent } from './setup/floor/addfloor/addfloor.component';
import { TablesComponent } from './setup/tables/tables.component';
import { AddtableComponent } from './setup/tables/addtable/addtable.component';
import { BrandDevicesComponent } from './brand-devices/brand-devices.component';
import { DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbdSortableHeader } from './_directives/sortable.directive';
import { AddbranddeviceComponent } from './brand-devices/addbranddevice/addbranddevice.component';
import { AdditemComponent } from './products/items/additem/additem.component';
import { ItemsComponent } from './products/items/item.component';
import { ImageuploadComponent } from './imageupload/imageupload.component';
import { CategoryComponent } from './products/category/category.component';
import { AddcategoryComponent } from './products/category/addcategory/addcategory.component';
import { SubcategoryComponent } from './products/subcategory/subcategory.component';
import { AddsubcategoryComponent } from './products/subcategory/addsubcategory/addsubcategory.component';
import { SupplierComponent } from './inventory/supplier/supplier.component';
import { AddsupplierComponent } from './inventory/supplier/addsupplier/addsupplier.component';
import { ModifierComponent } from './products/modifier/modifier.component';
import { AddmodifierComponent } from './products/modifier/addmodifier/addmodifier.component';
import { AddgroupComponent } from './inventory/group/addgroup/addgroup.component';
import { GroupComponent } from './inventory/group/group.component';
import { IngredientComponent } from './inventory/ingredient/ingredient.component';
import { AddingredientComponent } from './inventory/ingredient/addingredient/addingredient.component';
import { AddrecipeComponent } from './inventory/recipe/addrecipe/addrecipe.component';
import { RecipeComponent } from './inventory/recipe/recipe.component';
import { LocationInventoryComponent } from './setup/location-inventory/location-inventory.component';
import { PurchaseorderComponent } from './inventoryaction/purchaseorder/purchaseorder.component';
import { AddpurchaseorderComponent } from './inventoryaction/purchaseorder/addpurchaseorder/addpurchaseorder.component';
import { PreviewpurchaseorderComponent } from './inventoryaction/purchaseorder/previewpurchaseorder/previewpurchaseorder.component';
import { ReceivepreviewpoComponent } from './inventoryaction/purchaseorder/receivepreview-po/receivepreview-po.component';
import { ReceivepurchaseorderComponent } from './inventoryaction/purchaseorder/receivepurchaseorder/receivepurchaseorder.component';
import { NgbdDatepickerAdapter } from './datepicker/datepicker-adapter';
import { PrintPoComponent } from './print-templates/print-po/print-po.component';
import { PrintTransferComponent } from './print-templates/print-to/print-to.component';
import { SalesReportComponent } from './reports/salesreport/salesreport.component';
import { GeneratedreportComponent } from './reports/generatedreport/generatedreport.component';
import { PreviewreportComponent } from './reports/previewreport/previewreport.component';
import { InventorysetupComponent } from './inventory/inventorysetup.component';
import { InventoryactionComponent } from './inventoryaction/inventoryaction.component';
import { PrintService } from './_services/print.service';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { AddpurchasereturnComponent } from './inventoryaction/purchaseorder/purchasereturn/addpurchasereturn/addpurchasereturn.component';
import { PurchasereturnComponent } from './inventoryaction/purchaseorder/purchasereturn/purchasereturn.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PrepsComponent } from './inventoryaction/preps/preps.component';
import { AddprepsComponent } from './inventoryaction/preps/addpreps/addpreps.component';
import { NgbdDatepickerRangePopup } from './daterangepicker/datepicker-range-popup';
import { TransferComponent } from './inventoryaction/transfer/transfer.component';
import { AddtransferComponent } from './inventoryaction/transfer/addtransfer/addtransfer.component';
import { ReceivetransferComponent } from './inventoryaction/transfer/receivetransfer/receivetransfer.component';
import { ReceivepreviewtransferComponent } from './inventoryaction/transfer/receivepreview-transfer/receivepreview-transfer.component';
import { PreviewtransferComponent } from './inventoryaction/transfer/previewtransfer/previewtransfer.component';
import { ReportfilterComponent } from './reports/reportfilter/reportfilter.component';
import { RecentReportComponent } from './reports/generatedreport/recentreport/recentreport.component';
import { AddgroupmodifierComponent } from './products/modifier/groupmodifier/addgroupmodifier/addgroupmodifier.component';
import { GroupmodifierComponent } from './products/modifier/groupmodifier/groupmodifier.component';
import { InventorylogsComponent } from './reports/previewreport/inventorylogs/inventorylogs.component';
import { InventorystockComponent } from './reports/previewreport/inventorystock/inventorystock.component';
import { TimeFormat } from './_directives/timeformat';
import { PrintReportLogComponent } from './print-templates/print-report-log/print-report-log.component';
import { PrintReportStockComponent } from './print-templates/print-report-stock/print-report-stock.component';
import { TaxsetupComponent } from './setup/tax/taxsetup/taxsetup.component';
import { AddtaxsetupComponent } from './setup/tax/taxsetup/addtaxsetup/addtaxsetup.component';
import { TaxsettingComponent } from './setup/tax/taxsetting/taxsetting.component';
import { AddtaxsettingComponent } from './setup/tax/taxsetting/addtaxsetting/addtaxsetting.component';
import { TaxComponent } from './setup/tax/tax.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { NewroleComponent } from './user-roles/newrole/newrole.component';
import { IndividualroleComponent } from './user-roles/individualrole/individualrole.component';
import { UserlistComponent } from './user-roles/users/userlist/userlist.component';
import { AdduserComponent } from './user-roles/users/adduser/adduser.component';
import { ReceiptComponent } from './setup/receipt/receipt.component';
import { ReceiptimageComponent } from './setup/receipt/receiptimage/receiptimage.component';
import { AddtransferreturnComponent } from './inventoryaction/transfer/addtransferreturn/addtransferreturn.component';
import { PreviewPOReturnComponent } from './inventoryaction/purchaseorder/purchasereturn/preview-poreturn/preview-poreturn.component';
import { PreviewTransferReturnComponent } from './inventoryaction/transfer/preview-transfer-return/preview-transfer-return.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SalesdetailComponent } from './reports/previewreport/salesdetail/salesdetail.component';
import { PrintsalesdetailreportComponent } from './print-templates/printsalesdetailreport/printsalesdetailreport.component';
import { SalesordertypeComponent } from './reports/previewreport/salesordertype/salesordertype.component';
import { SalestransactiontypeComponent } from './reports/previewreport/salestransactiontype/salestransactiontype.component';
import { PrintordertypereportComponent } from './print-templates/printordertypereport/printordertypereport.component';
import { PrinttransactiontypereportComponent } from './print-templates/printtransactiontypereport/printtransactiontypereport.component';
import { MarketplaceComponent } from './setup/marketplace/marketplace.component';
import { MsegatComponent } from './setup/marketplace/msegat/msegat.component';
import { StcpayComponent } from './setup/marketplace/stcpay/stcpay.component';
import { VomAccountingComponent } from './setup/marketplace/vom-accounting/vom-accounting.component';
import { PartnersComponent } from './setup/marketplace/partners/partners.component';
import { StatsordertypeComponent } from './dashboard/statsordertype/statsordertype.component';
import { StatspaymenttypeComponent } from './dashboard/statspaymenttype/statspaymenttype.component';
import { DailystatsComponent } from './dashboard/dailystats/dailystats.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SwitchlocationComponent } from './switchlocation/switchlocation.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/languages/', '.json')
};
import { CreatebrandComponent } from './brand/createbrand/createbrand.component';
import { ReasonlistComponent } from './setup/reasonlist/reasonlist.component';
import { CreateReasonComponent } from './setup/reasonlist/create-reason/create-reason.component';
import { RefundReasonListComponent } from './setup/reasonlist/refund-reason-list/refund-reason-list.component';
import { ComplementaryReasonListComponent } from './setup/reasonlist/complementary-reason-list/complementary-reason-list.component';
import { VoidReasonListComponent } from './setup/reasonlist/void-reason-list/void-reason-list.component';



import { backNavigationDirective } from './_directives/backNavigation';
import { SalesmonitorComponent } from './reports/salesreport/salesmonitor/salesmonitor.component';
import { CustomerComponent } from './customer/customer.component';
import { AddCustomerComponent } from './customer/add-customer/add-customer.component';


import { ReconciliationComponent } from './inventoryaction/reconciliation/reconciliation.component';
import { AddreconciliationComponent } from './inventoryaction/reconciliation/addreconciliation/addreconciliation.component';
import { OrdersComponent } from './orders/orders.component';
import { PreviewOrderComponent } from './orders/preview-order/preview-order.component';
import { PrintReconciliationComponent } from './print-templates/print-reconciliation/print-reconciliation.component';

import { ItemsUploaderComponent } from './products/items-uploader/items-uploader.component';
import { SummaryComponent } from './reports/salesreport/salessummary/summary.component';
import { SalesSummaryReportComponent } from './reports/salesreport/salessummary/sales-summary-report/sales-summary-report.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { DeleteditemsComponent } from './reports/previewreport/deleteditems/deleteditems.component';
import { PrintDeletedItemsComponent } from './print-templates/print-deleted-items/print-deleted-items.component';
import { RefundComponent } from './reports/previewreport/refund/refund.component';
import { PrintRefundComponent } from './print-templates/print-refund/print-refund.component';
import { DiscountComponent } from './reports/previewreport/discount/discount.component';
import { PrintDiscountComponent } from './print-templates/print-discount/print-discount.component';
import * as Sentry from "@sentry/angular";
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { SalestaxComponent } from './reports/previewreport/salestax/salestax.component';
import { PrintSalestaxComponent } from './print-templates/print-salestax/print-salestax.component';
import { AddOrderTypeComponent } from './setup/order-type-list/add-order-type/add-order-type.component';
import { OrderTypeListComponent } from './setup/order-type-list/order-type-list.component';
import { RefundOrderComponent } from './orders/refund-order/refund-order.component';
import { InventoryAlertComponent } from './reports/salesreport/inventory-alert/inventory-alert.component';
import { CreatePurchaseOrderComponent } from './inventoryaction/purchaseorder/create-purchase-order/create-purchase-order.component';
import { ConversionCalculatorComponent } from './inventoryaction/conversion-calculator/conversion-calculator.component';
import { CreateTransferComponent } from './inventoryaction/transfer/create-transfer/create-transfer.component';
import { ActionConfirmationComponent } from './action-confirmation/action-confirmation.component';
import { TempLoginComponent } from './temp-login/temp-login.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { PasswordVisibilityDirective } from './_directives/password-visibility.directive';
import { DiscountsComponent } from './setup/discounts/discounts.component';
import { AddDiscountComponent } from './setup/discounts/add-discount/add-discount.component';
import { SalesSummaryPDFComponent } from './pdf-template/sales-summary-pdf/sales-summary-pdf.component';
import { PermissionAccessDirective } from './_directives/permission-access.directive';
import { LocationInventoryUpliftComponent } from './setup/location-inventory/location-inventory-uplift/location-inventory-uplift.component';
import { ItemSalesPDFComponent } from './pdf-template/item-sales-pdf/item-sales-pdf.component';
import { AddLocationCoordinatesComponent } from './setup/location/add-location-coordinates/add-location-coordinates.component';
//import { AgmCoreModule } from '@agm/core';
import { TimePeriodPickerComponent } from './time-period-picker/time-period-picker.component';
import { SetupComponent } from './setup/setup/setup.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { CategorisationComponent } from './products/category/categorisation/categorisation.component';
import { ItemModifiersComponent } from './products/modifier/item-modifiers/item-modifiers.component';
import { RolesComponent } from './user-roles/roles/roles.component';
import { BrandCreationConfirmationComponent } from './sidebar/brand-creation-confirmation/brand-creation-confirmation.component';
import { DailySalesComponent } from './reports/previewreport/daily-sales/daily-sales.component';
import { PrintdailysalesComponent } from './print-templates/printdailysales/printdailysales.component';
import { UninstallModalConfirmationComponent } from './setup/marketplace/uninstall-modal-confirmation/uninstall-modal-confirmation.component';
import { QRCodeModule } from 'angularx-qrcode';
import { OrderPDFComponent } from './pdf-template/order-pdf/order-pdf.component';
import { MoyasarComponent } from './setup/marketplace/moyasar/moyasar.component';
import { PaymentLinkProvidersComponent } from './setup/payment-link-providers/payment-link-providers.component';
import { ManagetaxesComponent } from './setup/tax/taxsetup/managetaxes/managetaxes.component';
import { JahezIntegrationComponent } from './setup/marketplace/jahez-integration/jahez-integration.component';
import { LocationSalesSummaryComponent } from './reports/previewreport/location-sales-summary/location-sales-summary.component';
import { PrintLocationSalesSummaryComponent } from './print-templates/print-location-sales-summary/print-location-sales-summary.component';
import { ActionRefreshComponent } from './action-refresh/action-refresh.component';
import { LocationItemSettingComponent } from './setup/location-items/location-item-setting/location-item-setting.component';
import { ProductMixComponent } from './reports/previewreport/product-mix/product-mix.component';
import { PrintProductMixComponent } from './print-templates/print-product-mix/print-product-mix.component';
import { LocationStatsComponent } from './setup/location-stats/location-stats.component';
import { TillAccountListComponent } from './setup/location-stats/till-account-list/till-account-list.component';
import { TillAccountViewComponent } from './setup/location-stats/till-account-list/till-account-view/till-account-view.component';
import { ModifierUplifterComponent } from './products/modifier/modifier-uplifter/modifier-uplifter.component';
import { CategoryUploaderComponent } from './products/category-uploader/category-uploader.component';
import { MarnpayComponent } from './setup/marketplace/marnpay/marnpay.component';
import { ZatcaComponent } from './setup/marketplace/zatca/zatca.component';
import { PartnerDailySummaryComponent } from './reports/previewreport/partner-daily-summary/partner-daily-summary.component';
import { PrintPartnerDailySummaryComponent } from './print-templates/print-partner-daily-summary/print-partner-daily-summary.component';
import { RefundPinpadOrderComponent } from './orders/refund-pinpad-order/refund-pinpad-order.component';
import { DeliverectComponent } from './setup/marketplace/deliverect/deliverect.component';
import { TaxReferenceComponent } from './setup/tax/tax-reference/tax-reference.component';
import { env } from 'process';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as firebase from 'firebase/app';
import { LogEventDirective } from './_directives/log-event.directive';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps'; 
import { ExpenseComponent } from './lite-accounting/expense/expense.component';
import { CreateExpenseComponent } from './lite-accounting/expense/create-expense/create-expense.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ExpensePDFComponent } from './pdf-template/expense-pdf/expense-pdf.component';
import { LiteAccountingComponent } from './lite-accounting/lite-accounting.component';
import { AccountSummaryComponent } from './lite-accounting/account-summary/account-summary.component';
import { GrubTechComponent } from './setup/marketplace/grub-tech/grub-tech.component';
import { AddInvoicePeriodComponent } from './orders/add-invoice-period/add-invoice-period.component';
import { OrderDetailPDFComponent } from './pdf-template/order-detail-pdf/order-detail-pdf.component';
import { DatePickerV2Component } from './date-picker-v2/date-picker-v2.component';
import { ConfirmationDialogueComponent } from './confirmation-dialogue/confirmation-dialogue.component';
import { UserAnnouncementComponent } from './user-announcement/user-announcement.component';
import { LocationIntegrationComponent } from './setup/location-integration/location-integration.component';
import { ConfirmationZatcaModalComponent } from './setup/location-integration/confirmation-zatca-modal/confirmation-zatca-modal.component';
import { StockMovementComponent } from './reports/previewreport/stock-movement/stock-movement.component';
import { InventoryCountComponent } from './inventoryaction/inventory-count/inventory-count.component';
import { AddInventoryCountComponent } from './inventoryaction/inventory-count/add-inventory-count/add-inventory-count.component';
import { UploadInventoryCountComponent } from './inventoryaction/inventory-count/add-inventory-count/upload-inventory-count/upload-inventory-count.component';
import { TobaccoTaxComponent } from './reports/previewreport/tobacco-tax/tobacco-tax.component';
import { ImportManagerComponent } from './setup/import-manager/import-manager.component';
import { ImportIngredientsComponent } from './inventory/ingredient/import-ingredients/import-ingredients.component';


//dsn: "https://cbc77137520747d7be869a47e6343597@o951259.ingest.sentry.io/5973535",--Production
// dsn: "https://81c2bd6639db4f33bd349d3e36825c6e@o951259.ingest.sentry.io/5973533",
//Sentry.init({
//  dsn: "https://81c2bd6639db4f33bd349d3e36825c6e@o951259.ingest.sentry.io/5973533",
//  //dsn: "https://cbc77137520747d7be869a47e6343597@o951259.ingest.sentry.io/5973535",//Production
//  integrations: [new TracingIntegrations.BrowserTracing()],
//  // Set tracesSampleRate to 1.0 to capture 100%
//  // of transactions for performance monitoring.
//  // We recommend adjusting this value in production
//  tracesSampleRate: 1.0,
//  release:"Admin-Dashboard@8.0.1"
//});

firebase.initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    LoginComponent,
    PasswordDirective,
    RestaurantComponent,
    HeaderComponent,
    ImageuploadComponent,
    DashboardComponent,
    RetailComponent,
    AlertComponent,
    ToastComponent,
    SalesAnalyticsComponent,
    SalesSummaryComponent,
    TodaySummaryComponent,
    LocationComponent,
    StickyHeaderDirective,
    AddlocationComponent,
    LocationItemsComponent,
    DevicesComponent,
    AdddeviceComponent,
    FloorComponent,
    AddfloorComponent,
    TablesComponent,
    AddtableComponent,
    BrandDevicesComponent,
    AddbranddeviceComponent,
    ItemsComponent,
    AdditemComponent,
    CategoryComponent,
    AddcategoryComponent,
    SubcategoryComponent,
    AddsubcategoryComponent,
    NgbdSortableHeader,
    SupplierComponent,
    AddsupplierComponent,
    ModifierComponent,
    AddmodifierComponent,
    AddgroupComponent,
    GroupComponent,
    IngredientComponent,
    AddingredientComponent,
    RecipeComponent,
    AddrecipeComponent,
    LocationInventoryComponent,
    PurchaseorderComponent,
    AddpurchaseorderComponent,
    PreviewpurchaseorderComponent,
    ReceivepreviewpoComponent,
    ReceivepurchaseorderComponent,
    PurchasereturnComponent,
    NgbdDatepickerAdapter,
    PrintLayoutComponent,
    PrintPoComponent,
    PrintTransferComponent,
    InventorysetupComponent,
    InventoryactionComponent,
    SalesReportComponent,
    GeneratedreportComponent,
    PreviewreportComponent,
    AddpurchasereturnComponent,
    PrepsComponent,
    AddprepsComponent,
    NgbdDatepickerRangePopup,
    TransferComponent,
    AddtransferComponent,
    ReceivetransferComponent,
    PreviewtransferComponent,
    ReceivepreviewtransferComponent,
    RecentReportComponent,
    ReportfilterComponent,
    GroupmodifierComponent,
    AddgroupmodifierComponent,
    InventorylogsComponent,
    InventorystockComponent,
    TimeFormat,
    PrintReportLogComponent,
    PrintReportStockComponent,
    TaxsetupComponent,
    AddtaxsetupComponent,
    TaxsettingComponent,
    AddtaxsettingComponent,
    TaxComponent,
    UserRolesComponent,
    NewroleComponent,
    IndividualroleComponent,
    UserlistComponent,
    AdduserComponent,
    ReceiptComponent,
    ReceiptimageComponent,
    AddtransferreturnComponent,
    PreviewPOReturnComponent,
    PreviewTransferReturnComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    SalesdetailComponent,
    PrintsalesdetailreportComponent,
    SalesordertypeComponent,
    SalestransactiontypeComponent,
    PrintordertypereportComponent,
    PrinttransactiontypereportComponent,
    MarketplaceComponent,
    MsegatComponent,
    StcpayComponent,
    VomAccountingComponent,
    PartnersComponent,
    StatsordertypeComponent,
    StatspaymenttypeComponent,
    DailystatsComponent,
    SidebarComponent,
    SwitchlocationComponent,
    CreatebrandComponent,
    ReasonlistComponent,
    CreateReasonComponent,
    RefundReasonListComponent,
    ComplementaryReasonListComponent,
    VoidReasonListComponent,
    backNavigationDirective,
    SalesmonitorComponent,
    CustomerComponent,
    AddCustomerComponent,
    AddreconciliationComponent,
    ReconciliationComponent,
    PrintReconciliationComponent,
    OrdersComponent,
    PreviewOrderComponent,
    ItemsUploaderComponent,
    SummaryComponent,
    SalesSummaryReportComponent,
    DeleteditemsComponent,
    PrintDeletedItemsComponent,
    RefundComponent,
    PrintRefundComponent,
    DiscountComponent,
    PrintDiscountComponent,
    SalestaxComponent,
    PrintSalestaxComponent,
    AddOrderTypeComponent,
    OrderTypeListComponent,
    RefundOrderComponent,
    InventoryAlertComponent,
    CreatePurchaseOrderComponent,
    ConversionCalculatorComponent,
    CreateTransferComponent,
    ActionConfirmationComponent,
    TempLoginComponent,
    UpdateProfileComponent,
    PasswordVisibilityDirective,
    DiscountsComponent,
    AddDiscountComponent,
    SalesSummaryPDFComponent,
    PermissionAccessDirective,
    ItemSalesPDFComponent,
    LocationInventoryUpliftComponent,
    AddLocationCoordinatesComponent,
    TimePeriodPickerComponent,
    SetupComponent,
    CategorisationComponent,
    ItemModifiersComponent,
    RolesComponent,
    BrandCreationConfirmationComponent,
    DailySalesComponent,
    PrintdailysalesComponent,
    UninstallModalConfirmationComponent,
    OrderPDFComponent,
    MoyasarComponent,
    PaymentLinkProvidersComponent,
    JahezIntegrationComponent,
    ManagetaxesComponent,
    LocationSalesSummaryComponent,
    PrintLocationSalesSummaryComponent,
    ActionRefreshComponent,
    LocationItemSettingComponent,
    ProductMixComponent,
    PrintProductMixComponent,
    LocationStatsComponent,
    TillAccountListComponent,
    TillAccountViewComponent,
    ModifierUplifterComponent,
    CategoryUploaderComponent,
    MarnpayComponent,
    PartnerDailySummaryComponent,
    PrintPartnerDailySummaryComponent,
    RefundPinpadOrderComponent,
    DeliverectComponent,
    TaxReferenceComponent,
    LogEventDirective,
    ExpenseComponent,
    CreateExpenseComponent,
    UploadFileComponent,
    ExpensePDFComponent,
    LiteAccountingComponent,
    AccountSummaryComponent,
    GrubTechComponent,
    AddInvoicePeriodComponent,
    OrderDetailPDFComponent,
    DatePickerV2Component,
    ConfirmationDialogueComponent,
    UserAnnouncementComponent,
    ZatcaComponent,
    LocationIntegrationComponent,
    ConfirmationZatcaModalComponent,
    StockMovementComponent,
    InventoryCountComponent,
    AddInventoryCountComponent,
    UploadInventoryCountComponent,
    TobaccoTaxComponent,
    ImportManagerComponent,
    ImportIngredientsComponent
      ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,    
    NgApexchartsModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbNavModule,
    RecaptchaModule,
    QRCodeModule,
    RouterModule.forRoot([
      { path: 'UserAccess', component: TempLoginComponent, pathMatch: 'full', data: { title: 'CRM Access' } },
      { path: '', component: LoginComponent, pathMatch: 'full', data: { title: 'Login' } },
      { path: 'reset-password', component: ResetpasswordComponent, data: { title: 'Reset Password' } },
      {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
          { path: 'po/:poid', component: PrintPoComponent },
          { path: 'ts/:tsid', component: PrintTransferComponent },
          { path: 'invlog/:id', component: PrintReportLogComponent },
          { path: 'invstock/:id', component: PrintReportStockComponent },
          { path: 'salesDetail/:id', component: PrintsalesdetailreportComponent },
          { path: 'salesOrderType/:id', component: PrintordertypereportComponent },
          { path: 'salesTransactionType/:id', component: PrinttransactiontypereportComponent },
          { path: 'rcn/:rcnid', component: PrintReconciliationComponent },
          { path: 'deletedItems/:id', component: PrintDeletedItemsComponent },
          { path: 'refund/:id', component: PrintRefundComponent },
          { path: 'discount/:id', component: PrintDiscountComponent },
          { path: 'salesTax/:id', component: PrintSalestaxComponent },
          { path: 'dailySales/:id', component: PrintdailysalesComponent },
          { path: 'locationSales/:id', component: PrintLocationSalesSummaryComponent },
          { path: 'productMix/:id', component: PrintProductMixComponent },
          { path: 'partnerSummary/:id', component: PrintPartnerDailySummaryComponent }
        ]
      },//1F2532
      {
        path: 'restaurant', component: RestaurantComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: DashboardComponent, pathMatch: 'full' , data: { title: 'Dashboard' } },
          { path: 'location', component: LocationComponent, data: { title: 'Location' } },
          { path: 'location/add', component: AddlocationComponent, data: { title: 'Location Add' } },
          { path: 'location/edit', component: AddlocationComponent, data: { title: 'Location Edit' } },
          { path: 'location/edit/:type', component: AddlocationComponent, data: { title: 'Location' } },
          { path: 'branddevices', component: BrandDevicesComponent, data: { title: 'Device' } },
          { path: 'branddevices/add', component: AddbranddeviceComponent, data: { title: 'Add Device' } },
          { path: 'branddevices/edit/:id', component: AddbranddeviceComponent, data: { title: 'Device Edit' } },
          { path: 'item', component: ItemsComponent, data: { title: 'Item' } },
          { path: 'modifier', component: ModifierComponent, data: { title: 'Modifier' } },
          { path: 'category', component: CategorisationComponent, data: { title: 'Categorisation' } },
          { path: 'report/generate', component: GeneratedreportComponent, data: { title: 'Generate Report' } },
          { path: 'report/recent', component: GeneratedreportComponent, data: { title: 'Generated Reports' } },
          { path: 'report/inventorystock', component: InventorystockComponent, data: { title: 'Stock Report' } },
          { path: 'report/inventorylogs', component: InventorylogsComponent, data: { title: 'Inventory Log' } },
          { path: 'report/stockreport', component: PreviewreportComponent , data: { title: 'Stock Report' }},
          { path: 'userroles', component: UserRolesComponent, data: { title: 'User Role' } },
          { path: 'tax/setup', component: TaxsetupComponent, data: { title: 'Tax' } },
          { path: 'report/salesdetail', component: SalesdetailComponent, data: { title: 'Sales Detail Report' } },
          { path: 'report/salesordertype', component: SalesordertypeComponent, data: { title: 'Order Type Report' } },
          { path: 'report/salestransactiontype', component: SalestransactiontypeComponent, data: { title: 'Transaction Type Report' } },
          { path: 'marketplace', component: MarketplaceComponent, data: { title: 'Marketplace' } },
          { path: 'marketplace/msegat', component: MsegatComponent, data: { title: 'MSEGet' } },
          { path: 'marketplace/stcpay', component: StcpayComponent, data: { title: 'STC Pay' } },
          { path: 'marketplace/vom', component: VomAccountingComponent, data: { title: 'VOM' } },
          { path: 'report/salesmonitor', component: SalesmonitorComponent, data: { title: 'Item Sales' } },
          { path: 'reasons', component: ReasonlistComponent, data: { title: 'Reason' } },
          { path: 'customer', component: CustomerComponent, data: { title: 'Customer' } },
          { path: 'orders', component: OrdersComponent, data: {title:'Order'} },
          { path: 'prview', component: PreviewOrderComponent, data: { title: 'Order View' } },
          { path: 'report/salessummary', component: SummaryComponent, data: { title: 'Sales Summary Report' } },
          { path: 'report/deleted-items', component: DeleteditemsComponent, data: { title: 'Voided Items Report' } },
          { path: 'report/refund', component: RefundComponent, data: { title: 'Refund Report' } },
          { path: 'report/discount', component: DiscountComponent, data: { title: 'Discount Report' } },
          { path: 'report/sales-tax', component: SalestaxComponent, data: { title: 'Tax Report' } },
          { path: 'orderType', component: OrderTypeListComponent, data: { title: 'Order Type' } },
          { path: 'setup/discounts', component: DiscountsComponent, data: { title: 'Discount' } },
          { path: 'inventorysetup', component: InventorysetupComponent, data: { title: 'Inventory Setup' } },
          { path: 'inventoryaction', component: InventoryactionComponent, data: { title: 'Inventory Action' } },
          { path: 'setup', component: SetupComponent, data: { title: 'Setup' } },
          { path: 'report/daily-sales', component: DailySalesComponent, data: { title: 'Daile Sales Report' } },
          { path: 'report/locationSalesSummary', component: LocationSalesSummaryComponent, data: { title: 'Location Summary Report' } },
          { path: 'report/productMix', component: ProductMixComponent, data: { title: 'Product Mix Report' } },
          { path: 'report/partnerSummary', component: PartnerDailySummaryComponent, data: { title: 'Partner Summary Report' } },
          { path: 'liteAccounting', component: LiteAccountingComponent, data: { title: 'Lite Accounting' } },
          { path: 'report/stockMovement', component: StockMovementComponent, data: { title: 'Stock Movement Report' } },
          { path: 'report/tobaccoTax', component: TobaccoTaxComponent, data: { title: 'Tobacco Tax Report' } }

        ]
      },
      {
        path: 'retail', component: RetailComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: DashboardComponent, pathMatch: 'full', data: { title: 'Dashboard' } },
          { path: 'location', component: LocationComponent, data: { title: 'Location' } },
          { path: 'location/add', component: AddlocationComponent, data: { title: 'Location Add' } },
          { path: 'location/edit', component: AddlocationComponent, data: { title: 'Location Edit' } },
          { path: 'location/edit/:type', component: AddlocationComponent, data: { title: 'Location' } },
          { path: 'branddevices', component: BrandDevicesComponent, data: { title: 'Device' } },
          { path: 'branddevices/add', component: AddbranddeviceComponent, data: { title: 'Add Device' } },
          { path: 'branddevices/edit/:id', component: AddbranddeviceComponent, data: { title: 'Device Edit' } },
          { path: 'item', component: ItemsComponent, data: { title: 'Item' } },
          { path: 'modifier', component: ModifierComponent, data: { title: 'Modifier' } },
          { path: 'category', component: CategorisationComponent, data: { title: 'Categorisation' } },
          { path: 'subcategory', component: SubcategoryComponent, data: { title: 'Generated Reports' } },
          { path: 'inventorysetup', component: InventorysetupComponent, data: { title: 'Inventory Setup' } },
          { path: 'inventoryaction', component: InventoryactionComponent, data: { title: 'Inventory Action' } },
          { path: 'report/sales', component: SalesReportComponent, data: { title: 'Sales Report' } },
          { path: 'report/generate', component: GeneratedreportComponent, data: { title: 'Generate Report' } },
          { path: 'report/recent', component: GeneratedreportComponent, data: { title: 'Generated Reports' } },
          { path: 'report/inventorystock', component: InventorystockComponent, data: { title: 'Stock Report' } },
          { path: 'report/inventorylogs', component: InventorylogsComponent, data: { title: 'Inventory Log' } },
          { path: 'report/stockreport', component: PreviewreportComponent, data: { title: 'Stock Report' } },
          { path: 'userroles', component: UserRolesComponent, data: { title: 'User Role' } },
          { path: 'tax/setup', component: TaxsetupComponent, data: { title: 'Tax' } },
          { path: 'report/salesdetail', component: SalesdetailComponent, data: { title: 'Sales Detail Report' } },
          { path: 'report/salesordertype', component: SalesordertypeComponent, data: { title: 'Order Type Report' } },
          { path: 'report/salestransactiontype', component: SalestransactiontypeComponent, data: { title: 'Transaction Type Report' } },
          { path: 'marketplace', component: MarketplaceComponent, data: { title: 'Marketplace' } },
          { path: 'marketplace/msegat', component: MsegatComponent, data: { title: 'MSEGet' } },
          { path: 'marketplace/stcpay', component: StcpayComponent, data: { title: 'STC Pay' } },
          { path: 'marketplace/vom', component: VomAccountingComponent, data: { title: 'VOM' } },
          { path: 'report/salesmonitor', component: SalesmonitorComponent, data: { title: 'Item Sales' } },
          { path: 'reasons', component: ReasonlistComponent, data: { title: 'Reason' } },
          { path: 'customer', component: CustomerComponent, data: { title: 'Customer' } },
          { path: 'orders', component: OrdersComponent, data: { title: 'Order' } },
          { path: 'prview', component: PreviewOrderComponent, data: { title: 'Order View' } },
          { path: 'report/salessummary', component: SummaryComponent, data: { title: 'Sales Summary Report' } },
          { path: 'report/deleted-items', component: DeleteditemsComponent, data: { title: 'Voided Items Report' } },
          { path: 'report/refund', component: RefundComponent, data: { title: 'Refund Report' } },
          { path: 'report/discount', component: DiscountComponent, data: { title: 'Discount Report' } },
          { path: 'report/sales-tax', component: SalestaxComponent, data: { title: 'Tax Report' } },
          { path: 'orderType', component: OrderTypeListComponent, data: { title: 'Order Type' } },
          { path: 'setup/discounts', component: DiscountsComponent, data: { title: 'Discount' } },
          { path: 'setup', component: SetupComponent, data: { title: 'Setup' } },
          { path: 'report/daily-sales', component: DailySalesComponent, data: { title: 'Daile Sales Report' } },
          { path: 'report/locationSalesSummary', component: LocationSalesSummaryComponent, data: { title: 'Location Summary Report' } },
          { path: 'report/productMix', component: ProductMixComponent, data: { title: 'Product Mix Report' } },
          { path: 'report/partnerSummary', component: PartnerDailySummaryComponent, data: { title: 'Partner Summary Report' } },
          { path: 'liteAccounting', component: LiteAccountingComponent, data: { title: 'Lite Accounting' } },
          { path: 'report/stockMovement', component: StockMovementComponent, data: { title: 'Stock Movement Report' } },
          { path: 'report/tobaccoTax', component: TobaccoTaxComponent, data: { title: 'Tobacco Tax Report' } }
        ]
      },
      // otherwise redirect to home
      { path: '**', redirectTo: '' }
    ], { scrollPositionRestoration: 'enabled' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgCircleProgressModule.forRoot(
      // {
      // "radius": 60,
      // "space": -10,
      // "outerStrokeGradient": true,
      // "outerStrokeWidth": 10,
      // "outerStrokeColor": "#4882c2",
      // "outerStrokeGradientStopColor": "#53a9ff",
      // "innerStrokeColor": "#e7e8ea",
      // "innerStrokeWidth": 10,
      // "title": "UI",
      // "animateTitle": false,
      // "animationDuration": 1000,
      // "showTitle": false,
      // "showSubtitle": false,
      // "showUnits": false,
      // "showBackground": false,
      // "clockwise": false,
      // "startFromZero": false,
      // "lazy": true}
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),//, { enabled: environment.production }),
    GoogleMapsModule,
    //AgmCoreModule.forRoot({
    //  apiKey: environment.googleMap.apiKey,
    //  libraries: ['geometry', 'places']
    //}),
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    DecimalPipe,
    PrintService,
    TimeFormat,
    GoogleMap,
    { provide: 'googleTagManagerId', useValue: environment.GTM_ID }
    //{
    //  provide: ErrorHandler,
    //  useValue: Sentry.createErrorHandler({
    //    showDialog: false,
    //  }),
    //},
    //{
    //  provide: Sentry.TraceService,
    //  deps: [Router],
    //},
    //{
    //  provide: APP_INITIALIZER,
    //  useFactory: () => () => { },
    //  deps: [Sentry.TraceService],
    //  multi: true,
    //},
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
