
<div class="mnt-header position-relative order-list">
  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-20" *ngIf="(service.reasons?.length > 0)">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Void' | translate }} </h5>
    </div>
    <div class="col-md-auto" *hasPermission="['Setup','Reasons','Complementary', 'Insert']">
      <button type="button" (click)="openReason()" class="btn btn-primary cutom-button custom-create-btn">
        + {{'New Reason' | translate }}
      </button>
    </div>
    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>

  <div *ngIf="(service.reasons?.length > 0)">
    <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
      <div class="tile-body p-0 table-responsive text-nowrap">
        <table class="table table-striped text-start">
          <thead>
            <tr class="table-header">
              <th width="50%" sortable="description" (sort)="onSort($event)">{{'Reasons' | translate }} <img  src="assets/images/icons/sorting-icon.png" /></th>
              <th width="20%" sortable="locations" (sort)="onSort($event)">{{'Applied on' | translate }} <img  src="assets/images/icons/sorting-icon.png" /></th>
              <th width="10%" class="text-center">
                <!-- Status Filter -->
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                    <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                    <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                             name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                      <label class="form-check-label font-blue" for="dropdownStatus_all"
                             (click)="selectAllFilters($event,StatusList)">
                        {{'All Status' | translate}}
                      </label>
                    </div>
                    <div *ngFor="let item of StatusList; let i = index">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                        <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                          <!-- {{item.name}} -->

                          <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                         class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                          </ngb-highlight>


                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reason of data$ | async" editRow>
              <td (click)="getReason(reason)" class="name-blue">
                <span>{{reason.description}}</span>
              </td>
              <td (click)="getReason(reason)"> {{reason.locations}} {{'Locations' | translate }}</td>
              <td (click)="getReason(reason)" class="text-center">
                <ngb-highlight [ngClass]="{'btn-active': reason.statusID === 1, 'btn-inactive':reason.statusID !== 1 }" class="btn btn-sm" [result]="(reason.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                  <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                </div>
                <ng-template #popContent>
                  <p class="dropdown-heading-mini">{{'Reason' | translate }}</p>
                  <a *hasPermission="['Setup','Reasons','Complementary', 'Edit']" (click)="getReason(reason)">{{'Edit' | translate }}</a>
                  <a *hasPermission="['Setup','Reasons','Complementary', 'Deactivate']" (click)="activate(reason)">{{((reason.statusID === 1)?'Deactivate':'Activate') | translate }}</a>
                  <a *hasPermission="['Setup','Reasons','Complementary', 'Delete']" (click)="openConfirmationDialog(reason)">{{'Delete' | translate }}</a>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <div class="row mt-10" *ngIf="((data$ | async).length > 0)">

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>

    </div>
  </div>





  <div *ngIf="(service.reasons?.length == 0) && ((data$ | async).length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Reasons yet' | translate }}</strong>
      <p class="text-center width-375">{{'Add reasons which will help to make changes in order if required.' | translate}}</p>
    </div>
    <div class="items-flex">
      <div class="create-btn mlmr-10">
        <div>
          <button type="button" (click)="openReason()" *hasPermission="['Setup','Reasons','Complementary', 'Insert']" class="btn btn-primary cutom-button form-control custom-create-btn">
            {{'+ Create New Reasons' | translate }}
          </button>
        </div>
      </div>


    </div>
  </div>



  <div *ngIf="(service.reasons?.length > 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Record Found' | translate }}</strong>
      <p class="text-center width-375">{{'Add reasons which will help to make changes in order if required.' | translate}}</p>
    </div>
  </div>

</div>
