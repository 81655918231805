import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbdDatepickerRangePopup } from '../../../daterangepicker/datepicker-range-popup';
import { SalesmonitorService } from '../../../_services/salesmonitor.service';
import { SalesMonitor } from '../../../_models/SalesMonitor';
import { SortEvent, NgbdSortableHeader } from '../../../_directives/sortable.directive';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LocationService } from '../../../_services/location.service';
import { WebSocketConnectionService } from '../../../_services/web-socket-connection.service';
import { start } from 'repl';
import { NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../../_services/excel.service';
import { PdfService } from '../../../_services/pdf.service';

@Component({
  selector: 'app-salesmonitor',
  templateUrl: './salesmonitor.component.html',
  styleUrls: ['./salesmonitor.component.css']
})
export class SalesmonitorComponent implements OnInit {
  data$: Observable<SalesMonitor[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  startdate: any;
  enddate: any;
  ButtonText = "Search";
  salesMonitorForm: UntypedFormGroup;
  Locations: any;
  public selectedLocation;
  public selectedLocationName = 'All Locations';
  public selectedDate = new Date();
  private companyCode;
  private selectedBrand;
  selectedLang = 'en';
  isGeneratingPDF: boolean = false;
  exportPDF: boolean = false;
  itemMods: boolean = false;
  items: boolean = true;
  subCategoryList = [];
  filteredList = [];
  dtModel: string = this.setToday();
  @ViewChild(NgbdDatepickerRangePopup) dateComp: { fromDate: any; toDate: any; };
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: SalesmonitorService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private location: LocationService,
    private socketService: WebSocketConnectionService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private excelService: ExcelService,
    private pdfService: PdfService) {
    //this.createForm();
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.companyCode = this.localStorageService.getSelectedUser().CompanyDetails.CompanyCode;
    this.loading$ = service.loading$;
    this.selectedLang = this.localStorageService.getSelectedLanguage();

  }
  ngAfterViewInit() {
    this.loadLocations();
    this.socketService.ConnectWebSocket(this.companyCode, this.selectedBrand);
    this.socketService.receiveData.pipe().subscribe((data) => {
      this.getData();
    });
  }
  ngOnInit() {

  }
  get f() { return this.salesMonitorForm.controls; }

  private createForm() {
    this.salesMonitorForm = this.formBuilder.group({
      // Form Controls
      locationID: '',
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      searhTerm: ''
    });
  }
  public bulkSelection($event) {
    if (this.service.allData$ !== null && this.service.allData$ !== undefined) {
      this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
    }
  }

  private loadLocations() {
    this.location.getAllLocationsddl(this.selectedBrand).subscribe((res: any) => {
      this.Locations = res;
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  setToday() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  getData() {
    if (this.dateComp.fromDate !== null && this.dateComp.toDate !== null && this.dateComp.fromDate !== undefined && this.dateComp.toDate !== undefined && this.dateComp.fromDate !== '' && this.dateComp.toDate !== '') {
      this.startdate = this.dateComp.fromDate.year + "-" + ('0' + this.dateComp.fromDate.month).slice(-2) + "-" + ('0' + this.dateComp.fromDate.day).slice(-2);
      this.enddate = this.dateComp.toDate.year + "-" + ('0' + this.dateComp.toDate.month).slice(-2) + "-" + ('0' + this.dateComp.toDate.day).slice(-2);
      let location = (this.selectedLocation === '' || this.selectedLocation === undefined || this.selectedLocation === null) ? null : this.selectedLocation;
      this.service.getSalesMonitoring(this.startdate, this.enddate, location, parseInt(this.localStorageService.getSelectedBrand().BrandID));
      this.service.headerFilter = { subCategory: [''] };
      this.data$ = this.service.data$;
      this.total$ = this.service.total$;
      this.loading$ = this.service.loading$;
      this.service._allData$.subscribe(res => {
        this.subCategoryList = [];
        if (res) {
          var categories: any = [];
          categories = new Set(res.map(item => this.selectedLang == 'en' ? item.categoryName : item.altCategoryName));
          categories.forEach((ct: any) => {
            var childs = [];
            const checkSubCatExists = childParam => childs.some(({ name }) => name == childParam)
            res.forEach((e: any) => {
              if ((this.selectedLang == 'en' ? e.categoryName : e.altCategoryName) === ct) {
                if (!checkSubCatExists(this.selectedLang == 'en' ? e.subCategoryName : e.altSubCategoryName)) {
                  childs.push({
                    name: this.selectedLang == 'en' ? e.subCategoryName : e.altSubCategoryName,
                    subCategoryID: e.subCategoryID
                  });
                }
              }
            });
            this.subCategoryList.push({
              name: ct,
              childs: childs
            });
          });
        }
      });
    }
  }
  public setLocation(val) {
    if (val === null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';
      this.getData();
    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;
      this.getData();
    }
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
    this.socketService.closeConnection();
  }
  setDate() {
    if (this.dateComp.fromDate !== null && this.dateComp.toDate !== null && this.dateComp.fromDate !== undefined && this.dateComp.toDate !== undefined && this.dateComp.fromDate !== '' && this.dateComp.toDate !== '') {
      this.connectDisconnectSocket();
      this.getData();
    }
  }
  connectDisconnectSocket() {
    let date;
    let currentDate = new Date();
    let yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    let startDate = new Date(this.dateComp.fromDate.year + "-" + ('0' + this.dateComp.fromDate.month).slice(-2) + "-" + ('0' + this.dateComp.fromDate.day).slice(-2));
    let endDate = new Date(this.dateComp.toDate.year + "-" + ('0' + this.dateComp.toDate.month).slice(-2) + "-" + ('0' + this.dateComp.toDate.day).slice(-2));
    while (startDate <= endDate) {
      if (currentDate.getDate() === startDate.getDate() && currentDate.getMonth() === startDate.getMonth() && currentDate.getFullYear() === startDate.getFullYear()) {
        date = currentDate;
        break;
      }
      if (yesterdayDate.getDate() === startDate.getDate() && yesterdayDate.getMonth() === startDate.getMonth() && yesterdayDate.getFullYear() === startDate.getFullYear()) {
        date = yesterdayDate;
        break;
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    let connectAgain: boolean = this.socketService.DisconnectOutDateSocket(date);
    if (connectAgain === true) {
      this.socketService.ConnectWebSocket(this.companyCode, this.selectedBrand);
      this.socketService.receiveData.pipe().subscribe((data) => {
        this.getData();
      });
    }
  }
  export(type, data): void {
    this.CustomizeReport(type, data);
  }
  private CustomizeReport(type, data) {

    let jsonData = [];
    if (data) {
      data.source._value.forEach(function (item) {
        if (item.selected) {
          let temp = {
            "Name": item.name,
            "Alternate Name": item.altName,
            "Type": "Item",
            "Sub Category": item.subCategoryName,
            "Alternate Sub Category": item.altSubCategoryName,
            "Barcode": item.barcode,
            "Item Count": item.itemCount,
            "Sales": item.sales,
            "Sales With Tax": item.salesWithTax
          };
          jsonData.push(temp)
          item.modifiers.forEach(function (itemDetail) {

            let tempDetail = {
              "Name": itemDetail.name,
              "Alternate Name": itemDetail.altName,
              "Type": "Modifier",
              "Sub Category": "",
              "Alternate Sub Category": "",
              "Barcode": "",
              "Item Count": itemDetail.itemCount,
              "Sales": itemDetail.sales,
              "Sales With Tax": itemDetail.salesWithTax
            };
            jsonData.push(tempDetail)
          });

        }
      });
      if (jsonData.length > 0) {
        this.excelService.exportAsExcelFile(jsonData, 'Export-Item_Sales', type);
      }
      else {
        this.alertService.error("Select Items to download.")
      }
    }

  }
  private getExportData() {
    return this.service.report;
  }
  receive($event) {
    this.pdfService.SaveAsPDFFileMultiPage($event, 'Item-Sales', "htmlData");
    this.pdfService.PDFGenerated.subscribe((res: any) => {
      if (res)
        this.isGeneratingPDF = false;
    });
  }
  public openPDF(): void {
    this.isGeneratingPDF = true;
    this.exportPDF = true;
    setTimeout(() => {
      this.exportPDF = false;
    }, 3000);
  }
  filterList(subCat) {
    //if (this.subCategoryList.filter(e => e.isChecked === false)?.length > 0) {
    //  this.checkAllOrderTakers = false;
    //}
    //else {
    //  this.checkAllOrderTakers = true;
    //}
    if (subCat.isChecked) {
      if (!this.filteredList.includes(subCat.name)) {
        this.filteredList.push(subCat.name);
      }
    }
    else {
      if (this.filteredList.includes(subCat.name)) {
        const index = this.filteredList.indexOf(subCat.name, 0);
        if (index > -1) {
          this.filteredList.splice(index, 1);
        }
      }
    }

    let headerFilter = {
      subCategory: this.filteredList
    }
    this.service.headerFilter = headerFilter;
  }
}
