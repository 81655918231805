
import { Component, OnInit, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import { AlertService } from '../../_services/alert.service';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FloorService } from 'src/app/_services/floor.service';
import { Floors } from 'src/app/_models/Floor';
import { AddfloorComponent } from './addfloor/addfloor.component';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TrackEventsService } from '../../_services/track-events.service';

@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class FloorComponent implements OnInit {
  data$: Observable<Floors[]>;
  oldData: Floors[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  submit: boolean;
  locationSubscription: Subscription;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: FloorService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private locationService: LocationService,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.loading$ = service.loading$;
    this.submit = false;

    this.locationSubscription = this.locationService.locationIdChange.subscribe((id) => {
      if (id !== undefined && id !== 0 && id !== null) {
        this.getData();

      }
    });

  }

  ngOnInit() {
    this.getData();
  }


  getData() {
    this.service.getFloors(LocationService.locationId);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddfloorComponent, { size: 'lg', windowClass: 'small-modal', animation: false });
    this.trackEvents.Log_Screen("Create Floor");
    modalRef.componentInstance.name = '';
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
      this.getData();
    }, (reason) => {
      this.getData();
    });

  }


  Edit(floor) {
    const modalRef = this.modalService.open(AddfloorComponent, { size: 'lg', windowClass: 'small-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Floor");
    modalRef.componentInstance.SelectedID = floor.floorID;
    modalRef.componentInstance.SelectedName = floor.floorTitle;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }


  Deactive(id, rowVersion) {
    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Floor has been Deactive");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Floor has been Deleted");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Floor has been Deleted");
            this.getData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
}
