<main class="views container">
  <div class="page-header tabheader ">
    <span>
      <a backLink>{{'Setup' | translate }} </a>&nbsp;/  {{(active=="refund" ?"Refund":active=="complementary"?"Complementary":active=="void"?"Void":"-") | translate}}
    </span>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="refund" id="refund" *hasPermission="['Setup','Reasons','Refund']" (click)="active='refund';">
            <a ngbNavLink> {{'Refund' | translate }} </a>
            <ng-template ngbNavContent>
              <app-refund-reason-list [LogEventType]="'screen'" [LogEvent]="'Create Refund Reason'"></app-refund-reason-list>
            </ng-template>
          </li>
          <li ngbNavItem="complementary" id="complementary" *hasPermission="['Setup','Reasons','Complementary']" (click)="active='complementary';">
            <a ngbNavLink>{{'Complementary' | translate }}  </a>
            <ng-template ngbNavContent>
              <app-complementary-reason-list [LogEventType]="'screen'" [LogEvent]="'Create Complementary Reason'"></app-complementary-reason-list>
            </ng-template>
          </li>
          <li ngbNavItem="void" id="void" *hasPermission="['Setup','Reasons','Void']" (click)="active='void';">
            <a ngbNavLink>{{'Void' | translate }}  </a>
            <ng-template ngbNavContent>
              <app-void-reason-list [LogEventType]="'screen'" [LogEvent]="'Create Void Reason'"></app-void-reason-list>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
