
import { Component, ViewChild, Input } from '@angular/core';
import { ChartOptions } from '../dashboard.component';
import { ChartComponent } from 'ng-apexcharts';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { DashboardService } from '../../_services/dashboard.service';
import { timer, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { SalesTodaySummary } from 'src/app/_models/Dashboard';
import { debug } from 'console';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-dailystats',
  templateUrl: './dailystats.component.html',
  styleUrls: ['./dailystats.component.css']
})
export class DailystatsComponent {
  @ViewChild('dailychart', { static: true }) chart: ChartComponent;
  public todaySummaryLineOptions: Partial<ChartOptions>;
  public loadingTodaySummary = true;

  @Input() selectedBrand: any;
  @Input() selectedLocation: any;
  @Input() selectedDate: any;
  @Input() GraphData: SalesTodaySummary;
  constructor(private translate: TranslateService) {
    this.todaySummaryLineOptions = {
      series: [],
      chart: {
        height: 200,
        type: 'area',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        },
      },
      colors: ['#EDF1F5', '#91C7C4'],
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        curve: "straight",
        colors: ['#EDF1F5', '#056D69'],
        width: 2

      },
      grid: {
        show: false,
        row: {
          opacity: 0.5
        },
      },
      xaxis: {
        //categories: categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            fontSize: '11px',
            fontFamily: 'Inter'
          }
        }
      },
      yaxis: {
        show: false,
        labels: {
          style: {
            fontSize: '15px',
            fontFamily: 'Inter-SemiBold'
          }
        }
      },
      legend: {
        show: false
      },
    };
  }

  ngOnInit() {
    //if (this.GraphData !== undefined && this.GraphData !== null) {
    //  this.loadTodaySalesSummary();
    //}
  }
  ngOnChanges() {
    if (this.GraphData !== undefined && this.GraphData !== null) {
      this.loadTodaySalesSummary();
    }
  }
  private loadTodaySalesSummary() {
    this.loadingTodaySummary = true;

    let todaySeries: number[] = [];
    let lastWeekSeries: number[] = [];
    let categories: string[] = [];



    let last = this.GraphData.lastRecord;
    let today = this.GraphData.currentRecord;

    // const unique = [...new Set(data.map(item => this.translate.instant(item.day)))];
    let unique = [];
    this.GraphData.graphKeys.forEach(e => {
      if (e.keyValue !== null && e.keyValue !== undefined && e.keyValue !== '')
        unique.push(this.translate.instant(e.keyValue));
    });

    //const last = data.filter(element => {
    //  return this.translate.instant(element.day) === this.translate.instant(unique[1]);
    //});

    //const today = data.filter(element => {
    //  return this.translate.instant(element.day) === this.translate.instant(unique[0]);
    //});

    for (let i = 0; i < today.length; i++) {
      todaySeries.push(today[i].sales);
      categories.push(today[i].time);
      lastWeekSeries.push(last[i].sales);
    }

    this.todaySummaryLineOptions = {

      series: [
        {
          name: unique[1],
          data: lastWeekSeries
        },
        {
          name: unique[0],
          data: todaySeries
        },


      ],
      chart: {
        height: 150,
        type: 'area',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        toolbar: {
          show: false
        },
      },
      colors: ['#EDF1F5', '#91C7C4'],
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        curve: "straight",
        colors: ['#EDF1F5', '#056D69'],
        width: 2

      },
      grid: {
        show: false,
        row: {
          opacity: 0.5
        },
      },
      xaxis: {

        categories: categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            fontSize: '11px',
            fontFamily: 'Inter'
          }
        }
      },
      yaxis: {
        show: false,
        labels: {
          style: {
            fontSize: '15px',
            fontFamily: 'Inter-SemiBold'
          }
        }
      },
      legend: {
        show: false
      },
    };
    this.loadingTodaySummary = false;


  }


}
