<section class="tile" [ngClass]="{'lazy': loading}">
  <div class="tile-header">
    <h6>Sales Analytics</h6>
  </div>
  <div class="tile-body">
    <label>TODAYS SALE</label>
    <h3>
      {{totalSales}} SR
      <img *ngIf="percentage >= 0" src="/assets/images/icons/profit-icon.png" class="h15" alt="icon">
      <img *ngIf="percentage < 0" src="/assets/images/icons/loss-icon.png" class="h15" alt="icon">
      <span [class.text-profit]="percentage >= 0" [class.text-loss]="percentage < 0">{{percentage < 0 ? -percentage : percentage }}%</span>
    </h3>
    <apx-chart [series]="salesAnalyticsLineOptions.series"
               [chart]="salesAnalyticsLineOptions.chart"
               [markers]="salesAnalyticsLineOptions.markers"
               [colors]="salesAnalyticsLineOptions.colors"
               [dataLabels]="salesAnalyticsLineOptions.dataLabels"
               [stroke]="salesAnalyticsLineOptions.stroke"
               [title]="salesAnalyticsLineOptions.title"
               [grid]="salesAnalyticsLineOptions.grid"
               [xaxis]="salesAnalyticsLineOptions.xaxis"
               [yaxis]="salesAnalyticsLineOptions.yaxis"></apx-chart>
  </div>
</section>
