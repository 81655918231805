import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import * as XLSX from 'xlsx';
import { LocationInventoryService } from '../../../_services/location-inventory.service';

@Component({
  selector: 'app-location-inventory-uplift',
  templateUrl: './location-inventory-uplift.component.html',
  styleUrls: ['./location-inventory-uplift.component.css']
})
export class LocationInventoryUpliftComponent implements OnInit {
  fileData: any;
  loading = false;
  showError = false;
  errorList = [];
  invalidFile: boolean;
  constructor(public activeModal: NgbActiveModal,
    private alertService: AlertService,
    public service: LocationInventoryService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService) {
  }

  ngOnInit() {
  }
  close() {
    this.activeModal.dismiss();
  }
  async onFileChange(ev) {
    let file = ev.target.files[0];
    //this.fileData = await this.excelService.ReadExcelFile(file);
    let ext = file.name.split('.').pop();
    if (ext.toLowerCase() === 'xls' || ext.toLowerCase() === 'xlsx') {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const _file = file;
      if (ev.target.files[0].size <= 5000000) {
        this.invalidFile = false;
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial = XLSX.utils.sheet_to_json(sheet);
            this.fileData = initial;
          }, {});
        }
        reader.readAsBinaryString(_file);
      }
      else {
        this.alertService.error("File size is larger than 5 MB.");
      }
    }
    else {
      this.invalidFile = true;
      this.alertService.error("Invalid file extension.");
    }
  }
  download() {
    //var data =
    //  [
    //    { Name: '', ArabicName: '', Barcode: '', Category: '', SubCategory: '', Orders: '', Price: '', Unit: '' }
    //  ];
    //this.excelService.exportAsExcelFile(data, 'ItemsTemplate', 'xlsx');
  }
  ValidateSheet(sheet) {
    let sheetFormat = true;
    let message = '';
    let i = 1;
    let _list = this.errorList;
    sheet.filter(function (item) {
      if (item['stockID'] === null || item['stockID'] === undefined || item['stockID'] === '') {
        sheetFormat = false;
        message = 'StockID is missing';
        _list.push({ rowNo: i, message: message });
      }
      if (item['minStockLevel'] === null || item['minStockLevel'] === undefined || item['minStockLevel'] === '') {
        sheetFormat = false;
        message = 'Min Stock Level is missing';
        _list.push({ rowNo: i, message: message });
      }
      if (item['rowVersion'] === null || item['rowVersion'] === undefined || item['rowVersion'] === '') {
        sheetFormat = false;
        message = 'RowVersion is missing';
        _list.push({ rowNo: i, message: message });
      }
      
      if (item['minStockLevel'] !== null && item['minStockLevel'] !== undefined && item['minStockLevel'] !== '') {
        if (isNaN(Number(item['minStockLevel'].toString()))) {
          _list.push({ rowNo: i, message: 'Min Stock Level format is invalid' });
          sheetFormat = false;
        }
      }
      if (item['currentStock'] !== null && item['currentStock'] !== undefined && item['currentStock'] !== '') {
        if (isNaN(Number(item['currentStock'].toString()))) {
          _list.push({ rowNo: i, message: 'Current Stock format is invalid' });
          sheetFormat = false;
        }
      }
      if (item['rowVersion'] !== null && item['rowVersion'] !== undefined && item['rowVersion'] !== '') {
        if (isNaN(Number(item['rowVersion'].toString()))) {
          _list.push({ rowNo: i, message: 'RowVersion format is invalid' });
          sheetFormat = false;
        }
      }
      i++;
    });
    this.errorList = _list;
    return sheetFormat;
  }
  onSubmit() {
    if (this.fileData !== null && this.fileData !== undefined) {
      if (this.ValidateSheet(this.fileData)) {
        this.loading = true;
        this.service.saveItems(this.fileData, this.localStorageService.getSelectedBrand().BrandID).subscribe(
          data => {
            this.alertService.success("Stocks has been updated.");
            this.loading = false;
            this.activeModal.dismiss();
          },
          error => {
            this.alertService.error(error)
            this.loading = false;
          });
      }
      else {
        this.showError = true;
        this.alertService.error("Attached sheet is not valid.");
      }
    }
    else {
      this.alertService.error("File not found.");
    }
  }
}
