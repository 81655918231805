
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { AddtransferComponent } from './addtransfer/addtransfer.component';
import { TransferInventoryService } from 'src/app/_services/transfer-inventory.service';
import { TransferInventory } from 'src/app/_models/TransferInventory';
import { ReceivepreviewtransferComponent } from './receivepreview-transfer/receivepreview-transfer.component';
import { PreviewtransferComponent } from './previewtransfer/previewtransfer.component';
import { PrintService } from 'src/app/_services/print.service';
import { CreateTransferComponent } from './create-transfer/create-transfer.component';
import { TrackEventsService } from '../../_services/track-events.service';


@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  providers: [ExcelService]
})
export class TransferComponent implements OnInit {
  data$: Observable<TransferInventory[]>;
  oldData: TransferInventory[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "transfer";
  submit: boolean;
  excelData = [];
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TransferInventoryService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    public printService: PrintService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Transfers', type);
  }

  getBrandData() {
    this.service.headerFilter = { status: [''], };
    this.service.getTransferData(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });



      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  add() {
    const modalRef = this.modalService.open(CreateTransferComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Transfer");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(transfer) {

    const modalRef = this.modalService.open(CreateTransferComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Transfer");
    modalRef.componentInstance.SelectedID = transfer.tsid;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }


  Preview(transfer) {
    if (transfer.statusID == 601 || transfer.statusID == 602) {
      const modalRef = this.modalService.open(ReceivepreviewtransferComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      modalRef.componentInstance.SelectedID = transfer.tsid;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      });
    }
    else {
      const modalRef = this.modalService.open(PreviewtransferComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      modalRef.componentInstance.SelectedID = transfer.tsid;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      });
    }
  }

  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, transfer) {
    this.service.transfers.forEach((item, index) => {
      if (item.tsid == transfer.tsid) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }
  Print(transfer) {

    const tsid = transfer.tsid;
    this.printService.printDocument('ts', tsid);
  }

  private getExportData() {
    let selected: any[];

    selected = [];
    this.service.transfers.forEach(d => {
      if (d.selected) {
        selected.push({
          TSCode: d.tsCode,
          ReferenceNo: d.referenceNo,
          Notes: d.notes,
          FromLocation: d.fromLocationName,
          ToLocation: d.toLocationName,
          Date: d.date,
          ExpectedDate: d.expectedDate,
          ReceivedDate: d.receivedDate,
          Status: d.status
        });
      }
    });
    if (selected.length == 0) {
      this.service.transfers.forEach(d => {
        selected.push({
          TSCode: d.tsCode,
          ReferenceNo: d.referenceNo,
          Notes: d.notes,
          FromLocation: d.fromLocationName,
          ToLocation: d.toLocationName,
          Date: d.date,
          ExpectedDate: d.expectedDate,
          ReceivedDate: d.receivedDate,
          Status: d.status
        });
      });
    }
    return selected;
  }
  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"
    }
  }

  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}

