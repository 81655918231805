import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketConnectionService {
  ws: WebSocket | null = null;
  dashboard: DashboardComponent;
  receiveData = new BehaviorSubject<string>(null);
  constructor() {
  }

  ConnectWebSocket(companyCode, brandID) {
    const protocol = window.location.protocol.replace('http', 'ws');
    const host = window.location.host;
    let url = `${protocol}//${host}/order?brandID=` + brandID + '&companyCode=' + companyCode;
    this.ws = new WebSocket(url);
    this.receiveData = new BehaviorSubject<string>(null);
    this.ws.onmessage = d => {
      this.ReceiveData(d.data);
    };

  }

  closeConnection() {
    if (this.ws === null) {
      //console.warn('WebSocket was null');
      return;
    }
    this.ws.close();
    this.ws = null;
  }
  ReceiveData(data) {
    const message = data;
    console.log(message);
    this.receiveData.next(message);
    return true;
  }
  DisconnectOutDateSocket(date) {
    let connectAgain: boolean;
    let currentDate = new Date();
    if (date !== null && date !== undefined && date !== '') {
      let socketDate = new Date(date);
      let diff = Math.ceil(Math.abs(currentDate.getTime() - socketDate.getTime()) / (1000 * 3600 * 24));
      if (diff > 2 || diff < 0) {
        this.closeConnection();
        return connectAgain = false;
      }
      else {
        if (this.ws === null)
          return connectAgain = true;
        else
          return connectAgain = false;
      }
    }
    else {
      this.closeConnection();
      return connectAgain = false;
    }
  }
}
