import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import * as myGlobals from '../_helpers/globals';
import { LocalStorageService } from '../_services/local-storage.service';

@Component({
  selector: 'app-temp-login',
  templateUrl: './temp-login.component.html',
  styleUrls: ['./temp-login.component.css']
})
export class TempLoginComponent implements OnInit {
  data: string;
  isRestaurant: boolean;
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService) {
    this.data = this.route.snapshot.queryParams['data'];
  }

  ngOnInit() {
    this.authenticationService.TempLogin(this.data)
      .pipe(first())
      .subscribe(
        data => {
          const LoginData = JSON.parse(data.data);
          this.router.navigate([this.localStorageService.getSelectedUser().User.Industry === 1 ? '/restaurant' : '/retail']);
        },
        error => {
          this.alertService.error(error);

        });
  }

}
