
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { AlertService } from 'src/app/_services/alert.service';
import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import * as $ from 'jquery';
import { SalesOrderTypeReportService } from 'src/app/_services/sales-ordertype-report.service';
import { LocalStorageService } from '../../_services/local-storage.service';

@Component({
  selector: 'app-printordertypereport',
  templateUrl: './printordertypereport.component.html',
  styleUrls: ['./printordertypereport.component.css']
})
export class PrintordertypereportComponent implements OnInit {


  loading$: Observable<boolean>;
  selectedReport : any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  selectedBrand: any;
  constructor(
    public service: SalesOrderTypeReportService,
    private router:Router,
    private printService: PrintService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService) {
    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
    this.selectedBrand = this.localStorageService.getSelectedBrand();
  }

  OrderTypes : any;
  ngOnInit() 
  {
   this.getReportData(this.selectedReport.dataURL);

  }

  
  OrderType : any;
  getReportData(dataURL) {

    let _this = this;
    $.ajax({
      method: 'GET',
      url:  dataURL,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if(res){
        _this.OrderType = res;
          console.log(_this.OrderType);
          _this.printService.onDataReady();
      
        }else{
          
        }
      }
      ,error: function (data:any) {
          console.log(data);
      }
  })

    
    
  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
  
  }


}

