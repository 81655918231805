import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Ordertype } from '../../../_models/ordertype';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationService } from '../../../_services/location.service';
import { OrdertypeService } from '../../../_services/ordertype.service';

@Component({
  selector: 'app-add-order-type',
  templateUrl: './add-order-type.component.html',
  styleUrls: ['./add-order-type.component.css']
})
export class AddOrderTypeComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  orderTypeForm: UntypedFormGroup;
  ButtonText = "Save";
  LocationsList = [];
  data$: Ordertype[];
  submitted = false;
  loading = false;
  total$: Observable<number>;
  isDefault: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private location: LocationService,
    public Service: OrdertypeService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService) {
    this.createForm();
  }

  ngOnInit() {

    this.setSelectedOrderType();
  }
  close() {
    this.activeModal.dismiss();
  }

  get f() { return this.orderTypeForm.controls; }
  private createForm() {

    this.orderTypeForm = this.formBuilder.group({
      name: ['', Validators.required],
      optionsApplicable: ['', Validators.required],
      statusID: [true, Validators.required],
      orderTypeID: 0,
      tableSelection: false,
      deliveryAllowed: false,
      packageCosting: false,
      refrenceAllowed: false,
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      rowVersion: 0
    });

  }
  setSelectedOrderType() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loading = true;

      this.Service.get(this.SelectedID).subscribe(res => {
        //Set
        console.log(res)
        this.editForm(res);
        this.loading = false;
      });
    }
    else {
      this.ButtonText = "Save";

    }
  }
  editForm(orderType) {
    this.f.name.setValue(orderType.name);
    this.f.orderTypeID.setValue(orderType.orderTypeID);
    this.f.rowVersion.setValue(orderType.rowVersion);
    this.f.statusID.setValue(orderType.statusID === 1 ? true : false);
   
    this.isDefault = orderType.isDefault;

    if (orderType.tableSelection === true) {
      this.f.optionsApplicable.setValue('dine_in');
    }
    else if (orderType.deliveryAllowed === true && orderType.packageCosting === true) {
      this.f.optionsApplicable.setValue('delivery');
    }
    else if (orderType.refrenceAllowed === true) {
      this.f.optionsApplicable.setValue('reference_number');
    }

  }
  onSubmit() {

    this.orderTypeForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.orderTypeForm.invalid) { return; }
    this.loading = true;
    const statusValue = (this.f.statusID.value === true ? 1 : 2);
    this.f.statusID.setValue(statusValue);

    if (this.f.optionsApplicable.value === 'dine_in') {
      this.f.tableSelection.setValue(true);
      this.f.deliveryAllowed.setValue(false);
      this.f.packageCosting.setValue(false);
      this.f.refrenceAllowed.setValue(false);
    }
    else if (this.f.optionsApplicable.value === 'delivery') {
      this.f.tableSelection.setValue(false);
      this.f.deliveryAllowed.setValue(true);
      this.f.packageCosting.setValue(true);
      this.f.refrenceAllowed.setValue(false);
    }
    else if (this.f.optionsApplicable.value === 'reference_number') {
      this.f.tableSelection.setValue(false);
      this.f.deliveryAllowed.setValue(false);
      this.f.packageCosting.setValue(false);
      this.f.refrenceAllowed.setValue(true);
    }

    if (this.f.orderTypeID.value === 0) {
      this.Service.insert(this.orderTypeForm.value).subscribe(() => {
        this.alertService.success("Order Type has saved successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
    else {
      this.Service.update(this.orderTypeForm.value).subscribe(() => {
        this.alertService.success("Order Type has updated successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }

  }
}
