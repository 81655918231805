import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { AddpurchaseorderComponent } from '../../../inventoryaction/purchaseorder/addpurchaseorder/addpurchaseorder.component';
import { CreatePurchaseOrderComponent } from '../../../inventoryaction/purchaseorder/create-purchase-order/create-purchase-order.component';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { InventoryAlert } from '../../../_models/inventory-alert';
import { AlertService } from '../../../_services/alert.service';
import { InventoryAlertService } from '../../../_services/inventory-alert.service';
import { ItemService } from '../../../_services/item.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationService } from '../../../_services/location.service';
import { SupplierService } from '../../../_services/supplier.service';

@Component({
  selector: 'app-inventory-alert',
  templateUrl: './inventory-alert.component.html',
  styleUrls: ['./inventory-alert.component.css']
})
export class InventoryAlertComponent implements OnInit {
  data$: Observable<InventoryAlert[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  Locations = [];
  private selectedLocation;
  public selectedLocationName = 'All Locations';
  private selectedSupplier;
  public selectedsupplierName = 'All Suppliers';
  private selectedBrand;
  SuppliersList = [];
  locationId = null;
  supplierId = null;
  items = [];
  GeneratePO: boolean = false;
  itemsCount = 0;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: InventoryAlertService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    // private location: LocationService,
    // private itemService: ItemService,
    private modalService: NgbModal,) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    // this.loadLocations();
    // this.loadSuppliers();
    this.loading$ = service.loading$;

    //this.getData();

  }

  ngOnInit() {

    this.getData();

  }
  private loadLocations() {
    // this.location.getAllLocationsddl(this.selectedBrand).subscribe((res: any) => {
    //   this.Locations = res;
    //  // this.Locations=[];
    //   // new Set(res.map(location => location.name)).forEach(e => {

    //   //   console.log(e);
    //   //   this.Locations.push(
    //   //     {
    //   //       name: e,
    //   //       isChecked: false
    //   //     }
    //   //   )
    //   // });


    // });
  }
  public setLocation(val) {
    if (val === null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';
      this.getData();
    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;
      this.getData();
    }
  }
  public setSupplier(val) {
    if (val === null) {
      this.selectedSupplier = null;
      this.selectedsupplierName = 'All Suppliers';
      this.getData();
    } else {
      this.selectedSupplier = val.supplierID;
      this.selectedsupplierName = val.name;
      this.getData();
    }
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  loadSuppliers() {
    // this.itemService.getSuppliers(parseInt(this.selectedBrand)).subscribe((res: any) => {
    //   //this.SuppliersList = res;

    //   this.SuppliersList=[];
    //   new Set(res.map(s => s.name)).forEach(e => {
    //     this.SuppliersList.push(
    //       {
    //         name: e,
    //         isChecked: false
    //       }
    //     )
    //   });

    // });
  }
  getData() {
    this.service.headerFilter = {locationName:[''],supplierName:['']};
    let location = (this.selectedLocation === '' || this.selectedLocation === undefined || this.selectedLocation === null) ? null : this.selectedLocation;
    let suppliers = (this.selectedSupplier === '' || this.selectedSupplier === undefined || this.selectedSupplier === null) ? null : this.selectedSupplier;
    this.service.getInventoryAlerts(parseInt(this.selectedBrand), location, suppliers);
    //debugger
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    this.service.allData$.subscribe(res => {
      //debugger

      if (res != null) {
        this.Locations = [];
        this.SuppliersList = [];
        this.items = [];
        this.itemsCount = 0;
        this.GeneratePO = false;
        //debugger
        new Set(res.map(invAlert => invAlert.supplierName)).forEach(e => {
          this.SuppliersList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(invAlert => invAlert.locationName)).forEach(e => {
          //debugger
          this.Locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }


    });


  }

  public chkSelection($event, _item) {
    this.locationId = _item.locationID;
    this.supplierId = _item.supplierID;
    this.service.items.forEach(e => {
      if (e.locationID === this.locationId && e.supplierID === this.supplierId) {
        if (e.selected) {
          e.disable = false;
        }
      }
      else {
        e.selected = false;
        e.disable = true;
      }
    });
    if ($event.target.checked) {
      this.items.push(_item.supplyID);
      this.itemsCount++;
    }
    else {
      const index = this.items.indexOf(_item.supplyID, 0);
      if (index > -1) {
        this.items.splice(index, 1);
        this.itemsCount--;
      }
    }

    if (this.itemsCount < 1) {
      this.GeneratePO = false;
      this.service.items.forEach(e => {
        e.disable = false;
      });
    }
    else {
      this.GeneratePO = true;
    }

  }
  public bulkSelection($event) {
    if (this.itemsCount > 0) {
      let count = 0;
      this.items = [];
      this.service.items.forEach(e => {
        if (e.disable === false) {
          e.selected = $event.target.checked;
          if ($event.target.checked) {
            this.items.push(e.supplyID);
            count++;
          }
          else {
            const index = this.items.indexOf(e.supplyID, 0);
            if (index > -1) {
              this.items.splice(index, 1);
            }
          }
        }
      });
      this.itemsCount = count;
      if (this.itemsCount < 1) {
        this.GeneratePO = false;
        this.service.items.forEach(e => {
          e.disable = false;
        });
      }
      else {
        this.GeneratePO = true;
      }
    }

  }
  open() {
    const modalRef = this.modalService.open(CreatePurchaseOrderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedSupplier = this.supplierId;
    modalRef.componentInstance.SelectedLocation = this.locationId;
    modalRef.componentInstance.SelectedItems = this.items;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }



  filters() {
    let headerFilter = {
      supplierName: this.SuppliersList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      locationName: this.Locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })

    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }


  setSelectedResult(val) {

    if (val == 'location') {
      return this.Locations.filter(k => k.isChecked == true).length;
    }
    if (val == 'supplier') {
      return this.SuppliersList.filter(k => k.isChecked == true).length;
    }

  }

}
