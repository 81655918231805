import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPassword]'
})
export class PasswordDirective {
  private _shown = false;
  constructor(private el: ElementRef) {
    this.setup();
  }
  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = `<img src='assets/images/eye-open.svg' style="top:2px; position:relative"/>`;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = `<img src='assets/images/eye-close.svg'>`;
    }
  }
  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.innerHTML = `<img src='assets/images/eye-close.svg'>`;
    span.className = "show-password float-end";
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

}
