

<div *ngIf="(service.modifiers?.length > 0)" class="margin-top-minus-30">
  <div class="mnt-header position-relative">


    <div class="row flex-row-reverse mb-4 tCustom-header">
      <div class="col-md-text-start hide-mobile">
        <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Modifier Items' | translate }} </h5>
      </div>
      <div class="col-md-auto" *hasPermission="['Products','Modifiers','Modifier Items', 'Insert']">
        <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
          {{'+ Add Modifier' | translate }}
        </button>
      </div>
      <div class="col-md-auto">
        <div class="" *hasPermission="['Products','Modifiers','Modifier Items' ,'Import']">
          <div ngbDropdown class="nav-item" placement="bottom">
            <button class="profile btn export-btn btn-lg form-control" (click)="import()">
              <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-auto">
        <div class="" *hasPermission="['Products','Modifiers','Modifier Items', 'Export']">
          <div ngbDropdown class="nav-item" placement="bottom">

            <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
              <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
            </button>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
              <button ngbDropdownItem (click)="export('csv')">CSV</button>
              <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-auto">
        <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
      </div>
    </div>
    <div class="position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped text-start">
            <thead>
              <tr class="table-header">
                <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                <th width="25%" sortable="name" (sort)="onSort($event)">{{'Modifier Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="barcode" (sort)="onSort($event)">{{'Barcode' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="price" (sort)="onSort($event)">{{'Price' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="trackInventory" (sort)="onSort($event)">{{'Inventory' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" class="text-center">

                  <!-- Status Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                      <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                               name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                        <label class="form-check-label font-blue" for="dropdownStatus_all"
                               (click)="selectAllFilters($event,StatusList)">
                          {{'All Status' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let item of StatusList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                          <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                            <!-- {{item.name}} -->


                            <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                           class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                            </ngb-highlight>


                          </label>
                        </div>
                      </div>
                    </div>
                  </div>



                </th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let modifier of data$ | async " editRow>
                <td class="text-center">
                  <input type="checkbox" class="form-check-input" (change)="chkSelection($event,modifier)" [(ngModel)]="modifier.selected" />
                </td>
                <td (click)="Edit(modifier)" class="name-blue"> {{selctedLang=='en'?modifier.name:modifier.alternateName}} </td>
                <td (click)="Edit(modifier)"> {{modifier.barcode}} </td>
                <td (click)="Edit(modifier)">{{modifier.price}}</td>
                <td (click)="Edit(modifier)" class="text-capitalize">


                  <svg width="10" height="10" viewBox="0 0 10 10" style=" margin: 0 10px;" *ngIf="modifier.trackInventory == true" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="#40A773" />
                  </svg>

                  <svg width="10" height="10" viewBox="0 0 10 10" style=" margin: 0 10px;" *ngIf="modifier.trackInventory == false" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="#DF0017" />
                  </svg>



                  {{(modifier.trackInventory ? 'True' :'False') | translate}}
                </td>
                <td (click)="Edit(modifier)" class="text-center">
                  <ngb-highlight [ngClass]="{'btn-active': modifier.statusID === 1, 'btn-inactive':modifier.statusID !== 1 }" class="btn btn-sm" [result]="(modifier.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                    <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                  </div>
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'MODIFIER ITEMS' | translate }}</p>
                    <a *hasPermission="['Products','Modifiers','Modifier Items', 'Edit']" (click)="Edit(modifier)">{{'Edit' | translate }}</a>
                    <ng-container *ngIf="modifier.statusID === 1">
                      <a *hasPermission="['Products','Modifiers','Modifier Items', 'Deactivate']" (click)="SetStatus(modifier,2, modifier.rowVersion)">{{'Deactivate' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="modifier.statusID === 2">
                      <a *hasPermission="['Products','Modifiers','Modifier Items', 'Deactivate']" (click)="SetStatus(modifier,1, modifier.rowVersion)">{{'Activate' | translate }}</a>
                    </ng-container>
                    <a *hasPermission="['Products','Modifiers','Modifier Items', 'Delete']" class="selected-inner-text color-danger" (click)="openConfirmationDialog(modifier.modifierID, modifier.rowVersion,selctedLang=='en'?modifier.name:modifier.alternateName)">

                      {{'Delete' | translate }}
                      <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                    </a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
        </div>
      <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <p class="pagination-count">
            <strong class="p-4">
              {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ | async)!}} {{'results' | translate }}
            </strong>
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>

      </div>
    </div>
  </div>

<div *ngIf="(service.modifiers?.length == 0) && ((data$ | async)?.length == 0)">
  <div class="blank-order">
    <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
    <strong>{{'No Product Modifier yet' | translate }}</strong>
    <p class="text-center width-375">{{'Create modifiers for item.' | translate}}</p>
  </div>
  <div class="items-flex">
    <div class="create-btn mlmr-10">

      <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
        {{'+ Create New Modifier' | translate }}
      </button>
    </div>
    <div class="import-sec" *hasPermission="['Products','Modifiers','Modifier Items' ,'Import']">
      <div ngbDropdown class="nav-item" placement="bottom">
        <button class="profile btn export-btn btn-lg form-control" (click)="import()">
          <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
        </button>
      </div>
    </div>

  </div>
</div>



<div *ngIf="(service.modifiers?.length > 0) && ((data$ | async)?.length == 0)">
  <div class="blank-order">
    <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
    <strong>{{'No Record Found' | translate }}</strong>
    <p class="text-center width-375">{{'Create modifiers for item.' | translate}}</p>
  </div>
</div>
