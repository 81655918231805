import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../../alert/alert.component';
import { OrderPDFComponent } from '../../pdf-template/order-pdf/order-pdf.component';
import { AlertService } from '../../_services/alert.service';
import { CommonService } from '../../_services/common.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { OrderService } from '../../_services/order.service';
import { PdfService } from '../../_services/pdf.service';
import { PermissionService } from '../../_services/permission.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { ZATCAService } from '../../_services/zatca.service';
import { RefundOrderComponent } from '../refund-order/refund-order.component';
import { RefundPinpadOrderComponent } from '../refund-pinpad-order/refund-pinpad-order.component';

@Component({
  selector: 'app-preview-order',
  templateUrl: './preview-order.component.html',
  styleUrls: ['./preview-order.component.css']
})
export class PreviewOrderComponent implements OnInit {


  orderData: any;
  @Input() SelectedID = 0;
  loading = false;
  industry = 0;
  fullRefunded = true;
  selctedLang = 'en';
  QRCode: string = '';
  export: boolean = false;
  exportDetailed: boolean = false;
  ZATCAConfig: boolean = true;
  invoiceLabel: string = '';
  checkZatcaCompliance: boolean = false;
  itemCount = 0;
  compItemCount = 0;
  constructor(public service: OrderService, public setting: CommonService, public activeModal: NgbActiveModal, public localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private permissionService: PermissionService,
    private pdfService: PdfService,
    private zatcaService: ZATCAService) {
  }

  ngOnInit() {
    console.log(this.localStorageService.getSelectedUser());
    this.industry = this.localStorageService.getSelectedUser().User.Industry;
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;
    this.loadSettings();
    this.getOrder();
    //this.orderData = { "order": { "orderID": 42342, "orderNo": 192, "orderTaker": "Ammar", "orderType": "Take Away", "orderState": "In Kitchen", "orderStatus": "OrderCheckOut", "partnerLogo": null, "paymentMode": null, "grandTotal": 41.4, "created": null, "location": "Al-Rayan-123456", "locationID": 1, "orderTypeID": 2, "createdDate": "2021-06-29T19:05:03", "eta": 0, "promisedTime": 0, "subTotal": 36, "taxAmount": 5.4 }, "payment": [{ "paymentMode": "Cash" }], "item": [{ "orderDetailID": 72680, "name": "Orangeberry Cake", "quantity": 1, "price": 36, "note": null, "isComplimentary": false, "refundQuantity": 0, "itemDetails": null }] };

  }

  sumModifiers(modifiers) {
    if (modifiers !== null && this.orderData.order.sumModifier) {
      let total = 0;
      for (let data of modifiers) {
        total += data.price * data.quantity;
      }
      return total;
    } else {
      return 0;
    }
  }

  getOrder() {
    if (this.SelectedID !== 0) {
      this.loading = true;
      this.fullRefunded = true;
      this.service.getById(this.SelectedID).subscribe(res => {
        this.orderData = res;
        if (this.orderData?.order.orderStatus === 'Paid') {
          this.invoiceLabel = (this.orderData?.customer?.vatID) ? 'فاتورة ضريبية' : 'فاتورة ضريبية مبسطة';
        }
        else if (this.orderData?.order.orderStatus === 'Partial Refund' || this.orderData?.order.orderStatus === 'Refund') {
          this.invoiceLabel = (this.orderData?.customer?.vatID) ? 'اشعار دائن للفاتورة الضريبية' : 'اشعار دائن للفاتورة الضريبية المبسطة';
        }
        this.QRCode = (this.orderData.order.phase2QRCode) ? this.orderData.order.phase2QRCode:this.orderData.qrCode;
        console.log(this.orderData);
        this.loading = false;
        if (this.orderData.order.pinpad === false) {
          this.orderData.item.forEach(e => {
            if (e.isRefunded === false || e.remainingQuantity > 0) {
              this.fullRefunded = false;
            }
            if (e.isComplimentary) {
              this.itemCount++;
            }
            this.compItemCount++;
          });
        }
        if (this.orderData.order.pinpad === true && this.orderData.order.orderStatus === 'Paid') {
          this.fullRefunded = false;
        }
        if (this.orderData.taxes && this.orderData.taxes.length > 0) {
          let sum: number = 0;
          this.orderData.taxes.forEach(a => sum += a.amount);
          if (sum == 0)
            this.ZATCAConfig = false;
        }
        else if (this.orderData.order.taxAmount !== undefined && this.orderData.order.taxAmount !== null && this.orderData.order.taxAmount == 0) {
          this.ZATCAConfig = false;
        }

      });
    }
  }

  close() {
    this.activeModal.dismiss();
  }


  Settings: any;
  VAT: any;
  loadSettings() {

    this.setting.getSettings(this.localStorageService.getSelectedBrand().BrandID).subscribe((res: any) => {
      //debugger
      this.Settings = res;
      console.log('res');
      console.log(res);

      this.VAT = res.vat;

    });

  }
  refundOrder() {
    var refundComp = null;
    if (this.orderData.order.pinpad === true && this.orderData.order.orderStatus === 'Paid') {
      refundComp = RefundPinpadOrderComponent;
    }
    else {
      refundComp = RefundOrderComponent;
    }

    const modalRef = this.modalService.open(refundComp, { size: 'lg', windowClass: 'small-modal' });
    this.trackEvents.Log_Screen("Create Refund");
    modalRef.componentInstance.name = '';
    modalRef.componentInstance.currentOrder = this.orderData;
    modalRef.result.then((result) => {
      this.getOrder();
    }, () => {
      this.getOrder();
    });

  }
  openLink() {
    if (!this.orderData.order.hashKey || this.orderData.order.hashKey === '')
      this.alertService.error('Hash key not found. Can\'t create URL');
    else
      this.service.openDigitalReceipt(this.industry, this.localStorageService.getSelectedBrand().BrandID, this.orderData.order.orderNo, this.orderData.order.hashKey);
  }
  receive($event) {
    this.pdfService.SaveAsPDFFileMultiPage($event, 'Order-PDF', "htmlData");
    this.pdfService.PDFGenerated.subscribe((res: any) => {
      if (res)
        this.loading = false;
    });
  }
  public openPDF(): void {
    this.loading = true;
    if (this.permissionService.checkPermission(['Invoice', 'Add Invoice Period'])) {
      this.exportDetailed = true;
    }
    else {
      this.export = true;
    }
    setTimeout(() => {
      this.export = false;
      this.exportDetailed = false;

    }, 3000);



    //const modalRef = this.modalService.open(OrderPDFComponent, { size: 'xl', windowClass: 'full-modal' });
    //modalRef.componentInstance.name = '';
    //modalRef.componentInstance.orderData = this.orderData;
    //modalRef.result.then((result) => {
    //  this.getOrder();
    //}, () => {
    //  this.getOrder();
    //});
  }
  public generatePDF() {
    this.loading = true;

    if (this.permissionService.checkPermission(['Invoice', 'Add Invoice Period'])) {
      this.pdfService.GenerateOrderDetailedPDF(this.orderData).then(x => {
        this.loading = false;
        const data = `data:application/pdf;base64,${x}`;
        var link = document.createElement('a');
        link.href = data;
        link.download = "Order-PDF.pdf";
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      });
    }
    else {
      this.pdfService.GenerateOrderPDF(this.orderData).then(x => {
        this.loading = false;
        const data = `data:application/pdf;base64,${x}`;
        var link = document.createElement('a');
        link.href = data;
        link.download = "Order-PDF.pdf";
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      });
    }


  }
  getFormattedPrice(price: number) {
    var value = 0;
    price = parseFloat((price + 0.001).toFixed(3));
    var result = (price - Math.floor(price)) !== 0;

    if (result) {
      var sp = price.toString().split('.');
      value = parseFloat(sp[0] + '.' + sp[1].substring(0, 2));
    }
    else {
      //var sp = result.toString().split('.');
      value = parseFloat(price.toString());//parseFloat(sp[0] + sp[1].substring(0, 1));
    }
    return value;
  }
  PostInvoiceToZATCA() {
    this.loading = true;
    this.zatcaService.PostInvoice(this.orderData?.order.orderID).subscribe(data => {
      this.alertService.success("Successfully Posted");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error("Failed to POST");
      this.loading = false;
    });

  }
}
