<form [formGroup]="ingredientsUploadForm" class="form">
  <div class="modal-header">
    <h4 class="modal-title">{{'Import Ingredients' | translate}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          @if(showFileUpload){
          <div class="form-group files">
            <input type="file" (change)="onFileChange($event)" class="form-control" accept=".xls, .xlsx" #itemFile (click)="itemFile.value = null" value="" />
          </div>
          }
          @if(showFileOptions){
            <div class="form-group col-lg-12 col-sm-7">
              <div class="row pb-4">
                <div class="col-lg-4">
                  <strong> <label style="color:black">{{'Column Name' | translate }}</label></strong>
                </div>
                <div class="col-lg-6">
                  <strong> <label style="color:black">{{'Map to field' | translate }}</label></strong>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Name' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Milk' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="name">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.name.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.name.value === f.group.value || f.name.value === f.unit.value || f.name.value === f.cost.value || f.name.value === f.hasLife.value ||
                  f.name.value === f.life.value || f.name.value === f.suppliers.value  || f.name.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Group' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Juices' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="group">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.group.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.group.value === f.name.value || f.group.value === f.unit.value || f.group.value === f.cost.value || f.group.value === f.hasLife.value ||
                  f.group.value === f.life.value || f.group.value === f.suppliers.value  || f.group.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Unit' | translate }}</label>
                  <p>{{'Sample' | translate }}:Kg</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="unit">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.unit.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.unit.value === f.group.value || f.unit.value === f.name.value || f.unit.value === f.cost.value || f.unit.value === f.hasLife.value ||
                  f.unit.value === f.life.value || f.unit.value === f.suppliers.value  || f.unit.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Cost' | translate }}</label>
                  <p>{{'Sample' | translate }}: 12.23</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="cost">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.cost.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.cost.value === f.group.value || f.cost.value === f.unit.value || f.cost.value === f.name.value || f.cost.value === f.hasLife.value ||
                  f.cost.value === f.life.value || f.cost.value === f.suppliers.value || f.cost.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Has Life Period' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Yes' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="hasLife">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.hasLife.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.hasLife.value === f.group.value || f.hasLife.value === f.unit.value || f.hasLife.value === f.cost.value || f.hasLife.value === f.name.value ||
                  f.hasLife.value === f.life.value || f.hasLife.value === f.suppliers.value  || f.hasLife.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Life' | translate }}</label>
                  <p>{{'Sample' | translate }}: 3</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="life">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.life.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.life.value === f.group.value || f.life.value === f.unit.value || f.life.value === f.cost.value || f.life.value === f.hasLife.value ||
                  f.life.value === f.name.value || f.life.value === f.suppliers.value || f.life.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'Suppliers' | translate }}</label>
                  <p>{{'Sample' | translate }}: {{'Al Karam' | translate }}</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="suppliers">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.suppliers.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.suppliers.value === f.group.value || f.suppliers.value === f.unit.value || f.suppliers.value === f.cost.value || f.suppliers.value === f.hasLife.value ||
                  f.suppliers.value === f.life.value || f.suppliers.value === f.name.value || f.suppliers.value === f.sku.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-lg-4">
                  <label>{{'SKU' | translate }}</label>
                  <p>{{'Sample' | translate }}: 009875</p>
                </div>
                <div class="col-lg-8">
                  <select class="custom-select form-control" formControlName="sku">
                    @for(option of FileOptionList; track option.value){
                    <option [ngValue]="option.value" [selected]="option.value === f.sku.value">
                      {{option.name }}
                    </option>
                    }
                  </select>
                  @if(f.sku.value === f.group.value || f.sku.value === f.unit.value || f.sku.value === f.cost.value || f.sku.value === f.hasLife.value ||
                  f.sku.value === f.life.value || f.sku.value === f.name.value || f.sku.value === f.suppliers.value){
                  <div class="alert alert-danger">
                    {{'Same columns cannot be selected.' | translate }}
                  </div>
                  }
                </div>
              </div>

            </div>
          }
          @if(showError){

          @if(partialErrorMessage){
          <label class="px-3 blue">{{partialErrorMessage[0]}}&nbsp;{{partialErrorMessage[1] | translate}}</label>
          }
          <ul>
            @for(option of errorList; track option.rowNo){
            <li>
              <span class="caret">{{'Error at Row No' | translate}} <b>{{option.rowNo | translate}} </b></span>
              <ul class="nested">
                <li> {{option.message | translate}}</li>
              </ul>
            </li>
            }
          </ul>
          }
          <hr />
          @if(showError === false){
          <div style="justify-content:normal">
            <div class="row">
              <div class="col-lg-8">
                @if(showTemplate){
                <a> <strong><label (click)="download()" style="color: #3754b7;cursor:pointer">{{'Get Template' | translate}}</label></strong></a>
                }
              </div>
              <div class="col-lg-4">
                <button class=" btn-primary cutom-button export-continue move-to-end" [ngClass]="selectedLang == 'en' ? 'px-3' : ''" (click)="onSubmit()" [disabled]="loading">
                  @if(loading){
                  <span class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
                  }
                  {{SaveButtonText | translate}}
                </button>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</form>
