import { Injectable } from '@angular/core';
import { AccessRights } from '../_models/access-rights';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private currentUser;
  applyDynamicMenu: boolean = false;
  constructor(private localStorageService: LocalStorageService) { }

  public checkPermission(permissions: string[]) {
    this.currentUser = this.localStorageService.getSelectedUser();
    this.applyDynamicMenu = this.localStorageService.getSelectedUser().CompanyDetails.Subscribe;
    let hasPermission = false;
    let form_tier_1: any;
    let form_tier_2: any;
    let form_tier_3: any;
    let form_tier_4: any;
    let permissionLength: number = +permissions.length;
    if (this.applyDynamicMenu === false && permissions[0].trim().toLowerCase() !== 'invoice' && permissions[0].trim().toLowerCase() !== 'analytics' && permissions[1].trim().toLowerCase() !== 'accounting')
      hasPermission = true;
    else {
      let right = this.getRight(permissionLength, permissions);
      hasPermission = (right?.Access) ? right?.Access : false;
    }
    return hasPermission;
  }
  private checkAccess(name: string, rights: any[]): any {
    return rights.find(i => i.FormName.trim().toLowerCase() === name.trim().toLowerCase());
  }
  private getRight(permissionLength: number, permissions: string[]) {
    let form_tier_1: any;
    let form_tier_2: any;
    let form_tier_3: any;
    let form_tier_4: any;
    let rights: any;
    if (permissionLength > 0) {
      form_tier_1 = this.checkAccess(permissions[0], this.currentUser.Rights);

      if (permissionLength > 1 && form_tier_1) {
        form_tier_2 = this.checkAccess(permissions[1], form_tier_1.ChildRights);
      }
      if (permissionLength > 2 && form_tier_2) {
        form_tier_3 = this.checkAccess(permissions[2], form_tier_2.ChildRights);
      }
      if (permissionLength > 3 && form_tier_3) {
        form_tier_4 = this.checkAccess(permissions[3], form_tier_3.ChildRights);
      }

      switch (permissionLength) {
        case 1:
          if (form_tier_1) {
            rights = form_tier_1;
          }
          break;
        case 2:
          if (form_tier_2) {
            rights = form_tier_2;
          }
          break;
        case 3:
          if (form_tier_3) {
            rights = form_tier_2;
          }
          break;
        case 4:
          if (form_tier_4) {
            rights = form_tier_4;
          }
          break;
      }
    }
    return rights;
  }


  public checkTrial(permissions: string[]): any {
    this.currentUser = this.localStorageService.getSelectedUser();
    let permissionLength: number = +permissions.length;
    let right = this.getRight(permissionLength, permissions);
    if (right.StartDate && right.EndDate) {
      var d1 = new Date(right.StartDate);
      var d2 = new Date(right.EndDate);

      var check = new Date();
      if (check > d1 && check < d2) {
        right.Access = true;
        right.IsExpired = false;
        right.ShowBanner = true;
      }
      else {
        right.Access = false;
        right.IsExpired = true;
        right.ShowBanner = true;
      }
    }
    else {
      right.Access = true;
      right.IsExpired = false;
      right.ShowBanner = false;
    }


    return right;
  }

}

