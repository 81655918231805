<form [formGroup]="ingForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Ingredient' :SelectedName) | translate}}  </h4>
    <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Inventory','Inventory Setup','Ingredient', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <div class="row">
          <div class="col-lg-12 form-group " style="margin-top: 40px;">
            <label for="Name">{{'Name' | translate}}</label>
            <input type="text" class="form-control" id="name" placeholder="" formControlName="name">
            <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
              <div *ngIf="f.name.errors.required">
                {{'Name is required' | translate}}.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12 col-sm-12">
            <label for="Device Type">{{'Inventory Groups' | translate}}</label>
            <select class="custom-select form-control" formControlName="groupID">
              <option [ngValue]="option.id" selected="option.id == groupID"
                      *ngFor="let option of Groups">
                {{option.name}}
              </option>
            </select>
            <div *ngIf="f.groupID.invalid && (f.groupID.dirty || f.groupID.touched)"
                 class="alert alert-danger">
              <div *ngIf="f.groupID.errors.required">
                {{'Group Name is required' | translate}}.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12 col-sm-12">
            <label for="Device Type">{{'Ingredient Unit' | translate}}</label>
            <select class="custom-select form-control" formControlName="unitID">
              <option [ngValue]="option.unitID" selected="option.unitID == unitID"
                      *ngFor="let option of Units">
                {{option.unit}}
              </option>
            </select>
            <div *ngIf="f.unitID.invalid && (f.unitID.dirty || f.unitID.touched)"
                 class="alert alert-danger">
              <div *ngIf="f.unitID.errors.required">
                {{'Ingredient Unit is required' | translate}}.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 form-group ">
            <label for="cost">{{'Cost' | translate}}</label>
            <input type="number" class="form-control" id="cost" placeholder="9.9999" formControlName="cost">
            <div *ngIf="f.cost.invalid && (f.cost.dirty || f.cost.touched)" class="alert alert-danger">
              <div *ngIf="f.cost.errors.required">
                {{'Cost is required' | translate}} .
              </div>
              <div *ngIf="!f.cost.errors.required">
                {{'Invalid cost' | translate}}.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12 col-sm-12">
            <label for="Country">{{'Suppliers' | translate}}</label>
            <!-- <ng-select [multiple]="true"  [ngModelOptions]="{standalone: true}"  [(ngModel)]="selectedSuppliers">
                  <ng-option *ngFor="let car of Suppliers" [value]="car.id" [disabled]="car.disabled">{{car.name}}</ng-option>
                  <ng-option [value]="'custom'">Custom</ng-option>
      </ng-select> -->
            <ng-select [items]="Suppliers" bindLabel="name" class="custom" bindValue="id" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSuppliers">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items">
                  <span class="ng-value-label">{{item.name}}</span>
                  <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;" (click)="clear(item)" aria-hidden="true">×</span>
                </div>

              </ng-template>
            </ng-select>
            <div class="alert alert-danger" *ngIf="!selectedSuppliers || selectedSuppliers.length === 0">
              {{'Supplier is required' | translate }}.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 form-group">
            <label for="sku">{{'SKU' | translate}}</label>

            <div class="input-group mb-3 section-sku">
              <input type="text" class="form-control" placeholder="SKU-000" aria-label="Default" aria-describedby="inputGroup-sizing-default" formControlName="sku" [readonly]="SelectedID > 0" (input)="SelectedID === 0 && manualSKUChangeByUser =true">
              <div class="input-group-append" role="button">
                <span class="input-group-text button-generate" id="basic-addon2" [ngClass]="{'SKU-Disable': !enableSKU}" (click)="enableSKU && GenInventorySKU()">{{'Generate' | translate }}</span>
              </div>
            </div>

            <div *ngIf="f.sku.invalid && (f.sku.dirty || f.sku.touched)"
                 class="alert alert-danger">
              <div *ngIf="f.sku.errors.required"> {{'SKU is required' | translate}}. </div>
            </div>
          </div>
        </div>
        <div class="row form-group tiltle-bar">
          <div class="col-lg-8 col-sm-8 col-6">
            <label>{{'Has Life Period' | translate }}</label>
          </div>
          <div class="col-lg-4 col-sm-4 col-6 col-xl-4 text-right">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="hasLifePeriod" formControlName="hasLifePeriod">
              <label class="form-check-label" for="hasLifePeriod"></label>
            </div>
          </div>
          <div class="container px-4" *ngIf="f.hasLifePeriod.value === true">
            <div class="row">
              <div class="col-lg-12 form-group">
                <label for="life">{{'Life Period' | translate}}</label>
                <div class="input-group mb-3">
                  <input type="number" min="0" class="form-control" formControlName="life" aria-label="Default"
                         aria-describedby="inputGroup-sizing-default" required>
                  <div class="input-group-append">
                    <!-- <span class="input-group-text" id="inputGroup-sizing-default">Days</span> -->
                    <select class="custom-select form-control px-4" formControlName="lifePeriod">
                      <option [ngValue]="option.id" selected="option.id == lifePeriod"
                              *ngFor="let option of Period">
                        {{option.lifePeriod | translate}}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="f.lifePeriod.invalid && (f.lifePeriod.dirty || f.lifePeriod.touched)"
                     class="alert alert-danger">
                  <div *ngIf="f.lifePeriod.errors.required">{{'Life Period is required' | translate}}. </div>
                   {{'Life Period is invalid' | translate}}.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>
  </div>
</form>
