
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { PrintService } from 'src/app/_services/print.service';
import { AddreconciliationComponent } from './addreconciliation/addreconciliation.component';
import { Reconcillation } from '../../_models/Reconcillation';
import { ReconcillationService } from 'src/app/_services/reconcillation.service';
import { TrackEventsService } from '../../_services/track-events.service';


@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.css'],
  providers: [ExcelService]
})
export class ReconciliationComponent implements OnInit {
  data$: Observable<Reconcillation[]>;
  oldData: Reconcillation[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "reconciliation";
  submit: boolean;
  excelData = [];
  Locations = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: ReconcillationService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    public printService: PrintService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Reconciliations', type);
  }
  getBrandData() {
    this.service.geReconcillation(this.selectedBrand);
    this.data$ = this.service.data$;



    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    this.service.allData$.subscribe(res => {
      //debugger

      if (res != null) {

        this.Locations = [];


        new Set(res.map(res => res.locationName)).forEach(e => {
          //debugger
          this.Locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }


    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  add() {
    const modalRef = this.modalService.open(AddreconciliationComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Reconciliation");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(reconciliation) {
    const modalRef = this.modalService.open(AddreconciliationComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Reconciliation");
    modalRef.componentInstance.SelectedID = reconciliation.rcnid;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }


  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, reconciliation) {
    this.service.reconcillations.forEach((item, index) => {
      if (item.rcnid == reconciliation.rcnid) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }

  private getExportData() {
    let selected: any[];

    selected = [];
    this.service.reconcillations.forEach(d => {
      if (d.selected) {
        selected.push({
          Location: d.locationName,
          Date: d.date,
          Reference: d.ReferenceNo
        });
      }
    });
    if (selected.length == 0) {
      this.service.reconcillations.forEach(d => {
        selected.push({
          RCNCode: d.rcnCode,
          Location: d.locationName,
          Date: d.date,
          Reference: d.ReferenceNo,
          CreatedBy: d.creaedBy
        });
      });
    }
    return selected;
  }


  Print(rcn) {
    this.printService.printDocument('rcn', rcn.rcnid);
  }

  filters() {
    let headerFilter = {

      locationName: this.Locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })

    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }


  setSelectedResult(val) {

    if (val == 'location') {
      return this.Locations.filter(k => k.isChecked == true).length;
    }


  }
}

