import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { InventoryStock } from '../_models/GeneratedReport';

import * as $ from 'jquery';

interface SearchInventoryStockReportResult {
  data: InventoryStock[];
  total: number;
}
interface stockReportHeaderState {
  group: string[],
  location: string[],
  type: string[]
}

export const contentHeaders = new Headers();
const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: InventoryStock[], column: SortColumn, direction: string): InventoryStock[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: InventoryStock, term: string) {
  return data.Name.toLowerCase().includes(term.toLowerCase())
}

function matcheHeader(data: InventoryStock, filterValues: stockReportHeaderState) {
  let group = false;
  let location = false;
  let type = false;

  // Order Taker
  if (filterValues.group !== null && filterValues.group.length > 0) {
    for (var i = 0, len = filterValues.group.length; i < len; i++) {
      if (data.Group.toLowerCase().toString().includes(filterValues.group[i].toLowerCase())) {
        group = true;
      }
    }
  }
  else {
    group = true;
  }

  // Order Type
  if (filterValues.location !== null && filterValues.location.length > 0) {
    for (var i = 0, len = filterValues.location.length; i < len; i++) {
      if (data.Location.toLowerCase().toString().includes(filterValues.location[i].toLowerCase())) {
        location = true;
      }
    }
  }
  else {
    location = true;
  }
  // Order State
  if (filterValues.type !== null && filterValues.type.length > 0) {
    for (var i = 0, len = filterValues.type.length; i < len; i++) {
      if (data.Type.toLowerCase().toString().includes(filterValues.type[i].toLowerCase())) {
        type = true;
      }
    }
  }
  else {
    type = true;
  }
  if (group && location && type) {
    return data;
  }
}

@Injectable({
  providedIn: 'root'
})
export class InventoryStockReportService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _searchHeader$ = new Subject<void>();
  public _allData$ = new BehaviorSubject<InventoryStock[]>([]);
  private _data$ = new BehaviorSubject<InventoryStock[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public report: any;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  private _headerState: stockReportHeaderState = {
    group: null,
    location: null,
    type: null
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: stockReportHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }


  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {
  }

  // async downloadFile(CONNECT_STR: string, fileName: string) {
  //   const containerURL = await new AzureStorage.ContainerURL(
  //     CONNECT_STR,
  //     AzureStorage.StorageURL.newPipeline(
  //       new AzureStorage.AnonymousCredential()
  //     ));
  //   const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, fileName);
  //   const downloadResponse = await blockBlobURL.download(AzureStorage.Aborter.none, 0);
  //   return downloadResponse.blobBody;
  // }


  downloadProfilePic(dataUrl) {


  }


  public getInventoryStockReport(dataUrl) {


    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataUrl,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {

          _this.report = res;
          _this._data$.next(_this.report);
          _this._allData$.next(_this.report);


          _this._search$.pipe(
            switchMap(() => _this._searchByHeader()),
            tap(() => _this._loading$.next(false))
          ).subscribe(result => {
            _this._data$.next(result.data);
            _this._total$.next(result.total);
          });
          _this._searchHeader$.pipe(
            switchMap(() => _this._searchByHeader()),
            tap(() => _this._loading$.next(false))
          ).subscribe(result => {
            _this._data$.next(result.data);
            _this._total$.next(result.total);
          });

          _this._search$.next();
          _this._searchHeader$.next();
        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })

  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  private _setHeader(headerFilter: stockReportHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }

  private _search(): Observable<SearchInventoryStockReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData;
    return of({ data, total });
  }
  private _searchByHeader(): Observable<SearchInventoryStockReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData;
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._searchHeader$.next();
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

  getAllLocationsddl(id) {
    return this.http.get(`api/location/all/${id}`);
  }

  getReportTest(url) {
    return this.http.get(url);
  }



}

