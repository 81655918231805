import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(private http: HttpClient) {
  }

  getLicenses(type) {
    return this.http.get<any[]>(`api/license/all/${type}`);
  }
  
}
