import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { CustomerDto, CustomerOrderDto, CustomerOrdersPaginationDto, CustomerPurchaseSummaryDto } from '../_models/CustomerDto';
import { State } from '../_models/State';


interface SearchCustomerResult {
  data: CustomerDto[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: CustomerDto[], column: SortColumn, direction: string): CustomerDto[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: CustomerDto, term: string) {
  return data.fullName.toLowerCase().includes(term.toLowerCase()) ||
    data.gender.toLowerCase().includes(term.toLowerCase()) ||
    data.email.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.city.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.locationName.toString().toLowerCase().includes(term.toLowerCase()) ||
  data.mobileNumber.toString().toLowerCase().includes(term.toLowerCase())
}



//@@@

//@@@
interface FilterHeaderState {
  gender: string[],
  city: string[],
  locationName: string[],

}


function matcheHeader(data: CustomerDto, filterValues: FilterHeaderState) {
  let gender = false;
  let city = false;

  let location = false;

  // Gender
  if (filterValues.gender !== null && filterValues.gender.length >0) {
    for (var i = 0, len = filterValues.gender.length; i < len; i++) {
      if (data.gender.toLowerCase().includes(filterValues.gender[i].toLowerCase())) {
        gender = true;
      }
    }
  }
  else {
    gender = true;
  }


    // City
    if (filterValues.city !== null && filterValues.city.length >0) {
      for (var i = 0, len = filterValues.city.length; i < len; i++) {
        if (data.city.toLowerCase().toString().includes(filterValues.city[i].toLowerCase())) {
          city = true;
        }
      }
    }
    else {
      city = true;
    }



 // Locations
 if (filterValues.locationName !== null && filterValues.locationName.length >0) {
  for (var i = 0, len = filterValues.locationName.length; i < len; i++) {
    if (data.locationName.toLowerCase().toString().includes(filterValues.locationName[i].toLowerCase())) {
      location = true;
    }
  }
}
else {
  location = true;
}
if (city && location &&  gender) {
  return data;
}
  
}




@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<CustomerDto[]>([]);
  private _data$ = new BehaviorSubject<CustomerDto[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public customers: CustomerDto[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    //@@ Customer Header Filter Start
    private _searchHeader$ = new Subject<void>();
    get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter);}

    private _headerState: FilterHeaderState = {
      gender: null,
      city: null,
      locationName: null
    }
    private _setHeader(headerFilter:FilterHeaderState) {
      this._headerState = headerFilter;
      this._searchHeader$.next();
    }


    private _searchByHeader(): Observable<SearchCustomerResult> {
      //debugger
      const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
      // 1. sort
      let sortedData = sort(this.customers, sortColumn, sortDirection);
  
      //// 2. filter
      sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
      sortedData = sortedData.filter(data => matches(data, searchTerm));
  
  
      const total = sortedData.length;
  
      // 3. paginate
      const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      this._data$.next(data);
      this._total$.next(total);
      return of({ data, total });
    }

  //@@ Customer Header Filter End
    
  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }
  constructor(private http: HttpClient) { }

  private _search(): Observable<SearchCustomerResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.customers, sortColumn, sortDirection);
    ////// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }


  public getAll(brandID) {
    const url = `api/customer/all/${brandID}/`;
    tap(() => this._loading$.next(true)),
      this.http.get<CustomerDto[]>(url).subscribe(res => {
        this.customers = res;
        //new Set(res.map(val => val)).forEach((e: any) => {
        //  let customer = e
        //  customer.gender = (e.gender == '0') ? 'Not Defined' : e.gender;
        //  customer.city = (e.city == '') ? 'Others' : e.city
        //  this.customers.push(customer)
        //});
        this.customers.forEach(e => {
          e.gender = (e.gender == '0') ? 'Not Defined' : e.gender;
          e.city = (e.city == '') ? 'Others' : e.city
        });

        this._data$.next(this.customers);
        this._allData$.next(this.customers);

        
        
        
        // this._search$.pipe(
        //   switchMap(() => this._search()),
        //   tap(() => this._loading$.next(false))
        // ).subscribe(result => {
        //   this._data$.next(result.data);
        //   this._total$.next(result.total);
        // });
        // this._search$.next();



        // @@@
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          console.log('result.data');
          console.log(result.data);
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

         this._search$.next();
         this._searchHeader$.next();
       
      });



    
  }

  
  public get(CustomerID) {
    return this.http.get<CustomerDto[]>(`api/customer/${CustomerID}`);
  }
  insert(data) {
    return this.http.post(`api/customer`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  update(data) {
    return this.http.put(`api/customer`, data)
      .pipe(map(res => {
        return res;
      }));
  }
 
  public UpdateStatus(id, version, statusID) {
    return this.http.patch(`api/customer/UpdateStatus/${id}/${version}/${statusID}`, null);
  }
  public GetOrders(CustomerID, brandId, pageNumber, pageSize, orderBy) {
    return this.http.get<CustomerOrdersPaginationDto>(`api/customer/GetOrders/${CustomerID}/${brandId}/${pageNumber}/${pageSize}/${orderBy}`);
  }
  public GetPurchaseSummary(CustomerID, brandId) {
    return this.http.get<CustomerPurchaseSummaryDto>(`api/customer/GetPurchaseSummary/${CustomerID}/${brandId}`);
  }
}
