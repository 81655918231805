import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-conversion-calculator',
  templateUrl: './conversion-calculator.component.html',
  styleUrls: ['./conversion-calculator.component.css']
})
export class ConversionCalculatorComponent implements OnInit {

  loading=false;
  conversionForm: UntypedFormGroup;
  total = 0;
  @Input() selectedItem = null;
  constructor(private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal) {
    this.createForm();
  }

  ngOnInit() {
  }
  get f() { return this.conversionForm.controls; }
  private createForm() {
    //this.Location = DataService.Name;
    this.conversionForm = this.formBuilder.group({
      bulkQuantity: [1, Validators.required],
      itemBulk: [1, Validators.required]
    });
  }
  calculateQty()
  {
    this.total = (this.selectedItem.cost !== null && this.selectedItem.cost !== undefined) ? this.selectedItem.cost *(this.f.bulkQuantity.value * this.f.itemBulk.value): this.f.bulkQuantity.value * this.f.itemBulk.value;
  }
  onSubmit() {
    this.selectedItem.quantity = this.f.bulkQuantity.value * this.f.itemBulk.value;
    this.activeModal.dismiss();
  }
}
