<div class="row mx-1">
  <div class="form-group col-lg-12 col-sm-12 Upload-Files border p-2 rounded" [ngClass]="{'disabled': Disable}" >
    <input type="file" (change)="onFileChange($event)" class="form-control" accept=".png, .jpg, .jpeg, .png, .svg, .gif, .pdf" max-size="1000"
           #itemFile id="itemFile" (click)="itemFile.value = null" value="" [disabled]="Disable" [ngClass]="{'disabled': Disable}" />
    <div class="d-flex row">
      <img src="../../assets/images/UploadFileImage.svg" height="75" class="justify-content-center my-2" />
      <label id="file-input-label" for="itemFile" [ngClass]="{'blue': !Disable}" (click)="currentFile = null"
             role="button" class="text-center">{{(currentFile) ? currentFile.name :'Click to upload' | translate}}</label>
      <label class="text-center">{{'SVG, PNG, JPG, PDF or GIF (max. 800 x 400px)' | translate}}</label>
    </div>
  </div>
</div>
