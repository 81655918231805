import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


@Injectable({ providedIn: 'root' })
export class ExcelService {
  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string, extension): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: extension, type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName, extension);
  }

  private saveAsExcelFile(buffer: any, fileName: string, extension): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + extension);
  }


  public exportAsExcelFileFromHTML(html:any, excelFileName: string, extension): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(html);
    worksheet['!cols'].push({ wpx: 200 });
    worksheet['!cols'].push({ wpx: 200 });
    console.log('worksheet', worksheet);
    
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: extension, type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName, extension);
  }
}
