<form [formGroup]="icForm" class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title"> {{'Inventory Count Update' | translate}} </h4>
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown" *ngIf="SelectedID === 0">
      <button [disabled]="loading" type="button" class="btn-primary btn-sm cutom-button custom-GroupButton" (click)="onSubmit('save')" *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Create']">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ButtonText | translate}}
      </button>
      <div class="btn-group" role="group">
        <button [disabled]="loading" id="btnGroupDrop1" type="button" class="btn-primary btn-sm custom-SubButton dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngbPopover]="popActions">
          <i [disabled]="loading" class="downArrow-white"></i>
        </button>
      </div>
    </div>
    <ng-template #popActions>
      <p class="dropdown-heading-mini">{{'Inventory Count' | translate }}</p>
      <a *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Create & Approve']" (click)="onSubmit('approve')"> {{'Save & Approve' | translate }}</a>
    </ng-template>
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown" *ngIf="SelectedID !== 0 && (f.statusID.value === 602)">
      <button type="button" class="btn-primary btn-sm cutom-button" (click)="onSubmit('approve')" *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Approve']">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'Approve' | translate}}
      </button>
      <div class="btn-group" role="group">
        <button [disabled]="loading" id="btnGroupDrop1" type="button" class="btn-primary btn-sm custom-SubButton dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngbPopover]="popUpdateStatusActions">
          <i [disabled]="loading" class="downArrow-white"></i>
        </button>
      </div>
    </div>
    <ng-template #popUpdateStatusActions>
      <p class="dropdown-heading-mini">{{'Inventory Count' | translate }}</p>
      <a *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Decline']" (click)="onSubmit('decline')" style="color: #8B0000 !important">{{'Decline' | translate }}</a>
    </ng-template>
    <button [disabled]="loading" class="btn btn-link" (click)="generatePDF()" *ngIf="f.statusID.value === 601 || f.statusID.value === 603">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{'Print' | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 mt-5">

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Inventory count update number' | translate }}</label>
              <input type="text" class="form-control disabled " id=name placeholder="" formControlName="icCode" [readonly]="true">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'Location' | translate }}</label>
              <select class="custom-select form-control" formControlName="locationID" (change)="loadItems()" [class.disabled]="SelectedID !== 0" >
                <option [ngValue]="option.locationID" [disabled]=option.maped
                        selected="option.locationID == locationID" *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Notes">{{'Note' | translate }}</label>
              <textarea class="form-control mh-80 h-75" formControlName="notes"
                        [class.disabled]="f.statusID.value === 601 || f.statusID.value === 603" [readonly]="f.statusID.value === 601 || f.statusID.value === 603"></textarea>

            </div>
          </div>

          <div class="tiltle-bar form-group">
            <div class="row">
              <div class="col-12">
                <label for="socialMedia">{{'Add Inventory count updated items' | translate}}</label>
                <p>{{'Select the item to be updated and add the count quantity' | translate}}</p>
              </div>

            </div>
          </div>

          <div class="row">
            <label for="Conversion">{{'Add Items' | translate }}</label>
            <div class="input-group form-group">
              <div class="input-group-append w-75">
                <ng-select [items]="Items" #select [closeOnSelect]="true" class="po-box-autocomplete border-right-radius-unset " placeholder="{{'Add Items' | translate }}"
                           [bindLabel]="(selectedLang == 'en' ? 'name' : 'alternateName')" bindValue="supplyID" (change)="addRow($event)"
                           [(ngModel)]="stockCountDetails.supplyID" [ngModelOptions]="{standalone: true}" [searchFn]="SearchItem"
                           [class.disabled]="f.statusID.value === 601 || f.statusID.value === 603" [readonly]="f.statusID.value === 601 || f.statusID.value === 603">
                  <ng-template ng-header-tmp>
                    <div (click)="closeSelect(select)" *ngIf="isSelectAll === false">
                      <button class="dropdown-item"
                              (click)="onSelectAll(select)">
                        {{'Select All' | translate }}
                      </button>
                    </div>

                    <div (click)="closeSelect(select)" *ngIf="isSelectAll === true">
                      <button class="dropdown-item"
                              (click)="onDeSelectAll(select)">
                        {{'Deselect All' | translate }}
                      </button>
                    </div>
                  </ng-template>

                </ng-select>
              </div>
              <div ngbDropdown class="nav-item w-25 px-3" placement="bottom">
                <button class="profile btn export-btn btn-lg h-100" (click)="import()" style="width:100% !important;" [disabled]="loading || f.statusID.value === 601 || f.statusID.value === 603"
                        [class.disabled]="f.statusID.value === 601 || f.statusID.value === 603" >
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
                  <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
                </button>
              </div>
            </div>
          </div>
          <section class="tile" style="border:none;box-shadow:none">
            <div class="p-0 table-responsive ">
              <table class="table table-striped po-item">
                <thead>
                  <tr class="table-header">
                    <th scope="col" width="5%"><img src="/assets/images/icons/v2/camera.svg" /></th>
                    <th scope="col" width="37%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                    <th scope="col" width="15%" class="border-start-width-0 border-end-width-0">{{'Current QTY' | translate }}</th>
                    <th scope="col" width="15%" class="border-end-width-0">{{'Update QTY' | translate }}</th>
                    <th scope="col" width="10%" class="border-end-width-0">{{'Difference' | translate }}</th>
                    <th scope="col" width="15%" class="border-end-width-0">{{'Cost' | translate }}</th>
                    <th scope="col" width="5%" class="border-radius-topright-10 border-start-width-0 border-end-width-0"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of arrItems">
                    <td>
                      <img [src]="item.itemImage" *ngIf="(item.itemImage !== '' && item.itemImage !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                      <img *ngIf="(item.itemImage === '' || item.itemImage === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="table-img" alt="" />
                    </td>
                    <td scope="row" class="font-weight-bold">{{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}</td>
                    <td>
                      {{item.currentStockQTY}} {{item.unit}}
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}" (ngModelChange)="UpdateQty(item)" class="form-control-price"
                             [class.disabled]="f.statusID.value === 601 || f.statusID.value === 603" [readonly]="f.statusID.value === 601 || f.statusID.value === 603">
                    </td>
                    <td>
                      <span>{{item.difference}}</span>
                    </td>
                    <td>
                      {{item.totalCost | number : '1.2-2'}}
                    </td>
                    <td>
                      <a *ngIf="f.statusID.value !== 601 && f.statusID.value !== 603"><img src="/assets/images/icons/v2/Delete.svg" (click)="deleteRow(item)" /></a>
                    </td>
                  </tr>
                  <tr *ngIf="arrItems.length === 0">
                    <td colspan="10">
                      <div class="row p-1">
                        <div class="col-12 text-center">
                          <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                        </div>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </section>
          <div class="row">
            <div class="col-4">
            </div>
            <div class="col-8">

              <div class="tiltle-bar form-group border-0">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <label class="font-red">{{'Total Losses' | translate }}</label>
                      </div>
                      <div class="col-6">
                        <label class="font-red move-to-end">{{f.totalLosses.value | number : '1.2-2'}} {{brandCurrency}}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="font-red">{{'Total Miscounted' | translate }}</label>
                      </div>
                      <div class="col-6">
                        <label class="font-red move-to-end">{{f.totalMiscounted.value | number : '1.2-2'}} {{brandCurrency}}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label>{{'Total Difference' | translate }}</label>
                      </div>
                      <div class="col-6">
                        <label class="move-to-end">{{f.totalDifference.value | number : '1.2-2'}} {{brandCurrency}}</label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="f.stockCountLog.value">
            <hr />
            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="fw-bold text-center">{{'Logs' | translate }}</div>
                <p class="text-center">{{'Activities on this Inventory Count' | translate }}</p>
              </div>
            </div>
            <div class="row" *ngFor="let log of f.stockCountLog.value">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="row g-0 d-flex align-items-center">
                  <div class="col-lg-1 col-sm-1 col">
                    <img src="../../../assets/images/icons/LogCreated.svg" class="pe-1" *ngIf="log.activity.includes('create')" />
                    <img src="../../../assets/images/icons/Void_Sign.svg" class="pe-1" *ngIf="log.activity.includes('Decline')" />
                    <img src="../../../assets/images/icons/LogApproved.svg" class="pe-1" *ngIf="log.activity.includes('Approve')" />
                    
                  </div>
                  <div class="col-6 text-break" [ngClass]="log.activity.includes('Decline') ? 'col-lg-8 col-sm-8' : 'col-lg-6 col-sm-6'">
                    <span class="fw-bold">{{log.activity | translate}}</span>&nbsp;
                    <span>{{log.activityBy}}&nbsp;</span>
                  </div>

                  <div [ngClass]="log.activity.includes('Decline') ? 'col-lg-3 col-sm-3 col' : 'col-lg-5 col-sm-5 col'">
                    <span class="float-end">
                      {{log.dateStamp | date:"dd MMM yyyy  hh:mm a"}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </div>
</form>
