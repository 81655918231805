<main>
    <div class="page">
      <div class="page-header">


        <div class="row">
          <div class="col-md-12" style="padding-right:1px">
            <img [src]="selectedBrand.BrandLogo" style="width:50px" />
          </div>
          <div class="col-md-12 text-right" style="padding-left:1px; font-size:20px">
            {{selectedBrand.BrandName}}
          </div>
        </div>

      </div>
        <div class="page-body">

        <!-- <div class="preview-filter-data">{{selectedReport.dateRange}}</div>
        <div class="preview-filter-data">{{selectedReport.locations}}</div> -->

            <section class="tile" style="margin-top: 20px">
               
              <div class="row text-center" style="background-color: #ebedee !important">
                <div class="col-lg-12">
                  {{selectedReport.locations}}
                </div>
                <div class="col-lg-12">
                  {{selectedReport.dateRange}}
                </div>
                <div class="col-lg-12">
                  Generated By: {{selectedReport.generatedBy}}
                </div>
                <div class="col-md-12">
                  {{selectedReport.reportName}}
                </div>
                <div class="col-md-12">
                  Generated On: {{selectedReport.generatedOn | date:'MM dd, yyyy'}}
                </div>
              </div>


                <div class="tile-body p-0 table-responsive ">
                    <table class="table table-striped">
                        <thead>
                            <tr class="table-header">
                                <th width="15%" sortable="Location" >BRANCH  </th>
                                <th width="10%" sortable="Date">DATE  </th>
                                <th width="10%" sortable="Time">TIME  </th>
                                <th width="15%" sortable="Name">ITEM  </th>
                                <th width="10%" sortable="ReceivedQty">QUANTITY  </th>
                                <th width="15%" sortable="SKU">SKU  </th>
                                <th width="10%" sortable="CurrentStock">IN STOCK  </th>
                                <th width="15%" sortable="Type">TYPE  </th>
                            </tr>
                        </thead>
                        <tbody>

                         
                            <tr *ngFor="let item of InvLog"> 
                                <td>  {{item.Location}} </td>
                                <td> {{item.Date | date}} </td>
                                <td> {{item.Time | timeFormat}} </td> 
                                <td> <div class="report-item"> {{item.Name}}</div> </td>
                                <td> {{item.ReceivedQty}} </td>
                                <td> {{item.SKU}} </td>
                                <td>
                                    <ngb-highlight  class="btn btn-lg w-auto btn-active" [result]="item.CurrentStock" [term]="service.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td> <div class="report-stock-type">{{item.Type}}</div>  </td>
                                
                                
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
                    <div class="col-lg-12" style="margin-top:1%">
                      Powered By marn.com
                    </div>
                  </div>
            </section>
           
        </div>
    </div>
</main>
