import { Component, Injectable, Input } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'ngbd-datepicker-adapter',
  templateUrl: './datepicker-adapter.html',

  // NOTE: For this example we are only providing current component, but probably
  // NOTE: you will want to provide your main App Module
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class NgbdDatepickerAdapter {

  model: string;
  dateModel: any;
  maxDate = { year: 2048, month: 12, day: 31 };
  @Input() AcceptPreviousDate: boolean = true;
  @Input() AcceptFutureDate: boolean = true;
  @Input() AllowEmptyDate: boolean = false;
  @Input() Disable: boolean = false;
  @Input() minDate = { year: 1950, month: 1, day: 1 };
  constructor(private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>, public formatter: NgbDateParserFormatter) {
    this.model = this.today();
    var date = this.ngbCalendar.getToday();
    date.day += 1;
    this.dateModel = this.formatter.parse(this.dateAdapter.toModel(date));
  }

  today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  setDate(date) {
    if (date != null && date != undefined && date != '') {
      var isoDate = (new Date(Date.parse(date))).toISOString()
      var userDate = new Date(isoDate);
      var ngbDateStruct = { day: userDate.getDate(), month: userDate.getMonth() + 1, year: userDate.getFullYear() };
      this.dateModel = this.formatter.parse(this.dateAdapter.toModel(ngbDateStruct));
      this.model = this.dateAdapter.toModel(this.dateModel);
    }
  }
  selectDate(date: NgbDate) {
    date.day = date.day + 1;
    this.dateModel = this.formatter.parse(this.dateAdapter.toModel(date));
  }
  ngAfterViewInit(): void {
  }
  ngOnInit() {
    if (this.AllowEmptyDate === true) {
      this.model = '';
      this.dateModel = null;
    }
    else {
      this.model = this.today();
    }
    if (this.AcceptPreviousDate === false) {
      this.minDate = {
        day: this.ngbCalendar.getToday().day,
        month: this.ngbCalendar.getToday().month,
        year: this.ngbCalendar.getToday().year
      }
    }
    if (this.AcceptFutureDate === false) {
      this.maxDate = {
        day: this.ngbCalendar.getToday().day,
        month: this.ngbCalendar.getToday().month,
        year: this.ngbCalendar.getToday().year
      }
    }
  }
}
