
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';


@Component({
  selector: 'app-inventoryaction',
  templateUrl: './inventoryaction.component.html',
  providers: []
})
export class InventoryactionComponent implements OnInit {

  active = "purchaseorder";

  constructor() {
  }

  ngOnInit() {
  }
}

