import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../_services/local-storage.service';
import { UserinfoService } from '../_services/userinfo.service';

@Component({
  selector: 'app-switchlocation',
  templateUrl: './switchlocation.component.html',
  styleUrls: ['./switchlocation.component.css']
})
export class SwitchlocationComponent implements OnInit {


  SelectedLocation: any;
  Locations: any;
  constructor(private localStorageService: LocalStorageService,) { }

  ngOnInit() {

    if (this.localStorageService.checkAlreadySelected()) {

      this.Locations =  this.localStorageService.getSelectedBrand().Locations;
      this.SelectedLocation = this.Locations[0];
      this.SelectedLocation = this.localStorageService.getSelectedLocation();
    }
    else {
  
      this.Locations = this.localStorageService.getSelectedBrand().Locations;
      this.SelectedLocation = this.Locations[0];

    }
  }

  public changeLocation(location) {

    this.SelectedLocation = location;
    this.localStorageService.setSelectedLocation(this.SelectedLocation);
    window.location.reload();
  }



  
}
