<form [formGroup]="partnerForm" class="form msegat">
  <div class="modal-header">
    <div class="d-flex" style="width: 100%;">
      <div class="d-flex align-items-start justify-content-start flex-1">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-center flex-1">
        <h4 class="modal-title">
          {{Partner?.name | translate}}
        </h4>
      </div>
      <div class="d-flex align-items-end justify-content-end flex-1">
        <a class="btn-default btn-grey" href="{{Partner?.url}}" target="_blank">
          {{'Learn More' | translate}}
        </a>
        <button [disabled]="loading" class="btn-primary cutom-button btn-save" (click)="onSubmit()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body integration">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" style="margin-top: 40px;">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <div class="logo-placeholder">
                <div class="card-img-top" [ngStyle]="{'background-image':'url(' + Partner?.logo + ')'}" style="background-position: center;
                    background-size: cover;
                    height: 166px;
                    background-repeat: no-repeat;
                    width: 100%;"> </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <h6 class="heading"> {{'Permissions' | translate }}:</h6>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <ul class="rules">
                <li *ngFor="let r of Rules">{{r.claimType}}</li>
              </ul>
            </div>
          </div>
          <div class="line"></div>
          <h6 class="heading"> {{'Setup' | translate }}:</h6>
          <p class="description">
            {{'To Setup the install you need to' | translate}}:
          </p>
          <p class="description ms-0 p-0 m-0">{{'1. After Signing Up in GrubTech, add Marn’s location keys in GrubTech' | translate }}</p>
          <p class="description ms-0 p-0 m-0">{{'2. Select the locations you want to Integrate' | translate }}</p>
          <p class="description ms-0 p-0 m-0">{{'3. Click on the “Install” button to complete the installation' | translate }}</p>
          <div class="row pt-5">
            <div class="form-group col-lg-12 col-sm-12">

              <label>{{'Locations' | translate}}</label>
              <div class="tile-body p-0 table-responsive text-nowrap tile">
                <table class="table table-striped text-start">
                  <thead>
                    <tr class="table-header">
                      <th class="text-start">{{'Location' | translate}}</th>
                      <th>{{'Integrate' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let location of Locations">
                      <td class="text-start"> {{location.name}} </td>
                      <td>
                        <input type="checkbox" class="form-check-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="location.isIntegrated" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ng-container *hasPermission="['Marketplace','Uninstall']">
            <div class="line" *ngIf="f.integrationID.value >0" style="margin-top: 30px;"></div>
            <h6 class="heading" *ngIf="f.integrationID.value >0"> {{'Danger zone' | translate }}</h6>
            <div class="row" style="margin-top: 30px;" *ngIf="f.integrationID.value >0">
              <div class="col-6">
                <label> {{'Uninstall this App' | translate }} </label>
              </div>
              <div class="col-6 text-right">
                <button [disabled]="loading" class="btn btn-danger btn-uninstall" (click)="openConformation()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'Uninstall' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>

      </div>
    </div>
  </div>
</form>
