<div class="conainer">
  <div class="row">
    <div class="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-4">
      <img src="/assets/images/icons/Featured_Icon.svg" class="p-2" />
      <h4 class="modal-title fw-bold">{{'Automatic Account Closure' | translate }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 px-5">
      <p>
        {{'Dear Marn valued partner' | translate}}
      </p>
      <p>
        {{'Due to the upgrade of our infrastructure, we would like to inform you of the necessity to update your information. This is to ensure an exceptional experience for our customers.' | translate}}
      </p>
      <p>
        {{'We kindly request that you update your information to avoid automatic system closure. If you encounter any issues during the update process, please don\'t hesitate to reach out directly to our dedicated support team at' | translate}}
        <span class="name-blue">{{' 920006276.'}}</span>
      </p>
      <p>
        {{'Thank you for your cooperation and understanding.' | translate}}
      </p>
    </div>
  </div>
  <div class="row p-3 move-to-end">
    <div class="create-btn">
      <button (click)="openLink()" class="btn btn-primary cutom-button custom-create-btn">
        {{'Update Information' | translate}}
      </button>
    </div>
  </div>
</div>
