import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepickerAdapter } from 'src/app/datepicker/datepicker-adapter';
import { CustomerOrderDto, CustomerPurchaseSummaryDto } from '../../_models/CustomerDto';
import { AlertService } from '../../_services/alert.service';
import { CityService } from '../../_services/city.service';
import { CountryService } from '../../_services/country.service';
import { CustomerService } from '../../_services/customer.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as myGlobals from '../../_helpers/globals';
import { PreviewOrderComponent } from '../../orders/preview-order/preview-order.component';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  ButtonText = "Save";
  submitted = false;
  loading = false;
  purchaseSummaryLoading = false;
  ordersLoading = false;
  Countries: any;
  Cities: any;
  customerForm: UntypedFormGroup;
  orders: CustomerOrderDto[];
  purchaseSummary: CustomerPurchaseSummaryDto;
  brandCurrency: string = '';
  pageClick: number = 0;
  totalPages: number = 0;
  selectedFilterName: string = '';
  selectedFilter: number = 1;
  @ViewChild(NgbdDatepickerAdapter, { static: true }) dateComp;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private customerService: CustomerService,
    private alertService: AlertService,
    private countryService: CountryService,
    private cityService: CityService,
    private modalService: NgbModal) {
    this.createForm();
    this.loadCountries();
    this.loadCities();
  }

  ngOnInit() {
    this.brandCurrency = myGlobals.brandCurrency(this.localStorageService);
    this.setSelectedCustomer();
    this.dateComp.model = null;
    this.dateComp.dateModel = null;
    this.dateComp.AllowEmptyDate = true;
  }

  get f() { return this.customerForm.controls; }
  private createForm() {

    this.customerForm = this.formBuilder.group({
      customerID: 0,
      fullName: [''],
      mobileNumber: ['', Validators.required],
      email: ['', Validators.email],
      gender: [''],
      dob: [null],
      country: ['SA', Validators.required],
      city: ['Riyadh', Validators.required],
      address: [''],
      carDetails: [''],
      locationID: parseInt(this.localStorageService.getSelectedLocation().LocationID),
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      statusID: 1,
      rowVersion: 0,
      vatID: ['', Validators.required],
      crn: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(15)]],
      notes: [''],
      province: ['', Validators.required],
      district: ['', Validators.required],
      postalCode: ['', Validators.required],
      streetName: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      additionalNumber: [''],
      businessCustomer: false,
      nationalID: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/) ,Validators.minLength(10), Validators.maxLength(10)]]
    });
  }
  close() {
    this.activeModal.dismiss();
  }
  private loadCountries() {
    this.countryService.getCountries().subscribe((res: any) => {
      this.Countries = res;
    });
  }
  loadCities(city:string =null) {
    this.cityService.getCities(this.f.country.value).subscribe((res: any) => {
      this.Cities = res;
      if (!city)
        this.f.city.setValue(this.Cities[0].name);
      else
        this.f.city.setValue(city);
    });
  }
  setSelectedCustomer() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loading = true;
      this.customerService.get(this.SelectedID).subscribe(res => {
        //Set
        console.log(res)
        this.editForm(res);
        this.SelectCustomerPurchaseSummary();
        this.SelectCustomerOrders(1, 'Latest', false);
        this.loading = false;
      });
    }
    else {
      this.ButtonText = "Save";

    }
  }
  SelectCustomerPurchaseSummary() {
    if (this.SelectedID !== 0) {
      this.purchaseSummaryLoading = true;
      this.customerService.GetPurchaseSummary(this.SelectedID, parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe(res => {
        //Set
        this.purchaseSummary = res;
        this.purchaseSummaryLoading = false;
      });
    }
  }
  SelectCustomerOrders(orderBy: number, filterName, pageClick: boolean = false) {
    if (this.SelectedID !== 0) {
      this.selectedFilter = orderBy;
      this.selectedFilterName = filterName;
      if (pageClick) {
        this.pageClick++;
      }
      else {
        this.pageClick = 1;
      }
      this.ordersLoading = true;
      this.customerService.GetOrders(this.SelectedID, parseInt(this.localStorageService.getSelectedBrand().BrandID), 1, this.pageClick * 5, orderBy).subscribe(res => {
        //Set
        this.orders = res.orders;
        this.totalPages = res.pageCount.count;
        this.ordersLoading = false;
      });
    }
  }
  editForm(customer) {

console.log(customer);
    this.f.customerID.setValue(customer.customerID);
    this.f.fullName.setValue(customer.fullName);
    this.f.mobileNumber.setValue(customer.mobileNumber);
    this.f.rowVersion.setValue(customer.rowVersion);
    this.f.email.setValue(customer.email);
    this.f.gender.setValue(customer.gender);
    this.f.country.setValue(customer.country);
    //this.f.city.setValue(customer.city);
    this.f.address.setValue(customer.address);
    this.f.carDetails.setValue(customer.carDetails);
    this.f.locationID.setValue(customer.locationID);
    this.f.brandID.setValue(customer.brandID);
    this.f.statusID.setValue(customer.statusID);
    this.f.vatID.setValue(customer.vatID);

    this.f.crn.setValue(customer.crn);
    this.f.notes.setValue(customer.notes);
    this.f.province.setValue(customer.province);
    this.f.district.setValue(customer.district);
    this.f.postalCode.setValue(customer.postalCode);
    this.f.streetName.setValue(customer.streetName);
    this.f.buildingNumber.setValue(customer.buildingNumber);
    this.f.additionalNumber.setValue(customer.additionalNumber);
    this.f.businessCustomer.setValue(customer.businessCustomer);
    this.f.nationalID.setValue(customer.nationalID);

    this.enableBusinessCustomer();

    if (customer.dob) {
      this.dateComp.setDate(customer.dob);
      //this.f.dob.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day));
    }
    this.loadCities(customer.city);

  }
  enableBusinessCustomer(){
    console.log(this.f.businessCustomer.value)
    if(this.f.businessCustomer.value === true){
    var businessinfo = document.getElementById('btnBusinessInfo') as HTMLElement;
   if(businessinfo.getAttribute('aria-expanded') === 'false')
    businessinfo.click();
    var address = document.getElementById('btnAddress') as HTMLElement;
    if(address.getAttribute('aria-expanded') === 'false')
    address.click();
    }
  }
  setDate() {
    if (this.dateComp.dateModel !== null) {
      this.f.dob.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day));
    }
  }
  previewOrder(orderID) {
    const modalRef = this.modalService.open(PreviewOrderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = orderID;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.SelectCustomerOrders(this.selectedFilter, this.selectedFilterName, false);
    });
  }
  onSubmit() {
    if (this.dateComp.dateModel) {
      this.f.dob.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day));
    }

    this.customerForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();


    if ((this.f.businessCustomer.value && this.customerForm.invalid) || (!this.f.businessCustomer.value && (this.f.mobileNumber.invalid || this.f.email.invalid || this.f.nationalID.invalid))) { return; }
    this.loading = true;

    this.f.locationID.setValue(this.localStorageService.getSelectedLocation().LocationID === null ? this.localStorageService.getSelectedBrand().Locations[1].LocationID : this.f.locationID.value);

    if (parseInt(this.f.customerID.value) === 0) {
      //Insert item
      console.log(JSON.stringify(this.customerForm.value));
      this.customerService.insert(this.customerForm.value).subscribe(data => {
        this.alertService.success("Customer has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update item
      this.customerService.update(this.customerForm.value).subscribe(data => {
        this.alertService.success("Customer has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }
}
