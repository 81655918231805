import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { MarketplaceService } from 'src/app/_services/marketplace.service';
import { Msegat } from 'src/app/_models/marketplace';
import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';

@Component({
  selector: 'app-msegat',
  templateUrl: './msegat.component.html',
  styleUrls: ['./msegat.component.css']
})
export class MsegatComponent implements OnInit {
  @Input() PartnerID = 0;


//  textSample="Use {{Order}}, {{Customer}} and {{Location}} to make dynamic messages, fees may vary.";
  textSample="Use {{Order}}, {{Customer}} and {{Location}} {{Receipt}} to add Customer's name and other info dynamically if existed. fees may vary if you're using dynamic keywording.";
  ButtonText = "Install";
  submitted = false;
  msegatForm: UntypedFormGroup;
  private selectedBrand;
  loading = false;

  IntegratedpaymentLinks = [{id:1, name:'Moyasar'},{id:2, name:'Payfort'}]
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService:AlertService,
    private services:MarketplaceService,
    private _modalService:NgbModal
    ) {
    this.createForm();

   }

  ngOnInit() {
    this.setSelectedPartner();
  }




  setSelectedPartner(){

    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getMsegat(this.f.brandID.value,this.PartnerID).subscribe((res:Msegat[]) => {
        //Set Forms
        console.log(res);
        if(res !==null){
          this.editForm(res);
          this.ButtonText = "Save";
        }
        this.loading = false;
      });
    }
  }



  private createForm() {

    this.msegatForm = this.formBuilder.group({
      Username: ['', Validators.required],
      APIKey: ['', Validators.required],
      SenderName: ['', Validators.required],
      //CustomerPurchaseMsg: ['Thank you {{Customer}} for visiting us in {{Location}}, Your order is confirmed, hope to see you again!', Validators.required],
      CustomerPurchaseMsg: ["Thank you {{Customer}} for visiting us in {{Location}}, here's your {{receipt}} Your order is confirmed, hope to see you again!", Validators.required],
      statusID: [true, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      isCustomerPurchase: [false, Validators.required],
      msegatID: 0,
      rowVersion: 0,
      partnerID:this.PartnerID,
      integrationID:0
    });
  }



  private editForm(value) {

    debugger
    this.f.Username.setValue(value.userName);
    this.f.CustomerPurchaseMsg.setValue(value.customerPurchaseMsg);
    this.f.APIKey.setValue(value.apiKey);
    this.f.SenderName.setValue(value.senderName);
    this.f.statusID.setValue(value.statusID === 1 ? true : false);
    this.f.isCustomerPurchase.setValue(value.isCustomerPurchase);
    this.f.msegatID.setValue(value.msegatID);
    this.f.integrationID.setValue(value.integrationID);

  }



  get f() { return this.msegatForm.controls; }

  ngAfterViewInit(){
    // document.getElementById("terminalName").focus();
  }


  onSubmit(){
    this.msegatForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.partnerID.setValue(this.PartnerID);

    if (this.msegatForm.invalid) { return; }
    this.loading = true;


    if (parseInt(this.f.msegatID.value) === 0) {

    //   //Insert
       console.log(this.msegatForm.value);
      this.services.insert(this.msegatForm.value).subscribe(data => {
        this.alertService.success("Msegat has installed successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

     } else{
    //   //Update
      this.services.update(this.msegatForm.value).subscribe(data => {
        this.alertService.success("Msegat has saved successfully");
        this.loading = false;
        this.PartnerID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
  }
  }


  close(){
    this.activeModal.dismiss();
  }

  uninstall() {
    this.loading = true;
    this.services.Uninstall(parseInt(this.f.integrationID.value)).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Msegat has uninstalled successfully");
      this.loading = false;
        this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }


  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true,windowClass: 'uninstall-modal',modalDialogClass: 'uninstall-modal-custom'  });
    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
        }
      });
  }

}
