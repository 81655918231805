import { Injectable } from '@angular/core';
import { TillAccountDto } from '../_models/TillAccountDto';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { switchMap, tap } from 'rxjs/operators';

interface SearchTillAccountResult {
  data: TillAccountDto[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: TillAccountDto[], column: SortColumn, direction: string): TillAccountDto[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: TillAccountDto, term: string) {

  return (!data.code || data.code.toLowerCase().includes(term.toLowerCase()))
    || (!data.startedBy || data.startedBy.toLowerCase().includes(term.toLowerCase()));
}
@Injectable({
  providedIn: 'root'
})

export class TillService {


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<TillAccountDto[]>([]);
  private _data$ = new BehaviorSubject<TillAccountDto[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public TillAccountDtos: TillAccountDto[];
  private _currentTotals$ = new BehaviorSubject<any>({});
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get currentTotals$() { return this._currentTotals$.asObservable(); }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getTillAccounts(locationId,brandId, date) {
    const newDate = date.getFullYear() + '-' +
      ("0" + (date.getMonth() + 1)).slice(-2) + '-' +
      ("0" + date.getDate()).slice(-2);
    const url = `api/till/all/${locationId}/${brandId}/${newDate}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<TillAccountDto[]>(url).subscribe(res => {
        this.TillAccountDtos = res;
        let selectedItems: TillAccountDto[];
        selectedItems = [];
        
        this._data$.next(this.TillAccountDtos);
        this._allData$.next(this.TillAccountDtos);
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
          this._computeCurrentPageTotals(result.data);
        });
        this._search$.next();
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
      });
    this._data$.next(this.TillAccountDtos);
    this._allData$.next(this.TillAccountDtos);
    this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _computeCurrentPageTotals(data: TillAccountDto[]) {
    const totals = {
      totalStartingCash: data.reduce((sum, item) => sum + (item.startingCash || 0), 0),
      totalCashSales: data.reduce((sum, item) => sum + (item.cashSales || 0), 0),
      totalCashRefund: data.reduce((sum, item) => sum + (item.cashRefund || 0), 0),
      totalPayIn: data.reduce((sum, item) => sum + (item.payIn || 0), 0),
      totalPayOut: data.reduce((sum, item) => sum + (item.payOut || 0), 0),
      totalExpectedCash: data.reduce((sum, item) => sum + (item.expectedCash || 0), 0),
      totalDifference: data.reduce((sum, item) => sum + (item.difference || 0), 0)
    };
    this._currentTotals$.next(totals);
  }

  private _search(): Observable<SearchTillAccountResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.TillAccountDtos, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
  public get(id) {
    return this.http.get<any[]>(`api/till/${id}`);
  }
}
