import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { SupplierService } from 'src/app/_services/supplier.service';

@Component({
  selector: 'app-addsupplier',
  templateUrl: './addsupplier.component.html',
  styleUrls: ['./addsupplier.component.css']
})
export class AddsupplierComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  supplierForm: UntypedFormGroup;
  loading = false;
  loadingSupplier = false;
  ButtonText = "Save";
  Cities = [];
  Countries = [];
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private supplierService: SupplierService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedSupplier();
    this.loadCountry();

  }
  get f() { return this.supplierForm.controls; }

  private createForm() {

    this.supplierForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      notes: [''],
      contactNo: [''],
      statusID: [true, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      supplierID: 0,
      cityID: [4080,Validators.required],
      countryID: ['SA', Validators.required],
      rowVersion: 0
    });
  }

  private editForm(supplier) {

    this.f.name.setValue(supplier.name);
    this.f.email.setValue(supplier.email);
    this.f.contactNo.setValue(supplier.contactNo);
    this.f.cityID.setValue(supplier.cityID);
    this.f.countryID.setValue(supplier.countryID);
    this.f.notes.setValue(supplier.notes);
    this.f.rowVersion.setValue(supplier.rowVersion);
    this.f.statusID.setValue(supplier.statusID === 1 ? true : false);
    this.f.supplierID.setValue(supplier.supplierID);

  }

  setSelectedSupplier() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingSupplier = true;
      this.f.supplierID.setValue(this.SelectedID);
      this.supplierService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingSupplier = false;
      });
    }
  }


  onSubmit() {

    this.supplierForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.supplierForm.invalid) { return; }
    this.loading = true;

    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    if (parseInt(this.f.supplierID.value) === 0) {

      //Insert supplier
      this.supplierService.insert(this.supplierForm.value).subscribe(data => {
        this.alertService.success("Supplier has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update supplier
      this.supplierService.update(this.supplierForm.value).subscribe(data => {
        this.alertService.success("Supplier has been updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }
  loadCountry() {

    this.supplierService.country().subscribe((res: any) => {
      this.Countries = res;
      if (this.Countries.length > 0) {
        this.loadCity();
      }
    });
  }
  loadCity() {

    this.supplierService.city(this.f.countryID.value).subscribe((res: any) => {
      this.Cities = res;
    });
  }
  close() {
    this.activeModal.dismiss();
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
