import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { State } from '../_models/State';
import { Order } from '../_models/Orders';

import { Utility } from '../_helpers/utility';
import { environment } from '../../environments/environment.prod';

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Order[], column: SortColumn, direction: string): Order[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Order, term: string) {
  return data.orderTaker.toLowerCase().includes(term.toLowerCase())
    || data.orderNo.toString().includes(term.toString())
    || data.orderType.toLowerCase().toString().includes(term.toLowerCase())
    || data.orderState.toLowerCase().includes(term.toLowerCase())
    || data.orderStatus.toLowerCase().includes(term.toLowerCase())
    || data.paymentMode.toLowerCase().includes(term.toLowerCase())
    //|| data.created.toLowerCase().includes(term.toLowerCase())
    || data.tableTitle.toLowerCase().includes(term.toLowerCase());
}
function matcheHeader(data: Order, filterValues: OrderHeaderState) {
  let orderTaker = false;
  let orderType = false;
  let orderState = false;
  let orderStatus = false;
  let payment = false;
  let ZATCAStatus = false;

  // Order Taker
  if (filterValues.orderTaker !== null && filterValues.orderTaker.length > 0) {
    for (var i = 0, len = filterValues.orderTaker.length; i < len; i++) {
      if (data.orderTaker.toLowerCase().toString().includes(filterValues.orderTaker[i].toLowerCase())) {
        orderTaker = true;
      }
    }
  }
  else {
    orderTaker = true;
  }

  // Order Type
  if (filterValues.orderType !== null && filterValues.orderType.length > 0) {
    for (var i = 0, len = filterValues.orderType.length; i < len; i++) {
      if (data.orderType.toLowerCase().toString().includes(filterValues.orderType[i].toLowerCase())) {
        orderType = true;
      }
    }
  }
  else {
    orderType = true;
  }
  // Order State
  if (filterValues.orderState !== null && filterValues.orderState.length > 0) {
    for (var i = 0, len = filterValues.orderState.length; i < len; i++) {
      if (data.orderState.toLowerCase().toString().includes(filterValues.orderState[i].toLowerCase())) {
        orderState = true;
      }
    }
  }
  else {
    orderState = true;
  }
  // Order Status
  if (filterValues.orderStatus !== null && filterValues.orderStatus.length > 0) {
    for (var i = 0, len = filterValues.orderStatus.length; i < len; i++) {
      if (data.orderStatus.toLowerCase().toString().includes(filterValues.orderStatus[i].toLowerCase())) {
        orderStatus = true;
      }
    }
  }
  else {
    orderStatus = true;
  }
  // Payment Mode
  if (filterValues.payment !== null && filterValues.payment.length > 0) {
    for (var i = 0, len = filterValues.payment.length; i < len; i++) {
      if (data.paymentMode.toLowerCase().toString().includes(filterValues.payment[i].toLowerCase())) {
        payment = true;
      }
    }
  }
  else {
    payment = true;
  }

  // ZATCA Status
  if (filterValues.zatcaStatus !== null && filterValues.zatcaStatus.length > 0) {
    for (var i = 0, len = filterValues.zatcaStatus.length; i < len; i++) {
      if (data.reportedToZATCA.toLowerCase().toString().includes(filterValues.zatcaStatus[i].toLowerCase())) {
        ZATCAStatus = true;
      }
    }
  }
  else {
    ZATCAStatus = true;
  }
  if (orderTaker && orderType && orderState && orderStatus && payment && ZATCAStatus) {
    return data;
  }
  //return data.orderTaker.toLowerCase().toString().includes(filterValues.orderTaker !== null ? orderTaker.toLowerCase() : data.orderTaker.toLowerCase().toString())
  //  && data.orderType.toLowerCase().toString().includes(filterValues.orderType !== null ? orderType.toLowerCase() : data.orderType.toLowerCase().toString())
  //  && data.orderState.toLowerCase().toString().includes(filterValues.orderState !== null ? orderState.toLowerCase() : data.orderState.toLowerCase().toString())
  //  && data.orderStatus.toLowerCase().toString().includes(filterValues.orderStatus !== null ? orderStatus.toLowerCase() : data.orderStatus.toLowerCase().toString())
  //  && data.paymentMode.toLowerCase().toString().includes(filterValues.payment !== null ? payment.toLowerCase() : data.paymentMode.toLowerCase().toString());
}
interface SearchOrderResult {
  data: Order[];
  total: number;
}
interface OrderHeaderState {
  orderTaker: string[],
  orderType: string[],
  orderState: string[],
  orderStatus: string[],
  zatcaStatus: string[],
  payment: string[]
}

@Injectable({
  providedIn: 'root'
})

export class OrderService {

  constructor(private http: HttpClient) {
  }


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _searchHeader$ = new Subject<void>();
  public _allData$ = new BehaviorSubject<Order[]>([]);
  private _data$ = new BehaviorSubject<Order[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public orders: Order[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  private _headerState: OrderHeaderState = {
    orderType: null,
    orderState: null,
    orderStatus: null,
    zatcaStatus: null,
    payment: null,
    orderTaker: null
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: OrderHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }


  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

  getById(orderid) {
    return this.http.get<any[]>(`api/order/${orderid}`);
  }
  updatePeriod(updateData) {
    return this.http.post(`api/order/SaveOrderPeriod`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  public getOrder(locationId, brandId, date) {
    //const date = Utility.setDateFormatforAPI(businessDate);
    const newDate = date.getFullYear() + '-' +
      ("0" + (date.getMonth() + 1)).slice(-2) + '-' +
      ("0" + date.getDate()).slice(-2);

    const url = `api/order/all/${locationId}/${brandId}/${newDate}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Order[]>(url).subscribe(res => {
        this.orders = res;
        this._data$.next(this.orders);
        this._allData$.next(this.orders);


        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();
      });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  private _setHeader(headerFilter: OrderHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }

  private _search(): Observable<SearchOrderResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.orders, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
  private _searchByHeader(): Observable<SearchOrderResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.orders, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }
  update(updateData) {
    return this.http.post(`api/order/RefundOrderItems`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  RefundPinpadOrder(updateData) {
    return this.http.post(`api/order/RefundPinpadOrder`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  openDigitalReceipt(industry, brandid, orderNo, hashkey) {
    var URL = environment.digitalReceipt.baseURL + `/${industry}/${brandid}/${orderNo}/${hashkey}`;
    window.open(URL);
  }
}
