
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { InventoryLog } from 'src/app/_models/GeneratedReport';
import { InventoryLogReportService } from 'src/app/_services/inventorylog.report.service';
import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { ExcelService } from '../../../_services/excel.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-inventorylogs',
  templateUrl: './inventorylogs.component.html',
  styleUrls: ['./inventorylogs.component.css']
})
export class InventorylogsComponent implements OnInit {
  data$: Observable<InventoryLog[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  checkAllLocation: boolean = false;
  checkAllType: boolean = false;
  submit: boolean;
  selectedReport: any;

  location = [];
  type = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: InventoryLogReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router:Router,
    private printService: PrintService,
    private alertService: AlertService,  private excelService: ExcelService) {
  

    this.loading$ = service.loading$;
    this.submit = false;


    this.selectedReport = history.state;
    console.log(JSON.stringify(this.selectedReport));
    //this.getReportData(this.selectedReport.dataURL);

    
    
   
  }

  InventoryLogs : any;
  ngOnInit() 
  {
   setTimeout(() => {
   this.getReportData(this.selectedReport.dataURL);
   


   }, 1000);
  }

  getReportData(dataURL) {
    this.service.headerFilter = { location: [''], type: [''] };
    this.service.getInventoryLogReport(dataURL);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    this.service._allData$.subscribe(res => {
      this.location = [];
      this.type = [];
      if (res) {
        new Set(res.map(e => e.Location)).forEach(e => {
          this.location.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(e => e.Type)).forEach(e => {
          this.type.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });

    //this.printService.onDataReady();
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if(this.locationSubscription!=undefined)
      this.locationSubscription.unsubscribe();
  
  }

  export(type, data): void {


   
    if (data !== undefined && data !== null && data !== "") {
      this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-Logs', type);   
    }
    else
      (data !== undefined) ? (data.length === 0 ? this.alertService.error("Data is not available.") : "") : "";
    }


  private getExportData(data) {
    let selected: InventoryLog[];

    selected = [];
    data.source._value.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? data.source._value : selected;
    return selected;
  }


  filterRecords() {
    
    if (this.location.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocation = false;
    }
    else {
      this.checkAllLocation = true;
    }
    if (this.type.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllType = false;
    }
    else {
      this.checkAllType = true;
    }

    let headerFilter = {
      location: this.location.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      type: this.type.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })
    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterRecords();
  }

}
