
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as myGlobals from '../../_helpers/globals';

@Component({
  selector: 'app-generatedreport',
  templateUrl: './generatedreport.component.html'
})
export class GeneratedreportComponent implements OnInit {
 
  active = "generate";

  constructor(public router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,  ) {
  }

  ngOnInit() {
    this.active = this.route.routeConfig.path.split('/').pop();
  }
  get industryURLName() {
    return myGlobals.industryName(this.localStorageService);
  }
  select(name: string) {
    this.router.navigate(['/' + this.industryURLName + `/report/${name}`]);
  }
  
}
