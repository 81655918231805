<form [formGroup]="treturnForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'Create Stock Return' | translate }} </h4>

    <button [disabled]="loading" class="btn-primary cutom-button">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="TSID">{{'Transfer' | translate }} #</label>
              <select class="custom-select form-control" formControlName="TSID" id="TSID" (change)="onTransferChange($event)">
                <option [ngValue]="option.tsid" selected="option.tsid === TSID"
                        *ngFor="let option of TRs">
                  {{option.tsCode}}
                </option>
              </select>
              <div *ngIf="f.TSID.invalid && (f.TSID.dirty || f.TSID.touched)" class="alert alert-danger">
                <div *ngIf="f.TSID.errors.required">
                  {{'Transfer # is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="location">{{'Location' | translate }}</label>
              <select class="custom-select form-control" formControlName="location" id="location" [class.disabled]="true" style="background: #e1e4e5 !important;color: #76828f">
                <option [ngValue]="option.locationID" [disabled]=option.maped selected="option.locationID == location" *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
              <div *ngIf="f.location.invalid && (f.location.dirty || f.location.touched)" class="alert alert-danger">
                <div *ngIf="f.location.errors.required">
                  {{'Location is required' | translate }} .
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="note">{{'Notes' | translate }}</label>
              <textarea class="form-control h-75" id=note placeholder="" formControlName="note"></textarea>
              <div *ngIf="f.note.invalid && (f.note.dirty || f.note.touched)" class="alert alert-danger">
                <div *ngIf="f.note.errors.required">
                  {{'Note is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="returnitem-box form-group">
            <div class="tile-body p-0 table-responsive ">
              <table class="table table-striped">
                <thead>
                  <tr class="table-header">
                    <th width="5%"> <input type="checkbox" /></th>
                    <th width="45%">{{'Item Name' | translate }} </th>
                    <th width="25%">{{'Ordered' | translate }}</th>
                    <th width="25%">{{'Qty Return' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of returnitems">
                    <tr *ngIf="!item.isReturned">
                      <td>
                        <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.selected" (change)="onReturnQtyChange(item)" />
                      </td>
                      <td>{{item.name}} </td>
                      <td>{{item.quantity}} {{item.unit}} </td>
                      <td>
                        <input type="number" [(ngModel)]="item.returnQuantity" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="" [ngClass]="{red : (item.selected === true && (item.returnQuantity > item.quantity  || item.returnQuantity === null || item.returnQuantity === '' || item.returnQuantity === undefined || item.returnQuantity <= 0))}" (ngModelChange)="onReturnQtyChange(item)" [attr.required]="item.selected">
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </div>
</form>
