import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { switchMap, tap, map } from 'rxjs/operators';

import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { LocationInventory } from '../_models/LocationInventory';

interface SearchLocationInventoryResult {
  data: LocationInventory[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: LocationInventory[], column: SortColumn, direction: string): LocationInventory[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: LocationInventory, term: string) {

  return data.name.toLowerCase().includes(term.toLowerCase())
    || data.type.toLowerCase().includes(term.toLowerCase())
    || data.sku.toLowerCase().includes(term.toLowerCase())
    || data.barcode.toLowerCase().includes(term.toLowerCase())
    || data.alternateName.toLowerCase().includes(term.toLowerCase());
  //|| pipe.transform(location.email).includes(term)


}

@Injectable({
  providedIn: 'root'
})
export class LocationInventoryService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<LocationInventory[]>([]);
  private _data$ = new BehaviorSubject<LocationInventory[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public locationInventorys: LocationInventory[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getLocationInventorys(brandId, locationId) {
    const url = `api/inventory/stock/all/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<LocationInventory[]>(url).subscribe(res => {
        this.locationInventorys = res;
        let selectedItems: LocationInventory[];
        selectedItems = [];
        this.locationInventorys.forEach(d => {
          d.currentStockUpdated = d.currentStock;
          var percentStock = ((d.currentStock / d.minStockLevel) * 100).toFixed(2);
          if (d.currentStock < d.minStockLevel)
            d.csscolor = "Red";
          else if (d.currentStock > d.minStockLevel && parseInt(percentStock) < 10)
            d.csscolor = "Yellow";
          else
            d.csscolor = "Green";
        });
        this._data$.next(this.locationInventorys);
        this._allData$.next(this.locationInventorys);
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._search$.next();
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
      });
    this._data$.next(this.locationInventorys);
    this._allData$.next(this.locationInventorys);
    this._search$.next();
  }
  public getLocationStock(locationId) {
    const url = `api/inventory/stock/GetLocationStock/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<LocationInventory[]>(url).subscribe(res => {
        this.locationInventorys = res;
        let selectedItems: LocationInventory[];
        selectedItems = [];
        this.locationInventorys.forEach(d => {
          d.currentStockUpdated = d.currentStock;
          var percentStock = ((d.currentStock / d.minStockLevel) * 100).toFixed(2);
          if (d.currentStock < d.minStockLevel)
            d.csscolor = "Red";
          else if (d.currentStock > d.minStockLevel && parseInt(percentStock) < 10)
            d.csscolor = "Yellow";
          else
            d.csscolor = "Green";
        });
        this._data$.next(this.locationInventorys);
        this._allData$.next(this.locationInventorys);
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._search$.next();
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });
      });
    this._data$.next(this.locationInventorys);
    this._allData$.next(this.locationInventorys);
    this._search$.next();
  }


  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchLocationInventoryResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.locationInventorys, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  public saveItems(items: LocationInventory[],brandID) {
    return this.http.put(`api/inventory/stock/${brandID}`, items);
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
}

