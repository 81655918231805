<div class="row ">
  <div class="col-lg-12 col-md-6 col-sm-12">
    <div class="d-flex row g-2 px-3 pt-1 loc-stats">
      <div class="col-sm-10 col-xl-10 col-lg-12 col-md-12 h6line-height">  <h5>{{'Location Stats' | translate}}</h5></div>
      <div class="col-sm-2 col-xl-2 col-lg-12 col-md-12">
        <div class="filter-field">
          <div class="position-relative">
            <div class="top-bar-flex">
              <div class="date-tab">
                <span class="show-filter-icon float-left calendar-icon" (click)="d.toggle()" type="button">
                  <img src="/assets/images/calendar.svg" style="margin-top: -4px;" alt="Calender">
                </span>

                <input (click)="d.toggle()" class="form-control selected-location selected-date bg-white" placeholder="{{selectedDate}}" (dateSelect)="setDate($event)" name="dp" today="true" selected="true"
                       [(ngModel)]="dtModel" ngbDatepicker #d="ngbDatepicker" readonly="readonly">
                <span class="aero-icon"><img src="/assets/images/down-aero.svg" alt="" (click)="d.toggle()"></span>
              </div>
            </div>
            </div>
          </div>
      </div>
    </div>
    <app-till-account-list [Date]="selectedDate" *hasPermission="['Locations','Stats','Till']" [LogEventType]="'screen'" [LogEvent]="'Till'"></app-till-account-list>
  </div>
</div>
