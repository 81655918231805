<form [formGroup]="itemsUploadForm" class="form">
  <div class="modal-header">
    <h4 class="modal-title">{{'Import Inventory Item Count' | translate}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div *ngIf="showFileUpload">
            <div class="form-group files">
              <input type="file" (change)="onFileChange($event)" class="form-control" accept=".xls, .xlsx" #itemFile (click)="itemFile.value = null" value="" />
            </div>
          </div>
            <div *ngIf="showError">
              <ul>
                <li *ngFor="let option of errorList">
                  <span class="caret">{{'Error at Row No' | translate}} <b>{{option.rowNo | translate}} </b></span>
                  <ul class="nested">
                    <li> {{option.message | translate}}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <hr />
            <div style="justify-content:normal" *ngIf="showError === false">
              <div class="row">
                <div class="col-lg-8">
                  <a> <strong><label (click)="download()" style="color: #3754b7;cursor:pointer">{{'Get Template' | translate}}</label></strong></a>
                </div>
                <div class="col-lg-4">
                  <button class="btn-primary cutom-button export-continue float-end" (click)="onSubmit()" [disabled]="loading">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{SaveButtonText | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</form>
