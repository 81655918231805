
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { trigger, state, style, animate, transition, keyframes  } from '@angular/animations';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
  animations:[
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      // transition(':leave', [
      //   animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
      // ]),
    ])
  ]
})
export class ForgotpasswordComponent {

  loading = false;
  submitted = false;
  isLinkSend = false;
  returnUrl: string;
  captchaResolved: boolean=false;
  @Input('isRestaurant') isRestaurant: boolean;
  stateOfElement: any = 'default';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }
    forgotForm: UntypedFormGroup;
    ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      username: ['', { validators: [Validators.required, Validators.email], updateOn: 'blur' }]
    });



  }
  get f() { return this.forgotForm.controls; }
  
  setIndustry(val: boolean) {
    this.forgotForm.reset();
    this.isRestaurant = val;
    if (this.isRestaurant == true) {
      this.stateOfElement = 'default';
    } else {
      this.stateOfElement = 'disabled';
    }


  }
  sendForgotPasswordLink(){


    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.f.username.value, this.isRestaurant)
      .pipe(first())
      .subscribe(
        data => {
          this.isLinkSend = true;
          const LoginData = JSON.parse(data.data);
          console.log(LoginData);
          
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
          this.isLinkSend = false;
        });
   
  }
  resolved(captchaResponse: string) {
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) 
      this.captchaResolved = true;
    else
      this.captchaResolved = false;
  }
}
