

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { RecipeService } from 'src/app/_services/recipe.service';
import { RecipeIngeridients } from 'src/app/_models/Recipe';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PrepsService } from 'src/app/_services/preps.service';
import { LocationService } from 'src/app/_services/location.service';
import { PrepDetails } from 'src/app/_models/Preps';

@Component({
  selector: 'app-addpreps',
  templateUrl: './addpreps.component.html',
  styleUrls: ['./addpreps.component.css']
})
export class AddprepsComponent implements OnInit {
  @Input() SelectedID = 0;
  isSameLocation: boolean = false;
  submitted = false;
  prepsForm: UntypedFormGroup;
  loading = false;
  loadingPreps = false;
  ButtonText = "Create";
  Locations = [];
  Recipes = [];
  public arrRecipeIngerdients: PrepDetails[] = [];

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private service: PrepsService,
    private locationService: LocationService,
    private recipeService: RecipeService

  ) {
    this.createForm();
  }

  ngOnInit() {
    //  this.setSelectedRecipe();
    this.loadLocations();
    this.loadRecipes();
  }
  get f() { return this.prepsForm.controls; }

  private createForm() {

    this.prepsForm = this.formBuilder.group({
      prepCode: [''],
      name: [''],
      supplyID: ['', Validators.required],
      sku: [''],
      preparedQty: [0, Validators.required],
      locationID: ['', Validators.required],
      sameLocation: false,
      type: 'Recipe',
      statusID: [1, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      prepID: 0,
      rowVersion: 0,
      prepDetails: ['', Validators.required]
    });
  }


  private loadLocations() {
    this.locationService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }

  private loadRecipes() {
    this.recipeService.getCategoriesddl(this.f.brandID.value).subscribe((res: any) => {

      console.log("RESIPE", res);
      this.Recipes = res.filter(_res => _res.autoPrepared === false);;
      //this.f.supplyID.setValue(res[0].recipeID);
    });
  }




  onSubmit() {

    this.prepsForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.isSameLocation == true) {
      console.log('Same Location');
      this.arrRecipeIngerdients = [];
      this.arrRecipeIngerdients.push({
        prepID: 0,
        name: '',
        locationID: this.f.locationID.value,
        transferQty: this.f.preparedQty.value
      });
    }

    if (this.arrRecipeIngerdients.length == 0) {
      this.alertService.error("Locations cannot be empty.");
      return;
    }

    let error;
    this.arrRecipeIngerdients.forEach(e => {

      if (e.transferQty <= 0) {
        error = true;
      }
    });

    if (error === true) {
      this.alertService.error("Locations cannot have 0 or less than 0 trasnferred quantities");
      return;
    }

    this.f.prepDetails.setValue(this.arrRecipeIngerdients!);
    if (this.prepsForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(1);
    if (parseInt(this.f.prepID.value) === 0) {

      let sum: number = this.arrRecipeIngerdients.map(a => a.transferQty).reduce(function (a, b) { return a + b; });
      if (sum > this.f.preparedQty.value) {

        this.alertService.error("Can not generate prep Transfer Qty Does not Match");
        this.loading = false;

      } else if (sum < this.f.preparedQty.value) {

        let remaining = this.f.preparedQty.value - sum;
        this.arrRecipeIngerdients.push({
          prepID: 0,
          name: '',
          locationID: this.f.locationID.value,
          transferQty: remaining
        });

      }

      let finalSum: number = this.arrRecipeIngerdients.map(a => a.transferQty).reduce(function (a, b) { return a + b; });
      if (finalSum == this.f.preparedQty.value) {

        this.service.insert(this.prepsForm.value).subscribe(data => {
          this.alertService.success("Prep has been created");
          this.loading = false;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });
      }


    }
  }

  close() {
    this.activeModal.dismiss();
  }
  chksameLocation() {
    this.isSameLocation = this.f.sameLocation.value;
  }


  deleteRow(obj) {
    const index = this.arrRecipeIngerdients.indexOf(obj);
    this.arrRecipeIngerdients.splice(index, 1);
  }
  addRow(obj) {

    console.log(obj);
    this.arrRecipeIngerdients.push({
      prepID: 0,
      name: obj.name,
      locationID: obj.locationID,
      transferQty: obj.transferQty == undefined ? 1 : obj.transferQty
    });


    console.log("arrRecipeIngerdients", this.arrRecipeIngerdients)
  }

  UpdateQty(newItem) {
    let updateItem = this.arrRecipeIngerdients.find(this.findIndexToUpdate, newItem.transferQty);
    let index = this.arrRecipeIngerdients.indexOf(updateItem);
    this.arrRecipeIngerdients[index] = newItem;
  }

  findIndexToUpdate(newItem) {
    return newItem.quantity === this;
  }
}
