import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) {
  }

  getDeviceType() {
    return this.http.get<any[]>(`api/common/devicetypes`);
  }
  
  getIngredientUnit() {
    return  this.http.get(`api/common/units`);
   }
   getInventoryGroup(id) {
    return  this.http.get(`api/common/ddl/groups/${id}`);
   }
   getSupplier(id) {
    return  this.http.get(`api/common/ddl/suppliers/${id}`);
   }

   getInventorySKU(id) {
    return  this.http.get(`api/common/inventory/sku/${id}`);
  }

  getMarnNowPartners(brandId: number) {
    return this.http.get(`api/common/ddl/partners/marnnow/${brandId}`);
  }
  public GetCurrency() {
    return this.http.get<any[]>(`api/common/GetCurrency`);
  }

  getSettings(brandId) {
    return this.http.get<any[]>(`api/common/setting/vat/${brandId}`);
  }
  getCurrencySettings(brandId) {
    return this.http.get<any[]>(`api/common/setting/currency/${brandId}`);
  }
}
