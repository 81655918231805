
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { SalesTransactionType } from 'src/app/_models/GeneratedReport';

import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { SalesTransactionTypeReportService } from 'src/app/_services/sales-transactiontype-report.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-salestransactiontype',
  templateUrl: './salestransactiontype.component.html',
  styleUrls: ['./salestransactiontype.component.css']
})
export class SalestransactiontypeComponent implements OnInit {
  data$: Observable<SalesTransactionType[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  totalAmount$: Observable<number>;
  submit: boolean;
  selectedReport : any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: SalesTransactionTypeReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router:Router,
    private printService: PrintService,
    private alertService: AlertService) {
    this.loading$ = service.loading$;
    this.submit = false;
    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
  
  }

  SalesOrderTypes : any;
  ngOnInit() 
  {
   this.getReportData(this.selectedReport.dataURL);
  }

  getReportData(dataURL) {
    this.service.getSalesTransactionTypeReport(dataURL);
    this.data$ = this.service.allData$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    this.totalAmount$ = this.service.totalAmount$;
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if(this.locationSubscription!=undefined)
      this.locationSubscription.unsubscribe();
  }
}
