import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap, map } from 'rxjs/operators';
import { Location } from '../_models/Location';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { State } from '../_models/State';



interface SearchResult {
  data: Location[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(locations: Location[], column: SortColumn, direction: string): Location[] {
  if (direction === '' || column === '') {
    return locations;
  } else {
    return [...locations].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(location: Location, term: string) {

  return location.name.toLowerCase().includes(term.toLowerCase())
    || location.email.toLowerCase().includes(term.toLowerCase())
    || location.contactNo.toLowerCase().includes(term.toLowerCase())
    || location.city.toLowerCase().includes(term.toLowerCase())
    || location.address.toLowerCase().includes(term.toLowerCase());
  //|| pipe.transform(location.email).includes(term)


}

//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[],
  city: string[],
}


function matcheHeader(data: Location, filterValues: FilterHeaderState) {
  let status = false;
  let city = false;

  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  // City
  if (filterValues.city !== null && filterValues.city.length > 0) {
    for (var i = 0, len = filterValues.city.length; i < len; i++) {
      if (data.city.toLowerCase().toString().includes(filterValues.city[i].toLowerCase())) {
        city = true;
      }
    }
  }
  else {
    city = true;
  }

  if (city && status) {
    return data;
  }

}
//@@@ Header Filter Code End

@Injectable({ providedIn: 'root' })
export class LocationService {
  public locationIdChange = new Subject<number>();
  public static locationId: number = 0;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _locations$ = new BehaviorSubject<Location[]>([]);
  private _allData$ = new BehaviorSubject<Location[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private locations: Location[];

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  private selectedBrand;
  private selectedLocation;

  constructor(private pipe: DecimalPipe, private http: HttpClient, private localStorageService: LocalStorageService) {



    this.locationIdChange.subscribe((value) => {
      console.log(value);
      LocationService.locationId = value;
      console.log(LocationService.locationId);
    });

  }
  getAllLocationsddl(id) {
    return this.http.get(`api/location/all/${id}`);

  }

  getLocations() {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLocation = this.localStorageService.getSelectedLocation().LocationID;

    console.log(this.selectedLocation);
    this.getlocations(this.selectedBrand, this.selectedLocation);
  }

  get allData$() {
    return this._allData$.asObservable();
  }

  get locations$() {
    return this._locations$.asObservable();
  }
  getlocations(brandId, locationId) {
    //this._locations$.next(null);
    let url = "";
    if (locationId === null) {
      url = `api/location/all/${brandId}`;
    } else {
      url = `api/location/${locationId}`;
    }

    this.http.get<any>(url).subscribe(res => {
      console.log("res", res);


      this.locations = res;
      this._locations$.next(this.locations);
      this._allData$.next(this.locations);

      // this._search$.pipe(
      //   tap(() => this._loading$.next(true)),
      //   switchMap(() => this._search()),
      //   tap(() => this._loading$.next(false))
      // ).subscribe(result => {
      //   this._locations$.next(result.data);
      //   this._total$.next(result.total);
      // });

      // this._search$.next();


      //@@@ Header Filter Code Start
      this._search$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._locations$.next(result.data);
        this._total$.next(result.total);
      });


      this._searchHeader$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._locations$.next(result.data);
        this._total$.next(result.total);
      });

      this._search$.next();
      this._searchHeader$.next();

      //@@@ Header Filter Code End

    });
  }
  getlocationsByBrand() {
    //this._locations$.next(null);
    var brandId=this.localStorageService.getSelectedBrand().BrandID;
    let url = `api/location/all/${brandId}`;
    

    this.http.get<any>(url).subscribe(res => {
      console.log("res", res);


      this.locations = res;
      this._locations$.next(this.locations);
      this._allData$.next(this.locations);

      // this._search$.pipe(
      //   tap(() => this._loading$.next(true)),
      //   switchMap(() => this._search()),
      //   tap(() => this._loading$.next(false))
      // ).subscribe(result => {
      //   this._locations$.next(result.data);
      //   this._total$.next(result.total);
      // });

      // this._search$.next();


      //@@@ Header Filter Code Start
      this._search$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._locations$.next(result.data);
        this._total$.next(result.total);
      });


      this._searchHeader$.pipe(
        switchMap(() => this._searchByHeader()),
        tap(() => this._loading$.next(false))
      ).subscribe(result => {

        this._locations$.next(result.data);
        this._total$.next(result.total);
      });

      this._search$.next();
      this._searchHeader$.next();

      //@@@ Header Filter Code End

    });
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  public getLocationId(): Observable<number> { return this.locationIdChange.asObservable(); }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null,
    city: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.locations, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._locations$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End

  public setLocationId(id: number) {
    console.info('setting locationId', id);
    this.locationIdChange.next(id);
  }

  clear() {
    this._locations$.next(null);
    this._searchHeader$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.locations, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }



  getAll(brandId) {
    return this.http.get<any[]>(`api/location/all/${brandId}`);
  }

  getById(id) {
    return this.http.get<any[]>(`api/location/${id}`);
  }

  insert(data) {
    return this.http.post(`api/location`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  update(updateData) {
    return this.http.put(`api/location`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  getPartners(brandID,locationID) {
    return this.http.get<any[]>(`api/location/GetPartnerAutoAccept/${brandID}/${locationID}`);
  }
  updatePartner(updateData) {
    return this.http.post(`api/location/SavePartnerAutoAcceptOrder`, updateData )
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
}
