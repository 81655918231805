<form [formGroup]="reconcillationForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'Generate' | translate }} {{f.rcnCode.value}} </h4>
    <button [disabled]="loading" class="btn-primary btn-sm cutom-button" [hidden]="f.rcnid.value>0" *hasPermission="['Inventory','Inventory Action','Reconciliation', 'Create']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container" [ngStyle]="{'pointer-events': f.rcnid.value>0 ? 'none' : 'unset' }">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Reference No' | translate }}</label>
              <input type="text" class="form-control" id=referenceNo placeholder="" formControlName="referenceNo">


              <div *ngIf="f.referenceNo.invalid && (f.referenceNo.dirty || f.referenceNo.touched)" class="alert alert-danger">
                <div *ngIf="f.referenceNo.errors.required">
                  {{'Reference No is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'Location' | translate }}</label>
              <select class="custom-select form-control" formControlName="locationID" (change)="loadItems(f.locationID.value)">
                <option [ngValue]="option.locationID" [disabled]=option.maped
                        selected="option.locationID == locationID" *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row rcn-box pt-3" [hidden]="f.rcnid.value>0">
            <label>{{'Item Name' | translate }}</label>
            <div class="input-group form-group">
              <div class="input-group-append w-100">
                <ng-select [items]="Items" class="form-control w-100 rcn-box-autocomplete border-right-radius-unset "
                           placeholder="{{'Click here to select Item' | translate }}" [bindLabel]="(selectedLang == 'en' ? 'name' : 'alternateName')" bindValue="supplyID"
                           (change)="selectedInvItem($event)" [(ngModel)]="rcnDetails.supplyID" [ngModelOptions]="{standalone: true}" [searchFn]="SearchItem">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row rcn-box">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Price">{{'Quantity' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" id=quantity value="1" [(ngModel)]="rcnDetails.quantity" [ngModelOptions]="{standalone: true}">
                <div class="input-group-append">
                  <select class="custom-select border-right-radius-unset form-control" style="    border-radius: 0px !important;pointer-events: none;" [(ngModel)]="rcnDetails.unitID" [ngModelOptions]="{standalone: true}">
                    <option [ngValue]="option.unitID" [disabled]=option.maped
                            selected="option.unitID == unitID" *ngFor="let option of Units">
                      {{option.unit}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row rcn-box">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Price">{{'Approximate Cost' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" id=quantity value="1" [(ngModel)]="rcnDetails.cost" [ngModelOptions]="{standalone: true}">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{{brandCurrency | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row rcn-box">
            <label>{{'Reason' | translate }} - </label>
            <div class="input-group form-group">
              <div class="input-group-append w-100">
                <ng-select [items]="Reasons" class="form-control w-100 rcn-box-autocomplete border-right-radius-unset "
                           placeholder="{{'Click here to select Reason' | translate }}" bindLabel="name" [translate]="rcnDetails.reason" bindValue="name"
                           (change)="selectedReason($event)" [(ngModel)]="rcnDetails.reason" [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row rcn-box">
            <div class="form-group col-lg-12">
              <!--<label for="add item" class="additem-po" (click)="addRow(rcnDetails)">+ {{'Add Item' | translate }}</label>-->
              <button class="btn btn-dark btn-block" type="button" (click)="addRow(rcnDetails)">{{'Reconciliation' | translate }}</button>
            </div>
          </div>
          <section class="tile pt-3" style="border:none;box-shadow:none">
            <div class="p-0 table-responsive ">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" width="40%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                    <th scope="col" width="20%">{{'Cost/Unit' | translate }}</th>
                    <th scope="col" width="15%" class="border-start-width-0 border-end-width-0">{{'Reason' | translate }}</th>
                    <th scope="col" width="10%" class="border-start-width-0 border-end-width-0 border-radius-topright-10"></th>
                  </tr>
                </thead>
                <tbody class="border-bottom-radius-unset">
                  <tr *ngFor="let item of arrItems">
                    <td scope="row" class="font-weight-bold">{{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}</td>
                    <td>
                      {{item.quantity}} ({{item.cost}} {{brandCurrency | translate }})
                    </td>
                    <td>{{item.reason  | translate }}</td>
                    <td>
                      <a [hidden]="f.rcnid.value>0"><img src="/assets/images/delete-icon.png" (click)="deleteRow(item)" /></a>
                    </td>
                  </tr>
                  <tr *ngIf="arrItems.length == 0">
                    <td colspan="5">
                      <div class="row p-1">
                        <div class="col-12 text-center">
                          <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>

