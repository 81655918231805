<div class="mnt-header position-relative">



  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-20" *ngIf="(service.roles?.length > 0)">

    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;" class="hide-mobile">  {{'Roles' | translate }} </h5>
    </div>


    <div class="col-md-auto" *hasPermission="['Setup','Users','Roles', 'Insert']">
      <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
        + {{'New Role' | translate }}
      </button>
    </div>

    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>


  <div *ngIf="(service.roles?.length > 0)">

    <section class="tile">
      <div class="tile-body p-0 table-responsive text-nowrap">
        <table class="table table-striped text-start">
          <thead>
            <tr class="table-header">

              <th width="30%" sortable="roleName" (sort)="onSort($event)">{{'Roles' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
              <th width="10%" class="text-center">

                <!--  UserType -->
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" id="ddlUserTypeFilter" ngbDropdownToggle>
                    <span>{{SelectedUserType | translate}} </span>
                    <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ddlUserTypeFilter dropdown-header-filter-body"
                       class="dropdown-header-filter-body ddl-width-sm">
                    <div>
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" [(ngModel)]="SelectedUserType" value="All Access Type"
                               name="usertypeOption" id="dropdownUserType_all" (change)="selectAllFilters($event,UserTypeList)">
                        <label class="form-check-label font-blue" for="dropdownUserType_all"
                               (click)="selectAllFilters($event,UserTypeList)">
                          {{'All Access Type' | translate}}
                        </label>
                      </div>
                    </div>
                    <div *ngFor="let usertype of UserTypeList; let i = index">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" name="usertypeOption" [value]="usertype.name"
                               id="dropdownUserType_{{i+1}}" [(ngModel)]="SelectedUserType" (ngModelChange)="filters()">
                        <label class="form-check-label" for="dropdownUserType_{{i+1}}" (click)="filters()">
                          {{usertype.name | translate}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>


              </th>
              <th width="20%" sortable="permissions" (sort)="onSort($event)">{{'Permissions' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
              <th width="20%" sortable="users" (sort)="onSort($event)">{{'Applied On' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
              <th width="10%">
                <!-- Status Filter -->
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                    <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                    <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                             name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                      <label class="form-check-label font-blue" for="dropdownStatus_all"
                             (click)="selectAllFilters($event,StatusList)">
                        {{'All Status' | translate}}
                      </label>
                    </div>
                    <div *ngFor="let item of StatusList; let i = index">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                        <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                          <!-- {{item.name}} -->

                          <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                         class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                          </ngb-highlight>


                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let role of data$ | async" editRow>
              <td (click)="getRole(role)">
                <span class="btn btn-sm w-auto {{role.cssClass}}">{{role.roleName}}</span>
              </td>
              <td class="text-center" (click)="getRole(role)">{{role.type | translate}}</td>
              <td (click)="getRole(role)">{{role.permissions | translate}}</td>
              <td (click)="getRole(role)">{{role.users}} {{'owner' | translate}}</td>
              <td style="width: 15%" (click)="getRole(role)">
                <ngb-highlight [ngClass]="{'btn-active': role.statusID === 1, 'btn-inactive':role.statusID !== 1 }" class="btn btn-sm" [result]="(role.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                  <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                </div>
                <ng-template #popContent>
                  <p class="dropdown-heading-mini">{{'Roles' | translate }}</p>
                  <a *hasPermission="['Setup','Users','Roles', 'Edit']" (click)="getRole(role)">{{'Edit' | translate }}</a>
                  <ng-container *ngIf="role.statusID === 1">
                    <a *hasPermission="['Setup','Users', 'Roles', 'Deactivate']" (click)="SetStatus(role)">{{'Deactivate' | translate }}</a>
                  </ng-container>
                  <ng-container *ngIf="role.statusID === 2">
                    <a *hasPermission="['Setup','Users', 'Roles', 'Deactivate']" (click)="SetStatus(role)">{{'Activate' | translate }}</a>
                  </ng-container>
                  <a *hasPermission="['Setup','Users','Roles', 'Delete']" (click)="openConfirmationDialog(role.roleID,role.rowVersion,role.roleName)">{{'Delete' | translate }}</a>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <div class="row mt-10" *ngIf="((data$ | async).length > 0)">

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
        <p class="pagination-count">
          <strong class="p-4">
            {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ | async)!}} {{'results' | translate }}
          </strong>
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>

    </div>

  </div>

</div>

<div *ngIf="(service.roles?.length == 0) && ((data$ | async).length == 0)">
  <div class="blank-order">
    <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
    <strong>{{'No Role yet' | translate }}</strong>
    <p class="text-center width-375">{{'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.' | translate}}</p>
  </div>
  <div class="items-flex">
    <div class="create-btn mlmr-10">
      <div>
        <button type="button" (click)="open()" *hasPermission="['Setup','Users','Roles', 'Insert']" class="btn btn-primary cutom-button form-control custom-create-btn">
          {{'+ Create New Role' | translate }}
        </button>
      </div>
    </div>


  </div>
</div>



<div *ngIf="(service.roles?.length > 0) && ((data$ | async)?.length == 0)">
  <div class="blank-order">
    <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
    <strong>{{'No Record Found' | translate }}</strong>
    <p class="text-center width-375">{{'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.' | translate}}</p>
  </div>
</div>
