import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import * as firebase from "firebase/analytics";
import { LocalStorageService } from './local-storage.service';
import { UserinfoService } from './userinfo.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackEventsService {
  analytics = null;
  brand: any | undefined | null = null;
  user: any | undefined | null = null;
  company: any | undefined | null = null;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userInfoService: UserinfoService,
    private localStorageService: LocalStorageService) {
    gtag('config', environment.firebaseConfig.measurementId, { 'send_page_view': false });
    this.analytics = firebase.getAnalytics();
  }

  handleRouteEvents(router: Router) {
    const title = this.getTitle(router.routerState, router.routerState.root).join('-');
    this.Log_Screen(title);

  }

  handleUserData() {
    this.userInfoService.getUserInfo().then((res: any) => {

      this.user = res.User;
      this.company = this.localStorageService.getSelectedUser().CompanyDetails;
      if (this.localStorageService.checkAlreadySelected()) {
        this.brand = this.localStorageService.getSelectedBrand();
      }
      else {
        this.brand = res.Brands[0];
      }

      let userID = 'B-' + this.brand.BrandName + '|I-' + (this.user.Industry === 1 ? 'Restaurant' : 'Retail') + '|N-' + this.user.FirstName + ' ' + this.user.LastName;
      let userProp = {
        name: this.user.FirstName + ' ' + this.user.LastName,
        companyCode: this.company.CompanyCode,
        brand: this.brand.BrandName,
        user_type: (this.user.UserType == '2' ? 'Main User' : 'Sub User'),
        industry: (this.user.Industry === 1 ? 'Restaurant' : 'Retail')
      };
      firebase.setUserId(firebase.getAnalytics(), userID);
      firebase.setUserProperties(firebase.getAnalytics(), userProp);
    });
  }
  public Log_Screen(title) {
    firebase.logEvent(firebase.getAnalytics(), 'page_view', {
      page_title: title,
      page_location: this.document.location.href,
      page_path: this.document.location.href
    });
    //this.Log_Industry();
  }
  public Log_Industry() {
    firebase.logEvent(firebase.getAnalytics(), 'industry_type', {
      industry: (this.user.Industry === 1 ? 'Restaurant' : 'Retail')
    });
  }

  public LogIn() {
    firebase.logEvent(firebase.getAnalytics(), 'login', {
      method:'Marn-Login'
    });
  }

  public Logout() {
    firebase.logEvent(firebase.getAnalytics(), 'logout', {
      name: this.user.FirstName + ' ' + this.user.LastName
    });
  }
  private getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

}
