import { Component, Injectable, Input } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../datepicker/datepicker-adapter';

@Component({
  selector: 'app-date-picker-v2',
  templateUrl: './date-picker-v2.component.html',
  styleUrl: './date-picker-v2.component.css',
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class DatePickerV2Component {

  model: string;
  dateModel: any;
  minDate = { year: 1950, month: 1, day: 1 };
  maxDate = { year: 2048, month: 12, day: 31 };
  @Input() AcceptPreviousDate: boolean = true;
  @Input() AcceptFutureDate: boolean = true;
  @Input() AllowEmptyDate: boolean = false;
  @Input() Disable: boolean = false;
  constructor(private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>, public formatter: NgbDateParserFormatter) {
    this.model = this.today();
    var date = this.ngbCalendar.getToday();
    this.dateModel = this.formatter.parse(this.dateAdapter.toModel(date));
  }

  today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  setDate(date) {
    if (date != null && date != undefined && date != '') {
      var isoDate = (new Date(Date.parse(date))).toISOString();
      var userDate = new Date(isoDate);
      var ngbDateStruct = { day: userDate.getDate(), month: userDate.getMonth()+1, year: userDate.getFullYear() };
      this.dateModel = this.formatter.parse(this.dateAdapter.toModel(ngbDateStruct));
      this.model = this.dateAdapter.toModel(this.dateModel);
    }
  }
  selectDate(date: NgbDate) {
    this.dateModel = this.formatter.parse(this.dateAdapter.toModel(date));
  }
  ngAfterViewInit(): void {
  }
  ngOnInit() {
    if (this.AllowEmptyDate === true) {
      this.model = '';
      this.dateModel = null;
    }
    else {
      this.model = this.today();
    }
    if (this.AcceptPreviousDate === false) {
      this.minDate = {
        day: this.ngbCalendar.getToday().day,
        month: this.ngbCalendar.getToday().month,
        year: this.ngbCalendar.getToday().year
      }
    }
    if (this.AcceptFutureDate === false) {
      this.maxDate = {
        day: this.ngbCalendar.getToday().day,
        month: this.ngbCalendar.getToday().month,
        year: this.ngbCalendar.getToday().year
      }
    }
  }
}
