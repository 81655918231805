<!--<div class="form-group files">
  <input type="file" [disabled]="allowedUpload" class="form-control" accept=".png, .jpg, .jpeg, .png" #fileInput (change)="onFileChange($event)" (click)="fileInput.value = null" value="">
</div>-->




<div class="group-gap mt-4">

  <div class="avatar-upload">
    <div class="avatar-edit">
      <input type='file' accept=".png, .jpg, .jpeg, .png" #fileInput (change)="onFileChange($event)" />
    </div>
    <div class="avatar-preview">
      <div *ngIf="localImagePath !== ''" id="imagePreviewlocal" [ngStyle]="{'background-image':'url(' + localImagePath +')'}">
      </div>
      <div *ngIf="!!this.pattern.test(imagepath) && localImagePath === ''" id="imagePreview" [ngStyle]="{'background-image':'url(' + imagepath + '?refreshCache='+ breakCache+ ')'}">
      </div>
    </div>
  </div>
</div>

<!-- Submit Button -->
<button type="button" class="file-upload-btn" (click)="fileInput.click()">{{'Add Logo' | translate }}</button>
