
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { PrintService } from 'src/app/_services/print.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { DailySales, DeletedItems, Discounts, GeneratedReport, InventoryLog, InventoryStock, LocationSalesSummary, PartnerSummary, Refunds, SalesDetail, SalesOrderType, SalesTax, SalesTransactionType, StockMovementReport, DetailTaxReport, DetailTaxes } from 'src/app/_models/GeneratedReport';
import { ReportService } from 'src/app/_services/report.service';
import { Router } from '@angular/router';
import { InventoryLogReportService } from 'src/app/_services/inventorylog.report.service';
import { InventoryStockReportService } from 'src/app/_services/inventorystock.report.service';
import { SalesDetailReportService } from '../../../_services/sales-detail-report.service';
import { SalesOrderTypeReportService } from 'src/app/_services/sales-ordertype-report.service';
import { SalesTransactionTypeReportService } from 'src/app/_services/sales-transactiontype-report.service';
import { DeletedItemReportService } from '../../../_services/deleteditems.report.service';
import { RefundReportService } from '../../../_services/refund.report.service';
import { DiscountReportService } from '../../../_services/discount.report.service';
import * as myGlobals from '../../../_helpers/globals';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { SalesTaxReportService } from '../../../_services/SalesTax.report.service';
import { PermissionService } from '../../../_services/permission.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductMix } from '../../../_models/ProductMix';
import { PartnerSummaryService } from '../../../_services/partner-summar.service';
import { PdfService } from '../../../_services/pdf.service';

@Component({
  selector: 'app-recentreport',
  templateUrl: './recentreport.component.html',
  providers: [ExcelService]
})
export class RecentReportComponent implements OnInit {
  data$: Observable<GeneratedReport[]>;
  oldData: GeneratedReport[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "report";
  submit: boolean;
  excelData = [];
  selectedLang = 'en';
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: ReportService,
    public logservice: InventoryLogReportService,
    public stockservice: InventoryStockReportService,
    public salesService: SalesDetailReportService,
    public salesOrderTypeService: SalesOrderTypeReportService,
    public salesTransactionTypeService: SalesTransactionTypeReportService,
    public deletedItemsService: DeletedItemReportService,
    public refundService: RefundReportService,
    public discountService: DiscountReportService,
    private localStorageService: LocalStorageService,
    public printService: PrintService,
    private router: Router,
    private excelService: ExcelService,
    private alertService: AlertService,
    private confirmationDialogService: ActionConfirmationService,
    public taxService: SalesTaxReportService,
    private permissionService: PermissionService,
    private translate: TranslateService,
    private pdfService: PdfService,
    public partnerService: PartnerSummaryService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.loading$ = service.loading$;
    this.submit = false;
    //this.loadGeneratedReports();
  }
  get industryURLName() {
    return myGlobals.industryName(this.localStorageService);
  }
  ngOnInit() {
    this.loadGeneratedReports();
  }

  loadGeneratedReports() {

    this.service.getReportList(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    this.service.allData$.subscribe(res => {
      if (res != null) {
        new Set(res.map(res => res.reportName)).forEach(e => {
          this.ReportTypeList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(invAlert => invAlert.generatedBy)).forEach(e => {
          this.GeneratedUserList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }


    });


  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {

    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }

  Print(report) {

    let docName = '';
    let docData = '';


    switch (report.reportName.toLowerCase()) {
      case 'inventory log':
        docName = 'invlog';
        docData = 'log';
        break;
      case 'inventory stock':
        docName = 'invstock';
        docData = 'stock';
        break;
      case 'sales detail':
        docName = 'salesDetail';
        docData = 'Sales Detail';
        break;
      case 'sales order type':
        docName = 'salesOrderType';
        docData = 'Sales Order Type';
        break;
      case 'sales transaction type':
        docName = 'salesTransactionType';
        docData = 'Sales Transaction Type';
        break;
      case 'voided cart items':
        docName = 'deletedItems';
        docData = 'Voided-Cart-Items';
        break;
      case 'refund report':
        docName = 'refund';
        docData = 'refund-report';
        break;
      case 'discount report':
        docName = 'discount';
        docData = 'discount-report';
        break;
      case 'tax report':
        docName = 'salesTax';
        docData = 'sales-tax';
        break;
      case 'daily sales':
        docName = 'dailySales';
        docData = 'Daily-Sales';
        break;
      case 'location sales':
        docName = 'locationSales';
        docData = 'Location-Sales';
        break;
      case 'product mix':
        docName = 'productMix';
        docData = 'Product-Mix';
        break;
      case 'partner summary':
        docName = 'partnerSummary';
        docData = 'Partner Summary';
        break;

    }
    this.printService.printDocumentWithData(docName, docData, report);
  }

  private getExportData(type, report) {
    switch (report.reportID) {
      case 1:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: InventoryLog[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-Logs', type);
          });
        break;
      case 2:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: InventoryStock[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-stock', type);
          });
        break;
      case 3:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: SalesDetail[] = res;

            if (!(logData[0].ReportVersion || logData[0].ReportVersion === 'V2')) {
              this.CustomizeReport(type, logData);
            }
            else {
              this.CustomizeReport_V2(type, logData);
            }
          });
        break;
      case 4:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: SalesOrderType[] = res;
            if (logData[0].LocationName) {
              delete logData['LocationName'];
              delete logData['LocationID'];
            }
            this.excelService.exportAsExcelFile(logData, 'Export-OrderType', type);
          });
        break;
      case 5:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: SalesTransactionType[] = res;
            this.CustomExportTransactionTypeReport(type, logData);
          });
        break;
      case 6:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData = this.getVoidedReportExportData(res);
            this.excelService.exportAsExcelFile(logData, 'Export-void-cart-items', type);
          });
        break;
      case 7:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: Refunds[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-refund', type);
          });
        break;
      case 8:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: Discounts[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-discount', type);
          });
        break;
      case 9:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: SalesTax[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-Tax', type);
          });
        break;
      case 10:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: DailySales[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-Daily-Sales', type);
          });
        break;
      case 11:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: LocationSalesSummary[] = res;
            this.excelService.exportAsExcelFile(logData, 'Export-LocationSales', type);
          });
        break;
      case 12:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: ProductMix[] = res;
            if (!logData[0].LocationName) {
              logData.forEach(d => {
                delete d['LocationName'];
                delete d['LocationID'];
              });

            }
            this.excelService.exportAsExcelFile(logData, 'Export-ProductMix', type);
          });
        break;
      case 13:
        this.partnerService.getSummaryReport(report.dataURL);
        this.partnerService.allData$.subscribe(e => {

        });
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: PartnerSummary[] = res;
            var _this = this;
            this.partnerService.sumOrderAverage = 0;
            this.partnerService.sumCard = 0;
            this.partnerService.sumCash = 0;
            this.partnerService.sumAggregator = 0;
            this.partnerService.sumDiscount = 0;
            this.partnerService.sumComplimentary = 0;
            this.partnerService.sumVoid = 0;
            this.partnerService.sumHold = 0;
            this.partnerService.sumRefund = 0;
            this.partnerService.sumSales = 0;
            this.partnerService.sumGrandTotal = 0;
            this.partnerService.sumTax = 0;

            logData.forEach(item => {
              _this.partnerService.sumValues('sumOrderAverage', item.OrderAverage)
              _this.partnerService.sumValues('sumCard', item.Card)
              _this.partnerService.sumValues('sumCash', item.Cash)
              _this.partnerService.sumValues('sumAggregator', item.Aggregator)
              _this.partnerService.sumValues('sumDiscount', item.Discount)
              _this.partnerService.sumValues('sumComplimentary', item.Complimentary)
              _this.partnerService.sumValues('sumVoid', item.Void)
              _this.partnerService.sumValues('sumHold', item.Hold)
              _this.partnerService.sumValues('sumRefund', item.Refund)
              _this.partnerService.sumValues('sumSales', item.Sales)
              _this.partnerService.sumValues('sumGrandTotal', item.GrandTotal)
              _this.partnerService.sumValues('sumTax', item.Tax)
            });
            logData.push({
              selected: false,
              BusinessDate: 'Total',
              Day: '',
              OrderCount: 0,
              HoldCount: 0,
              ComplimentaryCount: 0,
              Complimentary: parseFloat(_this.partnerService.sumComplimentary.toFixed(2)),
              OrderAverage: parseFloat(_this.partnerService.sumOrderAverage.toFixed(2)),
              Discount: parseFloat(_this.partnerService.sumDiscount.toFixed(2)),
              Void: parseFloat(_this.partnerService.sumVoid.toFixed(2)),
              Hold: parseFloat(_this.partnerService.sumHold.toFixed(2)),
              Refund: parseFloat(_this.partnerService.sumRefund.toFixed(2)),
              Sales: parseFloat(_this.partnerService.sumSales.toFixed(2)),
              GrandTotal: parseFloat(_this.partnerService.sumGrandTotal.toFixed(2)),
              Tax: parseFloat(_this.partnerService.sumTax.toFixed(2)),
              Cash: parseFloat(_this.partnerService.sumCash.toFixed(2)),
              Card: parseFloat(_this.partnerService.sumCard.toFixed(2)),
              Aggregator: parseFloat(_this.partnerService.sumAggregator.toFixed(2)),
              Wallet: 0
            });
            this.excelService.exportAsExcelFile(logData, 'Export-PartnerSummary', type);
          });
        break;
      case 14:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: StockMovementReport[] = res;
            this.ExportStockMovementReport(type, logData);
          });
        break;
      case 15:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: DetailTaxReport[] = res;
            this.DetailTaxExport(type, logData);
          });
        break;
    }
  }

  export(type, report): void {
    this.getExportData(type, report);
    //this.excelService.exportAsExcelFile(this.getExportData(report), 'Export-Logs',type);
    //this.getExportData(report);   
  }

  Preview(report) {
    if (this.permissionService.checkPermission(['Analytics', 'Reports', 'Generated Reports', 'Preview']) === false) {
      this.alertService.error("You have no permission to preview");
      return;
    }
    let reportNav = '';

    switch (report.reportID) {
      case 1:
        reportNav = 'inventorylogs';
        break;
      case 2:
        reportNav = 'inventorystock';
        break;
      case 3:
        reportNav = 'salesdetail';
        break;
      case 4:
        reportNav = 'salesordertype';
        break;
      case 5:
        reportNav = 'salestransactiontype';
        break;
      case 6:
        reportNav = 'deleted-items';
        break;
      case 7:
        reportNav = 'refund';
        break;
      case 8:
        reportNav = 'discount';
        break;
      case 9:
        reportNav = 'sales-tax';
        break;
      case 10:
        reportNav = 'daily-sales';
        break;
      case 11:
        reportNav = 'locationSalesSummary';
        break;
      case 12:
        reportNav = 'productMix';
        break;
      case 13:
        reportNav = 'partnerSummary';
        break;
      case 14:
        reportNav = 'stockMovement';
        break;
      case 15:
        reportNav = 'tobaccoTax';
        break;
    }
    this.router.navigate(['/' + this.industryURLName + `/report/${reportNav}`], { state: report });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Report has been Deleted");
      this.loadGeneratedReports();
    }, error => {
      this.alertService.error(error);
    });
  }


  public settLocationData(locations) {
    let location = locations.split(',');
    if (location.length > 1) {
      return location.length + " " + this.translate.instant("Locations");
    } else {
      return location[0];
    }
  }



  public getTimeDiff(date: Date) {
    const oToday = new Date();
    const oDatePublished = new Date(date);

    let nDiff = oToday.getTime() - oDatePublished.getTime();
    // Get diff in days
    const days = Math.floor(nDiff / 1000 / 60 / 60 / 24);
    nDiff -= days * 1000 * 60 * 60 * 24;

    // Get diff in hours
    const hours = Math.floor(nDiff / 1000 / 60 / 60);
    nDiff -= hours * 1000 * 60 * 60;

    // Get diff in minutes
    const minutes = Math.floor(nDiff / 1000 / 60);
    nDiff -= minutes * 1000 * 60;

    // Get diff in seconds
    const seconds = Math.floor(nDiff / 1000);

    // Render the diffs into friendly duration string

    // Days
    let sDays = '00';
    if (days > 0) {
      sDays = String(days);
    }
    if (sDays.length === 1) {
      sDays = '0' + sDays;
    }

    // Format Hours
    let sHour = '00';
    if (hours > 0) {
      sHour = String(hours);
    }
    if (sHour.length === 1) {
      sHour = '0' + sHour;
    }

    //  Format Minutes
    let sMins = '00';
    if (minutes > 0) {
      sMins = String(minutes);
    }
    if (sMins.length === 1) {
      sMins = '0' + sMins;
    }

    //  Format Seconds
    let sSecs = '00';
    if (seconds > 0) {
      sSecs = String(seconds);
    }
    if (sSecs.length === 1) {
      sSecs = '0' + sSecs;
    }
    let friendlyNiceText;
    let result = {
      value: 0,
      text: ''
    };
    // Set friendly text for printing
    if (days === 0) {

      if (hours === 0) {

        if (minutes === 0) {
          const sSecHolder = seconds > 1 ? 'Seconds' : 'Second';
          friendlyNiceText = seconds + ' ' + sSecHolder + ' ago';
          result.text = sSecHolder + ' ago';
          result.value = seconds;
        } else {
          const sMinutesHolder = minutes > 1 ? 'Minutes' : 'Minute';
          friendlyNiceText = minutes + ' ' + sMinutesHolder + ' ago';
          result.text = sMinutesHolder + ' ago';
          result.value = minutes;
        }

      } else {
        const sHourHolder = hours > 1 ? 'Hours' : 'Hour';
        friendlyNiceText = hours + ' ' + sHourHolder + ' ago';
        result.text = sHourHolder + ' ago';
        result.value = hours;
      }
    } else {
      const sDayHolder = days > 1 ? 'Days' : 'Day';
      friendlyNiceText = days + ' ' + sDayHolder + ' ago';
      result.text = sDayHolder + ' ago';
      result.value = days;
    }

    return result;
  }
  private CustomExportTransactionTypeReport(type, data) {

    let jsonData = [];
    if (data) {
      var _translate = this.translate;
      data.forEach(function (item) {
        let temp = {
          "Payment Type": _translate.instant(item.PaymentMode),
          "Total Pay": item.TotalPay
        };
        jsonData.push(temp);
      });
      this.excelService.exportAsExcelFile(jsonData, 'Export-TransactionType', type);
    }
    else
      (data != undefined) ? (data.length == 0 ? this.alertService.error("Data is not available.") : "") : "";

  }
  private CustomizeReport_V2(type, data) {
    let tableAvailable = data.some(a => (a.TableName));
    let total = {
      "RowType": "Total",
      "Sale": 0,
      "Discount": 0,
      "Refund": 0,
      "TotalTax": 0,
      "GrandTotal": 0,
      "Profit": 0,
      "PaidAmount": 0,
    };
    let jsonData = [];

    data.forEach(function (item) {
      let temp = {
        "DeviceTransactionNo": (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID,
        "Partner": item.PartnerName,
        "OrderNo": item.OrderNo,
        "OrderReference": item.OrderRefrenceID
      };
      if (tableAvailable)
        temp["TableName"] = item.TableName;

      temp["Category"] = "";
      temp["Sub-Category"] = "";
      temp["Name"] = "";
      temp["RowType"] = "Order";
      temp["Quantity"] = 0;
      temp["Cost"] = 0;
      temp["Sale"] = item.Sale;
      temp["Discount"] = item.DiscountAmount;
      temp["Refund"] = item.RefundAmount;
      temp["TotalTax"] = item.TaxAmount;
      temp["GrandTotal"] = item.GrandTotal;
      temp["Profit"] = parseFloat(item.Profit);
      temp["PaidAmount"] = item.TotalPayments;
      temp["Type"] = item.OrderType;
      temp["BarCode"] = "";
      temp["POSLocation"] = item.Location;
      temp["Location"] = item.Location;
      temp["PaymentMethod"] = item.PaymentMode;
      temp["TrancationID"] = "";
      temp["TrancationDateTime"] = item.CheckoutDate;// this.datePipe.transform(date; "yyyy-MM-dd");
      temp["TrancationCeatedBy"] = item.CheckoutBy;

      total.Sale += item.Sale;
      total.Discount += parseFloat(item.DiscountAmount);
      total.Refund += parseFloat(item.RefundAmount);
      total.TotalTax += parseFloat(item.TaxAmount);
      total.GrandTotal += parseFloat(item.GrandTotal);
      total.Profit += parseFloat(item.Profit);
      total.PaidAmount += parseFloat(item.TotalPayments);

      let duplicate = jsonData.filter(function (v, i) { return (v.OrderNo === item.OrderNo && v.POSLocation == item.Location && v.RowType === "Order" && v.DeviceTransactionNo === ((item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID)) })
      if (!duplicate || duplicate.length === 0) {
        if (item.TaxColumns?.length > 0) {
          for (var i = 0; i < item.TaxColumns.length; i++) {
            let amount = 0;
            for (var j = 0; j < item.Taxes.length; j++) {
              if (item.Taxes[j]?.Name && item.Taxes[j]?.Name === item.TaxColumns[i].Name) {
                amount = item.Taxes[j].Amount;
              }
            }
            temp[item.TaxColumns[i].Name] = amount;
            total[item.TaxColumns[i].Name] = (total[item.TaxColumns[i].Name] || 0) + amount;
          }
        }
        jsonData.push(temp);
        item.Details.forEach(function (itemDetail) {
          let tempDetail = {
            "DeviceTransactionNo": (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID,
            "Partner": item.PartnerName,
            "OrderNo": item.OrderNo,
            "Category": itemDetail.CategoryName,
            "Sub-Category": itemDetail.SubCategoryName,
            "Name": itemDetail.Name,
            "RowType": itemDetail.ItemType,
            "Quantity": itemDetail.Quantity,
            "Cost": (parseFloat(itemDetail.Cost)),
            "Sale": parseFloat(itemDetail.Sale),
            "Discount": itemDetail.DiscountAmount,
            "TotalTax": parseFloat(itemDetail.Tax),
            "GrandTotal": parseFloat(itemDetail.GrandTotal),
            "Profit": parseFloat(itemDetail.Profit),
            "PaidAmount": parseFloat(itemDetail.GrandTotal),
            "Type": item.OrderType,
            "BarCode": itemDetail.Barcode,
            "POSLocation": item.Location,
            "Location": item.Location,
            "PaymentMethod": item.PaymentMode,
            "TrancationID": "",
            "TrancationDateTime": item.CheckoutDate,// this.datePipe.transform(date, "yyyy-MM-dd"),
            "TrancationCeatedBy": item.CheckoutBy
          };
          jsonData.push(tempDetail);
          //jsonData.push(tempDetail);
        });
      }
      else {
        duplicate[0].PaymentMethod = "Multi";
      }


    });
    jsonData.push(total);
    this.excelService.exportAsExcelFile(jsonData, 'Export-SalesDetail', type);


  }
  private DetailTaxExport(type, data) {

    let report = [];
    data.forEach(e => {
      let d = {
        "OrderNo": e.OrderNo,
        "OrderID": e.OrderID,
        "DeviceTransactionNo": e.DeviceTransactionNo,
        "OrderDetailID": e.OrderDetailID,
        "BrandID": e.BrandID,
        "BrandName": e.BrandName,
        "BrandLogo": e.BrandLogo,
        "LocationID": e.LocationID,
        "DateTime": e.DateTime,
        "CategoryName": e.CategoryName,
        "AltCategoryName": e.AltCategoryName,
        "SubCategoryName": e.SubCategoryName,
        "AltSubCategoryName": e.AltSubCategoryName,
        "Name": e.Name,
        "AltName": e.AltName,
        "PaymentType": this.translate.instant(e.PaymentType),
        "Quantity": e.Quantity,
        "SubTotal": e.SubTotal,
        "Discount": e.Discount,
        "DiscountPercentage": e.DiscountPercentage
      };
      e.TaxColumns.forEach(x => {
        let name = (this.selectedLang == 'en') ? x.Name : x.ArabicName;
        d[name] = this.ShowCustomTaxes(e.Taxes, name);
      });
      d["GrandTotal"] = e.GrandTotal;
      d["selected"] = e.selected;
      report.push(d);
    });



    this.excelService.exportAsExcelFile(report, 'Export-DetailTax', type);

  }
  private ExportStockMovementReport(type, data) {

    let jsonData = [];

    data.source._value.forEach(function (item) {
      let temp = {
        "Location": item.Location,
        "Name": item.Name,
        "AlternateName": item.AlternateName,
        "Unit": item.Unit,
        "SKU": item.SKU,
        "InitialQTY": item.InitialQTY,
        "InitialQtyCost": item.Cost,
        "PurchasedQTY": item.PurchasedQTY,
        "ReturnedPurchasedQTY": '-' + item.ReturnedPurchasedQTY,
        "TransferQTY": item.TransferQTY,
        "ReturnedTransferQTY": '-' + item.ReturnedTransferQTY,
        "TransferOutQTY": '-' + item.TransferOutQTY,
        "ReturnedTransferOutQTY": item.ReturnedTransferOutQTY,
        "PrepConsumption": '-' + item.PrepConsumption,
        "PrepReceived": item.PrepReceived,
        "SalesConsumption": '-' + item.SalesConsumption,
        "SalesReturn": item.SalesReturn,
        "ReconciliationQTY": '-' + item.ReconciliationQTY,
        //"StockCountDifference": item.StockDifference,
        //"ExpiredQTY": '-' + item.ExpiredQTY,
        "Final Quantity": item.FinalQuantity,
        "Final QTY Cost": item.FinalQTYCost
      };
      jsonData.push(temp);
    });
    this.excelService.exportAsExcelFile(jsonData, 'Export-StockMovement', type);
  }

  private CustomizeReport(type, data) {

    let jsonData = [];

    data.forEach(function (item) {
      let temp = {
        "DeviceTransactionNo": (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID,
        "Partner": item.PartnerName,
        "OrderNo": item.OrderNo,
        "OrderReference": item.OrderRefrenceID,
        "Category": "",
        "Sub-Category": "",
        "Name": "",
        "RowType": "Order",
        "Quantity": 0,
        "Cost": 0,
        "Sale": parseFloat(item.GrandTotal) - parseFloat(item.TaxAmount),
        "Discount": item.DiscountAmount,
        "Refund": item.RefundAmount,
        "Tax": item.TaxAmount,
        "GrandTotal": item.GrandTotal,
        "Profit": parseFloat(item.Profit),
        "Type": item.OrderType,
        "BarCode": "",
        "POSLocation": item.Location,
        "PaymentMethod": item.PaymentMode,
        "TrancationID": "",
        "TrancationDateTime": item.CheckoutDate,// this.datePipe.transform(date, "yyyy-MM-dd"),
        "TrancationCeatedBy": item.CheckoutBy
      };
      if (item.selected != undefined)
        if (item.selected == true) {
          jsonData.push(temp)
          item.Details.forEach(function (itemDetail) {
            let tempDetail = {
              "DeviceTransactionNo": (item.DeviceTransactionNo) ? item.DeviceTransactionNo : item.OfflineUniqueID,
              "Partner": item.PartnerName,
              "OrderNo": item.OrderNo,
              "Category": itemDetail.CategoryName,
              "Sub-Category": itemDetail.SubCategoryName,
              "Name": itemDetail.Name,
              "RowType": itemDetail.ItemType,
              "Quantity": itemDetail.Quantity,
              "Cost": (parseFloat(itemDetail.Cost)),
              "Sale": parseFloat(itemDetail.Quantity) * (parseFloat(itemDetail.PriceWithVAT)),
              "Discount": itemDetail.DiscountAmount,
              "Tax": parseFloat(itemDetail.Quantity) * (parseFloat(itemDetail.PriceWithVAT) - parseFloat(itemDetail.Price)),
              "GrandTotal": parseFloat(itemDetail.Quantity) * itemDetail.PriceWithVAT,
              "Profit": (parseFloat(itemDetail.Quantity) * parseFloat(itemDetail.Price)) - parseFloat(itemDetail.Cost),
              "Type": item.OrderType,
              "BarCode": itemDetail.Barcode,
              "POSLocation": item.Location,
              "PaymentMethod": item.PaymentMode,
              "TrancationID": "",
              "TrancationDateTime": item.CheckoutDate,// this.datePipe.transform(date, "yyyy-MM-dd"),
              "TrancationCeatedBy": item.CheckoutBy
            };
            (item.selected == true) ? jsonData.push(tempDetail) : "";
            //jsonData.push(tempDetail);
          });
        }

    });
    this.excelService.exportAsExcelFile(jsonData, 'Export-SalesDetail', type);
  }

  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Report has been Deleted");
            this.loadGeneratedReports();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }


  ReportTypeList: any = [];
  GeneratedUserList: any = [];
  SelectedGeneratedBy = "Created By";
  filters() {
    let headerFilter = {
      generatedBy: (this.SelectedGeneratedBy == 'Created By') ? [] : [this.SelectedGeneratedBy],
      reportName: this.ReportTypeList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })

    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }


  setSelectedResult(val) {

    if (val == 'reportTypes') {
      return this.ReportTypeList.filter(k => k.isChecked == true).length;
    }
    if (val == 'createdBy') {
      return this.GeneratedUserList.filter(k => k.isChecked == true).length;
    }

  }
  private getVoidedReportExportData(data) {
    let selected: DeletedItems[];
    selected = [];
    data.forEach(d => {
      selected.push({
        OfflineUniqueID: d.OfflineUniqueID,
        OrderID: d.OrderID,
        OrderNo: d.OrderNo,
        BusinessDate: d.BusinessDate,
        Location: d.Location,
        Type: '',
        Details: null,
        TotalAmount: d.TotalAmount,
        TaxAmount: d.TaxAmount,
        GrandTotal: d.GrandTotal,
        selected: false,
        VoidItems: d.Name,
        VoidArabicItems: d.AltName
      });
      d.Details.forEach(dt => {
        selected.push({
          OfflineUniqueID: d.OfflineUniqueID,
          OrderID: d.OrderID,
          OrderNo: d.OrderNo,
          BusinessDate: d.BusinessDate,
          Location: d.Location,
          Type: 'Item',
          Details: null,
          TotalAmount: (dt.Quantity * dt.PriceWithVAT).toString(),
          TaxAmount: dt.Tax,
          GrandTotal: (dt.Quantity * dt.PriceWithVAT).toString(),
          selected: false,
          VoidItems: dt.Name,
          VoidArabicItems: dt.AltName
        });
      });
    });
    return selected;
  }
  showPDFOption(report) {
    let show: boolean = false;
    switch (report.reportID) {
      case 15:
        show = true;
        break;
      default:
        show = false;
        break;
    }
    return show;
  }
  public generateDetailTaxPDF(report) {

    switch (report.reportID) {
      case 15:
        fetch(report.dataURL)
          .then(res => res.json())
          .then(res => {
            var logData: DetailTaxReport[] = res;
            let data = {
              StartDate: report.dateRange.split(' ')[0],
              EndDate: report.dateRange.split(' ')[2],
              Report: logData
            };
            this.pdfService.GenerateDetailTaxPDF(data).then(x => {

              const data = `data:application/pdf;base64,${x}`;
              var link = document.createElement('a');
              link.href = data;
              link.download = "DetailTax-PDF.pdf";
              link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            });

          });
        break;
    }

  }
  ShowCustomTaxes(Taxes: DetailTaxes[], taxName: string) {
    let val = null;
    if (Taxes && taxName)
      val = Taxes.find(e => e.Name == taxName);
    return val?.Amount ?? 0;
  }
}
