<form [formGroup]="receivepreviewtransferForm" class="form">
    <div class="modal-header">

        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title"> {{'Transfer Order' | translate }} {{f.tsCode.value}} </h4>

        <button [disabled]="loading" class="btn btn-save m-0" (click)="onSubmit(f)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
          {{ButtonText | translate}}
        </button>


    </div>
    <div class="modal-body pr-0 pl-0">
        <nav class="navbar sticky-top navbar-light bg-white  sticky-header-preview p-3">
            <div class="row w-100">
                <div class="col-lg-6 d-flex">
                  <button type="button" class=" btn btn-secondary m-1">{{'Transfer Order' | translate }}</button>
                    <!-- <button type="button" class="btn btn-light m-1">Transfer History</button>
                    <button type="button" class="btn btn-light m-1">Receives</button> -->
                </div>
                <div class="col-lg-6 text-end">
                    <div class=" d-inline-flex">
                        <div placement="bottom" [ngbPopover]="popContent">
                            <button class="btn sticky-header-preview-drpbtn m-0 me-2"><b>{{'Recieve' | translate }}</b></button>
                        </div>
                        <ng-template #popContent>
                          <p class="dropdown-heading-mini">{{'TRANSFER' | translate }}</p>
                          <a (click)="selectedStatus('receive')">{{'Marked as Received' | translate }}</a>
                          <a (click)="CreateReceive(f)">{{'Create Receive' | translate }}</a>
                        </ng-template>

                        <div placement="bottom" [ngbPopover]="popContent1">
                            <button class="btn sticky-header-preview-drpbtn  m-0 me-2"><b>{{'Export' | translate }} </b></button>
                        </div>
                        <ng-template #popContent1>
                          <!--<p (click)="export('csv')">Excel</p>-->
                          <a (click)="export('xlsx')">{{'Excel' | translate }}</a>
                          <a (click)="export('csv')">{{'CSV' | translate }}</a>



                        </ng-template>
                        <!-- <div placement="bottom" [ngbPopover]="popContent2">
                            <button class="btn sticky-header-preview-drpbtn m-0 me-2"><b> More</b></button>
                        </div>
                        <ng-template #popContent2>
                            <p class="dropdown-heading-mini">TRANSFER</p>
                            <a (click)="Edit(purchaseorder)">Approve Transfers</a>
                            <a (click)="Edit(purchaseorder)">Decline Transfers</a>
                            <a (click)="Edit(purchaseorder)">Hold Transfers</a>
                            <a class="selected-inner-bottom"><span class=" po-preview-action-text"> Edit Transfers  </span></a>
                        </ng-template> -->
                    </div>
                </div>
            </div>
        </nav>

        <div class="container" id="recPreTrans">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-1"></div>
            <div class="col-lg-8 mt-5">
              <div class="jumbotron po-preview-box">
                <div class="row form-group">
                  <div class="col-lg-6 text-start">
                    <!-- <img src="/assets/images/marn-logo-po.png" alt="" /> -->
                    <img class="brandLogo" [src]="ls.getSelectedBrand().BrandLogo" alt="" />
                  </div>
                  <div class="col-lg-6 text-end">
                    <h3 class="font-weight-bold ">{{'Transfer Order' | translate }}</h3>
                    <h5 class=" ponum-heading ">{{f.tsCode.value}}</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 text-start">
                    <div [ngClass]="renderStatusClass(f.statusID.value)" class="btn btn-sm">
                      {{ (f.statusID.value ==600?'Created': f.statusID.value ==601?'Approved': f.statusID.value ==602?'Pending': f.statusID.value ==603?'Declined': f.statusID.value ==604?'Hold': f.statusID.value ==605?'Received': f.statusID.value ==605?'Returned': '-') | translate}}
                    </div>
                  </div>
                  <div class="col-lg-6 text-end">
                    <h6 class="font-weight-bold ">{{'Ref' | translate }}# {{f.referenceNo.value}}</h6>
                  </div>
                </div>
                <div class="my-4 divider"></div>
                <div class="row mb-5">
                  <div class="col-lg-6 text-start">
                    <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                    <label>{{f.date.value |date}}</label>
                  </div>
                  <div class="col-lg-6 text-end">
                    <h6 class="font-weight-bold ">{{'Expected Date' | translate }}</h6>
                    <label>{{f.expectedDate.value | date}}</label>
                  </div>
                </div>

                <div class="row  mb-5">
                  <div class="col-lg-6 text-start">
                    <h6 class="font-weight-bold po-preview-blue ">{{'From' | translate }}</h6>
                    <h6 class="font-weight-bold ">{{f.fromLocationName.value}}</h6>
                    <p>{{f.fromLocationAddress.value}}</p>
                    <label>{{f.fromLocationContact.value}}</label>
                  </div>
                  <div class="col-lg-6 text-end">
                    <h6 class="font-weight-bold po-preview-blue ">{{'Deliver to' | translate }}</h6>
                    <h6 class="font-weight-bold ">{{f.toLocationName.value}}</h6>
                    <p>{{f.toLocationAddress.value}}</p>
                    <label>{{f.toLocationContact.value}}</label>
                  </div>
                </div>
                <div class="table-V2">
                  <table class="table">
                    <thead class="table-header">
                      <tr>
                        <th width="30%" scope="col" class="text-center">{{'Item Name' | translate }}</th>
                        <th width="15%" scope="col" class="text-center">{{'Received Quantity' | translate }}</th>
                        <th width="15%" scope="col" class="text-center">{{'Quantity' | translate }}</th>
                        <th width="15%" scope="col" class="text-center">{{'Unit Cost' | translate }}</th>
                        <th width="15%" scope="col" class="text-center">{{'Total Cost' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of arrItems">
                        <td class="text-center">
                        
                          {{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}
                        </td>
                        <td class="text-center">{{item.receivedQty}} {{item.unit}}</td>
                        <td class="text-center">{{item.quantity }}  {{item.unit}}</td>
                        <td class="text-center">{{(item.cost || item.cost == 0)?(item.cost | number : '1.2-2'):'-' }} {{(item.cost || item.cost == 0)? brandCurrency:''}}</td>
                        <td class="text-center">{{(item.cost || item.cost == 0)?(item.quantity * item.cost | number : '1.2-2'):'-' }} {{(item.cost || item.cost == 0)? brandCurrency:''}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table class="table w-25 move-to-end" *ngIf="totalCost || totalCost == 0">
                    <thead class="table-header">
                      <tr>
                        <th scope="col" width="40%" class="text-center rounded-0 align-middle">{{'Total Cost' | translate }}</th>
                        <th scope="col" width="60%" class="text-center rounded-0 align-middle">{{totalCost | number : '1.2-2'}} {{brandCurrency}}</th>
                      </tr>
                    </thead>
                  </table>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
        </div>
    </div>

</form>
