export class Role {
  roleID: number;
  roleName: string;
  cssClass: string;
  fullAccess: boolean;
  type: string;
  permissions: string;
  users: string;
  rowVersion: any;
  statusID: number;
  rights: RoleRightDto[];
  selected: boolean;
}


export class RoleRightDto {

  id: number;
  formName: string;
  description: string;
  formOrder: number;
  type: string;
  subMenuID: number;
  isMenuItem: boolean;
  statusID: number;
  access: boolean;
  icon: string;
  child: RoleRightDto[];
}
