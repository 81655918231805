import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import * as myGlobals from '../_helpers/globals';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ZATCAService {
  URL: string = null;
  industry: string = null;
  checkZatcaCompliance: boolean = false;
  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService) {
    this.URL = environment.ZATCA_Config.URL;
    this.industry = myGlobals.industryName(this.localStorageService);
    this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;
  }
  PostInvoice(data) {
    if (this.checkZatcaCompliance) {
      let order = {
        orderID: data
      };
      return this.http.post(this.URL + `api/${this.industry}/invoice/PostByID`, order)
        .pipe(map(res => {
          console.log(res);
          return res;
        }));
    }
  }
}
