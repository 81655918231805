<main class="views container">
  <div class="page">
    <div class="title-header tCustom-header customer-header" style="margin-bottom:50px">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
          <h4>{{'Setup' | translate }} </h4>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let report of Setups">
      <div class="row report-list p-2" *ngIf="report.isAvailable === 1">
        <div class="row pb-4">
          <div class="title">
            <span>{{report.title | translate }}  </span>
          </div>
        </div>
        <div class="page-body report-cards">
          <div class="row mb-4">
            <ng-container *ngFor="let list of report?.list">
              <ng-container *ngIf="list.isAvailable === 1">
                <div class="col-sm-12 col-lg-4 col-md-6 col-lg-4 col-xl-4" *hasPermission="list.permission">
                  <div class="card" *ngIf="list.isFormPopup==false" [routerLink]="['/'+industryURLName+'/'+list.url]" [ngClass]="{ 'disable': list.isAvailable == 0}">
                    <div class="card-horizontal">
                      <div class="img-round" [ngStyle]="{'background': list.color}">
                        <img src={{list.icon}} />
                        <span class="report-soon" *ngIf="list.isAvailable == 0">{{'SOON' | translate }}</span>
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">{{list.title | translate }}</h4>
                        <p class="text-mute ">{{list.detail | translate }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="card" *ngIf="list.isFormPopup==true" (click)="openForm(list)" [ngClass]="{ 'disable': list.isAvailable == 0}">
                    <div class="card-horizontal">
                      <div class="img-round" [ngStyle]="{'background': list.color}">
                        <img src={{list.icon}} />
                        <span class="report-soon" *ngIf="list.isAvailable == 0">{{'SOON' | translate }}</span>
                      </div>
                      <div class="card-body">
                        <h4 class="card-title">{{list.title | translate }}</h4>
                        <p class="text-mute ">{{list.detail | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</main>


