import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';

import { SalesMonitor } from '../_models/SalesMonitor';

interface SalesMonitorResult {
  data: SalesMonitor[];
  total: number;
}
interface ItemSalesHeaderState {
  subCategory: string[],
}
export const contentHeaders = new Headers();
const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: SalesMonitor[], column: SortColumn, direction: string): SalesMonitor[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = `${a[column]}`.toString().localeCompare(`${b[column]}`.toString(), undefined, { numeric: true });
      //const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: SalesMonitor, term: string) {
  return data.name.toLowerCase().includes(term.toLowerCase()) || data.altName.toLowerCase().includes(term.toLowerCase()) || data.barcode.toLowerCase().includes(term.toLowerCase())
}

function matcheHeader(data: SalesMonitor, filterValues: ItemSalesHeaderState) {
  let subCategory = false;
  
  // Order Taker
  if (filterValues.subCategory !== null && filterValues.subCategory.length > 0) {
    for (var i = 0, len = filterValues.subCategory.length; i < len; i++) {
      if (data.subCategoryName.toLowerCase().toString().includes(filterValues.subCategory[i].toLowerCase())) {
        subCategory = true;
      }
      if (data.altSubCategoryName.toLowerCase().toString().includes(filterValues.subCategory[i].toLowerCase())) {
        subCategory = true;
      }
    }
  }
  else {
    subCategory = true;
  }

  if (subCategory ) {
    return data;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SalesmonitorService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _searchHeader$ = new Subject<void>();
  public _allData$ = new BehaviorSubject<SalesMonitor[]>([]);
  private _data$ = new BehaviorSubject<SalesMonitor[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public report: any;
  private _state: State = {
    page: 1,
    pageSize: Number.MAX_SAFE_INTEGER,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  private _headerState: ItemSalesHeaderState = {
    subCategory: null
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get headerFilter() { return this._headerState; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  set headerFilter(headerFilter: ItemSalesHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }


  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }
  constructor(private http: HttpClient) {
  }
  public getSalesMonitoring(start, end, locationID, brandId) {

    const url = `api/ItemSales/GetItemSales/${start}/${end}/${locationID}/${brandId}/`;
    tap(() => this._loading$.next(true)),
      this.http.get<SalesMonitor[]>(url).subscribe(res => {
        this.report = res;
        this._allData$.next(this.report);
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();
      });


  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  private _setHeader(headerFilter: ItemSalesHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }

  private _search(): Observable<SalesMonitorResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
  private _searchByHeader(): Observable<SalesMonitorResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: Number.MAX_SAFE_INTEGER,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

}
