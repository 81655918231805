import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { InventoryAlert } from '../_models/inventory-alert';
import { State } from '../_models/State';




const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: InventoryAlert[], column: SortColumn, direction: string): InventoryAlert[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: InventoryAlert, term: string) {

  return data.name.toLowerCase().includes(term.toLowerCase())
    || data.alternativeName.toLowerCase().includes(term.toLowerCase())
    || data.locationName.toLowerCase().includes(term.toLowerCase())
    || data.supplierName.toLowerCase().includes(term.toLowerCase())
}



//@@@
function matcheHeader(data: InventoryAlert, filterValues: InventoryAlertHeaderState) {
  let supplier = false;
  let location = false;

  // Suppier
  if (filterValues.supplierName !== null && filterValues.supplierName.length > 0) {
    for (var i = 0, len = filterValues.supplierName.length; i < len; i++) {
      if (data.supplierName.toLowerCase().toString().includes(filterValues.supplierName[i].toLowerCase())) {
        supplier = true;
      }
    }
  }
  else {
    supplier = true;
  }

  // Locations
  if (filterValues.locationName !== null && filterValues.locationName.length > 0) {
    for (var i = 0, len = filterValues.locationName.length; i < len; i++) {
      if (data.locationName.toLowerCase().toString().includes(filterValues.locationName[i].toLowerCase())) {
        location = true;
      }
    }
  }
  else {
    location = true;
  }
  if (supplier && location) {
    return data;
  }

}

interface SearchInventoryAlertResult {
  data: InventoryAlert[];
  total: number;
}
//@@@
interface InventoryAlertHeaderState {
  supplierName: string[],
  locationName: string[],

}

@Injectable({
  providedIn: 'root'
})
export class InventoryAlertService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _searchHeader$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<InventoryAlert[]>([]);
  private _data$ = new BehaviorSubject<InventoryAlert[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public items: InventoryAlert[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  private _headerState: InventoryAlertHeaderState = {
    supplierName: null,
    locationName: null
  }


  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: InventoryAlertHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }


  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }

  public getInventoryAlerts(brandId, locations, suppliers) {

    const url = `api/Inventory/GetInventoryAlerts/${brandId}/${locations}/${suppliers}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<InventoryAlert[]>(url).subscribe(res => {

        this.items = res;

        this.items.forEach(e => {
          e.disable = false;
        });
        this._data$.next(this.items);
        this._allData$.next(this.items);





        // this._search$.pipe(
        //   switchMap(() => this._search()),
        //   tap(() => this._loading$.next(false))
        // ).subscribe(result => {
        //   this._data$.next(result.data);
        //   this._total$.next(result.total);
        // });

        // this._search$.next();



        // @@@
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

      });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  //@@@
  private _setHeader(headerFilter: InventoryAlertHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _search(): Observable<SearchInventoryAlertResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.items, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }


  //@@@
  private _searchByHeader(): Observable<SearchInventoryAlertResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.items, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }



  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();

    //@@@
    this._searchHeader$.next();

    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

}
