import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { LocationService } from 'src/app/_services/location.service';
import { CommonService } from '../../../../_services/common.service';
import { SalesSummaryReportService } from '../../../../_services/salessummaryreport.service';
import * as myGlobals from '../../../../_helpers/globals';
import { NgbdDatepickerRangePopup } from '../../../../daterangepicker/datepicker-range-popup';
import { PdfService } from '../../../../_services/pdf.service';
import { SalesSummaryPDFComponent } from '../../../../pdf-template/sales-summary-pdf/sales-summary-pdf.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sales-summary-report',
  templateUrl: './sales-summary-report.component.html',
  styleUrls: ['./sales-summary-report.component.css']
})
export class SalesSummaryReportComponent implements OnInit {

  public selectedLocationName = 'All Locations';
  public selectedDate = new Date();
  private selectedBrand;
  public selectedLocation;
  LocationsList = [];
  placement = 'bottom';
  loading = false;
  selctedLang = 'en';
  startdate: any;
  enddate: any;
  dtModel: string = this.setToday();
  salesSummaryData: any;
  salesSummary: any;
  refundReason = [];
  orderTakers = [];
  mostSoldItems = [];
  inventoryAlerts = [];
  cardTypes = [];
  taxes = [];
  Currency: any;
  industryName;
  export: boolean = false;
  isGeneratingPDF: boolean = false;
  @ViewChild(NgbdDatepickerRangePopup, { static: false }) dateComp: { fromDate: any; toDate: any; };
  constructor(private localStorageService: LocalStorageService,
    public setting: CommonService,
    private service: SalesSummaryReportService,
    private location: LocationService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    private pdfService: PdfService) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLocation = this.localStorageService.getSelectedLocation().LocationID;
    this.industryName = myGlobals.industryName(this.localStorageService);
    this.loadLocations();
    //this.loadSettings();
  }

  ngOnInit() {
  }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }
  public setLocation(val) {
    if (val === null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';

    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;


    }
    this.loadSalesSummary();
  }
  loadLocations() {
    this.location.getAllLocationsddl(this.selectedBrand).subscribe((res: any) => {
      this.LocationsList = res;
      this.loadSalesSummary();
    });
  }
  setToday() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }



  loadSalesSummary() {
    if (this.dateComp.fromDate && this.dateComp.toDate && this.dateComp.fromDate !== '' && this.dateComp.toDate !== '') {
      this.loading = true;
      this.startdate = this.dateComp.fromDate.year + "-" + this.dateComp.fromDate.month + "-" + this.dateComp.fromDate.day;
      this.enddate = this.dateComp.toDate.year + "-" + this.dateComp.toDate.month + "-" + this.dateComp.toDate.day;
      this.service.getSalesSummary(this.selectedBrand, this.selectedLocation, this.startdate, this.enddate).subscribe((res: any) => {
        console.log(res);
        this.salesSummaryData = res;
        this.salesSummary = res.salesSummary;
        this.refundReason = res.refundReason;
        this.orderTakers = res.orderTakers;
        this.mostSoldItems = res.mostSoldItems;
        this.inventoryAlerts = res.inventoryAlerts;
        this.cardTypes = res.cardTypes;
        this.taxes = res.taxes;
        this.loading = false;
      });
    }
  }


  public setDate(date) {
    this.selectedDate = new Date(date.year + "-" + date.month + "-" + date.day);
    this.loadSalesSummary();

  }

  loadSettings() {
    this.setting.getCurrencySettings(this.localStorageService.getSelectedBrand().BrandID).subscribe((res: any) => {
      this.Currency = res.currency;
    });
  }
  receive($event) {
    this.pdfService.SaveAsPDFFileMultiPage($event, 'Sales-Summary', "htmlData");
    this.pdfService.PDFGenerated.subscribe((res: any) => {
      if (res)
        this.isGeneratingPDF = false;
    });
  }
  public openPDF(): void {
    this.isGeneratingPDF = true;
    this.export = true;
    setTimeout(() => {
      this.export = false;
    }, 3000);
    //const modalRef = this.modalService.open(SalesSummaryPDFComponent, { size: 'xl', windowClass: 'full-modal', animation: false });

    //modalRef.result.then((result) => {
    //}, (reason) => {

    //});
  }
  generatePDF() {
    this.isGeneratingPDF = true;
    let data = this.salesSummaryData;
    data.filters=({
      startDate: this.startdate,
      endDate: this.enddate,
      brandName: this.localStorageService.getSelectedBrand().BrandName,
      currency: this.brandCurrency,
      locationName: this.selectedLocationName,
      brandLogo: this.localStorageService.getSelectedBrand().BrandLogo
    });
    this.pdfService.GenerateSalesSummaryPDF(data).then(x => {
      const data = `data:application/pdf;base64,${x}`;
      var link = document.createElement('a');
      link.href = data;
      link.download = "Sales-Summary.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.isGeneratingPDF = false;
    });



  }



}



