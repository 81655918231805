<form [formGroup]="periodForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title-lg">
      {{ 'Invoice Period'| translate}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 form-group">
        <label for="startTime">{{'Start Period' | translate }}</label>
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-6 col-6 col-xl-6 col-xxl-6">
            <app-date-picker-v2 #startDate></app-date-picker-v2>
            <div *ngIf="f.startTime.invalid && (f.startTime.dirty || f.startTime.touched)" class="alert alert-danger">
              <div *ngIf="f.startTime.errors.required">
                {{'Start Date is required' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-6 col-xl-6 col-xxl-6">
            <input type="time" class="form-control custom-timepicker border rounded" placeholder="" formControlName="startTime" />
            <div *ngIf="f.startTime.invalid && (f.startTime.dirty || f.startTime.touched)" class="alert alert-danger">
              <div *ngIf="f.startTime.errors.required">
                {{'Start Time is required' | translate }}.
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 form-group ">
        <label for="endTime">{{'End Period' | translate }}</label>
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-6 col-6 col-xl-6 col-xxl-6">
            <app-date-picker-v2 #endDate></app-date-picker-v2>
            <div *ngIf="f.endTime.invalid && (f.endTime.dirty || f.endTime.touched)" class="alert alert-danger">
              <div *ngIf="f.endTime.errors.required">
                {{'End Date is required' | translate }}.
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-6 col-xl-6 col-xxl-6">
            <input type="time" class="form-control custom-timepicker border rounded" placeholder="" formControlName="endTime" />
            <div *ngIf="f.endTime.invalid && (f.endTime.dirty || f.endTime.touched)" class="alert alert-danger">
              <div *ngIf="f.endTime.errors.required">
                {{'End Time is required' | translate }}.
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button [disabled]="loading" class="btn btn-primary cutom-button  custom-create-btn ms-2">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>   {{'Save'  | translate }}
    </button>
  </div>
</form>
