<main class="views container  order-list product-list">
  <div class="page">
    <div class="mnt-header position-relative">


      <div class="page-header">
        <div class="tile-header tCustom-header">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
              <h4>    <a backLink>{{'Setup' | translate }} </a>&nbsp;/ {{'Taxes' | translate }}</h4>
            </div>
            <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12" *hasPermission="['Orders','Filter']">
              <div class="top-bar-flex">

                <div class="create-btn ms-2">
                  <div *hasPermission="['Setup','Taxes', 'Insert']">
                    <button type="button" (click)="selectTemplate()" class="btn btn-primary cutom-button form-control custom-create-btn">
                      {{'+ New Tax' | translate}}
                    </button>
                  </div>

                </div>

                <div class="create-btn- ms-2">
                  <div *hasPermission="['Setup','Taxes', 'Manage']">
                    <button type="button" (click)="openManageTaxes()" class="btn btn-default btn-lightblue form-control" style=" width: 150px; ">
                      {{'Manage Taxes' | translate}}
                    </button>
                  </div>
                </div>

                <div class="filter-field">
                  <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="(service.taxes?.length > 0) && ((data$ | async)?.length > 0)">
        <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
          <div class="tile-body p-0 table-responsive text-nowrap">
            <table class="table table-striped taxes text-start">
              <thead>
                <tr class="table-header">
                  <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                  <th width="20%" sortable="name" (sort)="onSort($event)">{{'Tax Name' | translate}}  <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th width="20%" sortable="arabicName" (sort)="onSort($event)">{{'Tax Name (AR)' | translate}}  <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th width="20%" sortable="percentage" (sort)="onSort($event)">{{'Percentage' | translate}}  <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th width="20%" sortable="percentage" (sort)="onSort($event)">{{'Applicable on' | translate}}  <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th width="10%" class="text-center">

                    <!-- Status Filter -->
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                        <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                 name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                          <label class="form-check-label font-blue" for="dropdownStatus_all"
                                 (click)="selectAllFilters($event,StatusList)">
                            {{'All Status' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let item of StatusList; let i = index">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                            <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                              <!-- {{item.name}} -->

                              <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                             class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                              </ngb-highlight>


                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </th>
                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data$ | async " editRow>
                  <td class="text-center">
                    <input type="checkbox" class="form-check-input" (change)="chkSelection($event,supplier)" [(ngModel)]="item.selected" />
                  </td>
                  <td class="name-blue" (click)="Edit(item)">
                    <div class="">
                      {{item.name}}
                      <!-- class="item-title" -->
                    </div>
                  </td>
                  <td (click)="Edit(item)">{{item.arabicName}}</td>
                  <td (click)="Edit(item)">{{item.percentage}}</td>
                  <td (click)="Edit(item)">{{item.applicableOn | translate}}</td>
                  <td (click)="Edit(item)" class="text-center">
                    <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                    </ngb-highlight>
                  </td>
                  <td>
                    <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                      <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                    </div>
                    <ng-template #popContent>
                      <p class="dropdown-heading-mini">{{'Taxes' | translate}} </p>
                      <a *hasPermission="['Setup','Taxes', 'Edit']" (click)="Edit(item)">{{'Edit' | translate}} </a>
                      <a *hasPermission="['Setup','Taxes','Deactivate']" (click)="SetStatus(item.taxID,item.statusID === 1 ?2:1, item.rowVersion)">{{((item.statusID === 1)?'Deactivate':'Activate') | translate }}</a>
                      <!--<a *hasPermission="['Setup','Taxes', 'Delete']" (click)="openConfirmationDialog(item.taxID, item.rowVersion,item.name)">{{'Delete' | translate}} </a>-->
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>


        <div class="row mt-10">

          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <p class="pagination-count">
              <strong class="p-4">

                {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ |  async)!}} {{'results' | translate }}
              </strong>
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                            [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
              <ng-template ngbPaginationFirst>
                <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
              </ng-template>
              <ng-template ngbPaginationLast>
                <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
              </ng-template>
              <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
              <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
          </div>

        </div>

      </div>



      <div *ngIf="(service.taxes?.length == 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Tax yet' | translate }}</strong>
          <p class="text-center width-375">{{'Create New Tax.' | translate}}</p>
        </div>
        <div class="items-flex">
          <div class="create-btn mlmr-10">
            <div *hasPermission="['Setup','Taxes', 'Insert']">
              <button type="button" (click)="selectTemplate()" class="btn btn-primary cutom-button form-control custom-create-btn">
                {{'+ New Tax' | translate}}
              </button>
            </div>
          </div>


        </div>
      </div>




      <div *ngIf="(service.taxes?.length > 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Record Found' | translate }}</strong>
          <p class="text-center width-375">{{'Create tax for order.' | translate}}</p>
        </div>
      </div>



    </div>
  </div>
</main>


