import { Component } from '@angular/core';
import { UserinfoService } from '../_services/userinfo.service';

import { LocalStorageService } from '../_services/local-storage.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptComponent } from '../setup/receipt/receipt.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  toggled: boolean = false;
  Brands: any;
  Locations: any;
  ImageError: boolean;
  SelectedBrand: any;
  SelectedLocation: any;
  SelectedUser: any;

  constructor(private userInfoService: UserinfoService,
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal) {
    this.getUserInfo();
    this.ImageError = false;
  }

  private getUserInfo() {
    this.userInfoService.getUserInfo().then((res: any) => {

      this.Brands = res.Brands;
      this.SelectedUser = res.User;

      if (this.localStorageService.checkAlreadySelected()) {

        this.SelectedBrand = this.localStorageService.getSelectedBrand();
        this.Locations = this.SelectedBrand.Locations;
        this.SelectedLocation = this.Locations[0];
        this.SelectedLocation = this.localStorageService.getSelectedLocation();
      }
      else {
        this.SelectedBrand = this.Brands[0];
        this.Locations = this.Brands[0].Locations;
        this.SelectedLocation = this.Locations[0];

        this.localStorageService.setSelectedBrand(this.SelectedBrand);
        this.localStorageService.setSelectedLocation(this.SelectedLocation);
      }

      console.info('header data',
        this.SelectedBrand,
        this.SelectedLocation
      )
    });
  }

  public errorHandler(event) {
    this.ImageError = true;
  }

  public changeBrand(brand) {
    this.SelectedBrand = brand;
    this.SelectedLocation = brand.Locations[0];
    this.localStorageService.setSelectedBrand(this.SelectedBrand);
    this.localStorageService.setSelectedLocation(this.SelectedLocation);
    window.location.reload();
  }

  public changeLocation(location) {
    this.SelectedLocation = location;
    this.localStorageService.setSelectedLocation(this.SelectedLocation);
    window.location.reload();
  }

  public toggleMobileMenu() {
    this.toggled = !this.toggled;
  }

  public logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }
  openReceipt() {

    const modalRef = this.modalService.open(ReceiptComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
   
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
}
