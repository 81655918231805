import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { MarketplaceService } from 'src/app/_services/marketplace.service';
import { Msegat } from 'src/app/_models/marketplace';
import { paymentLinkService } from 'src/app/_services/paymentLink.service';


@Component({
  selector: 'app-payment-link-providers',
  templateUrl: './payment-link-providers.component.html',
  styleUrls: ['./payment-link-providers.component.css']
})
export class PaymentLinkProvidersComponent implements OnInit {
  @Input() PartnerID = 0;
  textSamplePayLink = "Use {{Customer}} and {{Receipt}} to add Customer's name and other info dynamically if existed. fees may vary if you're using dynamic keywording.";

  ButtonText = "Save";
  submitted = false;
  loading = false;
  Data: any;
  DefaultPaymentIntegrationID = 0;
  DefaultSMSIntegrationID = 0;
  DefaultPaymentMsg = "Hello {{Customer}}, A Payment link is waiting for you! {{Receipt}}";

  constructor(public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private _service: paymentLinkService,

  ) {


  }

  ngOnInit() {
    this.setSelected();
  }


  smsProvider: any;
  setSelected() {

    this.loading = true;
    this._service.getPaymentLinkProviders(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      //Set Forms
      this.Data = res;

      console.log(this.Data);

      if (res == null) {
        this.alertService.error("Payment link and SMS Provider not integrated please integrate first.");
      } else if (res.sms.length == 0) {
        this.alertService.error("SMS Provider not integrated please integrate first.");
      } else if (res.paylinks.length == 0) {
        this.alertService.error("Payment link not integrated please integrate first.");
      }


      this.DefaultPaymentIntegrationID = (res.paylinks.filter(data => data.isDefault == true).length > 0) ? res.paylinks.filter(data => data.isDefault == true)[0].integrationID : 0;
      this.DefaultSMSIntegrationID = (res.sms.filter(data => data.isDefault == true).length > 0) ? res.sms.filter(data => data.isDefault == true)[0].integrationID : 0;
      this.DefaultPaymentMsg = (res.sms[0].paymentLinkMgs === '' || res.sms[0].paymentLinkMgs === null) ? this.DefaultPaymentMsg : res.sms[0].paymentLinkMgs;
      this.loading = false;

      console.log(res);


    });

  }


  ngAfterViewInit() {

  }


  onSubmit() {

    this.submitted = true;
    this.alertService.clear();
    const data = {
      DefaultPaymentIntegrationId: this.DefaultPaymentIntegrationID,
      DefaultSMSIntegrationId: this.DefaultSMSIntegrationID,
      DefaultPaymentMsg: (this.DefaultSMSIntegrationID > 0) ? this.DefaultPaymentMsg : '',
      BrandID: parseInt(this.localStorageService.getSelectedBrand().BrandID)

    };


    this.loading = true;
    //Insert
    this._service.setPaymentLinkProvidersDefault(data).subscribe(data => {

      console.log(data)
      this.alertService.success("Payment Link Providers has saved successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });

  }


  close() {
    this.activeModal.dismiss();
  }






}
