import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Role, RoleRightDto } from '../../_models/Role';
import { RoleService } from '../../_services/role.service';
import { AlertService } from '../../_services/alert.service';
import { IndividualroleComponent } from '../individualrole/individualrole.component';
import { Observable } from 'rxjs';
import { UserRolesComponent } from '../user-roles.component';

@Component({
  selector: 'app-newrole',
  templateUrl: './newrole.component.html',
  styleUrls: ['./newrole.component.css']
})
export class NewroleComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() pageType = '';
  @Input() SelectedName = '';
  ButtonText = "Save";
  roleForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  accessLists: RoleRightDto[];
  formData = new Role();
  userRole: UserRolesComponent;
  colorOptions: string[];
  //rights: object;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private roleService: RoleService,
    private alertService: AlertService) {

    this.createForm();
  }

  ngOnInit() {
    this.setSelectedRole();
    this.colorOptions = ["blue", "orange", "red", "green", "grey", "light-green"];
  }



  get f() { return this.roleForm.controls; }
  private createForm() {

    this.roleForm = this.formBuilder.group({
      roleName: ['', Validators.required],
      fullAccess: [false, Validators.required],
      roleID: 0,
      cssClass: ['', Validators.required],
      type: ['App', Validators.required],
      permissions: null,
      users: null,
      rowVersion: 0,
      statusID: 1,
      rights: []
    });
  }
  close() {
    this.activeModal.dismiss();
  }

  setSelectedRole() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loading = true;

      this.roleService.get(this.SelectedID).subscribe(res => {
        //Set
        console.log(res)
        this.editForm(res);
        this.loading = false;
      });
    }
    else {

      this.roleService.getRightsForNewRole(this.f.type.value).subscribe(res => {
        //Set Forms
        this.accessLists = res;
        this.setRights(res);
        this.f.rights.setValue(res);
      });

    }
  }
  onTypeChange() {
    this.loading = true;
    this.roleService.getRightsForNewRole(this.f.type.value).subscribe(res => {
      //Set Forms
      this.accessLists = res;
      this.setRights(res);
      this.f.rights.setValue(res);
      this.loading = false;
    });
  }
  private editForm(role) {
    console.log(role);
    this.f.roleName.setValue(role.roleName);
    this.f.type.setValue(role.type);
    this.f.fullAccess.setValue(role.fullAccess);
    this.f.roleID.setValue(role.roleID);
    this.f.rights.setValue(role.rights);
    this.f.cssClass.setValue(role.cssClass);
    this.f.rowVersion.setValue(role.rowVersion);
    this.accessLists = role.rights;
  }
  private setRights(rights) {
    this.formData.rights = rights;
  }

  public colorSelection(color: string) {
    this.f.cssClass.setValue(color);
  }

  onSubmit() {
    this.roleForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.roleForm.invalid) { return; }
    this.loading = true;

    this.f.rights.setValue(this.accessLists);
    console.log(this.roleForm.value);

    if (this.f.roleID.value === 0) {
      this.roleService.insert(this.roleForm.value).subscribe(data => {
        this.alertService.success("Role has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
    else {
      this.roleService.update(this.roleForm.value).subscribe(data => {
        this.alertService.success("Role has been updated");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }
  individualRole(right) {
    const modalRef = this.modalService.open(IndividualroleComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.componentInstance.SelectedRight = right;
    modalRef.result.then((receivedEntry) => {
      if (receivedEntry) {
        console.log(receivedEntry);
        let indexToUpdate = this.accessLists.findIndex(item => item.id === receivedEntry.id);
        this.accessLists[indexToUpdate] = receivedEntry;
        this.accessLists = Object.assign([], this.accessLists);
      }
    });
  }
}
