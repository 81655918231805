
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { Preps } from 'src/app/_models/Preps';
import { PrepsService } from 'src/app/_services/preps.service';
import { AddprepsComponent } from './addpreps/addpreps.component';
import { TrackEventsService } from '../../_services/track-events.service';

@Component({
  selector: 'app-preps',
  templateUrl: './preps.component.html',
  styleUrls: ['./preps.component.css'],
  providers: [ExcelService]
})
export class PrepsComponent implements OnInit {
  data$: Observable<Preps[]>;
  oldData: Preps[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "preps";
  excellData: any = [];
  submit: boolean;
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: PrepsService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private trackEvents: TrackEventsService,
    private alertService: AlertService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  getBrandData() {
    this.service.headerFilter = {status:['']};
    this.service.getIngredients(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddprepsComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Prep");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });

  }

  Edit(preps) {
    debugger
    const modalRef = this.modalService.open(AddprepsComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Prep");
    modalRef.componentInstance.SelectedID = preps.ingredientID;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Preps has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Preps has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(id, statusID, rowVersion) {

    this.service.SetStatus(parseInt(id), parseInt(statusID), rowVersion).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("Preps has been Actived");
      else
        this.alertService.success("Preps has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }


  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-SubCategory', type);
  }
  private getExportData() {
    let selected: Preps[];

    selected = [];
    this.service.preps.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.preps : selected;
    return selected;
  }
  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}

