<form [formGroup]="taxsetupForm" class="form taxesForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'Manage Taxes' | translate }} </h4>


    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Setup','Taxes', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText  | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4" style="margin-top: 40px;">
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Tax Name">
                {{'Item Price' | translate }}
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4494_9613)">
                    <path opacity="0.6" d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5ZM8.25 8.25V12.75H9.75V8.25H8.25ZM8.25 5.25V6.75H9.75V5.25H8.25Z" fill="#96A4B8" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4494_9613">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </label>
              <!--<input type="text" class="form-control" id="includedItemPrice" placeholder="" formControlName="includedItemPrice">-->

              <select class="custom-select form-control" formControlName="includedItemPrice">
                <option [ngValue]="option.value" selected="option.value === includedItemPrice" *ngFor="let option of itemsettings">
                  {{option.name | translate}}
                </option>
              </select>

              <div *ngIf="f.includedItemPrice.invalid && (f.includedItemPrice.dirty || f.includedItemPrice.touched)" class="alert alert-danger">
                <div *ngIf="f.includedItemPrice.errors.required">
                  {{'Item Price' | translate }} .
                </div>
              </div>
            </div>
          </div>
          <div class="row status-activebar m-0 px-2 py-2" *ngIf="f.includedItemPrice.value === '1'">
            <div class="col-1">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div class="col-11 pb-2">
              <label class="align-middle" style="text-transform:none">
                {{'This option will not be available on taxes applied on items like, Tobacco Taxes' | translate }}
              </label>
            </div>
            </div>
          </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel cutom-button" style="color: unset; width: unset;min-width: unset;" (click)="activeModal.close('Close click')">{{'Cancel' | translate }}</button>
    <button [disabled]="loading" style="float: right; " class="btn btn-primary cutom-button">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> {{ButtonText | translate }}
    </button>
  </div>
</form>
