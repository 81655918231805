<footer class="login-footer card-footer">
  <div class="row justify-content-center">
    <div class="col-lg-auto col-md-auto col-sm-12 hide-mobile">
      <a href="https://marn.com/"> <img src="/assets/images/marn-text.svg" width=60 /> </a>
    </div>
    <div class="col-lg-auto col-md-auto col-sm-12 hide-mobile">© 2020</div>
    <div class="col-lg-auto col-md-auto col-sm-12"><a href="https://marn.com/about.html">About</a></div>
    <div class="col-lg-auto col-md-auto col-sm-12"><a href="https://marn.com/contact.html">Help Center</a></div>
    <div class="col-lg-auto col-md-auto col-sm-12 hide-mobile"><a href="https://marn.com/terms.html">Terms</a></div>
    <div class="col-lg-auto col-md-auto col-sm-12 hide-mobile"><a href="">Privacy Policy</a></div>
    <div class="col-lg-auto col-md-auto col-sm-12 hide-mobile"><a href="">Blogs</a></div>
    <div class="col-lg-auto col-md-auto col-sm-12 hide-mobile"><a href="">Copyright Policy</a></div>
  </div>
</footer>
