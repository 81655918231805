import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as $ from 'jquery';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { PartnerSummary } from '../_models/GeneratedReport';
import { State } from '../_models/State';

interface PartnerSummaryReportResult {
  data: PartnerSummary[];
  total: number;
}
export const contentHeaders = new Headers();
const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: PartnerSummary[], column: SortColumn, direction: string): PartnerSummary[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = `${a[column]}`.toString().localeCompare(`${b[column]}`.toString(), undefined, { numeric: true });
      //const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: PartnerSummary, term: string) {
  return data.BusinessDate.toLowerCase().includes(term.toLowerCase()) ||
    data.Day.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Aggregator.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Card.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Cash.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Complimentary.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Discount.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.GrandTotal.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Wallet.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Void.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Tax.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Refund.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.OrderCount.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Hold.toString().toLowerCase().includes(term.toLowerCase())
}

@Injectable({
  providedIn: 'root'
})
export class PartnerSummaryService {

  sumOrderAverage: number = 0
  sumCard: number = 0
  sumCash: number = 0
  sumAggregator: number = 0
  sumDiscount: number = 0
  sumComplimentary: number = 0
  sumVoid: number = 0
  sumHold: number = 0
  sumRefund: number = 0
  sumSales: number = 0
  sumGrandTotal: number = 0
  sumTax: number = 0

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<PartnerSummary[]>([]);
  private _data$ = new BehaviorSubject<PartnerSummary[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public report: any;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }
  constructor(private http: HttpClient) {
  }

  public getSummaryReport(dataUrl) {


    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataUrl,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {
          _this.report = res;
          _this.sumOrderAverage = 0;
          _this.sumCard = 0;
          _this.sumCash = 0;
          _this.sumAggregator = 0;
          _this.sumDiscount = 0;
          _this.sumComplimentary = 0;
          _this.sumVoid = 0;
          _this.sumHold = 0;
          _this.sumRefund = 0;
          _this.sumSales = 0;
          _this.sumGrandTotal = 0;
          _this.sumTax = 0;

          _this.report.forEach(item => {
            _this.sumValues('sumOrderAverage', item.OrderAverage)
            _this.sumValues('sumCard', item.Card)
            _this.sumValues('sumCash', item.Cash)
            _this.sumValues('sumAggregator', item.Aggregator)
            _this.sumValues('sumDiscount', item.Discount)
            _this.sumValues('sumComplimentary', item.Complimentary)
            _this.sumValues('sumVoid', item.Void)
            _this.sumValues('sumHold', item.Hold)
            _this.sumValues('sumRefund', item.Refund)
            _this.sumValues('sumSales', item.Sales)
            _this.sumValues('sumGrandTotal', item.GrandTotal)
            _this.sumValues('sumTax', item.Tax)
          });
          _this._data$.next(_this.report);
          _this._allData$.next(_this.report);

          _this.report.forEach(e => {

            e.Sales = e.GrandTotal - e.Tax
          });

          _this._search$.pipe(
            switchMap(() => _this._search()),
            tap(() => _this._loading$.next(false))
          ).subscribe(result => {
            _this._data$.next(result.data);
            _this._allData$.next(result.data);
            _this._total$.next(result.total);
          });
          _this._search$.next();
        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })

  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<PartnerSummaryReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData;
    return of({ data, total });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
  sumValues(text: string, value: number) {
    if (value) {
      switch (text) {
        case 'sumOrderAverage':
          this.sumOrderAverage += value;
          break;
        case 'sumCard':
          this.sumCard += value;
          break;
        case 'sumCash':
          this.sumCash += value;
          break;
        case 'sumAggregator':
          this.sumAggregator += value;
          break;
        case 'sumDiscount':
          this.sumDiscount += value;
          break;
        case 'sumComplimentary':
          this.sumComplimentary += value;
          break;
        case 'sumVoid':
          this.sumVoid += value;
          break;
        case 'sumHold':
          this.sumHold += value;
          break;
        case 'sumRefund':
          this.sumRefund += value;
          break;
        case 'sumSales':
          this.sumSales += value;
          break;
        case 'sumGrandTotal':
          this.sumGrandTotal += value;
          break;
        case 'sumTax':
          this.sumTax += value;
          break;
      }
    }
  }
}
