import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class VOMService {

  constructor(private http: HttpClient) {
  }

 
  getVOM(brandId: number, partnerId: number) {
    return this.http.get(`api/vom/brand/${brandId}/partner/${partnerId}`);
  }



  insert(data) {
    return this.http.post(`api/VOM/`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/VOM`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  Uninstall(id) {
    return this.http.patch(`api/VOM/uninstall/${id}`, null);
 
   }

  
}
