import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { ExpenseDto } from '../../_models/Expense';
import { AlertService } from '../../_services/alert.service';
import { ExcelService } from '../../_services/excel.service';
import { ExpenseService } from '../../_services/expense.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { CreateExpenseComponent } from './create-expense/create-expense.component';
import * as myGlobals from '../../_helpers/globals';
import { LocationService } from '../../_services/location.service';
import { NgbdDatepickerRangePopup } from '../../daterangepicker/datepicker-range-popup';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../_services/permission.service';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrl: './expense.component.css'
})
export class ExpenseComponent {
  data$: Observable<ExpenseDto[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  submit: boolean;
  excelData = [];
  LocationList = [];
  StatusList = [];
  brandCurrency: string = '';
  public selectedLocationName = 'All Locations';
  checkAllLocations: boolean = false;
  Locations: any;
  public selectedLocation;
  trial: any = null;
  applyFilters: boolean = true;
  @ViewChild(NgbdDatepickerRangePopup) dateComp: { fromDate: any; toDate: any; };
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: ExpenseService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private location: LocationService,
    private translate: TranslateService,
    private confirmationDialogService: ActionConfirmationService,
    private permissionService: PermissionService  ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.loading$ = service.loading$;
    this.submit = false;
    this.brandCurrency = myGlobals.brandCurrency(this.localStorageService);
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.checkTrial();
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Expenses', type);
  }
  checkTrial() {
    this.trial = this.permissionService.checkTrial(['Analytics', 'Accounting', 'Expense']);
    if (this.trial.Access) {
      this.applyFilters = false;
      this.dateComp.fromDate = null;
      this.dateComp.toDate = null;
      this.loadLocations();
      this.getBrandData();
    }
  }
  private loadLocations() {
    this.location.getAllLocationsddl(this.selectedBrand).subscribe((res: any) => {
      this.Locations = res;
    });
  }
  setPeriod() {
    if (this.dateComp.fromDate && this.dateComp.toDate) {
      this.applyFilters = true;
      this.getBrandData();
    }
  }
  getBrandData() {
    let startdate = null;
    let enddate = null;
    let location = (this.applyFilters === false) ? null : ((this.selectedLocation === '' || this.selectedLocation === undefined || this.selectedLocation === null) ? null : this.selectedLocation);
    if (this.dateComp.fromDate && this.dateComp.toDate) {
      startdate = (this.applyFilters === false) ? null : this.dateComp.fromDate.year + "-" + ('0' + this.dateComp.fromDate.month).slice(-2) + "-" + ('0' + this.dateComp.fromDate.day).slice(-2);
      enddate = (this.applyFilters === false) ? null : this.dateComp.toDate.year + "-" + ('0' + this.dateComp.toDate.month).slice(-2) + "-" + ('0' + this.dateComp.toDate.day).slice(-2);
    }

    this.service.headerFilter = { locationName: null,status:null };
    this.service.getExpense(startdate, enddate, location, this.selectedBrand);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.LocationList = [];
      if (res != null && res.length > 0) {
        let list = [];
        res.forEach(s => {
          if (s.locations) {
            let arr = s.locations.split(',');
            list = [...list, ...arr];
          }
          else {
            list = list.includes('(Empty)') ? list : [...list, '(Empty)'];
          }
        });
        new Set(list.map(val => val)).forEach(e => {
          this.LocationList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  create() {
    const modalRef = this.modalService.open(CreateExpenseComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Expense");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Preview(expense) {
    const modalRef = this.modalService.open(CreateExpenseComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Expense");
    modalRef.componentInstance.SelectedID = expense.expenseID;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  Print(expense) {
  }
  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, expense) {
    this.service.expense.forEach((item, index) => {
      if (item.expenseID == expense.expenseID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }

  private getExportData() {
    let selected: any[];
    selected = [];
    this.service.expense.forEach(d => {
      if (d.selected) {
        selected.push(this.getDataForExport(d));
      }
    });
    if (selected.length == 0) {
      this.service.expense.forEach(e => {
        selected.push(this.getDataForExport(e));
      });
    }
    return selected;
  }
  getDataForExport(item) {
    let temp = {
      "ExpenseID": item.code,
      "ExpenseName": item.name,
      "RefNo": item.referenceNo,
      "Total": item.grandTotal,
      "Locations": item.locations,
      "MaturityDate": item.maturityDate,
      "GenerationDate": item.generationDate
    };
    return temp;
  }
  SelectedLocation = '';
  SelectedStatus = '';
  filters() {
    if (this.LocationList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      locationName: this.LocationList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })
    }
    this.service.headerFilter = headerFilter;
  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }

  setSelectedResult(val) {
    if (val == 'location') {
      return this.LocationList.filter(k => k.isChecked == true).length;
    }

  }
  public setLocation(val) {
    this.applyFilters = true;
    if (val === null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';
      this.getBrandData();
    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;
      this.getBrandData();
    }
  }
  public openConfirmationDialog(expense) {
    let text = { a: 'Are you sure you want to void the expense', b:'Once you add the void reason and confirm the void action the amount of the expense will not be calculated in Income statement or VAT Declaration'}
    let body = '<p>' + this.translate.instant(text.a) + ' <b>' + expense.code + '</b>' + this.translate.instant('?')+'</p>' + this.translate.instant(text.b) +' <p></p>';
    this.confirmationDialogService.confirmationDialogue('Void Expense', body,'Void',true)
      .then((confirmed) => {
        if (confirmed.status) {
          this.service.void(parseInt(this.selectedBrand), parseInt(expense.expenseID), expense.rowVersion, confirmed.text).subscribe((res: any) => {
            this.alertService.success("Expense has been Voided");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed.status)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
}
