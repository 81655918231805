<main class="invoice">
  <div class="page">
    <div class="row">
      <div class="col-md-12" style="padding-right:1px">
        <img [src]="selectedBrand?.BrandLogo" style="width:50px" />
      </div>
      <div class="col-md-12 text-right" style="padding-left:1px; font-size:20px">
        {{selectedBrand.BrandName}}
      </div>
    </div>
    <div class="page-body">
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="row text-center" style="background-color: #ebedee !important">
          <div class="col-lg-12">
            {{inventoryPurchase}}
          </div>
          <div class="col-lg-12">
            {{selectedReport.dateRange}}
          </div>
          <div class="col-lg-12">
            Generated By: {{selectedReport.generatedBy}}
          </div>
          <div class="col-md-12">
            {{selectedReport.reportName}}
          </div>
          <div class="col-md-12">
            Generated On: {{selectedReport.generatedOn | date:'MM dd, yyyy'}}
          </div>
        </div>
        <div class="tile-body p-0">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <!--<th>{{'Item ID' | translate }} </th>-->
                <th>{{'Item Name (EN)' | translate }} </th>
                <th>{{'Item Name (AR)' | translate }} </th>
                <th>{{'Menu Price' | translate }} </th>
                <th>{{'Sales Price (avg.)' | translate }} </th>
                <th>{{'Quantity Sold' | translate }} </th>
                <th>{{'Total Sales' | translate }} </th>
                <th>{{'Item Cost' | translate }} </th>
                <th>{{'Total Item Cost' | translate }} </th>
                <th>{{'Product Margin %' | translate }} </th>
                <th>{{'Total Profit' | translate }} </th>

              </tr>
            </thead>
            <tbody *ngFor="let item of report; let i = index">
              <tr>
                <!--<td>{{item.ItemID}}</td>-->
                <td> {{item.Name }} </td>
                <td> {{item.AlternateName }} </td>
                <td> {{item.Price | number:'1.2-2'}} </td>
                <td>  {{item.SalePrice | number:'1.2-2' }} </td>
                <td> {{item.Quantity | number:'1.2-2' }} </td>
                <td>  {{item.TotalSales | number:'1.2-2' }} </td>
                <td> {{item.ItemCost | number:'1.2-2' }} </td>
                <td> {{item.TotalItemCost | number:'1.2-2' }} </td>
                <td> {{item.Margin | number:'1.2-2' }} </td>
                <td>  {{item.Profit | number:'1.2-2' }} </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
          <div class="col-lg-12" style="margin-top:1%">
            Powered By marn.com
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
