<main class="views container">
  <div class="page">


    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Voided Cart Items' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">

            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">

              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>




    <div class="page-body">

      <div class="preview-filter-data" dir="ltr">{{selectedReport.dateRange}}</div>
      <div class="preview-filter-data" dir="ltr">{{selectedReport.locations}}</div>

      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive hrzScroll salesOrderDetail">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <th width="10%" sortable="OrderNo" (sort)="onSort($event)">{{'Order' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
                <th width="15%" sortable="BusinessDate" (sort)="onSort($event)">{{'Date' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
                <th width="20%" sortable="Location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
                <th width="15%" sortable="Details" (sort)="onSort($event)">{{'Voided Items' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
                <th width="15%" sortable="TotalAmount" (sort)="onSort($event)">{{'Voided Net amount' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
                <th width="10%" sortable="TaxAmount" (sort)="onSort($event)">{{'Voided Tax' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
                <th width="10%" sortable="GrandTotal" (sort)="onSort($event)">{{'Total Voided' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async ">
                <td>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                </td>
                <td>  {{item.OrderNo}} </td>
                <td> {{item.BusinessDate | date}} </td>
                <td> {{item.Location}} </td>
                <!--<td> {{ (item.Details.length > 1)?item.Details.length + " items":(item.Details.length == 0)?"-": item.Details[0].Name }}</td>-->
                <td>
                  <ng-template #orderDetail>
                    <div class="hidden myPopover">
                      <div class="popover-heading"> <span style="float:right;cursor:pointer;" class="fa fa-times"></span></div>
                      <div class="popover-body">
                        <table style="width:100%">
                          <thead>
                            <tr>
                              <th>{{'Item' | translate }}</th>
                              <th>{{'QTY' | translate }}</th>
                              <th>{{'Price' | translate }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let order of item.Details">
                              <td>
                                <!--{{order.Name}}-->
                                {{selctedLang=='en'?order.Name:order.AltName}}
                              </td>
                              <td>{{order.Quantity}}</td>
                              <td>{{order.PriceWithVAT}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                  <a [placement]="['left', 'right']" [ngbPopover]="item.Details.length > 0 ? orderDetail : ''">
                    <!--{{item.VoidItems}}-->
                    {{selctedLang=='en'?item.VoidItems:item.VoidArabicItems}}
                  </a>
                </td>
                <td> {{item.TotalAmount}} </td>
                <td> {{item.TaxAmount}} </td>
                <td> {{item.GrandTotal}} </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{(total$ | async)}}</strong>
            {{'results' | translate }}
          </div>
        </div>
      </section>

    </div>
  </div>
</main>
