<div class="mnt-header position-relative order-list product-list">



  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-30" *ngIf="(service.recipes?.length > 0)">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Recipes' | translate }} </h5>
    </div>
    <div class="col-md-auto" *hasPermission="['Inventory','Inventory Setup','Recipes', 'Insert']">
      <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
        {{'+ Add Recipes' | translate }}
      </button>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Inventory','Inventory Setup','Recipes', 'Export']">

          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>


  <div *ngIf="(service.recipes?.length > 0)">
    <div class="position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped  text-start">
            <thead>
              <tr class="table-header">
                <th width="35%" sortable="name" (sort)="onSort($event)">{{'Recipe Name' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="20%" sortable="itemCount" (sort)="onSort($event)">{{'Used in Items' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="20%" sortable="modifierCount" (sort)="onSort($event)">{{'Used in Modifiers' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="20%" sortable="autoPrepared" (sort)="onSort($event)">{{'Auto Prepared' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let recipe of data$ | async " editRow>
                <td (click)="Edit(recipe)" class="name-blue"> {{recipe.name}} </td>
                <td (click)="Edit(recipe)"> {{recipe.itemCount}} </td>
                <td (click)="Edit(recipe)"> {{recipe.modifierCount}} </td>
                <td (click)="Edit(recipe)" class="text-capitalize">


                  <svg width="10" height="10" viewBox="0 0 10 10" style=" margin: 0 10px;" *ngIf="recipe.autoPrepared == true" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="#40A773" />
                  </svg>

                  <svg width="10" height="10" viewBox="0 0 10 10" style=" margin: 0 10px;" *ngIf="recipe.autoPrepared == false" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="#DF0017" />
                  </svg>

                  {{(recipe.autoPrepared ? 'True' :'False') | translate}}
                </td>
                <td>
                  <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                    <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                  </div>
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'RECIPE' | translate }}</p>
                    <a *hasPermission="['Inventory','Inventory Setup','Recipes', 'Edit']" (click)="Edit(recipe)">{{'Edit' | translate }}</a>
                    <a *hasPermission="['Inventory','Inventory Setup','Recipes', 'Delete']" class="selected-inner-text color-danger" (click)="openConfirmationDialog(recipe.recipeID, recipe.rowVersion,recipe.name)">
                      {{'Delete' | translate }}
                      <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                    </a>

                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      </div>


      <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            {{'Showing' | translate }}
            <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
            <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>

    </div>


    <div *ngIf="(service.recipes?.length == 0) && ((data$ | async)?.length == 0)">
      <div class="blank-order">
        <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
        <strong>{{'No Recipe yet' | translate }}</strong>
        <p class="text-center width-375">{{'Create New Recipe.' | translate}}</p>
      </div>
      <div class="items-flex">
        <div class="create-btn mlmr-10">
          <div>
            <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
              {{'+ Create New Recipe' | translate }}
            </button>
          </div>
        </div>


      </div>
    </div>

    <div *ngIf="(service.recipes?.length > 0) && ((data$ | async)?.length == 0)">
      <div class="blank-order">
        <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
        <strong>{{'No Record Found' | translate }}</strong>
        <p class="text-center width-375">{{'Create recipe for item.' | translate}}</p>
      </div>
    </div>


  </div>
