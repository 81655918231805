import { Directive, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appPasswordVisibility]'
})
export class PasswordVisibilityDirective {

  private _shown = false;
  constructor(private el: ElementRef,
    private translate: TranslateService  ) {
    this.setup();
  }
  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerText = this.translate.instant('hide');
      //span.innerHTML = `<ngb-highlight result={{'hide' | translate}} ></ngb-highlight>`;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerText = this.translate.instant('show');
      //span.innerHTML = `<ngb-highlight result={{'show' | translate}}></ngb-highlight>`;
    }
  }
  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('ngb-highlight');
    //span.innerHTML = `<ngb-highlight result={{'show' | translate}}></ngb-highlight>`;
    span.className = "btn btn-sm btn-badge-grey float-end rounded-pill me-2 ms-2";
    span.style.marginTop = '-33px';
    span.innerText = this.translate.instant('show');
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

}
