<form class="form-inline w-100">

  <div class="form-group hidden">
    <div class="input-group">
      <input name="datepicker" class="opacity-0 position-absolute" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event,datepicker)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1"
             [minDate]="minDate"  
             [maxDate]="{year: 2048, month: 12, day: 31}" >
      <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <div class="input-group-append btn-calender">
        <button class="btn btn-outline-secondary input-group-text"  (click)="datepicker.toggle()" type="button"><img src="/assets/images/calendar.svg" alt="Calender"></button>
      </div>
      <input #dpFromDate (click)="datepicker.toggle()" class="form-control dpFromDate" readonly="readonly" autocomplete="off" placeholder="yyyy-mm-dd" name="dpFromDate" [value]="formatter.format(fromDate)" (keyup)="changeEv()" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">

      <input #dpToDate (click)="datepicker.toggle()" class="form-control dpToDate" readonly="readonly" autocomplete="off" placeholder="yyyy-mm-dd" name="dpToDate" [value]="formatter.format(toDate)" (keyup)="changeEvTodate();" (input)="toDate = validateInput(toDate, dpToDate.value)">

    </div>
  </div>
</form>
