


import { Component, OnInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { IntegrationService } from 'src/app/_services/Integration.service';
import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';
import { Router } from '@angular/router';
import * as myGlobals from '../../../_helpers/globals';


@Component({
  selector: 'app-deliverect',
  templateUrl: './deliverect.component.html',
  styleUrls: ['./deliverect.component.css']
})
export class DeliverectComponent implements OnInit {

  @Input() PartnerID = 0;
  @Input() Partner: any;
  ButtonText = "Install";
  submitted = false;
  partnerForm: UntypedFormGroup;
  private selectedBrand;
  loading = false;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private services: IntegrationService,
    private _modalService: NgbModal,
    private router: Router
  ) {
    this.createForm();

  }

  ngOnInit() {
    this.setSelectedPartner();
  }

  Rules: any;
  GetPartnerClaim() {
    this.services.GetPartnerClaim(this.PartnerID).subscribe((res: any) => {
      this.Rules = res;

    });
  }
  GetIntegrationClaim(inegrationid) {
    this.services.GetIntegrationClaim(inegrationid).subscribe((res: any) => {
      this.Rules = res;

    });
  }


  GenerateBrandKey() {
    this.services.GenerateBrandKey().subscribe((res: any) => {
      this.f.brandKey.setValue(res.key);
    });
  }
  setSelectedPartner() {

    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getIntegration(this.f.brandID.value, this.PartnerID).subscribe((res: any) => {

        console.log(res);
        //Set Forms
        console.log(res);
        if (res !== null) {
          this.GetIntegrationClaim(res.integrationID);
          this.editForm(res);

          this.ButtonText = "Save";
        } else {
          this.GenerateBrandKey();
          this.GetPartnerClaim();
        }
        this.loading = false;
      });
    }
  }



  private createForm() {

    this.partnerForm = this.formBuilder.group({

      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      brandKey: ['', Validators.required],
      statusID: [true, Validators.required],
      integrationID: 0,
      rowID: 0,
      enabled: 0,
      partnerID: this.PartnerID

    });
  }



  private editForm(value) {

    this.f.rowID.setValue(value.rowID);
    this.f.integrationID.setValue(value.integrationID);
    this.f.brandKey.setValue(value.brandKey);
    this.f.enabled.setValue(value.enabled);
  }



  get f() { return this.partnerForm.controls; }

  ngAfterViewInit() {

  }


  onSubmit() {
    debugger
    this.partnerForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.partnerID.setValue(this.PartnerID);

    if (this.partnerForm.invalid) { return; }
    console.log(this.partnerForm.value)
    this.loading = true;

    const partner = {
      rowID: this.f.rowID.value,
      integrationID: this.f.integrationID.value,
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      partnerID: this.PartnerID,
      brandKey: this.f.brandKey.value,
      enabled: true,
      approved: true,
      statusID: 1,
    }

    if (parseInt(this.f.integrationID.value) === 0) {

      //   //Insert
      console.log(JSON.stringify(partner));
      this.services.insert(partner).subscribe(data => {
        this.alertService.success("Integration partner has installed successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //   //Update
      this.services.update(partner).subscribe(data => {
        this.alertService.success("Integration partner has saved successfully");
        this.loading = false;
        this.PartnerID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        //this.activeModal.dismiss();
      });
    }
  }


  close() {
    this.activeModal.dismiss();
  }

  uninstall() {
    this.loading = true;
    this.services.Uninstall(parseInt(this.f.integrationID.value)).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Integration partner has uninstalled successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }


  //copy() {
  //  this.copyToClipboard(this.f.brandKey.value);
  //  this.alertService.success("Brand key copied");
  //}


  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true, windowClass: 'uninstall-modal', modalDialogClass: 'uninstall-modal-custom' });

    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
      }
    });

  }


  //copyToClipboard(key) {
  //  if (navigator.clipboard) {
  //    navigator.clipboard.writeText(key).then(() => {
  //    }, (error) => {
  //      console.log(error)
  //    });
  //  } else {
  //    console.log('Browser do not support Clipboard API');
  //    this.alertService.error("Browser do not support Clipboard API");
  //  }

  //}


  //get industryURLName() {
  //  return myGlobals.industryName(this.localStorageService);
  //}
  //golink() {
  //  // restaurant/branddevices
  //  this.activeModal.dismiss();


  //  setTimeout(() => {
  //    this.router.navigate([`/${this.industryURLName}/branddevices`]);
  //  }, 500);
  //}

}
