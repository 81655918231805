'use strict';

import { LocalStorageService } from "../_services/local-storage.service";

export const industryName = (localStorageService: LocalStorageService) => {
  if (localStorageService.getSelectedUser() !== undefined && localStorageService.getSelectedUser() !== null) {
    let industry: number = localStorageService.getSelectedUser().User.Industry;
    let industryName: string = (industry === 1) ? 'restaurant' : (industry === 2) ? 'retail' : '';
    return industryName;
  }
};
export const industryIsRestaurant = (localStorageService: LocalStorageService) => {
  if (localStorageService.getSelectedUser() !== undefined && localStorageService.getSelectedUser() !== null) {
    let industry: number = localStorageService.getSelectedUser().User.Industry;
    let industryIsRestaurant: boolean = (industry === 1) ? true : false;
    return industryIsRestaurant;
  }
};
export const brandLanguage = (localStorageService: LocalStorageService) => {
  if (localStorageService.getSelectedBrand() !== undefined && localStorageService.getSelectedBrand() !== null) {
    let brandLanguage: string = localStorageService.getSelectedBrand().Language;
    return brandLanguage;
  }
};
export const brandCurrency = (localStorageService: LocalStorageService) => {
  if (localStorageService.getSelectedBrand() !== undefined && localStorageService.getSelectedBrand() !== null) {
    let brandCurrency: string = localStorageService.getSelectedBrand().Currency;
    return (brandCurrency !== null && brandCurrency !== undefined && brandCurrency !== '') ? brandCurrency :'';
  }
};
export class global {
 public static internetConnection: boolean = true;
}




