import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../_services/alert.service';
import { ExcelService } from '../../_services/excel.service';
import * as XLSX from 'xlsx';
import { LocalStorageService } from '../../_services/local-storage.service';
import { ItemService } from '../../_services/item.service';
@Component({
  selector: 'app-items-uploader',
  templateUrl: './items-uploader.component.html',
  styleUrls: ['./items-uploader.component.css']
})
export class ItemsUploaderComponent implements OnInit {

  SaveButtonText = "Continue";
  showFileUpload = true;
  showFileOptions = false;
  showError = false;
  loading = false;
  showTemplate = true;
  itemsUploadForm: UntypedFormGroup;
  FileOptionList: Array<any> = [
    { value: "Name", name: 'Name' },
    { value: "ArabicName", name: 'Arabic Name' },
    { value: "Barcode", name: 'Barcode' },
    { value: "Category", name: 'Category' },
    { value: "SubCategory", name: 'Sub Category' },
    { value: "Orders", name: 'Orders' },
    { value: "Price", name: 'Price' },
    { value: "PriceType", name: 'Price Type' },
    { value: "Unit", name: 'Unit' },
    { value: "GroupModifier", name: 'Group Modifier' },
  ];
  errorList: any[];
  fileData: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private itemService: ItemService) {
    this.createForm();
  }
  get f() { return this.itemsUploadForm.controls; }

  private createForm() {
    this.itemsUploadForm = this.formBuilder.group({
      itemName: [Validators.required],
      arabicName: [Validators.required],
      barcode: [Validators.required],
      category: [Validators.required],
      subcategory: [Validators.required],
      order: [Validators.required],
      price: [Validators.required],
      unit: [Validators.required],
      priceType: [Validators.required],
      groupModifier: [Validators.required]
    });
  }
  ngOnInit() {
  }
  close() {
    this.activeModal.dismiss();
  }

  async onFileChange(ev) {
    let file = ev.target.files[0];
    //this.fileData = await this.excelService.ReadExcelFile(file);

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const _file = file;
    if (ev.target.files[0].size <= 5000000) {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          let format = this.validateFileHeaders(initial);
          if (format === true) {
            this.fileData = initial;
            this.setKeys(initial);
          }
        }, {});
      }
      reader.readAsBinaryString(_file);
    }
    else {
      this.alertService.error("File size is larger than 5 MB.");
    }
  }
  ValidateSheet(sheet) {
    let sheetFormat = true;
    sheet.filter(function (item) {
      //if (item.Name === null || item.Name === undefined || item.Name === '')
      //  sheetFormat = false;
      //if (item.ArabicName === null || item.ArabicName === undefined || item.ArabicName === '')
      //  sheetFormat = false;
      //if (item.Barcode === null || item.Barcode === undefined || item.Barcode === '')
      //  sheetFormat = false;
      //if (item.Category === null || item.Category === undefined || item.Category === '')
      //  sheetFormat = false;
      //if (item.SubCategory === null || item.SubCategory === undefined || item.SubCategory === '')
      //  sheetFormat = false;
      //if (item.Price === null || item.Price === undefined || item.Price === '')
      //  sheetFormat = false;
      //if (item.Unit === null || item.Unit === undefined || item.Unit === '')
      //  sheetFormat = false;
      //if (item.Orders === null || item.Orders === undefined || item.Orders === '')
      //  sheetFormat = false;
      if (Object.keys(item).length < 8)
        sheetFormat = false;
      if (item[Object.keys(item)[0]] === null || item[Object.keys(item)[0]] === undefined || item[Object.keys(item)[0]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[1]] === null || item[Object.keys(item)[1]] === undefined || item[Object.keys(item)[1]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[2]] === null || item[Object.keys(item)[2]] === undefined || item[Object.keys(item)[2]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[3]] === null || item[Object.keys(item)[3]] === undefined || item[Object.keys(item)[3]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[4]] === null || item[Object.keys(item)[4]] === undefined || item[Object.keys(item)[4]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[5]] === null || item[Object.keys(item)[5]] === undefined || item[Object.keys(item)[5]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[6]] === null || item[Object.keys(item)[6]] === undefined || item[Object.keys(item)[6]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[7]] === null || item[Object.keys(item)[7]] === undefined || item[Object.keys(item)[7]] === '')
        sheetFormat = false;

    });
    return sheetFormat;
  }
  setKeys(obj) {
    if (obj.length > 0) {
      let keys = Object.keys(obj[0]);
     //this.FileOptionList = null; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
      let fileOption = [];
      keys.filter(function (item) {
        let data = { value: item, name: item };
        fileOption.push(data);
      });
      //this.FileOptionList = fileOption; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
    }
  }
  onSubmit() {
    if (this.SaveButtonText === "Continue") {
      if (this.fileData !== null && this.fileData !== undefined) {
        if (this.ValidateSheet(this.fileData)) {
          this.showFileUpload = false;
          this.showTemplate = false;
          this.showFileOptions = true;
          this.SaveButtonText = "Upload";
        }
        else
          this.alertService.error("Attached sheet is not valid.");
      }
      else {
        this.showFileUpload = true;
        this.showTemplate = true;
        this.showFileOptions = false;
        this.alertService.error("File not found or invalid file.");
      }
    }
    else {

      let itemName = this.f.itemName.value === this.f.arabicName.value || this.f.itemName.value === this.f.barcode.value || this.f.itemName.value === this.f.category.value || this.f.itemName.value === this.f.subcategory.value || this.f.itemName.value === this.f.order.value || this.f.itemName.value === this.f.price.value || this.f.itemName.value === this.f.unit.value || this.f.itemName.value === this.f.priceType.value || this.f.itemName.value === this.f.groupModifier.value;
      let arabicName = this.f.arabicName.value === this.f.itemName.value || this.f.arabicName.value === this.f.barcode.value || this.f.arabicName.value === this.f.category.value || this.f.arabicName.value === this.f.subcategory.value || this.f.arabicName.value === this.f.order.value || this.f.arabicName.value === this.f.price.value || this.f.arabicName.value === this.f.unit.value || this.f.arabicName.value === this.f.priceType.value || this.f.arabicName.value === this.f.groupModifier.value;
      let barcode = this.f.barcode.value === this.f.arabicName.value || this.f.barcode.value === this.f.itemName.value || this.f.barcode.value === this.f.category.value || this.f.barcode.value === this.f.subcategory.value || this.f.barcode.value === this.f.order.value || this.f.barcode.value === this.f.price.value || this.f.barcode.value === this.f.unit.value || this.f.arabicName.value === this.f.priceType.value || this.f.barcode.value === this.f.groupModifier.value;
      let category = this.f.category.value === this.f.arabicName.value || this.f.category.value === this.f.barcode.value || this.f.category.value === this.f.itemName.value || this.f.category.value === this.f.subcategory.value || this.f.category.value === this.f.order.value || this.f.category.value === this.f.price.value || this.f.category.value === this.f.unit.value || this.f.arabicName.value === this.f.priceType.value || this.f.category.value === this.f.groupModifier.value;
      let subcategory = this.f.subcategory.value === this.f.arabicName.value || this.f.subcategory.value === this.f.barcode.value || this.f.subcategory.value === this.f.category.value || this.f.subcategory.value === this.f.itemName.value || this.f.subcategory.value === this.f.order.value || this.f.subcategory.value === this.f.price.value || this.f.subcategory.value === this.f.unit.value || this.f.arabicName.value === this.f.priceType.value || this.f.subcategory.value === this.f.groupModifier.value;
      let order = this.f.order.value === this.f.arabicName.value || this.f.order.value === this.f.barcode.value || this.f.order.value === this.f.category.value || this.f.order.value === this.f.subcategory.value || this.f.order.value === this.f.itemName.value || this.f.order.value === this.f.price.value || this.f.order.value === this.f.unit.value || this.f.arabicName.value === this.f.priceType.value || this.f.order.value === this.f.groupModifier.value;
      let price = this.f.price.value === this.f.arabicName.value || this.f.price.value === this.f.barcode.value || this.f.price.value === this.f.category.value || this.f.price.value === this.f.subcategory.value || this.f.price.value === this.f.order.value || this.f.price.value === this.f.itemName.value || this.f.price.value === this.f.unit.value || this.f.arabicName.value === this.f.priceType.value || this.f.price.value === this.f.groupModifier.value;
      let unit = this.f.unit.value === this.f.arabicName.value || this.f.unit.value === this.f.barcode.value || this.f.unit.value === this.f.category.value || this.f.unit.value === this.f.subcategory.value || this.f.unit.value === this.f.order.value || this.f.unit.value === this.f.price.value || this.f.unit.value === this.f.itemName.value || this.f.arabicName.value === this.f.priceType.value || this.f.unit.value === this.f.groupModifier.value;
      let priceType = this.f.priceType.value === this.f.arabicName.value || this.f.priceType.value === this.f.barcode.value || this.f.priceType.value === this.f.category.value || this.f.priceType.value === this.f.subcategory.value || this.f.priceType.value === this.f.order.value || this.f.priceType.value === this.f.price.value || this.f.priceType.value === this.f.unit.value || this.f.priceType.value === this.f.itemName.value || this.f.priceType.value === this.f.groupModifier.value;

      if (itemName || arabicName || barcode || category || subcategory || order || price || unit) {
        this.itemsUploadForm.setErrors({ 'invalid': true });
      }

      this.itemsUploadForm.markAllAsTouched();
      this.alertService.clear();
      if (this.itemsUploadForm.invalid) {
        return;
      }
      this.loading = true;
      let uploadData = [];
      let brandID = this.localStorageService.getSelectedBrand().BrandID;
      let fc = this.f;
      let funcKey = this.getKey;
      this.fileData.filter(function (item) {
        var data =
        {

          Name: item[funcKey(item, fc.itemName.value)].toString().trim(),  
          NameOnReceipt: item[funcKey(item, fc.arabicName.value)].toString().trim(),   
          SubCategoryName: item[funcKey(item, fc.subcategory.value)].toString().trim(),
          CategoryName: item[funcKey(item, fc.category.value)].toString().trim(),   
          Barcode: item[funcKey(item, fc.barcode.value)].toString().trim(),   
          Price: item[funcKey(item, fc.price.value)],
          DisplayOrder: item[funcKey(item, fc.order.value)],
          Unit: item[funcKey(item, fc.unit.value)].toString().trim(),   
          SheetItemPriceType: item[funcKey(item, fc.priceType.value)],
          SheetGroupModifier: item[funcKey(item, fc.groupModifier.value)],
          BrandID: brandID
        };
        uploadData.push(data);
        return true;
      });
      this.itemService.uploadItems(uploadData).subscribe(data => {
        if (data != null) {
          if (data.result.status === 1) {
            this.alertService.success("Items have been saved");
            this.activeModal.dismiss();
          } else {
            this.showFileUpload = false;
            this.showFileOptions = false;
            this.showError = true;
            this.errorList = data.messages;
          }
        }
        this.loading = false;
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }
  download() {
    var data =
      [
        { Name: '', ArabicName: '', Barcode: '', Category: '', SubCategory: '', Orders: '', Price: '', PriceType: '', Unit: '', GroupModifier: '' }
      ];
    this.excelService.exportAsExcelFile(data, 'ItemsTemplate', 'xlsx');
  }
  getKey(item, formValue) {
    let key = '';
    Object.keys(item).forEach((val) => {
      if (formValue === val)
        key = val;
    });
    return key;
  }
  validateFileHeaders(sheet: any) {
    let sheetFormat = true;
    if (this.getKey(sheet[0], 'Name') !== 'Name')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'ArabicName') !== 'ArabicName')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Barcode') !== 'Barcode')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Category') !== 'Category')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'SubCategory') !== 'SubCategory')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Orders') !== 'Orders')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Price') !== 'Price')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'PriceType') !== 'PriceType')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'Unit') !== 'Unit')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'GroupModifier') !== 'GroupModifier')
      sheetFormat = false;

    return sheetFormat;

  }
}
