
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';


@Component({
  selector: 'app-inventorysetup',
  templateUrl: './inventorysetup.component.html',
  providers: []
})
export class InventorysetupComponent implements OnInit {

  active = "supplier";

  constructor() {
  }

  ngOnInit() {
  }
}

