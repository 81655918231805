<main class="views container order-list">
  <div class="page-header tabheader">
    <span>
      {{'Accounting' | translate }}
    </span>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="summary" id="summary" *hasPermission="['Analytics','Accounting','Expense']" (click)="active='summary';">
            <a ngbNavLink> {{'Summary' | translate }} </a>
            <ng-template ngbNavContent>
              <app-account-summary [LogEventType]="'screen'" [LogEvent]="'Lite Account Summary'"></app-account-summary>
            </ng-template>
          </li>
          <li ngbNavItem="expenses" id="expense" *hasPermission="['Analytics','Accounting','Summary']" (click)="active='expenses';">
            <a ngbNavLink> {{'Expenses' | translate }} </a>
            <ng-template ngbNavContent>
              <app-expense [LogEventType]="'screen'" [LogEvent]="'Expenses'"></app-expense>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
