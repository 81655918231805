import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { AddtaxsetupComponent } from '../taxsetup/addtaxsetup/addtaxsetup.component';

@Component({
  selector: 'app-tax-reference',
  templateUrl: './tax-reference.component.html',
  styleUrls: ['./tax-reference.component.css']
})
export class TaxReferenceComponent implements OnInit {

  taxRef = 0;
  taxRefList = [
    {
      taxRef: 1,
      img: 'assets/images/Zatca.svg',
      name: 'Saudi VAT',
      value: '15%',
      applicableOn: 'On Locations',
      isCustom: false
    },
    {
      taxRef: 2,
      img: 'assets/images/Balady.svg',
      name: 'Tobacco Tax',
      value: '100%',
      applicableOn: 'On Items',
      isCustom: false
    },
    {
      taxRef: 3,
      img: null,
      name: 'Custom Tax',
      value: null,
      applicableOn: null,
      isCustom: true
    },
  ]

  constructor(public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private alertService: AlertService,) { }

  ngOnInit(): void {
  }


  selectTemplate() {
    if (this.taxRef === 0) {
      this.alertService.error("Please select template");
      return;
    }
    var obj = null;
    if (this.taxRef === 1) {
      obj = {
        name: 'Saudi VAT',
        arabicName: 'ضريبة القيمة المضافة',
        percentage: 15,
        applicableOn: 'Locations',
        calculationBy: 'Invoice',
        minTax: 0,
        taxRef: 1
      };
    }
    else if (this.taxRef === 2) {
      obj = {
        name: 'Tobacco Tax',
        arabicName: 'ضريبة التبغ',
        percentage: 100,
        applicableOn: 'Items',
        calculationBy: 'SubTotal',
        minTax: 25,
        taxRef: 2
      };
    }

    const modalRef = this.modalService.open(AddtaxsetupComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.componentInstance.SelectedTaxReference = obj;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.activeModal.dismiss();
    });

  }
}
