<main class="mnt-header position-relative order-list product-list">

  <div class="row flex-row-reverse mb-3 custom-action-header margin-top-minus-30 item-sales align-items-center">

    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Item Sales' | translate }} </h5>
    </div>
    <div class="col-md-auto">
      <div class="p-0">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Analytics','Summary','Item Sales Tab', 'Filter']">
          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" [disabled]="loading" ngbDropdownToggle>
            <span *ngIf="isGeneratingPDF" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv',data$)">CSV</button>
            <button ngbDropdownItem (click)="openPDF()">PDF</button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-auto">
      <div class="p-0">

        <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />

      </div>
    </div>
    <div class="col-md-auto">
      <div class="p-0" *hasPermission="['Analytics','Summary','Item Sales Tab', 'Filter']">
        <div ngbDropdown>
          <a ngbDropdownToggle class="form-control text-center selected-location">
            <span>{{selectedLocationName | translate}}</span>
            <img class="down-aero" src="assets/images/down-aero.svg">
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropaero-menu">
            <a class="dropdown-item" [hidden]="selectedLocationName == 'All Locations' " (click)="setLocation(null)">{{'All Locations' | translate }} </a>
            <a class="dropdown-item" *ngFor="let location of Locations" [hidden]="location.name == selectedLocationName"
               (click)="setLocation(location)">{{location.name}} </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto" *hasPermission="['Analytics','Summary','Item Sales Tab', 'Filter']">
      <ngbd-datepicker-range (OnHit)="getData()"></ngbd-datepicker-range>
    </div>
  </div>
  <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
    <div class="tile-body p-0 table-responsive hrzScroll itemSalesModifiers text-nowrap">
      <table class="table table-striped table-custom popover-detail">
        <thead>
          <tr class="table-header">
            <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
            <th sortable="name" (sort)="onSort($event)">{{'Item Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th sortable="altName" (sort)="onSort($event)">{{'Alternate Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th>
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                <button class="btn btn-outline-filter" ngbDropdownToggle>
                  <span>{{'Sub Category' | translate}}</span>
                  <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                     class="dropdown-header-filter-body">
                  <div *ngFor="let parent of subCategoryList">
                    <span>{{parent?.name}}</span>
                    <div *ngFor="let subCat of parent?.childs">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" id="{{'subCat_'}}{{subCat.subCategoryID}}"
                               [(ngModel)]="subCat.isChecked" (ngModelChange)="filterList(subCat)">
                        <label class="form-check-label" (click)="filterList()" for="{{'subCat_'}}{{subCat.subCategoryID}}">
                          {{subCat.name}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>


            <th sortable="barcode" (sort)="onSort($event)">{{'Barcode' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
            <th sortable="itemCount" (sort)="onSort($event)">{{'Quantity' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
            <th sortable="sales" (sort)="onSort($event)">{{'Sales' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th sortable="salesWithTax" (sort)="onSort($event)">{{'Sale With Tax' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data$ | async ">
            <td class="text-center">
              <input type="checkbox" class="form-check-input"  [(ngModel)]="item.selected" />
            </td>
            <td>
              <ng-template #ItemModifiers>
                <div class="hidden myPopover">
                  <div class="popover-heading"> {{item.name}} <span class="fa fa-times"></span></div>
                  <div class="popover-body">
                    <table class="table table-striped table-custom mw-100">
                      <thead>
                        <tr>
                          <th>{{'Modifier Item' | translate }}</th>
                          <th>{{'QTY' | translate }}</th>
                          <th>{{'Sales' | translate }}</th>
                          <th>{{'Sales With Tax' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let mod of item?.modifiers">
                          <td> <strong>{{selectedLang=='en'?mod.name:mod.altName }}</strong></td>
                          <td>{{mod.itemCount}}</td>
                          <td>{{mod.sales | number:'1.2-2'}}</td>
                          <td>{{mod.salesWithTax | number:'1.2-2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-template>
              <ng-container *ngIf="item?.modifiers.length >0; then itemMods; else items">
              </ng-container>
              <ng-template #itemMods>
                <div [placement]="['right', 'left']" [ngbPopover]="ItemModifiers" >
                  <strong role="button" placement="bottom" ngbTooltip="View Details">
                    {{item.name}}
                  </strong>
                </div>
              </ng-template>
              <ng-template #items>
                <span>{{item.name}}</span>
              </ng-template>

            </td>
            <td>{{item.altName }}</td>
            <td>{{selectedLang=='en'?item.subCategoryName:item.altSubCategoryName }}</td>
            <td>{{item.barcode }}</td>
            <td> {{item.itemCount }} </td>
            <td> {{item.sales | number:'1.2-2'}} </td>
            <td> {{item.salesWithTax | number:'1.2-2'}} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
      <div class="col-lg-12" style="margin-top:1%">
        {{'Showing' | translate }}
        <strong>{{(total$ | async)}}</strong>
        {{'results' | translate }}
      </div>
    </div>
  </section>
  <app-item-sales-pdf *ngIf="exportPDF === true" [selectedLocation]="selectedLocation" [startdate]="startdate" [enddate]="enddate" [selectedLocationName]="selectedLocationName" [itemSalesData]="service.report" (html)="receive($event)" ></app-item-sales-pdf>



</main>





