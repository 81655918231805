import { Component, OnInit } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-time-period-picker',
  templateUrl: './time-period-picker.component.html',
  styleUrls: ['./time-period-picker.component.css']
})
export class TimePeriodPickerComponent implements OnInit {
  fromTime: NgbTimeStruct = {
    hour: 12,
    minute: 0,
    second: 0
  };
  toTime: NgbTimeStruct = {
    hour: 23,
    minute: 59,
    second: 59
  };
  inValid: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
  }
  validateTime() {
    this.inValid = this.fromTime.hour > this.toTime.hour ||
      (this.fromTime.hour === this.toTime.hour && this.fromTime.minute > this.toTime.minute) ||
      (this.fromTime.hour === this.toTime.hour && this.fromTime.minute === this.toTime.minute && this.fromTime.second > this.toTime.second) ||
      this.toTime.hour < this.fromTime.hour ||
      (this.toTime.hour === this.fromTime.hour && this.toTime.minute < this.fromTime.minute) ||
      (this.toTime.hour === this.fromTime.hour && this.toTime.minute === this.fromTime.minute && this.toTime.second < this.fromTime.second);
  }
}
