export class InventoryCount {
  icid: number;
  icCode: string;
  locationID: number;
  locationName: string;
  notes: string;
  totalItems: number;
  totalLosses: number;
  totalMiscounted: number;
  totalDifference: number;
  brandID: number;
  userID: number;
  createdOn: string;
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  statusID: number;
  approvedBy: string;
  approvedDate: string;
  rejectedBy: string;
  rejectedDate: string;
  selected: boolean;
  stockCountDetails: InventoryCountDetails[];
}

export class InventoryCountDetails {
  id: number;
  icid: number;
  supplyID: number;
  type: string;
  name: string;
  alternateName: string;
  currentStockQTY: number;
  quantity: number;
  difference: number;
  avgCost: number;
  cost: number;
  itemImage: string;
  totalCost: number;
  statusID: number;
  unit: string;
}
export class InventoryCountLog {
  id: number;
  icid: number;
  activityBy: string;
  activity: string;
  dateStamp: string;
}
