import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-location-stats',
  templateUrl: './location-stats.component.html',
  styleUrls: ['./location-stats.component.css']
})
export class LocationStatsComponent implements OnInit {
  dtModel: string = this.setToday();
  public selectedDate = new Date();
  constructor(private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>) {
    let dateString = this.ngbCalendar.getToday().year + "-" + ('0' + this.ngbCalendar.getToday().month).slice(-2) + "-" + ('0' + this.ngbCalendar.getToday().day).slice(-2);
    this.selectedDate = new Date(dateString);
  }

  ngOnInit(): void {
  }
  setToday() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  public setDate(date) {
    let dateString = date.year + "-" + ('0' + date.month).slice(-2) + "-" + ('0' + date.day).slice(-2);
    this.selectedDate = new Date(dateString);

  }

}
