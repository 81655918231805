<main class="views container order-list product-list">

  <div class="page-header-ex tabheader">
    <!-- <span> {{(active=="category"?"Categories":"Sub-Categories") | translate }}</span> -->
    <span> {{'Categorisation' | translate }}</span>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="category" id="category">
            <a ngbNavLink> {{'Categories' | translate }}  </a>
            <ng-template ngbNavContent >
              <app-category [LogEventType]="'screen'" [LogEvent]="'Category'"></app-category>
            </ng-template>
          </li>
          <li ngbNavItem="sub-category" id="sub-category">
            <a ngbNavLink>{{'Sub Categories' | translate }} </a>
            <ng-template ngbNavContent>
              <app-subcategory [LogEventType]="'screen'" [LogEvent]="'Sub Category'"></app-subcategory>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>

  </div>


  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
