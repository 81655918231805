<div class="row">
  <div class="col-lg-12 col-md-6 col-sm-12">
    <section class="tile">
      <div class="d-flex row g-2 px-3 pt-1">
        <div class="col-sm-7 col-xl-7 col-lg-12 col-md-12 hide-mobile h6line-height">  <h6>{{'Location Inventory' | translate}}</h6></div>
        <div class="col-sm-2 col-xl-2 col-lg-12 col-md-12">
          <div class="row g-2">
            <div class="col-8 col-sm-6 col-xl-6 col-lg-6 col-md-6">
              <div class="filter-field" *hasPermission="['Locations','Inventory', 'Export']">
                <button class="profile btn export-btn btn-lg form-control h-100" (click)="import()">
                  <img src="../../../../../assets/images/icons/v2/export.svg" class=" mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
                </button>
              </div>
            </div>
            <div class="col-4 col-sm-6 col-xl-6 col-lg-6 col-md-6">
              <div class="filter-field" *hasPermission="['Locations','Inventory', 'Export']">
                <div ngbDropdown class="nav-item" placement="bottom">
                  <button class="profile btn export-btn btn-lg form-control h-100" id="dropdownBasic1" ngbDropdownToggle>
                    <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                    <p class="excel-list" (click)="export('csv')">CSV</p>
                    <p class="excel-list" (click)="export('xlsx')">Excel</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          </div>
          <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
            <div class="filter-field">
              <div class="position-relative">
                <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
              </div>
            </div>
          </div>
        </div>

        <div class="tile-body table-responsive text-nowrap">
          <!-- <div class="form-group form-inline" style="float: right; margin-top: 10px;  margin-right: 10px;">
      <input class="form-control ml-2" placeholder="Search" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div> -->
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <th width="20%" sortable="name" (sort)="onSort($event)">{{'NAME' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="type" (sort)="onSort($event)">{{'TYPE' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="sku" (sort)="onSort($event)">{{'SKU' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="costPerUnit" (sort)="onSort($event)">{{'COST/UNIT' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="barcode" (sort)="onSort($event)">{{'Barcode' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="minStock" (sort)="onSort($event)">{{'MIN STOCK' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="currentStock" (sort)="onSort($event)">{{'CURRENT' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async ">
                <td width="5%">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                </td>
                <td width="20%">
                  <ngb-highlight [result]="selctedLang=='en'?item.name:item.alternateName !== ''?item.alternateName:item.name" [term]="service.name"></ngb-highlight>
                </td>
                <td width="10%">
                  <ngb-highlight [result]="item.type | translate" [term]="service.type"></ngb-highlight>
                </td>
                <td width="15%">
                  <ngb-highlight [result]="item.sku" [term]="service.sku"></ngb-highlight>
                </td>
                <td width="10%">
                  <ngb-highlight [result]="item.cost" [term]="service.cost"></ngb-highlight>
                </td>
                <td width="10%">
                  <ngb-highlight [result]="item.barcode" [term]="service.barcode"></ngb-highlight>
                </td>
                <td width="15%">
                  <input type="number" (keyup)="priceChange(item, $event)" pattern="/^\d*\.?\d{0,2}$/g" class="form-control-price" style="float:left" placeholder="{{item.minStockLevel}}" [(ngModel)]="item.minStockLevel" *ngIf="minStock === true" />
                  <span class="inv-unit">{{item.unit}}</span>
                </td>
                <td width="15%">
                  <input type="number" *ngIf="item.currentStock==null" pattern="/^\d*\.?\d{0,2}$/g" class="form-control-price float-none" placeholder="{{item.currentStockUpdated}}" [(ngModel)]="item.currentStockUpdated" />
                  <ngb-highlight *ngIf="item.currentStock!=null" [ngClass]="item.csscolor" class="btn btn-sm float-left" [result]="item.currentStockUpdated" [term]="service.searchTerm"></ngb-highlight>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
</section>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 pt-1">
        <button [disabled]="submit" class="btn btn-save" (click)="saveItems()" *hasPermission="['Locations','Inventory', 'Save']">
          <span *ngIf="submit" class="spinner-border spinner-border-sm mr-1"></span>
          {{'Save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
