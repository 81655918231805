import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { LocalStorageService } from '../../../../_services/local-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseReturnService } from '../../../../_services/purchasereturn.service';
import { AlertService } from '../../../../_services/alert.service';
import { PurchaseorderService } from '../../../../_services/purchaseorder.service';
import { PurchaseReturnDetails } from '../../../../_models/purchase-return';
import * as myGlobals from '../../../../_helpers/globals';
@Component({
  selector: 'app-preview-poreturn',
  templateUrl: './preview-poreturn.component.html',
  styleUrls: ['./preview-poreturn.component.css']
})
export class PreviewPOReturnComponent implements OnInit {
  @Input() SelectedID = 0;
  previewpurchaseReturnForm: UntypedFormGroup;
  ButtonText = "Save";
  loading= false;
  arrItems: any[] = [];
  poData: any;
  constructor(private formBuilder: UntypedFormBuilder,
    public ls: LocalStorageService,
    public activeModal: NgbActiveModal,
    public service: PurchaseReturnService,
    private alertService: AlertService,
    private purchaseorderService: PurchaseorderService) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedPurchaseReturn();
  }

  get f() { return this.previewpurchaseReturnForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }
  private createForm() {

    this.previewpurchaseReturnForm = this.formBuilder.group({
      poCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      poid: 0,
      supplierID: 0,
      supplierName: [''],
      supplierContact: [''],
      supplierAddress: [''],
      subTotal: 0,
      taxAmount: 0,
      grandTotal: 0,
      expectedDate: [],
      returnDate: [],
      date:[],
      locationID: 0,
      locationName: [''],
      locationContact: [''],
      locationAddress: [''],
      rowVersion: 0,
      poDetails: [],
      taxes: []
    });
  }
  close() {
    this.activeModal.dismiss();
  }
  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"
    }
  }
  loadLocation(id) {
    this.purchaseorderService.getLocationsById(id).subscribe((res: any) => {
      this.f.locationID.setValue(res.locationID);
      this.f.locationName.setValue(res.name);
      this.f.locationAddress.setValue(res.address);
      this.f.locationContact.setValue(res.contactNo);
    });
  }
  setSelectedPurchaseReturn() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loading = true;
      this.f.poid.setValue(this.SelectedID);
      this.service.getReturnPO(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.poData = res;
        this.loading = false;
      });
    }
  }
  loadSupplier(id) {
    this.purchaseorderService.getSupplierById(id).subscribe((res: any) => {
      this.f.supplierID.setValue(res.supplierID);
      this.f.supplierName.setValue(res.name);
      this.f.supplierAddress.setValue(res.email);
      this.f.supplierContact.setValue(res.contactNo);
    });
  }
  private editForm(purchaseReturnOrder) {
    debugger;
    this.f.poCode.setValue(purchaseReturnOrder.poCode);
    this.f.returnDate.setValue(purchaseReturnOrder.date);
    this.f.date.setValue(purchaseReturnOrder.date);
    this.f.expectedDate.setValue(purchaseReturnOrder.expectedDate);
    this.f.referenceNo.setValue(purchaseReturnOrder.referenceNo);
    this.f.poDetails.setValue(purchaseReturnOrder.poDetails);
    this.arrItems = purchaseReturnOrder.poDetails;

    this.f.subTotal.setValue(purchaseReturnOrder.subTotal);
    this.f.taxAmount.setValue(purchaseReturnOrder.taxAmount);
    this.f.grandTotal.setValue(purchaseReturnOrder.grandTotal);

    this.f.notes.setValue(purchaseReturnOrder.notes);
    this.f.rowVersion.setValue(purchaseReturnOrder.rowVersion);
    this.f.statusID.setValue(purchaseReturnOrder.returnStatusID);
    this.f.poid.setValue(purchaseReturnOrder.poid);

    this.loadLocation(purchaseReturnOrder.locationID);
    this.loadSupplier(purchaseReturnOrder.supplierID);

    //if (this.f.statusID.value == 605 || this.f.statusID.value == 601 || this.f.statusID.value == 606) {
    //  this.isShowActions = false;
    //}
  }
  selectedStatus(status) {

    if (status == "approve") {
      this.f.statusID.setValue(601);
    }
    else if (status == "decline") {
      this.f.statusID.setValue(603);
    }
  }
  onSubmit() {
    this.setStatus();
  }


  setStatus() {
    this.service.update(this.previewpurchaseReturnForm.value).subscribe((res: any) => {
      this.alertService.success("Purchase return has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }

}
