import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private http: HttpClient) { }


  getIntegration(brandId: number, partnerId: number) {
    return this.http.get(`api/integration/brand/${brandId}/partner/${partnerId}`);
  }

  insert(data) {
    return this.http.post(`api/integration`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  update(updateData) {
    return this.http.put(`api/integration`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  Uninstall(id) {
    return this.http.patch(`api/integration/uninstall/${id}`, null);

  }

  GenerateBrandKey() {
    return this.http.get(`api/integration/generate/brandkey`);

  }

  GetPartnerClaim(partnerId) {
    return this.http.get(`api/integration/partner/claim/${partnerId}`);

  }

  GetIntegrationClaim(integrationId) {
    console.log(`api/integration/integration/claim/${integrationId}`);
    return this.http.get(`api/integration/integration/claim/${integrationId}`);

  }

  SaveGrubTech(data) {
    return this.http.post(`api/GRUBTECH/V1/grubtech/activateIntegration`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  GetGrubTechLocations(integrationId, partnerId, brandId) {
    return this.http.get(`api/integration/GetGrubTechIntegratedLocations/${integrationId}/${partnerId}/${brandId}`);

  }

}
