<form [formGroup]="receivepoForm" class="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'Purchase Order' | translate }} {{f.poCode.value}}</h4>
        <button [disabled]="loading" class="btn btn-primary btn-sm cutom-button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate }}
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-6 mt-5">
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="name">{{'Reference No' | translate }}</label>
                            <input type="text" class="form-control" id=name placeholder="" formControlName="referenceNo">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="email">{{'Order Date' | translate }}</label>
                            <ngbd-datepicker-adapter #date></ngbd-datepicker-adapter>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="email">{{'Receive Date' | translate }}</label>
                            <ngbd-datepicker-adapter #receivedate></ngbd-datepicker-adapter>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="Notes">{{'Notes' | translate }}</label>
                            <textarea class="form-control mh-80" formControlName="notes"></textarea>
                        </div>
                    </div>
                    <div class="po-box form-group">
                        <table class="table m-0">
                            <thead class="thead-light border-top-radius-unset">
                                <tr class="table-header">
                                  <th scope="col" width="50%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                                  <th scope="col" width="25%">{{'Ordered' | translate }}</th>
                                  <th scope="col" width="25%">{{'Qty Received' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom-radius-unset">
                                <tr *ngFor="let item of arrItems">
                                    <td scope="row" class="font-weight-bold">{{item.name}}</td>
                                    <td>{{item.quantity + item.unit}}</td>
                                    <td>
                                        <input type="number" [(ngModel)]="item.receivedQty" [ngModelOptions]="{standalone: true}" class="form-control"
                                          [ngStyle]="{'border-color': (item.receivedQty > item.quantity  || item.receivedQty === null || item.receivedQty === '' || item.receivedQty === undefined || item.receivedQty <= 0) ? 'red' : null }"
                                                (ngModelChange)="UpdateQty(item)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-3"></div>
            </div>
        </div>
    </div>
</form>
