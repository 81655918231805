export const environment = {
  production: true,
  baseUrl: 'http://marn-portal.azurewebsites.net/',
  accountName: "marnpossastorage",
  containerName: "marnpos-v3-uat",
  key: "yFQz/m+/TEo2nvH5iiu3v242SKYkIsplpOl3yw+CTe7csUZas+5L0aw3dRYIiBX6wXuJa+CQkVFGftXYVxdjfQ==",
  recaptcha: {
    siteKey: '6LcLniEfAAAAAA-6rK39ysz0EV6EQ_L8PN-Y6OwH',
  },
  googleMap: {
    apiKey: 'AIzaSyAliUxdJ3SLC8nb8wM_NIVXbVW-4zkD_lg',
  },
  digitalReceipt: {
    //baseURL: 'https://rc.marn.com/'
    baseURL: 'https://rc-uat.marn.com/'
  },
  intercom: {
    appId: 'usn2ht3p',
  },
  firebaseConfig : {
    apiKey: "AIzaSyDMfWKZf4LlsLKmk6_x9Af91Z61o-gJtqU",
    authDomain: "marn-dashboard-app.firebaseapp.com",
    projectId: "marn-dashboard-app",
    storageBucket: "marn-dashboard-app.appspot.com",
    messagingSenderId: "699288820458",
    appId: "1:699288820458:web:e895e30f0ad62c7b8e5593",
    measurementId: "G-LGW3H05VNG"
  },
  ZATCA_Config: {
    URL: 'https://e-invoicing-uat.marn.com/'
    //URL: 'https://marn-e-invoicing-staging.azurewebsites.net/'
    //URL: 'https://marn-e-invoicing.azurewebsites.net/'
  },
  GTM_ID: 'GTM-M6PNB3QL'
};
