<form [formGroup]="partnerForm" class="form msegat">
  <div class="modal-header">

    <div class="d-flex" style="width: 100%;">

      <div class="d-flex align-items-start justify-content-start flex-1">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <div class="d-flex align-items-center justify-content-center flex-1">
        <h4 class="modal-title">
          {{Partner?.name | translate}}
        </h4>
      </div>


      <div class="d-flex align-items-end justify-content-end flex-1">
        <a class="btn-default btn-grey" href="{{Partner?.url}}" target="_blank">
          {{'Learn More' | translate}}
        </a>
        <button [disabled]="loading" class="btn-primary btn-save" [hidden]="f.integrationID.value >0" (click)="onSubmit()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate}}
        </button>
      </div>

    </div>
  </div>
  <div class="modal-body integration">
    <div class="container">
      <div class="row">

        <div class="col-lg-12" style="margin-top: 40px;">




          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <div class="logo-placeholder">
                <div class="card-img-top" [ngStyle]="{'background-image':'url(' + Partner?.logo + ')'}" style="background-position: center;
                    background-size: cover;
                    height: 166px;
                    background-repeat: no-repeat;
                    width: 100%;"> </div>
              </div>
            </div>
          </div>
          <div class="line"></div>


          <h6 class="heading"> {{'Setup' | translate }}:</h6>


          <p class="description"> To Setup Soft POS you need to:</p>
          <p class="description ms-0 p-0 m-0"> 1. Install Marn Softpos   </p>
          <p class="description ms-0 p-0 m-0"> 2. in Devices page, Add Nearpay’s Terminal ID.</p>

          <a class="device-link" (click)="golink()">Visit Devices page</a>

        



          <!--<h6 class="heading"> {{'Permissions' | translate }}:</h6>

  <div class="row">
    <div class="form-group col-lg-12 col-sm-12">
      <ul class="rules">
        <li *ngFor="let r of Rules">{{r.claimType}}</li>
      </ul>
    </div>
  </div>



  <div class="line"></div>

  <h6 class="heading"> {{'Install' | translate }}</h6>

  <div class="row">
    <div class="form-group col-lg-12 col-sm-12">
      <label for="brandkeys"> {{'Brandkey' | translate }}</label>
      <input type="text" class="form-control" [disabled] id="brandKey" placeholder="" formControlName="brandKey">
      <span class="copy-brandkey float-right" (click)="copy()"><img src="assets/images/copy.svg"></span>

      <div *ngIf="f.brandKey.invalid && (f.brandKey.dirty || f.brandKey.touched)" class="alert alert-danger">
        <div *ngIf="f.brandKey.errors.required">
          {{'BrandKey is required' | translate }}.
        </div>
      </div>
    </div>
  </div>-->




          <ng-container *hasPermission="['Marketplace','Uninstall']">
            <div class="line" *ngIf="f.integrationID.value >0" style="margin-top: 30px;"></div>

            <h6 class="heading" *ngIf="f.integrationID.value >0"> {{'Danger zone' | translate }}</h6>

            <div class="row" style="margin-top: 30px;" *ngIf="f.integrationID.value >0">
              <div class="col-6">
                <label> {{'Uninstall this App' | translate }} </label>
              </div>
              <div class="col-6 text-right">
                <button [disabled]="loading" class="btn btn-danger btn-uninstall" (click)="openConformation()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'Uninstall' | translate }}
                </button>
              </div>

            </div>
          </ng-container>
        </div>

      </div>
    </div>
  </div>
</form>
