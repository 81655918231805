<div #chartpaymenttype class="chart-direction">
    <apx-chart
      [series]="chartOptions?.series"
      [chart]="chartOptions?.chart"
      [dataLabels]="chartOptions?.dataLabels"
      [stroke]="chartOptions?.stroke"
      [colors]="chartOptions?.colors"
      [title]="chartOptions?.title"
      [subtitle]="chartOptions?.subtitle"
      [plotOptions]="chartOptions?.plotOptions"
      [yaxis]="chartOptions?.yaxis"
      [xaxis]="chartOptions?.xaxis"
      [tooltip]="chartOptions?.tooltip"
      [grid]="chartOptions?.grid" 
      [fill]="chartOptions?.fill" 
      [legend]="chartOptions?.legend"
    ></apx-chart>
  </div>
