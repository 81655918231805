import { Component, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BlobServiceClient, AnonymousCredential, newPipeline } from '@azure/storage-blob';
import { UntypedFormBuilder } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-imageupload',
  templateUrl: './imageupload.component.html'
})
export class ImageuploadComponent {

  imagepath: any = "";//"https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG";
  editFile: boolean = true;
  removeUpload: boolean = false;
  title = 'web1';
  currentFile: File = null;
  pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  localImagePath: string = '';
  breakCache=Math.floor((Math.random() * 100) + 1);
  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.localImagePath = reader.result as string;
        this.currentFile = event.target.files[0];

      };

    }

    //this.upload();
  }

  constructor(
    public fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef

  ) { }
  registrationForm = this.fb.group({
    file: [null]
  })
   async upload(imagePath: string | null) {
     var promise = new Promise((resolve, reject) => {
       if (this.currentFile) {
         // generate account sas token
         const accountName = environment.accountName;
         const key = environment.key;
         const start = new Date(new Date().getTime() - (15 * 60 * 1000));
         const end = new Date(new Date().getTime() + (30 * 60 * 1000));
         const signedpermissions = 'rwdlac';
         const signedservice = 'b';
         const signedresourcetype = 'sco';
         const signedexpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
         const signedProtocol = 'https';
         const signedversion = '2018-03-28';

         const StringToSign =
           accountName + '\n' +
           signedpermissions + '\n' +
           signedservice + '\n' +
           signedresourcetype + '\n' +
           '\n' +
           signedexpiry + '\n' +
           '\n' +
           signedProtocol + '\n' +
           signedversion + '\n';
         // const crypto = require('crypto')
         // const sig = crypto.createHmac('sha256', Buffer.from(key, 'base64')).update(StringToSign, 'utf8').digest('base64');

         var str = CryptoJS.HmacSHA256(StringToSign, CryptoJS.enc.Base64.parse(key));
         var sig = CryptoJS.enc.Base64.stringify(str);


         const sasToken = `sv=${(signedversion)}&ss=${(signedservice)}&srt=${(signedresourcetype)}&sp=${(signedpermissions)}&se=${encodeURIComponent(signedexpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;
         const containerName = environment.containerName;

         const pipeline = newPipeline(new AnonymousCredential(), {
           retryOptions: { maxTries: 4 }, // Retry options
           userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
           keepAliveOptions: {
             // Keep alive is enabled by default, disable keep alive by setting false
             enable: false
           }
         });

         const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`,
           pipeline)
         const containerClient = blobServiceClient.getContainerClient(containerName)
         if (!containerClient.exists()) {
           console.log("the container does not exit")
           containerClient.create()

         }
         var d = new Date(); // Your date
         var dStart = new Date(1970, 1, 1);
         var dateDifference = ((d.getTime() - dStart.getTime()) * 10000).toString();
         if (imagePath && imagePath !== '' && !!this.pattern.test(imagePath)) {
           var name = imagePath.split('?')[0].split('/').pop();
           dateDifference = name.split('.')[0];
         }
         const client = containerClient.getBlockBlobClient(dateDifference + '.jpg')
         const response = client.uploadBrowserData(this.currentFile, {
           blockSize: 4 * 1024 * 1024, // 4MB block size
           concurrency: 20, // 20 concurrency
           onProgress: (ev) => console.log(ev),
           blobHTTPHeaders: { blobContentType: this.currentFile.type }
         })

         this.imagepath = `https://${accountName}.blob.core.windows.net/` + client.containerName + '/' + client.name;
         resolve('Done');
       }
       else {
         this.imagepath = imagePath;
         resolve('Done');
       }
    });
    return promise;
  }

}
