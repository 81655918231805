import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VersionUpdateService } from '../_services/version-update.service';

@Component({
  selector: 'app-action-refresh',
  templateUrl: './action-refresh.component.html',
  styleUrls: ['./action-refresh.component.css']
})
export class ActionRefreshComponent implements OnInit {
  private subscription: Subscription;
  update: any;
  constructor(private updateService: VersionUpdateService) {
    this.subscription = this.updateService.getUpdate()
      .subscribe(msg => {
        this.update = msg;
      });

  }

  ngOnInit(): void {
  }
  updateAPP() {
    this.updateService.updateAPP();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
