
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';


@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css']
})
export class UserRolesComponent implements OnInit {
  active = "userlist";
  constructor() {
   
  }

  ngOnInit() {
  }
  
}
