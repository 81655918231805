


import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { Discount } from '../../_models/discounts';
import { AlertService } from '../../_services/alert.service';
import { DiscountService } from '../../_services/discounts.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { TrackEventsService } from '../../_services/track-events.service';

import { AddDiscountComponent } from './add-discount/add-discount.component';


@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit {
  data$: Observable<Discount[]>;
  submit: boolean;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  excelData = [];
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private modalService: NgbModal,
    public service: DiscountService,
    private local: LocalStorageService,
    private trackEvents: TrackEventsService,
    private alertService: AlertService) {
    this.loading$ = service.loading$;
    this.submit = false;
    this.getData();
  }

  ngOnInit() {
  }
  getData() {
    var brandID = this.local.getSelectedBrand().BrandID;
    this.service.getAll(brandID);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];

      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


      }
    });
  }
  open() {
    const modalRef = this.modalService.open(AddDiscountComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Discount");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }

  Edit(data) {
    const modalRef = this.modalService.open(AddDiscountComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Discount");
    modalRef.componentInstance.SelectedID = data.discountID;
    modalRef.componentInstance.SelectedName = data.discountName;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });

  }

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription !== undefined)
      this.locationSubscription.unsubscribe();
  }

  SelectedStatus = '';

  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]

    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
  public getTimeDiff(date: Date) {
    if (date) {
      let oToday = new Date();
      let oDatePublished = new Date(date);

      let nDiff = oDatePublished.getTime() - oToday.getTime();
      // Get diff in days
      let days = Math.floor(nDiff / 1000 / 60 / 60 / 24);
      nDiff += days * 1000 * 60 * 60 * 24;

      // Get diff in hours
      let hours = Math.floor(nDiff / 1000 / 60 / 60);
      nDiff += hours * 1000 * 60 * 60;

      // Get diff in minutes
      let minutes = Math.floor(nDiff / 1000 / 60);
      nDiff += minutes * 1000 * 60;

      // Get diff in seconds
      let seconds = Math.floor(nDiff / 1000);

      // Render the diffs into friendly duration string

      // Days
      let sDays = '00';
      if (days > 0) {
        sDays = String(days);
      }
      if (sDays.length === 1) {
        sDays = '0' + sDays;
      }

      // Format Hours
      let sHour = '00';
      if (hours > 0) {
        sHour = String(hours);
      }
      if (sHour.length === 1) {
        sHour = '0' + sHour;
      }

      //  Format Minutes
      let sMins = '00';
      if (minutes > 0) {
        sMins = String(minutes);
      }
      if (sMins.length === 1) {
        sMins = '0' + sMins;
      }

      //  Format Seconds
      let sSecs = '00';
      if (seconds > 0) {
        sSecs = String(seconds);
      }
      if (sSecs.length === 1) {
        sSecs = '0' + sSecs;
      }

      let friendlyNiceText;
      let result = {
        value: 0,
        text: ''
      };
      // Set friendly text for printing
      if (days === 0) {

        if (hours === 0) {

          if (minutes === 0) {
            let sSecHolder = seconds > 1 ? 'Seconds' : 'Second';
            friendlyNiceText = seconds + ' ' + sSecHolder;
            result.text = sSecHolder;
            result.value = seconds;
          } else {
            let sMinutesHolder = minutes > 1 ? 'Minutes' : 'Minute';
            friendlyNiceText = minutes + ' ' + sMinutesHolder;
            result.text = sMinutesHolder;
            result.value = minutes;
          }

        } else {
          let sHourHolder = hours > 1 ? 'Hours' : 'Hour';
          friendlyNiceText = hours + ' ' + sHourHolder;
          result.text = sHourHolder;
          result.value = hours;
        }
      } else {
        let sDayHolder = days > 1 ? 'Days' : 'Day';
        friendlyNiceText = days + ' ' + sDayHolder;
        result.text = sDayHolder;
        result.value = days;
      }
      if (result.value < 0) {
        return {
          value: 'Expired',
          text: ''
        };
      }
      else {
        return result;
      }

    }
    else {
      return {
        value: 'Expired',
        text: ''
      };
    }
  }
}
