import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { UnitService } from 'src/app/_services/unit.service';
import { poDetails } from 'src/app/_models/Purchaseorder';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-receivepurchaseorder',
  templateUrl: './receivepurchaseorder.component.html',
  styleUrls: ['./receivepurchaseorder.component.css']
})
export class ReceivepurchaseorderComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  receivepoForm: UntypedFormGroup;
  loading = false;
  loadingReceivepo = false;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  Items = [];
  Units = [];
  arrItems: Observable<poDetails[]> ;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private receivepoService: PurchaseorderService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedReceivepo();

  }
  get f() { return this.receivepoForm.controls; }

  private createForm() {

    this.receivepoForm = this.formBuilder.group({
      poCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      poid: 0,
      supplierID: 0,
      supplierName: [''],
      supplierContact: [''],
      supplierAddress: [''],
      subTotal: 0,
      taxAmount: 0,
      grandTotal: 0,
      expectedDate: [],
      receivedDate: [],
      date: [],
      locationID: 0,
      locationName: [''],
      locationContact: [''],
      locationAddress: [''],
      rowVersion: 0,
      poDetails: [],
      taxes: []
    });
  }

  private editForm(receivepurchaseorder) {

    this.f.poCode.setValue(receivepurchaseorder.poCode);
    this.f.date.setValue(receivepurchaseorder.date);
    this.f.expectedDate.setValue(receivepurchaseorder.expectedDate);
    this.f.referenceNo.setValue(receivepurchaseorder.referenceNo);
    this.f.poDetails.setValue(receivepurchaseorder.poDetails);
    this.f.taxes.setValue(receivepurchaseorder.taxes);

    if (receivepurchaseorder.poDetails.length > 0) {
      receivepurchaseorder.poDetails.forEach(element => {
        element.receivedQty = element.receivedQty == null ? 0 : element.receivedQty;
      });
    }
    this.arrItems = receivepurchaseorder.poDetails;

    this.f.notes.setValue(receivepurchaseorder.notes);
    this.f.rowVersion.setValue(receivepurchaseorder.rowVersion);
    this.f.statusID.setValue(receivepurchaseorder.statusID);
    this.f.poid.setValue(receivepurchaseorder.poid);
    this.f.supplierID.setValue(receivepurchaseorder.supplierID);
    this.f.locationID.setValue(receivepurchaseorder.locationID);

  }

  setSelectedReceivepo() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Receive";
      this.loadingReceivepo = true;
      this.f.poid.setValue(this.SelectedID);
      this.receivepoService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingReceivepo = false;
      });
    }
  }


  onSubmit() {
    this.f.poDetails.value.forEach(item => {
      if (item.receivedQty === undefined || item.receivedQty === '' || item.receivedQty === null || item.receivedQty > item.quantity || item.receivedQty <= 0) {
        this.receivepoForm.setErrors({ 'invalid': true });
      }
    });

    this.receivepoForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.receivepoForm.invalid) { return; }
    this.loading = true;

    this.f.statusID.setValue(605);

    if (parseInt(this.f.poid.value) !== 0) {
      //Update receivepurchaseorder
      this.receivepoService.receivePO(this.receivepoForm.value).subscribe(data => {
        this.alertService.success("Purchase order has been received");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });

    }
  }

  close() {
    this.activeModal.dismiss();
  }

  UpdateQty(newItem) {
    if (newItem.receivedQty === undefined || newItem.receivedQty === '' || newItem.receivedQty === null || newItem.receivedQty > newItem.quantity || newItem.receivedQty <= 0) {
      this.receivepoForm.setErrors({ 'invalid': true });
    }
    else {
      this.receivepoForm.setErrors(null);
      //let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.receivedQty);
      //let index = this.arrItems.indexOf(updateItem);
      //this.arrItems[index] = newItem;
    }
  }

  findIndexToUpdate(newItem) {
    return newItem.receivedQty === this;
  }
}
