<div class="d-flex app-alert" *ngIf="update">
  <div class="card mt-5 p-3">
    <div class="media">
      <img src="../../favicon.ico" class="mb-2">
      &nbsp;<span class="title">Update</span>
      <div class="media-body">
        <h6 class="mt-2 mb-0">New Version Available</h6>
        <small class="text" (click)="updateAPP()"><u><a>Click here to update →</a></u></small>
      </div>
    </div>
  </div>
</div>
