import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ExpenseService } from '../../_services/expense.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as myGlobals from '../../_helpers/globals';
import { LocationService } from '../../_services/location.service';

@Component({
  selector: 'app-expense-pdf',
  templateUrl: './expense-pdf.component.html',
  styleUrl: './expense-pdf.component.css'
})
export class ExpensePDFComponent {
  SelectedBrand: any;
  selctedLang = 'en';
  @Input() Data: any;
  @Output() html = new EventEmitter<HTMLElement>();
  industry = 0;
  brandCurrency: string = '';
  
  elRef: ElementRef;
  constructor(private localStorageService: LocalStorageService,
    public service: ExpenseService,
    private elementRef: ElementRef,
    private location: LocationService  ) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.SelectedBrand = this.localStorageService.getSelectedBrand();
    this.industry = this.localStorageService.getSelectedUser().User.Industry;
    this.brandCurrency = myGlobals.brandCurrency(this.localStorageService);
    this.elRef = elementRef;
  }

  ngOnDestroy() {
    if (this.Data) {
      this.html.emit(this.elementRef.nativeElement);
    }
  }
}
