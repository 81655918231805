import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { trigger, state, style, animate, transition, keyframes  } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as $ from 'jquery';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
  animations:[
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ])
  ]
})
export class ResetpasswordComponent {

  loading = false;
  submitted = false;
  done = false;
  token = "";
  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }
  resetForm: UntypedFormGroup;
  
  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      password: ['', { validators: [Validators.required], updateOn: 'blur' }],
      confirmpassword: ['', { validators: [Validators.required], updateOn: 'blur' }]
    });
    this.token = this.route.snapshot.queryParams['token'];
  }
  get f() { return this.resetForm.controls; }

  ResetPassword() {

    this.submitted = true;
    this.alertService.clear();


    let _this = this;
    $.ajax({
      method: 'POST',
      data: {
        "password": this.f.password.value
      },
      url: "api/Authenticate/resetpassword",
      crossDomain: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Authorization": "Bearer " + this.token
      },
      success: function (res) {
        _this.done = true;
      }, error: function (data: any) {

        if (data.status === 401) {
          _this.alertService.error(data.statusText + "! Token Invalid or Expired");
        } else {
          _this.alertService.error(data.statusText);
        }
        _this.loading = false;
        _this.done = false;
      }
    })

  }


  continue(){
    this.router.navigate(['/']);
  }
}
