import { Component, OnInit, DebugElement } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { PurchaseReturnService } from '../../../../_services/purchasereturn.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PurchaseorderService } from '../../../../_services/purchaseorder.service';
import { AlertService } from '../../../../_services/alert.service';
import { PurchaseReturnDetails, PurchaseReturn } from '../../../../_models/purchase-return';

@Component({
  selector: 'app-addpurchasereturn',
  templateUrl: './addpurchasereturn.component.html',
  styleUrls: ['./addpurchasereturn.component.css']
})
export class AddpurchasereturnComponent implements OnInit {

  preturnForm: UntypedFormGroup;
  ButtonText = "Create";
  isItemNotSelected = true;
  loading: any;
  POs: any;
  returnitems: Observable<PurchaseReturnDetails[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    public service: PurchaseReturnService,
    public activeModal: NgbActiveModal,
    private purchaseorderService: PurchaseorderService,
    private alertService: AlertService) {
    this.createForm();
    this.getPurchaseDDL();
  }

  ngOnInit() {

  }

  get f() { return this.preturnForm.controls; }

  private createForm() {

    this.preturnForm = this.formBuilder.group({
      POID: ['', Validators.required],
      POCode: '',
      supplier: [''],
      note: [''],
      statusID: [1, Validators.required],
      BrandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      returnID: 0,
      rowVersion: 0,
      PODetails: null
    });
  }


  close() {
    this.activeModal.dismiss();
  }
  getPurchaseDDL() {
    this.service.getPOsDDL(this.f.BrandID.value).subscribe((res: any) => {
      debugger;
      this.POs = res;
    });

  }
  setSelectedPurchaseorder() {

    //this.ButtonText = "Update";

    this.service.getById(this.f.POID.value, this.f.BrandID.value).subscribe(res => {
      //Set Forms
      this.editForm(res);

    });

  }
  private editForm(purchaseorder) {
    debugger;


    this.f.note.setValue(purchaseorder.notes);
    this.f.POCode.setValue(purchaseorder.poCode);
    this.f.rowVersion.setValue(purchaseorder.rowVersion);
    this.f.statusID.setValue(purchaseorder.statusID);
    this.returnitems = purchaseorder.poDetails;
    this.loadSupplier(purchaseorder.supplierID);

  }
  loadSupplier(id) {
    this.purchaseorderService.getSupplierById(id).subscribe((res: any) => {
      // this.f.supplierID.setValue(res.supplierID);
      this.f.supplier.setValue(res.name);
      // this.f.supplierAddress.setValue(res.email);
      // this.f.supplierContact.setValue(res.contactNo);
    });
  }
  onPurchaseChange(event) {
    this.setSelectedPurchaseorder();
  }
  public bulkSelection($event) {
    this.returnitems.forEach(e => {
      e["selected"] = $event.target.checked
  });
  }
  onReturnQtyChange(item) {
    debugger;
    if (item.selected === true && (item.returnQuantity === undefined || item.returnQuantity === '' || item.returnQuantity === null || item.returnQuantity > item.quantity || item.returnQuantity <= 0)) {
      this.preturnForm.setErrors({ 'invalid': true });
    }
    else {
      this.preturnForm.setErrors(null);
    }
   

  }
  onSubmit() {
    this.preturnForm.markAllAsTouched();
    debugger;
    // reset alerts on submits
    this.alertService.clear();
    if (this.preturnForm.invalid) {
      return;
    }

    this.returnitems.forEach(x => {
      x["returnDate"] = new Date();//"2020-12-16";
      if (x["selected"] == undefined) {
        x["selected"] = false;
      }
    });
    this.returnitems.forEach(x => {
      if (x["selected"] === true) {
        this.isItemNotSelected = false;
        return;
      }
    });


    if (this.isItemNotSelected === true) {
      this.alertService.error("Please select item");
      return;
    }
   
    this.f.PODetails.setValue(this.returnitems);
    


    this.loading = true;
    this.service.insert(this.preturnForm.value).subscribe(() => {
      this.alertService.success("Purchase Return has been saved.");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });


  }
}
