

import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../_services/alert.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { LocationInventoryService } from 'src/app/_services/location-inventory.service';
import { LocationInventory } from 'src/app/_models/LocationInventory';
import { ExcelService } from 'src/app/_services/excel.service';
import { PermissionService } from '../../_services/permission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationInventoryUpliftComponent } from './location-inventory-uplift/location-inventory-uplift.component';

@Component({
  selector: 'app-location-inventory',
  templateUrl: './location-inventory.component.html',
  styleUrls: ['./location-inventory.component.css'],
  providers: [ExcelService]
})
export class LocationInventoryComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  private locationSubscription: Subscription;
  data$: Observable<LocationInventory[]>;
  oldData: LocationInventory[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  excelData = [];
  submit: boolean;
  selectedLocation: number;
  minStock: boolean = false;
  selctedLang = 'en';
  constructor(public service: LocationInventoryService,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
    private excelService: ExcelService,
    private alertService: AlertService,
    private permissionService: PermissionService,
    private modalService: NgbModal) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.loading$ = service.loading$;

    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.submit = false;
    this.minStock = this.permissionService.checkPermission(['Locations', 'Inventory', 'Min Stock']);
    this.locationSubscription = this.locationService.locationIdChange.subscribe((id) => {
      if (id !== undefined && id !== 0 && id !== null) {
        service.getLocationStock(id);

      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnInit() {
    if (LocationService.locationId !== 0) {
      this.service.getLocationStock(LocationService.locationId);
    }
  }

  public bulkSelection($event) {
    if (this.service.allData$) {
      this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
    }
  }

  public saveItems() {
    this.submit = true;
    let items: LocationInventory[];
    this.changeItemQTY().then(res => {
      items = this.service.locationInventorys;
      this.service.saveItems(items, this.selectedBrand).subscribe(
        data => {
          this.alertService.success("Stocks has been updated.");
          this.submit = false;
        },
        error => {
          this.alertService.error(error)
          this.submit = false;
        });
    });
  }

  public priceChange(item: LocationInventory, event: any) {
    if (event.target.value === '')
      item.minStockLevel = null;
  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    this.locationSubscription.unsubscribe();
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-LocationInventory', type);
  }

  public chkSelection($event, obj) {
    this.service.locationInventorys.forEach((item, index) => {
      if (item.stockID == obj.stockID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {
          this.excelData.splice(index, 1);
        }
      }
    });
  }

  private getExportData() {
    let selected: LocationInventory[];
    let allInventory: LocationInventory[];
    selected = [];
    let returnList = [];
    let lang=this.selctedLang;
    allInventory = this.service.locationInventorys;
    this.service.locationInventorys.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? allInventory : selected;
    selected = selected.filter(function (item) {
      var data =
      {
        stockID: item.stockID,
        supplyID: item.supplyID,
        type: item.type,
        name: lang == 'en' ? item.name : item.alternateName !== '' ? item.alternateName : item.name,
        sku: item.sku,
        barcode: item.barcode,
        unit: item.unit,
        cost: item.cost,
        avgCost: item.avgCost,
        minStockLevel: item.minStockLevel,
        currentStock: item.currentStock,
        rowVersion: item.rowVersion
      };
      returnList.push(data);
      return true;
    });
    return returnList;
  }
  changeItemQTY() {
    return new Promise<void>((resolve, reject) => {
      this.service.locationInventorys.forEach(ele => {
        ele.currentStock = ele.currentStockUpdated;
      });
      resolve();
    });
  }
  import() {
    const modalRef = this.modalService.open(LocationInventoryUpliftComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.result.then((result) => {
    }, (r) => {
        this.service.getLocationInventorys(this.selectedBrand, LocationService.locationId);
        this.data$ = this.service.data$;
    });
  }
}
