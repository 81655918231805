import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { StockMovementReport } from '../../../_models/GeneratedReport';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { StockMovementReportService } from '../../../_services/stock-movement-report.service';

@Component({
  selector: 'app-stock-movement',
  templateUrl: './stock-movement.component.html',
  styleUrl: './stock-movement.component.css'
})
export class StockMovementComponent {
  data$: Observable<StockMovementReport[]>;
  allData$: Observable<StockMovementReport[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  selectedLang = 'en';
  submit: boolean;
  selectedReport: any;
  selectAll: boolean = true;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  locations = [];
  checkAllLocations: boolean = false;
  constructor(public service: StockMovementReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private excelService: ExcelService) {

    this.loading$ = service.loading$;
    this.submit = false;
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.selectedReport = history.state;

  }

  ngOnInit() {

    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
    }, 1000);
  }
  getReportData(dataURL) {
    this.service.getStockMovementReport(dataURL);
    this.data$ = this.service.data$;
    this.allData$ = this.service.allData$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    this.service._allData$.subscribe(res => {
      this.locations = [];
      if (res) {
        new Set(res.map(report => report.Location)).forEach(e => {
          this.locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }
  export(type, data): void {
    if (data != undefined && data != null && data != "") {
      this.CustomizeReport(type, data);

    }
    else
      (data != undefined) ? (data.length == 0 ? this.alertService.error("Data is not available.") : "") : "";

  }

  public bulkSelection($event) {
    this.data$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
    this.allData$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
  }


  private CustomizeReport(type, data) {

    let jsonData = [];

    data.source._value.forEach(function (item) {
      let temp = {
        "Location": item.Location,
        "Name": item.Name,
        "AlternateName": item.AlternateName,
        "Unit": item.Unit,
        "SKU": item.SKU,
        "InitialQTY": item.InitialQTY,
        "InitialQtyCost": item.Cost,
        "PurchasedQTY": item.PurchasedQTY,
        "ReturnedPurchasedQTY": '-'+ item.ReturnedPurchasedQTY,
        "TransferQTY": item.TransferQTY,
        "ReturnedTransferQTY": '-' + item.ReturnedTransferQTY,
        "TransferOutQTY": '-' +item.TransferOutQTY,
        "ReturnedTransferOutQTY": item.ReturnedTransferOutQTY,
        "PrepConsumption": '-' + item.PrepConsumption,
        "PrepReceived": item.PrepReceived,
        "SalesConsumption": '-' + item.SalesConsumption,
        "SalesReturn": item.SalesReturn,
        "ReconciliationQTY": '-' +item.ReconciliationQTY,
        //"StockCountDifference": item.StockDifference,
        //"ExpiredQTY": '-' +item.ExpiredQTY,
        "Final Quantity": item.FinalQuantity,
        "Final QTY Cost": item.FinalQTYCost
      };
      jsonData.push(temp);
    });
    if (jsonData.length > 0) {
      this.excelService.exportAsExcelFile(jsonData, 'Export-StockMovement', type);
    }
    else {
      this.alertService.error("Select orders to download.")
    }
  }
  filter() {
    if (this.locations.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    else {
      this.checkAllLocations = true;
    }
    let headerFilter = {
      locations: this.locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filter();
  }

}
