<main class="invoice">
  <div class="page">
    <div class="row">
      <div class="col-md-12" style="padding-right:1px">
        <img [src]="selectedBrand?.BrandLogo" style="width:50px" />
      </div>
      <div class="col-md-12 text-right" style="padding-left:1px; font-size:20px">
        {{selectedBrand.BrandName}}
      </div>
    </div>
    <div class="page-body">
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="row text-center" style="background-color: #ebedee !important">
          <div class="col-lg-12">
            {{selectedReport.locations}}
          </div>
          <div class="col-lg-12">
            {{selectedReport.dateRange}}
          </div>
          <div class="col-lg-12">
            Generated By: {{selectedReport.generatedBy}}
          </div>
          <div class="col-md-12">
            {{selectedReport.reportName}}
          </div>
          <div class="col-md-12">
            Generated On: {{selectedReport.generatedOn | date:'MM dd, yyyy'}}
          </div>
        </div>
        <div class="tile-body p-0">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th>{{'Date' | translate }} </th>
                <th>{{'Day' | translate }} </th>
                <th>{{'Order Count' | translate }} </th>
                <th>{{'Order Average' | translate }} </th>
                <th>{{'Card' | translate }} </th>
                <th>{{'Cash' | translate }} </th>
                <th>{{'Aggregator' | translate }} </th>
                <th>{{'Discount' | translate }} </th>
                <th>{{'Complimentary' | translate }} </th>
                <th>{{'Void' | translate }} </th>
                <th>{{'Hold' | translate }} </th>
                <th>{{'Refund' | translate }} </th>
                <th>{{'Sales' | translate }} </th>
                <th>{{'Sales With Tax' | translate }} </th>
                <th>{{'Tax' | translate }} </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dailySales; let i = index">
                <td>{{item.BusinessDate | date:'dd/MM/yyyy' }}</td>
                <td> {{item.Day }} </td>
                <td> {{item.OrderCount }} </td>
                <td> {{item.OrderAverage | number:'1.2-2'}} </td>
                <td>  {{item.Card | number:'1.2-2' }} </td>
                <td> {{item.Cash | number:'1.2-2' }} </td>
                <td>  {{item.Aggregator | number:'1.2-2' }} </td>
                <td> {{item.Discount | number:'1.2-2' }} </td>
                <td> {{item.ComplimentaryCount}}({{item.Complimentary | number:'1.2-2' }}) </td>
                <td> {{item.Void | number:'1.2-2' }} </td>
                <td>  {{item.HoldCount}}({{item.Hold | number:'1.2-2' }}) </td>
                <td>{{item.Refund | number:'1.2-2' }} </td>
                <td> {{item.Sales | number:'1.2-2' }} </td>
                <td> {{item.GrandTotal | number:'1.2-2' }} </td>
                <td>{{item.Tax | number:'1.2-2' }}</td>
              </tr>
              <tr class="tbl-total-row">
                <td></td>
                <td>
                  <label class="fw-bold font-15">{{'Totals' | translate }}</label>
                </td>
                <td></td>
                <td> {{service.sumOrderAverage | number:'1.2-2'}} </td>
                <td>  {{service.sumCard | number:'1.2-2' }} </td>
                <td> {{service.sumCash | number:'1.2-2' }} </td>
                <td>  {{service.sumAggregator | number:'1.2-2' }} </td>
                <td> {{service.sumDiscount | number:'1.2-2' }} </td>
                <td> {{service.sumComplimentary | number:'1.2-2' }} </td>
                <td> {{service.sumVoid | number:'1.2-2' }} </td>
                <td> {{service.sumHold | number:'1.2-2' }} </td>
                <td>{{service.sumRefund | number:'1.2-2' }} </td>
                <td> {{service.sumSales | number:'1.2-2' }} </td>
                <td> {{service.sumGrandTotal | number:'1.2-2' }} </td>
                <td>{{service.sumTax | number:'1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
          <div class="col-lg-12" style="margin-top:1%">
            Powered By marn.com
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
