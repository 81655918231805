import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { LocalStorageService } from './local-storage.service';
import * as myGlobals from '../_helpers/globals';


@Injectable({
  providedIn: 'root'
})
export class LocationIntegrationZATCAService {


  URL: string = null;
  industry: string = null;
  checkZatcaCompliance: boolean = false;
  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService, private httpBackend: HttpBackend) {
    this.URL = environment.ZATCA_Config.URL;
    this.industry = myGlobals.industryName(this.localStorageService);
    // this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;
  }



  getZATCACompliance(brandid, locationid) {
    return this.http.get<any[]>(`api/zatca/compliance/${brandid}/${locationid}`);
  }


    ComplianceDeviceWithOTP(data: any): Observable<any> {

    return this.http.post<any>('api/zatca/ComplianceDevice/'+myGlobals.industryName(this.localStorageService), {
            "authenticatedCode": data.authenticatedCode,
            "deviceModel": data.deviceModel,
            "otp": data.otp,
            "serialNumber": data.serialNumber,
            "terminalID": data.terminalID
    })
      .pipe(
        catchError((err) => {
          throw '1234. Request failed:' + err;
      })
      );
  }



}

