import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { TableService } from 'src/app/_services/table.service';
import { LocationService } from 'src/app/_services/location.service';
import { Floors } from 'src/app/_models/Floor';
import { TableType } from 'src/app/_models/TableType';

@Component({
  selector: 'app-addtable',
  templateUrl: './addtable.component.html',
  styleUrls: ['./addtable.component.css']
})
export class AddtableComponent implements OnInit {
  @Input() name;
  @Input() SelectedName = '';

  Floors: Floors[];
  TableTypes: TableType[];
  @Input() SelectedID = 0;

  LocationID = '';
  submitted = false;
  tableForm: UntypedFormGroup;
  loading = false;
  loadingTables = false;
  ButtonText = "Add Table";
  Title = "Add Table";
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private tableService: TableService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.LocationID = LocationService.locationId.toString();
    this.loadFloors();
    this.loadTypes();
    this.setSelectedTables();
  }

  ngAfterViewInit() {
    // document.getElementById("tableTitle").focus();
  }


  private createForm() {
    //this.Location = DataService.Name;
    this.tableForm = this.formBuilder.group({
      tableTitle: ['', Validators.required],
      totalSeats: [3, Validators.required],
      statusID: [true, Validators.required],
      locationID: LocationService.locationId,
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      floorID: 0,
      tableID: 0,
      tableTypeID: 0,
      rowVersion: 0
    });
  }

  private clearForm() {
    this.f.tableID.setValue(0);
    //this.f.tableTitle.setValue('');
    this.f.rowVersion.setValue(0);
    this.f.statusID.setValue(true);
    this.f.totalSeats.setValue(3);
  }

  get f() { return this.tableForm.controls; }

  setSelectedTables() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingTables = true;
      this.f.tableID.setValue(this.SelectedID);
      this.tableService.getById(this.SelectedID).subscribe(res => {

        this.Title = "Edit Table";
        //Set Forms
        this.editForm(res);
        this.loadingTables = false;
      });
    }
  }


  private editForm(table) {
    this.f.tableID.setValue(table.tableID);
    this.f.floorID.setValue(table.floorID);
    this.f.tableTypeID.setValue(table.tableTypeID);
    this.f.tableTitle.setValue(table.tableTitle);
    this.f.rowVersion.setValue(table.rowVersion);
    this.f.statusID.setValue(table.statusID === 1 ? true : false);
    this.f.locationID.setValue(table.locationID);
    this.f.brandID.setValue(table.brandID);
    this.f.totalSeats.setValue(table.totalSeats);

  }


  close() {
    this.activeModal.dismiss();
  }
  private loadFloors() {
    this.tableService.getFloors(this.LocationID).subscribe((res: Floors[]) => {
      this.Floors = res;
      if (this.Floors[0].floorID !== undefined && this.Floors[0].floorID !== null) {
        this.f.floorID.setValue(this.Floors[0].floorID);
      }
    });
  }
  private loadTypes() {
    this.tableService.getTableType().subscribe((res: TableType[]) => {
      this.TableTypes = res;
      if (this.TableTypes[0].tableTypeID !== undefined && this.TableTypes[0].tableTypeID !== null) {
        this.f.tableTypeID.setValue(this.TableTypes[0].tableTypeID);
      }
    });
  }
  onSubmit() {
    this.tableForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    if (this.tableForm.invalid) { return; }
    this.loading = true;
    this.f.totalSeats.setValue(parseInt(this.f.totalSeats.value));

    if (parseInt(this.f.tableID.value) === 0) {

      console.log(JSON.stringify(this.tableForm.value));
      this.tableService.insert(this.tableForm.value).subscribe(data => {
        this.alertService.success("Table has been created");
        this.loading = false;
        //this.activeModal.dismiss();
        this.f.tableTitle.reset();
        this.clearForm();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update Location
      this.tableService.update(this.tableForm.value).subscribe(data => {
        this.alertService.success("Table has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }



}
