import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionUpdateService {
  private subject = new Subject<any>();

  constructor(swUpdate: SwUpdate) {
    /*
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        if (prompt('Refresh','Yes')) {
          // Reload the page to update to the latest version.
          document.location.reload();
        }
      });
      */
    swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.promptUser();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
      
    });
  }

  private promptUser(): void {
    this.subject.next({ refresh: "Page is Refreshing" });
  }
  getUpdate(): Observable<any> {
    return this.subject.asObservable();
  }
  updateAPP() {
    this.clear();
    document.location.reload();
  }
  clear() {
    // clear by calling subject.next() without parameters
    this.subject.next();
  }
}
