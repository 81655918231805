export class Reconcillation {
  rcnid: number;
  rcnCode: string;
  ReferenceNo: string;
  locationID: number;
  locationName: string;
  notes: string;
  date: string;
  time: string;
  brandID: number;
  statusID: number;
  selected: boolean;
  creaedBy:string;
}

export class rcnDetails {
  supplyID: number;
  type: string;
  name: string;
  alternateName: string;
  quantity: number;
  cost: number;
  reasonID: number;
  unitID: number;
  statusID: number;
  reason: string;
}
