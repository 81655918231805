import { Component, ViewChild, Input } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexMarkers,
  AnnotationStyle,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  ApexAnnotations,
  ApexNonAxisChartSeries,
  ApexFill,
  ApexLegend,
  ApexTooltip,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexTheme
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  markers: ApexMarkers;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  legend: ApexLegend;
};
@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html'
})
export class SalesSummaryComponent {
  public salesSummaryBarOptions: Partial<ChartOptions>;
  constructor() {
    this.salesSummaryBarOptions = {
      series: [{
        name: '',
        data: [44, 55, 41, 67, 22, 43, 21, 49]
      },
      {
        name: '',
        data: [66, 45, 59, 33, 78, 57, 79, 51]
      }
      ],
      chart: {
        type: 'bar',
        height: 200,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false
        }
      },
      colors: ['#50cc7d', '#ebedee'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%'
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['MON', 'TUE', 'WED', 'THURS', 'FRI', 'SAT', 'SUN'],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        labels: {
          style: {
            fontSize: '11px',
            fontFamily: 'Montserrat-SemiBold'
          }
        }
      },
      yaxis: {
        show: false
      },
      grid: {
        show: false
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
    };

  }
}
