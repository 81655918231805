import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepickerAdapter } from '../../../datepicker/datepicker-adapter';
import { ExpenseDetailDto, ExpenseDto, ExpenseLog, ExpenseTaxDto } from '../../../_models/Expense';
import { AlertService } from '../../../_services/alert.service';
import { ExpenseService } from '../../../_services/expense.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import * as myGlobals from '../../../_helpers/globals';
import { UploadFileComponent } from '../../../upload-file/upload-file.component';
import { PdfService } from '../../../_services/pdf.service';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-expense',
  templateUrl: './create-expense.component.html',
  styleUrl: './create-expense.component.css'
})
export class CreateExpenseComponent {

  @Input() SelectedID = 0;
  submitted = false;
  expenseForm: UntypedFormGroup;
  loading = false;
  ButtonText = "Create";
  Locations = [];
  arrItems: ExpenseDetailDto[] = [];
  arrtaxes: ExpenseTaxDto[] = [];
  PDFExpenseData: any = null;
  taxTypes = null;
  selectedLang = 'en';
  selectedLocationIds: string[];
  export: boolean = false;
  showAttachment: boolean = true;
  @ViewChild('maturityDate') maturityDate: NgbdDatepickerAdapter;
  @ViewChild('generationDate') generationDate: NgbdDatepickerAdapter;
  @ViewChild(UploadFileComponent, { static: true }) imgComp;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private expenseService: ExpenseService,
    private modalService: NgbModal,
    private pdfService: PdfService,
    private translate: TranslateService,
    private confirmationDialogService: ActionConfirmationService
  ) {
    this.createForm();
    this.selectedLang = this.ls.getSelectedLanguage();
  }

  ngOnInit() {
    this.GetTaxType();
    this.setSelectedExpense();
    this.loadLocations();

    if (this.f.expenseID.value == 0)
      this.getTaxes(this.f.brandID.value);

  }

  get f() { return this.expenseForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }

  ngAfterViewInit(): void {
    this.showAttachment = this.f.expenseID.value == 0 ? true : false;
  }
  private createForm() {
    this.expenseForm = this.formBuilder.group({
      expenseID: [0],
      code: [''],
      name: ['', Validators.required],
      maturityDate: ['', Validators.required],
      generationDate: [''],
      expenseTo: [''],
      referenceNo: [''],
      locations: [''],
      locationIDs: [''],
      note: [''],
      attachment: [''],
      subTotal: [0],
      grandTotal: [0],
      taxAmount: [0],
      statusID: [1],
      lastUpdatedBy: [''],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      userID: [0],
      rowVersion: [0],
      details: [],
      taxes: [],
      logs: []

    });
  }

  private editForm(expense) {
    this.maturityDate.setDate(expense.maturityDate);
    this.generationDate.setDate(expense.generationDate);

    this.f.expenseID.setValue(expense.expenseID);;
    this.f.code.setValue(expense.code);
    this.f.name.setValue(expense.name);
    this.f.maturityDate.setValue(new Date(this.maturityDate.dateModel.year + "/" + this.maturityDate.dateModel.month + "/" + this.maturityDate.dateModel.day));
    this.f.generationDate.setValue(new Date(this.generationDate.dateModel.year + "/" + this.generationDate.dateModel.month + "/" + this.generationDate.dateModel.day));
    this.f.expenseTo.setValue(expense.expenseTo);
    this.f.referenceNo.setValue(expense.referenceNo);
    this.arrItems = expense.details;
    if (expense.locations) {
      const stringToConvert = expense.locationIDs;
      this.selectedLocationIds = stringToConvert.split(',').map(Number);
      this.f.locationIDs.setValue(expense.locations);
    }

    this.f.note.setValue(expense.note);
    this.f.attachment.setValue(expense.attachment);
    this.f.subTotal.setValue(expense.subTotal);
    this.f.grandTotal.setValue(expense.grandTotal);
    this.f.statusID.setValue(expense.statusID);
    this.f.lastUpdatedBy.setValue(expense.lastUpdatedBy);
    this.f.brandID.setValue(parseInt(this.ls.getSelectedBrand().BrandID));
    this.f.userID.setValue(expense.userID);
    this.f.rowVersion.setValue(expense.rowVersion);
    this.f.taxes.setValue(expense.taxes);
    this.f.logs.setValue(expense.logs);
    this.arrtaxes = expense.taxes;
    //this.imgComp.filePath = expense.attachment;
  }

  setSelectedExpense() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loading = true;
      this.f.expenseID.setValue(this.SelectedID);
      this.expenseService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.PDFExpenseData = res;
        this.CalculateSummary();
        this.loading = false;
      });
    }
    else {
      this.addItem();
    }
  }

  onSubmit() {

    this.expenseForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.arrItems.length == 0) {
      this.alertService.error("Items cannot be empty.");
      return;
    }
    let error;
    this.arrItems.forEach(e => {
      if (e.amount <= 0) {
        error = true;
      }
      if (e.quantity <= 0) {
        error = true;
      }
    });
    if (error === true) {
      return;
    }
    if (this.maturityDate.model)
      this.f.maturityDate.setValue(new Date(this.maturityDate.dateModel.year + "/" + this.maturityDate.dateModel.month + "/" + this.maturityDate.dateModel.day));

    if (this.expenseForm.invalid) { return; }
    this.loading = true;
    this.f.locationIDs.setValue(this.selectedLocationIds === undefined ? "" : this.selectedLocationIds.toString());


    this.f.generationDate.setValue(new Date(this.generationDate.dateModel.year + "/" + this.generationDate.dateModel.month + "/" + this.generationDate.dateModel.day));

    this.f.details.setValue(this.arrItems);
    this.f.taxes.setValue(this.arrtaxes);
    let text = {
      a: 'Make sure your inputs are correct',
      b: 'Once you confirm creating the expense the amount will be calculated in Income statement or VAT Declaration'
    };
    let body = '<p>' + this.translate.instant(text.a) + ' </p> <p>' + this.translate.instant(text.b) + ' </p>';
    this.confirmationDialogService.confirmationDialogue('New Expense', body, 'Create', false)
      .then((confirmed) => {
        if (confirmed.status) {
          this.imgComp.upload((this.f.attachment.value && this.f.attachment.value !== '' ? this.f.attachment.value : '')).then(() => {
            this.f.attachment.setValue(this.imgComp.filePath);
            if (parseInt(this.f.expenseID.value) === 0) {

              //Insert Expense
              this.expenseService.insert(this.expenseForm.value).subscribe(data => {
                this.alertService.success("Expense has been created");
                this.loading = false;
                this.activeModal.dismiss();
              }, error => {
                this.alertService.error(error);
                this.loading = false;
              });

            } else {
              //Update Expense
              this.expenseService.update(this.expenseForm.value).subscribe(data => {
                this.alertService.success("Expense has been updated");
                this.loading = false;
                this.SelectedID = 0;
                this.activeModal.dismiss();
              }, error => {
                this.alertService.error(error);
                this.loading = false;
                this.activeModal.dismiss();
              });
            }
          });
        }
        console.log('User confirmed:', confirmed.status)
        this.loading = false;
      })
      .catch(() => { console.log('User dismissed the dialog.'); this.loading = false; });
  }

  loadLocations() {
    this.expenseService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }

  close() {
    this.activeModal.dismiss();
  }
  deleteRow(obj) {

    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);

    this.CalculateSummary();

  }
  addItem() {
    let count = this.arrItems.length + 1;
    this.arrItems.push({
      rowNo: count,
      totalAmount: 0,
      name: '',
      statusID: 1,
      quantity: 0,
      amount: 0,
      expenseDetailID: 0,
      expenseID: 0
    });
  }
  updateRow(obj) {
    obj.totalAmount = obj.quantity * obj.amount;
    this.CalculateSummary();


  }
  CalculateSummary() {

    this.f.subTotal.setValue(0);
    this.f.grandTotal.setValue(0);
    this.f.taxAmount.setValue(0);
    var subTotal = 0;
    var taxAmount = 0;
    var itemTotal = 0;
    if (this.arrItems.length > 0) {
      this.arrItems.forEach(element => {
        subTotal += Number(element["quantity"] * element["amount"]);
        itemTotal += Number(element["quantity"] * element["amount"]);
      });
      this.arrtaxes.forEach(element => {
        if (element.type === 2) {
          var _itemTotal = Number(itemTotal / (1 + (element.percentage / 100)))
          var tax = Number((element.percentage / 100) * _itemTotal);
          subTotal -= tax;
          element.amount = tax;
          taxAmount += tax;
        }
        else if (element.type === 3) {
          element.amount = Number((element.percentage / 100) * itemTotal);
          taxAmount += Number((element.percentage / 100) * itemTotal);
        }
        else {
          element.amount = Number((element.percentage / 100) * itemTotal);
        }

      });
      this.f.subTotal.setValue(subTotal);
      this.f.taxAmount.setValue(taxAmount);
      this.f.grandTotal.setValue(subTotal + taxAmount);
    }
  }


  UpdateQty(newItem) {
    if (newItem.cost > 0) {
      let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.Cost);
      let index = this.arrItems.indexOf(updateItem);

      this.arrItems[index] = newItem;

      this.CalculateSummary();
    }
    if (newItem.cost <= 0) {
      this.alertService.error("Item rate must be greater than 0.");
      //newItem.cost = newItem.cost * -1;
      return;
    }
  }

  findIndexToUpdate(newItem) {
    return newItem.cost === this;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      const search = item['name'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) !== -1);
    });

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      const search = item['SKU'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) !== -1);
    });


    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  SearchItem(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.barcode.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.alternateName.toLocaleLowerCase().indexOf(term) > -1 || item.sku.toLocaleLowerCase().indexOf(term) > -1;
  }

  getTaxes(brandID) {
    this.expenseService.getTaxes(brandID).subscribe((res: any) => {
      this.arrtaxes = res;
      var len = this.arrtaxes.length;
      this.arrtaxes.forEach(e => {
        if (len > 1)
          e.type = 1;
        else if (len === 1)
          e.type = 3;
      });
    });
  }
  GetTaxType() {
    this.expenseService.GetTaxType().subscribe((res: any) => {
      this.taxTypes = res;
    });
  }
  changeTax(tax, $event) {
    tax.type = parseInt($event.target.value);
    this.CalculateSummary();
  }
  public openPDF(): void {
    this.loading = true;
    this.export = true;
    setTimeout(() => {
      this.export = false;
    }, 3000);
  }
  receive($event) {
    this.pdfService.SaveAsPDFFileMultiPage($event, 'Expense-PDF', "htmlData");
    this.pdfService.PDFGenerated.subscribe((res: any) => {
      if (res)
        this.loading = false;
    });
  }

  public void() {
    let text = { a: 'Are you sure you want to void the expense', b: 'Once you add the void reason and confirm the void action the amount of the expense will not be calculated in Income statement or VAT Declaration' }
    let body = '<p>' + this.translate.instant(text.a) + ' <b>' + this.f.code.value + '</b>' + this.translate.instant('?') +'</p>' + this.translate.instant(text.b) + ' <p></p>';
    this.confirmationDialogService.confirmationDialogue('Void Expense', body, 'Void', true)
      .then((confirmed) => {
        if (confirmed.status) {
          this.expenseService.void(parseInt(this.ls.getSelectedBrand().BrandID), parseInt(this.f.expenseID.value), this.f.rowVersion.value, confirmed.text).subscribe((res: any) => {
            this.alertService.success("Expense has been Voided");
            this.setSelectedExpense();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed.status)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
}
