import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { PartnerSummary } from '../../../_models/GeneratedReport';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { PartnerSummaryService } from '../../../_services/partner-summar.service';

@Component({
  selector: 'app-partner-daily-summary',
  templateUrl: './partner-daily-summary.component.html',
  styleUrls: ['./partner-daily-summary.component.css']
})
export class PartnerDailySummaryComponent implements OnInit {
  data$: Observable<PartnerSummary[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  submit: boolean;
  selectedReport: any;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: PartnerSummaryService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private excelService: ExcelService) {
    this.loading$ = service.loading$;
    this.submit = false;

    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      //this.getReportData("https://marnpossastorage.blob.core.windows.net/marnpos-admin-reports/2021-01-30/stock-20210130T103754521");
    }, 1000);
  }

  getReportData(dataURL) {
    this.service.getSummaryReport(dataURL);
    this.data$ = this.service.allData$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }
  export(type, data): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-PartnerSummary', type);
  }
  private getExportData() {
    let selected: any;
    var _this = this;
    selected = [];
    this.service.report.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.report : selected;
    selected.push({
      BusinessDate:'Total',
      Day:'',
      OrderCount:0,
      HoldCount: 0,
      ComplimentaryCount:0,
      Complimentary: parseFloat(_this.service.sumComplimentary.toFixed(2)),
      OrderAverage: parseFloat(_this.service.sumOrderAverage.toFixed(2)),
      Discount: parseFloat(_this.service.sumDiscount.toFixed(2)),
      Void: parseFloat(_this.service.sumVoid.toFixed(2)),
      Hold: parseFloat(_this.service.sumHold.toFixed(2)),
      Refund: parseFloat(_this.service.sumRefund.toFixed(2)),
      Sales: parseFloat(_this.service.sumSales.toFixed(2)),
      GrandTotal: parseFloat(_this.service.sumGrandTotal.toFixed(2)),
      Tax: parseFloat(_this.service.sumTax.toFixed(2)),
      Cash: parseFloat(_this.service.sumCash.toFixed(2)),
      Card: parseFloat(_this.service.sumCard.toFixed(2)),
      Aggregator: parseFloat(_this.service.sumAggregator.toFixed(2)),
      Wallet:0
    });

    return selected;
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i?.forEach(e => e.selected = $event.target.checked));
  }
}
