import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransferreturnService {

  constructor(private http: HttpClient) {
  }


  getById(tsid, brandId) {
    return this.http.get<any[]>(`api/inventory/transfer/${tsid}/brand/${brandId}`);
  }
  getTransferDDL(brandID) {
    return this.http.get(`api/TransferReturn/GetTSDDL/${brandID}`);
  }

  insert(data) {
    debugger
    return this.http.post(`api/TransferReturn/`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));



  }
  update(data) {
    return this.http.put(`api/TransferReturn`, data)
      .pipe(map(res => {
        return res;
      }));
  }
  getReturnTransfer(id, brandID) {
    return this.http.get(`api/TransferReturn/${id}/brand/${brandID}`);
  }
}
