<main class="invoice">
  <div class="page">




    <div class="row">
      <div class="col-md-12" style="padding-right:1px">
        <img [src]="selectedBrand?.BrandLogo" style="width:50px" />
      </div>
      <div class="col-md-12 text-right" style="padding-left:1px; font-size:20px">
        {{selectedBrand.BrandName}}
      </div>
    </div>




    <div class="page-body">
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="row text-center" style="background-color: #ebedee !important">
          <div class="col-lg-12">
            {{selectedReport.locations}}
          </div>
          <div class="col-lg-12">
            {{selectedReport.dateRange}}
          </div>
          <div class="col-lg-12">
            Generated By: {{selectedReport.generatedBy}}
          </div>
          <div class="col-md-12">
            {{selectedReport.reportName}}
          </div>
          <div class="col-md-12">
            Generated On: {{selectedReport.generatedOn | date:'MM dd, yyyy'}}
          </div>
        </div>
        
        <div class="tile-body p-0">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="20%">TRANSACTION</th>
                <th>PARTNER</th>
                <th>ORDER NO</th>
                <th>ORDER REFERENCE</th>
                <th>PRICE</th>
                <th sortable="Cost">COST  </th>
                <th sortable="DiscountAmount">DISCOUNT  </th>
                <th sortable="RefundAmount">REFUND  </th>
                <th>TAX</th>
                <th>GRAND</th>
                <th>PROFIT</th>
                <th>LOCATION  </th>
                <th>TIME  </th>
                <th sortable="CheckoutBy">USER  </th>
                <th>PAT TYPE  </th>
                <th>ORDER TYPE  </th>
              </tr>
            </thead>
            <tbody *ngFor="let item of salesDetails; let i = index">
              <tr>
                <td>
                  {{(item.DeviceTransactionNo)? item.DeviceTransactionNo : item.OfflineUniqueID}}
                </td>
                <td> {{item.PartnerName }} </td>
                <td> {{item.OrderNo }} </td>
                <td> {{item.OrderRefrenceID }} </td>
                <td> {{item.TotalAmount }} </td>
                <td>  {{item.Cost}} </td>
                <td> {{item.DiscountAmount }} </td>
                <td> {{item.RefundAmount }} </td>
                <td>  {{item.TaxAmount}} </td>
                <td> {{item.GrandTotal }} </td>
                <td> {{item.GrandTotal-item.Cost-item.TaxAmount | number:'1.2-2' }} </td>
                <td> {{item.Location }}</td>
                <td>  {{item.CheckoutDate | date:'HH:MM | dd/MM/yyyy' }} </td>
                <td style="color: #2d8c9f !important;"> <strong>{{item.CheckoutBy }} </strong></td>
                <td> {{item.PaymentMode }} </td>
                <td>
                  <span class="btn btn-sm w-auto grey">{{item.OrderType}}</span>
                </td>
              </tr>
              <tr *ngIf="item.Details.length > 0">
                <td colspan="3">
                  <ul>
                    <li *ngFor="let order of item.Details">
                      <p>{{order.Name}}</p>
                      <p> {{'QTY' | translate }} : {{order.Quantity}} &nbsp; {{'AMT' | translate }} : {{order.PriceWithVAT}}</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
          <div class="col-lg-12" style="margin-top:1%">
            Powered By marn.com
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
