import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import * as XLSX from 'xlsx';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IngredientService } from '../../../_services/ingredient.service';

@Component({
  selector: 'app-import-ingredients',
  templateUrl: './import-ingredients.component.html',
  styleUrl: './import-ingredients.component.css'
})
export class ImportIngredientsComponent {

  SaveButtonText = "Continue";
  showFileUpload = true;
  showFileOptions = false;
  showError = false;
  loading = false;
  showTemplate = true;
  partialErrorMessage: any = null;
  selectedLang = 'en';
  FileOptionList: Array<any> = [
    { value: "Name", name: 'Name' },
    { value: "Group", name: 'Group' },
    { value: "Unit", name: 'Unit' },
    { value: "Cost", name: 'Cost' },
    { value: "HasLife", name: 'Has Life Period (yes or no)' },
    { value: "Life", name: 'Life Period In Days' },
    { value: "Suppliers", name: 'Suppliers (seperated by ",")' },
    { value: "SKU", name: 'SKU' }
  ];
  ingredientsUploadForm: UntypedFormGroup;
  errorList: any[];
  fileData: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private excelService: ExcelService,
    private ingredientService: IngredientService,
    private localStorageService: LocalStorageService) {
    this.createForm();
  }
  get f() { return this.ingredientsUploadForm.controls; }

  private createForm() {
    this.ingredientsUploadForm = this.formBuilder.group({
      name: ['Name', Validators.required],
      group: ['Group', Validators.required],
      unit: ['Unit', Validators.required],
      cost: ['Cost', Validators.required],
      hasLife: ['HasLife', Validators.required],
      life: ['Life', Validators.required],
      suppliers: ['Suppliers', Validators.required],
      sku: ['SKU', Validators.required]
    });
  }
  ngOnInit() {
    this.selectedLang = this.localStorageService.getSelectedLanguage();
  }
  close() {
    this.activeModal.dismiss();
  }
  async onFileChange(ev) {
    let file = ev.target.files[0];
    //this.fileData = await this.excelService.ReadExcelFile(file);

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const _file = file;
    if (ev.target.files[0].size <= 5000000) {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary', raw: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet, { defval: "" });
          let format = this.validateFileHeaders(initial);
          if (format === true) {
            //this.fileData = initial;
            //this.setKeys(initial);

            this.fileData = [];
            initial.forEach(e => {

              e.Name = e['Name'];
              e.Group = e['Group'];
              e.Unit = e['Unit'];
              e.Cost = e['Cost'];
              e.HasLife = e['Has Life Period (yes or no)'];
              e.Life = e['Life Period In Days'];
              e.Suppliers = e['Suppliers (seperated by ",")'];
              e.SKU = e['SKU'];
              this.fileData.push(e);
            });

          }
          else {
            this.alertService.error("Invalid or empty file");
          }
        }, {});
      }
      reader.readAsBinaryString(_file);
    }
    else {
      this.alertService.error("File size is larger than 5 MB.");
    }
  }
  validateFileHeaders(sheet: any) {
    let sheetFormat = true;
    if (sheet && sheet.length > 0) {
      if (this.getKey(sheet[0], 'Name') !== 'Name')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Group') !== 'Group')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Unit') !== 'Unit')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Cost') !== 'Cost')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Has Life Period (yes or no)') !== 'Has Life Period (yes or no)')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Life Period In Days') !== 'Life Period In Days')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Suppliers (seperated by ",")') !== 'Suppliers (seperated by ",")')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'SKU') !== 'SKU')
        sheetFormat = false;
    }
    else {
      sheetFormat = false;
      this.alertService.error("Invalid or empty file");
    }
    return sheetFormat;

  }

  checkValidations(item) {
    item.HasDuplication = false;
    if (this.fileData.length > 0 && this.fileData?.filter(a => a.Name.trim() === item.Name.trim() || a.SKU.toString().trim() === item.SKU.toString().trim()).length > 1) {
      item.HasDuplication = true;
    }
    return item;
  }

  download() {
    var data =
      [
        {
          'Name': '', 'Group': '', 'Unit': '', 'Cost': '', 'Has Life Period (yes or no)': '', 'Life Period In Days': '', 'Suppliers (seperated by ",")': '', 'SKU': ''
        }
      ];
    this.excelService.exportAsExcelFile(data, 'IngredientsTemplate', 'xlsx');
  }
  getKey(item, formValue) {
    let key = '';
    Object.keys(item).forEach((val) => {
      if (formValue === val)
        key = val;
    });
    return key;
  }
  setKeys(obj) {
    if (obj.length > 0) {
      let keys = Object.keys(obj[0]);
      let fileOption = [];
      keys.filter(function (item) {
        let data = { value: item, name: item };
        fileOption.push(data);
      });
    }
  }

  ValidateSheet(sheet) {
    let sheetFormat = true;
    let _this = this;
    sheet.filter(function (item) {
      if (Object.keys(item).length < 8)
        sheetFormat = false;
      if (item[Object.keys(item)[0]] === null || item[Object.keys(item)[0]] === undefined || item[Object.keys(item)[0]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[1]] === null || item[Object.keys(item)[1]] === undefined || item[Object.keys(item)[1]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[2]] === null || item[Object.keys(item)[2]] === undefined || item[Object.keys(item)[2]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[3]] === null || item[Object.keys(item)[3]] === undefined || item[Object.keys(item)[3]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[4]] === null || item[Object.keys(item)[4]] === undefined || item[Object.keys(item)[4]] === '')
        sheetFormat = false;
      if ((item[Object.keys(item)[4]]?.toLowerCase() === 'yes') && (item[Object.keys(item)[5]] === null || item[Object.keys(item)[5]] === undefined || item[Object.keys(item)[5]] === ''))
        sheetFormat = false;
      if (item[Object.keys(item)[6]] === null || item[Object.keys(item)[6]] === undefined || item[Object.keys(item)[6]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[7]] === null || item[Object.keys(item)[7]] === undefined || item[Object.keys(item)[7]] === '')
        sheetFormat = false;
      if (_this.checkValidations(item).HasDuplication)
        sheetFormat = false;
    });
    return sheetFormat;
  }

  onSubmit() {
    this.loading = true;
    if (this.SaveButtonText === "Continue") {
      if (this.fileData !== null && this.fileData !== undefined) {
        if (this.ValidateSheet(this.fileData)) {
          this.showFileUpload = false;
          this.showTemplate = false;
          this.showFileOptions = true;
          this.SaveButtonText = "Upload";
          this.loading = false;
        }
        else {
          this.loading = false;
          this.alertService.error("Invalid/Duplication found");
        }
      }
      else {
        this.showFileUpload = true;
        this.showTemplate = true;
        this.showFileOptions = false;
        this.alertService.error("File not found or invalid file");
        this.loading = false;
      }
    }
    else {

      let name = this.f.name.value === this.f.group.value || this.f.name.value === this.f.unit.value || this.f.name.value === this.f.cost.value || this.f.name.value === this.f.hasLife.value || this.f.name.value === this.f.life.value || this.f.name.value === this.f.suppliers.value || this.f.name.value === this.f.sku.value;
      let group = this.f.group.value === this.f.name.value || this.f.group.value === this.f.unit.value || this.f.group.value === this.f.cost.value || this.f.group.value === this.f.hasLife.value || this.f.group.value === this.f.life.value || this.f.group.value === this.f.suppliers.value || this.f.group.value === this.f.unit.value;
      let unit = this.f.unit.value === this.f.group.value || this.f.unit.value === this.f.name.value || this.f.unit.value === this.f.cost.value || this.f.unit.value === this.f.hasLife.value || this.f.unit.value === this.f.life.value || this.f.unit.value === this.f.suppliers.value;
      let cost = this.f.cost.value === this.f.group.value || this.f.cost.value === this.f.unit.value || this.f.cost.value === this.f.name.value || this.f.cost.value === this.f.hasLife.value || this.f.cost.value === this.f.life.value || this.f.cost.value === this.f.suppliers.value || this.f.cost.value === this.f.unit.value;
      let hasLife = this.f.hasLife.value === this.f.group.value || this.f.hasLife.value === this.f.unit.value || this.f.hasLife.value === this.f.cost.value || this.f.hasLife.value === this.f.name.value || this.f.hasLife.value === this.f.life.value || this.f.hasLife.value === this.f.suppliers.value || this.f.hasLife.value === this.f.unit.value;
      let life = this.f.life.value === this.f.group.value || this.f.life.value === this.f.unit.value || this.f.life.value === this.f.cost.value || this.f.life.value === this.f.hasLife.value || this.f.life.value === this.f.name.value || this.f.life.value === this.f.suppliers.value || this.f.life.value === this.f.unit.value;
      let suppliers = this.f.suppliers.value === this.f.group.value || this.f.suppliers.value === this.f.unit.value || this.f.suppliers.value === this.f.cost.value || this.f.suppliers.value === this.f.hasLife.value || this.f.suppliers.value === this.f.life.value || this.f.suppliers.value === this.f.name.value || this.f.suppliers.value === this.f.unit.value;
      let sku = this.f.sku.value === this.f.group.value || this.f.sku.value === this.f.unit.value || this.f.sku.value === this.f.cost.value || this.f.sku.value === this.f.hasLife.value || this.f.sku.value === this.f.life.value || this.f.sku.value === this.f.suppliers.value || this.f.sku.value === this.f.name.value;

      if (name || group || unit || cost || hasLife || life || suppliers || sku) {
        this.ingredientsUploadForm.setErrors({ 'invalid': true });
      }

      this.ingredientsUploadForm.markAllAsTouched();
      this.alertService.clear();
      if (this.ingredientsUploadForm.invalid) {
        this.loading = false;
        return;
      }
      this.loading = true;
      let uploadData = [];
      let brandID = this.localStorageService.getSelectedBrand().BrandID;
      let fc = this.f;
      let _this = this;
      let funcKey = this.getKey;
      this.fileData.filter(function (item) {
        var data =
        {

          Name: item[funcKey(item, fc.name.value)].toString().trim(),
          Group: item[funcKey(item, fc.group.value)].toString().trim(),
          Unit: item[funcKey(item, fc.unit.value)].toString().trim(),
          Cost: item[funcKey(item, fc.cost.value)],
          Life: item[funcKey(item, fc.hasLife.value)].toString().toLowerCase().trim() === 'yes' ? 1 : 0,
          LifePeriod: (item[funcKey(item, fc.life.value)]) ? item[funcKey(item, fc.life.value)] : null,
          SupplierIDs: item[funcKey(item, fc.suppliers.value)].toString().trim(),
          SKU: item[funcKey(item, fc.sku.value)].toString().trim(),
          BrandID: brandID
        };
        
        uploadData.push(data);
        return true;
      });
      this.ingredientService.uploadIngredients(uploadData).subscribe(data => {
        if (data != null) {
          if (data.result.status === 1) {
            this.alertService.success("Ingredients have been saved");
            this.activeModal.dismiss();
          } else {
            if (data.result.status === 3) {
              this.partialErrorMessage = data.partialSuccessMessages.split(',');
            }
            this.showFileUpload = false;
            this.showFileOptions = false;
            this.showError = true;
            this.errorList = data.messages;
          }
        }
        this.loading = false;
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }
}
