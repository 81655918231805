<div class="mnt-header position-relative order-list product-list">
  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-30" *ngIf="(service.inventoryCounts?.length > 0)">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Inventory Counts' | translate }} </h5>
    </div>
    <div class="col-md-auto" *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Create']">
      <button type="button" (click)="add()" class="btn btn-primary cutom-button custom-create-btn">
        {{'New Count' | translate }}
      </button>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Export']">

          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>



  <div *ngIf="(service.inventoryCounts?.length > 0)">
    <div class="position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped text-start">
            <thead>
              <tr class="table-header">
                <th width="5%" class="text-center">

                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>


                <th width="20%" sortable="icCode" (sort)="onSort($event)">
                  {{'Ref' | translate }}# <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%" sortable="createdOn" (sort)="onSort($event)">
                  {{'Date' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%">
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">

                    <button class="btn btn-outline-filter" id="ddllocationFilter" ngbDropdownToggle
                            [ngClass]="{ 'active': setSelectedResult('location') > 0}">
                      <span>
                        <span class="badge" *ngIf="setSelectedResult('location')>0">{{setSelectedResult('location')}}</span>

                        {{'Location' | translate}}

                      </span>
                      <img class="down-aero" ngbDropdownOpen src="{{(setSelectedResult('location') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'}}">
                    </button>

                    <div ngbDropdownMenu aria-labelledby="ddllocationFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdownlocation_all" (change)="selectAllFilters($event,LocationList)">
                          <label class="form-check-label font-blue" for="dropdownlocation_all" (click)="selectAllFilters($event,LocationList)">
                            {{'All Location' | translate}}
                          </label>
                        </div>
                      </div>

                      <div *ngFor="let location of LocationList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdownlocation_{{i+1}}" [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                          <label class="form-check-label" for="dropdownlocation_{{i+1}}" (click)="filters()">
                            {{location.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th width="20%">
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">

                    <button class="btn btn-outline-filter" id="ddlcreateFilter" ngbDropdownToggle
                            [ngClass]="{ 'active': setSelectedResult('createdBy') > 0}">
                      <span>
                        <span class="badge" *ngIf="setSelectedResult('createdBy')>0">{{setSelectedResult('createdBy')}}</span>

                        {{'User' | translate}}

                      </span>
                      <img class="down-aero" ngbDropdownOpen src="{{(setSelectedResult('createdBy') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'}}">
                    </button>

                    <div ngbDropdownMenu aria-labelledby="ddlcreateFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdowncreate_all" (change)="selectAllFilters($event,CreatedByList)">
                          <label class="form-check-label font-blue" for="dropdowncreate_all" (click)="selectAllFilters($event,CreatedByList)">
                            {{'All User' | translate}}
                          </label>
                        </div>
                      </div>

                      <div *ngFor="let create of CreatedByList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdowncreate_{{i+1}}" [(ngModel)]="create.isChecked" (ngModelChange)="filters()">
                          <label class="form-check-label" for="dropdowncreate_{{i+1}}" (click)="filters()">
                            {{create.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th width="20%" sortable="totalItems" (sort)="onSort($event)">
                  {{'Counted Items' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%" sortable="totalDifference" (sort)="onSort($event)">
                  {{'Total Difference' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <!-- <th width="15%" sortable="createdBy" (sort)="onSort($event)">CREATED BY <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th> -->
                <th width="15%">

                  <!-- Status Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                      <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                               name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                        <label class="form-check-label font-blue" for="dropdownStatus_all"
                               (click)="selectAllFilters($event,StatusList)">
                          {{'All Status' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let item of StatusList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                          <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>


                            <ngb-highlight [ngClass]="renderStatusClass(item.name)" class="btn btn-sm"
                                           [result]="(
                                          item.name ==600?'Created':
                                          item.name ==601?'Approved':
                                          item.name ==602?'Pending':
                                          item.name ==603?'Declined':
                                          item.name ==604?'Hold':
                                          item.name ==605?'Received':
                                          item.name ==606?'Returned':'-') | translate" [term]="service.searchTerm">
                            </ngb-highlight>

                          </label>

                        </div>
                      </div>
                    </div>
                  </div>

                </th>
                <th width="5%">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let inventoryCount of data$ | async " editRow>
                <td class="text-center">
                  <input type="checkbox" class="form-check-input" (change)="chkSelection($event,inventoryCount)" [(ngModel)]="inventoryCount.selected" />
                </td>

                <td (click)="Edit(inventoryCount)" class=" name-blue"> {{inventoryCount.icCode}} </td>
                <td (click)="Edit(inventoryCount)"> {{inventoryCount.createdOn | date}} </td>
                <td (click)="Edit(inventoryCount)"> {{inventoryCount.locationName}} </td>
                <td (click)="Edit(inventoryCount)"> {{inventoryCount.createdBy }} </td>
                <td (click)="Edit(inventoryCount)"> {{inventoryCount.totalItems }} </td>
                <td (click)="Edit(inventoryCount)"> {{inventoryCount.totalDifference | number : '1.2-2'}} </td>
                <td>
                  <ngb-highlight [ngClass]="renderStatusClass(inventoryCount.statusID)" class="btn btn-sm"
                                 [result]="(
                                          inventoryCount.statusID ==600?'Created':
                                          inventoryCount.statusID ==601?'Approved':
                                          inventoryCount.statusID ==602?'Pending':
                                          inventoryCount.statusID ==603?'Declined':
                                          inventoryCount.statusID ==604?'Hold':
                                          inventoryCount.statusID ==605?'Received':
                                          inventoryCount.statusID ==606?'Returned':'-') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                    <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                  </div>
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'Count' | translate }}</p>
                   
                    <a *hasPermission="['Inventory','Inventory Action','Inventory Count', 'Preview']" (click)="Edit(inventoryCount)">{{'Preview Count' | translate }}</a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                        [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst>
            <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
          </ng-template>
          <ng-template ngbPaginationLast>
            <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
          </ng-template>
          <ng-template ngbPaginationPrevious>
            <img src="../../../assets/images/icons/backward.svg" />
          </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

  </div>


  <div *ngIf="(service.inventoryCounts?.length == 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Inventory Count yet' | translate }}</strong>
      <p class="text-center width-375">{{'Create Inventory count to manage inventory.' | translate}}</p>
    </div>
    <div class="items-flex">
      <div class="create-btn mlmr-10">
        <div>
          <button type="button" (click)="add()" class="btn btn-primary cutom-button form-control custom-create-btn">
            {{'+ Create New Inventory Count' | translate }}
          </button>
        </div>
      </div>


    </div>
  </div>

  <div *ngIf="(service.inventoryCounts?.length > 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Record Found' | translate }}</strong>
      <p class="text-center width-375">{{'Create Inventory count to manage inventory.' | translate}}</p>
    </div>
  </div>


</div>
