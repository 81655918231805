


import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { IngredientService } from 'src/app/_services/ingredient.service';
import { GroupService } from 'src/app/_services/group.service';
import { CommonService } from 'src/app/_services/common.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-addingredient',
  templateUrl: './addingredient.component.html',
  styleUrls: ['./addingredient.component.css']
})
export class AddingredientComponent implements OnInit {
  @Input() name;
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  ingForm: UntypedFormGroup;
  loading = false;
  loadingIng = false;
  ButtonText = "Save";
  Title = "New Ingredient";
  Groups = [];
  Units = [];
  Period = [
    { lifePeriod: 'Days', id: 1 },
    { lifePeriod: 'Months', id: 2 },
    { lifePeriod: 'Year', id: 3 }
  ]

  selectedSuppliers = [];
  Suppliers = [];
  manualSKUChangeByUser = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private service: IngredientService,
    private commonService: CommonService,

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.loadGroup(parseInt(this.localStorageService.getSelectedBrand().BrandID));

    this.f.hasLifePeriod?.valueChanges.subscribe((hasLifePeriod: boolean) => {
      if (!hasLifePeriod) {
        this.f.life?.patchValue(0);
      }
    });
  }

  ngAfterViewInit() {

  }

  private loadGroup(brandId) {
    this.commonService.getInventoryGroup(brandId).subscribe((res: any) => {
      this.Groups = res;
      this.f.groupID.setValue(this.Groups[0].id);
      console.log('Groups', this.Groups)
      this.loadIngredientUnit();
    });
  }

  private loadIngredientUnit() {
    this.commonService.getIngredientUnit().subscribe((res: any) => {
      this.Units = res;
      this.f.unitID.setValue(this.Units[0].unitID);
      console.log('Units', this.Units);
      this.loadSupplier(parseInt(this.localStorageService.getSelectedBrand().BrandID));
    });
  }

  private loadSupplier(brandId) {
    this.commonService.getSupplier(brandId).subscribe((res: any) => {
      this.Suppliers = res;
      console.log('Suppliers', this.Suppliers);
      this.setSelectedIngredient();
    });
  }

  private getInventorySKU(brandId) {
    this.loading = true;
    this.commonService.getInventorySKU(brandId).subscribe((res: any) => {
      this.f.sku.setValue(res.sku);
      this.loading = false;
    });
  }

  GenInventorySKU() {
    if (this.enableSKU) {
      this.manualSKUChangeByUser = false;
      this.getInventorySKU(parseInt(this.localStorageService.getSelectedBrand().BrandID));
    }
  }

  private createForm() {
    this.ingForm = this.formBuilder.group({
      name: ['', Validators.required],
      statusID: [1, Validators.required],
      groupID: [0, Validators.required],
      unitID: [0, Validators.required],
      cost: [0.0, Validators.required],
      lifePeriod: [1, Validators.required],
      life: [0],
      supplierIDs: [[], Validators.required],
      sku: ['', Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      ingredientID: 0,
      rowVersion: 0,
      hasLifePeriod: false
    });
  }


  get f() { return this.ingForm.controls; }
  get enableSKU() {
    if (!this.f.sku.value || this.f.sku.value === '' || this.manualSKUChangeByUser) {
      return true;

    }
    else {
      return false;
    }
  }


  setSelectedIngredient() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingIng = true;
      this.f.ingredientID.setValue(this.SelectedID);
      this.service.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {

        this.Title = "Edit Ingredient";
        //Set Forms
        this.editForm(res);
        this.loadingIng = false;
      });
    }
    else {
      this.GenInventorySKU();
    }
  }


  private editForm(ingredient) {
    console.log(ingredient);
    this.f.ingredientID.setValue(ingredient.ingredientID);
    this.f.name.setValue(ingredient.name);
    this.f.statusID.setValue(ingredient.statusID === 1 ? true : false);
    this.f.brandID.setValue(ingredient.brandID);

    this.f.groupID.setValue(ingredient.groupID);
    this.f.unitID.setValue(ingredient.unitID);
    this.f.cost.setValue(ingredient.cost);
    this.f.lifePeriod.setValue(ingredient.lifePeriod);
    this.f.life.setValue(ingredient.life);
    this.f.sku.setValue(ingredient.sku);
    this.f.rowVersion.setValue(ingredient.rowVersion);
    this.f.supplierIDs.setValue(ingredient.supplierIDs);
    this.selectedSuppliers = ingredient.supplierIDs.split(',').map(i => Number(i));
    this.f.hasLifePeriod.setValue(ingredient.hasLifePeriod);
    console.log(this.selectedSuppliers);
  }


  close() {
    this.activeModal.dismiss();
  }



  onSubmit() {
    this.ingForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.statusID.setValue(1);
    //this.f.cost.setValue((this.f.cost.value));

    this.f.supplierIDs.setValue(this.selectedSuppliers.toString());

    //this.f.life.setValue((this.f.hasLifePeriod.value) ? 1 : 0);

    if (this.ingForm.invalid) { return; }
    this.loading = true;

    if (parseInt(this.f.ingredientID.value) === 0) {

      //Insert Location
      console.log(JSON.stringify(this.ingForm.value));
      this.service.insert(this.ingForm.value).subscribe(data => {
        this.alertService.success("Ingredient has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update Location
      this.service.update(this.ingForm.value).subscribe(data => {
        this.alertService.success("Ingredient has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }



}
