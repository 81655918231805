import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { switchMap, tap, map } from 'rxjs/operators';
import { LocationItem } from '../_models/LocationItem';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';

interface SearchLocationItemsResult {
  data: LocationItem[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: LocationItem[], column: SortColumn, direction: string): LocationItem[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: LocationItem, term: string) {

  return data.category.toLowerCase().includes(term.toLowerCase())
    || data.alternateCategoryName.toLowerCase().includes(term.toLowerCase())
    || data.subCategory.toLowerCase().includes(term.toLowerCase())
    || data.alternateSubCategoryName.toLowerCase().includes(term.toLowerCase())
    || data.name.toLowerCase().includes(term.toLowerCase())
    || data.nameOnReceipt.toLowerCase().includes(term.toLowerCase())
    || data.barcode.toLowerCase().includes(term.toLowerCase());
  //|| pipe.transform(location.email).includes(term)


}
function matcheHeader(data: LocationItem, filterValues: LocationItemHeaderState) {
  let subCategory = false;
  let partnerStatus = false;


  if (filterValues.subCategory !== null && filterValues.subCategory.length > 0) {
    for (var i = 0, len = filterValues.subCategory.length; i < len; i++) {
      if (data.subCategory.toLowerCase().toString() === filterValues.subCategory[i].toLowerCase() || filterValues.subCategory[i].toString() === '') {
        subCategory = true;
      }
      if (data.alternateSubCategoryName.toLowerCase().toString() === filterValues.subCategory[i].toLowerCase() || filterValues.subCategory[i].toString() === '') {
        subCategory = true;
      }
    }
  }
  else {
    subCategory = true;
  }

  if (filterValues.partnerStatusID !== null && filterValues.partnerStatusID.length > 0) {
    for (var i = 0, len = filterValues.partnerStatusID.length; i < len; i++) {
      if (data.partnerStatusID.toString() === filterValues.partnerStatusID[i].toString().toLowerCase() || filterValues.partnerStatusID[i].toString() === '') {
        partnerStatus = true;
      }
    }
  }
  else {
    partnerStatus = true;
  }
  if (subCategory && partnerStatus) {
    return data;
  }
}

interface LocationItemHeaderState {
  subCategory: string[],
  partnerStatusID: string[]
}

@Injectable({
  providedIn: 'root'
})
export class LocationItemService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _searchHeader$ = new Subject<void>();
  public _allData$ = new BehaviorSubject<LocationItem[]>([]);
  private _data$ = new BehaviorSubject<LocationItem[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _checkAll$ = new BehaviorSubject<boolean>(false);
  public locationItems: LocationItem[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  private _headerState: LocationItemHeaderState = {
    subCategory: null,
    partnerStatusID: null
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get checkAll() { return this._checkAll$.asObservable(); }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: LocationItemHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getLocationItems(brandId, locationId) {
    const url = `api/LocationItem/${brandId}/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<LocationItem[]>(url).subscribe(res => {
        this.locationItems = res;
        this._data$.next(this.locationItems);
        this._allData$.next(this.locationItems);
        var selected = this.locationItems.find(e => e.selected === false)?.selected;
        if (selected || selected! === false)
          this._checkAll$.next(false);
        else
          this._checkAll$.next(true);

        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._searchHeader$.next();
        this._search$.next();


      });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _setHeader(headerFilter: LocationItemHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }

  private _search(): Observable<SearchLocationItemsResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.locationItems, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  private _searchByHeader(): Observable<SearchLocationItemsResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.locationItems, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  public saveItems(locationId: number, items: LocationItem[]) {
    return this.http.post(`api/LocationItem/${locationId}`, items);
  }
  public saveAllItems(locationId: number, items: LocationItem[]) {
    return this.http.post(`api/LocationItem/SaveAllLocationItems/${locationId}`, items);
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
  markAsChecked(checked: boolean) {
    this.locationItems.forEach(e => {
      e.selected = checked;
      switch (checked) {
        case true:
          if (e.partnerStatusID === 15)
            e.partnerStatusID = 15;
          else
            e.partnerStatusID = 1;
          e.statusID = 1;
          break;

        default:
          e.partnerStatusID = 2;
          e.statusID = 2;
          break;
      }
    });
    this._allData$.next(this.locationItems);
  }
}

