import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { PrintService } from 'src/app/_services/print.service';
import { ReconcillationService } from 'src/app/_services/reconcillation.service';

@Component({
  selector: 'app-print-reconciliation',
  templateUrl: './print-reconciliation.component.html',
  styleUrls: ['./print-reconciliation.component.css']
})
export class PrintReconciliationComponent implements OnInit {


  rcnid: number;
  Reconciliation : any = [];
  selectedBrand:any;
  constructor(private route: ActivatedRoute, private localStorageService:LocalStorageService,
    private printService: PrintService,private service:ReconcillationService,) {
     this.rcnid = parseInt(this.route.snapshot.params["rcnid"]);
     this.selectedBrand = this.localStorageService.getSelectedBrand();
  }

  ngOnInit() {

    this.rcnid = parseInt(this.route.snapshot.params["rcnid"]);
    this.service.getById(this.rcnid,  parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe(res => {
    this.Reconciliation = res;
    this.Reconciliation.locationName =this.localStorageService.getSelectedBrand().Locations.filter(l=>l.LocationID == this.Reconciliation.locationID)[0].Name;
     this.printService.onDataReady();
    });

    
  }




}
















// import { Component, OnInit, Input, ViewChild } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
// import { LocalStorageService } from 'src/app/_services/local-storage.service';
// import { AlertService } from 'src/app/_services/alert.service';

// import { UnitService } from 'src/app/_services/unit.service';

// import { NgbdDatepickerAdapter } from 'src/app/datepicker/datepicker-adapter';
// import { ReconcillationService } from '../../../_services/reconcillation.service';
// import { rcnDetails } from '../../../_models/Reconcillation';


// @Component({
//   selector: 'app-addreconciliation',
//   templateUrl: './addreconciliation.component.html',
//   styleUrls: ['./addreconciliation.component.css']
// })
// export class AddreconciliationComponent implements OnInit {
//   @Input() SelectedID = 0;
//   submitted = false;
//   conversionBoxOpen = false;
//   reconcillationForm: FormGroup;
//   loading = false;
//   loadingReconcillation = false;
//   ButtonText = "Save";
//   Locations = [];
//   Suppliers = [];
//   Items = [];
//   Units = [];
//   Reasons = [];
//   taxPercent = 15;
//   arrItems: rcnDetails[] = [];

//   @ViewChild(NgbdDatepickerAdapter, { static: true }) dateComp;
//   public rcnDetails = new rcnDetails();
//   constructor(public activeModal: NgbActiveModal,
//     private formBuilder: FormBuilder,
//     private ls: LocalStorageService,
//     private alertService: AlertService,
//     private unitService: UnitService,
//     private reconcillationService: ReconcillationService

//   ) {
//     this.createForm();
//   }

//   ngOnInit() {
//     debugger
//     this.setSelectedReconcillation();

//     this.loadLocations();
//     this.loadUnits();
//     this.refreshDetailObj();
//     this.loadReasons();
//     debugger
//     if (this.f.rcnid.value === 0)
//       this.generateCode();
//   }
//   get f() { return this.reconcillationForm.controls; }

//   private createForm() {

//     this.reconcillationForm = this.formBuilder.group({
//       rcnCode: ['',Validators.required],
//       referenceNo: ['', Validators.required],
//       statusID: [1, Validators.required],
//       brandID: parseInt(this.ls.getSelectedBrand().BrandID),
//       rcnid: 0,
//       date: [],
//       locationID: 0,
//       rowVersion: 0,
//       rcnDetails: [],
//       taxes: []
//     });
//   }

//   private editForm(reconcillation) {

//     this.f.rcnCode.setValue(reconcillation.rcnCode);
//     this.f.date.setValue(reconcillation.date);
//     this.f.referenceNo.setValue(reconcillation.referenceNo);

//     this.f.rcnDetails.setValue(reconcillation.rcnDetails);
//     this.arrItems = reconcillation.rcnDetails;

//     this.f.rowVersion.setValue(reconcillation.rowVersion);
//     this.f.statusID.setValue(reconcillation.statusID);
//     this.f.rcnid.setValue(reconcillation.rcnid);
//     this.f.locationID.setValue(reconcillation.locationID);




//   }

//   setSelectedReconcillation() {

//     if (this.SelectedID !== 0) {
//       this.ButtonText = "Update";
//       this.loadingReconcillation = true;
//       this.f.rcnid.setValue(this.SelectedID);
//       this.reconcillationService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
//         //Set Forms
//         this.editForm(res);
//         this.loadingReconcillation = false;
//       });
//     }
//   }

//   onSubmit() {

//     this.reconcillationForm.markAllAsTouched();
//     this.submitted = true;
//     // reset alerts on submits
//     this.alertService.clear();

//     if (this.arrItems.length == 0) {
//       this.alertService.error("Items cannot be empty.");
//       return;
//     }
//     if (this.reconcillationForm.invalid) { return; }
//     this.loading = true;

//     this.f.statusID.setValue(this.f.statusID.value);
    
//     this.f.date.setValue(new Date());
//     this.f.rcnDetails.setValue(this.arrItems);

//     if (parseInt(this.f.rcnid.value) === 0) {

//       //Insert reconcillation
//       this.reconcillationService.insert(this.reconcillationForm.value).subscribe(data => {
//         this.alertService.success("Reconcillation has been created");
//         this.loading = false;
//         this.activeModal.dismiss();
//       }, error => {
//         this.alertService.error(error);
//         this.loading = false;
//       });

//     } else {
//       //Update reconcillation
//       this.reconcillationService.update(this.reconcillationForm.value).subscribe(data => {
//         this.alertService.success("Reconcillation has been updated");
//         this.loading = false;
//         this.SelectedID = 0;
//         this.activeModal.dismiss();
//       }, error => {
//         this.alertService.error(error);
//         this.loading = false;
//         this.activeModal.dismiss();
//       });
//     }
//   }
//   //loadSuppliers() {
//   //  this.reconcillationService.getSuppliers(this.f.brandID.value).subscribe((res: any) => {
//   //    this.Suppliers = res;
//   //    if (this.Suppliers.length > 0) {

//   //      if (this.f.rcnid.value == 0)
//   //        this.f.supplierID.setValue(res[0].supplierID);

//   //      this.loadItems(this.f.locationID.value, this.f.supplierID.value);
//   //    }
//   //  });
//   //}

//   loadLocations() {
//     this.reconcillationService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
//       this.Locations = res;
//       if (this.Locations.length > 0) {

//         if (this.f.rcnid.value == 0)
//           this.f.locationID.setValue(res[0].locationID);

//         //this.loadSuppliers();
//         this.loadItems(this.f.locationID.value);
       
//       }
//     });
//   }

//   loadItems(locationID) {
//     this.reconcillationService.getItems(locationID).subscribe((res: any) => {
//       this.Items = res;
  
//     });
//   }

//   loadUnits() {
//     this.unitService.getUnits().subscribe((res: any) => {
//       this.Units = res;
//       //this.f.unitID.setValue(res[0].unitID);
//     });
//   }

//   loadReasons() {
//     this.Reasons = [
//       { reasonID: 1, name: "Damaged" },
//       { reasonID: 2, name: "Wastage" },
//       { reasonID: 3, name: "Others" }
//     ];


//     this.rcnDetails.reason = "Damaged";
//   }


//   close() {
//     this.activeModal.dismiss();
//   }
//   selectedInvItem(obj) {
//     console.log(obj);
//     this.rcnDetails.type = obj.type;
//     this.rcnDetails.name = obj.name;
//     this.rcnDetails.cost = obj.cost;
//     var ele = this.Units.find(element => element.unit == obj.unit);
//     if (ele) {
//       this.rcnDetails.unitID = ele.unitID;
//     }
//   }


//   selectedReason(obj) {
//     this.rcnDetails.reason = obj.name
//   }
//   deleteRow(obj) {

//     const index = this.arrItems.indexOf(obj);
//     this.arrItems.splice(index, 1);

//   }

//   addRow(obj) {

//     console.log(obj);

//     if (obj.name != undefined && obj.name != '' && obj.name != null && obj.quantity > 0) {
//       if (!this.arrItems.find(element => element.supplyID == obj.supplyID)) {
//         this.arrItems.push({
//           supplyID: obj.supplyID,
//           cost: obj.cost,
//           name: obj.name,
//           type: obj.type,
//           statusID: 600,
//           quantity: (obj.quantity == null ? 1 : obj.quantity),
//           unitID: obj.unitID,
//           reasonID: obj.reasonID,
//           reason: obj.reason
//         });
//       }
//       else {
//         alert("Item already added in list")
//       }
//     }
//     else {
//       if (obj.name == undefined || obj.name == '' || obj.name == null) {
//         this.alertService.error("Please select item.");
//         return;
//       }
//       else if (obj.quantity <= 0) {
//         this.alertService.error("Item quantity must be greater than 0.");
//         return;
//       }
    
//     }
//   }



//   UpdateQty(newItem) {
//     if (newItem.cost > 0) {
//       let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.Cost);
//       let index = this.arrItems.indexOf(updateItem);

//       this.arrItems[index] = newItem;

//     }
//     if (newItem.cost <= 0) {
//       this.alertService.error("Item rate must be greater than 0.");
//       newItem.cost = newItem.cost * -1;
//       return;
//     }
//   }

//   findIndexToUpdate(newItem) {
//     return newItem.cost === this;
//   }

//   generateCode() {
//     debugger
//     this.reconcillationService.generateRcnNumber(this.f.brandID.value).subscribe((res: any) => {
//       debugger
//       this.f.rcnCode.setValue(res.code);
//     });
//   }

//   refreshDetailObj() {
//     this.rcnDetails.cost = 0;
//     this.rcnDetails.quantity = 1;
//   }
//   isApplyTax(obj) {
//     if (obj.target.checked == true) {
//       this.taxPercent = 15;
//     }
//     else {
//       this.taxPercent = 0;
//     }
   
//   }

//   customSearchFn(term: string, item: any) {
//     term = term.toLowerCase();

//     // Creating and array of space saperated term and removinf the empty values using filter
//     let splitTerm = term.split(' ').filter(t => t);

//     let isWordThere = [];

//     // Pushing True/False if match is found
//     splitTerm.forEach(arr_term => {
//       const search = item['name'].toLowerCase();
//       isWordThere.push(search.indexOf(arr_term) !== -1);
//     });

//     // Pushing True/False if match is found
//     splitTerm.forEach(arr_term => {
//       const search = item['SKU'].toLowerCase();
//       isWordThere.push(search.indexOf(arr_term) !== -1);
//     });


//     const all_words = (this_word) => this_word;
//     // Every method will return true if all values are true in isWordThere.
//     return isWordThere.every(all_words);
//   }

//   SearchItem(term: string, item: any) {
//     term = term.toLocaleLowerCase();
//     return item.barcode.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.sku.toLocaleLowerCase().indexOf(term) > -1;
//   }
// }
