import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { poDetails, PurchaseOrder, taxes } from 'src/app/_models/Purchaseorder';
import { AddpurchaseorderComponent } from '../addpurchaseorder/addpurchaseorder.component';
import { Observable } from 'rxjs';
import * as myGlobals from '../../../_helpers/globals';


@Component({
  selector: 'app-previewpurchaseorder',
  templateUrl: './previewpurchaseorder.component.html',
  styleUrls: ['./previewpurchaseorder.component.css']
})
export class PreviewpurchaseorderComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  data$: Observable<PurchaseOrder[]>;
  oldData: PurchaseOrder[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  previewpurchaseorderForm: UntypedFormGroup;
  loading = false;
  loadingPurchaseorder = false;
  isShowActions = true;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  arrItems: poDetails[] = [];
  arrtaxes: taxes[] = [];
  selectedLang = 'en';
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public ls: LocalStorageService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private purchaseorderService: PurchaseorderService
  ) {
 
    this.createForm();
    this.selectedBrand = this.ls.getSelectedBrand().BrandID;
    this.selectedLang = this.ls.getSelectedLanguage();
  }

  ngOnInit() {
    this.setSelectedPurchaseorder();
  }
  get f() { return this.previewpurchaseorderForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }

  private createForm() {

    this.previewpurchaseorderForm = this.formBuilder.group({
      poCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      poid: 0,
      supplierID: 0,
      supplierName: [''],
      supplierContact: [''],
      supplierAddress: [''],
      subTotal: 0,
      taxAmount: 0,
      grandTotal: 0,
      expectedDate: [],
      date: [],
      locationID: 0,
      locationName: [''],
      locationContact: [''],
      locationAddress: [''],
      rowVersion: 0,
      poDetails: [],
      taxes: []
    });
  }

  private editForm(purchaseorder) {

    this.f.poCode.setValue(purchaseorder.poCode);
    this.f.date.setValue(purchaseorder.date);
    this.f.expectedDate.setValue(purchaseorder.expectedDate);
    this.f.referenceNo.setValue(purchaseorder.referenceNo);
    this.f.poDetails.setValue(purchaseorder.poDetails);
    this.arrItems = purchaseorder.poDetails;

    this.f.subTotal.setValue(purchaseorder.subTotal);
    this.f.taxAmount.setValue(purchaseorder.taxAmount);
    this.f.grandTotal.setValue(purchaseorder.grandTotal);

    this.f.notes.setValue(purchaseorder.notes);
    this.f.rowVersion.setValue(purchaseorder.rowVersion);
    this.f.statusID.setValue(purchaseorder.statusID);
    this.f.poid.setValue(purchaseorder.poid);

    this.loadLocation(purchaseorder.locationID);
    this.loadSupplier(purchaseorder.supplierID);

    if (this.f.statusID.value == 605 || this.f.statusID.value == 601 || this.f.statusID.value == 606) {
      this.isShowActions = false;
    }
  }

  setSelectedPurchaseorder() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingPurchaseorder = true;
      this.f.poid.setValue(this.SelectedID);
      this.purchaseorderService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingPurchaseorder = false;
      });
    }
  }

  Edit(purchaseorder) {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(AddpurchaseorderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = purchaseorder.poid.value;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  getBrandData() {
    this.purchaseorderService.getPurchaseorders(this.selectedBrand);
    this.data$ = this.purchaseorderService.data$;

    this.total$ = this.purchaseorderService.total$;
    this.loading$ = this.purchaseorderService.loading$;

  }
  onSubmit(obj) {

    if (this.f.statusID.value == 601) {
      this.Approve(obj);
    }
    else if (this.f.statusID.value == 603) {
      this.Decline(obj);
    }
    else if (this.f.statusID.value == 604) {
      this.Hold(obj);
    }
  }
  loadSupplier(id) {
    this.purchaseorderService.getSupplierById(id).subscribe((res: any) => {
      this.f.supplierID.setValue(res.supplierID);
      this.f.supplierName.setValue(res.name);
      this.f.supplierAddress.setValue(res.email);
      this.f.supplierContact.setValue(res.contactNo);
    });
  }
  loadLocation(id) {
    this.purchaseorderService.getLocationsById(id).subscribe((res: any) => {
      this.f.locationID.setValue(res.locationID);
      this.f.locationName.setValue(res.name);
      this.f.locationAddress.setValue(res.address);
      this.f.locationContact.setValue(res.contactNo);
    });
  }

  close() {
    this.activeModal.dismiss();
  }
  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"
    }
  }

  selectedStatus(status) {

    if (status == "approve") {
      this.f.statusID.setValue(601);
    }
    else if (status == "hold") {
      this.f.statusID.setValue(604);
    }
    else if (status == "decline") {
      this.f.statusID.setValue(603);
    }
  }

  Approve(obj) {
    this.purchaseorderService.approve(obj.brandID.value, obj.poid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Purchaseorder has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }

  Hold(obj) {
    this.purchaseorderService.hold(obj.brandID.value, obj.poid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Purchaseorder has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }

  Decline(obj) {
    this.purchaseorderService.decline(obj.brandID.value, obj.poid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Purchaseorder has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }

}
