<form [formGroup]="JahezForm" class="form jahezForm">
  <div class="modal-header">
    <div class="d-flex" style="width: 100%;">
      <div class="d-flex align-items-start justify-content-start flex-1">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-center flex-1">
        <h4 class="modal-title">
          {{'Jahez' | translate }}
        </h4>
      </div>
      <div class="d-flex align-items-end justify-content-end flex-1">
        <a class="btn-default btn-grey" href="https://www.jahez.net/" target="_blank">
          {{'Learn More' | translate }}
        </a>
        <button [disabled]="loading" class="btn-primary btn-save" (click)="onSubmit()" [hidden]="f.integrationID.value >0 ">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText  | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body integration">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" style="margin-top: 40px;">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12 text-center">
              <div class="logo-placeholder bg-transparent">
                <img class="h-100" src="assets/images/marketplace/Jahez.png" alt="Msegat">
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="row mt-3">
            <label class="font-weight-bold">{{'Setup' | translate }}</label>
            <div class="form-group col-lg-12 col-sm-12 mt-3">
              <span>To Setup Jahez you need to:</span>
              <br />
              <span>Contact Sales&#64;marn.com to get keys and setup integration.</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="x_API_KEY">{{'API Key' | translate }}</label>
              <input type="text" class="form-control" id=x_API_KEY placeholder="" formControlName="x_API_KEY">
              <div *ngIf="f.x_API_KEY.invalid && (f.x_API_KEY.dirty || f.x_API_KEY.touched)" class="alert alert-danger">
                <div *ngIf="f.x_API_KEY.errors.required">
                  {{'API Key is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="secretCode">{{'Secret Code' | translate }}</label>
              <input type="text" class="form-control" id=secretCode placeholder="" formControlName="secretCode">
              <div *ngIf="f.secretCode.invalid && (f.secretCode.dirty || f.secretCode.touched)" class="alert alert-danger">
                <div *ngIf="f.secretCode.errors.required">
                  {{'Secret Code is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.integrationID.value >0">
            <div class="form-group col-lg-12 col-sm-12">
              <!--<button type="button" [disabled]="loading" class="btn btn-primary btn-lg btn-block btn-save w-100" (click)="syncProducts(0)">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{'Send Products to Jahez' | translate }}
              </button>-->

              <div class="btn-group partner-group-button" role="group" aria-label="Button group with nested dropdown" >
                <button type="button" class="btn btn-primary btn-sm cutom-button custom-GroupButton" [disabled]="loading" (click)="syncProducts(0)">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'Send Products to Jahez' | translate }}
                </button>
                <div class="btn-group partner-group-sub-button" role="group">
                  <button id="btnGroupDrop1" type="button" class="btn btn-primary btn-sm custom-SubButton dropdown-toggle"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngbPopover]="popActions" placement="right auto">
                    <i class="downArrow-white"></i>
                  </button>
                </div>
              </div>
              <ng-template #popActions>
                <p class="dropdown-heading-mini">{{'Locations' | translate }}</p>
                  <div class="inner-content">
                    <ng-container *ngFor="let location of Locations">
                      <a class="dropdown-item" (click)="syncProducts(location?.LocationID)" *ngIf="location?.LocationID !== null">
                        {{location?.Name}}
                      </a>
                    </ng-container>
                  </div>
                <!--<a *hasPermission="['Inventory','Inventory Action','Purchase', 'Create & Approve']" (click)="onSubmit('approve')"> {{'Create & Approve' | translate }}</a>-->
              </ng-template>
            </div>
          </div>
          <ng-container *hasPermission="['Marketplace','Uninstall']">
            <div class="line" *ngIf="f.integrationID.value > 0"></div>
            <h6 class="heading" *ngIf="f.integrationID.value >0 ">{{'Danger zone' | translate }}</h6>
            <div class="row" style="margin-top: 30px;" *ngIf="f.integrationID.value >0 ">
              <div class="col-6">
                <label>{{'Uninstall this App' | translate }} </label>
              </div>
              <div class="col-6 text-right">
                <button class="btn btn-danger btn-uninstall" [disabled]="loading" (click)="openConformation()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'Uninstall' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
