import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { Preps } from 'src/app/_models/Preps';


interface SearchPrepsResult {
  data: Preps[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Preps[], column: SortColumn, direction: string): Preps[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Preps, term: string) {
  return data.name.toLowerCase().includes(term.toLowerCase()) || data.prepCode.toLowerCase().includes(term.toLowerCase());
}

//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[]
}


function matcheHeader(data: Preps, filterValues: FilterHeaderState) {
  let status = false;
  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  if (status) {
    return data;
  }

}
//@@@ Header Filter Code End

@Injectable({
  providedIn: 'root'
})
export class PrepsService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Preps[]>([]);
  private _data$ = new BehaviorSubject<Preps[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public preps: Preps[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchPrepsResult> {
    debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.preps, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getIngredient(locationId) {

    const url = `api/preps/all/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Preps[]>(url).subscribe(res => {
        this.preps = res;
        this._data$.next(this.preps);
        this._allData$.next(this.preps);


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End

        //this._search$.pipe(
        //  switchMap(() => this._search()),
        //  tap(() => this._loading$.next(false))
        //).subscribe(result => {
        //  this._data$.next(result.data);
        //  this._total$.next(result.total);
        //});

        //this._search$.next();
      });
  }
  public getIngredients(brandId) {

    const url = `api/inventory/prep/all/${brandId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Preps[]>(url).subscribe(res => {
        this.preps = res;
        this._data$.next(this.preps);
        this._allData$.next(this.preps);
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._search$.next();
        this._searchHeader$.next();
      });



    // this.preps = [
    //     {prepID:1, name: "PRP-412",  recipe: "Large Bread", sku:'16840-6504',fromLocation:'Central Kitchen',toLocation:'Alrayan',unit:'500',unitID:1, brandID: 1, statusID: 1,  userID: 2, selected: false,rowVersion:0},
    //     {prepID:2, name: "PRP-412",  recipe: "Small Bread", sku:'39799-0403',fromLocation:'Central Kitchen',toLocation:'3 Locations',unit:'700',unitID:2, brandID: 1, statusID: 1,  userID: 2, selected: false,rowVersion:0},

    // ];
    // this._data$.next(this.preps);
    // this._allData$.next(this.preps);
    // this._search$.pipe(
    //   switchMap(() => this._search()),
    //   tap(() => this._loading$.next(false))
    // ).subscribe(result => {
    //   this._data$.next(result.data);
    //   this._total$.next(result.total);
    // });
    // this._search$.next();

  }
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchPrepsResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.preps, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }



  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }



  getById(id, brandId) {
    return this.http.get<any[]>(`api/inventory/prep/${id}/brand/${brandId}`);
  }

  insert(data) {

    console.log(JSON.stringify(data))
    return this.http.post(`api/inventory/prep`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/inventory/preps`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  deactive(id, version) {
    return this.http.patch(`api/preps/deactive/${id}/${version}`, null);

  }

  delete(id, version) {
    return this.http.delete<any[]>(`api/inventory/preps/${id}/${version}`);
  }

  SetStatus(id, statusId, version) {
    return this.http.patch(`api/inventory/prep/SetStatus/${id}/${statusId}/${version}`, null);
  }


}

