
<main class="mnt-header position-relative ingredient order-list product-list">
  <div class="row flex-row-reverse mb-3 custom-action-header margin-top-minus-30">

    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Inventory Alerts' | translate }} </h5>
    </div>
    <div class="col-md-auto">
      <div class="p-0">
        <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
      </div>
    </div>
    <div class="col-md-auto" *hasPermission="['Analytics','Summary','Inventory Tab', 'Create PO']">

      <div ngbDropdown *ngIf="GeneratePO === true">
        <a ngbDropdownToggle class="form-control text-center selected-location float-end" (click)="open()" style=" text-decoration: auto; color: white;background-color:black; width: 100% !important;     color: #fff !important;padding: 6px 20px;">
          <span>{{itemsCount}} {{'Selected' | translate}} | {{'Create Purchase' | translate}} </span>
        </a>
      </div>

    </div>
  </div>


  <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
    <div class="tile-body p-0 table-responsive text-nowrap">
      <table class="table table-striped table-custom text-start">
        <thead>
          <tr class="table-header">
            <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
            <th width="5%" class="text-center">

              <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.1111 13.9444C18.1111 14.357 17.9472 14.7527 17.6555 15.0444C17.3638 15.3361 16.9681 15.5 16.5556 15.5H2.55556C2.143 15.5 1.74733 15.3361 1.45561 15.0444C1.16389 14.7527 1 14.357 1 13.9444V5.38889C1 4.97633 1.16389 4.58067 1.45561 4.28894C1.74733 3.99722 2.143 3.83333 2.55556 3.83333H5.66667L7.22222 1.5H11.8889L13.4444 3.83333H16.5556C16.9681 3.83333 17.3638 3.99722 17.6555 4.28894C17.9472 4.58067 18.1111 4.97633 18.1111 5.38889V13.9444Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.55557 12.3889C11.2738 12.3889 12.6667 10.996 12.6667 9.27778C12.6667 7.55956 11.2738 6.16667 9.55557 6.16667C7.83735 6.16667 6.44446 7.55956 6.44446 9.27778C6.44446 10.996 7.83735 12.3889 9.55557 12.3889Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </th>
            <th width="17%" sortable="name" (sort)="onSort($event)">{{'Item Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th width="16%" sortable="alternativeName" (sort)="onSort($event)">{{'Alternative Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"/></th>
            <th width="13%">

              <!-- Location Filter -->
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                <button class="btn btn-outline-filter" id="ddlLocationFilter" ngbDropdownToggle
                        [ngClass]="{ 'active': setSelectedResult('location') > 0}">
                  <span>
                    <span class="badge" *ngIf="setSelectedResult('location')>0">{{setSelectedResult('location')}}</span>

                    {{'Location' | translate}}

                  </span>
                  <img class="down-aero" ngbDropdownOpen src="{{
                    (setSelectedResult('location') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'
                  }}">
                </button>
                <div ngbDropdownMenu aria-labelledby="ddlLocationFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                  <div>
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownLocation_all" (change)="selectAllFilters($event,Locations)">
                      <label class="form-check-label font-blue" for="dropdownLocation_all" (click)="selectAllFilters($event,Locations)">
                        {{'All Locations' | translate}}
                      </label>
                    </div>
                  </div>
                  <div *ngFor="let location of Locations; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownLocation_{{i+1}}" [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                      <label class="form-check-label" for="dropdownLocation_{{i+1}}" (click)="filters()">
                        {{location.name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>

            <th width="12%">
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">

                <button class="btn btn-outline-filter" id="ddlSupplierFilter" ngbDropdownToggle
                        [ngClass]="{ 'active': setSelectedResult('supplier') > 0}">
                  <span>
                    <span class="badge" *ngIf="setSelectedResult('supplier')>0">{{setSelectedResult('supplier')}}</span>

                    {{'Supplier' | translate}}

                  </span>
                  <img class="down-aero" ngbDropdownOpen src="{{
                    (setSelectedResult('supplier') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'
                  }}">
                </button>

                <div ngbDropdownMenu aria-labelledby="ddlSupplierFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                  <div>
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownSupplier_all" (change)="selectAllFilters($event,SuppliersList)">
                      <label class="form-check-label font-blue" for="dropdownSupplier_all" (click)="selectAllFilters($event,SuppliersList)">
                        {{'All Supplier' | translate}}
                      </label>
                    </div>
                  </div>

                  <div *ngFor="let supplier of SuppliersList; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownSupplier_{{i+1}}" [(ngModel)]="supplier.isChecked" (ngModelChange)="filters()">
                      <label class="form-check-label" for="dropdownSupplier_{{i+1}}" (click)="filters()">
                        {{supplier.name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th width="12%" sortable="minStockLevel" (sort)="onSort($event)">{{'Min. Stock' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th width="12%" sortable="currentStock" (sort)="onSort($event)">{{'Curr. Stock' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th width="5%" sortable="percentage" (sort)="onSort($event)">{{'' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"/></th>
            <th width="3%" class="text-center">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.40385 18.6548C14.3112 18.6548 18.286 14.68 18.286 9.77273C18.286 4.86541 14.3112 0.890625 9.40385 0.890625C4.49654 0.890625 0.521751 4.86541 0.521751 9.77273C0.521751 14.68 4.49654 18.6548 9.40385 18.6548ZM9.40385 17.4222C5.17516 17.4222 1.75435 13.9968 1.75435 9.77273C1.75435 5.54403 5.17516 2.12322 9.40385 2.12322C13.6325 2.12322 17.0534 5.54403 17.0534 9.77273C17.0534 13.9968 13.6325 17.4222 9.40385 17.4222Z" fill="#6B7280" />
              </svg>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data$ | async " [ngStyle]="{'opacity':item.disable === true ? '0.6' : '1'}">
            <td class="text-center">
              <input type="checkbox" class="form-check-input" (change)="chkSelection($event,item)" [(ngModel)]="item.selected" [disabled]="item.disable" />
            </td>
            <td class="text-center">
              <img [src]="item.imageURL" *ngIf="(item.imageURL !== '' && item.imageURL !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
              <img *ngIf="(item.imageURL === '' || item.imageURL === null)" src="/assets/images/icons/v2/inventory.svg" alt="" />
            </td>
            <td class="name-blue">{{item.name }}</td>
            <td>{{item.alternativeName }}</td>
            <td>{{item.locationName }}</td>
            <td> {{item.supplierName }} </td>
            <td>  {{item.minStockLevel}} </td>
            <td> {{item.currentStock}} </td>
            <td>
              <div class="title-right">
                <span *ngIf="item.percentage <= 0">{{'OOS' | translate}}</span>
                <span *ngIf="item.percentage > 0 && item.percentage <= 99">{{item.percentage}}%</span>
                <span *ngIf="item.percentage > 99">{{'Good' | translate}}</span>
              </div>
            </td>
            <td>
              <div *ngIf="item.percentage <= 99">
                <circle-progress [percent]="item.percentage <= 0 ? 100 : item.percentage"
                                 [radius]="15"
                                 [space]="-2"
                                 [outerStrokeWidth]="3"
                                 [innerStrokeWidth]="item.percentage <= 0 ? 15.5 : 2"
                                 [outerStrokeColor]="item.percentage > 50 && item.percentage <= 99 ? '#ffce00' :'#D31E1E'"
                                 [innerStrokeColor]="item.percentage <= 0 ? '#D31E1E':'#CDD7E2'"
                                 [animation]="true"
                                 [animationDuration]="300"
                                 [showInnerStroke]=true
                                 [showTitle]=false
                                 [showSubtitle]=false
                                 [showUnits]=false
                                 [showBackground]=false
                                 [clockwise]=false
                                 [startFromZero]=false
                                 [outerStrokeGradient]=false
                                 outerStrokeLinecap=square></circle-progress>
                <!--item.percentage >=99 ? '#006400':-->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <div class="row mt-10">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <p class="pagination-count">
        {{'Showing' | translate }}
        <!-- <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }} -->
        <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
        <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
      </p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                      [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
        <ng-template ngbPaginationFirst>
          <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
        </ng-template>
        <ng-template ngbPaginationLast>
          <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
        </ng-template>
        <ng-template ngbPaginationPrevious>
          <img src="../../../assets/images/icons/backward.svg" />
        </ng-template>
        <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>

</main>




