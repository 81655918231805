import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { OrderService } from '../../_services/order.service';
import * as myGlobals from '../../_helpers/globals';
@Component({
  selector: 'app-order-pdf',
  templateUrl: './order-pdf.component.html',
  styleUrls: ['./order-pdf.component.css']
})
export class OrderPDFComponent implements OnInit {
  SelectedBrand: any;
  selctedLang = 'en';
  @Input() orderData: any;
  @Output() html = new EventEmitter<HTMLElement>();
  QRCode = '';
  Phase2QRCode = '';
  industry = 0;
  VAT: any;
  elRef: ElementRef;
  ZATCAConfig: boolean = true;
  invoiceLabel: string = '';
  invoiceLabelText: boolean = true;
  showInvoicePeriod: boolean = false;
  constructor(private localStorageService: LocalStorageService,
    public service: OrderService,
    private elementRef: ElementRef,
    public setting: CommonService) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.SelectedBrand = this.localStorageService.getSelectedBrand();
    this.industry = this.localStorageService.getSelectedUser().User.Industry;
    this.loadSettings();
    this.elRef = elementRef;

  }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }
  ngOnInit(): void {
    //this.getOrder();
    if (this.orderData) {
      if (this.orderData?.order.orderStatus === 'Paid') {
        this.invoiceLabel = (this.orderData?.customer?.vatID) ? 'فاتورة ضريبية' : 'فاتورة ضريبية مبسطة';
      }
      else if (this.orderData?.order.orderStatus === 'Partial Refund' || this.orderData?.order.orderStatus === 'Refund') {
        this.invoiceLabel = (this.orderData?.customer?.vatID) ? 'اشعار دائن للفاتورة الضريبية' : 'اشعار دائن للفاتورة الضريبية المبسطة';
      }
      this.QRCode = this.orderData.qrCode;
      this.Phase2QRCode = this.orderData.order.phase2QRCode;
      if (this.orderData.taxes && this.orderData.taxes.length > 0) {
        let sum: number = 0;
        this.orderData.taxes.forEach(a => sum += a.amount);
        if (sum == 0)
          this.ZATCAConfig = false;
      }
      else if (this.orderData.order.taxAmount !== undefined && this.orderData.order.taxAmount !== null && this.orderData.order.taxAmount == 0) {
        this.ZATCAConfig = false;
      }
      if (this.orderData?.order.orderStatus === 'Hold') {
        this.invoiceLabelText = false;
      }
      else {
        this.invoiceLabelText = true;
      }
      if (this.orderData?.order.orderPeriod.orderPeriod) {
        this.showInvoicePeriod = true;
      }
      else {
        this.showInvoicePeriod = false;
      }
    }
  }
  ngOnChanges() {
    if (this.orderData) {
      this.QRCode = this.orderData.qrCode;
    }
  }
  sumModifiers(modifiers) {
    if (modifiers !== null && this.orderData.order.sumModifier) {
      let total = 0;
      for (let data of modifiers) {
        total += data.price * data.quantity;
      }
      return total;
    } else {
      return 0;
    }
  }
  checkDiscount(discounts, itemID) {
    if (discounts && discounts?.filter(e => e.levels.toLowerCase() === 'items' && e.itemID === itemID).length > 0)
      return true;
    else
      return false;
  }
  sumDiscounts(discounts,itemID) {
    if (discounts) {
      var dis = discounts?.filter(e => e.levels.toLowerCase() === 'items' && e.itemID===itemID).length > 0
      let total = 0;
      for (let data of discounts) {
        total += data.amount;
      }
      return total;
    } else {
      return 0;
    }
  }
  getOrder() {
    this.service.getById(52250).subscribe(res => {
      this.orderData = res;
    });
  }

  loadSettings() {
    this.setting.getSettings(this.localStorageService.getSelectedBrand().BrandID).subscribe((res: any) => {
      //debugger
      this.VAT = res.vat;
    });
  }
  getFormattedPrice(price: number) {
    var value = 0;
    price = parseFloat((price + 0.001).toFixed(3));
    var result = (price - Math.floor(price)) !== 0;

    if (result) {
      var sp = price.toString().split('.');
      value = parseFloat(sp[0] + '.' + sp[1].substring(0, 2));
    }
    else {
      //var sp = result.toString().split('.');
      value = parseFloat(price.toString());//parseFloat(sp[0] + sp[1].substring(0, 1));
    }
    return value;
  }
  ngOnDestroy() {
    if (this.orderData) {
      this.html.emit(this.elementRef.nativeElement);
      //this.pdfService.SaveAsPDFFile(this.elementRef.nativeElement, 'Sales-Summary', JSON.stringify(this.salesSummaryData).length);
    }
  }
}
