<main class="views container">
  <div class="page-header tabheader">
    <span>
      {{ "Reports"  | translate }}
    </span>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal" [(activeId)]="active">
        <li ngbNavItem="generate" id="reportsList" *hasPermission="['Analytics','Reports','Report Tab']" (click)="select('generate')" role="button">
          <a ngbNavLink>{{'Report Types' | translate }}</a>
          <ng-template ngbNavContent>
            <app-salesreport [LogEventType]="'screen'" [LogEvent]="'Report Tab'"></app-salesreport>
          </ng-template>
        </li>
        <li ngbNavItem="recent" id="recentReport" *hasPermission="['Analytics','Reports','Generated Reports']" (click)="select('recent')" role="button">
          <a ngbNavLink>{{'Generated Report' | translate }}  </a>
          <ng-template ngbNavContent>
            <app-recentreport [LogEventType]="'screen'" [LogEvent]="'Generated Reports'"></app-recentreport>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
