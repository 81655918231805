import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ExcelService } from 'src/app/_services/excel.service';
import { AddtaxsettingComponent } from './addtaxsetting/addtaxsetting.component';
import { TaxSetting } from 'src/app/_models/TaxSetting';
import { TaxSettingService } from 'src/app/_services/taxsetting.service';

@Component({
  selector: 'app-taxsetting',
  templateUrl: './taxsetting.component.html',
  styleUrls: ['./taxsetting.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class TaxsettingComponent  {
  data$: Observable<TaxSetting[]>;
  oldData: TaxSetting[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "tax";
  submit: boolean;
  excelData = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TaxSettingService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  getBrandData() {
    this.service.getTaxes(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }

  open() {
    const modalRef = this.modalService.open(AddtaxsettingComponent, { size: 'lg', windowClass: 'small-modal' });
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(tax) {
    const modalRef = this.modalService.open(AddtaxsettingComponent, { size: 'lg', windowClass: 'small-modal' });
    modalRef.componentInstance.SelectedID = tax.locationID;
    modalRef.componentInstance.SelectedName = tax.location;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id) {
    this.service.delete(parseInt(id)).subscribe((res: any) => {
      this.alertService.success("Tax Settings been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }

  Disable(id) {
    this.service.disable(parseInt(id)).subscribe((res: any) => {
      this.alertService.success("Tax Settings has been Disabled");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
}








