<div class="mnt-header">
  <div class="page-header">
    <div class="tile-header">

      <div class="d-flex header-list-topbar">
        <div class="me-auto p-2">
          <h6 class="hide-mobile">{{'Tax Setting' | translate }}</h6>
        </div>

        <div class="p-2  position-relative">
          <span class="show-filter-icon float-left">
            <img class="dots-list" src="assets/images/search.svg" />
          </span>
          <input class="form-control search-filter" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </div>
        <div class="p-2">
          <button type="button" (click)="open()" class="btn btn-primary cutom-button">
            {{'Add Taxes' | translate }}
          </button>
        </div>
      </div>

    </div>

  </div>

  <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
    <div class="tile-body p-0 table-responsive ">
      <table class="table table-striped">
        <thead>
          <tr class="table-header">
            <th width="25%" sortable="location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
            <th width="25%" sortable="vatNo" (sort)="onSort($event)">{{'VAT NO' | translate }}# <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
            <th width="30%" sortable="appliedTax" (sort)="onSort($event)">{{'TAX APPLIED' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
            <th width="15%" sortable="statusID" (sort)="onSort($event)">{{'Status' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" class="td-sorting-icon" /></th>
            <th width="5%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data$ | async " editRow>
            <td (click)="Edit(item)">
              <div class="item-title">{{item.location}}</div>
            </td>

            <td (click)="Edit(item)">{{item.vatNo}}</td>
            <td (click)="Edit(item)">{{item.appliedTax}}</td>

            <td (click)="Edit(item)">
              <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
              </ngb-highlight>
            </td>
            <td>
              <div class="position-relative">
                <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent" data-container="body">
                  <img class="dots-list" src="/assets/images/dots-list.svg" />
                </div>
                <ng-template #popContent>
                  <p class="dropdown-heading-mini">{{'TAXES' | translate }}</p>
                  <a (click)="Edit(item)">{{'Edit Tax' | translate }}</a>
                  <a (click)="Disable(item.locationID)">{{'Disable Tax' | translate }}</a>
                  <a (click)="Delete(item.locationID)">{{'Delete Tax' | translate }}</a>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </section>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <p class="pagination-count">
        {{'Showing' | translate }}
        <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
        <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
        <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
      </p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 justify-content-end">
      <!-- class="float-right"[rotate]="true" [boundaryLinks]="true"-->
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
        <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
        <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
        <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>

  </div>
</div>
