

import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { MarketplaceService } from 'src/app/_services/marketplace.service';
import { AccountingIntegration } from 'src/app/_models/marketplace';
import { IntegrationService } from '../../../_services/Integration.service';
import { VOMService } from '../../../_services/vom.service';
import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';

@Component({
  selector: 'app-vom-accounting',
  templateUrl: './vom-accounting.component.html',
  styleUrls: ['./vom-accounting.component.css']
})
export class VomAccountingComponent implements OnInit {
  @Input() PartnerID = 0;



  ButtonText = "Install";
  submitted = false;
  vomForm: UntypedFormGroup;
  private selectedBrand;
  loading = false;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private services: VOMService,
    private integrationServices: IntegrationService,
    private _modalService:NgbModal

  ) {
    this.createForm();

  }

  ngOnInit() {
    this.setSelectedPartner();
  }




  setSelectedPartner() {

    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getVOM(this.f.brandID.value, this.PartnerID).subscribe((res: AccountingIntegration[]) => {
        //Set Forms
        console.log(res);
        if (res !== null) {
          this.editForm(res);
          this.ButtonText = "Save";
        }
        this.loading = false;
      });
    }
  }



  private createForm() {

    this.vomForm = this.formBuilder.group({
      brandURL: ['', Validators.required],
      brandUser: ['', Validators.required],
      brandKey: ['', Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      accountingIntegrationID: 0,
      statusID:1,
      partnerID: this.PartnerID

    });
  }



  private editForm(value) {
    console.log(value);
    debugger
    this.f.brandURL.setValue(value.brandURL);
    this.f.brandUser.setValue(value.brandUser);
    this.f.brandKey.setValue(value.brandKey);
    this.f.accountingIntegrationID.setValue(value.accountingIntegrationID);
    this.f.statusID.setValue(value.statusID);
  }



  get f() { return this.vomForm.controls; }

  ngAfterViewInit() {
    // document.getElementById("terminalName").focus();
  }


  onSubmit() {
    this.vomForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    //this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.partnerID.setValue(this.PartnerID);

    if (this.vomForm.invalid) { return; }
    this.loading = true;


    if (parseInt(this.f.accountingIntegrationID.value) === 0) {

      //   //Insert
      console.log(this.vomForm.value);
      this.services.insert(this.vomForm.value).subscribe(data => {
        console.log(data);
        this.alertService.success("VOM has installed successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });


    } else {
      //   //Update
      this.services.update(this.vomForm.value).subscribe(data => {
        this.alertService.success("VOM Account has saved successfully");
        this.loading = false;
        this.PartnerID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }


  close() {
    this.activeModal.dismiss();
  }

  uninstall() {
    this.loading = true;
    this.services.Uninstall(parseInt(this.f.accountingIntegrationID.value)).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("VOM Account  has uninstalled successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }


  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true,windowClass: 'uninstall-modal',modalDialogClass: 'uninstall-modal-custom' });
    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
        }
      });
  }
}
