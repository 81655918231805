<main class="views container">
  <div class="page">
    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Sales Detail' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header align-items-center">
            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                </div>
              </div>
            </div>
            <div class="col-md-auto">
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" container="body">
                <button class="btn btn-outline-filter" id="ddlLocationFilter" ngbDropdownToggle
                        [ngClass]="{ 'active': setSelectedResult('columns') > 0}">
                  <span>
                    <span class="badge" *ngIf="setSelectedResult('columns')>0">{{setSelectedResult('columns')}}</span>
                    {{'Select Options' | translate}}
                  </span>
                  <img class="down-aero" ngbDropdownOpen src="{{
                    (setSelectedResult('columns') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'
                  }}">
                </button>
                <div ngbDropdownMenu aria-labelledby="ddlLocationFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                  <div>
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownLocation_all" [(ngModel)]="selectAll" (change)="selectAllFilters($event,ColumnList)">
                      <label class="form-check-label font-blue" for="dropdownLocation_all" (click)="selectAllFilters($event,ColumnList)">
                        {{'All' | translate}}
                      </label>
                    </div>
                  </div>
                  <div *ngFor="let location of ColumnList; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownLocation_{{i+1}}" [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                      <label class="form-check-label" for="dropdownLocation_{{i+1}}" (click)="filters()">
                        {{location.name | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body position-relative">
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.dateRange}}</div>
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive hrzScroll salesOrderDetail">
          <div style="width:fit-content">
            <div class="preview-filter-data" dir="ltr">{{selectedReport?.locations}}</div>
            <table class="table table-striped">
              <thead>
                <tr class="table-header">
                  <th width="5%">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="allSelected" (change)="bulkSelection($event)" />
                  </th>
                  <th *ngIf="setHidden('DeviceTransactionNo')" sortable="DeviceTransactionNo" (sort)="onSort($event)">{{'TRANSACTION' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('Partner')" sortable="Partner" (sort)="onSort($event)">{{'Partner' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('OrderNo')" sortable="OrderNo" (sort)="onSort($event)">{{'Order No' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('OrderRefrenceID')" sortable="OrderRefrenceID" (sort)="onSort($event)">{{'Order Reference' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('TableName') && service.tableAvailable" sortable="TableName" (sort)="onSort($event)">{{'Table Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('TotalAmount')" sortable="TotalAmount" (sort)="onSort($event)">{{'Price' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('Cost')" sortable="Cost" (sort)="onSort($event)">{{'Cost' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <!-- <th *ngIf="setHidden('DiscountPercentage')" sortable="DiscountPercentage" (sort)="onSort($event)">{{'Discount Percentage' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>-->
                  <th *ngIf="setHidden('DiscountAmount')" sortable="DiscountAmount" (sort)="onSort($event)">{{'Discount' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('RefundAmount')" sortable="RefundAmount" (sort)="onSort($event)">{{'Refund' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('TaxAmount')" sortable="TaxAmount" (sort)="onSort($event)">{{'Total Taxes' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <ng-container *ngIf="(data$ | async) && (data$ | async)[0]?.TaxColumns && (data$ | async)[0]?.TaxColumns?.length>0">
                    <ng-container *ngFor="let col of (data$| async )[0]?.TaxColumns">
                      <th *ngIf="setHidden(col)">
                        {{selectedLang=='en'?col.Name:col.ArabicName }}
                      </th>
                    </ng-container>
                  </ng-container>

                  <th *ngIf="setHidden('GrandTotal')" sortable="GrandTotal" (sort)="onSort($event)">{{'Grand Total' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('Profit')" sortable="Profit" (sort)="onSort($event)">{{'Profit' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('MultiPayments')" sortable="MultiPayments" (sort)="onSort($event)">{{'Paid Amount' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>

                  <!--<th *ngIf="setHidden('Location')" sortable="Location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>-->

                  <th *ngIf="setHidden('Location')">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Location' | translate }}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllLocations" (ngModelChange)="selectAllLocationFilters($event,locations)">
                          <label class="form-check-label font-blue" (click)="selectAllLocationFilters($event,locations)">
                            {{'All Locations' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let location of locations">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="location.isChecked" (ngModelChange)="filterLocations()">
                            <label class="form-check-label" (click)="filterLocations()">
                              {{location.name}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>

                  <th *ngIf="setHidden('CheckoutDate')" sortable="CheckoutDate" (sort)="onSort($event)">{{'Time' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('CheckoutBy')" sortable="CheckoutBy" (sort)="onSort($event)">{{'User' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('PaymentMode')" sortable="PaymentMode" (sort)="onSort($event)">{{'Pay Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th *ngIf="setHidden('OrderType')" sortable="OrderType" (sort)="onSort($event)">{{'Order Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data$ | async ">
                  <td>
                    <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" (change)="checkSelection(data$)" />
                  </td>
                  <td *ngIf="setHidden('DeviceTransactionNo')">
                    <ng-template #orderDetail>
                      <div class="hidden myPopover">
                        <div class="popover-heading">{{'Order' | translate }}:&nbsp;{{(item.DeviceTransactionNo)? item.DeviceTransactionNo : item.OfflineUniqueID}} <span style="float:right;cursor:pointer;" class="fa fa-times"></span></div>
                        <div class="popover-body">
                          <table style="width:100%">
                            <thead>
                              <tr>
                                <th>{{'Item' | translate }}</th>
                                <th>{{'QTY' | translate }}</th>
                                <th>{{'Price' | translate }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let order of item.Details">
                                <td> {{order.Name}}</td>
                                <td>{{order.Quantity}}</td>
                                <td>{{order.PriceWithVAT * order.Quantity | number:'1.2-2'}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-template>
                    <a [placement]="['left', 'right']" [ngbPopover]="orderDetail">
                      {{(item.DeviceTransactionNo)? item.DeviceTransactionNo : item.OfflineUniqueID}}
                    </a>
                  </td>
                  <td *ngIf="setHidden('Partner')" sortable="Partner"> {{item.PartnerName }} </td>
                  <td *ngIf="setHidden('OrderNo')" sortable="OrderNo"> {{item.OrderNo }} </td>
                  <td *ngIf="setHidden('OrderRefrenceID')"> {{item.OrderRefrenceID }} </td>
                  <td *ngIf="setHidden('OrderRefrenceID') && service.tableAvailable"> {{item.TableName }} </td>
                  <td *ngIf="setHidden('TotalAmount')"> {{item.TotalAmount | number:'1.2-2'}} </td>
                  <td *ngIf="setHidden('Cost')">  {{item.Cost | number:'1.2-2'}} </td>
                  <!--<td *ngIf="setHidden('DiscountPercentage')">  {{item.DiscountPercentage}} </td>-->
                  <td *ngIf="setHidden('DiscountAmount')"> {{item.DiscountAmount | number:'1.2-2'}} </td>
                  <td *ngIf="setHidden('RefundAmount')">
                    <ng-template #refundedOrderDetail>
                      <div class="hidden myPopover">
                        <div class="popover-heading">{{'Order' | translate }}:&nbsp;{{(item.DeviceTransactionNo)? item.DeviceTransactionNo : item.OfflineUniqueID}} <span style="float:right;cursor:pointer;" class="fa fa-times"></span></div>
                        <div class="popover-body">
                          <table style="width:100%">
                            <thead>
                              <tr>
                                <th>{{'Item' | translate }}</th>
                                <th>{{'Refunded QTY' | translate }}</th>
                                <th>{{'Price' | translate }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let order of item.RefundedItemDetails">
                                <td> {{order.Name}}</td>
                                <td>{{order.Quantity}}</td>
                                <td class="font-red fw-bold">{{order.PriceWithVAT * order.Quantity | number:'1.2-2'}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-template>
                    <a [placement]="['left', 'right']" [ngbPopover]="refundedOrderDetail">
                      {{item.RefundAmount | number:'1.2-2'}}
                    </a>
                  </td>
                  <td *ngIf="setHidden('TaxAmount')">  {{item.TaxAmount | number:'1.2-2'}} </td>
                  <ng-container *ngIf="(data$| async)[0].TaxColumns && (data$| async)[0].TaxColumns.length>0">
                    <ng-container *ngFor="let col of (data$| async)[0].TaxColumns; let i = index">
                      <td *ngIf="setHidden(col)">
                        {{ShowCustomTaxes(item.Taxes,col.Name) | number:'1.2-2'}}
                      </td>
                    </ng-container>
                  </ng-container>
                  <td *ngIf="setHidden('GrandTotal')"> {{item.GrandTotal | number:'1.2-2'}} </td>
                  <td *ngIf="setHidden('Profit')"> {{item.Profit | number:'1.2-2' }} </td>
                  <td *ngIf="setHidden('MultiPayments')"> {{item.MultiPayments | number:'1.2-2' }} </td>
                  <td *ngIf="setHidden('Location')"> <span class="preview-filter-data">{{item.Location }}</span> </td>
                  <td *ngIf="setHidden('CheckoutDate')">  {{item.CheckoutDate | date:'HH:mm | dd/MM/yyyy' }} </td>
                  <td *ngIf="setHidden('CheckoutBy')" style="color: #2d8c9f !important;"> <strong>{{item.CheckoutBy }} </strong></td>
                  <td *ngIf="setHidden('PaymentMode')" style="color: #328f60 !important;"> {{item.PaymentMode | translate}} </td>
                  <!--<td>
    <ngb-highlight style="width: 75px;" [ngClass]="{'btn-active': item.CurrentStock >=  item.MinStockLevel, 'btn-inactive':item.CurrentStock <  item.MinStockLevel }"
                   class="btn btn-sm" [result]="item.CurrentStock" [term]="service.searchTerm">
    </ngb-highlight>

  </td>-->
                  <td *ngIf="setHidden('OrderType')">
                    <span class="btn btn-sm w-auto grey">{{item.OrderType}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{(total$ | async)}}</strong>
            {{'results' | translate }}
          </div>
        </div>-->
      </section>
      <div class="row mt-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            {{'Showing' | translate }}
            <strong>{{(service.pageSize * service.page)-199}}</strong> {{'to' | translate }}
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>&nbsp;{{'of' | translate }}
            <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</main>

