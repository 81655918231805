<main class="views container order-list product-list">


  <div class="page-header tabheader">
    <!-- <span>
    {{( active=="modifier" ?"Modifier Items" :active=="groupmodifier"?"Group Modifier":"-") | translate}}
  </span> -->

    <span>
      {{'Modifiers' | translate}}
    </span>
    <div class="row">
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
        <li ngbNavItem="modifier" id="modifier" *hasPermission="['Products','Modifiers','Modifier Items']" (click)="active='modifier';">
          <a ngbNavLink>{{'Modifier Items' | translate }}  </a>
          <ng-template ngbNavContent>
            <app-item-modifiers [LogEventType]="'screen'" [LogEvent]="'Modifier'"></app-item-modifiers>
          </ng-template>
        </li>
        <li ngbNavItem="groupmodifier" id="groupmodifier" *hasPermission="['Products','Modifiers','Group Modifier']" (click)="active='groupmodifier';">
          <a ngbNavLink>{{'Group Modifier' | translate }} </a>
          <ng-template ngbNavContent>
            <app-groupmodifier [LogEventType]="'screen'" [LogEvent]="'Group Modifier'"></app-groupmodifier>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
