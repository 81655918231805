<section class="tile till-preview">
  <div class="tile-body table-responsive text-nowrap">
    <h6 class="pb-1">{{'Till Records' | translate}} </h6>
    <table class="table table-striped">
      <thead>
        <tr class="table-header">
          <th sortable="name" (sort)="onSort($event)">{{'Device' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="code" (sort)="onSort($event)">{{'Code' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="startedBy" (sort)="onSort($event)">{{'Opener' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="startingCash" (sort)="onSort($event)">{{'Opening Cash' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="cashSales" (sort)="onSort($event)">{{'Cash Sales' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="cashRefund" (sort)="onSort($event)">{{'Cash Refunds' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="payIn" (sort)="onSort($event)">{{'Pay In' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="payIn" (sort)="onSort($event)">{{'Pay Out' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="expectedCash" (sort)="onSort($event)">{{'Expected' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th sortable="difference" (sort)="onSort($event)">{{'Difference' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data$ | async " (click)="open(item?.tillID)">
          <td class="text-start">
            <span class="highlighted-text fw-bold">{{item.terminalName }}</span>
            <br />
            <span>{{item.refNumber }}</span>
          </td>
          <td>
            <span *ngIf="item.code !== ''">{{item.code }}</span>
            <span *ngIf="!item.code || item.code === ''">{{'-' }}</span>
          </td>
          <td>
            <ngb-highlight [result]="item.startedBy " [term]="service.startedBy"></ngb-highlight>
          </td>
          <td>
            <span *ngIf="item.startingCash">{{item?.startingCash | number:'1.2-2' }}</span>
            <span *ngIf="!item.startingCash">{{'-' }}</span>
          </td>
          <td>
            <span *ngIf="item.cashSales">{{item?.cashSales | number:'1.2-2' }}</span>
            <span *ngIf="!item.cashSales">{{'-' }}</span>
          </td>
          <td>
            <span *ngIf="item.cashRefund">{{item?.cashRefund | number:'1.2-2' }}</span>
            <span *ngIf="!item.cashRefund">{{'-' }}</span>
          </td>
          <td>
            <span *ngIf="item.payIn">{{item?.payIn | number:'1.2-2' }}</span>
            <span *ngIf="!item.payIn">{{'-' }}</span>
          </td>
          <td>
            <span *ngIf="item.payOut">{{item?.payOut | number:'1.2-2' }}</span>
            <span *ngIf="!item.payOut">{{'-' }}</span>
          </td>
          <td>
            <span *ngIf="item.expectedCash">{{item?.expectedCash | number:'1.2-2' }}</span>
            <span *ngIf="!item.expectedCash">{{'-' }}</span>
          </td>
          <td>
            <span *ngIf="item.statusID === 902 && item.difference">
              <span *ngIf="item.difference">{{item?.difference | number:'1.2-2' }}</span>
              <span *ngIf="!item.difference">{{'-' }}</span>
            </span>
            <ngb-highlight *ngIf="item.statusID === 901" class="btn-badge-blue btn btn-sm" [result]="'Active' | translate">
            </ngb-highlight>
            <ngb-highlight *ngIf="item.statusID === 902 && (!item.difference || item.difference === 0)" class="btn-active btn btn-sm" [result]="'None' | translate">
            </ngb-highlight>
          </td>

        </tr>
      </tbody>
      <tfoot *ngIf="totals">
        <tr class="total-row">
          <!-- Adjust styling as needed -->
          <td colspan="3" class="total-title">{{ 'Total of'}}&nbsp;{{((service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page) -  ((service.pageSize * service.page)-10)}}&nbsp;{{'rows' | translate }}</td>
          <td>{{ totals.totalStartingCash | number: '1.2-2' }}</td>
          <td>{{ totals.totalCashSales | number: '1.2-2' }}</td>
          <td>{{ totals.totalCashRefund | number: '1.2-2' }}</td>
          <td>{{ totals.totalPayIn | number: '1.2-2' }}</td>
          <td>{{ totals.totalPayOut | number: '1.2-2' }}</td>
          <td>{{ totals.totalExpectedCash | number: '1.2-2' }}</td>
          <td>{{ totals.totalDifference | number: '1.2-2' }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</section>
<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <p class="pagination-count">
      {{'Showing' | translate }}
      <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
      <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
      <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
    </p>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
      <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
      <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
      <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
      <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>
