import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LocationCoordinates, marker } from '../../../_models/Location';
import { AlertService } from '../../../_services/alert.service';

@Component({
  selector: 'app-add-location-coordinates',
  templateUrl: './add-location-coordinates.component.html',
  styleUrls: ['./add-location-coordinates.component.css']
})
export class AddLocationCoordinatesComponent implements OnInit {
  @Input() location: LocationCoordinates = {
    description: '',
    position: {
      lat: 0,
      lng: 0,
    },
    locationName: ''
  };
  marker = {
    position: {
      lat: null,
      lng: null,
    },
    label: '',
    displayDetails: false,
  };
  ButtonText = "Submit";
  zoom: number = 8;
  private geoCoder;
  mapClickListener;

  map: google.maps.Map;
  private searchElementRef: ElementRef;
  @ViewChild('search') set content(content: ElementRef) {
    if (content) {
      this.searchElementRef = content;
    }
  }

  constructor(public activeModal: NgbActiveModal,
    private ngZone: NgZone,
    httpClient: HttpClient,
    private alertService: AlertService) {
  }
  onMapReady(map: any) {

  }
  onMapClick(event: any) {
    console.log(event)
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.getPlacesAPI();
  }
  getPlacesAPI() {
    this.geoCoder = new google.maps.Geocoder;
    this.setLocation();
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);

    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.location.position.lat = place.geometry.location.lat();
        this.location.position.lng = place.geometry.location.lng();
        this.marker = {
          displayDetails: false,
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          label: place.formatted_address
        };
        this.zoom = 12;
      });
    });
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (this.location.position.lat === 0 || this.location.position.lng === 0) {
          this.location = {
            description: (this.location.description) ? this.location.description : '',
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            locationName: (this.location.locationName) ? this.location.locationName : ''
          };
          this.marker = {
            position: {
              lat: null,
              lng: null
            },
            displayDetails: false,

            label: this.location.description
          };
        }
        else {
          this.marker = {
            displayDetails: false,
            position: {
              lat: +this.location.position.lat,
              lng: +this.location.position.lng
            },
            label: this.location.description
          };
          //this.getAddress(this.marker.latitude, this.marker.longitude);
        }
        this.zoom = 8;
      });
    }
  }
  private setLocation() {
    if (this.location.position.lat === 0 || this.location.position.lng === 0) {
      this.location = {
        description: (this.location.description) ? this.location.description : '',
        position: {
          lat: 25.045563090492156, // Riyadh Lat 
          lng: 46.60262694377201, // Riyadh Lng
        },
        locationName: (this.location.locationName) ? this.location.locationName : ''
      };
      this.marker = {
        displayDetails: false,
        position: {
          lat: null,
          lng: null
        },
        label: this.location.description
      };
    }
    else {
      this.marker = {
        displayDetails: false,
        position: {
          lat: +this.location.position.lat,
          lng: +this.location.position.lng
        },
        label: this.location.description
      };
      //this.getAddress(this.marker.latitude, this.marker.longitude);
    }
    this.zoom = 8;
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.marker.label = this.location.description//results[0].formatted_address;
        } else {
          this.alertService.error('No results found');
        }
      } else {
        this.alertService.error('Geocoder failed due to: ' + status);
      }

    });
  }
  save() {
    this.location = {
      description: this.marker.label,
      position: {
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
      },
      locationName: this.location.locationName
    };
    this.activeModal.close(this.location);
  }
  //public mapReadyHandler(map: google.maps.Map): void {
  //  this.map = map;
  //  this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
  //    this.ngZone.run(() => {
  //      // Here we can get correct event
  //      //this.getAddress(e.latLng.lat(), e.latLng.lng());
  //      this.marker = {
  //        displayDetails: false,
  //        position: {
  //          lat: e.latLng.lat(),
  //          lng: e.latLng.lng(),
  //        },

  //        label: this.location.description
  //      };
  //      console.log(e.latLng.lat(), e.latLng.lng());
  //    });
  //  });
  //}


  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }
}
