<div id="htmlData">
  <div class="container expense-pdf p-4">
    <div class="row text-center">
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <h5 class="font-weight-bold float-end">
          {{"Expenses" }}
        </h5>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 pb-1">
        <label class="secondary-label float-end">
          {{Data?.name}}&nbsp;{{Data?.code}}
        </label>
      </div>
      <hr />
    </div>
    <div class="row pt-2">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label">
          {{'Expense To'}}:
        </label>
        <br />
        <label class="secondary-label">
          {{Data?.expenseTo}}
        </label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label float-end">
          {{'Maturity Date'}}
        </label>
        <br />
        <label class="secondary-label float-end">
          {{Data?.maturityDate |date}}
        </label>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label">
          {{'Reference Number'}}:
        </label>
        <br />
        <label class="secondary-label">
          {{Data?.referenceNo}}
        </label>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label float-end">
          {{'Generation Date'}}
        </label>
        <br />
        <label class="secondary-label float-end">
          {{Data?.generationDate |date}}
        </label>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-6 col-sm-6 col-md-6 col-lg-6">
        <label class="main-label">
          {{'Location'}}:
        </label>
        <br />
        <label class="secondary-label">
          {{Data?.locations}}
        </label>
      </div>
      
    </div>

    <div class="row pt-5">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <table class="table">
          <thead>
            <tr class="table-header">
              <th width="40%">{{"Expense Item name" }} </th>
              <th width="15%">{{"QTY"}}</th>
              <th width="15%">{{"Amount"}} </th>
              <th width="30%">{{"Total"}} </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of  Data?.details">
              <td>
                <label class="main-label"><span>{{item.name}}</span> </label>
              </td>
              <td align="right">
                <label class="main-label">{{(item.quantity) | number: '1.2-2'}}</label>
              </td>
              <td>
                <label class="main-label">{{item.amount}}</label>
              </td>
              <td align="right">
                <label class="main-label">{{item.totalAmount | number: '1.2-2'}} {{brandCurrency}}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
        <label class="secondary-label mb-1" *ngIf="Data?.note && Data?.note !== ''"> {{'Notes' | translate}}:</label>
        
      </div>
      <div class="col-3 col-sm-3 col-md-3 col-lg-3">
        <label class="secondary-label mb-1"><span>{{'Subtotal'}} </span> </label>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <p class="secondary-label mb-1 w-100 float-end">
          <label class="float-end">
            {{Data?.subTotal | number: '1.2-2'}}&nbsp;{{brandCurrency}}
          </label>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
        <p class="secondary-label"> {{Data?.note}}</p>
      </div>
      <div class="col-5 col-sm-5 col-md-5 col-lg-5">
        <div class="row" *ngFor="let tax of Data?.taxes">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <label class="secondary-label mb-1 "> <span>{{ tax.tax }}</span> <span> <pre class="secondary-label" style=" display: inline; font-family:inherit">({{tax.percentage}}%)</pre></span></label>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <p class="secondary-label mb-1 w-100 float-end">
              <label class="float-end">
                {{tax.amount | number: '1.2-2'}}&nbsp;{{brandCurrency}}
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="Data?.taxes && Data?.taxes?.length>0">
     
    </div>
    <div class="row">
      <div class="col-7 col-sm-7 col-md-7 col-lg-7">
      </div>

      <div class="col-3 col-sm-3 col-md-3 col-lg-3">
        <label class="main-label mb-1 font-weight-bold"><span>{{'Total'}}</span>  </label>
      </div>

      <div class="col-2 col-sm-2 col-md-2 col-lg-2">
        <p class="main-label font-weight-bold w-100 float-end mb-1">
          <label class="float-end">
            {{Data?.grandTotal | number: '1.2-2'}} &nbsp;{{brandCurrency}}
          </label>
        </p>
      </div>
    </div>
    <div class="row d-flex">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 align-self-end pt-5">
        <p class="main-label font-weight-bold float-end m-0">
          {{"Powered by" | translate}}
        </p>
        <br />
        <img class="float-end" src="/assets/images/logo-full.svg">
      </div>
    </div>
  </div>
  </div>
