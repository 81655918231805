import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { TransferInventoryService } from 'src/app/_services/transfer-inventory.service';
import * as myGlobals from '../../_helpers/globals';

@Component({
  selector: 'app-print-to',
  templateUrl: './print-to.component.html',
  styleUrls: ['./print-to.component.css']
})
export class PrintTransferComponent implements OnInit {

  tsid: number;
  TO: any = [];
  totalCost: number | null = null;
  selectedLang = 'en';
  constructor(private route: ActivatedRoute, public localStorageService:LocalStorageService,
     private printService: PrintService,private service:TransferInventoryService) {
    this.tsid = parseInt(this.route.snapshot.params["tsid"]);
    this.selectedLang = this.localStorageService.getSelectedLanguage();
   }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }
 ngOnInit() {
  this.tsid = parseInt(this.route.snapshot.params["tsid"]);
  this.service.getById(this.tsid,  parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe(res => {

    this.TO = res;
    this.LoadLocationTo(this.TO.fromLocationID);
    this.loadLocationFrom(this.TO.toLocationID);
    this.sumTotalCost(this.TO.tsDetails);
   this.printService.onDataReady();
  });

  }

  sumTotalCost(tsDetails) {
    let sum = 0;
    tsDetails.forEach(item => {
      if (item.cost || item.cost == 0)
        sum += item.quantity * item.cost;
    });
    this.totalCost = sum;
  }
  LoadLocationTo(id) {
    this.service.getLocationsById(id).subscribe((res: any) => {
      this.TO.toLocationID=res.locationID;
      this.TO.toLocationName=res.name;
      this.TO.toLocationAddress=res.address;
      this.TO.toLocationContact=res.contactNo;
    });
  }
  loadLocationFrom(id) {
    this.service.getLocationsById(id).subscribe((res: any) => {
      this.TO.fromLocationID=res.locationID;
      this.TO.fromLocationName=res.name;
      this.TO.fromLocationAddress=res.address;
      this.TO.fromLocationContact=res.contactNo;
    });
  }


  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"

    }
  }

}
