
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { TaxService } from 'src/app/_services/tax.service';
import { LocationService } from 'src/app/_services/location.service';
import { TaxSettingService } from 'src/app/_services/taxsetting.service';


@Component({
  selector: 'app-addtaxsetting',
  templateUrl: './addtaxsetting.component.html',
  styleUrls: ['./addtaxsetting.component.css']
})
export class AddtaxsettingComponent implements OnInit {
  @Input() name;

  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  taxsettingForm: UntypedFormGroup;
  loading = false;
  loadingTax = false;
  ButtonText = "Confirm";
  Title = "Apply Tax";
  Locations: any;
  Taxes = [];
  selectedTaxes = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private taxService: TaxService,
    private location: LocationService,
    public service: TaxSettingService
  ) {
    this.createForm();
    this.loadLocations();
    this.loadTaxes(parseInt(this.localStorageService.getSelectedBrand().BrandID));
  }

  ngOnInit() {
    this.setSelectedTax();
  }

  private createForm() {
    this.taxsettingForm = this.formBuilder.group({
      locationID: [0, Validators.required],
      locationTaxIds: ['', Validators.required],
      statusID: [0, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      vatNo: [''],
      rowVersion: 0
    });
  }

  get f() { return this.taxsettingForm.controls; }

  setSelectedTax() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingTax = true;
      this.f.locationID.setValue(this.SelectedID);
      this.service.getTaxesByLocation(this.SelectedID).subscribe(res => {
        console.log(res);
        this.Title = "Edit Tax Setting";
        //Set Forms
        this.editForm(res);
        this.loadingTax = false;
      });
    }
  }


  private editForm(value) {
    this.f.locationID.setValue(value.locationID);
    this.f.brandID.setValue(value.brandID);
    this.f.vatNo.setValue(value.vatNo);
    this.f.statusID.setValue(value.statusID === 1 ? true : false);
    this.f.rowVersion.setValue(value.rowVersion);
    this.f.locationTaxIds.setValue(value.locationTaxIds);
    this.selectedTaxes = value.locationTaxIds.split(',').map(i => Number(i));
  }

  close() {
    this.activeModal.dismiss();
  }

  private loadLocations() {
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }

  private getLocationVat() {
    this.service.getVATByLocation(this.f.locationID.value).subscribe((res: any) => {
      console.log(res);
      this.f.vatNo.setValue(res.vatNo);
    });
  }

  private loadTaxes(brandId) {
    this.taxService.getAllTaxesddl(brandId).subscribe((res: any) => {
      this.Taxes = res;
    });
  }

  onSubmit() {
    this.taxsettingForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.locationTaxIds.setValue(this.selectedTaxes.toString());

    if (this.taxsettingForm.invalid) { return; }

    this.loading = true;
    //if (parseInt(this.f.locationID.value) === 0) {

    //Insert Location
    this.service.insert(this.taxsettingForm.value).subscribe(data => {
      this.alertService.success("Tax Setup has been created");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });

    // } else {
    //   //Update Location
    //   this.service.update(this.taxsettingForm.value).subscribe(data => {
    //     this.alertService.success("Tax has been Updated");
    //     this.loading = false;
    //     this.SelectedID = 0;
    //     this.activeModal.dismiss();
    //   }, error => {
    //     this.alertService.error(error);
    //     this.loading = false;
    //     this.activeModal.dismiss();
    //   });
    // }
  }
}

