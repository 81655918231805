<form [formGroup]="customerForm" class="form customer">
  <div class="modal-header custom-modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L20.7133 20.7133" stroke="black" stroke-width="2" stroke-linecap="round" />
          <path d="M20.7129 1L0.999611 20.7133" stroke="black" stroke-width="2" stroke-linecap="round" />
        </svg>
      </span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Customer' :SelectedName) | translate }}  </h4>

    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn"
            (click)="onSubmit()"
            *hasPermission="['Analytics','Customers', (SelectedID > 0) ? 'Edit' : 'New']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="fullName">{{'Customer Name' | translate }}</label>
              <input type="text" class="form-control" id="fullName" placeholder="" formControlName="fullName" pattern="^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,100}$" />
              <div *ngIf="f.fullName.invalid && (f.fullName.dirty || f.fullName.touched)" class="alert alert-danger">
                <div *ngIf="f.fullName.errors.required">
                  {{'Customer Name is Required' | translate }}
                </div>
                <div *ngIf="f.fullName.invalid">{{'Customer Name format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="mobileNumber">{{'Phone No.' | translate }}</label>
              <input type="text" class="form-control" pattern="^\(?([\+]|00)(\d{2,3})\)?[ .-]?(\d{2,3})[ .-]?(\d{4})[ .-]?(\d{3})$" id="mobileNumber" placeholder="" formControlName="mobileNumber" />
              <div *ngIf="f.mobileNumber.invalid && (f.mobileNumber.dirty || f.mobileNumber.touched)" class="alert alert-danger">
                <div *ngIf="f.mobileNumber.errors.required">
                  {{'Phone Number is Required' | translate }}
                </div>
                <div *ngIf="f.mobileNumber.invalid">{{'Phone Number format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="email">{{'Email' | translate }}</label>
              <input type="text" class="form-control" id="email" placeholder="" formControlName="email" autocomplete="off" />
              <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger">
                <div *ngIf="f.email.errors.required">
                  {{'Email is required' | translate }}.
                </div>
                <div *ngIf="f.email.invalid">{{'Email format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="gender">{{'Gender' | translate }}</label>
              <select class="custom-select-2 form-control" formControlName="gender">
                <option value="Male" selected>{{'Male' | translate }}</option>
                <option value="Female">{{'Female' | translate }}</option>
                <option value="Not Specified">{{'Not Specified' | translate }}</option>
              </select>
              <div *ngIf="f.gender.invalid && (f.gender.dirty || f.gender.touched)" class="alert alert-danger">
                <div *ngIf="f.gender.errors.required">
                  {{'Gender is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'Date of birth' | translate }}</label>
              <ngbd-datepicker-adapter formControlName="dob" ngDefaultControl (click)="setDate()"></ngbd-datepicker-adapter>
              <div *ngIf="f.dob.invalid && (f.dob.dirty || f.dob.touched)" class="alert alert-danger">
                <div *ngIf="f.dob.errors.required">
                  {{'Date of birth is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="nationalID">{{'National ID' | translate }}</label>
              <input type="text" class="form-control" id="nationalID" placeholder="" formControlName="nationalID" autocomplete="off" />
              <div *ngIf="f.nationalID.invalid && (f.nationalID.dirty || f.nationalID.touched)" class="alert alert-danger">
                {{'Only numbers with Min and Max length must be 10' | translate }}.
                <div *ngIf="f.nationalID.errors.required">
                  {{'National ID is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="carDetail">{{'Car Info' | translate }}</label>
              <input type="text" class="form-control" id="carDetails" placeholder="" formControlName="carDetails" autocomplete="off" />
              <div *ngIf="f.carDetails.invalid && (f.carDetails.dirty || f.carDetails.touched)" class="alert alert-danger">
                <div *ngIf="f.carDetails.errors.required">
                  {{'Car Info is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="notes">{{'Notes' | translate }}</label>
              <input type="text" class="form-control" id="notes" placeholder="" formControlName="notes" autocomplete="off" />
              <div *ngIf="f.notes.invalid && (f.notes.dirty || f.notes.touched)" class="alert alert-danger">
                <div *ngIf="f.notes.errors.required">
                  {{'Notes is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <div class="accordion pb-2" id="accordionPanelBusinessInfo">
                <div class="title">
                  <h2 class="accordion-header" id="panelBusinessInfo-headingOne">
                    <button class="accordion-button collapsed" id="btnBusinessInfo" type="button" data-bs-toggle="collapse" data-bs-target="#BusinessInfo" aria-expanded="false" aria-controls="BusinessInfo">
                      {{'Business Information' | translate}}
                    </button>
                  </h2>
                </div>
                <div id="BusinessInfo" class="accordion-collapse collapse" aria-labelledby="panelBusinessInfo-headingOne">
                  <div class="accordion-body">
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="vatID">{{'VAT ID' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="vatID" placeholder="" formControlName="vatID" />
                        <div *ngIf="f.businessCustomer.value && f.vatID.invalid && (f.vatID.dirty || f.vatID.touched)" class="alert alert-danger">
                          <div *ngIf="f.vatID.errors.required">
                            {{'VAT ID is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12 mb-0">
                        <label for="crn">{{'Company Registration Number (CRN)' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="crn" pattern="^\d{9,15}$" placeholder="XXXXXXXXXXXXXXX" formControlName="crn" />
                        <div *ngIf="f.businessCustomer.value && f.crn.invalid && (f.crn.dirty || f.crn.touched)" class="alert alert-danger">
                          {{'Only numbers with Min length 9 and Max length could be 15' | translate }}                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <div class="accordion pb-2" id="accordionPanelsAddress">
                <div class="title">
                  <h2 class="accordion-header" id="panelsAddress-headingOne">
                    <button class="accordion-button collapsed" id="btnAddress" type="button" data-bs-toggle="collapse" data-bs-target="#AddressInfo" aria-expanded="false" aria-controls="AddressInfo">
                      {{'Address' | translate}}
                    </button>
                  </h2>
                </div>
                <div id="AddressInfo" class="accordion-collapse collapse" aria-labelledby="panelsAddress-headingOne">
                  <div class="accordion-body">
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="country">{{'Country' | translate }}</label> <span *ngIf="f.businessCustomer.value">*</span>
                        <select class="custom-select-2 form-control" formControlName="country" (change)="loadCities()">
                          <option [value]="option.code"
                                  selected="option.Code == CountryID"
                                  *ngFor="let option of Countries">
                            {{option.name}}
                          </option>
                        </select>
                        <div *ngIf="f.businessCustomer.value && f.country.invalid && (f.country.dirty || f.country.touched)" class="alert alert-danger">
                          <div *ngIf="f.country.errors.required">
                            {{'Country is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="city">{{'City' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <select class="custom-select-2 form-control" formControlName="city">
                          <option [ngValue]="option.name"
                                  selected="option.name == city"
                                  *ngFor="let option of Cities">
                            {{option.name}}
                          </option>
                        </select>
                        <div *ngIf="f.businessCustomer.value && f.city.invalid && (f.city.dirty || f.city.touched)" class="alert alert-danger">
                          <div *ngIf="f.city.errors.required">
                            {{'City is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="province">{{'Province' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="province" placeholder="" formControlName="province" />
                        <div *ngIf="f.businessCustomer.value && f.province.invalid && (f.province.dirty || f.province.touched)" class="alert alert-danger">
                          <div *ngIf="f.province.errors.required">
                            {{'Province is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="district">{{'District' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="district" placeholder="" formControlName="district" />
                        <div *ngIf="f.businessCustomer.value && f.district.invalid && (f.district.dirty || f.district.touched)" class="alert alert-danger">
                          <div *ngIf="f.district.errors.required">
                            {{'District is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="postalCode">{{'Postal Code' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="postalCode" pattern="^\d{5}$" placeholder="XXXXX" formControlName="postalCode" />
                        <div *ngIf="f.businessCustomer.value && f.postalCode.invalid && (f.postalCode.dirty || f.postalCode.touched)" class="alert alert-danger">
                          <div *ngIf="f.postalCode.invalid">
                            {{'Postal Code is invalid' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="streetName">{{'Street Name' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="streetName" placeholder="" formControlName="streetName" />
                        <div *ngIf="f.businessCustomer.value && f.streetName.invalid && (f.streetName.dirty || f.streetName.touched)" class="alert alert-danger">
                          <div *ngIf="f.streetName.errors.required">
                            {{'Street Name is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="buildingNumber">{{'Building Number' | translate }}</label><span *ngIf="f.businessCustomer.value">*</span>
                        <input type="text" class="form-control" id="buildingNumber" pattern="^\d{4}$" placeholder="XXXX" formControlName="buildingNumber" />
                        <div *ngIf="f.businessCustomer.value && f.buildingNumber.invalid && (f.buildingNumber.dirty || f.buildingNumber.touched)" class="alert alert-danger">
                          <div *ngIf="f.buildingNumber.invalid">
                            {{'Building Number is invalid' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="additionalNumber">{{'Additional Number' | translate }}</label>
                        <input type="text" class="form-control" id="additionalNumber" pattern="^\d{4}$" placeholder="XXXX" formControlName="additionalNumber" />
                        <div *ngIf="f.additionalNumber.invalid && (f.additionalNumber.dirty || f.additionalNumber.touched)" class="alert alert-danger">
                          <div *ngIf="f.additionalNumber.invalid">
                            {{'Additional Number is invalid' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12 mb-0">
                        <label for="address">{{'Address' | translate }}</label>
                        <textarea class="form-control mh-80 h-75" formControlName="address" cols="4"></textarea>
                        <div *ngIf="f.address.invalid && (f.address.dirty || f.address.touched)" class="alert alert-danger">
                          <div *ngIf="f.address.errors.required">
                            {{'Address is Required' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center form-group business-customer">

            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
              <label for="businessCustomer" class="label-bold">
                {{'Is your customer a business?' | translate }}
              </label>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-right">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="businessCustomer" formControlName="businessCustomer" (change)="enableBusinessCustomer()">
                <label class="form-check-label" for="businessCustomer"></label>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <p class="font-12">{{'Business require an invoice compliant with ZATCA B2B invoicing' | translate }}</p>
            </div>
          </div>

          <div *ngIf="purchaseSummary !== null && purchaseSummary !== undefined && purchaseSummaryLoading === false" class="customer">
            <!--<div class="tiltle-bar">
      <div class="row">
        <div class="col-lg-8">
          <span>{{'Customer Purchase Summary' | translate }}</span>
        </div>
      </div>
    </div>-->


            <div class="form-group col-lg-12 col-sm-12 ">
              <div class="tiltle-bar">
                <div class="title">
                  <label>Customer Purchase Summary</label>
                </div>
              </div>
            </div>


            <div class="row pt-2">
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'Total Orders:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.orderCount !== null && purchaseSummary?.orderCount !== undefined && purchaseSummary?.orderCount !== '' && purchaseSummary?.orderCount !== 0">
                  {{purchaseSummary?.orderCount}} {{'Orders' | translate }}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.orderCount === null || purchaseSummary?.orderCount === undefined || purchaseSummary?.orderCount === '' || purchaseSummary?.orderCount === 0">
                  {{'-'| translate }}
                </label>
              </div>
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'Total Amounts:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.grandTotal !== null && purchaseSummary?.grandTotal !== undefined && purchaseSummary?.grandTotal !== '' && purchaseSummary?.grandTotal !== 0">
                  {{purchaseSummary?.grandTotal | number:'1.2-2'}} {{brandCurrency | translate }}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.grandTotal === null || purchaseSummary?.grandTotal === undefined || purchaseSummary?.grandTotal === '' || purchaseSummary?.grandTotal === 0">
                  {{'-' | translate }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'Order Average:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.averageAmount !== null && purchaseSummary?.averageAmount !== undefined && purchaseSummary?.averageAmount !== '' && purchaseSummary?.averageAmount !== 0">
                  {{purchaseSummary?.averageAmount | number:'1.2-2'}} {{brandCurrency | translate }}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.averageAmount === null || purchaseSummary?.averageAmount === undefined || purchaseSummary?.averageAmount === '' || purchaseSummary?.averageAmount === 0">
                  {{'-' | translate }}
                </label>
              </div>
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'Customer Recurrance:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.customerRecurrence !== null && purchaseSummary?.customerRecurrence !== undefined && purchaseSummary?.customerRecurrence !== '' && purchaseSummary?.customerRecurrence !== 0">
                  {{purchaseSummary?.customerRecurrence}} {{'Days' | translate }}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.customerRecurrence === null || purchaseSummary?.customerRecurrence === undefined || purchaseSummary?.customerRecurrence === '' || purchaseSummary?.customerRecurrence === 0">
                  {{'-' | translate }}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'First Visit:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.firstVisit !== null && purchaseSummary?.firstVisit !== undefined && purchaseSummary?.firstVisit !== '' && purchaseSummary?.firstVisit !== 0">
                  {{purchaseSummary?.firstVisit | date: "dd/MM/yyyy"}} - {{purchaseSummary?.firstVisit | date:'shortTime'}}

                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.firstVisit === null || purchaseSummary?.firstVisit === undefined || purchaseSummary?.firstVisit === '' || purchaseSummary?.firstVisit === 0">
                  {{'-' | translate}}
                </label>
              </div>
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'Last Visit:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.lastVisit !== null && purchaseSummary?.lastVisit !== undefined && purchaseSummary?.lastVisit !== '' && purchaseSummary?.lastVisit !== 0">
                  {{purchaseSummary?.lastVisit | date: "dd/MM/yyyy"}} - {{purchaseSummary?.lastVisit | date:'shortTime'}}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.lastVisit === null || purchaseSummary?.lastVisit === undefined || purchaseSummary?.lastVisit === '' || purchaseSummary?.lastVisit === 0">
                  {{'-' | translate}}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'First Visited Location:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.firstLocation !== null && purchaseSummary?.firstLocation !== undefined && purchaseSummary?.firstLocation !== '' && purchaseSummary?.firstLocation !== 0">
                  {{purchaseSummary?.firstLocation}}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.firstLocation === null || purchaseSummary?.firstLocation === undefined || purchaseSummary?.firstLocation === '' || purchaseSummary?.firstLocation === 0">
                  {{'-' | translate}}
                </label>
              </div>
              <div class="form-group col-lg-6 col-sm-6 col-6">
                {{'Most Visited Location:' | translate }}
                <br />
                <label class="label-bold" *ngIf="purchaseSummary?.mostVisitedLocation !== null && purchaseSummary?.mostVisitedLocation !== undefined && purchaseSummary?.mostVisitedLocation !== '' && purchaseSummary?.mostVisitedLocation !== 0">
                  {{purchaseSummary?.mostVisitedLocation}}
                </label>
                <label class="label-bold" *ngIf="purchaseSummary?.mostVisitedLocation === null || purchaseSummary?.mostVisitedLocation === undefined || purchaseSummary?.mostVisitedLocation === '' || purchaseSummary?.mostVisitedLocation === 0">
                  {{'-' | translate}}
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="orders !== null && orders !== undefined" class="customer">
            <div class="tiltle-bar" *ngIf="orders.length > 0">
              <div class="row">
                <div class="col-lg-8 col-sm-8 col-8">
                  <div class="form-groups col-lg-12 col-sm-12 ">

                    <div class="title">
                      <label>Orders</label>
                    </div>

                  </div>

                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                  <div ngbDropdown>
                    <a ngbDropdownToggle class="form-control text-center selected-location float-end" style="text-decoration: auto; color: #6B7280; width: 115px !important; height: auto; border-radius: 30px; margin-top: -2px; padding-top: 3px; padding-bottom: 3px; ">
                      <span>{{selectedFilterName | translate}}</span>
                      <img class="down-aero" ngbDropdownToggle src="assets/images/down-aero.svg" style=" margin-left: 15px; margin-right: 10px; margin-top: -3px;">
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropaero-menu">
                      <a class="dropdown-item" [hidden]="selectedFilter === 1" (click)="SelectCustomerOrders(1,'Latest',false)">{{'Latest' | translate}} </a>
                      <a class="dropdown-item" [hidden]="selectedFilter === 2" (click)="SelectCustomerOrders(2,'High $',false)">{{'High $' | translate}} </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tile-body p-0 table-responsive ">
              <table class="table table-striped text-start">
                <tbody>
                  <tr *ngFor="let item of orders" (click)="previewOrder(item?.orderID)" editrow>
                    <td class="text-start">
                      <span class="label-bolder">#{{item?.orderNo}}</span>
                      <br />
                      <span> {{item?.location | translate}}</span>
                    </td>
                    <td class="customerorders">
                      <div class="float-end label-bold"> {{item?.businessDate | date: "dd/MM/yyyy"}}</div>
                      <br />
                      <span class="float-end"> {{item?.grandTotal | number:'1.2-2'}}</span>
                      &nbsp;
                      <ngb-highlight *ngIf="item?.status === 301" class="btn btn-sm btn-badge-blue float-end rounded-pill me-2 ms-2" result="{{'Hold' | translate}}">
                      </ngb-highlight>
                      <ngb-highlight *ngIf="item?.status === 310" class="btn btn-sm btn-inactive float-end rounded-pill me-2 ms-2" result="{{'Partial Refund' | translate}}">
                      </ngb-highlight>
                      <ngb-highlight *ngIf="item?.status === 306" class="btn btn-sm btn-inactive float-end rounded-pill me-2 ms-2" result="{{'Refund' | translate}}">
                      </ngb-highlight>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center" *ngIf="totalPages-1 >0">
                <ngb-highlight class="btn btn-sm btn-badge-blue rounded-pill customer-loadmore" result="{{'Load More' | translate}}" (click)="SelectCustomerOrders(selectedFilter,selectedFilterName,true)">
                </ngb-highlight>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
