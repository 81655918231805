<main class="views container order-list product-list marketplace">

  <div class="page-header-ex tabheader">
    <span> {{'Integrations' | translate }}</span>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 ">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="marketplace" id="marketplace">
            <a ngbNavLink> {{'Marketplace' | translate }} </a>
            <ng-template ngbNavContent>

              <main class="views container">
                <div class="page">
                   <div class="page-header">
                    <div class="title" style="font-size:20px">
                      <!--<span>  {{'Most popular apps' | translate }}</span>-->
                      <div>
                      </div>
                    </div>
                  </div> 
                  <div class="page-body">
                    <section class="report-list">
                      <div class="page-body report-cards">
                        <div class="row">
                          <div class="col-sm-12 col-lg-4 col-md-6 col-lg-4 col-xl-4 " *ngFor="let p of Partners" [hidden]="p.type!=='PARTNER' || p.install=='Installed'">
                            <div class="card" style="margin-bottom:20px">
                              <div class="card-horizontal">
                                <div class="img-rounds">
                                  <img src={{p.logo}} style="width:60px; vertical-align:middle" />
                                </div>
                                <div class="card-body">
                                  <h4 class="card-title">{{p.name}}</h4>
                                  <!--<p class="text-mute ">Food Delivery</p>-->
                                </div>
                              </div>
                              <div>
                                <p class="text-detail " style="height:50px; margin-bottom: 50px;"> {{p.description}}</p>

                                <!-- <a class="btn btn-link" style="padding-left:0" [hidden]="p.install=='Installed'" (click)="install(p)" *hasPermission="['Marketplace','Install']">Get App</a>
                                <a href="{{p.url}}" target="_blank" style="padding-left:0" *ngIf="p.install=='Installed'" class="btn btn-link">{{'Learn More' | translate }}</a> -->


                                <button class="btn-install plr-0 text-decoration-none"   (click)="install(p)" *hasPermission="['Marketplace','Install']">{{'Get App' | translate}}</button>
                                <a href="{{p.url}}" target="_blank" style="padding-left:0" class="btn-link ms-3">{{'Learn More' | translate }}</a>

                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4 col-md-6 col-lg-4 col-xl-4 " *ngFor="let p of Partners" [hidden]="p.type=='PARTNER' || p.install=='Installed'">
                            <div class="card mb-4">
                              <div class="card-horizontal">
                                <div class="img-rounds">
                                  <img src={{p.logo}} style="width:60px; vertical-align:middle" />
                                </div>
                                <div class="card-body">
                                  <h4 class="card-title">{{p.name}}</h4>
                                  <!--<p class="text-mute ">Food Delivery</p>-->
                                </div>

                              </div>
                              <div>
                                <p class="text-detail" style="height:50px; margin-bottom: 50px;"> {{p.description}}</p>

                                <!-- <a class="btn btn-link plr-0" style="padding-left:0" [hidden]="p.install=='Installed'" (click)="install(p)" *hasPermission="['Marketplace','Install']">Get App </a>
                                <a href="{{p.url}}" target="_blank" style="padding-left:0" *ngIf="p.install=='Installed'" class="btn btn-link">{{'Learn More' | translate }}</a> -->


                             <button class="btn-install plr-0 text-decoration-none"  (click)="install(p)" *hasPermission="['Marketplace','Install']"> {{'Get App' | translate }}</button>
                                <a href="{{p.url}}" target="_blank" style="padding-left:0" class="btn-link ms-3">{{'Learn More' | translate }}</a>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="Partners?.length == 0" >
                            <div class="blank-order">
                              <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
                              <strong>{{'No App Available' | translate }}</strong>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="title mb-3" style="font-size:20px">
                          <span>  {{'Accounting & Finance' | translate }}</span>
                          <div>
                          </div>
                        </div> -->

                        <!-- <div class="row"> -->

                        <!-- </div> -->
                      </div>

                      <div id="partners" class="row marketplace" [hidden]=true>
                      </div>
                    </section>
                  </div>
                </div>
              </main>
            </ng-template>
          </li>
          <li ngbNavItem="integratedApp" id="integratedApp">
            <a ngbNavLink>{{'Integrated Apps' | translate }} </a>
            <ng-template ngbNavContent>
              <main class="views container">
                <div class="page">
                  <div class="page-header">
                    <!-- <div class="title" style="font-size:20px">
                      <span>  {{'All Connected Apps' | translate }}</span>
                      <div>
                      </div>
                    </div> -->
                  </div>
                  <div class="page-body">
                    <section class="report-list">
                      <div class="page-body report-cards">
                        <div class="row mb-4">
                          <div class="col-sm-12 col-lg-4 col-md-6 col-lg-4 col-xl-4 " *ngFor="let p of Partners" [hidden]="p.install=='Install'">
                            <div class="card mb-4">
                              <div class="card-horizontal">
                                <div class="img-rounds">
                                  <img src={{p.logo}} style="width:60px; vertical-align:middle" />

                                </div>
                                <div class="card-body">
                                  <h4 class="card-title">{{p.name}}</h4>
                                  <!--<p class="text-mute ">Food Delivery</p>-->
                                </div>

                              </div>
                              <div>
                                <p class="text-detail" style="height:50px; margin-bottom: 50px;"> {{p.description | translate }}</p>
                                <button class="btn-install plr-0 text-decoration-none" (click)="install(p)" *hasPermission="['Marketplace','Install']"> {{'Details' | translate }}</button>
                                <a href="{{p.url}}" target="_blank" style="padding-left:0;" class="btn-link ms-3">{{'Learn More' | translate }}</a>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="InstalledCount == 0" >
                            <div class="blank-order">
                              <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
                              <strong>{{'No App Integrated' | translate }}</strong>
                              <!-- <p class="text-center width-375">{{'' | translate}}</p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="partners" class="row marketplace" [hidden]=true>
                      </div>
                    </section>
                  </div>
                </div>
              </main>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>

  </div>


  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
