<div class="mnt-header position-relative order-list product-list">


  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-30" *ngIf="(service.purchaseorders?.length > 0)">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Return' | translate }} </h5>
    </div>
    <div class="col-md-auto" *hasPermission="['Inventory','Inventory Action','Purchase', 'New']">
      <button type="button" class="btn btn-primary cutom-button custom-create-btn" placement="bottom" [ngbPopover]="popNavigation">
        {{'+ Create Return' | translate }}
      </button>
      <ng-template #popNavigation>
        <p class="dropdown-heading-mini">{{'RETURNS' | translate }}</p>
        <a *hasPermission="['Inventory','Inventory Action','Return', 'Purchase Return']" (click)="navigatePage('Purchase')">{{'Purchase Returns' | translate }}</a>
        <a *hasPermission="['Inventory','Inventory Action','Return', 'Stock Return']" (click)="navigatePage('Stock')">{{'Stock Returns' | translate }}</a>
      </ng-template>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Inventory','Inventory Action','Return', 'Export']">

          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>


  <!--<div class="page-header">
    <div class="tile-header">
      <div class="d-flex header-list-topbar">
        <div class="me-auto p-2">
          <h6 class="hide-mobile">{{'Return List' | translate }}</h6>
        </div>
        <div class="p-2  position-relative">
          <span class="show-filter-icon float-left">
            <img class="dots-list" src="assets/images/search.svg" />
          </span>
          <input class="form-control search-filter" placeholder="{{'Filter' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </div>
        <div class="p-2" *hasPermission="['Inventory','Inventory Action','Return', 'Export']">



          <div ngbDropdown class="nav-item" placement="bottom">
            <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" [disabled]="loading" ngbDropdownToggle>
              <span *ngIf="isGeneratingPDF" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
              <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
              <button ngbDropdownItem (click)="export('csv')">CSV</button>
              <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
            </div>
          </div>


        </div>
        <div class="p-2">
          <button type="button" class="btn btn-primary cutom-button" placement="bottom" [ngbPopover]="popNavigation">
            {{'Create New' | translate }}
          </button>
          <ng-template #popNavigation>
            <p class="dropdown-heading-mini">{{'RETURNS' | translate }}</p>
            <a *hasPermission="['Inventory','Inventory Action','Return', 'Purchase Return']" (click)="navigatePage('Purchase')">{{'Purchase Returns' | translate }}</a>
            <a *hasPermission="['Inventory','Inventory Action','Return', 'Stock Return']" (click)="navigatePage('Stock')">{{'Stock Returns' | translate }}</a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>-->




<div *ngIf="(service.purchaseorders?.length > 0)">
  <div class="position-relative">
    <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
      <div class="tile-body p-0 table-responsive text-nowrap">
        <table class="table table-striped text-start">
          <thead>
            <tr class="table-header">
              <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
              <th width="15%" sortable="code" (sort)="onSort($event)">{{'REF' | translate }}# <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%" sortable="date" (sort)="onSort($event)">{{'DATE' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>

              <th width="18%" sortable="from" (sort)="onSort($event)">{{'FROM' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="17%" sortable="to" (sort)="onSort($event)">{{'TO' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="17%" sortable="lastUpdatedBy" (sort)="onSort($event)">{{'Returned By' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%">


                <!-- Status Filter -->
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                    <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                    <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                             name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                      <label class="form-check-label font-blue" for="dropdownStatus_all"
                             (click)="selectAllFilters($event,StatusList)">
                        {{'All Status' | translate}}
                      </label>
                    </div>
                    <div *ngFor="let item of StatusList; let i = index">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                        <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>


                          <ngb-highlight [ngClass]="renderStatusClass(item.name)" class="btn btn-sm"
                                         [result]="(
                                          item.name ==600?'Created':
                                          item.name ==601?'Approved':
                                          item.name ==602?'Pending':
                                          item.name ==603?'Declined':
                                          item.name ==604?'Hold':
                                          item.name ==605?'Received':
                                          item.name ==606?'Returned':'-') | translate" [term]="service.searchTerm">
                          </ngb-highlight>

                        </label>

                      </div>
                    </div>
                  </div>
                </div>


              </th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let purchaseorder of data$ | async ">
              <td class="text-center">
                <input type="checkbox" class="form-check-input" (change)="chkSelection($event,purchaseorder)" [(ngModel)]="purchaseorder.selected" />
              </td>
              <td class=" name-blue"> {{purchaseorder.code}} </td>
              <td> {{purchaseorder.date | date}} </td>

              <td> {{purchaseorder.from}} </td>
              <td> {{purchaseorder.to }} </td>
              <td> {{purchaseorder.lastUpdatedBy}} </td>
              <td>
                <ngb-highlight [ngClass]="renderStatusClass(purchaseorder.statusID)" class="btn btn-sm" [result]="(
                            purchaseorder.statusID ==600?'Created':
                            purchaseorder.statusID ==601?'Approved':
                            purchaseorder.statusID ==602?'Pending':
                            purchaseorder.statusID ==603?'Declined':
                            purchaseorder.statusID ==604?'Hold':
                            purchaseorder.statusID ==605?'Received':
                            purchaseorder.statusID ==606?'Returned':'-') | translate" [term]="service.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                  <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                </div>
                <ng-template #popContent>
                  <p class="dropdown-heading-mini">{{'RETURNS' | translate }}</p>
                  <a *hasPermission="['Inventory','Inventory Action','Return', (purchaseorder.code.trim().toLowerCase().substring(0, 2) === 'po') ? 'Purchase Return' : 'Stock Return']" (click)="Preview(purchaseorder)">{{ (purchaseorder.code.trim().toLowerCase().substring(0, 2) === "po" ? "Preview Purchase" : "Preview Stock") | translate  }} </a>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    </div>
    <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

  </div>


  <div *ngIf="(service.purchaseorders?.length == 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Return yet' | translate }}</strong>
      <p class="text-center width-375">{{'Create New Return.' | translate}}</p>
    </div>
    <div class="items-flex">
      <div class="create-btn mlmr-10">

        <div *hasPermission="['Inventory','Inventory Action','Purchase', 'New']">
          <button type="button" class="btn btn-primary cutom-button" placement="bottom" [ngbPopover]="popNavigation">
            {{'+ Create New Return' | translate }}
          </button>
          <ng-template #popNavigation>
            <p class="dropdown-heading-mini">{{'RETURNS' | translate }}</p>
            <a *hasPermission="['Inventory','Inventory Action','Return', 'Purchase Return']" (click)="navigatePage('Purchase')">{{'Purchase Returns' | translate }}</a>
            <a *hasPermission="['Inventory','Inventory Action','Return', 'Stock Return']" (click)="navigatePage('Stock')">{{'Stock Returns' | translate }}</a>
          </ng-template>
        </div>

      </div>


    </div>
  </div>

  <div *ngIf="(service.purchaseorders?.length > 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Record Found' | translate }}</strong>
      <p class="text-center width-375">{{'Create return for purchase and transfer.' | translate}}</p>
    </div>
  </div>

</div>
