// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accountName: "marnpossastorage",
  containerName: "marnpos-v3-uat",
  key: "yFQz/m+/TEo2nvH5iiu3v242SKYkIsplpOl3yw+CTe7csUZas+5L0aw3dRYIiBX6wXuJa+CQkVFGftXYVxdjfQ==",
  recaptcha: {
    siteKey: '6LcLniEfAAAAAA-6rK39ysz0EV6EQ_L8PN-Y6OwH',
  },
  googleMap: {
    apiKey: 'AIzaSyAliUxdJ3SLC8nb8wM_NIVXbVW-4zkD_lg',
  },
  digitalReceipt:{
    baseURL:'https://rc-uat.marn.com/'
  },
  intercom: {
    appId: 'usn2ht3p',
  },
  firebaseConfig : {
    apiKey: "AIzaSyD-Zn1UaiIy9V2CDt0E4k2-5PZciwCzEnI",
    authDomain: "marn-web-demo.firebaseapp.com",
    projectId: "marn-web-demo",
    storageBucket: "marn-web-demo.appspot.com",
    messagingSenderId: "1020691586725",
    appId: "1:1020691586725:web:5addcdda9f88cb66d4b74c",
    measurementId: "G-XJ6V608PN3"
  },
  ZATCA_Config: {
    URL: 'https://e-invoicing-uat.marn.com/'
  },
  GTM_ID: 'GTM-M6PNB3QL'
};  

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
