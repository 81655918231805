<form [formGroup]="purchaseorderForm" class="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'Generate' | translate }} {{f.poCode.value}} </h4>

        <button [disabled]="loading" class="btn btn-primary btn-sm cutom-button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate}}
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-6 mt-5">
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="name">{{'Reference No' | translate }}</label>
                            <input type="text" class="form-control" id=name placeholder="" formControlName="referenceNo">

                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="License">{{'Supplier' | translate }}</label>
                            <select class="custom-select form-control" formControlName="supplierID" (change)="loadItems(f.locationID.value,f.supplierID.value)">
                             
                                <option [ngValue]="option.supplierID" [disabled]=option.maped 
                                    selected="option.supplierID == supplierID" *ngFor="let option of Suppliers">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="License">{{'Deliver To' | translate }}</label>
                            <select class="custom-select form-control" formControlName="locationID" (change)="loadItems(f.locationID.value,f.supplierID.value)" [class.disabled]="SelectedID !== 0">
                            <option [ngValue]="option.locationID" [disabled]=option.maped 
                                    selected="option.locationID == locationID" *ngFor="let option of Locations">
                                   {{option.name}}
                            </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="expDate">{{'Expected Date' | translate }}</label>
                            <ngbd-datepicker-adapter></ngbd-datepicker-adapter>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="Notes">{{'Notes' | translate }}</label>
                            <textarea class="form-control mh-80" formControlName="notes"></textarea>

                        </div>
                    </div>

                    <div class="po-box form-group p-3">
                      <label for="Conversion">{{'Item Name' | translate }}</label>
                        <div class="input-group form-group">
                            <div class="input-group-append w-100 row">
                                <ng-select [items]="Items" class="form-control w-75 po-box-autocomplete border-right-radius-unset " placeholder="{{'Click here to select Item' | translate }}" bindLabel="name" bindValue="supplyID" (change)="selectedInvItem($event)" [(ngModel)]="poDetails.supplyID" [ngModelOptions]="{standalone: true}" [searchFn]="SearchItem">
                                </ng-select>
                                <input type="number" class="input-group-text w-25 quantity" id=quantity value="1" [(ngModel)]="poDetails.quantity" [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>
                        <div *ngIf="conversionBoxOpen">
                          <label for="Conversion">{{'Conversion' | translate }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend border-right-0">
                                    <select class="custom-select border-right-radius-unset form-control" [(ngModel)]="poDetails.unitID" [ngModelOptions]="{standalone: true}">                             
                                    <option [ngValue]="option.unitID" [disabled]=option.maped
                                        selected="option.unitID == unitID" *ngFor="let option of Units">
                                        {{option.unit}}
                                    </option>
                                </select>
                                </div>
                                <input type="number" class="form-control text-center" [(ngModel)]="poDetails.conversionRate" [ngModelOptions]="{standalone: true}">
                                <div class="input-group-prepend">
                                    <select class="custom-select border-left-radius-unset form-control" disabled=true [(ngModel)]="poDetails.conversionUnitID" [ngModelOptions]="{standalone: true}">                             
                                    <option [ngValue]="option.unitID" [disabled]=option.maped
                                        selected="option.unitID == unitID" *ngFor="let option of Units">
                                        {{option.unit}}
                                    </option>
                                </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12">
                          <label for="add item" class="additem-po" (click)="addRow(poDetails)">+ {{'Add Item' | translate }}</label>

                        </div>
                    </div>
                    <div class="po-box form-group">
                        <table class="table m-0">
                            <thead class="thead-light border-top-radius-unset">
                                <tr>
                                  <th scope="col" width="40%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                                  <th scope="col" width="15%">{{'Qty' | translate }}</th>
                                  <th scope="col" width="20%">{{'Rate' | translate }}</th>
                                  <th scope="col" width="15%">{{'Total' | translate }}</th>
                                    <th scope="col" width="10%" class="border-radius-topright-10"></th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom-radius-unset">
                                <tr *ngFor="let item of arrItems">
                                    <td scope="row" class="font-weight-bold">{{item.name}}</td>
                                    <td>{{item.quantity}} {{item.conversionUnit}}</td>
                                    <td>
                                        <input type="number" (keyup)="UpdateQty(item)" [(ngModel)]="item.cost" [ngModelOptions]="{standalone: true}" class="form-control rounded">
                                    </td>
                                    <td>{{item.quantity * item.cost | number : '1.2-2'}}</td>
                                    <td>
                                        <a><img src="/assets/images/delete-icon.png" (click)="deleteRow(item)" /></a>
                                    </td>
                                </tr>
                                <tr *ngIf="arrItems.length == 0">
                                    <td colspan="5">
                                        <div class="row p-1">
                                            <div class="col-12 text-center">
                                              <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr class="bg-summary">
                                  <td colspan="3">{{'Subtotal' | translate }}</td>

                                    <td colspan="2" class="text-right">
                                      <label for="total"> {{f.subTotal.value | number : '1.2-2'}} {{'SAR' | translate }}</label>
                                    </td>
                                </tr>
                                <tr class="table-borderless bg-summary">
                                  <td colspan="3">{{'Tax' | translate }} {{taxPercent}} % </td>

                                    <td colspan="2" class="text-right">
                                      <label for="total"> {{f.taxAmount.value | number : '1.2-2'}} {{'SAR' | translate }}</label>
                                    </td>
                                </tr>
                                <tr class="text-white bg-dark">
                                  <td colspan="3" class="border-radius-bottomleft-10">{{'Total Due' | translate }}</td>

                                    <td colspan="2" class="border-radius-bottomright-10 text-right">
                                      <label for="total" class="text-white"> {{f.grandTotal.value | number : '1.2-2'}} {{'SAR' | translate }}</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="row">
                        <div class="form-group col-lg-8">
                          <label for="Item Status">{{'Apply' | translate }} {{taxPercent}} % {{'VAT' | translate }}</label>
                            <div class="form-check form-switch" (change)="isApplyTax($event)">
                                <input type="checkbox" class="form-check-input" checked id="isApplyTax">
                                <label class="form-check-label" for="isApplyTax"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3"></div>
            </div>
        </div>
    </div>

</form>
