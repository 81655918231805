

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { AlertService } from 'src/app/_services/alert.service';
import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import * as $ from 'jquery';
import { LocalStorageService } from '../../_services/local-storage.service';

@Component({
  selector: 'app-print-salestax',
  templateUrl: './print-salestax.component.html',
  styleUrls: ['./print-salestax.component.css']
})
export class PrintSalestaxComponent implements OnInit {


  loading$: Observable<boolean>;
  selectedReport: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(

    private router: Router,
    private localStorageService: LocalStorageService,
    private printService: PrintService,
    private alertService: AlertService) {
    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
    this.selectedBrand = this.localStorageService.getSelectedBrand();
  }
  selectedBrand: any;
  ngOnInit() {
    //setTimeout(() => {
    this.getReportData(this.selectedReport.dataURL);

    //}, 1000);
  }


  ReportData: any;
  getReportData(dataURL) {

    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataURL,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {
          _this.ReportData = res;
          console.log(_this.ReportData);
          _this.printService.onDataReady();

        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })



  }

  ngOnDestroy() {
    this.alertService.clear();

  }


}
