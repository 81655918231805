<form [formGroup]="receivetsForm" class="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'Transfer Order' | translate }} {{f.tsCode.value}}</h4>

        <button [disabled]="loading" class="btn btn-primary btn-sm cutom-button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate }}
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-6 mt-5">
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="name">{{'Reference No' | translate }}</label>
                            <input type="text" class="form-control" id=name placeholder="" formControlName="referenceNo">

                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="email">{{'Order Date' | translate }}</label>
                            <ngbd-datepicker-adapter #date></ngbd-datepicker-adapter>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="email">{{'Receive Date' | translate }}</label>
                            <ngbd-datepicker-adapter #receivedate></ngbd-datepicker-adapter>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="Notes">{{'Notes' | translate }}</label>
                            <textarea class="form-control mh-80" formControlName="notes"></textarea>

                        </div>
                    </div>
                    <div class="po-box form-group">
                        <table class="table m-0">
                            <thead class="thead-light border-top-radius-unset">
                                <tr>
                                  <th scope="col" width="50%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                                  <th scope="col" width="25%">{{'Ordered' | translate }}</th>

                                  <th scope="col" width="25%">{{'Qty Received' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom-radius-unset">
                                <tr *ngFor="let item of arrItems">
                                    <td scope="row" class="font-weight-bold">{{item.name}}</td>
                                    <td>{{item.quantity + item.unit}}</td>
                                    <td>
                                        <input type="number" (keyup)="UpdateQty(item)" [(ngModel)]="item.receivedQty" [ngModelOptions]="{standalone: true}" class="form-control rounded">
                                    </td>
                                </tr>
                                <tr *ngIf="arrItems.length == 0 || arrItems.length == undefined">
                                    <td colspan="3">
                                        <div class="row p-1">
                                            <div class="col-12 text-center">
                                              <h6 for="name" class="pt-3 pb-3">{{'No Items' | translate }}!</h6>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="col-lg-3"></div>
            </div>
        </div>
    </div>

</form>
