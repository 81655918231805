import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../_services/alert.service';
import { CategoryService } from '../../_services/category.service';
import { ExcelService } from '../../_services/excel.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-category-uploader',
  templateUrl: './category-uploader.component.html',
  styleUrls: ['./category-uploader.component.css']
})
export class CategoryUploaderComponent implements OnInit {
  SaveButtonText = "Continue";
  showFileUpload = true;
  showFileOptions = false;
  showError = false;
  loading = false;
  showTemplate = true;
  categoryUploadForm: UntypedFormGroup;
  FileOptionList: Array<any> = [
    { value: "CategoryName", name: 'Category Name' },
    { value: "CategoryArabicName", name: 'Category Arabic Name' },
    { value: "CategoryDisplayOrders", name: 'Category Display Orders' },
    { value: "SubCategoryName", name: 'Sub-Category Name' },
    { value: "SubCategoryArabicName", name: 'Sub-Category Arabic Name' },
    { value: "SubCategoryDisplayOrders", name: 'Sub-Category Display Orders' }
  ];
  errorList: any[];
  fileData: any;


  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private categoryService: CategoryService) {
    this.createForm();
  }

  close() {
    this.activeModal.dismiss();
  }

  get f() { return this.categoryUploadForm.controls; }

  private createForm() {
    this.categoryUploadForm = this.formBuilder.group({
      categoryName: [Validators.required],
      categoryArabicName: [Validators.required],
      categoryDisplayOrders: [Validators.required],
      subCategoryName: [Validators.required],
      subCategoryArabicName: [Validators.required],
      subCategoryDisplayOrders: [Validators.required]
    });
  }
  ngOnInit(): void {
  }
  ValidateSheet(sheet) {
    let sheetFormat = true;
    sheet.filter(function (item) {
      if (Object.keys(item).length < 6)
        sheetFormat = false;
      if (item[Object.keys(item)[0]] === null || item[Object.keys(item)[0]] === undefined || item[Object.keys(item)[0]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[1]] === null || item[Object.keys(item)[1]] === undefined || item[Object.keys(item)[1]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[2]] === null || item[Object.keys(item)[2]] === undefined || item[Object.keys(item)[2]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[3]] === null || item[Object.keys(item)[3]] === undefined || item[Object.keys(item)[3]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[4]] === null || item[Object.keys(item)[4]] === undefined || item[Object.keys(item)[4]] === '')
        sheetFormat = false;
      if (item[Object.keys(item)[5]] === null || item[Object.keys(item)[5]] === undefined || item[Object.keys(item)[5]] === '')
        sheetFormat = false;
      
    });
    return sheetFormat;
  }

  onSubmit() {
    if (this.SaveButtonText === "Continue") {
      if (this.fileData !== null && this.fileData !== undefined) {
        if (this.ValidateSheet(this.fileData)) {
          this.showFileUpload = false;
          this.showTemplate = false;
          this.showFileOptions = true;
          this.SaveButtonText = "Upload";
        }
        else
          this.alertService.error("Attached sheet is not valid.");
      }
      else {
        this.showFileUpload = true;
        this.showTemplate = true;
        this.showFileOptions = false;
        this.alertService.error("File not found or invalid file.");
      }
    }
    else {

      let categoryName = this.f.categoryName.value === this.f.categoryArabicName.value || this.f.categoryName.value === this.f.categoryDisplayOrders.value || this.f.categoryName.value === this.f.subCategoryName.value || this.f.categoryName.value === this.f.subCategoryArabicName.value || this.f.categoryName.value === this.f.subCategoryDisplayOrders.value;
      let categoryArabicName = this.f.categoryArabicName.value === this.f.categoryName.value || this.f.categoryArabicName.value === this.f.categoryDisplayOrders.value || this.f.categoryArabicName.value === this.f.subCategoryName.value || this.f.categoryArabicName.value === this.f.subCategoryArabicName.value || this.f.categoryArabicName.value === this.f.subCategoryDisplayOrders.value;
      let categoryDisplayOrders = this.f.categoryDisplayOrders.value === this.f.categoryArabicName.value || this.f.categoryDisplayOrders.value === this.f.categoryName.value || this.f.categoryDisplayOrders.value === this.f.subCategoryName.value || this.f.categoryDisplayOrders.value === this.f.subCategoryArabicName.value || this.f.categoryDisplayOrders.value === this.f.subCategoryDisplayOrders.value;
      let subCategoryName = this.f.subCategoryName.value === this.f.categoryArabicName.value || this.f.subCategoryName.value === this.f.categoryDisplayOrders.value || this.f.subCategoryName.value === this.f.categoryName.value || this.f.subCategoryName.value === this.f.subCategoryArabicName.value || this.f.subCategoryName.value === this.f.subCategoryDisplayOrders.value ;
      let subCategoryArabicName = this.f.subCategoryArabicName.value === this.f.categoryArabicName.value || this.f.subCategoryArabicName.value === this.f.categoryDisplayOrders.value || this.f.subCategoryArabicName.value === this.f.subCategoryName.value || this.f.subCategoryArabicName.value === this.f.categoryName.value || this.f.subCategoryArabicName.value === this.f.subCategoryDisplayOrders.value;
      let subCategoryDisplayOrders = this.f.subCategoryDisplayOrders.value === this.f.categoryArabicName.value || this.f.subCategoryDisplayOrders.value === this.f.categoryDisplayOrders.value || this.f.subCategoryDisplayOrders.value === this.f.subCategoryName.value || this.f.subCategoryDisplayOrders.value === this.f.subCategoryArabicName.value || this.f.subCategoryDisplayOrders.value === this.f.categoryName.value;
      
      if (categoryName || categoryArabicName || categoryDisplayOrders || subCategoryName || subCategoryArabicName || subCategoryDisplayOrders ) {
        this.categoryUploadForm.setErrors({ 'invalid': true });
      }

      this.categoryUploadForm.markAllAsTouched();
      this.alertService.clear();
      if (this.categoryUploadForm.invalid) {
        return;
      }
      this.loading = true;
      let uploadData = [];
      let brandID = this.localStorageService.getSelectedBrand().BrandID;
      let fc = this.f;
      let funcKey = this.getKey;
      this.fileData.filter(function (item) {
        var data =
        {
          CategoryName: item[funcKey(item, fc.categoryName.value)].toString().trim(),
          CategoryArabicName: item[funcKey(item, fc.categoryArabicName.value)].toString().trim(),
          CategoryDisplayOrders: item[funcKey(item, fc.categoryDisplayOrders.value)],
          SubCategoryName: item[funcKey(item, fc.subCategoryName.value)].toString().trim(),
          SubCategoryArabicName: item[funcKey(item, fc.subCategoryArabicName.value)].toString().trim(),
          SubCategoryDisplayOrders: item[funcKey(item, fc.subCategoryDisplayOrders.value)],
          BrandID: brandID
        };
        uploadData.push(data);
        return true;
      });
      this.categoryService.uploadCategory(uploadData).subscribe(data => {
        if (data != null) {
          if (data.result.status === 1) {
            this.alertService.success("Sheet has been saved");
            this.activeModal.dismiss();
          } else {
            this.showFileUpload = false;
            this.showFileOptions = false;
            this.showError = true;
            this.errorList = data.messages;
          }
        }
        this.loading = false;
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }

  setKeys(obj) {
    if (obj.length > 0) {
      let keys = Object.keys(obj[0]);
      //this.FileOptionList = null; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
      let fileOption = [];
      keys.filter(function (item) {
        let data = { value: item, name: item };
        fileOption.push(data);
      });
      //this.FileOptionList = fileOption; // Commenting because when any column is empty it doesnot show in dropdown. It was commented while working on Price Type
    }
  }
  async onFileChange(ev) {
    let file = ev.target.files[0];
    //this.fileData = await this.excelService.ReadExcelFile(file);

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const _file = file;
    if (ev.target.files[0].size <= 5000000) {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          let format = this.validateFileHeaders(initial);
          if (format === true) {
            this.fileData = initial;
            this.setKeys(initial);
          }
        }, {});
      }
      reader.readAsBinaryString(_file);
    }
    else {
      this.alertService.error("File size is larger than 5 MB.");
    }
  }


  download() {
    var data =
      [
        {
          CategoryName: '', CategoryArabicName: '', CategoryDisplayOrders: '', SubCategoryName: '', SubCategoryArabicName: '', SubCategoryDisplayOrders: ''
        }
      ];
    this.excelService.exportAsExcelFile(data, 'CategoryTemplate', 'xlsx');
  }

  getKey(item, formValue) {
    let key = '';
    Object.keys(item).forEach((val) => {
      if (formValue === val)
        key = val;
    });
    return key;
  }
  validateFileHeaders(sheet: any) {
    let sheetFormat = true;
    if (this.getKey(sheet[0], 'CategoryName') !== 'CategoryName')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'CategoryArabicName') !== 'CategoryArabicName')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'CategoryDisplayOrders') !== 'CategoryDisplayOrders')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'SubCategoryName') !== 'SubCategoryName')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'SubCategoryArabicName') !== 'SubCategoryArabicName')
      sheetFormat = false;
    if (this.getKey(sheet[0], 'SubCategoryDisplayOrders') !== 'SubCategoryDisplayOrders')
      sheetFormat = false;


    return sheetFormat;

  }
}
