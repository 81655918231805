import { Component, ViewChild, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexTooltip,
  ApexLegend,
  ApexGrid,
  ApexFill
} from "ng-apexcharts";
import { DashboardPaymentType, DashboardReport } from '../../_models/dashboard-report.model';
import * as myGlobals from '../../_helpers/globals';
import { LocalStorageService } from '../../_services/local-storage.service';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  legend: ApexLegend;
  grid: ApexGrid;
  fill: ApexFill;
};
@Component({
  selector: 'stats-payment-type',
  templateUrl: './statspaymenttype.component.html',
  styleUrls: ['./statspaymenttype.component.css']
})
export class StatspaymenttypeComponent {
  @Input() report: DashboardPaymentType[] = [];
  brandCurrency: string = '';
  selctedLang = 'en';
  @ViewChild('chartpaymenttype', { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  chartheight: any = 30;
  constructor(private translate: TranslateService,
    private localStorageService: LocalStorageService) {
  }


  ngOnInit() {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    //if (this.report !== undefined && this.report !== null) {
    //  this.load();
    //}
  }
  ngOnChanges() {
    if (!this.report)
      this.report = [];

    this.brandCurrency = (this.brandCurrency !== null && this.brandCurrency !== undefined && this.brandCurrency !== '') ? this.translate.instant(myGlobals.brandCurrency(this.localStorageService)) : '';
    this.load();
  }
  load() {
    let amount = [];
    let categories = [];
    this.report.forEach(val => {
      if (val.name !== null && val.name !== undefined && val.name !== '') {
        amount.push(val.amount);
        categories.push(this.translate.instant(val.name) + ' (' + val.percentage.toFixed(2) + '%) ' + ' ' + this.brandCurrency);
      }
    });

    this.chartOptions = {
      series: [
        {
          data: amount
        }
      ],
      chart: {
        toolbar: {
          show: false,
        },
        type: "bar",
        animations: {
          enabled: false
        },
        height: categories.length * this.chartheight
      },
      plotOptions: {
        bar: {
          barHeight: "80%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      colors: [
        "#855CF8"
      ],

      dataLabels: {
        enabled: true,
        
        textAnchor: (this.selctedLang === 'en') ? "start" : "end",
        style: {
          colors: ["#1F2532"],

          fontSize: (this.selctedLang === 'en') ? '13px' : '13px',
          fontFamily: 'Inter-Semibold'

        },

        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },

      stroke: {
        width: 0,
        colors: ["#1F2532"]
      },
      xaxis: {
        categories: categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },

        labels: {
          show: false,
          style: {
            fontSize: '13px',
            fontFamily: 'Inter-Semibold'
          }
        }

      },
      yaxis: {
        labels: {
          show: false
        }
      },


      grid: {
        show: false,
        padding: {
          left: -15,
          top: -30,
          right: -50
        }
      },
      fill: {
        opacity: 0.4
      },
      legend: {
        show: false
      },


      // title: {
      //   text: "Custom DataLabels",
      //   align: "center",
      //   floating: true
      // },
      // subtitle: {
      //   text: "Category Names as DataLabels inside bars",
      //   align: "center"
      // },
      tooltip: {
        enabled:false,
        theme: "dark",
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return "";
            }
          }
        }
      }
    };
  }

}
