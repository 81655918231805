import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { LicenseService } from 'src/app/_services/license.service';
import { CommonService } from 'src/app/_services/common.service';
import { LocationService } from 'src/app/_services/location.service';
import { AlertService } from 'src/app/_services/alert.service';
import { DevicesService } from 'src/app/_services/devices.service';

@Component({
  selector: 'app-adddevice',
  templateUrl: './adddevice.component.html',
  styleUrls: ['./adddevice.component.css']
})
export class AdddeviceComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  Licenses: any;
  isdisableLoc: boolean = false;
  Locations: any;
  DeviceTypes: any;
  submitted = false;
  deviceForm: UntypedFormGroup;
  Location = "";
  private selectedBrand;
  loading = false;
  loadingDevice = false;
  ButtonText = "Save";
  partnerReference: boolean = false;
  checkZatcaCompliance: boolean = false;
  deviceZATCAComplianced: boolean = false;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private license: LicenseService,
    private location: LocationService,
    private deviceType: CommonService,
    private alertService: AlertService,
    private deviceService: DevicesService
  ) {
    this.createForm();
    this.loadLocations();
    this.loadDeviceType();
    this.checkPartnerReference();
    //this.loadLicenses();

  }

  ngOnInit() {
    this.Location = LocationService.locationId.toString();
    this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;
  }

  private createForm() {

    this.deviceForm = this.formBuilder.group({
      Location: new UntypedFormControl({ value: "", disabled: true }, Validators.required), //DataService.Name,
      deviceTypeID: [0, Validators.required],
      deviceType: [''],
      terminalName: ['', Validators.required],
      code: [''],
      refCode: ['', Validators.required],
      statusID: [true, Validators.required],
      licenseID: [0, Validators.required],
      cdsCode: [''],
      isCDS: [false],
      locationID: LocationService.locationId,
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      isUsed: [false, Validators.required],
      terminalID: 0,
      rowVersion: 0,
      highSafety: [false],
      partnerReferenceID: [''],
      vendorIdentifier: [''],
      devicePlatform: [''],
      deviceModel: [''],
      isLinked: [false]
    });
  }



  private editForm(device) {
    this.deviceZATCAComplianced = device.zatcaComplianced;

    this.f.terminalName.setValue(device.terminalName);
    this.f.deviceTypeID.setValue(device.deviceTypeID);
    this.f.locationID.setValue(device.locationID);
    this.f.deviceType.setValue(device.deviceType);
    this.loadLicenses();
    this.f.code.setValue(device.code);
    this.f.refCode.setValue(device.refCode);
    this.f.licenseID.setValue(device.licenseID);
    this.f.cdsCode.setValue(device.cdsCode);
    this.f.isCDS.setValue(device.isCDS);
    this.f.isUsed.setValue(device.isUsed);
    this.f.rowVersion.setValue(device.rowVersion);
    this.f.statusID.setValue(device.statusID === 1 ? true : false);
    this.f.terminalID.setValue(device.terminalID);
    this.f.highSafety.setValue(device.highSafety);
    this.f.partnerReferenceID.setValue(device.partnerReferenceID);
    this.f.vendorIdentifier.setValue(device.vendorIdentifier);
    this.f.devicePlatform.setValue(device.devicePlatform);
    this.f.deviceModel.setValue(device.deviceModel);
    this.f.isLinked.setValue(device.isLinked);

  }



  get f() { return this.deviceForm.controls; }

  ngAfterViewInit() {
    // document.getElementById("terminalName").focus();
  }

  checkPartnerReference() {
    this.deviceService.checkIntegrationByBrandId(this.f.brandID.value).subscribe((res: any) => {
      if (res && res === 1)
        this.partnerReference = true;
    });
  }

  loadLicenses() {
    if (this.DeviceTypes !== null && this.DeviceTypes !== undefined) {
      let type = '';
      if (this.deviceForm.get('deviceTypeID').value !== "0")
        type = (this.DeviceTypes.filter(v => v.deviceTypeID == this.deviceForm.get('deviceTypeID').value)[0].deviceType) === 'POS' ? 'TR' : this.DeviceTypes.filter(v => v.deviceTypeID == this.deviceForm.get('deviceTypeID').value)[0].deviceType;
      else
        type = '0';
      this.license.getLicenses(type).subscribe((res: any) => {
        this.Licenses = res;
      });
    }
  }
  private loadLocations() {
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }
  private loadDeviceType() {
    this.deviceType.getDeviceType().subscribe((res: any) => {
      this.DeviceTypes = res;
      this.setSelectedDevice();
    });
  }

  setSelectedDevice() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingDevice = true;
      this.f.terminalID.setValue(this.SelectedID);
      this.deviceService.getById(this.SelectedID).subscribe(locationRes => {

        //Set Forms
        this.editForm(locationRes);
        this.loadingDevice = false;


      });
    }
  }


  onSubmit() {
    this.deviceForm.markAllAsTouched();
    if (this.DeviceTypes.length > 0 && parseInt(this.f.deviceTypeID.value) == 0) {
      this.alertService.error("Please Select Device Type");
      return false;
    }
    if (this.Licenses.length > 0 && parseInt(this.f.licenseID.value) == 0) {
      this.alertService.error("Please Select License");
      return false;
    }
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    // stop here if form is invalid


    this.f.licenseID.setValue(parseInt(this.f.licenseID.value));
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    this.f.deviceType.setValue(this.f.deviceType.value);
    if (this.deviceForm.invalid) { return; }
    this.loading = true;

    if (parseInt(this.f.terminalID.value) === 0) {

      //Insert Location
      console.log(JSON.stringify(this.deviceForm.value));
      this.deviceService.insert(this.deviceForm.value).subscribe(data => {
        this.alertService.success("Device has been created");
        this.loading = false;
        this.activeModal.dismiss();
        // this.router.navigate(["restaurant/location/edit", 'devices']);
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update Location
      this.deviceService.update(this.deviceForm.value).subscribe(data => {
        this.alertService.success("Device has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }


  deactive() {
    this.deviceService.unlink(parseInt(this.f.terminalID.value), this.f.rowVersion.value).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Device has been Unlinked");
    }, error => {
      this.alertService.error(error);
      this.loading = false;
      this.activeModal.dismiss();
    });
  }


  close() {
    this.activeModal.dismiss();
  }

  unlinkDevice() {
    this.f.licenseID.setValue(parseInt(this.f.licenseID.value));
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.deviceType.setValue(this.f.deviceType.value);
    this.f.isUsed.setValue(false);
    if (this.deviceForm.invalid) { return; }

    //Update Location
    this.deviceService.update(this.deviceForm.value).subscribe(data => {
      this.alertService.success("Device has been Unlinked");
      this.loading = false;
      this.SelectedID = 0;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
      this.activeModal.dismiss();
    });

  }
  copyToClipboard(key) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(key).then(() => {
      }, (error) => {
        console.log(error)
      });
    } else {
      console.log('Browser do not support Clipboard API');
      this.alertService.error("Browser do not support Clipboard API");
    }
  }
  copy() {
    this.copyToClipboard(this.f.code.value);
    this.alertService.success("Device key copied");
  }
  format(code) {
    var format = '';
    try {
      format = code.match(/.{1,3}/g).join(' ');
    } catch (e) {
      format = code;
    }
    return format;
  }
}
