<div class="page-wrapper marn-sidebar-theme toggled">
  <div class="sidebar">
    <div class="page-wrapper marn-sidebar-theme toggled">
      <a id="show-sidebar">
        <img src="/assets/images/icons/menu.svg">
      </a>
      <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
          <div id="close-sidebar" style="display:none" class="move-to-end px-2 py-1">
            <img src="/assets/images/icons/close.svg">
          </div>
          <div class="header-sidebar-logo align-items-center">
            <div class="user-pic">
              <img class="img-responsive img-rounded" src="assets/images/logo-full.svg" alt="User picture" />
            </div>
            <div class="brand-info">
              <span class="user-name" [placement]="['bottom']" [ngbPopover]="popContent">
                <!--<img src="/assets/images/brand-logo.png" placement="bottom" [ngbPopover]="popContent" style="   border: 2px solid #FFF; width: 42px; height: 42px; border-radius: 10px; margin-top: -4px;" />-->
                <img src="{{SelectedBrand?.BrandLogo}}" placement="bottom" style="border: 2px solid #FFF; width: 42px; height: 42px; border-radius: 10px; margin-top: -4px;filter: drop-shadow(0px 2px 6px #D0D4D9);"
                     onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
              </span>
              <ng-template #popContent>
                <p class="dropdown-heading-mini ">{{'CURRENT BRAND' | translate}}</p>
                <a class="dropdown-item text-truncate w-75" (click)="editBrand(SelectedBrand)">
                  <img style="margin-left: 0 !important;" src="{{SelectedBrand?.BrandLogo}}" placement="bottom" class="img-circle wh20 m-1"
                       onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                  <span role="button" placement="bottom" ngbTooltip="{{SelectedBrand.BrandName}}">
                    {{SelectedBrand.BrandName}}
                  </span>
                  <img class="edit-brand-logo " src="/assets/images/edit-brand.svg " />
                </a>
                <div class="inner-content ">
                  <p class="dropdown-heading-mini ">{{'SWITCH TO' | translate }}</p>
                  <a class="dropdown-item " style="padding: 4px;" (click)="changeBrand(brand)" *ngFor="let brand of Brands" [hidden]="brand.BrandID == SelectedBrand.BrandID">
                    <img style="margin-left: 0 !important;" src="{{brand?.BrandLogo}}" placement="bottom" class="img-circle wh20 m-1"
                         onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                    {{brand.BrandName}}
                  </a>
                </div>
                <div class="selected-inner-bottom">
                  <!--<svg width="18" height="18" class="opacity-3" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95928 0C4.01753 0 0 4.03579 0 9C0 13.9642 4.01753 18 8.95928 18C13.901 18 17.9186 13.9642 17.9186 9C17.9186 4.03579 13.901 0 8.95928 0Z" fill="#1F2532" />
            <path d="M12.5292 8.32299H9.63321V5.4138C9.63321 5.04786 9.34179 4.73682 8.95929 4.73682C8.59501 4.73682 8.28537 5.02957 8.28537 5.4138V8.32299H5.38934C5.02506 8.32299 4.71542 8.61574 4.71542 8.99997C4.71542 9.18294 4.78828 9.34761 4.91578 9.47569C5.04328 9.60377 5.2072 9.67696 5.38934 9.67696H8.28537V12.5862C8.28537 12.7691 8.35823 12.9338 8.48573 13.0619C8.61322 13.1899 8.77715 13.2631 8.95929 13.2631C9.32357 13.2631 9.63321 12.9704 9.63321 12.5862V9.67696H12.5292C12.8935 9.67696 13.2032 9.38421 13.2032 8.99997C13.2032 8.61574 12.8935 8.32299 12.5292 8.32299Z" fill="white" />
          </svg>
          <span class="create-brand opacity-3"> {{ 'Create New Brand' | translate }}</span>-->
                  <a class="dropdown-item selected-inner-bottom p-0 pt-2" (click)="openBrand()" routerLinkActive="active">
                    <img src="/assets/images/create-brand-icon.svg " class="px-1"/>{{ 'Create New Brand' | translate }}
                  </a>

                  <span class="contact-sales" (click)="openEmailConformation()">{{ 'Contact Sales' | translate }}</span>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="sidebar-menu">
            <ul *ngIf="applyDynamicMenu">
              <ng-container *ngFor="let parent of rights">
                <li [ngClass]="(verifySubmenu(parent) === 0 || (parent.WebPath !==null && parent.WebPath!==undefined && parent.WebPath!=='')) ? 'sidebar' : 'sidebar-dropdown'"
                    *ngIf="parent.FormName.trim().toLowerCase() !== 'quick actions'">
                  <a [routerLink]="['/'+industryURLName+'/'+parent.WebPath]" *ngIf="verifySubmenu(parent) === 0 || (parent.WebPath !==null && parent.WebPath!==undefined && parent.WebPath!=='')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <img [src]="parent.Icon" />
                    <span>{{parent.FormName | translate }}</span>
                  </a>
                  <ng-container *ngIf="verifySubmenu(parent) > 0 && (parent.WebPath ===null || parent.WebPath === undefined || parent.WebPath === '')">
                    <a *ngIf="parent.IsMenuItem === true">
                      <img [src]="parent.Icon" />
                      <span>{{ parent.FormName | translate }}</span>
                    </a>
                    <div class="sidebar-submenu" *ngIf="parent.WebPath===null || parent.WebPath===undefined || parent.WebPath===''">
                      <ul>
                        <li *ngFor="let childRights of parent.ChildRights">
                          <a *ngIf="childRights.FormName.trim().toLowerCase() !== 'receipt' && childRights.IsMenuItem === true" [routerLink]="[ '/'+industryURLName+'/'+childRights.WebPath]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            {{childRights.FormName | translate }}
                            <span class="border m-0 rounded-1 px-2 menu-tag accouting" *ngIf="childRights.FormName === 'Accounting'">{{ 'Lite' | translate }}</span>
                          </a>
                          <a *ngIf="childRights.FormName.trim().toLowerCase() === 'receipt' && childRights.IsMenuItem === true" (click)="openReceipt()">{{childRights.FormName | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                </li>
                <!--<li class="header-menu" *ngIf="parent.FormName === 'Quick Actions'">
          <span>{{'QUICK ACTIONS' | translate }}</span>
        </li>-->
                <!--<li *ngIf="parent.FormName === 'Quick Actions'" *ngFor="let childRights of parent.ChildRights">
          <a>
            <img ng-src="childRights.Icon" />
            <span>{{childRights.FormName | translate }}</span>
          </a>
        </li>-->
                <ng-container *ngIf="parent.FormName.trim().toLowerCase() === 'quick actions'">
                  <li class="header-menu text-uppercase">
                    <span>{{parent.FormName | translate }}</span>
                  </li>
                  <li *ngFor="let childRights of parent.ChildRights">
                    <a [routerLink]="[ '/'+industryURLName+'/'+childRights.WebPath]" *ngIf="childRights.IsMenuItem === true">
                      <img [src]="childRights.Icon" />
                      <span>
                        {{childRights.FormName | translate }}
                      </span>
                    </a>
                  </li>
                </ng-container>
              </ng-container>

            </ul>
            <ul *ngIf="!applyDynamicMenu">
              <li class="sidebar">
                <a [routerLink]="['/'+industryURLName]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <img src="assets/images/icons/v2/dashboard.svg" />
                  <span>{{'Dashboard' | translate }}</span>
                </a>
              </li>
              <li class="sidebar">
                <a routerLinkActive="active" [routerLink]="[ '/'+industryURLName+'/orders']" [routerLinkActiveOptions]="{exact: true}">
                  <img src="assets/images/icons/v2/orders.svg" />
                  <span>{{'Orders' | translate }}</span>
                </a>
              </li>
              <li class="sidebar-dropdown">
                <a>
                  <img src="assets/images/icons/v2/analytics.svg" />
                  <span>{{'Analytics' | translate }}</span>
                </a>
                <div class="sidebar-submenu">
                  <ul>
                    <li>
                      <a [routerLink]="[ '/'+industryURLName+'/report/salessummary']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Summary' | translate }}</a>
                    </li>
                    <li>
                      <a [routerLink]="[ '/'+industryURLName+'/customer']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Customers' | translate }}</a>
                    </li>
                    <li>
                      <a [routerLink]="[ '/'+industryURLName+'/report/generate']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Reports' | translate }}</a>
                    </li>
                    <li *hasPermission="['Analytics','Accounting']">
                      <a [routerLink]="[ '/'+industryURLName+'/liteAccounting']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        {{'Accounting' | translate }}
                        <span class="border m-0 rounded-1 px-2 menu-tag accouting">{{ 'Lite' | translate }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="sidebar-dropdown">
                <a>
                  <img src="assets/images/icons/v2/products.svg" />
                  <span>{{'Products' | translate }}</span>
                </a>
                <div class="sidebar-submenu">
                  <ul>
                    <li>
                      <a [routerLink]="['/'+industryURLName+'/item']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Items' | translate }}</a>
                    </li>
                    <li>
                      <a [routerLink]="['/'+industryURLName+'/category']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Categorisation' | translate }}</a>
                    </li>
                    <li>
                      <a [routerLink]="['/'+industryURLName+'/modifier']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Modifiers' | translate }}</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="sidebar-dropdown">
                <a>
                  <img src="assets/images/icons/v2/inventory.svg" />
                  <span>{{'Inventory' | translate }}</span>
                </a>
                <div class="sidebar-submenu">
                  <ul>
                    <li>
                      <a [routerLink]="['/'+industryURLName+'/inventorysetup']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Inventory Setup' | translate }}</a>
                    </li>
                    <li>
                      <a [routerLink]="['/'+industryURLName+'/inventoryaction']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Inventory Actions' | translate }}</a>
                    </li>
                  </ul>
                </div>
              </li>



              <li class="sidebar">
                <a [routerLink]="['/'+industryURLName+'/location']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <img src="assets/images/icons/v2/location.svg" />
                  <span>  {{'Locations' | translate }}</span>

                </a>
              </li>

              <li class="sidebar">
                <a [routerLink]="['/'+industryURLName+'/setup']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <img src="assets/images/icons/v2/setup.svg" />
                  <span>{{'Setup' | translate }}</span>

                </a>
              </li>



              <!--<li class="sidebar-dropdown">
        <a>
          <img src="assets/images/icons/v2/setup.svg" />
          <span>{{'Setup' | translate }}</span>
        </a>
        <div class="sidebar-submenu">
          <ul>
            <li>
              <a [routerLink]="['/'+industryURLName+'/branddevices']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Devices' | translate }}</a>
            </li>
            <li>
              <a [routerLink]="['/'+industryURLName+'/reasons']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Reasons' | translate }}</a>
            </li>
            <li>
              <a [routerLink]="['/'+industryURLName+'/userroles']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Users' | translate }}</a>
            </li>
            <li><a [routerLink]="['/'+industryURLName+'/location']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Locations' | translate }}</a></li>
            <li><a [routerLink]="['/'+industryURLName+'/orderType']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Order Types' | translate }}</a></li>
            <li><a (click)="openReceipt()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Receipts' | translate }}</a></li>
            <li><a [routerLink]="['/'+industryURLName+'/tax/setup']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Taxes' | translate }}</a></li>
          </ul>
        </div>
      </li>-->
              <li>
                <a [routerLink]="['/'+industryURLName+'/marketplace']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <img src="assets/images/icons/v2/marketplace.svg" />
                  <span>{{'Marketplace' | translate }}</span>
                </a>
              </li>
              <li class="header-menu">
                <span>{{'QUICK ACTIONS' | translate }}</span>
              </li>
              <li>
                <a [routerLink]="['/'+industryURLName+'/setup/discounts']">
                  <img src="assets/images/icons/v2/discount.svg" />
                  <span>{{'Discount' | translate }}</span>
                </a>
              </li>
              <li>
                <a (click)="openReceipt()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <img src="assets/images/icons/v2/receipt.svg" />
                  <span>{{'Receipt' | translate }} </span>
                </a>
              </li>
            </ul>
            <div class="location-switch remove-down-aero" style="margin: 10px;" [hidden]="SelectedLocation.Name == 'All Locations'">
              <h5 class="heading-viewing">{{'You’re viewing' | translate }}</h5>
              <div class="d-flex justify-content-center">
                <app-switchlocation></app-switchlocation>
                <a class="location-dismiss" (click)="dismissLocation()">
                  {{'Dismiss' | translate }}
                </a>
              </div>
            </div>
          </div>
          <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
          <div class="footer-container">
            <a class=" dropdown-toggle profile " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false " (click)="openProfile()">
              <img [src]="SelectedUser?.UserImage " class="img-circle wh34 " style="border: 2px solid #FFFFFF;" *ngIf="!ImageError " (error)="errorHandler($event) " />
              <span class="img-error ImageError " style="display: inline-block;" *ngIf="ImageError "> {{SelectedUser?.FirstName}} </span>
            </a>
            <label style=" margin-left: 10px;" (click)="openProfile()">{{userName}} </label>
            <img (click)="logout() " src="assets/images/icons/v2/logout.svg" class="footer-logout" />
          </div>
        </div>
      </nav>
    </div>
    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="container-fluid">
        <div class="sidebar-overlay"></div>
        <router-outlet class="page-content" (activate)="changeOfRoutes()"></router-outlet>
      </div>
    </main>
    <!-- page-content" -->
  </div>
  <!-- page-wrapper -->
</div>

