
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { MerchantService } from 'src/app/_services/merchant.service';
import { Merchant } from 'src/app/_models/marketplace';
import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';


@Component({
  selector: 'app-stcpay',
  templateUrl: './stcpay.component.html',
  styleUrls: ['./stcpay.component.css']
})
export class StcpayComponent implements OnInit {
  @Input() PartnerID = 0;
  ButtonText = "Install";
  submitted = false;
  stcForm: UntypedFormGroup;
  private selectedBrand;
  loading = false;

  constructor(public activeModal: NgbActiveModal, 
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService:AlertService,
    private services:MerchantService, 
    private _modalService:NgbModal
    ) {
    this.createForm();

   }

  ngOnInit() {
    this.setSelectedPartner();
  }




  setSelectedPartner(){

    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getMerchant(this.f.brandID.value,this.PartnerID).subscribe((res:Merchant[]) => {
        //Set Forms
        console.log(res);
        if(res !==null){
          this.editForm(res);
          this.ButtonText = "Save";
        }
        this.loading = false;
      });
    }
  }



  private createForm() {

    this.stcForm = this.formBuilder.group({

      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      merchantID: ['', Validators.required],
      statusID: [true, Validators.required],
      rowID: 0,
      partnerID:this.PartnerID

    });
  }



  private editForm(value) {

    this.f.rowID.setValue(value.rowID);
    this.f.merchantID.setValue(value.merchantID);
  
  }



  get f() { return this.stcForm.controls; }

  ngAfterViewInit(){
    
  }


  onSubmit(){
    this.stcForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.partnerID.setValue(this.PartnerID);

    if (this.stcForm.invalid) { return; }
    this.loading = true;

     const merchant = {
        rowID : this.f.rowID.value,
        merchantID :  this.f.merchantID.value,
        merchantName : "STCPay_"+this.localStorageService.getSelectedBrand().BrandName,
        merchantUrl : "-",
        merchantCategory : "Food",
        company : "STC_PAY",
        brandID : parseInt(this.localStorageService.getSelectedBrand().BrandID),
        partnerID : this.PartnerID,
        statusID: 1,
     }
 
    if (parseInt(this.f.rowID.value) === 0) {

    //   //Insert
       console.log(JSON.stringify(merchant));
      this.services.insert(merchant).subscribe(data => {
        this.alertService.success("STC Pay has installed successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

     } else {
    //   //Update
      this.services.update(merchant).subscribe(data => {
        this.alertService.success("STC Pay has saved successfully");
        this.loading = false;
        this.PartnerID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        //this.activeModal.dismiss();
      });
  }
  }


  close(){
    this.activeModal.dismiss();
  }

  uninstall() {
    this.loading = true;
    this.services.Uninstall(parseInt(this.f.rowID.value)).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("STC Pay has uninstalled successfully");
      this.loading = false;
        this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }


  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true, windowClass: 'uninstall-modal',modalDialogClass: 'uninstall-modal-custom' });
    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
        }
      });;
  }
}
