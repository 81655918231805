
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ModifierService } from 'src/app/_services/modifier.service';
import { AddmodifierComponent } from './addmodifier/addmodifier.component';
import { Modifier } from 'src/app/_models/Modifier';
import { ExcelService } from 'src/app/_services/excel.service';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';


@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  providers: [ExcelService]
})
export class ModifierComponent implements OnInit {
  active = "modifier";
  constructor() {
   
  }

  ngOnInit() {
  }

}

