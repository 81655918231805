
<div class="modal-body ">
        <div class="container">
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10 mt-5">
                    <div class="jumbotron">
                        <div class="row form-group">
                            <div class="col-lg-6 text-start">
                              <!-- <img src="assets/images/marn-logo-po.png" alt="" /> -->
                              <img class="brandLogo mt-10" [src]="localStorageService.getSelectedBrand().BrandLogo" alt="" />
                            </div>
                            <div class="col-lg-6 text-end">
                              <h3 class="font-weight-bold ">{{'Purchase Order' | translate }}</h3>
                                <h5 class=" ponum-heading ">{{PO?.poCode}}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 text-start">
                              <div [ngClass]="renderStatusClass(PO?.statusID)" class="btn btn-sm">
                                {{ (PO?.statusID ==600?'Created': PO?.statusID ==601?'Approved': PO?.statusID ==602?'Pending': PO?.statusID ==603?'Declined': PO?.statusID ==604?'Hold': PO?.statusID ==605?'Received': PO?.statusID ==605?'Returned': '-' ) | translate}}
                              </div>
                            </div>
                            <div class="col-lg-6 text-end">
                              <h6 class="font-weight-bold ">{{'Ref' | translate }}# {{PO?.referenceNo}}</h6>
                            </div>
                        </div>
                        <div class="my-4 divider"></div>
                        <div class="row mb-5">
                            <div class="col-lg-6 text-start">
                              <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                                <label>{{PO?.date | date}}</label>
                            </div>
                            <div class="col-lg-6 text-end">
                              <h6 class="font-weight-bold ">{{'Expected Date' | translate }}</h6>
                                <label>{{PO?.expectedDate | date}}</label>
                            </div>
                        </div>

                        <div class="row  mb-5">
                            <div class="col-lg-6 text-start">
                              <h6 class="font-weight-bold po-preview-blue ">{{'Supplier Address' | translate }}</h6>
                                <h6 class="font-weight-bold ">{{PO.supplierName}}</h6>
                                <p>{{PO.supplierAddress}}</p>
                                <label>{{PO.supplierContact}}</label>
                            </div>
                            <div class="col-lg-6 text-end">
                              <h6 class="font-weight-bold po-preview-blue ">{{'Deliver to' | translate }}</h6>
                                <h6 class="font-weight-bold ">{{PO.locationName}}</h6>
                                <p>{{PO.locationAddress}}</p>
                                <label>{{PO.locationContact}}</label>
                            </div>
                        </div>

                        <div class="tile">
                        <table class="table table-striped">
                            <thead class="thead-dark">
                                <tr>
                                  <th width="45%" scope="col">{{'Item Name' | translate }}</th>
                                  <th width="15%" scope="col">{{'Quantity' | translate }}</th>
                                  <th width="20%" scope="col">{{'Unit Price' | translate }}</th>
                                  <th width="20%" scope="col">{{'Total' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of PO.poDetails">
                                    <td scope="row">{{item.name}}</td>
                                    <td>{{item.quantity}}</td>
                                    <td>{{item.cost}} {{'SAR' | translate }}</td>
                                    <td class="font-weight-bold text-right">{{item.quantity * item.cost}} {{'SAR' | translate }}</td>
                                </tr>

                                <tr class="po-summary">
                                    <td colspan="2" scope="row">
                                      <h6 class="font-weight-light" *ngIf="PO.notes!=''">{{'Notes' | translate }}:</h6>
                                        <p>{{PO.notes}}</p>
                                    </td>

                                    <td colspan="2">
                                        <br/>
                                        <div class="row form-group">
                                            <div class="col-lg-6 text-start">
                                              <h6 class="font-weight-bold ">{{'Sub Total' | translate }}</h6>
                                            </div>
                                            <div class="col-lg-6 text-end">
                                              <h6 class="font-weight-bold ">{{PO.subTotal}} {{'SAR' | translate }}</h6>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-lg-6 text-start">
                                              <h6 class="font-weight-bold ">{{'Tax' | translate }} 15%</h6>
                                            </div>
                                            <div class="col-lg-6 text-end">
                                              <h6 class="font-weight-bold ">{{PO.taxAmount}} {{'SAR' | translate }}</h6>
                                            </div>
                                        </div>
                                        <div class="row form-group po-summary-total">
                                            <div class="col-lg-6 text-start">
                                              <h6 class="font-weight-bold ">{{'Total Amount' | translate }}</h6>
                                            </div>
                                            <div class="col-lg-6 text-end">
                                              <h6 class="font-weight-bold ">{{PO.grandTotal}} {{'SAR' | translate }}</h6>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>

                </div>
                <div class="col-lg-1"></div>
            </div>
        </div>
    </div>
