import { Component, ViewChild, Input } from '@angular/core';
import { first } from 'rxjs/operators';

import { DashboardService } from '../../_services/dashboard.service';


import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexMarkers,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  ApexFill,
  ApexLegend,
  ApexPlotOptions
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  markers: ApexMarkers;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  legend: ApexLegend;
};


@Component({
  selector: 'app-sales-analytics',
  templateUrl: './sales-analytics.component.html',
  styleUrls: ['./sales-analytics.component.css']
})
export class SalesAnalyticsComponent {
  public salesAnalyticsLineOptions:  Partial<ChartOptions>;
  public totalSales = 0;
  public percentage = 0;
  public loading = true;


  constructor(
    private dashboardService: DashboardService
  ) {

    this.salesAnalyticsLineOptions = {};
  }

  ngOnInit() {
    //this.loadSalesAnalytics();
  }


  private loadSalesAnalytics() {
    this.loading = true;
    this.dashboardService.getAnalytics(2, 3, '2020-04-06-00-00-00')
      .pipe(first())
      .subscribe(
        data => {
          const categoriesData: string[] = [];
          const seriesData: number[] = [];
          this.percentage = data.length > 0 ? data[0].percentage : 0;

          for (let i = 0; i < data.length; i++) {
            categoriesData.push(data[i].timeSlot);
            seriesData.push(data[i].sales);

            this.totalSales += data[i].sales;
          }
          this.salesAnalyticsLineOptions = {
            series: [{
              name: "",
              data: seriesData
            }],
            chart: {
              height: 200,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              }
            },
            markers: {
              size: 5,
              strokeWidth: 3,
              colors: ['#fff'],
              strokeColors: ['#576cf5', '#00d7e0'],
            },
            colors: ['#576cf5'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight',
              width: 3,
            },
            grid: {
              row: {

                opacity: 1
              },
            },
            xaxis: {
              categories: categoriesData,
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              crosshairs: {
                show: false,
              },
              labels: {
                style: {
                  fontSize: '11px',
                  fontFamily: 'Montserrat-SemiBold'
                }
              }
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '11px',
                  fontFamily: 'Montserrat-SemiBold'
                },
                formatter: function (val) {
                  return (val < 1000 ? val.toString() : (val / 1000) + 'K');
                }
              }
            }
          };
          this.loading = false;
          console.log(data);
        },
        error => {
          console.log(error);
          this.loading = false;
        });

  }
}
