import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { InventoryCountService } from '../../../_services/inventory-count.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import * as myGlobals from '../../../_helpers/globals';
import { InventoryCountDetails } from '../../../_models/InventoryCount';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UnitService } from '../../../_services/unit.service';
import { UploadInventoryCountComponent } from './upload-inventory-count/upload-inventory-count.component';
import { PdfService } from '../../../_services/pdf.service';

@Component({
  selector: 'app-add-inventory-count',
  templateUrl: './add-inventory-count.component.html',
  styleUrl: './add-inventory-count.component.css'
})
export class AddInventoryCountComponent {
  @Input() SelectedID = 0;
  Items = [];
  Locations = [];
  icForm: UntypedFormGroup;
  Units = [];
  selectedLang = 'en';
  ButtonText = "Save";
  loading: boolean = false;
  arrItems: InventoryCountDetails[] = [];
  data = null;
  public stockCountDetails = new InventoryCountDetails();
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private unitService: UnitService,
    private icService: InventoryCountService,
    private modalService: NgbModal,
    private pdfService: PdfService,
    private localStorageService: LocalStorageService
  ) {
    this.createForm();
    this.selectedLang = this.localStorageService.getSelectedLanguage();
  }

  ngOnInit() {
    this.setSelectedInventoryCount();
    this.loadLocations();
    this.loadUnits();

    if (this.f.icid.value == 0)
      this.generateIC();
  }
  generateIC() {
    this.icService.generateICno(this.f.brandID.value).subscribe((res: any) => {
      this.f.icCode.setValue(res.code);

    });
  }

  get f() { return this.icForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }
  setSelectedInventoryCount() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loading = true;
      this.f.icid.setValue(this.SelectedID);
      this.icService.getById(this.SelectedID).subscribe(res => {
        //Set Forms
        this.data = res;
        this.editForm(res);
        this.CalculateSummary();
        //this.getLogs(this.SelectedID);
        this.loading = false;
      });
    }
  }
  private editForm(ic) {

    this.f.icCode.setValue(ic.icCode);

    this.f.locationID.setValue(ic.locationID);
    this.f.notes.setValue(ic.notes);
    this.f.statusID.setValue(ic.statusID);
    this.f.brandID.setValue(parseInt(this.ls.getSelectedBrand().BrandID));
    this.f.totalItems.setValue(ic.totalItems);
    this.f.totalLosses.setValue(ic.totalLosses);
    this.f.totalMiscounted.setValue(ic.totalMiscounted);
    this.f.totalDifference.setValue(ic.totalDifference);
    this.f.stockCountDetails.setValue(ic.stockCountDetails);
    this.arrItems = ic.stockCountDetails;
    this.f.stockCountLog.setValue(ic.stockCountLog);

    this.f.locationID.disable();
  }

  private createForm() {

    this.icForm = this.formBuilder.group({
      icid: [0],
      icCode: [''],
      locationID: [Validators.required],
      notes: [''],
      statusID: [602, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      totalItems: 0,
      totalLosses: 0,
      totalMiscounted: 0,
      totalDifference: 0,
      stockCountDetails: [],
      stockCountLog: []
    });
  }

  loadUnits() {
    this.unitService.getUnits().subscribe((res: any) => {
      this.Units = res;
      //this.f.unitID.setValue(res[0].unitID);
    });
  }
  loadLocations() {
    this.loading = true;
    this.icService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
      if (this.Locations.length > 0) {

        if (this.f.icid.value == 0)
          this.f.locationID.setValue(res[0].locationID);

        this.loading = false;
        this.loadItems();
      }
    });
  }
  loadItems() {
    this.loading = true;
    this.icService.getInvetoryById(this.f.locationID.value).subscribe((res: any) => {
      this.Items = res;
      this.loading = false;
    });
  }
  close() {
    this.activeModal.dismiss();
  }

  deleteRow(obj) {

    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);
    this.isSelectAll = false;
    this.CalculateSummary();
  }

  addRow(obj) {
    if (!this.arrItems.find(element => element.supplyID == obj.supplyID)) {
      if (obj.quantity) {
        obj.difference = obj.quantity - obj.currentStock;
        obj.totalCost = obj.difference * obj.cost;
        obj.quantity = (obj.quantity) ? obj.quantity : 0;
      }
      else {
        obj.totalCost = 0;
        obj.difference = 0;
        obj.quantity = 0;
      }
      this.arrItems.push({
        supplyID: obj.supplyID,
        cost: obj.cost,
        avgCost: obj.avgCost,
        totalCost: obj.totalCost,
        name: obj.name,
        alternateName: obj.alternateName,
        type: obj.type,
        statusID: 600,
        quantity: obj.quantity,
        unit: obj.unit,
        itemImage: obj.itemImage,
        currentStockQTY: obj.currentStock,
        difference: obj.difference,
        icid: 0,
        id: 0
      });

      this.CalculateSummary();
    }
    else {
      this.alertService.error("Item already added in list");
    }

  }
  UpdateQty(item) {
    if (item.quantity) {
      item.difference = item.quantity - item.currentStockQTY;
      item.totalCost = item.difference * item.cost;
    }
    else {
      item.difference = 0;
      item.totalCost = 0;
    }

    this.CalculateSummary();

  }
  closeSelect(select: NgSelectComponent) { select.close(); }
  isSelectAll = false;
  public onSelectAll(select) {
    this.arrItems = [];
    this.isSelectAll = true;
    this.Items.forEach(item => {
      this.stockCountDetails.supplyID = item.supplyID;
      this.addRow(item);
    });
    this.closeSelect(select);
  }

  public onDeSelectAll(select) {
    this.isSelectAll = false;
    this.arrItems = [];
    this.closeSelect(select);
  }
  SearchItem(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.barcode.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.alternateName.toLocaleLowerCase().indexOf(term) > -1 || item.sku.toLocaleLowerCase().indexOf(term) > -1;
  }
  CalculateSummary() {
    let losses = 0;
    let miscount = 0;
    this.arrItems.forEach(e => {
      if (e.totalCost > 0) {
        miscount += e.totalCost;
      }
      else {
        losses += e.totalCost;
      }
    });
    this.f.totalLosses.setValue(losses);
    this.f.totalMiscounted.setValue(miscount);
    this.f.totalDifference.setValue(losses + miscount);
  }
  import() {
    const modalRef = this.modalService.open(UploadInventoryCountComponent, { size: 'xl', windowClass: 'small-modal' });
    modalRef.componentInstance.Items = this.Items;
    modalRef.result.then((result) => {
      let data = result;
      result.forEach(e => {
        this.addRow(e);
      });
    }, (r) => {

      //this.getBrandData();
    });


  }
  onSubmit(status) {

    this.icForm.markAllAsTouched();

    // reset alerts on submits
    this.alertService.clear();

    if (this.arrItems.length == 0) {
      this.alertService.error("Items cannot be empty.");
      return;
    }


    if (this.icForm.invalid) { return; }
    this.loading = true;


    if (status === "save") {
      this.f.statusID.setValue(602);
    }
    else if (status === "approve") {
      this.f.statusID.setValue(601);
    }
    else if (status === "decline") {
      this.f.statusID.setValue(603);
    }
    this.f.locationID.enable();
    this.f.stockCountDetails.setValue(this.arrItems);
    this.f.totalItems.setValue(this.arrItems.length);

    this.icService.insert(this.icForm.value).subscribe(data => {
      this.alertService.success("Inventory Count has been created");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      if (this.SelectedID !== 0)
        this.f.locationID.disable();
      this.alertService.error(error);
      this.loading = false;
    });

  }
  public generatePDF() {
    this.loading = true;
    this.pdfService.GenerateInventoryCountPDF(this.data).then(x => {
      this.loading = false;
      const data = `data:application/pdf;base64,${x}`;
      var link = document.createElement('a');
      link.href = data;
      link.download = "InventoryCount-PDF.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    });


  }
}
