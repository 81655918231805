<div class="tile-body" #dailychart>
    <apx-chart [series]="todaySummaryLineOptions?.series"
               [chart]="todaySummaryLineOptions?.chart"
               [markers]="todaySummaryLineOptions?.markers"
               [colors]="todaySummaryLineOptions?.colors"
               [dataLabels]="todaySummaryLineOptions?.dataLabels"
               [stroke]="todaySummaryLineOptions?.stroke"
               [title]="todaySummaryLineOptions?.title"
               [grid]="todaySummaryLineOptions?.grid"
               [xaxis]="todaySummaryLineOptions?.xaxis"
               [yaxis]="todaySummaryLineOptions?.yaxis"
               [legend]="todaySummaryLineOptions?.legend"></apx-chart>
  </div>
