<main class="main views container order-list">
  <div class="page">
    <div class="page-body">
      <div class="page-header">
        <div class="tile-header tCustom-header">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
              <h4> {{"Orders" | translate }}</h4>
            </div>
            <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12" *hasPermission="['Orders','Filter']">
              <div class="top-bar-flex">
                <div class="update-toggle">
                  <form [formGroup]="orderForm" class="form order-update-toggle">
                    <div class="">
                      <label for="Item Status">{{'Live Updates' | translate }}</label>
                      <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="liveUpdate" formControlName="liveUpdate" (click)="ConnectWebSocket($event)">
                        <label class="form-check-label" for="liveUpdate"></label>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="location-tab">
                  <div ngbDropdown>
                    <a ngbDropdownToggle class="form-control text-center selected-location" style=" text-decoration: auto; color: #000; width: fit-content !important">
                      <span>{{selectedLocationName | translate}}</span>
                      <img class="down-aero" src="assets/images/down-aero.svg">
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropaero-menu">
                      <a class="dropdown-item" [hidden]="selectedLocationName == 'All Locations' " (click)="setLocation(null)">{{'All Locations' | translate}} </a>
                      <a class="dropdown-item" *ngFor="let location of LocationsList" [hidden]="location.name == selectedLocationName"
                         (click)="setLocation(location)">{{location.name}} </a>
                    </div>
                  </div>
                </div>
                <div class="date-tab">
                  <span class="show-filter-icon float-left calendar-icon" (click)="d.toggle()" type="button">
                    <img src="/assets/images/calendar.svg" style="margin-top: -4px;" alt="Calender">
                  </span>

                  <input (click)="d.toggle()" class="form-control selected-location selected-date bg-white" readonly="readonly" placeholder="{{selectedDate}}" (dateSelect)="setDate($event)" name="dp" today="true" selected="true" [(ngModel)]="dtModel" ngbDatepicker #d="ngbDatepicker">
                  <span class="aero-icon"><img src="/assets/images/down-aero.svg" alt="" (click)="d.toggle()"></span>
                </div>
                <div class="filter-field">
                  <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="((data$ | async)?.length > 0)">
        <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
          <div class="tile-body p-0 table-responsive text-nowrap">
            <table class="table table-striped table-custom">
              <thead>
                <tr class="table-header">
                  <!--<th width="5%"> <input type="checkbox" (change)="bulkSelection($event)"/></th>-->
                  <th width="10%" sortable="orderNo" (sort)="onSort($event)">
                    {{'Order' | translate }}
                    <img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th width="15%">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Order Taker' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllOrderTakers" (ngModelChange)="selectAllFilters($event,orderTakerList)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,orderTakerList)">
                            {{'All Cashiers' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let taker of orderTakerList">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="taker.isChecked" (ngModelChange)="filterOrders()">
                            <label class="form-check-label" (click)="filterOrders()">
                              {{taker.name}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th width="10%">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Order Types' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllOrderTypes" (ngModelChange)="selectAllFilters($event,orderTypeList)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,orderTypeList)">
                            {{'All Types' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let orderType of orderTypeList">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="orderType.isChecked" (ngModelChange)="filterOrders()">
                            <label class="form-check-label" (click)="filterOrders()">
                              {{orderType.name}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th width="10%" sortable="orderType" (sort)="onSort($event)">
                    {{'Table' | translate }} <img class="d-inline"
                                                  src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th width="10%">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'State' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllOrderState" (ngModelChange)="selectAllFilters($event,orderStateList)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,orderStateList)">
                            {{'All State' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let orderState of orderStateList">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="orderState.isChecked" (ngModelChange)="filterOrders()">
                            <label class="form-check-label" (click)="filterOrders()">
                              {{orderState.name | translate}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th width="10%">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Status' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllOrderStatus" (ngModelChange)="selectAllFilters($event,orderStatusList)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,orderStatusList)">
                            {{'All Status' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let orderStatus of orderStatusList">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="orderStatus.isChecked" (ngModelChange)="filterOrders()">
                            <label class="form-check-label" (click)="filterOrders()">
                              {{orderStatus.name | translate}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th width="10%">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'Payment' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllOrderPayments" (ngModelChange)="selectAllFilters($event,paymentList)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,paymentList)">
                            {{'All Payments' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let payment of paymentList">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="payment.isChecked" (ngModelChange)="filterOrders()">
                            <label class="form-check-label" (click)="filterOrders()">
                              {{payment.name | translate}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th width="10%" sortable="grandTotal" (sort)="onSort($event)">
                    {{'Total' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>
                  <th width="10%" *ngIf="checkZatcaCompliance">
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" ngbDropdownToggle>
                        <span>{{'ZATCA' | translate}}</span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                           class="dropdown-header-filter-body">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllZatcaStatus" (ngModelChange)="selectAllFilters($event,reportedToZATCAList)">
                          <label class="form-check-label font-blue" (click)="selectAllFilters($event,reportedToZATCAList)">
                            {{'All Status' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let zatcaStatus of reportedToZATCAList">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="checkbox" class="form-check-input"
                                   [(ngModel)]="zatcaStatus.isChecked" (ngModelChange)="filterOrders()">
                            <label class="form-check-label" (click)="filterOrders()">
                              {{zatcaStatus.name | translate}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th width="10%" sortable="created" (sort)="onSort($event)">
                    {{'Created' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                  </th>

                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let order of data$ | async " editRow>
                  <!--<td>
                    <input type="checkbox" (change)="chkSelection($event,order)"
                           [(ngModel)]="order.selected" />
                  </td>-->
                  <td (click)="Edit(order);">
                    <div class="order-partner-logo">
                      <img *ngIf="order.partnerLogo !== ''" [src]="order.partnerLogo" loading="lazy" alt="">
                    </div>
                    <label class="highlight-title-blue">{{order.orderNo}}</label>
                  </td>
                  <td (click)="Edit(order);"> {{order.orderTaker}} </td>
                  <td (click)="Edit(order);"> {{order.orderType | translate}} </td>
                  <td (click)="Edit(order);"> {{order.tableTitle | translate}} </td>
                  <td (click)="Edit(order);">
                    <ngb-highlight *ngIf="order.orderState === 'Waiting'" class="btn btn-sm bullet-button waiting" result="{{order.orderState | translate}}">
                      <!-- btn-inactive -->
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.orderState === 'In Kitchen' || order.orderState === 'Preparing'" class="btn btn-sm bullet-button kitchen" result="{{order.orderState | translate}}">
                      <!-- <ngb-highlight *ngIf="order.orderState === 'In Progress'" class="btn btn-sm btn-badge-yellow " result="{{order.orderState | translate}}"> -->
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.orderState === 'Ready'" class="btn btn-sm bullet-button ready " result="{{order.orderState | translate}}">
                      <!-- btn-badge-blue -->
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.orderState === 'Served'" class="btn btn-sm bullet-button served" result="{{order.orderState | translate}}">
                      <!-- btn-active -->
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.orderState === 'On the Way'" class="btn btn-sm bullet-button on-way" result="{{order.orderState | translate}}">
                      <!-- btn-active -->
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.orderState === 'Delivered'" class="btn btn-sm  bullet-button delivered" result="{{order.orderState | translate}}">
                      <!-- btn-active -->
                    </ngb-highlight>
                  </td>
                  <td (click)="Edit(order);">
                    <ngb-highlight *ngIf="order.orderStatus === 'Hold'" class="btn btn-sm  btn-badge-blue hold" result="{{order.orderStatus | translate}}">
                    </ngb-highlight>

                    <ngb-highlight *ngIf="order.orderStatus === 'Partial Refund' || order.orderStatus === 'Refund' || order.orderStatus === 'Void' || order.orderStatus === 'Refunded Order'" class="btn btn-sm btn-inactive refund" result="{{order.orderStatus | translate}}">
                    </ngb-highlight>

                    <ngb-highlight *ngIf="order.orderStatus === 'Pending'" class="btn btn-sm btn-badge-yellow pending" result="{{order.orderStatus | translate}}">
                    </ngb-highlight>

                    <ngb-highlight *ngIf="order.orderStatus === 'Paid'" class="btn btn-sm btn-active paid" result="{{order.orderStatus | translate}}">
                    </ngb-highlight>

                    <ngb-highlight *ngIf="order.orderStatus !== 'Hold' &&  order.orderStatus !== 'Partial Refund' && order.orderStatus !== 'Refund' && order.orderStatus !== 'Void' && order.orderStatus !== 'Refunded Order' && order.orderStatus !== 'Pending' && order.orderStatus !== 'Paid' " class="btn btn-sm btn-active paid" result="{{order.orderStatus | translate}}">
                    </ngb-highlight>
                  </td>
                  <td (click)="Edit(order);">
                    <div class="payment-type">
                      <img *ngIf="order.paymentMode === 'Mada'" src="../assets/images/Mada_ic.svg"
                           loading="lazy" width="40" alt="">
                      <img *ngIf="order.paymentMode === 'Visa'" src="../assets/images/Visa_ic.svg"
                           loading="lazy" width="33" alt="">
                      <img *ngIf="order.paymentMode === 'MasterCard'" src="../assets/images/Mastercard_ic.svg"
                           loading="lazy" width="33" height="24" alt="">
                      <img *ngIf="order.paymentMode === 'AmericanExpress'" src="../assets/images/Amex_ic.svg"
                           loading="lazy" width="42" alt="">
                      <img *ngIf="order.paymentMode === 'StcPay'" src="../assets/images/STCPay_ic.svg"
                           loading="lazy" width="37" alt="">
                    </div>
                    <div class="payment-type text-center" *ngIf="order.paymentMode === 'Aggregator' || order.paymentMode === 'Other' || order.paymentMode === 'Wallet' || order.paymentMode === 'Cash' || order.paymentMode === 'Multi' || order.paymentMode === 'Card' || order.paymentMode === '-'">
                      {{order.paymentMode | translate}}
                    </div>
                  </td>
                  <td (click)="Edit(order);"> {{order.grandTotal}} </td>
                  <td *ngIf="checkZatcaCompliance" (click)="Edit(order);">
                    <ngb-highlight *ngIf="order.reportedToZATCA === 'Rejected'" class="btn btn-sm btn-inactive m-0" result="{{order.reportedToZATCA | translate}}">
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.reportedToZATCA === 'Warning'" class="btn btn-sm btn-badge-yellow m-0" result="{{ order.reportedToZATCA | translate}}">
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.reportedToZATCA === 'Pending'" class="btn btn-sm btn-badge-blue m-0" result="{{order.reportedToZATCA | translate}}">
                    </ngb-highlight>
                    <ngb-highlight *ngIf="order.reportedToZATCA === 'Reported'" class="btn btn-sm btn-active m-0" result="{{ order.reportedToZATCA | translate}}">
                    </ngb-highlight>
                  </td>
                  <td (click)="Edit(order);"> {{order.created | date:'shortTime'}} </td>
                  <td>
                    <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                      <img class="dots-list" src="/assets/images/dots-list.svg" />
                    </div>
                    <ng-template #popContent>
                      <p class="dropdown-heading-mini">{{'ORDERS' | translate }}</p>
                      <a (click)="Edit(order)">{{'Preview' | translate }}</a>
                      <a *hasPermission="['Invoice','Add Invoice Period']" (click)="openPeriod(order)" class="text-decoration-none">{{'Add Invoice Period' | translate }}</a>
                      <!--<a (click)="generatePDF(order)">{{'Export PDF' | translate }}</a>-->
                      <a (click)="openLink(order)">{{'Invoice Link' | translate }}</a>
                      <a *ngIf="checkZatcaCompliance && (order.orderStatus === 'Paid' || order.orderStatus === 'Partial Refund' || order.orderStatus === 'Refund') && (order.reportedToZATCA === 'Pending' || order.reportedToZATCA === 'Rejected')" (click)="PostInvoiceToZATCA(order)">{{'Send Invoice to ZATCA' | translate }}</a>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div class="row mt-10">
          <!-- <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
            <p class="pagination-count">
              {{'Showing' | translate }}
              <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
              <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
              <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
            </p>
          </div> -->
          <div class="col-lg-12 col-md-12  col-sm-12 col-xs-12">
            <div class="pagination-flex pt-0">
              <p class="pagination-count">
                {{'Showing' | translate }}
                <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
                <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
                <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
              </p>
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                              [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
                <ng-template ngbPaginationFirst>
                  <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
                </ng-template>
                <ng-template ngbPaginationLast>
                  <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
                </ng-template>
                <ng-template ngbPaginationPrevious class="page-pre">
                  <img src="../../../assets/images/icons/backward.svg" />
                </ng-template>
                <ng-template ngbPaginationNext class="page-next"><img src="../../../assets/images/icons/forward.svg" /></ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/empty-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Orders yet' | translate }}</strong>
          <p class="text-center">{{'No transactions took place during the selected time' | translate}} <br>{{'frame Head to punch new orders from marn app' | translate}} </p>
        </div>
      </div>

    </div>
  </div>
</main>



