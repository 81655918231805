import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ordertype } from '../_models/ordertype';

@Injectable({
  providedIn: 'root'
})
export class SalesSummaryReportService {

  constructor(private http: HttpClient) { }

  getSalesSummary(brandId, locationId, fromDate, toDate) {
    
    //const newFromDate = fromDate.getFullYear() + '-' +
    //  ("0" + (fromDate.getMonth() + 1)).slice(-2) + '-' +
    //  ("0" + fromDate.getDate()).slice(-2);
    //const newToDate = toDate.getFullYear() + '-' +
    //  ("0" + (toDate.getMonth() + 1)).slice(-2) + '-' +
    //  ("0" + toDate.getDate()).slice(-2);

    //locationId =(locationId == null) ? 1 : location;
    console.log(`api/SalesSummaryReport/all/${brandId}/${locationId}/${fromDate}/${toDate}`);
    return this.http.get<any>(`api/SalesSummaryReport/all/${brandId}/${locationId}/${fromDate}/${toDate}`);
  }

}
