<section class="container top-login-navbar">
  <div class="d-flex row">
    <div class="col-1"></div>
    <div class="col hide-mobile">
      <span class="navbar-contact">
        <img src="/assets/images/SA-flag.svg">
        <label class="lbl-contact">92000&nbsp;6276&nbsp;&nbsp;|&nbsp;&nbsp;Support&#64;marn.com</label>
      </span>
    </div>
    <div class="col d-flex justify-content-md-end justify-content-center">
      <div ngbDropdown role="group">
        <button class="btn-language" ngbDropdownToggle>{{selectedLang == 'en'?'English':'عربى'}} <img src="assets/images/down-aero.svg" /></button>
        <div class="dropdown-menu animate slideIn" ngbDropdownMenu>
          <button ngbDropdownItem (click)="changeLang('ar')"> عربى</button>
          <button ngbDropdownItem (click)="changeLang('en')">English</button>
        </div>

      </div>
      
    </div>
    <div class="col-1"></div>
  </div>
</section>
