import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocationItem } from '../../_models/LocationItem';
import { LocationItemService } from '../../_services/location-item.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../_services/alert.service'; ``
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionService } from '../../_services/permission.service';
import { LocationItemSettingComponent } from './location-item-setting/location-item-setting.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-location-items',
  templateUrl: './location-items.component.html',
  styleUrls: ['./location-items.component.css']
})
export class LocationItemsComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  private locationSubscription: Subscription;
  data$: Observable<LocationItem[]>;
  oldData: LocationItem[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  submit: boolean;
  selectedLocation: number;
  locationItemForm: UntypedFormGroup;
  canPriceChange: boolean = false;
  selectedLang = 'en';

  subCategoryList = [];
  partnerStatusList = [];
  checkAllsubCategory: boolean = false;
  SelectedStatus = '';

  constructor(public service: LocationItemService,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private permissionService: PermissionService,
    private modalService: NgbModal) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.createForm();
    this.service.headerFilter = { partnerStatusID: [''],subCategory:[''] };
    this.loading$ = service.loading$;
    this.data$ = this.service.data$;
    this.setOldData();
    this.total$ = this.service.total$;
    this.submit = false;

    this.service._allData$.subscribe(res => {
      this.subCategoryList = [];
      this.partnerStatusList = [];
      if (res) {
        var cat = new Set(res.map(item => (this.selectedLang != 'en' && item.alternateCategoryName && item.alternateCategoryName !== '' ? item.alternateCategoryName : item.category)));
        let i = 0;
        cat.forEach(ct => {
          var subCat = res.filter(e => this.selectedLang != 'en' ? e.alternateCategoryName === ct : e.category === ct);
          var unqSubCat = new Set(subCat.map(item => (this.selectedLang != 'en' && item.alternateSubCategoryName && item.alternateSubCategoryName !== '' ? item.alternateSubCategoryName : item.subCategory)));

          this.subCategoryList.push(
            {
              parentName: ct,
              isParent: true,
              subCat: []
            }
          );
          unqSubCat.forEach(us => {
            this.subCategoryList[i].subCat.push(
              {
                name: us,
                isChecked: false
              }
            );
          });
          i++;
        });
        //new Set(res.map(item => (item.subCategory))).forEach(e => {
        //  this.subCategoryList.push(
        //    {
        //      name: e,
        //      isChecked: false
        //    }
        //  )
        //});
        new Set(res.map(item => item.partnerStatusID))?.forEach(e => {
          this.partnerStatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });

    this.canPriceChange = this.permissionService.checkPermission(['Locations', 'Items', 'Price Change']);
    this.locationSubscription = this.locationService.locationIdChange.subscribe((id) => {
      console.log(id);
      if (id !== undefined && id !== 0 && id !== null) {
        this.selectedLocation = id;
        service.getLocationItems(this.selectedBrand, id);
        this.f.checkAll.setValue(false);
      }
    });
    this.service.checkAll.subscribe(e => {
      this.f.checkAll.setValue(e);
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnInit() {
    if (LocationService.locationId !== 0) {
      this.service.getLocationItems(this.selectedBrand, LocationService.locationId);
      this.selectedLocation = LocationService.locationId;
      this.f.checkAll.setValue(false);
    }
  }

  private setOldData() {
    this.oldData = [];
    this.service.allData$.forEach(i => {
      if (i !== null && i !== undefined) {
        i.forEach(d => {
          let item: LocationItem;

          const exists = this.oldData.find(i => i.itemID === d.itemID);

          if (exists === null || exists === undefined) {
            item = new LocationItem();

            item.alternateCategoryName = d.alternateCategoryName;
            item.alternateSubCategoryName = d.alternateSubCategoryName;
            item.category = d.category;
            item.fixedPrice = d.fixedPrice;
            item.itemID = d.itemID;
            item.itemImage = d.itemImage;
            item.name = d.name;
            item.nameOnReceipt = d.nameOnReceipt;
            item.originalPrice = d.originalPrice;
            item.price = d.price;
            item.selected = d.selected;
            item.statusID = d.statusID;
            item.subCategory = d.subCategory;

            this.oldData.push(item);
          }

        })
      }
    });

  }
  public bulkSelection($event) {
    let checked = $event.target.checked;
    this.service.markAsChecked(checked);
    //this.service.allData$.forEach(i => i.forEach(e => e.selected = checked));
  }

  private createForm() {
    this.locationItemForm = this.formBuilder.group({
      checkAll: [false]
    });
  }
  get f() { return this.locationItemForm.controls; }
  public saveItems() {
    this.submit = true;
    let items: LocationItem[];

    items = this.getItemtoSave();


    this.service.saveAllItems(LocationService.locationId, items).pipe(first())
      .subscribe(
        data => {
          this.alertService.success("Items saved for selected location");
          this.submit = false;
        },
        error => {
          this.alertService.error(error)
          this.submit = false;
        });
  }

  private getItemtoSave() {
    let selectedItems: LocationItem[];

    selectedItems = [];

    this.service.locationItems.forEach(d => {

      if (d.selected) {
        d.statusID = 1;
        if (d.partnerStatusID !== 15) {
          d.partnerStatusID === 1;
        }
        else {
          d.partnerStatusID === 15;
        }

        if (!d?.price) {
          d.price = null;
        }
        else {
          d.price = isNaN(parseFloat(d.price)) ? null : parseFloat(d.price.toString());
        }
        selectedItems.push(d);
      }
      else {
        if (this.oldData.length > 0) {
          const deleteItem = this.oldData.find(f => f.itemID === d.itemID);

          if (deleteItem !== null || deleteItem !== undefined) {
            if (deleteItem.selected) {
              deleteItem.selected = false;
              deleteItem.statusID = 3;
              deleteItem.partnerStatusID = 2;
              selectedItems.push(deleteItem);
            }
          }
        }
      }
    });
    return selectedItems;
  }

  public priceChange(item: LocationItem, event: any) {
    if (event.target.value === '')
      item.price = null;

    console.log(event);
  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    this.locationSubscription.unsubscribe();
  }
  filters() {
    let subCat: boolean=true;
    var subCatList = [];
    this.checkAllsubCategory = true;
    for (var e of this.subCategoryList) {
      var subCateg = e.subCat;
      subCateg.forEach(l => {
        subCatList.push(l);
      });
      if (subCateg.filter(e => e.isChecked === false)?.length > 0) {
        this.checkAllsubCategory = false;
      }
    }

    let headerFilter = {
      subCategory: subCatList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      partnerStatusID: (this.SelectedStatus == 'All Status') ? this.partnerStatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => {
      if (e.isChecked != undefined && e.isChecked != null) {
        e.isChecked = $event;
      }
      else {
        e.subCat.forEach(k => k.isChecked = $event); 
      }
    });
    this.filters();
  }
  openSettings(item) {
    const modalRef = this.modalService.open(LocationItemSettingComponent, { size: 'lg', windowClass: 'small-modal', animation: false });
    modalRef.componentInstance.Item = item;
    modalRef.componentInstance.LocationId = this.selectedLocation;
    modalRef.componentInstance.BrandId = this.selectedBrand;
    modalRef.result.then((result) => {
      var index = this.service.locationItems.findIndex(x => x.itemID === result.itemID);
      this.service.locationItems.splice(index, 1);
      this.service.locationItems.push(result);
    }, (reason) => {
      //this.getData();
    });

  }
  toggleValue(item) {
    if (item.statusID === 1) {
      item.partnerStatusID = 2;
      item.selected = false;
      item.statusID = 2;
    }
    else {
      item.partnerStatusID = 1;
      item.selected = true;
      item.statusID = 1;
    }
  }
}
