import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { BehaviorSubject, Observable } from 'rxjs';
import $ from 'jquery'
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class PdfService {
  PDFGenerated = new BehaviorSubject<boolean>(false);
  selctedLang = 'en';
  requestOptions;
  constructor(private http: HttpClient,
    public localStorageService: LocalStorageService  ) {
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Browser-Language': this.selctedLang
    };

     this.requestOptions = {
      headers: new Headers(headerDict),
    };
  }
  
  public SaveAsPDFFile(DATA: HTMLElement, name: string, divID: string) {
    var copyDom = DATA.cloneNode(true);
    document.body.appendChild(copyDom);
    var el = document.getElementById(divID);
    document.body.removeChild(copyDom);
    //el.style.display = "block";
    html2canvas(el, { scrollY: -window.scrollY, allowTaint: true, useCORS: true }).then(canvas => {
      // Few necessary setting options
      //el.style.display = "none";

      var imgWidth = 210;
      var pageHeight = 300;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      if (imgHeight > 295) {
        imgHeight = 295;
      }
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(name + '.pdf');
      return this.PDFGenerated.next(true);
    });
  }
  
  public SaveAsPDFFileMultiPage(DATA: HTMLElement, name: string, divID: string) {
    var quotes = document.getElementById(divID);
    
    var _generated = this.PDFGenerated;
    html2canvas(quotes, {
      scrollY: -window.scrollY, allowTaint: true, useCORS: true, onclone: function (clonedDoc) {
        clonedDoc.getElementById(divID).style.display = 'block';
      } }).then(function (canvas) {

      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', 'a4');
      var position = 0;
      var pageData = canvas.toDataURL('image/jpeg', 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.setLineWidth(3);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(3);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save(name + '.pdf');
      return _generated.next(true);
    });
  }
  async GenerateOrderPDF(updateData): Promise<any> {
    return this.http.post(`api/PDF/GenerateOrderPDF`, updateData, this.requestOptions).pipe().toPromise();
  }
  async GenerateOrderDetailedPDF(updateData): Promise<any> {
    return this.http.post(`api/PDF/GenerateOrderDetailedPDF`, updateData, this.requestOptions).pipe().toPromise();
  }
  async GenerateSalesSummaryPDF(updateData): Promise<any> {
    return this.http.post(`api/PDF/GenerateSalesSummaryPDF`, updateData, this.requestOptions).pipe().toPromise();
  }
  async GenerateInventoryCountPDF(updateData): Promise<any> {
    return this.http.post(`api/PDF/GenerateInventoryCountPDF`, updateData, this.requestOptions).pipe().toPromise();
  }
  async GenerateDetailTaxPDF(updateData): Promise<any> {
    return this.http.post(`api/PDF/GenerateDetailTaxPDF`, updateData, this.requestOptions).pipe().toPromise();
  }

  
  
}
