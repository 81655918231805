import { Component } from '@angular/core';

@Component({
  selector: 'app-lite-accounting',
  templateUrl: './lite-accounting.component.html',
  styleUrl: './lite-accounting.component.css'
})
export class LiteAccountingComponent {
  active = "expenses";
}
