<form [formGroup]="profileForm" class="form" *ngIf="activeSection === 'profile'">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'User Settings' | translate }}  </h4>

    <button [disabled]="loading" class="btn btn-primary cutom-button" (click)="onSubmit()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{'Save' | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="firstName">{{'User Name' | translate }}</label>
              <input type="text" class="form-control" id="firstName" placeholder="" formControlName="firstName" />
              <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
                <div *ngIf="f.firstName.errors.required">
                  {{'User Name is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!--<div class="rcn-box form-group col-lg-3 col-sm-3" style="background-color:white">
              <label for="Country">{{'Country' | translate}}</label>
              <ng-select [items]="Countries" bindLabel="name" bindValue="code" [closeOnSelect]="true" placeholder=""
                         [ngModelOptions]="{standalone: true}" formControlName="countryID">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label" *ngIf="(item?.flagImage !== '' && item?.flagImage !== null && item?.flagImage !== undefined)">
                      <img class="item-image" [src]="item?.flagImage" width="40" height="30" />{{item.phonePrefix}}
                    </span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                  <img class="item-image" [src]="item?.flagImage" width="40" height="30" />
                  <label>{{item.phonePrefix}}</label>
                </ng-template>
              </ng-select>
              <div *ngIf="f.countryID.invalid && (f.countryID.dirty || f.countryID.touched)" class="alert alert-danger">
                <div *ngIf="f.countryID.errors.required">
                  {{'Country is Required' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-lg-9 col-sm-9">
              <label for="contact">{{'Phone Number' | translate }}</label>
              <input type="text" class="form-control" pattern="^\(?[ .-]?(\d{2,3})[ .-]?(\d{4})[ .-]?(\d{3})$" id="contact" placeholder="" formControlName="contact" />
              <div *ngIf="f.contact.invalid && (f.contact.dirty || f.contact.touched)" class="alert alert-danger">
                <div *ngIf="f.contact.errors.required">
                  {{'Phone Number is Required' | translate }}
                </div>
                <div *ngIf="f.contact.invalid">{{'Phone Number format is invalid' | translate }}.</div>
              </div>
            </div>-->
            <div class="form-group col-lg-12 col-sm-12 countries">
              <label for="contact">{{'Phone Number' | translate }}</label>
              <ng-select [items]="Countries" bindLabel="name" bindValue="code" [closeOnSelect]="true" placeholder=""
                         [ngModelOptions]="{standalone: true}" formControlName="countryID" class="p-1 position-absolute">
                <ng-template ng-multi-label-tmp let-items="items">
                  <div class="ng-value border-0" *ngFor="let item of items">
                    <span class="ng-value-label" *ngIf="(item?.flagImage !== '' && item?.flagImage !== null && item?.flagImage !== undefined)">
                      <img class="item-image" [src]="item?.flagImage" width="40" height="30" />{{item.phonePrefix}}
                    </span>
                    <!--<span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>-->
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                  <img class="item-image" [src]="item?.flagImage" width="40" height="30" />
                  <label>{{item.phonePrefix}}</label>
                </ng-template>
              </ng-select>
              <input type="text" class="form-control country-padding" id="contact" placeholder="" formControlName="contact" pattern="^\(?[ .-]?(\d{2,3})[ .-]?(\d{4})[ .-]?(\d{3})$" />
              <div *ngIf="(f.countryID.invalid && (f.countryID.dirty || f.countryID.touched)) || (f.contact.invalid && (f.contact.dirty || f.contact.touched))" class="alert alert-danger">
                <div *ngIf="((f.countryID.invalid && (f.countryID.dirty || f.countryID.touched))) && f.countryID.errors.required">
                  {{'Country code is Required' | translate }}
                </div>
                <div *ngIf="(f.contact.invalid && (f.contact.dirty || f.contact.touched)) && f.contact.errors.required">
                  {{'Phone Number is Required' | translate }}
                </div>
                <div *ngIf="(f.contact.invalid && (f.contact.dirty || f.contact.touched)) &&  f.contact.invalid">{{'Phone Number format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="email">{{'Email' | translate }}</label>
              <input type="text" class="form-control" id="email" placeholder="" formControlName="email" autocomplete="off" />
              <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger">
                <div *ngIf="f.email.errors.required">
                  {{'Email is required' | translate }}.
                </div>
                <div *ngIf="f.email.invalid">{{'Email format is invalid' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="tiltle-bar">
            <div class="row">
              <div class="col-lg-8 col-sm-8 col-8">
                {{'Change Password' | translate }}
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="form-group col-lg-12 col-sm-12 password-visibility">
              <label for="currentPassword">{{'Current Password' | translate }}</label>
              <input type="password" formControlName="currentPassword" id="currentPassword" name="currentPassword" class="form-control form-control-appended" placeholder="{{'Current Password' | translate }}" appPasswordVisibility />
              <div *ngIf="f.currentPassword.invalid && (f.currentPassword.dirty || f.currentPassword.touched)" class="alert alert-danger">
                <div *ngIf="f.currentPassword.errors.required">
                  {{'Password is required' | translate }}.
                </div>
              </div>
              <div *ngIf="isPasswordVerified === false" class="alert alert-danger">
                {{'Current Password is inaccurrate' | translate }}.
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isPasswordVerified === false || isPasswordVerified  === undefined">
            <div class="col-lg-4 col-sm-4">
              <button class="btn btn-primary cutom-button" (click)="Verify()" style="background-color: #DEE4F5; color: #3754B7;">
                <!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>-->
                {{'Verify' | translate}}
              </button>
            </div>
            <div class="col-lg-8 col-sm-8 pt-3">
              <u>
                <a (click)="forgotPassword()">
                  <span class="forgot">
                    {{'Forgot Password?' | translate }}
                  </span>
                </a>
              </u>
            </div>
          </div>
          <div class="row" *ngIf="isPasswordVerified === true">
            <div class="form-group col-lg-12 col-sm-12 password-visibility">
              <label for="password">{{'Set new Password' | translate }}</label>
              <input type="password" formControlName="password" id="password" name="password" class="form-control form-control-appended" placeholder="{{'Set new Password' | translate }}" appPasswordVisibility />
              <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
                <div *ngIf="f.password.errors.required">
                  {{'Password is required' | translate }}.
                </div>
                <div *ngIf="f.password.invalid">{{'Minimum eight characters, at least one capital letter, one number and one special character' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isPasswordVerified === true">
            <div class="form-group col-lg-12 col-sm-12 password-visibility">
              <label for="confirmPassword">{{'Confirm new Password' | translate }}</label>
              <input type="password" formControlName="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control form-control-appended" placeholder="{{'Confirm new Password' | translate }}" appPasswordVisibility />
              <div *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)" class="alert alert-danger">
                <div *ngIf="f.confirmPassword.errors.required">
                  {{'Password is required' | translate }}.
                </div>
                <div *ngIf="f.confirmPassword.invalid">{{'Minimum eight characters, at least one capital letter, one number and one special character' | translate }}.</div>
              </div>
              <div *ngIf="f.confirmPassword.value !== f.password.value" class="alert alert-danger">
                {{"New Password doesn't match confirmation" | translate }}.
              </div>
              <div *ngIf="f.password.value === f.confirmPassword.value && f.password.value === f.currentPassword.value" class="alert alert-danger">
                {{"New and Current password can't be same" | translate }}.
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
</form>
<main class="login justify-content-center d-flex flex-column justify-content-center align-items-center" *ngIf="activeSection ==='forgot'">
  <img class="marn-logo mb-per-15" src="/assets/images/marn-logo.svg" alt="Marn" />
  <div class="row login-container">
    <div class="col-12">
      <app-forgotpassword [isRestaurant]="isRestaurant"></app-forgotpassword>
    </div>
  </div>
</main>
