<main class="views container">
  <div class="page">
    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Details Tax Report' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header align-items-center">
            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                  <button ngbDropdownItem (click)="generatePDF()">{{'PDF' | translate }}</button>

                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <div class="page-body position-relative">
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.dateRange}}</div>
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive hrzScroll salesOrderDetail">
          <div>
            <div class="preview-filter-data" dir="ltr">{{selectedReport?.locations}}</div>
            <table class="table table-striped">
              <thead>
                <tr class="table-header">
                  <th width="5%">
                    <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                  </th>
                  <th sortable="OrderNo" (sort)="onSort($event)">{{'Order #' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="OrderID" (sort)="onSort($event)">{{'Invoice #' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="DateTime" (sort)="onSort($event)">{{'Date & Time' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="CategoryName" (sort)="onSort($event)">{{'Category' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="Name" (sort)="onSort($event)">{{'Item' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="PaymentType" (sort)="onSort($event)">{{'Payment Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="Quantity" (sort)="onSort($event)">{{'Quantity' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="SubTotal" (sort)="onSort($event)">{{'Subtotal' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th sortable="Discount" (sort)="onSort($event)">{{'Discount' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  
                  <ng-container *ngIf="(data$ | async) && (data$ | async)[0]?.TaxColumns && (data$ | async)[0]?.TaxColumns?.length>0">
                    <ng-container *ngFor="let col of (data$| async )[0]?.TaxColumns">
                      <th>
                        {{selectedLang=='en'?col.Name:col.ArabicName }}
                      </th>
                    </ng-container>
                  </ng-container>

                  <th sortable="GrandTotal" (sort)="onSort($event)">{{'Total' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data$ | async ">
                  <td>
                    <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                  </td>

                  <td> {{item.OrderNo }} </td>
                  <td> {{item.DeviceTransactionNo }} </td>
                  <td>  {{item.DateTime | date:'HH:mm | dd/MM/yyyy' }} </td>
                  <td> {{item.CategoryName }} </td>
                  <td> {{(selectedLang === 'en')?item.Name:item.AltName }} </td>
                  <td> {{item.PaymentType | translate}} </td>
                  <td> {{item.Quantity  | number:'1.2-2'}} </td>
                  <td> {{item.SubTotal  | number:'1.2-2'}} </td>
                  <td> {{item.Discount  | number:'1.2-2'}} </td>
                  
                  <ng-container *ngIf="(data$| async)[0].TaxColumns && (data$| async)[0].TaxColumns.length>0">
                    <ng-container *ngFor="let col of (data$| async)[0].TaxColumns; let i = index">
                      <td >
                        {{ShowCustomTaxes(item.Taxes,col.Name) | number:'1.2-2'}}
                      </td>
                    </ng-container>
                  </ng-container>

                  <td> {{item.GrandTotal  | number:'1.2-2'}} </td>



                </tr>
              </tbody>
            </table>
          </div>
        </div>
       
      </section>
      <div class="row mt-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            {{'Showing' | translate }}
            <strong>{{(service.pageSize * service.page)-199}}</strong> {{'to' | translate }}
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>&nbsp;{{'of' | translate }}
            <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</main>

