<main class="views container order-list">
  <div class="page">



    <div class="page-header" *ngIf="(service.orderTypes?.length > 0)">
      <div class="tile-header tCustom-header items">
        <div class="row">
          <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
            <h4>    <a backLink>{{'Setup' | translate }} </a>&nbsp;/ {{'Order Types' | translate }}</h4>
          </div>
          <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
            <div class="top-bar-flex">
              <div class="filter-field">
                <div class=" position-relative" style="margin-left:10px !important;">
                  <!-- <span class="show-filter-icon float-left">
                    <img class="dots-list" src="assets/images/search.svg" />
                  </span> -->
                  <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
                </div>
              </div>
              <div class="col-md-auto ps-2" *hasPermission="['Setup','Order Types','Insert']">
                <div ngbDropdown class="nav-item" placement="bottom">

                  <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                    <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                  </button>

                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
                    <button ngbDropdownItem (click)="export('csv')">CSV</button>
                    <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
                  </div>
                </div>
              </div>
              <div class="create-btn ms-2">
                <div *hasPermission="['Setup','Order Types', 'Insert']">
                  <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
                    {{'+ Add Order Type' | translate }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>






    <div *ngIf="(service.orderTypes?.length > 0) && ((data$ | async)?.length > 0)">
      <div class="page-body position-relative">
        <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">

          <div class="tile-body p-0 table-responsive text-nowrap">
            <table class="table table-striped order-type text-start">
              <thead>
                <tr class="table-header">
                  <th width="5%"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                  <th width="70%" sortable="name" (sort)="onSort($event)">{{'Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                  <th width="20%">

                    <!-- Status Filter -->
                    <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                      <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                        <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                        <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                      </button>
                      <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                 name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                          <label class="form-check-label font-blue" for="dropdownStatus_all"
                                 (click)="selectAllFilters($event,StatusList)">
                            {{'All Status' | translate}}
                          </label>
                        </div>
                        <div *ngFor="let item of StatusList; let i = index">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                            <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                              <!-- {{item.name}} -->

                              <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                             class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                              </ngb-highlight>


                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </th>
                  <th width="3%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let orderType of data$ | async " editRow>
                  <td>
                    <input type="checkbox" class="form-check-input" (change)="chkSelection($event,orderType)" [(ngModel)]="orderType.selected" />
                  </td>
                  <td (click)="Edit(orderType)"> {{orderType.name}} </td>
                  <td (click)="Edit(orderType)">
                    <ngb-highlight [ngClass]="{'btn-active': orderType.statusID === 1, 'btn-inactive':orderType.statusID !== 1 }" class="btn btn-sm" [result]="(orderType.statusID == 1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                    </ngb-highlight>
                  </td>
                  <td>
                    <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                      <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                    </div>
                    <ng-template #popContent>
                      <p class="dropdown-heading-mini">{{'ORDER TYPE' | translate }} </p>
                      <a *hasPermission="['Setup','Order Types', 'Edit']" (click)="Edit(orderType)">{{'Edit' | translate }} </a>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>


        <div class="row mt-10">

          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <p class="pagination-count">
              <strong class="p-4">

                {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ |  async)!}} {{'results' | translate }}
              </strong>
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                            [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
              <ng-template ngbPaginationFirst>
                <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
              </ng-template>
              <ng-template ngbPaginationLast>
                <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
              </ng-template>
              <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
              <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
          </div>

        </div>





      </div>

    </div>


    <div *ngIf="(service.orderTypes?.length == 0) && ((data$ | async)?.length == 0)">
      <div class="blank-order">
        <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
        <strong>{{'No Order Types yet' | translate }}</strong>
        <p class="text-center width-375">{{'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.' | translate}}</p>
      </div>
      <div class="items-flex">
        <div class="create-btn mlmr-10">
          <div *hasPermission="['Setup','Order Types', 'Insert']">
            <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
              {{'+ Create New Item' | translate }}
            </button>
          </div>
        </div>


      </div>
    </div>




    <div *ngIf="(service.orderTypes?.length > 0) && ((data$ | async)?.length == 0)">
      <div class="blank-order">
        <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
        <strong>{{'No Record Found' | translate }}</strong>
        <p class="text-center width-375">{{'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.' | translate}}</p>
      </div>
    </div>




  </div>
</main>



