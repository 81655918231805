<form [formGroup]="modifierForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Modifier Item' :SelectedName) | translate}}   </h4>
    <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Products','Modifiers','Modifier Items', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Name' | translate }}</label>
              <input type="text" class="form-control" id=name placeholder="" formControlName="name" pattern="^[a-zA-Z0-9 ]{3,}$">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is required' | translate }}.
                </div>
                {{'Invalid input. Length must be 3 or more' | translate }}.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="nameOnReceipt">{{'Name Alt' | translate }}</label>
              <input type="text" class="form-control" id=alternateName placeholder="" formControlName="alternateName" pattern="^[ء-ي0-9 ]{3,}$">
              <div *ngIf="f.alternateName.invalid && (f.alternateName.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.alternateName.errors.required">
                  {{'Name Alt is required' | translate }}.
                </div>
                {{'Invalid input. Length must be 3 or more' | translate }}.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="terminalName">{{'Barcode' | translate }}</label>
              <input type="text" class="form-control" id=barcode placeholder="" formControlName="barcode">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Price">{{'Price' | translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="0" formControlName="price">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{{brandCurrency | translate }}</span>
                </div>
              </div>
              <div *ngIf="f.price.invalid && (f.price.dirty || f.price.touched)" class="alert alert-danger">
                <div *ngIf="f.price.errors.required">
                  {{'Price is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'Sell By' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="unitID">
                <option value="">
                  {{'Select Unit' | translate }}
                </option>
                <option [ngValue]="option.unitID" [disabled]=option.maped
                        selected="option.unitID == unitID" *ngFor="let option of Units">
                  {{option.unit}}
                </option>
              </select>
              <div *ngIf="f.unitID.invalid && (f.unitID.dirty || f.unitID.touched)" class="alert alert-danger">
                <div *ngIf="f.unitID.errors.required">
                  {{'Unit is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="tiltle-bar form-group">
            <div class="row">
              <div class="col-8">
                <label for="inventory">{{'Track inventory' | translate }}</label>
              </div>
              <div class="col-4 text-right">
                <div class="form-check form-switch switch-2">
                  <input type="checkbox" class="form-check-input" id="trackInventory" formControlName="trackInventory" (change)="generateSKU()">
                  <label class="form-check-label" for="trackInventory"></label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="f.trackInventory.value">
            <div class="row">
              <div class="form-group col-lg-12">
                <label for="License">{{'Inventory' | translate }}</label>
                <select class="custom-select form-control  custom-select-2" formControlName="type" (change)="ChangeInventoryType($event)">
                  <option [ngValue]="option.inventoryType" *ngFor="let option of  InventoryTypes">
                    {{option.name | translate}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row" *ngIf="isRetailModifier">
              <div class=" form-group col-lg-12 col-sm-12">
                <label for="Supplier">{{'Suppliers' | translate }}</label>
                <ng-select [items]="SuppliersList" bindLabel="name" bindValue="supplierID" [multiple]="true" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSupplierIds">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items">
                      <span class="ng-value-label">{{item.name}}</span>
                      <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                  </ng-template>
                </ng-select>
                <div *ngIf="f.trackInventory.value==true && (selectedSupplierIds == undefined ||selectedSupplierIds == '')" class="alert alert-danger">
                  {{'Supplier cannot be empty' | translate }}
                </div>
              </div>
              <div class="form-group col-lg-12 col-sm-12">
                <label for="License">{{'Inventory Group' | translate }}</label>
                <select class="custom-select form-control  custom-select-2" formControlName="groupID">
                  <option [ngValue]="option.groupID" [disabled]=option.maped
                          [selected]="option.groupID == groupID" *ngFor="let option of Groups">
                    {{option.name}}
                  </option>
                </select>
                <div *ngIf="f.trackInventory.value==true && (!f.groupID.value || f.groupID.value === '')" class="alert alert-danger">
                  {{'Select Inventory Group' | translate }}
                </div>
              </div>
              <div class="form-group col-lg-12 col-sm-12">
                <label for="Price">{{'Cost' | translate }}</label>
                <div class="input-group">
                  <input type="number" class="form-control" placeholder="0" formControlName="cost">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">{{brandCurrency | translate }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-12 col-sm-12">
                <div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label for="">SKU</label>
                    <div class="input-group mb-3 section-sku">
                      <input type="text" class="form-control" placeholder="SKU-000" aria-label="" aria-describedby="basic-addon2" formControlName="sku" [readonly]="SelectedID > 0" (input)="SelectedID === 0 && manualSKUChangeByUser =true">
                      <div class="input-group-append" role="button">
                        <span class="input-group-text button-generate" id="basic-addon2" [ngClass]="{'SKU-Disable': !enableSKU}" (click)="enableSKU && generateSKU()">{{'Generate' | translate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row " *ngIf="!isRetailModifier">

              <div class=" form-group col-lg-12 col-sm-12 recipe-autocomplete">
                <ng-select [items]="RecipesList" class="form-control recipe-box-autocomplete-" placeholder="Add Recipes" bindLabel="name" bindValue="recipeID" (change)="addRow($event)">
                </ng-select>
              </div>
              <div class=" form-group col-lg-12 col-sm-12">
                <div class="recipe-box form-group">
                  <!--<ng-select [items]="RecipesList" class="form-control recipe-box-autocomplete" placeholder="Add Recipes" bindLabel="name" bindValue="recipeID" (change)="addRow($event)">
          </ng-select>-->

                  <div class="row box-bottomline" *ngFor="let obj of arrRecipes">
                    <div class="col-6">
                      <h6 for="name" class="black pt-3">{{obj.name}}</h6>
                    </div>
                    <div class="col-4 p-0">
                      <div class="input-group">
                        <input type="number" [(ngModel)]="obj.quantity" [ngModelOptions]="{standalone: true}" (change)="UpdateQty(obj)" class="form-control rounded-0" placeholder="" aria-label="" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                          <span class="input-group-text rounded-0" id="basic-addon2"><label for="unit" class="unit">{{'Unit' | translate }}</label></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 text-center delete">
                      <a>
                        <!--<img class=" pt-3" src="/assets/images/delete-icon.png" (click)="deleteRow(obj)" />-->
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" (click)="deleteRow(obj)" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L9 9" stroke="#A80011" stroke-width="2" stroke-linecap="round" />
                          <path d="M9 1L1 9" stroke="#A80011" stroke-width="2" stroke-linecap="round" />
                        </svg>


                      </a>
                    </div>
                  </div>
                  <div class="row p-1" *ngIf="arrRecipes.length==0">
                    <div class="col-12 text-center">
                      <h6 for="name" class="pt-3">{{'No Recipe Added!' | translate }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12">
              <!--<label for="Status">{{'Modifier Item Status' | translate }}</label>
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" checked id="statusID" formControlName="statusID">
        <label class="form-check-label" for="statusID"></label>
      </div>-->

              <div class="status-activebar">
                <label for="Status">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'Modifier Item Status' | translate }}
                </label>

                <div class="form-check form-switch">


                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4"></div>
  </div>
</form>
