<form [formGroup]="receiptForm" class="form receipt" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'Receipt Setup' | translate}} </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Setup','Receipt', 'Save']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row" style="margin-top: 40px;">
        <div class="col-lg-2"></div>
        <div class="col-lg-8 mt-5">
          <div class="row">
            <div class="col-lg-7">
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="locationID">{{'Location Name' | translate}}</label>
                  <select class="custom-select form-control custom-select-2" formControlName="locationID" id="locationID" (change)="onLocationChange($event)">
                    <option selected [ngValue]=""> {{'Select' | translate}} </option>
                    <option [ngValue]="option.locationID" *ngFor="let option of Locations">
                      {{option.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 ">
                  <app-receiptimage [allowedUpload]="(f.locationID.value === null || f.locationID.value === '' || f.locationID.value === undefined) ? true : false"></app-receiptimage>
                </div>
              </div>
              <!--<div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label>{{'Printed logos are converted to black and white. Size 5 MB' | translate}}.</label>
                  <hr />
                </div>
              </div>-->


              <div class="tiltle-bar form-group">
                <div class="row">
                  <div class="col-12">
                    <label for="socialMedia">{{'Business info' | translate}}</label>
                    <p>{{'These informations can be edit from Locations section' | translate}}</p>
                  </div>

                </div>
              </div>



              <div class="row" *ngIf="allowReadonly === false">
                <div class="form-group col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-12">
                      <label for="companyTitle">{{'Business Name' | translate}}</label>
                    </div>
                  </div>
                  <input type="text" class="form-control disabled" [readonly]="allowReadonly" id="companyTitle" placeholder="" formControlName="companyTitle" />
                  <div *ngIf="f.companyTitle.invalid && (f.companyTitle.dirty || f.companyTitle.touched)" class="alert alert-danger">
                    <div *ngIf="f.companyTitle.errors.required">
                      {{'Business Name is required.' | translate}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="companyPhones">{{'Phone Number' | translate}}</label>
                  <input type="text" class="form-control" id="companyPhones" placeholder="" formControlName="companyPhones"
                         pattern="^((?:[+?0?0?966]+)(?:\s?\d{2})(?:\s?\d{7}))$" />
                  <div *ngIf="f.companyPhones.invalid && (f.companyPhones.dirty || f.companyPhones.touched)" class="alert alert-danger">
                    <div *ngIf="f.companyPhones.errors.required"> {{'Mobile Number is required.' | translate}} </div>
                    <div *ngIf="f.companyPhones.invalid"> {{'Mobile Number is invalid.' | translate}} </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="companyAddress">{{'Address' | translate}}</label>
                  <input type="text" class="form-control" id="companyAddress" placeholder="" formControlName="companyAddress" />
                  <div *ngIf="f.companyAddress.invalid && (f.companyAddress.dirty || f.companyAddress.touched)" class="alert alert-danger">
                    <div *ngIf="f.companyAddress.errors.required">
                      {{'Address is required.' | translate}}
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label>{{'This information will be edit from location section' | translate}}</label>
                  </div>
                </div>-->
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="vatid">{{'VAT ID' | translate}}</label>
                  <input type="text" class="form-control" id="vatid" placeholder="" formControlName="vatid" pattern="^[0-9\.\-\/]+$" />
                  <div *ngIf="f.vatid.invalid && (f.vatid.dirty || f.vatid.touched)" class="alert alert-danger">
                    <!--<div *ngIf="f.vatid.errors.required"> {{'VAT ID is required.' | translate}} </div>-->
                    <div *ngIf="f.vatid.invalid"> {{'VAT ID is invalid.' | translate}} </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="companyWebsite">{{'Website' | translate}}</label>
                  <div class="input-group">
                    <div class="input-group-append">
                      <span class="input-group-text" id="inputGroup-sizing-default">https://</span>
                    </div>
                    <input type="url" class="form-control" id="companyWebsite" formControlName="companyWebsite" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="footer">{{'Additional Text' | translate}}</label>
                  <textarea class="form-control mh-80 h-auto" id="footer" placeholder="" formControlName="footer" rows="3"></textarea>
                </div>
              </div>


              <div class="tiltle-bar form-group">
                <div class="row">
                  <div class="col-8">
                    <label for="socialMedia">{{'Add Social media' | translate}}</label>
                  </div>
                  <div class="col-4">
                    <div class="form-check form-switch">
                      <input type="checkbox" class="form-check-input" id="socialMedia" formControlName="socialMedia">
                      <label class="form-check-label" for="socialMedia"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="f.socialMedia.value === true ? true : false" class="socialmedia">


                <div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label for="twitter">{{'Twitter' | translate}}</label>
                    <input type="text" class="form-control" id="twitter" placeholder="" formControlName="twitter" />
                    <img src="../../../assets/images/icons/twitter-receipt.svg" class="image-input" />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label for="snapchat">{{'Snapchat' | translate}}</label>
                    <input type="text" class="form-control" id="snapchat" placeholder="" formControlName="snapchat" />
                    <img src="../../../assets/images/icons/snapchat-receipt.svg" class="image-input" />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label for="instagram">{{'Instagram' | translate}}</label>
                    <input type="text" class="form-control" id="instagram" placeholder="" formControlName="instagram" />
                    <img src="../../../assets/images/icons/instagam-receipt.svg" class="image-input" />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-lg-12 col-sm-12">
                    <label for="facebook">{{'Facebook' | translate}}</label>
                    <input type="text" class="form-control" id="facebook" placeholder="" formControlName="facebook" />
                    <img src="../../../assets/images/icons/fb-receipt.svg" class="image-input" />
                  </div>
                </div>
              </div>
              <!--<div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="companyWebsite">{{'Website' | translate}}</label>
                  <div class="input-group">
                    <div class="input-group-append">
                      <span class="input-group-text" id="inputGroup-sizing-default">https://</span>
                    </div>
                    <input type="url" class="form-control" id="companyWebsite" formControlName="companyWebsite" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-sm-12">
                  <label for="footer">{{'Additional Text' | translate}}</label>
                  <textarea class="form-control mh-80 h-auto" id="footer" placeholder="" formControlName="footer" rows="3"></textarea>
                </div>
              </div>-->
            </div>
            <div class="col-lg-5">
              <section class="tile">
                <div class="d-flex justify-content-center">
                  <div class="avatar-upload">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <div class="avatar-preview border-0 bg-transparent">
                      <div id="localImagePreview" [style.backgroundImage]="'url('+ imgComp.localImagePath + ')'" *ngIf="imgComp.localImagePath !== ''">
                      </div>
                      <div id="imagePreview" [style.backgroundImage]="'url('+ imgComp.imagepath + '?refreshCache='+ breakCache+')'" *ngIf="imgComp.localImagePath === '' && imgComp.imagepath !== ''">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tile-body" style="padding:10px">
                  <div class="row">
                    <div class="text-center">
                      <label>{{f.companyTitle.value}}</label>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="text-center">
                      <label> {{f.companyAddress.value}}</label>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="text-center">
                      <label>{{'VAT' | translate}}# {{f.vatid.value}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="text-center">
                      <h3>110</h3>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Cashier Name' | translate}}</label>
                    </div>
                    <div class="col">
                      <label> 02:16 AM 12/08/2021</label>
                    </div>
                  </div>
                  <hr />
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Order Source' | translate}}</label>
                    </div>
                    <div class="col text-end">
                      <label> Transaction No. </label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Table No.' | translate}}.</label>
                    </div>
                    <div class="col text-end">
                      <label>Order Taker</label>
                    </div>
                  </div>
                  <hr />
                  <div class="row justify-content-between">
                    <div class="col-4">
                      <label style="font-size:12px !important; font-weight:bold !important;">{{'Item' | translate}}</label>
                    </div>
                    <div class="col-3 text-center">
                      <label style="font-size: 12px !important; font-weight: bold !important;">{{ 'Unit Price' | translate}}</label>
                    </div>
                    <div class="col-2 text-center">
                      <label style="font-size: 12px !important; font-weight: bold !important;">{{ 'Qty' | translate}}</label>
                    </div>
                    <div class="col-3 text-end">
                      <label style="font-size: 12px !important; font-weight: bold !important;">{{ 'Total' | translate}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between mb-3">
                    <div class="col-4">
                      <label style="font-size: 12px !important;">{{'Item Name' | translate}}</label>
                    </div>
                    <div class="col-3 text-center">
                      <label style="font-size: 12px !important;">22.0 {{brandCurrency | translate}}</label>
                    </div>
                    <div class="col-2 text-center">
                      <label style="font-size: 12px !important;">2</label>
                    </div>
                    <div class="col-3  text-end">
                      <label style="font-size: 12px !important;">44.0 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                  <!--<div class="row justify-content-between">
    <div class="col-4">
      <label>{{'Item Name' | translate}}</label>
    </div>
    <div class="col-3">
      <label> 22.00 {{brandCurrency | translate}} </label>
    </div>
  </div>-->
                  <hr />
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Sub Total' | translate}}</label>
                    </div>
                    <div class="col text-end">
                      <label> 22.00 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Discount' | translate}}</label>
                    </div>
                    <div class="col text-end">
                      <label> 22.00 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Tobacco' | translate}} </label>
                    </div>
                    <div class="col text-end">
                      <label> 22.00 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'VAT' | translate}} </label>
                    </div>
                    <div class="col text-end">
                      <label> 22.00 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                  <hr />
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Grand Total' | translate}}</label>
                    </div>
                    <div class="col text-end">
                      <label> 22.00 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col">
                      <label>{{'Payment' | translate}} - Stc Pay</label>
                    </div>
                    <div class="col text-end">
                      <label> 22.00 {{brandCurrency | translate}}</label>
                    </div>
                  </div>
                </div>
                <div class="panel-heading tiltle-bar" style="height: 200px; background:#fff !important">
                  <div class="row d-flex text-center" style="margin-top:21px">
                    <label>{{'Thankyou for visiting' | translate}}</label>
                  </div>
                  <div class="row d-flex text-center" style="margin-top:10px">
                    <label>{{'Phone No' | translate}}: {{f.companyPhones.value}}</label>
                  </div>
                  <div *ngIf="f.socialMedia.value === true ? true : false" class="row" style="margin-top:10px">
                    <div class="col">
                      <span *ngIf="f.facebook.value !== ''"><img src="../../../assets/images/icons/fb-receipt.svg" /> <label class="ms-1">{{f.facebook.value}}</label></span>
                    </div>
                    <div class="col">
                      <span *ngIf="f.twitter.value !== ''"><img src="../../../assets/images/icons/twitter-receipt.svg" /> <label class="ms-1">{{f.twitter.value}}</label></span>
                    </div>
                    <div class="col">
                      <span *ngIf="f.instagram.value !== ''"><img src="../../../assets/images/icons/instagam-receipt.svg" /> <label class="ms-1">{{f.instagram.value}}</label></span>
                    </div>
                    <div class="col">
                      <span *ngIf="f.snapchat.value !== ''"><img src="../../../assets/images/icons/snapchat-receipt.svg" /> <label class="ms-1">{{f.snapchat.value}}</label></span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
</form>
