import { Component, ElementRef, NgZone, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../_services/alert.service';
import { ExcelService } from '../../_services/excel.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as XLSX from 'xlsx';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ItemService } from '../../_services/item.service';
@Component({
  selector: 'app-import-manager',
  templateUrl: './import-manager.component.html',
  styleUrl: './import-manager.component.css'
})
export class ImportManagerComponent {
  ButtonText = "Next";
  formNumber = 1;
  loading = false;
  fileData: any;
  currentFile = null;
  selectedMethod = '';
  sheetVerified: boolean = false;
  currentProgress = 0;
  selectedColumn = {
    ItemName: false,
    AlternateItemName: false,
    Category: false,
    AlternateCategory: false,
    SubCategory: false,
    AlternateSubCategory: false,
    Description: false,
    Image: false,
    PriceType: false,
    Price: false,
    Status: false
  };
  isInValidT = {
    ItemName: false,
    AlternateItemName: false,
    Category: false,
    AlternateCategory: false,
    SubCategory: false,
    AlternateSubCategory: false,
    PriceType: false,
    Description: false,
    Price: false,
    Status: false,
    HasDuplication: false
  };
  constructor(public activeModal: NgbActiveModal,
    private alertService: AlertService,
    private excelService: ExcelService,
    private formBuilder: UntypedFormBuilder,
    private itemService: ItemService,
    private localStorageService: LocalStorageService) {
  }

  ngOnInit() {

  }
  SelectMethod(val) {
    this.selectedMethod = val;
  }
  close() {
    this.activeModal.dismiss();
  }
  showUploader(val) {
    this.SelectMethod(val);
    this.onSubmit();
  }
  download_CSV() {
    var data =
      [
        { Category: '', ArabicCategory: '', SubCategory: '', ArabicSubCategory: '', ItemName: '', ArabicItemName: '', Description: '', Image: '', PriceType: '', Price: '', Status: '' }
      ];
    this.excelService.exportAsExcelFile(data, 'ItemsTemplate', 'csv');
  }
  async onFileChange(ev) {
    let file = ev.target.files[0];
    //this.fileData = await this.excelService.ReadExcelFile(file);
    this.currentFile = file;
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const _file = file;
    if (ev.target.files[0].size <= 5000000) {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary', raw: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet, { defval: "" });
          let format = this.validateFileHeaders(initial);
          if (format === true) {
            this.fileData = [];
            initial.forEach(e => {
              e.AlternateCategory = e.ArabicCategory;
              e.AlternateSubCategory = e.ArabicSubCategory;
              e.AlternateItemName = e.ArabicItemName;
              this.checkValidations(e);
              this.fileData.push(e);
            });
           
          }
          else {
            this.alertService.error("Invalid or empty file");
          }
        }, {});
      }
      reader.readAsBinaryString(_file);

    }
    else {
      this.alertService.error("File size is larger than 5 MB.");
    }
  }
  getKey(item, formValue) {
    let key = '';
    Object.keys(item).forEach((val) => {
      if (formValue === val)
        key = val;
    });
    return key;
  }

  validateFileHeaders(sheet: any) {
    let sheetFormat = true;
    if (sheet && sheet.length > 0) {
      if (this.getKey(sheet[0], 'ItemName') !== 'ItemName' || !sheet[0]['ItemName'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'ArabicItemName') !== 'ArabicItemName' || !sheet[0]['ArabicItemName'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Category') !== 'Category' || !sheet[0]['Category'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'ArabicCategory') !== 'ArabicCategory' || !sheet[0]['ArabicCategory'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'SubCategory') !== 'SubCategory' || !sheet[0]['SubCategory'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'ArabicSubCategory') !== 'ArabicSubCategory' || !sheet[0]['ArabicSubCategory'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Description') !== 'Description')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Image') !== 'Image')
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Price') !== 'Price' || !sheet[0]['Price'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'PriceType') !== 'PriceType' || !sheet[0]['PriceType'])
        sheetFormat = false;
      if (this.getKey(sheet[0], 'Status') !== 'Status' || !sheet[0]['Status'])
        sheetFormat = false;
    }
    else {
      sheetFormat = false;
      this.alertService.error("Invalid or empty file");
    }
    return sheetFormat;
  }
  ValidateSheet(sheet) {
    let sheetFormat = true;
    sheet.filter(function (item) {
      if (Object.keys(item).length < 10)
        sheetFormat = false;
      if (!item['AltCategoryName'])
        sheetFormat = false;
      if (!item['AltSubCategoryName'])
        sheetFormat = false;
      if (!item['CategoryName'])
        sheetFormat = false;
      if (!item['Name'])
        sheetFormat = false;
      if (!item['NameOnReceipt'])
        sheetFormat = false;
      if (!item['Price'])
        sheetFormat = false;
      if (!item['SheetItemPriceType'])
        sheetFormat = false;
      if (!item['Status'])
        sheetFormat = false;
      if (!item['SubCategoryName'])
        sheetFormat = false;

    });
    return sheetFormat;
  }
  async randomWait() {
    this.loading = true;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    for (var i = 0; i < 100; i++) {
      let d = {
        ItemName: "",
        AlternateItemName: "",
        Category: "",
        AlternateCategory: "",
        SubCategory: "",
        AlternateSubCategory: "",
        Description: "",
        Image: "",
        Price: "",
        PriceType: "",
        Status: ""
      };
      this.fileData.push(d);
    }
    this.formNumber++;
    this.loading = false;
    return;
  }

  changePage_andReturnIfFinish(): boolean {
    if (!this.selectedMethod) {
      this.alertService.error('Select Method');
      return false;
    }
    else if (!this.fileData && this.formNumber === 2) {
      this.alertService.error('Select file');
      return false;
    }

    if (this.formNumber < 5) {
      this.formNumber++;
      if (this.formNumber === 3) {
        this.randomWait();
      }
      else if (this.formNumber === 5) {
        if (this.sheetVerified) {
          return true;
        }
        else {
          this.onVerification();
        }
      }
      return false;
    }
    else {
      return true;
    }
  }
  SetInerval() {
    const interval = setInterval(() => {
      this.currentProgress += 0.2 * 0.2;
      if (this.currentPercentage === 100) {
        clearInterval(interval);
      }
    });
  }
  get currentPercentage(): number {
    let val = Math.min(1, this.currentProgress) * 100;
    return parseFloat(val.toFixed(2));
  }

  async onVerification() {
    this.SetInerval();
    this.alertService.clear();

    this.loading = true;
    let uploadData = [];
    let brandID = this.localStorageService.getSelectedBrand().BrandID;
    let _this = this;
    let verificationHasError = false
    this.fileData.filter(function (item) {
      if (item.ItemName.toString().trim() && item.AlternateItemName.toString().trim() && item.Category.toString().trim() && item.AlternateCategory.toString().trim() &&
        item.SubCategory.toString().trim() && item.AlternateSubCategory.toString().trim() && item.PriceType.toString().trim() && item.Price && item.Status.toString().trim()) {
        var data =
        {
          Name: item.ItemName.toString().trim(),
          NameOnReceipt: item.AlternateItemName.toString().trim(),
          CategoryName: item.Category.toString().trim(),
          AltCategoryName: item.AlternateCategory.toString().trim(),
          SubCategoryName: item.SubCategory.toString().trim(),
          AltSubCategoryName: item.AlternateSubCategory.toString().trim(),
          Description: item.Description.toString().trim(),
          ItemImage: item.Image.toString().trim(),
          DisplayOrder: 1,
          Price: item.Price,
          SheetItemPriceType: item.PriceType.toString().trim(),
          Status: item.Status.toString().trim(),
          BrandID: brandID
        };
        let checkValidate = _this.checkValidations(item);
        if (checkValidate.ItemName === false &&
          checkValidate.AlternateItemName === false &&
          checkValidate.Category === false &&
          checkValidate.AlternateCategory === false &&
          checkValidate.SubCategory === false &&
          checkValidate.AlternateSubCategory === false &&
          checkValidate.PriceType === false &&
          checkValidate.Description === false &&
          checkValidate.Image === false &&
          checkValidate.Price === false &&
          checkValidate.Status === false &&
          checkValidate.HasDuplication === false) {
          uploadData.push(data);
        }
        else {
          verificationHasError = true;
        }
      }
    });
    if (uploadData.length > 0 && verificationHasError === false) {
      if (!this.sheetVerified && this.ValidateSheet(uploadData)) {
        this.itemService.VerifyItems(uploadData).subscribe(data => {
          if (data != null) {
            if (data.result.status === 1) {
              this.sheetVerified = true;
              this.ButtonText = "Insert Menu";
              this.loading = false;
            }
            else {
              let errorData = [];
              data.items.forEach(item => {
                var data =
                {

                  ItemName: item.name.toString().trim(),
                  AlternateItemName: item.nameOnReceipt.toString().trim(),
                  Category: item.categoryName.toString().trim(),
                  AlternateCategory: item.altCategoryName.toString().trim(),
                  SubCategory: item.subCategoryName.toString().trim(),
                  AlternateSubCategory: item.altSubCategoryName.toString().trim(),
                  Description: item.description.toString().trim(),
                  Image: item.itemImage.toString().trim(),
                  DisplayOrder: 1,
                  Price: parseFloat(item.price.toString().trim()),
                  PriceType: item.sheetItemPriceType.toString().trim(),
                  Status: item.status.toString().trim(),
                  BrandID: brandID,
                  HasError: item.hasError,
                  AlternateNameExists: item.alternateNameExists,
                  ItemNameExists: item.itemNameExists,

                };
                this.checkValidations(data)
                errorData.push(data);
              });
              this.fileData = errorData;
              this.formNumber = 4;
              this.loading = false;
            }
          }
          else {
            this.loading = false;
          }
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });
      }
      else {
        this.alertService.error("Data is not valid");
      }
    }
    else {
      this.formNumber = 4;
      this.loading = false;
    }
  }
  onSubmit() {
    if (this.changePage_andReturnIfFinish()) {
      if (this.fileData !== null && this.fileData !== undefined) {
        this.alertService.clear();

        this.loading = true;
        let uploadData = [];
        let brandID = this.localStorageService.getSelectedBrand().BrandID;
        this.fileData.filter(function (item) {
          if (item.ItemName.toString().trim() && item.AlternateItemName.toString().trim() && item.Category.toString().trim() && item.AlternateCategory.toString().trim() &&
            item.SubCategory.toString().trim() && item.AlternateSubCategory.toString().trim() && item.PriceType.toString().trim() && item.Price && item.Status.toString().trim()) {
            var data =
            {

              Name: item.ItemName.toString().trim(),
              NameOnReceipt: item.AlternateItemName.toString().trim(),
              CategoryName: item.Category.toString().trim(),
              AltCategoryName: item.AlternateCategory.toString().trim(),
              SubCategoryName: item.SubCategory.toString().trim(),
              AltSubCategoryName: item.AlternateSubCategory.toString().trim(),
              Description: item.Description.toString().trim(),
              ItemImage: item.Image.toString().trim(),
              DisplayOrder: 1,
              Price: parseFloat(item.Price.toString().trim()),
              SheetItemPriceType: item.PriceType.toString().trim(),
              Status: item.Status.toString().trim(),
              BrandID: brandID
            };
            uploadData.push(data);
          }
        });
        if (uploadData.length > 0) {
          if (this.sheetVerified) {
            this.itemService.uploadItemsV2(uploadData).subscribe(data => {
              if (data != null) {
                if (data.result.status === 1) {
                  this.alertService.success("Items have been saved");
                  this.activeModal.dismiss();
                } else {
                  //this.showFileUpload = false;
                  //this.showFileOptions = false;
                  //this.showError = true;
                  //this.errorList = data.messages;
                }
              }
              this.loading = false;
            }, error => {
              this.alertService.error(error);
              this.loading = false;
            });
          }
        }
        else
          this.alertService.error("Attached sheet is not valid");
      }

    }
  }

  isArabic(value: string): boolean {
    const arabicPattern = /^[\u0600-\u06FF\u0660-\u0669\u0030-\u0039\s]{3,}$/;
    return arabicPattern.test(value);
  }
  isNameValidate(value: string): boolean {
    const namePattern = /^[a-zA-Z0-9-\s]{3,}$/;
    return namePattern.test(value);
  }
  isNumberValidate(value: string): boolean {
    const namePattern = /^[0-9]+$/;
    return namePattern.test(value);
  }
  isImageValidate(value: string): boolean {
    const namePattern = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i;
    return namePattern.test(value);
  }

  checkValidations(item) {
    let isInValid = {
      ItemName: false,
      AlternateItemName: false,
      Category: false,
      AlternateCategory: false,
      SubCategory: false,
      AlternateSubCategory: false,
      PriceType: false,
      Description: false,
      Image: false,
      Price: false,
      Status: false,
      HasDuplication: false
    };
    item.ItemNameInvalid = false;
    item.AlternateItemNameInvalid = false;
    item.CategoryInvalid = false;
    item.AlternateCategoryInvalid = false;
    item.SubCategoryInvalid = false;
    item.AlternateSubCategoryInvalid = false;
    item.PriceTypeInvalid = false;
    item.DescriptionInvalid = false;
    item.ImageInvalid = false,
    item.PriceInvalid = false;
    item.StatusInvalid = false;
    item.HasDuplication = false;

    if (item.ItemName.toString().trim() || item.AlternateItemName.toString().trim() || item.Category.toString().trim() || item.AlternateCategory.toString().trim() ||
      item.SubCategory.toString().trim() || item.AlternateSubCategory.toString().trim() || item.PriceType.toString().trim() || item.Price || item.Status.toString().trim()) {

      if (item.ItemNameExists || item.ItemName.trim() === '' || !this.isNameValidate(item.ItemName)) {
        isInValid.ItemName = true;
        item.ItemNameInvalid = true;
      }
      if ((!this.isArabic(item.AlternateItemName) || item.AlternateItemName.trim() === '') || item.AlternateNameExists) {
        isInValid.AlternateItemName = true;
        item.AlternateItemNameInvalid = true;
      }
      if (item.Category.trim() === '' || !this.isNameValidate(item.Category)) {
        isInValid.Category = true;
        item.CategoryInvalid = true;
      }
      if (!this.isArabic(item.AlternateCategory) || item.AlternateCategory.trim() === '') {
        isInValid.AlternateCategory = true;
        item.AlternateCategoryInvalid = true;
      }
      if (item.SubCategory.trim() === '' || !this.isNameValidate(item.SubCategory)) {
        isInValid.SubCategory = true;
        item.SubCategoryInvalid = true;
      }
      if (!this.isArabic(item.AlternateSubCategory) || item.AlternateSubCategory.trim() === '') {
        isInValid.AlternateSubCategory = true;
        item.AlternateSubCategoryInvalid = true;
      }
      if (item.PriceType.trim() === '' || !this.isNameValidate(item.PriceType.toString().trim()) || !['fixed', 'fixed price', 'open price', 'open', 'unit price', 'unit'].includes(item.PriceType.toString().trim().toLowerCase())) {
        isInValid.PriceType = true;
        item.PriceTypeInvalid = true;
      }
      //if (item.Description && item.Description.trim() !== '' && !this.isNameValidate(item.Description.toString().trim())) {
      //  isInValid.Description = true;
      //  item.DescriptionInvalid = true;
      //}
      if (item.Image && item.Image.trim() !== '' && !this.isImageValidate(item.Image.toString().trim())) {
        isInValid.Image = true;
        item.ImageInvalid = true;
      }
      if (item.Price === '' || item.Price === 0 || !item.Price || !this.isNumberValidate(item.Price)) {
        isInValid.Price = true;
        item.PriceInvalid = true;
      }
      if (item.Status.toString().trim() === '' || !this.isNameValidate(item.Status.toString().trim()) || !['true', 'false'].includes(item.Status.toString().trim().toLowerCase())) {
        isInValid.Status = true;
        item.StatusInvalid = true;
      }
      if (this.fileData.length > 0 && this.fileData?.filter(a => a.ItemName.trim() === item.ItemName.trim() || a.AlternateItemName.trim() === item.AlternateItemName.trim()).length > 1) {
        isInValid.HasDuplication = true;
        item.HasDuplication = true;
      }
    }
    return isInValid;
  }

  selectColumn(val) {
    switch (val) {
      case 'itemName':
        this.selectedColumn.ItemName = !this.selectedColumn.ItemName;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'alternateItemName':
        this.selectedColumn.AlternateItemName = !this.selectedColumn.AlternateItemName;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'categoryName':
        this.selectedColumn.Category = !this.selectedColumn.Category;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'alternateCategoryName':
        this.selectedColumn.AlternateCategory = !this.selectedColumn.AlternateCategory;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'subcategoryName':
        this.selectedColumn.SubCategory = !this.selectedColumn.SubCategory;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'alternateSubcategoryName':
        this.selectedColumn.AlternateSubCategory = !this.selectedColumn.AlternateSubCategory;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'description':
        this.selectedColumn.Description = !this.selectedColumn.Description;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'image':
        this.selectedColumn.Image = !this.selectedColumn.Image;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'price':
        this.selectedColumn.Price = !this.selectedColumn.Price;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Status = false;
        break;
      case 'priceType':
        this.selectedColumn.PriceType = !this.selectedColumn.PriceType;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.Price = false;
        this.selectedColumn.Status = false;
        break;
      case 'status':
        this.selectedColumn.Status = !this.selectedColumn.Status;

        this.selectedColumn.ItemName = false;
        this.selectedColumn.AlternateItemName = false;
        this.selectedColumn.Category = false;
        this.selectedColumn.AlternateCategory = false;
        this.selectedColumn.SubCategory = false;
        this.selectedColumn.AlternateSubCategory = false;
        this.selectedColumn.Description = false;
        this.selectedColumn.Image = false;
        this.selectedColumn.PriceType = false;
        this.selectedColumn.Price = false;
        break;
    }
  }
  ChangeAllValues(event, col) {
    let _this = this;
    this.fileData.filter(function (item) {
      if (item.ItemName.toString().trim() && item.AlternateItemName.toString().trim() && item.Category.toString().trim() && item.AlternateCategory.toString().trim() &&
        item.SubCategory.toString().trim() && item.AlternateSubCategory.toString().trim() && item.PriceType.toString().trim() && item.Price && item.Status.toString().trim()) {
        switch (col) {
          case 'itemName':
            item.ItemName = event.target.value.toString().trim();
            _this.selectedColumn.ItemName = false;
            break;
          case 'alternateItemName':
            item.AlternateItemName = event.target.value.toString().trim();
            _this.selectedColumn.AlternateItemName= false;
            break;
          case 'categoryName':
            item.Category = event.target.value.toString().trim();
            _this.selectedColumn.Category = false;
            break;
          case 'alternateCategoryName':
            item.AlternateCategory = event.target.value.toString().trim();
            _this.selectedColumn.AlternateCategory = false;
            break;
          case 'subcategoryName':
            item.SubCategory = event.target.value.toString().trim();
            _this.selectedColumn.SubCategory = false;
            break;
          case 'alternateSubcategoryName':
            item.AlternateSubCategory = event.target.value.toString().trim();
            _this.selectedColumn.AlternateSubCategory = false;
            break;
          case 'description':
            item.Description = event.target.value.toString().trim();
            _this.selectedColumn.Description = false;
            break;
          case 'image':
            item.Image = event.target.value.toString().trim();
            _this.selectedColumn.Image = false;
            break;
          case 'price':
            item.Price = event.target.value;
            _this.selectedColumn.Price = false;
            break;
          case 'priceType':
            item.PriceType = event.target.value.toString().trim();
            _this.selectedColumn.PriceType = false;
            break;
          case 'status':
            item.Status = event.target.value.toString().trim();
            _this.selectedColumn.Status = false;
            break;
        }
      }
    });
  }
  ExportSheetData() {
    var data = [];
    this.fileData.forEach(e => {
      let d = {
        Category: e.Category,
        ArabicCategory: e.AlternateCategory,
        SubCategory: e.SubCategory,
        ArabicSubCategory: e.AlternateSubCategory,
        ItemName: e.ItemName,
        ArabicItemName: e.AlternateItemName,
        Description: e.Description,
        Image: e.Image,
        PriceType: e.PriceType,
        Price: e.Price,
        Status: e.Status
      };
      data.push(d);
    });
    this.excelService.exportAsExcelFile(data, 'UploadItemData', 'csv');
  }
  moveToPage(pageNumber: number) {
    if (!this.sheetVerified && pageNumber < this.formNumber) {
      this.formNumber = pageNumber;
    }
  }
}
