import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { AdduserComponent } from '../adduser/adduser.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubUserService } from '../../../_services/sub-user.service';
import { Observable, Subscription } from 'rxjs';
import { SubUser } from '../../../_models/SubUser';
import { AlertService } from '../../../_services/alert.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { TrackEventsService } from '../../../_services/track-events.service';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserlistComponent implements OnInit {
  data$: Observable<SubUser[]>;
  oldData: SubUser[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  submit: boolean;
  StatusList = [];
  UserTypeList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private modalService: NgbModal,
    public service: SubUserService,
    private alertService: AlertService,
    private local: LocalStorageService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.loading$ = service.loading$;
    this.submit = false;
    this.getData();
  }

  ngOnInit() {

  }


  open() {
    const modalRef = this.modalService.open(AdduserComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create User");
    modalRef.componentInstance.SelectedID = 0
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
        this.getData();
    });
  }

  getUser(subUser) {
    const modalRef = this.modalService.open(AdduserComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit User");
    modalRef.componentInstance.SelectedID = subUser.subUserID;
    modalRef.componentInstance.SelectedName = subUser.firstName + ' ' + subUser.lastName;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });

  }

  getData() {
    this.service.headerFilter = { status:[''],usertype:['']};
    var brandID = this.local.getSelectedBrand().BrandID;
    this.service.getAll(brandID);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      this.UserTypeList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(val => val.userType)).forEach(e => {
          this.UserTypeList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  delete(id, version) {
    this.service.delete(parseInt(id), version).subscribe((res: any) => {
      this.alertService.success("User has been Deleted");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  deactive(id, version) {
    this.service.deactive(parseInt(id), version).subscribe((res: any) => {
      this.alertService.success("User has been Deactive");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  setStatus(id,statusID, version) {
    this.service.setStatus(parseInt(id), parseInt(statusID), version).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("User has been Actived");
      else
        this.alertService.success("User has been Deactived");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription !== undefined)
      this.locationSubscription.unsubscribe();
  }
  public openConfirmationDialog(id, version, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), version).subscribe((res: any) => {
            this.alertService.success("User has been Deleted");
            this.getData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';
  SelectedUserType = "All Access Type";
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      usertype: (this.SelectedUserType == 'All Access Type') ? this.UserTypeList.map(function (k) { return k.name }) : [this.SelectedUserType]
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}
