import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { FloorService } from 'src/app/_services/floor.service';
import { LocationService } from 'src/app/_services/location.service';

@Component({
  selector: 'app-addfloor',
  templateUrl: './addfloor.component.html',
  styleUrls: ['./addfloor.component.css']
})
export class AddfloorComponent implements OnInit {
  @Input() name;



  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  floorForm: UntypedFormGroup;
  loading = false;
  loadingFloor = false;
  ButtonText = "Add Floor";
  Title = "Add Floor";
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private floorService: FloorService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedFloor();
  }

  ngAfterViewInit() {
    // document.getElementById("floorTitle").focus();
  }


  private createForm() {
    //this.Location = DataService.Name;
    this.floorForm = this.formBuilder.group({
      floorNo: [0, Validators.required],
      floorTitle: ['', Validators.required],
      tableCount: [0, Validators.required],
      statusID: [true, Validators.required],
      locationID: LocationService.locationId,
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      floorID: 0,
      rowVersion: 0
    });
  }


  get f() { return this.floorForm.controls; }

  setSelectedFloor() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingFloor = true;
      this.f.floorID.setValue(this.SelectedID);
      this.floorService.getById(this.SelectedID).subscribe(res => {

        this.Title = "Edit Floor";
        //Set Forms
        this.editForm(res);
        this.loadingFloor = false;


      });
    }
  }


  private editForm(floor) {
    this.f.floorID.setValue(floor.floorID);
    this.f.floorNo.setValue(floor.floorNo);
    this.f.floorTitle.setValue(floor.floorTitle);
    this.f.rowVersion.setValue(floor.rowVersion);
    this.f.statusID.setValue(floor.statusID === 1 ? true : false);
    this.f.locationID.setValue(floor.locationID);
    this.f.brandID.setValue(floor.brandID);
  }


  close() {
    this.activeModal.dismiss();
  }



  onSubmit() {
    this.floorForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.floorNo.setValue(parseInt(this.f.floorNo.value));
    this.f.tableCount.setValue(parseInt(this.f.tableCount.value));
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    if (this.floorForm.invalid) { return; }
    this.loading = true;

    if (parseInt(this.f.floorID.value) === 0) {

      //Insert Location
      console.log(JSON.stringify(this.floorForm.value));
      this.floorService.insert(this.floorForm.value).subscribe(data => {
        this.alertService.success("Floor has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update Location
      this.floorService.update(this.floorForm.value).subscribe(data => {
        this.alertService.success("Floor has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }



}
