<section class="tile position-relative">
  <div class="d-flex row gy-2 px-3 pt-1 mobile-column-reverse">
    <div class="col-sm-7 col-xl-7 col-lg-12 col-md-12 hide-mobile h6line-height">  <h6>{{'Location Floors' | translate}}</h6></div>
    <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
      <div class="filter-field">
        <div class="position-relative">
          <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </div>
      </div>
    </div>
    <div class="col-sm-2 col-xl-2 col-lg-12 col-md-12">
      <div class="create-btn" *hasPermission="['Locations','Floors', 'Insert']">
        <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn w-100">
          {{'Add floor' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="tile-body table-responsive form text-nowrap">
    <table class="table table-striped">
      <thead>
        <tr class="table-header">
          <th width="20%" sortable="floorNo" (sort)="onSort($event)">{{'Floor' | translate }} # <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th width="30%" sortable="floorTitle" (sort)="onSort($event)">{{'Floor Title' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th width="25%" sortable="tableCount" (sort)="onSort($event)">{{'Tables' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th width="20%" sortable="statusID" (sort)="onSort($event)">{{'Status' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" /></th>
          <th width="5%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data$ | async " editRow>
          <td (click)="Edit(item)">
            <div class="item-title">{{item.floorNo}}</div>
          </td>
          <td (click)="Edit(item)">{{item.floorTitle}}</td>
          <td (click)="Edit(item)">{{item.tableCount}}</td>
          <td (click)="Edit(item)">
            <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
            </ngb-highlight>
          </td>
          <td>
            <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
              <img class="dots-list" src="/assets/images/dots-list.svg" />
            </div>
            <ng-template #popContent>
              <p class="dropdown-heading-mini">{{'Floors' | translate }}</p>
              <a *hasPermission="['Locations','Floors', 'Edit']" (click)="Edit(item)">{{'Edit' | translate }}</a>
              <a *hasPermission="['Locations','Floors', 'Delete']" (click)="openConfirmationDialog(item.floorID, item.rowVersion,item.floorTitle)" class="selected-inner-text selected-inner-bottom">{{'Delete' | translate }}   </a>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <p class="pagination-count">
      {{'Showing' | translate }}
      <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
      <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
      <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
    </p>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
      <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
      <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
      <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
      <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>
