import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { Floors } from '../_models/Floor';

interface SearchFloorsResult {
  data: Floors[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Floors[], column: SortColumn, direction: string): Floors[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}


function matches(data: Floors, term: string) {

  return data.floorTitle.toLowerCase().includes(term.toLowerCase());
  //|| pipe.transform(location.email).includes(term)


}

@Injectable({
  providedIn: 'root'
})
export class FloorService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Floors[]>([]);
  private _data$ = new BehaviorSubject<Floors[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public floor: Floors[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getFloors(locationId) {
    const url = `api/floor/all/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Floors[]>(url).subscribe(res => {
        this.floor = res;
        this._data$.next(this.floor);
        this._allData$.next(this.floor);


        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {
          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();

      });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchFloorsResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.floor, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }



  clear() {
     // clear by calling subject.next() without parameters
     this._search$.next();
     this._data$.next(null);
     this._allData$.next(null);
     this._total$.next(null);
     this._loading$.next(null);
     this._state = {
       page: 1,
       pageSize: 10,
       searchTerm: '',
       sortColumn: '',
       sortDirection: ''
     };
   }


  getById(id) {
    return this.http.get<any[]>(`api/floor/${id}`);
  }

  insert(data) {
    
    return this.http.post(`api/floor`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    
    return this.http.put(`api/floor`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  deactive(id, version) {
    return  this.http.patch(`api/floor/deactive/${id}/${version}`, null);
 
   }


  delete(id, version) {
    return this.http.delete<any[]>(`api/floor/${id}/${version}`);
  }

}

