import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../_services/alert.service';
import { JahezServiceService } from '../../../_services/jahez-service.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { MarketplaceService } from '../../../_services/marketplace.service';
import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';

@Component({
  selector: 'app-jahez-integration',
  templateUrl: './jahez-integration.component.html',
  styleUrls: ['./jahez-integration.component.css']
})
export class JahezIntegrationComponent implements OnInit {
  @Input() PartnerID = 0;

  ButtonText = "Save";
  submitted = false;
  JahezForm: UntypedFormGroup;
  loading = false;
  Locations: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private services: JahezServiceService,
    private _modalService: NgbModal) {
    
  }
  ngOnInit(): void {
    this.createForm();
    this.setSelectedPartner();
    this.Locations = this.localStorageService.getSelectedBrand().Locations;
  }
  private createForm() {

    this.JahezForm = this.formBuilder.group({
      x_API_KEY: ['', Validators.required],
      secretCode: ['', Validators.required],
      integrationID: 0,
      statusID: [true, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      userID: parseInt(this.localStorageService.getSelectedUser().User.UserID),
      rowVersion: 0,
      partnerID: this.PartnerID
    });
  }
  get f() { return this.JahezForm.controls; }




  private editForm(value) {

    this.f.x_API_KEY.setValue(value.x_API_KEY);
    this.f.secretCode.setValue(value.secretCode);
    //this.f.statusID.setValue(value.statusID === 1 ? true : false);
    this.f.integrationID.setValue(value.integrationID);

  }
  setSelectedPartner() {

    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getIntegration(this.f.brandID.value, this.PartnerID).subscribe((res) => {
        //Set Forms
        console.log(res);
        if (res !== null) {
          this.editForm(res);
          this.ButtonText = "Save";
        }
        this.loading = false;
      });
    }
  }
  onSubmit() {
    this.JahezForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    //this.f.partnerID.setValue(this.PartnerID);

    if (this.JahezForm.invalid) { return; }
    this.loading = true;

    this.services.activateIntegration(this.JahezForm.value).subscribe(data => {
      this.alertService.success("Jahez has installed successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });


  }

  close() {
    this.activeModal.dismiss();
  }
  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true, windowClass: 'uninstall-modal', modalDialogClass: 'uninstall-modal-custom' });
    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
      }
    });
  }
  uninstall() {
    this.loading = true;
    this.services.Uninstall(parseInt(this.f.integrationID.value)).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Jahez has uninstalled successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }
  syncProducts(locationID:number) {
    this.loading = true;
    var req = {
      brandID: parseInt(this.f.brandID.value),
      partnerID: parseInt(this.f.partnerID.value),
      locationID: locationID,
    };
    this.services.syncPorducts(req).subscribe(data => {
      this.alertService.success("Products synced successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });   
  }

}
