<!-- Form -->
<form [formGroup]="forgotForm" *ngIf="!isLinkSend" @fadeSlideInOut class="forgotpassword" (ngSubmit)="sendForgotPasswordLink()">
  <h2>{{'Reset Password' | translate }}</h2>
  <!--<p class="mb-per-10">{{'Enter the email address you used to register' | translate }}</p>-->
  <div class="form-group">
    <div class="form-control selection">
      <a id="select-restaurant" href="#Restaurant" (click)="setIndustry(true)" [class.active]="isRestaurant===true">{{'Restaurant' | translate }}</a>
      <a id="select-retail" href="#Retail" (click)="setIndustry(false)" [class.active]="isRestaurant===false">{{'Retail' | translate }} </a>
    </div>
  </div>
  <div id="forgotpassword">
    <!-- Email address -->
    <div class="form-group">
      <!-- Input -->
      <input type="email" formControlName="username" pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,24}$"
             [class.is-valid]="f.username.invalid===false" name=res-email class="form-control"
             [class.input-error]="f.username.invalid && (f.username.dirty)"
             placeholder="{{'Email Address' | translate }}">
      <div *ngIf="f.username.invalid && (f.username.dirty)">
        <label *ngIf="f.username.errors.required" class="error">
          {{'Username is required' | translate }}.
        </label>
        <label *ngIf="f.username.invalid" class="error">
          {{'Invalid username format' | translate }}.
        </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-12 col-sm-12">
        <re-captcha (resolved)="resolved($event)"></re-captcha>
      </div>
    </div>
  </div>

  <!-- Submit -->
  <button type="submit" [disabled]="loading || f.username.invalid || !captchaResolved"
          class="btn btn-primary btn-lg btn-block">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{'Send Reset Link' | translate }}
  </button>

</form>


<form *ngIf="isLinkSend" @fadeSlideInOut class="forgotpassword" (ngSubmit)="sendForgotPasswordLink()">

  <h1> {{'Reset Password' | translate }}</h1>
  <p class="mute">{{'Check your inbox for an email from Marn support' | translate }}</p>
  <p class="mb-per-10">{{'We just sent you an update password reset link. if you don’t see it in your inbox, remember to check your spam folder' | translate }}.</p>

</form>
