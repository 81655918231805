<div class="mnt-header position-relative order-list product-list">


  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-30" *ngIf="(service.reconcillations?.length > 0)">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Reconciliation' | translate }} </h5>
    </div>
    <div class="col-md-auto" *hasPermission="['Inventory','Inventory Action','Reconciliation', 'Create']">
      <button type="button" (click)="add()" class="btn btn-primary cutom-button custom-create-btn">
        {{'+ Create Reconciliation' | translate }}
      </button>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Inventory','Inventory Action','Reconciliation', 'Export']">

          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>



  <div *ngIf="(service.reconcillations?.length > 0)">
    <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
      <div class="tile-body p-0 table-responsive text-nowrap">
        <table class="table table-striped text-start">
          <thead>
            <tr class="table-header">
              <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
              <th width="25%">
                <!-- Location Filter -->
                <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                  <button class="btn btn-outline-filter" id="ddlLocationFilter" ngbDropdownToggle
                          [ngClass]="{ 'active': setSelectedResult('location') > 0}">
                    <span>
                      <span class="badge" *ngIf="setSelectedResult('location')>0">{{setSelectedResult('location')}}</span>

                      {{'Location' | translate}}

                    </span>
                    <img class="down-aero" ngbDropdownOpen src="{{
                    (setSelectedResult('location') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'
                  }}">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ddlLocationFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                    <div>
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" id="dropdownLocation_all" (change)="selectAllFilters($event,Locations)">
                        <label class="form-check-label font-blue" for="dropdownLocation_all" (click)="selectAllFilters($event,Locations)">
                          {{'All Locations' | translate}}
                        </label>
                      </div>
                    </div>
                    <div *ngFor="let location of Locations; let i = index">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" id="dropdownLocation_{{i+1}}" [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                        <label class="form-check-label" for="dropdownLocation_{{i+1}}" (click)="filters()">
                          {{location.name}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th width="15%" sortable="date" (sort)="onSort($event)">
                {{'Date' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
              </th>
              <th width="15%" sortable="time" (sort)="onSort($event)">
                {{'Time' | translate }}# <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
              </th>
              <th width="20%" sortable="referenceNo" (sort)="onSort($event)">
                {{'Refrence' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
              </th>
              <th width="15%" sortable="creaedBy" (sort)="onSort($event)">
                {{'Created By' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
              </th>
              <th width="5%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reconciliation of data$ | async " editRow>
              <td class="text-center">
                <input type="checkbox" class="form-check-input" (change)="chkSelection($event,reconciliation)"
                       [(ngModel)]="reconciliation.selected" />
              </td>
              <td (click)="Edit(reconciliation);" class=" name-blue"> {{reconciliation.locationName}} </td>
              <td (click)="Edit(reconciliation);"> {{reconciliation.date | date}} </td>
              <td (click)="Edit(reconciliation);"> {{reconciliation.date | date:'shortTime' }} </td>
              <td (click)="Edit(reconciliation);"> {{reconciliation.referenceNo }} </td>
              <td (click)="Edit(reconciliation);"> {{reconciliation.creaedBy }} </td>
              <td>
                <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                  <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                </div>
                <ng-template #popContent>
                  <p class="dropdown-heading-mini">{{'RECONCILIATION' | translate }}</p>
                  <a *hasPermission="['Inventory','Inventory Action','Reconciliation', 'Preview']" (click)="Edit(reconciliation)">{{'Preview' | translate }}</a>
                  <a *hasPermission="['Inventory','Inventory Action','Reconciliation', 'Print']" (click)="Print(reconciliation)">{{'Print' | translate }}</a>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                        [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst>
            <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
          </ng-template>
          <ng-template ngbPaginationLast>
            <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
          </ng-template>
          <ng-template ngbPaginationPrevious>
            <img src="../../../assets/images/icons/backward.svg" />
          </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>

  <div *ngIf="(service.reconcillations?.length == 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Reconciliation yet' | translate }}</strong>
      <p class="text-center width-375">{{'Create New Reconciliation.' | translate}}</p>
    </div>
    <div class="items-flex">
      <div class="create-btn mlmr-10">
        <div>
          <button type="button" (click)="add()" class="btn btn-primary cutom-button form-control custom-create-btn">
            {{'+ Create New Reconciliation' | translate }}
          </button>
        </div>
      </div>


    </div>
  </div>

  <div *ngIf="(service.reconcillations?.length > 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Record Found' | translate }}</strong>
      <p class="text-center width-375">{{'Create reconciliation for purchases.' | translate}}</p>
    </div>
  </div>
</div>
