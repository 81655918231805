<form

  class="form msegat"
  style="background-color: #edf1f5"
>
  <div class="modal-header">
    <div class="d-flex" style="width: 100%">
      <div class="d-flex align-items-start justify-content-start flex-1">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="d-flex align-items-center justify-content-center flex-1">
        <h4 class="modal-title">
          {{ "Payment Links" | translate }}
        </h4>
      </div>

      <div class="d-flex align-items-end justify-content-end flex-1">
        <button
          [disabled]="loading"
          class="btn btn-primary btn-save"
          (click)="onSubmit()"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          {{ ButtonText | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="modal-body integration" style="background-color: #edf1f5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" style="margin-top: 40px">
          <div class="row">
            <div class="col-12">
              <label class="greeting-title">
                {{ "Payment Service Provider" | translate }} </label
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">

              <select class="custom-select form-control custom-select-2" style="background-color: #fff; height: 50px" [(ngModel)]="DefaultPaymentIntegrationID" [ngModelOptions]="{standalone: true}"  >
                <option value=0
                        selected="option.integrationID == DefaultPaymentIntegrationID">
                  {{'None' | translate}}
                </option>
                <option [ngValue]="option.integrationID"
                        selected="option.integrationID == DefaultPaymentIntegrationID"
                        *ngFor="let option of Data?.paylinks">
                  {{option.name}}
                </option>
              </select>



            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <label class="greeting-title">
                {{ "SMS Service Provider" | translate }}</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">

              <select class="custom-select form-control custom-select-2" style="background-color: #fff; height: 50px" [(ngModel)]="DefaultSMSIntegrationID" [ngModelOptions]="{standalone: true}"  >
                <option value=0 selected="option.integrationID == DefaultSMSIntegrationID">
                  {{'None' | translate}}
                </option>
                <option [ngValue]="option.integrationID"
                        selected="option.integrationID== DefaultSMSIntegrationID"
                        *ngFor="let option of Data?.sms">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group" style="margin-top: 20px" *ngIf="DefaultSMSIntegrationID > 0">
            <div class="row high-safety-mode">
              <div class="col-12">
                <label for="highSafety" class="label-bold">
                  <svg
                    style="margin-left: 10px"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z"
                      fill="#545F75"
                    />
                  </svg>
                  {{ "Using Custom SMS" | translate }}
                </label>
              </div>

              <div class="form-group col-lg-12 col-sm-12">
                <label style="
                    margin-left: 31px;
                    font-size: 12px !important;
                    color: #545f75;
                  ">
                  {{ "Send Message when a new Hold or partially paid order is issued for the customer" | translate }}
                </label>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="DefaultSMSIntegrationID > 0">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="CustomerPaymentLinkMsg"
                >{{ "Description" | translate }}
              </label>
              <textarea
                type="text"
                rows="2"
                style="height: unset"
                class="form-control message-format"
                id="CustomerPaymentLinkMsg"
                placeholder=""

                [(ngModel)]="DefaultPaymentMsg" [ngModelOptions]="{standalone: true}"
              >
              </textarea>
            </div>
          </div>

          <div class="row" *ngIf="DefaultSMSIntegrationID > 0">
            <div
              class="form-group col-lg-12 col-sm-12"
              style="margin-bottom: 10px"
            >
              <p class="greeting-desc">
                {{ textSamplePayLink | translate }}
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</form>
