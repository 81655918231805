

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { AddgroupComponent } from './addgroup/addgroup.component';
import { Group } from 'src/app/_models/group';
import { GroupService } from 'src/app/_services/group.service';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TrackEventsService } from '../../_services/track-events.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
  providers: [ExcelService]
})
export class GroupComponent implements OnInit {
  data$: Observable<Group[]>;
  oldData: Group[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "group";
  excellData: any = [];
  submit: boolean;
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: GroupService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  getBrandData() {
    this.service.headerFilter = {status:['']};
    this.service.getGroups(this.selectedBrand);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];

      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddgroupComponent, { size: 'lg', windowClass: 'small-modal' });
    this.trackEvents.Log_Screen("Create Inventory Group");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });

  }

  Edit(group) {
    const modalRef = this.modalService.open(AddgroupComponent, { size: 'lg', windowClass: 'small-modal' });
    this.trackEvents.Log_Screen("Edit Inventory Group");
    modalRef.componentInstance.SelectedID = group.groupID;
    modalRef.componentInstance.SelectedName = group.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Group has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Group has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(group) {
    this.service.getById(group.groupID, group.brandID).subscribe((res: any) => {
      res.statusID = group.statusID === 1 ? 2 : 1;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Group has been " + (group.statusID === 1 ? "Deactived" : "Active"));
        this.getBrandData();
      }, error => {
        this.alertService.error(error);
      });
    });
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }


  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-InventoryGroup', type);
  }
  private getExportData() {
    let selected: any[];

    selected = [];
    this.service.group.forEach(d => {
      if (d.selected) {
        selected.push({
          Name: d.name,
          Status: (d.statusID == 1 ? 'Active' : 'Inactive')
        });
      }
    });
    if (selected.length == 0) {
      this.service.group.forEach(d => {
          selected.push({
            Name: d.name,
            Status: (d.statusID == 1 ? 'Active' : 'Inactive')
          });
      });
    }
    return selected;
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Group has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';

  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]

    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}

