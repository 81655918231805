<div class="mnt-header position-relative order-list product-list">
  <div class="row form px-2 mb-3" *ngIf="trial?.ShowBanner  || trial?.IsExpired">
    <div class="form-group col-lg-12">
      <div class="status-activebar row shadow">
        <div class="col-1 mt-1" style="width:55px">
          <label for="Status">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g style="mix-blend-mode:multiply">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 53.6668C41.7276 53.6668 53.6666 41.7278 53.6666 27.0002C53.6666 12.2726 41.7276 0.333496 27 0.333496C12.2724 0.333496 0.333313 12.2726 0.333313 27.0002C0.333313 41.7278 12.2724 53.6668 27 53.6668Z" fill="#FAF5FF" />
              </g>
              <g style="mix-blend-mode:multiply">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 47C38.0457 47 47 38.0457 47 27C47 15.9543 38.0457 7 27 7C15.9543 7 7 15.9543 7 27C7 38.0457 15.9543 47 27 47Z" fill="#F3E8FF" />
              </g>
              <g clip-path="url(#clip0_11064_338)">
                <path d="M27 31V27M27 23H27.01M37 27C37 32.5228 32.5228 37 27 37C21.4772 37 17 32.5228 17 27C17 21.4772 21.4772 17 27 17C32.5228 17 37 21.4772 37 27Z" stroke="#9333EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_11064_338">
                  <rect width="24" height="24" fill="white" transform="translate(15 15)" />
                </clipPath>
              </defs>
            </svg>
          </label>
        </div>
        <div class="col-11">
          <p class="inactive mb-0 mt-1 fw-bold" *ngIf="trial?.Access"> {{'Free Trial!' | translate}}</p>
          <p class="inactive mb-0 mt-1 fw-bold" *ngIf="trial?.IsExpired"> {{'Trial Expired!' | translate}}</p>
          <p class="inactive mb-0 text-transform-none"> {{'This feature will be on a free trial until' | translate}} {{ trial.EndDate | date:'dd MMMM'}}. {{'If you\'d like to continue using it after that period, please contact us at' | translate}} <span class="fw-bold">920006276</span>.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-30 align-items-center">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Expenses' | translate }} </h5>
    </div>

    <div class="col-md-auto" *hasPermission="['Analytics','Accounting','Expense', 'Create']">
      <button type="button" (click)="trial?.Access && create()" class="btn btn-primary cutom-button custom-create-btn" [ngClass]="{'cursor-not-allowed': !trial?.Access}">
        {{'+ Add Expense' | translate }}
      </button>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Analytics','Accounting','Expense', 'Export']">
          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
    <div class="col-md-auto">
      <div class="p-0" *hasPermission="['Analytics','Accounting','Expense', 'Filter']">
        <div ngbDropdown>
          <a ngbDropdownToggle class="form-control text-center selected-location text-decoration-none">
            <span>{{selectedLocationName | translate}}</span>
            <img class="down-aero" src="assets/images/down-aero.svg">
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropaero-menu">
            <a class="dropdown-item" [hidden]="selectedLocationName == 'All Locations' " (click)="setLocation(null)">{{'All Locations' | translate }} </a>
            <a class="dropdown-item" *ngFor="let location of Locations" [hidden]="location.name == selectedLocationName"
               (click)="setLocation(location)">{{location.name}} </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto" *hasPermission="['Analytics','Accounting','Expense', 'Filter']">
      <ngbd-datepicker-range (OnHit)="trial?.Access && setPeriod()"></ngbd-datepicker-range>
    </div>
  </div>
  <div *ngIf="(service.expense?.length > 0)">
    <div class="position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped text-start">
            <thead>
              <tr class="table-header">
                <th width="5%" class="text-center">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <th width="10%" sortable="code" (sort)="onSort($event)">
                  {{'Expense ID' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%" sortable="name" (sort)="onSort($event)">
                  {{'Expense Name' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%" sortable="referenceNo" (sort)="onSort($event)">
                  {{'Ref No.' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%" sortable="grandTotal" (sort)="onSort($event)">
                  {{'Total' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%">
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlLocationFilter" ngbDropdownToggle
                            [ngClass]="{ 'active': setSelectedResult('location') > 0}">
                      <span>
                        <span class="badge" *ngIf="setSelectedResult('location')>0">{{setSelectedResult('location')}}</span>

                        {{'Location' | translate}}

                      </span>
                      <img class="down-aero" ngbDropdownOpen src="{{(setSelectedResult('location') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'}}">
                    </button>

                    <div ngbDropdownMenu aria-labelledby="ddlLocationFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdownLocation_all" (change)="selectAllFilters($event,LocationList)">
                          <label class="form-check-label font-blue" for="dropdownLocation_all" (click)="selectAllFilters($event,LocationList)">
                            {{'All Location' | translate}}
                          </label>
                        </div>
                      </div>

                      <div *ngFor="let location of LocationList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdownLocation_{{i+1}}" [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                          <label class="form-check-label" for="dropdownLocation_{{i+1}}" (click)="filters()">
                            {{location.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th width="20%" sortable="maturityDate" (sort)="onSort($event)">
                  {{'Due Date' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="20%" sortable="generationDate" (sort)="onSort($event)">
                  {{'Issue Date' | translate }} <img class="d-inline " src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="10%" class="text-center">
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                      <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Void')) | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                               name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                        <label class="form-check-label font-blue" for="dropdownStatus_all"
                               (click)="selectAllFilters($event,StatusList)">
                          {{'All Status' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let item of StatusList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                          <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                            <!-- {{item.name}} -->

                            <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                           class="btn btn-sm" [result]="(item.name ==1?'Active':'Void') | translate">
                            </ngb-highlight>


                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th width="5%">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let expense of data$ | async " editRow>
                <td class="text-center">
                  <input type="checkbox" class="form-check-input" (change)="chkSelection($event,expense)" [(ngModel)]="expense.selected" />
                </td>

                <td (click)="Preview(expense)" class=" name-blue"> {{expense.code}} </td>
                <td (click)="Preview(expense)"> {{expense.name }} </td>
                <td (click)="Preview(expense)"> {{expense.referenceNo}} </td>
                <td (click)="Preview(expense)"> {{expense.grandTotal | number : '1.2-2'}} </td>
                <td (click)="Preview(expense)"> {{expense.locations }} </td>
                <td (click)="Preview(expense)"> {{expense.maturityDate | date}} </td>
                <td (click)="Preview(expense)"> {{expense.generationDate | date}} </td>
                <td class="text-center">
                  <ngb-highlight [ngClass]="{'btn-active': expense.statusID === 1, 'btn-inactive':expense.statusID !== 1 }" class="btn btn-sm" [result]="(expense.statusID ==1?'Active':'Void') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td>
                  <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                    <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                  </div>
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'Expense' | translate }}</p>
                    <a *hasPermission="['Analytics','Accounting','Expense', 'Preview']" (click)="Preview(expense)">{{'Preview' | translate }}</a>
                    <a class="selected-inner-text color-danger border-0 m-0 py-2" (click)="openConfirmationDialog(expense)" *ngIf="expense.statusID !== 3">
                      {{'Void' | translate }}
                    </a>
                    <!--*hasPermission="['Analytics','Accounting','Expense', 'Void']"-->
                  </ng-template>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                        [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst>
            <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
          </ng-template>
          <ng-template ngbPaginationLast>
            <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
          </ng-template>
          <ng-template ngbPaginationPrevious>
            <img src="../../../assets/images/icons/backward.svg" />
          </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

  </div>


  <div *ngIf="(service.expense?.length == 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Expense yet' | translate }}</strong>
      <p class="text-center width-375">{{'Create Expense.' | translate}}</p>
    </div>
    <div class="items-flex">
      <div class="create-btn mlmr-10">
        <div>
          <button type="button" (click)="trial?.Access && create()" class="btn btn-primary cutom-button form-control custom-create-btn">
            {{'+ Add Expense' | translate }}
          </button>
        </div>
      </div>


    </div>
  </div>

  <div *ngIf="(service.expense?.length > 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Record Found' | translate }}</strong>
      <p class="text-center width-375">{{'Create Expense.' | translate}}</p>
    </div>
  </div>


</div>
