<!-- Sidebar -->
<div class="bg-white border-right" [class.toggled]="toggled" id="sidebar-wrapper">
  <div class="sidebar-heading">
    <ul class="nav nav-inline">
      <li ngbDropdown class="nav-item">
        <a ngbDropdownToggle class="profile">
          <img [src]="SelectedUser.UserImage" class="img-circle wh34" *ngIf="!ImageError" (error)="errorHandler($event)" />
          <span class="img-error" *ngIf="ImageError"> {{SelectedUser.FirstName.substring(0,2)}} </span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropaero-profile">
          <p class="dropdown-heading-mini">{{SelectedUser.FirstName}}</p>
          <div class="inner-content">
            <a class="dropdown-item">Profile</a>
            <a (click)="logout()" class="dropdown-item">Logout</a>
          </div>
        </div>
      </li>
      <li ngbDropdown class="nav-item dropdown pull-right">
        <a class="dropdown-toggle selected-brand" ngbDropdownToggle><img src="/assets/images/brand-logo.png" class="img-circle wh20 m-1" />{{SelectedBrand.BrandName}}</a>
        <div class="dropdown-menu dropaero-menu " ngbDropdownMenu>
          <p class="dropdown-heading-mini">CURRENT BRAND</p>
          <a class="dropdown-item selected-inner-text">
            <img style="margin-left: 0 !important;" src="/assets/images/brand-logo.png" class="img-circle wh20 m-1" />{{SelectedBrand.BrandName}}
            <img class="edit-brand-logo" src="/assets/images/edit-brand.svg" />
          </a>
          <div class="inner-content">
            <p class="dropdown-heading-mini">SWITCH TO</p>
            <a class="dropdown-item" (click)="changeBrand(brand)" *ngFor="let brand of Brands">{{brand.BrandName}}</a>
          </div>
          <a class="dropdown-item selected-inner-text selected-inner-bottom">
            <img style="margin-left: 0 !important;" src="/assets/images/create-brand-icon.svg" /> Create New Brand
          </a>
        </div>
      </li>
    </ul>
  </div>

  <nav class="navbar mobile-nav">
    <div class="navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="['/restaurant']">Dashboard</a>
        </li>
        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            Reports
            +
          </a>
          <div ngbDropdownMenu class="dropdown-menu main-menu">
            <div class="inner-content">
              <a class="dropdown-item " [routerLink]="[ '/restaurant/report/sales']">  Sales Reports</a>
              <!-- <a class="dropdown-item">  Inventory Reports</a> -->
              <a class="dropdown-item" [routerLink]="[ '/restaurant/report/generate']">
                <img style="margin-left: 0 !important;" src="/assets/images/icons/generated-reports.svg" /> Generated Reports
              </a>
            </div>
          </div>
        </li>
        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            Actions
          </a>
          <div ngbDropdownMenu class="dropdown-menu main-menu">
            <div class="inner-content">
              <a class="dropdown-item"> <img class="mr-8" src="/assets/images/icons/refunds.svg" alt="Refunds"> Refunds</a>
              <a class="dropdown-item"> <img class="mr-8" src="/assets/images/icons/discounts.svg" alt="Discounts"> Discounts</a>

            </div>
          </div>
        </li>
        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            Products
          </a>
          <div ngbDropdownMenu class="dropdown-menu main-menu">
            <div class="inner-content">
              <a class="dropdown-item" [routerLink]="['/restaurant/category']"> <img class="mr-8" src="/assets/images/icons/categories.svg" alt="Categories"> Categories</a>
              <a class="dropdown-item" [routerLink]="['/restaurant/item']"> <img class="mr-8" src="/assets/images/icons/items.svg" alt="Items"> Items</a>
              <a class="dropdown-item" [routerLink]="['/restaurant/modifier']"> <img class="mr-8" src="/assets/images/icons/modifiers.svg" alt="Modifiers"> Modifiers</a>

            </div>
          </div>
        </li>
        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            Inventory
          </a>
          <div ngbDropdownMenu class="dropdown-menu main-menu">
            <div class="inner-content">
              <a class="dropdown-item " [routerLink]="['/restaurant/supplier']">  Inventory Setup</a>

              <a class="dropdown-item selected-inner-text selected-inner-bottom " [routerLink]="['/restaurant/inventorysetup']">
                <img style="margin-left: 0 !important; " src="/assets/images/icons/generated-reports.svg " /> Inventory Actions
              </a>
            </div>
          </div>
        </li>
        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            Products
          </a>
          <div ngbDropdownMenu class="dropdown-menu main-menu">
            <div class="inner-content">
              <a class="dropdown-item" [routerLink]="['/restaurant/category']"> <img class="mr-8" src="/assets/images/icons/categories.svg" alt="Categories"> Categories</a>
              <a class="dropdown-item" [routerLink]="['/restaurant/item']"> <img class="mr-8" src="/assets/images/icons/items.svg" alt="Items"> Items</a>
              <a class="dropdown-item" [routerLink]="['/restaurant/modifier']"> <img class="mr-8" src="/assets/images/icons/modifiers.svg" alt="Modifiers"> Modifiers</a>

            </div>
          </div>
        </li>

        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            Setup
          </a>
          <div ngbDropdownMenu class="dropdown-menu main-menu">
            <div class="inner-content">
              <a class="dropdown-item" [routerLink]="['/restaurant/branddevices']"> <img class=" mr-8 " src="/assets/images/icons/devices.svg " alt="Menu "> Devices</a>
              <a class="dropdown-item " [routerLink]="['/restaurant/userroles']">  <img class="mr-8 " src="/assets/images/icons/users.svg " alt="Menu "> Users</a>
              <a class="dropdown-item " [routerLink]="['/restaurant/location']"> <img class="mr-8 " src="/assets/images/icons/locations.svg " alt="Menu "> Locations</a>
              <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/order-types.svg " alt="Menu "> Order types</a>
              <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/payment-types.svg " alt="Menu "> Payment types</a>
              <a class="dropdown-item " (click)="openReceipt()"> <img class="mr-8 " src="/assets/images/icons/receipts.svg " alt="Menu "> Receipts</a>
              <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/discounts.svg " alt="Menu "> Taxes</a>

              <a class="dropdown-item selected-inner-text selected-inner-bottom " [routerLink]="['/restaurant/marketplace']">
                <img style="margin-left: 0 !important; " src="/assets/images/icons/integrations.svg " /> Marketplace
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </nav>

</div>
<div class="overlay " (click)="toggled=false; " [class.active]="toggled "></div>
<!-- /Sidebar -->
<header class="fixed-top " id="topnav ">
  <div class="header-fixed container ">
    <img class="logo " src="/assets/images/logo-full.svg " style="width:111px" alt="Marn " />
    <ul class="nav nav-inline ">
      <li ngbDropdown class="nav-item dropdown hide-mobile ">
        <a class="dropdown-toggle selected-brand " ngbDropdownToggle><img src="/assets/images/brand-logo.png " class="img-circle wh20 m-1 " />{{SelectedBrand.BrandName}}</a>
        <div class="dropdown-menu dropaero-menu " ngbDropdownMenu>
          <p class="dropdown-heading-mini ">CURRENT BRAND</p>
          <a class="dropdown-item selected-inner-text ">
            <img style="margin-left: 0 !important; " src="/assets/images/brand-logo.png " class="img-circle wh20 m-1 " />{{SelectedBrand.BrandName}}
            <img class="edit-brand-logo " src="/assets/images/edit-brand.svg " />
          </a>
          <div class="inner-content ">
            <p class="dropdown-heading-mini ">SWITCH TO</p>
            <a class="dropdown-item " (click)="changeBrand(brand) " *ngFor="let brand of Brands ">{{brand.BrandName}}</a>
          </div>
          <a class="dropdown-item selected-inner-text selected-inner-bottom ">
            <img style="margin-left: 0 !important; " src="/assets/images/create-brand-icon.svg " /> Create New Brand
          </a>
        </div>
      </li>
      <li ngbDropdown class="nav-item dropdown ">
        <a ngbDropdownToggle class="dropdown-toggle selected-location " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false ">{{SelectedLocation.Name}}</a>
        <div ngbDropdownMenu class="dropdown-menu dropaero-menu ">
          <p class="dropdown-heading-mini ">CURRENT LOCATION</p>
          <a class="dropdown-item selected-inner-text ">{{SelectedLocation.Name}}</a>
          <div class="inner-content ">
            <p class="dropdown-heading-mini ">SWITCH TO</p>
            <a class="dropdown-item " (click)="changeLocation(location) " *ngFor="let location of Locations ">{{location.Name}}</a>
          </div>
        </div>
      </li>
      <li ngbDropdown class="nav-item dropdown nav-quick-action hide-mobile ">
        <a ngbDropdownToggle class=" dropdown-toggle profile " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false ">
          <img [src]="SelectedUser.UserImage " class="img-circle wh34 " *ngIf="!ImageError " (error)="errorHandler($event) " />
          <span class="img-error " *ngIf="ImageError "> {{SelectedUser.FirstName.substring(0,2)}} </span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropaero-profile ">
          <p class="dropdown-heading-mini ">{{SelectedUser.FirstName}}</p>
          <a class="dropdown-item ">Profile</a>
          <a class="dropdown-item ">Language</a>
          <div class="dropdown-divider "></div>
          <a (click)="logout() " class="dropdown-item ">Logout</a>
        </div>
      </li>
      <!--<li ngbDropdown class="nav-item dropdown ">
        <a ngbDropdownToggle class="dropdown-toggle " id="navbarDropdown " role="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">Dropdown2</a>
        <ul ngbDropdownMenu class="dropdown-menu " aria-labelledby="navbarDropdown ">
          <li><a>Action</a></li>
          <li><a>Another action</a></li>
          <li><a>Something else here</a></li>
          <li ngbDropdown class="dropdown-submenu ">
            <a ngbDropdownToggle class="dropdown-toggle " id="navbarDropdown " role="button " data-toggle="dropdown " aria-haspopup="true " aria-expanded="false ">Dropdown3</a>
            <ul ngbDropdownMenu class="dropdown-menu " aria-labelledby="navbarDropdown ">
              <li><a>Action</a></li>
              <li><a>Another action</a></li>
              <li><a>Something else here</a></li>
            </ul>
          </li>
        </ul>
      </li>-->
      <li class="nav-item dropdown show-mobile ">
        <a (click)="toggleMobileMenu() ">
          <img class="ml-4 " src="/assets/images/icons/mobile-menu.svg " />
        </a>
      </li>
    </ul>
  </div>
  <nav class="navbar navbar-expand-md hide-mobile ">
    <div class="container ">
      <button class="navbar-toggler " type="button " data-toggle="collapse " data-target="#navbarCollapse " aria-controls="navbarCollapse " aria-expanded="false " aria-label="Toggle navigation ">
        <img src="/assets/images/menu-mobile.svg " alt="Menu ">
      </button>
      <div class="navbar-collapse collapse " id="navbarCollapse ">
        <ul class="navbar-nav mr-auto ">
          <li class="nav-item active ">
            <a class="nav-link " [routerLink]="[ '/restaurant'] ">Dashboard</a>
          </li>
          <li ngbDropdown class="nav-item dropdown ">
            <a ngbDropdownToggle class="nav-link dropdown-toggle " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false ">
              Reports
            </a>
            <div ngbDropdownMenu class="dropdown-menu main-menu ">
              <p class="dropdown-heading-mini ">Reports</p>
              <div class="inner-content ">
                <a class="dropdown-item " [routerLink]="[ '/restaurant/report/sales']">  Sales Reports</a>
                <!-- <a class="dropdown-item ">  Inventory Reports</a> -->

                <a class="dropdown-item selected-inner-text selected-inner-bottom " [routerLink]="[ '/restaurant/report/generate']">
                  <img style="margin-left: 0 !important; " src="/assets/images/icons/generated-reports.svg " /> Generated Reports
                </a>
              </div>
            </div>
          </li>
          <li ngbDropdown class="nav-item dropdown ">
            <a ngbDropdownToggle class="nav-link dropdown-toggle " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false ">
              Actions
            </a>
            <div ngbDropdownMenu class="dropdown-menu main-menu ">
              <p class="dropdown-heading-mini ">Actions</p>
              <div class="inner-content ">
                <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/refunds.svg " alt="Refunds "> Refunds</a>
                <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/discounts.svg " alt="Discounts "> Discounts</a>

              </div>
            </div>
          </li>
          <li ngbDropdown class="nav-item dropdown ">
            <a ngbDropdownToggle class="nav-link dropdown-toggle " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false ">
              Products
            </a>
            <div ngbDropdownMenu class="dropdown-menu main-menu ">
              <p class="dropdown-heading-mini ">Products</p>
              <div class="inner-content ">
                <a class="dropdown-item " [routerLink]="['/restaurant/category']"> <img class="mr-8 " src="/assets/images/icons/categories.svg " alt="Categories "> Categories</a>
                <a class="dropdown-item " [routerLink]="['/restaurant/item']"> <img class="mr-8 " src="/assets/images/icons/items.svg " alt="Items "> Items</a>
                <a class="dropdown-item " [routerLink]="['/restaurant/modifier']"> <img class="mr-8 " src="/assets/images/icons/modifiers.svg " alt="Modifiers "> Modifiers</a>

              </div>
            </div>
          </li>
          <li ngbDropdown class="nav-item dropdown">
            <a ngbDropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              Inventory
            </a>
            <div ngbDropdownMenu class="dropdown-menu main-menu">
              <div class="inner-content">
                <a class="dropdown-item " [routerLink]="['/restaurant/inventorysetup']">  Inventory Setup</a>

                <a class="dropdown-item selected-inner-text selected-inner-bottom " [routerLink]="['/restaurant/inventoryaction']">
                  <img style="margin-left: 0 !important; " src="/assets/images/icons/generated-reports.svg " /> Inventory Actions
                </a>
              </div>
            </div>
          </li>
          <li ngbDropdown class="nav-item dropdown ">
            <a ngbDropdownToggle class="nav-link dropdown-toggle " data-toggle="dropdown " role="button " aria-haspopup="true " aria-expanded="false ">
              Setup
            </a>
            <div ngbDropdownMenu class="dropdown-menu main-menu ">
              <p class="dropdown-heading-mini ">Setup</p>
              <div class="inner-content ">
                <a class="dropdown-item " [routerLink]="['/restaurant/branddevices']"> <img class="mr-8 " src="/assets/images/icons/devices.svg " alt="Menu "> Devices</a>
                <a class="dropdown-item " [routerLink]="['/restaurant/userroles']"> <img class="mr-8 " src="/assets/images/icons/users.svg " alt="Menu "> Users</a>
                <a class="dropdown-item " [routerLink]="['/restaurant/location']"> <img class="mr-8 " src="/assets/images/icons/locations.svg " alt="Menu "> Locations</a>
                <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/order-types.svg " alt="Menu "> Order types</a>
                <a class="dropdown-item "> <img class="mr-8 " src="/assets/images/icons/payment-types.svg " alt="Menu "> Payment types</a>
                <a class="dropdown-item " (click)="openReceipt()"> <img class="mr-8 " src="/assets/images/icons/receipts.svg " alt="Menu "> Receipts</a>
                <a class="dropdown-item " [routerLink]="['/restaurant/tax/setup']"> <img class="mr-8 " src="/assets/images/icons/discounts.svg " alt="Menu "> Taxes</a>

                <a class="dropdown-item selected-inner-text selected-inner-bottom "  [routerLink]="['/restaurant/marketplace']">
                  <img style="margin-left: 0 !important; " src="/assets/images/icons/integrations.svg " /> Marketplace
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
