import { Component, OnInit } from '@angular/core';
import { ChatBotService } from '../_services/chat-bot.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit {
  constructor(private chatBotService: ChatBotService) {}

  ngOnInit() {
    //this.chatBotService.LoadChatBot();
  }
}

