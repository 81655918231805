
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Devices } from '../_models/Devices';
import { LocalStorageService } from '../_services/local-storage.service';
import { LocationService } from '../_services/location.service';
import { AlertService } from '../_services/alert.service';
import { DevicesService } from 'src/app/_services/devices.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddbranddeviceComponent } from './addbranddevice/addbranddevice.component';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { TrackEventsService } from '../_services/track-events.service';

@Component({
  selector: 'app-brand-devices',
  templateUrl: './brand-devices.component.html'
})
export class BrandDevicesComponent implements OnInit {
  data$: Observable<Devices[]>;
  oldData: Devices[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  Locations = [];
  StatusList = [];
  DeviceTypeList = [];
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: DevicesService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private trackEvents: TrackEventsService,
    private alertService: AlertService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;



    this.getBrandData();
  }

  ngOnInit() {
  }



  getBrandData() {
    this.service.headerFilter = { status: [''], deviceType: [''], locationName: [''] };
    this.service.getBrandDevices(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    this.service.allData$.subscribe(res => {
      //debugger

      if (res != null) {

        this.StatusList = [];
        this.Locations = [];
        this.DeviceTypeList = [];

        new Set(res.map(val => val.deviceType)).forEach(e => {
          this.DeviceTypeList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


        new Set(res.map(val => val.locationName)).forEach(e => {
          //debugger
          this.Locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }


    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }


  open() {
    const modalRef = this.modalService.open(AddbranddeviceComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Device");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(device) {
    //DataService.Id = device.terminalID;
    const modalRef = this.modalService.open(AddbranddeviceComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Device");
    modalRef.componentInstance.SelectedID = device.terminalID;
    modalRef.componentInstance.SelectedName = device.terminalName;
    modalRef.result.then((result) => {

    }, (reason) => {
      this.getBrandData();
    });
  }


  Deactive(id, rowVersion) {
    this.service.unlink(parseInt(id), rowVersion).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Device has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }

  Unlink(device) {
    this.service.getById(device.terminalID).subscribe((res: any) => {

      res.isUsed = false;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Device has been Unlinked");
        this.getBrandData();
      }, error => {
        this.alertService.error(error);
      });


    });
  }
  SetStatus(device) {
    this.service.getById(device.terminalID).subscribe((res: any) => {
      res.statusID = device.statusID === 1 ? 2 : 1;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Device has been " + (device.statusID === 1 ? "Deactived" : "Active"));
        this.getBrandData();
      }, error => {
        this.alertService.error(error);
      });


    });
  }
  SelectedStatus = '';
  SelectedDeviceType = '';

  filters() {
    let headerFilter = {

      locationName: this.Locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      deviceType: (this.SelectedDeviceType == '') ? this.DeviceTypeList.map(function (k) { return k.name }) : [this.SelectedDeviceType],
    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }


  setSelectedResult(val) {

    if (val == 'location') {
      return this.Locations.filter(k => k.isChecked == true).length;
    }


  }
}
