import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  constructor(private http: HttpClient) {
  }

 
  getMarketplaces(brandId: number) {
    return this.http.get(`api/marketplace/all/marketplaces/${brandId}`);
  }


  getIntegrationPartner(brandId: number, partnerId:number) {
    return this.http.get(`api/marketplace/brand/${brandId}/partner/${partnerId}`);
  }
  
  getMsegat(brandId: number, partnerId:number) {
    return this.http.get(`api/msegat/brand/${brandId}/partner/${partnerId}`);
  }

  getVOM(brandId: number, partnerId: number) {
    return this.http.get(`api/vom/brand/${brandId}/partner/${partnerId}`);
  }



  insert(data) {
    return this.http.post(`api/msegat`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/msegat`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  Uninstall(id) {
    return  this.http.patch(`api/msegat/uninstall/${id}`, null);
 
   }

  
}
