export class PurchaseOrder {
  poid: number;
  poCode: string;
  referenceNo: string;
  supplierID: number;
  supplierName: string;
  locationID: number;
  locationName: string;
  expectedDate: string;
  receivedDate: string;
  notes: string;
  city: string;
  taxAmount: string;
  grandTotal: number;
  subTotal: number;
  date: string;
  status: string;
  brandID: number;
  statusID: number;
  selected: boolean;
}

export class poDetails {
  supplyID: number;
  type: string;
  name: string;
  alternateName: string;
  quantity: number;
  minStockLevel: number;
  approvedQty: number;
  receivedQty: number;
  cost: number;
  totalCost: number;
  unitID: number;
  statusID: number;
  conversionRate: number;
  conversionUnit: string;
  conversionUnitID: number;
  itemImage: string;
  unit: string;
}

export class taxes {
  poTaxID: number;
  poTax: string;
  amount: number;
  percentage: number;
  type: number;
}


export class PurchaseReturn {
  poid: number;
  poCode: string;
  referenceNo: string;
  supplierID: number;
  supplierName: string;
  locationID: number;
  locationName: string;
  expectedDate: string;
  receivedDate: string;
  notes: string;
  city: string;
  taxAmount: string;
  grandTotal: number;
  subTotal: number;
  date: string;
  status: string;
  brandID: number;
  statusID: number;
  selected: boolean;
  returnedby: string;
}
export class InvLogs {
  createdBy: string;
  createdOn: string;
  approvedBy: string;
  approvedDate: string;
  receivedBy: string;
  receivedDate: string;
}
