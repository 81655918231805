<form [formGroup]="transferForm" class="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'Generate Transfer' | translate }} {{f.tsCode.value}} </h4>

        <button [disabled]="loading" class="btn btn-primary btn-sm cutom-button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ButtonText | translate}}
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-6 mt-5">
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="name">{{'Reference No' | translate }}</label>
                            <input type="text" class="form-control" id=name placeholder="" formControlName="referenceNo">

                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="License">{{'From' | translate }}</label>
                            <select class="custom-select form-control" formControlName="fromLocationID">

                            <option [ngValue]="option.locationID" [disabled]=option.maped selected="option.locationID == locationID" *ngFor="let option of Locations">
                                {{option.name}}
                            </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12 col-sm-12">
                        <label for="License">{{'To' | translate }}</label>
                        <select class="custom-select form-control" formControlName="toLocationID">

                          <option [ngValue]="option.locationID" [disabled]=option.maped selected="option.locationID == locationID" *ngFor="let option of Locations">
                            {{option.name}}
                          </option>
                        </select>
                        <div *ngIf="f.toLocationID.value === f.fromLocationID.value" class="alert alert-danger">
                          {{'From and To location can not be same' | translate }}.
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="expDate">{{'Expected Date' | translate }}</label>
                            <ngbd-datepicker-adapter></ngbd-datepicker-adapter>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-sm-12">
                          <label for="Notes">{{'Notes' | translate }}</label>
                            <textarea class="form-control mh-80" formControlName="notes"></textarea>

                        </div>
                    </div>

                    <div class="po-box form-group p-3">
                      <label for="Conversion">{{'Item Name' | translate }}</label>
                        <div class="input-group form-group">
                            <div class="input-group-append w-100 row">
                                <ng-select [items]="Items" class="form-control w-75 po-box-autocomplete border-right-radius-unset " placeholder="{{'Click here to select Item' | translate }}" bindLabel="name" bindValue="supplyID" (change)="selectedInvItem($event)" [(ngModel)]="tsDetails.supplyID" [ngModelOptions]="{standalone: true}" [searchFn]="SearchItem">
                                </ng-select>
                                <input type="number" class="input-group-text w-25 quantity" id=quantity value="1" [(ngModel)]="tsDetails.quantity" [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12">
                          <label for="add item" class="additem-po" (click)="addRow(tsDetails)">+ {{'Add Item' | translate }}</label>

                        </div>
                    </div>
                    <div class="po-box form-group">
                        <table class="table m-0">
                            <thead class="thead-light border-top-radius-unset">
                                <tr>
                                  <th scope="col" width="60%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                                  <th scope="col" width="30%">{{'Qty' | translate }}</th>
                                    <th scope="col" width="10%" class="border-radius-topright-10">{{'Action' | translate }} </th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom-radius-unset">
                                <tr *ngFor="let item of arrItems">
                                    <td scope="row" class="font-weight-bold">{{item.name}}</td>
                                    <td>{{item.quantity}} {{item.unit}}</td>
                                    <td>
                                        <a><img src="/assets/images/delete-icon.png" (click)="deleteRow(item)" /></a>
                                    </td>
                                </tr>
                                <tr *ngIf="arrItems.length == 0">
                                    <td colspan="3">
                                        <div class="row p-1">
                                            <div class="col-12 text-center">
                                              <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-3"></div>
            </div>
        </div>
    </div>

</form>
