
<div class="modal-header">
  <h6 class="modal-title fw-bold">{{SelectedRight.formName | translate}} </h6>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container h-75">
    <div class="row">
      <div class="col-lg-12">
        <div class="row align-items-center">
          <div class="col-8">
            <label class="main-label fw-bold">
              {{'Grant all permissions of' | translate }} {{SelectedRight.formName  | translate }}
            </label>
          </div>
          <div class="col-4 text-right">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" name="parentRight" id="parentRight" [(ngModel)]="updateRights.access" (change)="checkAll()">
              <label class="form-check-label" for="parentRight"></label>
            </div>
          </div>
        </div>

        <br />
        <section class="container overflow-scroll h-100">
          <div class="d-flex flex-row gap-2 pt-3" *ngFor="let child of updateRights.child" [ngStyle]="{'height': !(child.description && child.description !=='')? '50px':'auto'}" >
            <div class="flex-column">
              <input type="checkbox" [(ngModel)]="child.access" (ngModelChange)="enableAccess()" class="form-check-input">
            </div>
            <div class="flex-column">
              <label class="main-label fw-bold"> {{child.formName | translate}}</label>
              <ng-container *ngIf="child.description && child.description !==''">
                <br />
                <div class="px-1 secondary-label">{{child.description | translate}}</div>
              </ng-container>
            </div>
          </div>

        </section>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="close()">{{'Cancel' | translate}}</button>
  <button [disabled]="loading" style="float: right;" class="btn-primary cutom-button" (click)="onSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{SaveButtonText | translate}}
  </button>
</div>

