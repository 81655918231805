import { partnerItems } from "./partnerItems";

export class LocationItem {
  itemID: number;
  itemImage: string;
  category: string;
  alternateCategoryName: string;
  subCategory: string;
  alternateSubCategoryName: string;
  name: string;
  nameOnReceipt: string;
  originalPrice: number;
  fixedPrice: boolean;
  selected: boolean;
  price: any;
  statusID: number;
  barcode: string;
  locationName: string;
  partnerStatus: boolean;
  partnerStatusID: number;
  partnerLocationItems: partnerItems[];
}
