<div class="modal-header">
  <h4 class="modal-title-lg">
    {{ 'Refund' | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form">
  <div class="container preview-order">
    <div class="row">
      <div class="col-lg-12">
        <div class="row d-flex">
          <div class="col-1">
            <input type="checkbox" [(ngModel)]="allowPost" />
          </div>
          <div class="col-9">
            <label class="item-title"> {{'Select all items' | translate}} </label>
          </div>
          <div class="col-2">
            <div class="move-to-end">
              <label class="item-price">{{'Amount' | translate}}    </label>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-center order-items line-seprator">
          <div class="col-1">
            <input type="checkbox" [(ngModel)]="allowPost" />
          </div>
          <div class="col-2">
            <div class="item-image bg-white rounded-3"> </div>
          </div>
          <div class="col-5 px-0">
            <label class="item-title text-truncate">{{'Total'| translate }}</label>
          </div>
          <div class="col-4">
            <label class="item-price move-to-end">{{currentOrder?.order.grandTotal | number: '1.2-2'}}    </label>
          </div>
          <!--<div class="row d-flex">
            <select class="form-control custom-select-2 bg-white" [(ngModel)]="currentOrder?.item.refundReasonID">
              <option [ngValue]="option.id"
                      selected="option.id === id" *ngFor="let option of ReasonType">
                {{option.name | translate}}
              </option>
            </select>
          </div>-->
        </div>
        <hr />
        <div class="row d-flex align-items-center">
          <div class="col-lg-5">
            <label class="modifier-title">{{(allowPost)? 1:0}}&nbsp;{{'item selected' | translate }}</label>
          </div>
          <div class="col-lg-7">
            <button [disabled]="loading" class="btn btn-danger btn-uninstall float-end" (click)="onSubmit()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>   {{'Refund'  | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

