import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { Ordertype } from '../../_models/ordertype';
import { AlertService } from '../../_services/alert.service';
import { ExcelService } from '../../_services/excel.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { OrdertypeService } from '../../_services/ordertype.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { AddOrderTypeComponent } from './add-order-type/add-order-type.component';

@Component({
  selector: 'app-order-type-list',
  templateUrl: './order-type-list.component.html',
  styleUrls: ['./order-type-list.component.css']
})
export class OrderTypeListComponent implements OnInit {
  data$: Observable<Ordertype[]>;
  submit: boolean;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  excelData = [];
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private modalService: NgbModal,
    public service: OrdertypeService,
    private local: LocalStorageService,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private excelService: ExcelService  ) {
    this.loading$ = service.loading$;
    this.submit = false;
    this.getData();
  }

  ngOnInit() {
  }
  getData() {
    this.service.headerFilter = {status:['']};
    var brandID = this.local.getSelectedBrand().BrandID;
    this.service.getAll(brandID);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];

      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


      }
    });
  }
  open() {
    const modalRef = this.modalService.open(AddOrderTypeComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Order Type");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }
 
  Edit(orderType) {
    const modalRef = this.modalService.open(AddOrderTypeComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Order Type");
    modalRef.componentInstance.SelectedID = orderType.orderTypeID;
    modalRef.componentInstance.SelectedName = orderType.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });

  }
  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }
  public chkSelection($event, orderType) {
    this.service.orderTypes.forEach((item, index) => {
      if (item.orderTypeID == orderType.orderTypeID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Order-Types', type);
  }
  private getExportData() {
    let selected: any[];

    selected = [];
    this.service.orderTypes.forEach(d => {
      if (d.selected) {
        selected.push({
          Name: d.name,
          Status: (d.statusID == 1 ? 'Active' : 'Inactive')
        });
      }
    });
    if (selected.length == 0) {
      this.service.orderTypes.forEach(d => {
        selected.push({
          Name: d.name,
          Status: (d.statusID == 1 ? 'Active' : 'Inactive')
        });
      });
    }
    return selected;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription !== undefined)
      this.locationSubscription.unsubscribe();
  }
  SelectedStatus = '';

  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]

    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}
