import { Component, Input, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerV2Component } from '../../date-picker-v2/date-picker-v2.component';
import { NgbdDatepickerAdapter } from '../../datepicker/datepicker-adapter';
import { AlertService } from '../../_services/alert.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { OrderService } from '../../_services/order.service';

@Component({
  selector: 'app-add-invoice-period',
  templateUrl: './add-invoice-period.component.html',
  styleUrl: './add-invoice-period.component.css'
})
export class AddInvoicePeriodComponent {

  @Input() SelectedOrder = null;
  submitted = false;
  periodForm: UntypedFormGroup;
  loading = false;
  orderData: any;
  @ViewChild('startDate') startDate: DatePickerV2Component;
  @ViewChild('endDate') endDate: DatePickerV2Component;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private orderService: OrderService
  ) {
    this.createForm();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.setSelectedPeriod();
  }

  private createForm() {
    this.periodForm = this.formBuilder.group({
      orderID: [0, Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: ['09:00', Validators.required],
      endTime: ['09:00', Validators.required],
      orderPeriod: [false]
    });
  }


  get f() { return this.periodForm.controls; }

  setSelectedPeriod() {
    if (this.SelectedOrder) {
      this.orderService.getById(this.SelectedOrder.orderID).subscribe(res => {
        this.orderData = res;
        this.f.orderID.setValue(this.SelectedOrder.orderID);
        if (this.orderData?.order.orderPeriod.startDate) {
          this.startDate.setDate(this.orderData?.order.orderPeriod.startDate);
          this.f.startDate.setValue(new Date(this.startDate.dateModel.year + "/" + this.startDate.dateModel.month + "/" + this.startDate.dateModel.day));
        }
        if (this.orderData?.order.orderPeriod.endDate) {
          this.endDate.setDate(this.orderData?.order.orderPeriod.endDate);
          this.f.endDate.setValue(new Date(this.endDate.dateModel.year + "/" + this.endDate.dateModel.month + "/" + this.endDate.dateModel.day));
        }
        if (this.orderData?.order.orderPeriod.startTime)
          this.f.startTime.setValue(this.orderData?.order.orderPeriod.startTime);
        if (this.orderData?.order.orderPeriod.endTime)
          this.f.endTime.setValue(this.orderData?.order.orderPeriod.endTime);
        if (this.orderData?.order.orderPeriod.orderPeriod)
          this.f.orderPeriod.setValue(this.orderData?.order.orderPeriod.orderPeriod);

      });
    }
  }

  close() {
    this.activeModal.dismiss();
  }



  onSubmit() {
    this.periodForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    if (new Date(this.endDate.dateModel.year + "/" + this.endDate.dateModel.month + "/" + this.endDate.dateModel.day) < new Date(this.startDate.dateModel.year + "/" + this.startDate.dateModel.month + "/" + this.startDate.dateModel.day)) {
      this.alertService.error("Incorrect Period");
      return;
    }

    if (this.startDate.model)
      this.f.startDate.setValue((this.startDate.dateModel.year + "/" + this.startDate.dateModel.month + "/" + this.startDate.dateModel.day).toString());

    if (this.endDate.model)
      this.f.endDate.setValue((this.endDate.dateModel.year + "/" + this.endDate.dateModel.month + "/" + this.endDate.dateModel.day).toString());

    if (this.periodForm.invalid) { return; }
    this.loading = true;



    this.orderService.updatePeriod(this.periodForm.value).subscribe(data => {
      this.alertService.success("Period has been updated");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });


  }
}
