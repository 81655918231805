import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const timeRangeValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const startTime = formGroup.get('startTime').value;
  const endTime = formGroup.get('endTime').value;

  if (!startTime || !endTime) {
    return null; // If either start time or end time is not set, don't validate
  }

  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  const startDateTime = new Date();
  startDateTime.setHours(startHour, startMinute, 0);

  const endDateTime = new Date();
  endDateTime.setHours(endHour, endMinute, 0);

  return startDateTime >= endDateTime ? { timeRangeInvalid: true } : null;
};
