import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reasonlist',
  templateUrl: './reasonlist.component.html',
  styleUrls: ['./reasonlist.component.css']
})
export class ReasonlistComponent implements OnInit {
  active = "refund";
  constructor() { }

  ngOnInit() {
  }

}
