<main class="views container">
  <div class="page">




    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Inventory Action Logs' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">

            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">

              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,data$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',data$)">Excel</button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>




    <div class="page-body">

      <div class="preview-filter-data" dir="ltr">{{selectedReport.dateRange}}</div>
      <div class="preview-filter-data" dir="ltr">{{selectedReport.locations}}</div>

      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive hrzScroll">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th sortable="Code" (sort)="onSort($event)">{{'Ref' | translate }} # <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th>
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" ngbDropdownToggle>
                      <span>{{'Type' | translate}}</span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                         class="dropdown-header-filter-body">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllType" (ngModelChange)="selectAllFilters($event,type)">
                        <label class="form-check-label font-blue" (click)="selectAllFilters($event,type)">
                          {{'All Type' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let grp of type">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input"
                                 [(ngModel)]="grp.isChecked" (ngModelChange)="filterRecords()">
                          <label class="form-check-label" (click)="filterRecords()">
                            {{grp.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" ngbDropdownToggle>
                      <span>{{'Location' | translate}}</span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body"
                         class="dropdown-header-filter-body">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllLocation" (ngModelChange)="selectAllFilters($event,location)">
                        <label class="form-check-label font-blue" (click)="selectAllFilters($event,location)">
                          {{'All Location' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let grp of location">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input"
                                 [(ngModel)]="grp.isChecked" (ngModelChange)="filterRecords()">
                          <label class="form-check-label" (click)="filterRecords()">
                            {{grp.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>


                <th sortable="Name" (sort)="onSort($event)">{{'Item' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="ReceivedQty" (sort)="onSort($event)">{{'Quantity' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="SKU" (sort)="onSort($event)">{{'Sku' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Cost" (sort)="onSort($event)">{{'Unit Cost' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="TotalCost" (sort)="onSort($event)">{{'Total Cost' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Type" (sort)="onSort($event)">{{'Created By' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="Type" (sort)="onSort($event)">{{'Created At' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async ">
                <td style="color: #425CC7 !important;">  {{item.Code}} </td>
                <td> {{item.Type | translate }}  </td>
                <td>  {{item.Location}} </td>
                <td> {{item.Name}} </td>
                <td> {{item.ReceivedQty}} </td>
                <td> {{item.SKU}} </td>
                <td> {{item.Cost  | number:'1.2-2'}} </td>
                <td> {{item.TotalCost  | number:'1.2-2' }} </td>
                <td> {{item.CreatedBy}} </td>
                <td> {{item.Date | date}} - {{item.Time | timeFormat}} </td>                
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{(total$ | async)}}</strong>
            {{'results' | translate }}
          </div>
        </div>
      </section>

    </div>
  </div>
</main>
