
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { TaxService } from 'src/app/_services/tax.service';
import { LocationService } from 'src/app/_services/location.service';
import { ItemService } from '../../../../_services/item.service';
import { NgSelectComponent } from '@ng-select/ng-select';




@Component({
  selector: 'app-managetaxes',
  templateUrl: './managetaxes.component.html',
  styleUrls: ['./managetaxes.component.css']
})
export class ManagetaxesComponent implements OnInit {
  @Input() name;
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  taxsetupForm: UntypedFormGroup;
  loading = false;
  loadingTax = false;
  ButtonText = "Save";





  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private taxService: TaxService,
    private location: LocationService,
    private itemService: ItemService
  ) {
    this.createForm();
    parseInt(this.localStorageService.getSelectedBrand().BrandID)
  }

  ngOnInit() {
    this.setSelectedTax();
  }

  ngAfterViewInit() {

  }

  private createForm() {
    this.taxsetupForm = this.formBuilder.group({
      includedItemPrice: ['', Validators.required]
    });
  }


  get f() { return this.taxsetupForm.controls; }

  itemsettings = [
    {
      name: "Include Taxes",
      value: "1"
    },
    {
      name: "Exclude Taxes",
      value:"0"
    }

  ]
  setSelectedTax() {
    
      this.ButtonText = "Save";
      this.loadingTax = true;

    this.taxService.getItemPriceSetting(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res:any) => {
        console.log(res);
        //Set Forms
        // this.f.includedItemPrice.setValue(res.value === 1 ? true : false);
      this.f.includedItemPrice.setValue(res.value);
        this.loadingTax = false;
      });
    
  }





  close() {
    this.activeModal.dismiss();
  }


  onSubmit() {
    this.taxsetupForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();
   
    if (this.taxsetupForm.invalid) { return; }
   

   
    this.loading = true;

   

    this.taxService.insertTaxSetting(parseInt(this.localStorageService.getSelectedBrand().BrandID), this.f.includedItemPrice.value).subscribe(data => {
      this.alertService.success("Price Setting been Updated");
      this.loading = false;
      this.SelectedID = 0;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });
  }
}

