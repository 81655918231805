import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { Item } from '../_models/Item';

interface SearchItemResult {
  data: Item[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Item[], column: SortColumn, direction: string): Item[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Item, term: string) {
  return data.name.toLowerCase().includes(term.toLowerCase())
    || data.barcode.toLowerCase().includes(term.toLowerCase())
    || data.categoryName.toLowerCase().includes(term.toLowerCase())
    || data.altCategoryName.toLowerCase().includes(term.toLowerCase())
    || data.subCategoryName.toLowerCase().includes(term.toLowerCase())
    || data.altSubCategoryName.toLowerCase().includes(term.toLowerCase())
    || data.price.toString().includes(term.toLowerCase())
    || data.nameOnReceipt.toLowerCase().includes(term.toLowerCase());
}




//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[]
}


function matcheHeader(data: Item, filterValues: FilterHeaderState) {
  let status = false;
  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  if (status) {
    return data;
  }

}
//@@@ Header Filter Code End


@Injectable({
  providedIn: 'root'
})
export class ItemService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Item[]>([]);
  private _data$ = new BehaviorSubject<Item[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public items: Item[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }


  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchItemResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.items, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End


  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getItem(locationId) {

    const url = `api/item/all/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Item[]>(url).subscribe(res => {
        this.items = res;
        this._data$.next(this.items);
        this._allData$.next(this.items);


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End
      });
  }
  public getItems(brandId) {

    const url = `api/item/all/${brandId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Item[]>(url).subscribe(res => {
        this.items = res;
        this._data$.next(this.items);
        this._allData$.next(this.items);

        // @@@
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();


      });
  }
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchItemResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.items, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }



  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }



  getById(id, brandId) {
    return this.http.get<any[]>(`api/item/${id}/brand/${brandId}`);
  }

  insert(data) {
    return this.http.post(`api/item`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/item`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  deactive(id, version) {
    return this.http.patch(`api/item/deactive/${id}/${version}`, null);
  }
  SetStatus(id, statusId, version) {
    return this.http.patch(`api/item/SetStatus/${id}/${statusId}/${version}`, null);
  }
  delete(id, version) {
    return this.http.delete<any[]>(`api/item/${id}/${version}`);
  }

  getGroupModifiers(id) {
    return this.http.get<any[]>(`api/common/ddl/groupmodifiers/${id}`);
  }

  getSuppliers(id) {
    return this.http.get<any[]>(`api/inventory/supplier/all/${id}`);
  }
  public getGroup(brandId) {
    return this.http.get<any[]>(`api/inventory/group/all/${brandId}`);
  }
  getDisplayOrder(id, subCategoryID) {
    return this.http.get<any[]>(`api/common/displayorder/item/${id}/${subCategoryID}`);
  }
  getRecipes(id) {
    return this.http.get<any[]>(`api/inventory/recipe/all/${id}`);
  }
  generateSKU(id) {
    return this.http.get(`api/common/inventory/sku/${id}`);
  }
  getAllItemsddl(id) {
    return this.http.get(`api/item/all/${id}`);

  }
  uploadItems(items) {
    return this.http.post<any>(`api/Item/UpliftItems`, items);
  }
  uploadItemsV2(items) {
    return this.http.post<any>(`api/Item/UpliftItems_V2`, items);
  }
  VerifyItems(items) {
    return this.http.post<any>(`api/Item/VerifyItems`, items);
  }
  
}

