import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { Devices } from '../_models/Devices';

interface SearchDevicesResult {
  data: Devices[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Devices[], column: SortColumn, direction: string): Devices[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Devices, term: string) {

  return data.terminalName.toLowerCase().includes(term.toLowerCase())
    || data.code.toLowerCase().includes(term.toLowerCase())
    || data.deviceType.toLowerCase().includes(term.toLowerCase())
    || data.refCode.toLowerCase().includes(term.toLowerCase());
  //|| pipe.transform(location.email).includes(term)


}


//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[],
  locationName: string[],
  deviceType: string[],
}


function matcheHeader(data: Devices, filterValues: FilterHeaderState) {
  let status = false;
  let city = false;
  let devicetype = false;

  if (filterValues.deviceType !== null && filterValues.deviceType.length > 0) {
    for (var i = 0, len = filterValues.deviceType.length; i < len; i++) {
      if (data.deviceType.toString().includes(filterValues.deviceType[i].toString())) {
        devicetype = true;
      }
    }
  }
  else {
    devicetype = true;
  }


  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }

  // City
  if (filterValues.locationName !== null && filterValues.locationName.length > 0) {
    for (var i = 0, len = filterValues.locationName.length; i < len; i++) {
      if (data.locationName.toLowerCase().toString().includes(filterValues.locationName[i].toLowerCase())) {
        city = true;
      }
    }
  }
  else {
    city = true;
  }

  if (city && status && devicetype) {
    return data;
  }

}
//@@@ Header Filter Code End


@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Devices[]>([]);
  private _data$ = new BehaviorSubject<Devices[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public devices: Devices[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null,
    locationName: null,
    deviceType: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchDevicesResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.devices, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {

  }


  public getDevices(locationId) {

    const url = `api/device/all/${locationId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Devices[]>(url).subscribe(res => {
        this.devices = res;
        this._data$.next(this.devices);
        this._allData$.next(this.devices);


        //this._search$.pipe(
        //  switchMap(() => this._search()),
        //  tap(() => this._loading$.next(false))
        //).subscribe(result => {
        //  this._data$.next(result.data);
        //  this._total$.next(result.total);
        //});

        //this._search$.next();


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

     //@@@ Header Filter Code End


      });
  }
  getLocationDevicesDDL(locationID) {
    return this.http.get<any[]>(`api/device/all/${locationID}`);
  }
  public getBrandDevices(brandId) {

    const url = `api/device/all/brand/${brandId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<Devices[]>(url).subscribe(res => {
        this.devices = res;
        console.log( this.devices );
        this._data$.next(this.devices);
        this._allData$.next(this.devices);


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

     //@@@ Header Filter Code End
      });
  }
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchDevicesResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.devices, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }



  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }



  getById(id) {
    return this.http.get<any[]>(`api/device/${id}`);
  }

  insert(data) {
    return this.http.post(`api/device`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/device`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  unlink(id, version) {
    return this.http.delete<any[]>(`api/device/${id}/${version}`);
  }
  checkIntegrationByBrandId(id) {
    return this.http.get<any[]>(`api/integration/CheckPartnerReference/brand/${id}`);
  }

}

