
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { AddpurchaseorderComponent } from './addpurchaseorder/addpurchaseorder.component';
import { PurchaseOrder } from 'src/app/_models/Purchaseorder';
import { PreviewpurchaseorderComponent } from './previewpurchaseorder/previewpurchaseorder.component';
import { ReceivepreviewpoComponent } from './receivepreview-po/receivepreview-po.component';
import { PrintService } from 'src/app/_services/print.service';
import { CreatePurchaseOrderComponent } from './create-purchase-order/create-purchase-order.component';
import { TrackEventsService } from '../../_services/track-events.service';


@Component({
  selector: 'app-purchaseorder',
  templateUrl: './purchaseorder.component.html',
  providers: [ExcelService]
})
export class PurchaseorderComponent implements OnInit {
  data$: Observable<PurchaseOrder[]>;
  oldData: PurchaseOrder[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "purchaseorder";
  submit: boolean;
  excelData = [];
  StatusList = [];
  SuppliersList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: PurchaseorderService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    public printService: PrintService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Purchaseorders', type);
  }
  getBrandData() {
    this.service.headerFilter = { status: [''], supplierName: [''] };
    this.service.getPurchaseorders(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


        new Set(res.map(invAlert => invAlert.supplierName)).forEach(e => {
          this.SuppliersList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  add() {
    const modalRef = this.modalService.open(CreatePurchaseOrderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Purchase Order");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(purchaseorder) {
    const modalRef = this.modalService.open(CreatePurchaseOrderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Purchase Order");
    modalRef.componentInstance.SelectedID = purchaseorder.poid;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  Print(purchaseorder) {
    this.printService.printDocument('po', purchaseorder.poid);
  }

  Preview(purchaseorder) {
    if (purchaseorder.statusID == 601) {
      const modalRef = this.modalService.open(ReceivepreviewpoComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      modalRef.componentInstance.SelectedID = purchaseorder.poid;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      });
    }
    else {
      const modalRef = this.modalService.open(PreviewpurchaseorderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      modalRef.componentInstance.SelectedID = purchaseorder.poid;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      });
    }
  }

  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, purchaseorder) {
    this.service.purchaseorders.forEach((item, index) => {
      if (item.poid == purchaseorder.poid) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }

  private getExportData() {
    let selected: PurchaseOrder[];

    selected = [];
    this.service.purchaseorders.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.purchaseorders : selected;
    return selected;
  }
  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"

    }
  }

  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      supplierName: this.SuppliersList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }

  setSelectedResult(val) {


    if (val == 'supplier') {
      return this.SuppliersList.filter(k => k.isChecked == true).length;
    }

  }
}

