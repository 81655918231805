<form [formGroup]="preturnForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'Create Purchase Return' | translate }} </h4>
    <button [disabled]="loading" class="btn-primary cutom-button">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="POID">{{'Purchase' | translate }} #</label>
              <select class="custom-select form-control" formControlName="POID" id="POID" (change)="onPurchaseChange($event)">
                <option [ngValue]="option.poid" selected="option.poid === POID"
                        *ngFor="let option of POs">
                  {{option.poCode}}
                </option>
              </select>
              <div *ngIf="f.POID.invalid && (f.POID.dirty || f.POID.touched)" class="alert alert-danger">
                <div *ngIf="f.POID.errors.required">
                  {{'Purchase # is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="supplier">{{'Supplier Name' | translate }}</label>
              <input type="text" class="form-control" id=supplier placeholder="" formControlName="supplier" [class.disabled]="true" disabled>
              <div *ngIf="f.supplier.invalid && (f.supplier.dirty || f.supplier.touched)" class="alert alert-danger">
                <div *ngIf="f.supplier.errors.required">
                  {{'Supplier is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="note">{{'Notes' | translate }}</label>
              <textarea class="form-control h-75" id=note placeholder="" formControlName="note"></textarea>
              <div *ngIf="f.note.invalid && (f.note.dirty || f.note.touched)" class="alert alert-danger">
                <div *ngIf="f.note.errors.required">
                  {{'Note is required' | translate }} .
                </div>
              </div>
            </div>
          </div>
          <div class="returnitem-box form-group">
            <div class="tile-body p-0 table-responsive ">
              <table class="table table-striped">
                <thead>
                  <tr class="table-header">
                    <th width="5%"> <input type="checkbox" (change)="bulkSelection($event)" /></th>
                    <th width="45%">{{'Item Name' | translate }} </th>
                    <th width="25%">{{'Ordered' | translate }}</th>
                    <th width="25%">{{'Qty Return' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of returnitems">
                    <tr *ngIf="!item.isReturned">
                      <td>
                        <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.selected" (change)="onReturnQtyChange(item)" />
                      </td>
                      <td>{{item.name}} </td>
                      <td>{{item.quantity}} {{item.unit}} </td>
                      <td>
                        <input type="number" [(ngModel)]="item.returnQuantity" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="" [ngClass]="{red : (item.selected === true && (item.returnQuantity > item.quantity  || item.returnQuantity === null || item.returnQuantity === '' || item.returnQuantity === undefined || item.returnQuantity <= 0))}" (ngModelChange)="onReturnQtyChange(item)" [attr.required]="item.selected">
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>

</form>
