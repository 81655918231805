
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { PrintService } from 'src/app/_services/print.service';
import { OrderService } from 'src/app/_services/order.service';
import { PreviewOrderComponent } from './preview-order/preview-order.component';
import { Order } from '../_models/Orders';
import { LocalStorageService } from '../_services/local-storage.service';
import { AlertService } from '../_services/alert.service';
import { LocationService } from '../_services/location.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { filter } from 'rxjs/operators';
import { Socket } from 'net';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { NgbdDatepickerAdapter } from 'src/app/datepicker/datepicker-adapter';
import { WebSocketConnectionService } from '../_services/web-socket-connection.service';
import { CommonService } from '../_services/common.service';
import { PermissionService } from '../_services/permission.service';
import { TrackEventsService } from '../_services/track-events.service';
import { AddInvoicePeriodComponent } from './add-invoice-period/add-invoice-period.component';
import { PdfService } from '../_services/pdf.service';
import { ZATCAService } from '../_services/zatca.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  providers: [ExcelService]
})
export class OrdersComponent implements OnInit {
  data$: Observable<Order[]>;
  oldData: Order[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  private selectedLocation;
  LocationsList = [];
  locationSubscription: Subscription;
  active = "reconciliation";
  submit: boolean;
  excelData = [];
  public selectedLocationName = 'All Locations';
  orderForm: UntypedFormGroup;
  public selectedDate = new Date();
  dtModel: string = this.setToday();
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  ws: WebSocket | null = null;
  industry = 0;
  private companyCode;
  orderTakerList = [];
  orderTypeList = [];
  orderStateList = [];
  orderStatusList = [];
  reportedToZATCAList = [];
  zatcaStatusList = [];
  paymentList = [];
  checkAllOrderTakers: boolean = false;
  checkAllOrderTypes: boolean = false;
  checkAllOrderState: boolean = false;
  checkAllOrderStatus: boolean = false;
  checkAllZatcaStatus: boolean = false;
  checkAllOrderPayments: boolean = false;
  checkZatcaCompliance: boolean = false;
  constructor(public service: OrderService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private location: LocationService,
    public printService: PrintService,
    private formBuilder: UntypedFormBuilder, private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>, public formatter: NgbDateParserFormatter,
    private socketService: WebSocketConnectionService,
    private trackEvents: TrackEventsService,
    private permissionService: PermissionService,
    private pdfService: PdfService,
    private zatcaService: ZATCAService  ) {
    this.createForm();
  }

  ngOnInit() {
    this.service.headerFilter = { orderState: [''], orderStatus: [''], zatcaStatus: [''], orderTaker: [''], orderType: [''], payment: [''] };
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLocation = this.localStorageService.getSelectedLocation().LocationID;
    this.industry = this.localStorageService.getSelectedUser().User.Industry;
    this.companyCode = this.localStorageService.getSelectedUser().CompanyDetails.CompanyCode;
    this.submit = false;
    let dateString = this.ngbCalendar.getToday().year + "-" + ('0' + this.ngbCalendar.getToday().month).slice(-2) + "-" + ('0' + this.ngbCalendar.getToday().day).slice(-2);
    this.selectedDate = new Date(dateString)
    this.checkZatcaCompliance = this.localStorageService.getSelectedBrand().ZATCACompliance;

    this.loadLocations();
    if (this.f.liveUpdate.value === true) {
      this.socketService.ConnectWebSocket(this.companyCode, this.selectedBrand);
      this.socketService.receiveData.pipe().subscribe((data) => {
        this.getBrandData();
      });
    }
  }

  get f() { return this.orderForm.controls; }

  private createForm() {
    this.orderForm = this.formBuilder.group({
      liveUpdate: true,

    });
  }
  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Orders', type);
  }
  getBrandData() {
    this.service.getOrder(this.selectedLocation, this.selectedBrand, this.selectedDate);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    this.service._allData$.subscribe(res => {
      this.orderTakerList = [];
      this.orderTypeList = [];
      this.orderStatusList = [];
      this.reportedToZATCAList = [];
      this.orderStateList = [];
      this.paymentList = [];
      if (res) {
        new Set(res.map(order => order.orderTaker)).forEach(e => {
          this.orderTakerList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(order => order.orderType)).forEach(e => {
          this.orderTypeList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(order => order.orderStatus)).forEach(e => {
          this.orderStatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(order => order.reportedToZATCA)).forEach(e => {
          this.reportedToZATCAList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });;
        new Set(res.map(order => order.orderState)).forEach(e => {
          this.orderStateList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });;
        new Set(res.map(order => order.paymentMode)).forEach(e => {
          this.paymentList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription !== undefined)
      this.locationSubscription.unsubscribe();
    this.close();
  }


  Edit(order) {
    if (this.permissionService.checkPermission(['Orders', 'Order Preview']) === false) {
      this.alertService.error("You have no permission to preview");
      return;
    }

    const modalRef = this.modalService.open(PreviewOrderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("View Order");
    modalRef.componentInstance.SelectedID = order.orderID;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  openPeriod(order) {
    const modalRef = this.modalService.open(AddInvoicePeriodComponent, { size: 'lg', windowClass: 'small-modal', animation: false });
    this.trackEvents.Log_Screen("Add Order Period");
    modalRef.componentInstance.SelectedOrder = order;
    modalRef.result.then((result) => {
      this.getBrandData();
    }, (reason) => {
      this.getBrandData();
    });

  }
  openLink(order) {
    if (!order.hashKey || order.hashKey === '')
      this.alertService.error('Hash key not found. Can\'t create URL');
    else
      this.service.openDigitalReceipt(this.industry, this.localStorageService.getSelectedBrand().BrandID, order.orderNo, order.hashKey);
  }
  generatePDF(order) {
    this.service.getById(order.orderID).subscribe(res => {
      if (this.permissionService.checkPermission(['Invoice', 'Add Invoice Period'])) {
        this.pdfService.GenerateOrderDetailedPDF(res).then(x => {
          const data = `data:application/pdf;base64,${x}`;
          var link = document.createElement('a');
          link.href = data;
          link.download = "Order-PDF.pdf";
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        });
      }
      else {
        this.pdfService.GenerateOrderPDF(res).then(x => {
          const data = `data:application/pdf;base64,${x}`;
          var link = document.createElement('a');
          link.href = data;
          link.download = "Order-PDF.pdf";
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        });
      }

    });


  }
  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, reconciliation) {
    this.service.orders.forEach((item, index) => {
      if (item.orderID === reconciliation.orderID) {
        if ($event.target.checked === true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }

  private getExportData() {
    let selected: Order[];

    selected = [];
    this.service.orders.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length === 0 ? this.service.orders : selected;
    return selected;
  }



  public setDate(date) {
    let dateString = date.year + "-" + ('0' + date.month).slice(-2) + "-" + ('0' + date.day).slice(-2);
    this.selectedDate = new Date(dateString);
    this.connectDisconnectSocket(dateString);
    //this.dtModel = this.dateAdapter.toModel({ day: date.day, month: date.month, year: date.year })!;
    this.getBrandData();

  }


  loadLocations() {
    this.location.getAllLocationsddl(this.selectedBrand).subscribe((res: any) => {
      this.LocationsList = res;

    });
  }




  public setLocation(val) {
    if (val === null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';
      this.getBrandData();
    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;
      this.getBrandData();
    }
  }


  ConnectWebSocket(event) {
    if (event.currentTarget.checked === true) {
      this.socketService.ConnectWebSocket(this.companyCode, this.selectedBrand);
      this.socketService.receiveData.pipe().subscribe((data) => {
        this.getBrandData();
      });
    } else {
      this.close();
    }
  }

  close() {
    this.socketService.closeConnection();
    this.f.liveUpdate.setValue(false);
  }

  setToday() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  connectDisconnectSocket(date) {
    let connectAgain: boolean = this.socketService.DisconnectOutDateSocket(date);
    if (connectAgain === true) {
      this.socketService.ConnectWebSocket(this.companyCode, this.selectedBrand);
      this.socketService.receiveData.pipe().subscribe((data) => {
        this.getBrandData();
      });
    }
    if (this.socketService.ws === null) {
      this.f.liveUpdate.setValue(false);
    }
    else {
      this.f.liveUpdate.setValue(true);
    }
  }
  filterOrders() {
    if (this.orderTakerList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllOrderTakers = false;
    }
    else {
      this.checkAllOrderTakers = true;
    }
    if (this.orderTypeList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllOrderTypes = false;
    }
    else {
      this.checkAllOrderTypes = true;
    }
    if (this.orderStateList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllOrderState = false;
    }
    else {
      this.checkAllOrderState = true;
    }

    if (this.orderStatusList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllOrderStatus = false;
    }
    else {
      this.checkAllOrderStatus = true;
    }
    if (this.reportedToZATCAList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllZatcaStatus = false;
    }
    else {
      this.checkAllZatcaStatus = true;
    }
    if (this.paymentList.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllOrderPayments = false;
    }
    else {
      this.checkAllOrderPayments = true;
    }

    let headerFilter = {
      orderTaker: this.orderTakerList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      orderType: this.orderTypeList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      orderState: this.orderStateList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      orderStatus: this.orderStatusList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      zatcaStatus: this.reportedToZATCAList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      payment: this.paymentList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name })
    }
    this.service.headerFilter = headerFilter;
  }
  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterOrders();
  }
  PostInvoiceToZATCA(order) {
    this.zatcaService.PostInvoice(order.orderID).subscribe(data => {
      //this.alertService.success("Successfully Send");
      this.getBrandData();
    }, error => {
      this.alertService.error("Failed to Send");

    });

  }
}


