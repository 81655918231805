import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageuploadComponent } from '../../imageupload/imageupload.component';
import { AlertService } from '../../_services/alert.service';
import { BrandService } from '../../_services/brand.service';
import { CommonService } from '../../_services/common.service';
import { LicenseService } from '../../_services/license.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as myGlobals from '../../_helpers/globals';
import { Router } from '@angular/router';
@Component({
  selector: 'app-createbrand',
  templateUrl: './createbrand.component.html',
  styleUrls: ['./createbrand.component.css']
})
export class CreatebrandComponent implements OnInit {

  @Input() SelectedItemName = '';
  @Input() SelectedID = 0;
  ButtonText = "Save";
  brandForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  Licenses: any;
  Industries: any;
  AdditionalIDOptions: any;
  Currencies: any;
  Languages: any;
  industryURLName: string = '';
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public brandService: BrandService,
    private commonService: CommonService,
    public licenseService: LicenseService,
    private localStorageService: LocalStorageService,
    private router: Router) {
    this.createForm();
    this.loadDropDowns();
    this.industryURLName = myGlobals.industryName(this.localStorageService);
  }

  ngOnInit() {
    this.getData();
  }
  get f() { return this.brandForm.controls; }

  private createForm() {
    this.brandForm = this.formBuilder.group({
      brandName: ['', Validators.required],
      altBrandName: [''],
      licenseID: [''],
      additionalID: [0],
      CRN: ['', Validators.required],
      language: [''],
      currency: ['', Validators.required],
      industryID: ['', Validators.required],
      statusID: [true, Validators.required],
      id: 0,
      logo: [''],
      rowVersion: 0
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  private loadDropDowns() {
    this.loadLicenses();
    this.loadAdditionalIDOptions();
    this.loadIndustries();
    this.loadCurrencies();
    this.loadLanguages();
  }

  private loadLicenses() {
    this.licenseService.getLicenses("BR").subscribe((res: any) => {
      this.Licenses = res;
    });
  }
  private loadIndustries() {
    this.Industries = [
      { name: 'Restaurant', value: 1 },
      { name: 'Retail', value: 2 },
      { name: 'Cafe', value: 3 },
      { name: 'Food truck Restaurant', value: 3 },
      { name: 'Bakery', value: 4 },
      { name: 'Food court', value: 5 },
      { name: 'Drive Thru', value: 6 }
    ];

  }

  private loadAdditionalIDOptions() {
    this.AdditionalIDOptions = [
      { name: 'Choose Any', value: 0 },
      { name: 'Commercial Registration Number (CRN)', value: 1 },
      { name: 'MOMRA License', value: 2 },
      { name: 'MLSD License', value: 3 },
      { name: 'SAGIA License', value: 4 }
    ];

  }
  private loadCurrencies() {
    this.commonService.GetCurrency().subscribe((res: any) => {
      this.Currencies = res;
    });
  }
  private loadLanguages() {
    this.Languages = [
      { name: 'English', value: 'English' },
      { name: 'Arabic', value: 'Arabic' }
    ];

  }

  getData() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      // const brandID = this.local.getSelectedBrand().BrandID;
      this.brandService.get(this.SelectedID).subscribe(res => {
        //Set
        console.log(res);
        this.editForm(res);
        this.loading = false;
      });
    }

  }

  getAdditionalOptionName(id) {
    return this.AdditionalIDOptions.filter((a) => a.value === id)[0].name
  }

  editForm(brand) {
    this.f.brandName.setValue(brand.brandName);
    this.f.statusID.setValue(brand.statusID === 1 ? true : false);
    this.f.altBrandName.setValue(brand.altBrandName);
    this.f.licenseID.setValue(brand.licenseID);
    this.f.language.setValue(brand.language);
    this.f.currency.setValue(brand.currency);
    this.f.industryID.setValue(brand.industryID);
    this.f.id.setValue(this.SelectedID);
    this.f.rowVersion.setValue(brand.rowVersion);
    this.f.logo.setValue(brand.logo);
    this.f.CRN.setValue(brand.crn);
    this.f.additionalID.setValue(brand.additionalID);
    this.imgComp.imagepath = brand.logo;
  }
  onSubmit() {
    this.brandForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if ((this.f.additionalID.value !== 0 && this.brandForm.invalid)) { return; }

    const statusValue = (this.f.statusID.value === true ? 1 : 2);
    this.f.statusID.setValue(statusValue);
    //this.f.logo.setValue(this.imgComp.imagepath);

    if (this.f.licenseID.value === '' || this.f.licenseID.value === undefined) {
      this.f.licenseID.setValue(null);
    }

    this.imgComp.upload((this.f.logo.value && this.f.logo.value !== '' ? this.f.logo.value : '')).then(() => {
      this.f.logo.setValue(this.imgComp.imagepath);
      if (this.f.id.value === 0) {
        this.brandService.insert(this.brandForm.value).subscribe(() => {
          this.alertService.success("Brand has been created");
          this.loading = false;
          this.activeModal.dismiss();
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });
      }
      else {
        this.brandService.update(this.brandForm.value).subscribe(() => {
          this.alertService.success("Brand has been updated");
          this.activeModal.dismiss();
          // Set Currency to selected brand
          var brand = this.localStorageService.getSelectedBrand();
          brand.Currency = this.f.currency.value;
          this.localStorageService.setSelectedBrand(brand);
          // Set Currency to brand list
          var user = this.localStorageService.getUser();
          var userbrand = JSON.parse(user.data);
          userbrand.Brands.filter(brand => brand["BrandID"] === parseInt(this.localStorageService.getSelectedBrand().BrandID))[0].Currency = this.f.currency.value;
          user.data = JSON.stringify(userbrand);
          this.localStorageService.setSelectedUser(user);
          // Refresh the dashboard to update currency
          let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
            this.loading = false;
            this.activeModal.dismiss();
          });
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        });
      }
    });
  }

  ngOnDestroy() {
  }
}
