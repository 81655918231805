<main class="views container">
  <div class="page-header tabheader">
    <!-- <span>
    {{
            (active=="purchaseorder" ?"Purchase Order"
            :active=="transfer"?"Transfers"
            :active=="return"?"Return"
            :active=="prep"?"Preps"
            :active=="reconciliation"?"Reconciliation"
            :"-") | translate
    }}
  </span> -->
    <span>
      {{'Inventory Actions' | translate}}
    </span>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="purchaseorder" id="purchaseorder" *hasPermission="['Inventory','Inventory Action','Purchase']" (click)="active='purchaseorder';">
            <a ngbNavLink>  {{'Purchases' | translate }} </a>
            <ng-template ngbNavContent>
              <app-purchaseorder [LogEventType]="'screen'" [LogEvent]="'Purchase Return'"></app-purchaseorder>
            </ng-template>
          </li>
          <li ngbNavItem="transfer" id="transfer" *hasPermission="['Inventory','Inventory Action','Transfers']" (click)="active='transfer';">
            <a ngbNavLink> {{'Transfers' | translate }}</a>
            <ng-template ngbNavContent>
              <app-transfer [LogEventType]="'screen'" [LogEvent]="'Transfer Return'"></app-transfer>
            </ng-template>
          </li>
          <li ngbNavItem="prep" id="prep" *hasPermission="['Inventory','Inventory Action','Preps']" (click)="active='prep';">
            <a ngbNavLink> {{'Preps' | translate }} </a>
            <ng-template ngbNavContent>
              <app-preps [LogEventType]="'screen'" [LogEvent]="'Preps'"></app-preps>
            </ng-template>
          </li>
          <li ngbNavItem="return" id="return" *hasPermission="['Inventory','Inventory Action','Return']" (click)="active='return';">
            <a ngbNavLink> {{'Return' | translate }}</a>
            <ng-template ngbNavContent>
              <app-purchasereturn [LogEventType]="'screen'" [LogEvent]="'Purchase/Transfer Return'"></app-purchasereturn>
            </ng-template>
          </li>
          <li ngbNavItem="reconciliation" id="reconciliation" *hasPermission="['Inventory','Inventory Action','Reconciliation']" (click)="active='reconciliation';">
            <a ngbNavLink> {{'Reconciliation' | translate }}</a>
            <ng-template ngbNavContent>
              <app-reconciliation [LogEventType]="'screen'" [LogEvent]="'Reconciliation'"></app-reconciliation>
            </ng-template>
          </li>
          <li ngbNavItem="count" id="count" *hasPermission="['Inventory','Inventory Action','Inventory Count']" (click)="active='count';">
            <a ngbNavLink> {{'Count' | translate }}</a>
            <ng-template ngbNavContent>
              <app-inventory-count [LogEventType]="'screen'" [LogEvent]="'Inventory Count'"></app-inventory-count>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
