import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserinfoService } from './userinfo.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  selectedBrand: any;
  selectedLocation: any;

  selectedBrandChange: Subject<any> = new Subject<any>();
  selectedLocationChange: Subject<any> = new Subject<any>();

  constructor(private userInfoService: UserinfoService) {

  }

  checkAlreadySelected() {
    if (this.getSelectedBrand() !== null && this.getSelectedBrand() !== undefined)
      return true;
    else
      return false;
  }

  setSelectedBrand(brand) {
    sessionStorage.setItem('selectedBrand', JSON.stringify(brand));
    this.selectedBrandChange.next(brand);
  }

  setSelectedLocation(location) {
    sessionStorage.setItem('selectedLocation', JSON.stringify(location));
    this.selectedLocationChange.next(location);
  }
  getSelectedBrand() {
    return JSON.parse(sessionStorage.getItem('selectedBrand'));
  }

  getSelectedLocation() {
    return JSON.parse(sessionStorage.getItem('selectedLocation'));
  }
  getSelectedUser() {
    let userInfo = JSON.parse(sessionStorage.getItem("currentUser"));
    if (userInfo !== null) {
      userInfo = JSON.parse(userInfo.data);
      return userInfo;
    }
  }
  setSelectedUser(user) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }
  getUser() {
    let userInfo = JSON.parse(sessionStorage.getItem("currentUser"));
    if (userInfo !== null) {
      return userInfo;
    }
  }

  getSelectedLanguage() {
    let lang = localStorage.getItem("locale");
    if (lang !== null) {
      return lang;
    }
  }
}
