
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { PurchaseReturn, PurchaseOrder } from 'src/app/_models/Purchaseorder';
import { PrintService } from 'src/app/_services/print.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { AddpurchasereturnComponent } from './addpurchasereturn/addpurchasereturn.component';
import { PurchaseReturnService } from 'src/app/_services/purchasereturn.service';
import { InventoryReturn } from '../../../_models/purchase-return';
import { AddtransferreturnComponent } from '../../transfer/addtransferreturn/addtransferreturn.component';
import { PreviewPOReturnComponent } from './preview-poreturn/preview-poreturn.component';
import { PreviewTransferReturnComponent } from '../../transfer/preview-transfer-return/preview-transfer-return.component';
import { TrackEventsService } from '../../../_services/track-events.service';


@Component({
  selector: 'app-purchasereturn',
  templateUrl: './purchasereturn.component.html',
  styleUrls: ['./purchasereturn.component.css'],
  providers: [ExcelService]
})
export class PurchasereturnComponent implements OnInit {
  data$: Observable<InventoryReturn[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "purchaseorder";
  submit: boolean;
  excelData = [];
  StatusList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: PurchaseReturnService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    public printService: PrintService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Purchaseorders',type);    
  }
  getBrandData() {
    this.service.headerFilter = { status: [''] };
    this.service.getPurchaseorders(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });



      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }

  navigatePage(pageName) {
    if (pageName === 'Purchase') {
      const modalRef = this.modalService.open(AddpurchasereturnComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      this.trackEvents.Log_Screen("Create Purchase Return");
      modalRef.componentInstance.SelectedID = 0;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      });
    }
    else if (pageName === 'Stock') {
      const modalRef = this.modalService.open(AddtransferreturnComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      this.trackEvents.Log_Screen("Create Transfer Return");
      modalRef.componentInstance.SelectedID = 0;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      });
    }

  }

  Edit(purchaseorder) {
    const modalRef = this.modalService.open(AddpurchasereturnComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Purchase Return");
    modalRef.componentInstance.SelectedID = purchaseorder.poid;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  Print(purchaseorder) {
     const poid = purchaseorder.poid;
     this.printService.printDocument('po', poid);
  }

  Preview(order) {
    if (order.code.trim().toLowerCase().substring(0, 2) == 'po') {
      const modalRef = this.modalService.open(PreviewPOReturnComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      this.trackEvents.Log_Screen("View Purchase Return");
      modalRef.componentInstance.SelectedID = order.id
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      }); 
    }
    else {
      const modalRef = this.modalService.open(PreviewTransferReturnComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
      this.trackEvents.Log_Screen("View Transfer Return");
      modalRef.componentInstance.SelectedID = order.id
      modalRef.result.then((result) => {
      }, (reason) => {
        this.getBrandData();
      }); 
    }
       
  }

  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, purchaseorder) {
    this.service.purchaseorders.forEach((item, index) => {
      if (item.id == purchaseorder.id) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {
          
          this.excelData.splice(index, 1);
        }
      }
    });
  }
  
  private getExportData() {
    let selected: InventoryReturn[];

    selected = [];
    this.service.purchaseorders.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.purchaseorders : selected;
    return selected;
  }
  renderStatusClass(obj) {    
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 602:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"

    }
  }

  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}


