
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html'
})
export class TaxComponent implements OnInit {
 
  active = "setup";

  constructor() {
  }

  ngOnInit() {
  }
}
