import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { Observable } from 'rxjs';
import { TransferInventory, tsDetails } from 'src/app/_models/TransferInventory';
import { TransferInventoryService } from 'src/app/_services/transfer-inventory.service';
import { ReceivetransferComponent } from '../receivetransfer/receivetransfer.component';
import { ExcelService } from '../../../_services/excel.service';
import * as myGlobals from '../../../_helpers/globals';


@Component({
  selector: 'app-receivepreviewtransfer',
  templateUrl: './receivepreview-transfer.component.html',
  styleUrls: ['./receivepreview-transfer.component.css'],

})
export class ReceivepreviewtransferComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  data$: Observable<TransferInventory[]>;
  oldData: TransferInventory[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  receivepreviewtransferForm: UntypedFormGroup;
  loading = false;
  loadingTransfer = false;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  Items = [];
  Units = [];
  arrItems: tsDetails[] = [];
  totalCost: number | null = null;
  selectedLang = 'en';
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public ls: LocalStorageService,
    private alertService: AlertService,
    private transferService: TransferInventoryService,
    private modalService: NgbModal,
    private excelService: ExcelService,

  ) {
    this.createForm();
    this.selectedLang = this.ls.getSelectedLanguage();
  }

  ngOnInit() {
    this.setSelectedTransfer();
  }
  get f() { return this.receivepreviewtransferForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }
  private createForm() {

    this.receivepreviewtransferForm = this.formBuilder.group({
      tsCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      tsid: 0,
      expectedDate: [],
      date: [],
      fromLocationID: 0,
      fromLocationName: [''],
      fromLocationContact: [''],
      fromLocationAddress: [''],
      toLocationID: 0,
      toLocationName: [''],
      toLocationContact: [''],
      toLocationAddress: [''],
      rowVersion: 0,
      tsDetails: []
    });
  }

  private editForm(transfer) {

    this.f.tsCode.setValue(transfer.tsCode);
    this.f.date.setValue(transfer.date);
    this.f.expectedDate.setValue(transfer.expectedDate);
    this.f.referenceNo.setValue(transfer.referenceNo);
    this.f.tsDetails.setValue(transfer.tsDetails);
    this.arrItems = transfer.tsDetails;

    this.f.notes.setValue(transfer.notes);
    this.f.rowVersion.setValue(transfer.rowVersion);
    this.f.statusID.setValue(transfer.statusID);
    this.f.tsid.setValue(transfer.tsid);

    this.setLocations(transfer.fromLocationID, transfer.toLocationID);
    transfer.tsDetails.forEach(item => {
      if (item.cost || item.cost == 0)
        this.totalCost += item.quantity * item.cost;
    });
  }

  setSelectedTransfer() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingTransfer = true;
      this.f.tsid.setValue(this.SelectedID);
      this.transferService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingTransfer = false;
      });
    }
  }

  onSubmit(obj) {
    if (this.f.statusID.value == 605) {
      this.MarkasReceived(obj);
    }
  }

  CreateReceive(transfer) {
    this.activeModal.dismiss();

    const modalRef = this.modalService.open(ReceivetransferComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = transfer.tsid.value;
    this.selectedBrand = this.f.brandID.value;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });

  }
  getBrandData() {
    this.transferService.getTransferData(this.selectedBrand);
    this.data$ = this.transferService.data$;

    this.total$ = this.transferService.total$;
    this.loading$ = this.transferService.loading$;

  }
  setLocations(fromLocationID, toLocationID) {
    this.transferService.getLocationsById(fromLocationID).subscribe((res: any) => {
      this.f.fromLocationID.setValue(res.locationID);
      this.f.fromLocationName.setValue(res.name);
      this.f.fromLocationAddress.setValue(res.address);
      this.f.fromLocationContact.setValue(res.contactNo);
    });
    this.transferService.getLocationsById(toLocationID).subscribe((res: any) => {
      this.f.toLocationID.setValue(res.locationID);
      this.f.toLocationName.setValue(res.name);
      this.f.toLocationAddress.setValue(res.address);
      this.f.toLocationContact.setValue(res.contactNo);
    });
  }

  renderStatusClass(obj) {

    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"
    }
  }
  MarkasReceived(obj) {

    this.transferService.markasreceived(obj.brandID.value, obj.tsid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Transfer Order has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }
  selectedStatus(status) {

    if (status == "receive") {
      this.f.statusID.setValue(605);
    }
  }

  close() {
    this.activeModal.dismiss();
  }
  export(type): void {
    let element = document.getElementById('recPreTrans');
    var wrapper = document.createElement('div');


    let table = "<table>";
    table += "<thead><tr>"
    table += "<th>Transfer Order</th>";
    table += "<th> </th>";
    table += "</tr></thead><tbody>";
    let _status = this.f.statusID.value == 600 ? 'Created' : this.f.statusID.value == 601 ? 'Approved' : this.f.statusID.value == 602 ? 'Pending' : this.f.statusID.value == 603 ? 'Declined' : this.f.statusID.value == 604 ? 'Hold' : this.f.statusID.value == 605 ? 'Received' : this.f.statusID.value == 605 ? 'Returned' : '-';

    table += "<tr>";
    table += "<td><b>" + this.f.tsCode.value + "</b></td>";
    table += "<td>" + _status + "</td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td></td>";
    table += "<td></td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td>Date</td>";
    table += "<td>Expected Date</td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td>" + this.f.date.value + "</td>";
    table += "<td>" + this.f.expectedDate.value + "</td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td></td>";
    table += "<td></td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td>From</td>";
    table += "<td>Deliver to</td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td>" + this.f.fromLocationName.value + "</td>";
    table += "<td>" + this.f.toLocationName.value + "</td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td>" + this.f.fromLocationAddress.value + "</td>";
    table += "<td>" + this.f.toLocationAddress.value + "</td>";
    table += "</tr>";

    table += "<tr>";
    table += "<td>" + this.f.fromLocationContact.value + "</td>";
    table += "<td>" + this.f.toLocationContact.value + "</td>";
    table += "</tr>";


    table += "<tr>";
    table += "<td></td>";
    table += "<td></td>";
    table += "</tr>";





    table += "<tr>";
    table += "<td>Item Name</td>";
    table += "<td>Quantity</td>";
    table += "<td>Unit Cost</td>";
    table += "<td>Total Cost</td>";
    table += "</tr>";


    for (var i = 0; i < this.arrItems.length; i++) {
      table += "<tr>";
      table += "<td>" + this.arrItems[i].name + "</td>";
      table += "<td>" + this.arrItems[i].quantity + " " + this.arrItems[i].unit + "</td>";
      table += "<td>" + this.arrItems[i].cost + "</td>";
      table += "<td>" + this.arrItems[i].quantity * this.arrItems[i].cost + "</td>";
      table += "</tr>";
    }
    table += "<tr>";
    table += "<td></td>";
    table += "<td></td>";
    table += "<td>Total Cost</td>";
    table += "<td>" + this.totalCost + "</td>";
    table += "</tr>";
    table += "</tbody></table>";




    wrapper.innerHTML = table;


    this.excelService.exportAsExcelFileFromHTML(wrapper, 'Export-ReceivePreviewTransfer', type);
  }






}
