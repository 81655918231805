import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../_services/location.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from '../../_services/local-storage.service';
import { ReceiptimageComponent } from './receiptimage/receiptimage.component';
import { ReceiptService } from '../../_services/receipt.service';
import { AlertService } from '../../_services/alert.service';
import * as myGlobals from '../../_helpers/globals';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  ButtonText = "Save";
  Locations: any;
  receiptForm: UntypedFormGroup;
  loading = false;
  allowReadonly = true;
  breakCache = Math.floor((Math.random() * 100) + 1);
  @ViewChild(ReceiptimageComponent, { static: true }) imgComp;
  constructor(public activeModal: NgbActiveModal,
    private location: LocationService,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private service: ReceiptService,
    private alertService: AlertService) {

    this.createForm();
    console.log(this.f.locationID.value);
    this.loadLocations();
  }

  ngOnInit() {
  }

  close() {
    this.activeModal.dismiss();
  }

  get f() { return this.receiptForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }

  private createForm() {

    this.receiptForm = this.formBuilder.group({
      reciptID: '',
      locationID: ['', Validators.required],
      companyLogoURL: [''],
      companyTitle: ['', Validators.required],
      companyPhones: [''],
      companyAddress: ['', Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      facebook: '',
      twitter: '',
      instagram: '',
      snapchat: '',
      companyWebsite: '',
      footer: '',
      rowVersion: '',
      socialMedia: [false, Validators.required],
      vatid: ''
    });

  }

  private loadLocations() {
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }
  onLocationChange(event) {
    const locationID = this.f.locationID.value;

    console.log(locationID);

    this.service.get(locationID, this.f.brandID.value).subscribe(res => {
      //Set
      this.breakCache = Math.floor((Math.random() * 100) + 1);
      this.editForm(res);
      this.loading = false;
    });


  }

  editForm(receipt) {

    if (receipt !== null) {
      this.f.reciptID.setValue(receipt.reciptID);
      this.f.locationID.setValue(receipt.locationID);
      if (this.validURL(receipt.companyLogoURL)) {
        this.f.companyLogoURL.setValue(receipt.companyLogoURL);
        this.imgComp.imagepath = receipt.companyLogoURL;
      }
      else {
        this.imgComp.imagepath = "";
      }
      this.f.companyTitle.setValue(receipt.companyTitle);
      this.f.companyPhones.setValue(receipt.companyPhones);
      this.f.companyAddress.setValue(receipt.companyAddress);
      this.f.facebook.setValue(receipt.facebook);
      this.f.twitter.setValue(receipt.twitter);
      this.f.instagram.setValue(receipt.instagram);
      this.f.snapchat.setValue(receipt.snapchat);
      this.f.companyWebsite.setValue(receipt.companyWebsite);
      this.f.footer.setValue(receipt.footer);
      this.f.rowVersion.setValue(receipt.rowVersion);
      if (receipt.facebook !== '' || receipt.twitter !== '' || receipt.instagram !== '' || receipt.snapchat !== '')
        this.f.socialMedia.setValue(true);
      else
        this.f.socialMedia.setValue(false);
      this.f.vatid.setValue(receipt.vatid);
    }
    else {
      this.f.companyTitle.setValue('');
      this.f.companyPhones.setValue('');
      this.f.companyAddress.setValue('');
      this.f.companyLogoURL.setValue('');
      this.f.facebook.setValue('');
      this.f.twitter.setValue('');
      this.f.instagram.setValue('');
      this.f.snapchat.setValue('');
      this.f.companyWebsite.setValue('');
      this.f.footer.setValue('');
      this.f.rowVersion.setValue('');
      this.f.vatid.setValue('');
      this.f.socialMedia.setValue(false);
      this.imgComp.imagepath = "";
    }
  }
  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
  onSubmit() {
    this.receiptForm.markAllAsTouched();



    // reset alerts on submits
    this.alertService.clear();
    //this.f.companyLogoURL.setValue(this.imgComp.imagepath);

    //console.log(this.imgComp.imagepath);
    console.log(this.f.companyLogoURL);
    console.log(this.receiptForm.value);

    if (this.receiptForm.invalid) {
      return;
    }
    this.loading = true;
    this.imgComp.upload((this.f.companyLogoURL.value && this.f.companyLogoURL.value !== '' ? this.f.companyLogoURL.value : '')).then(() => {
      this.f.companyLogoURL.setValue(this.imgComp.imagepath);
      this.service.insert(this.receiptForm.value).subscribe(() => {
        this.alertService.success("Receipt has been saved.");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    });
  }
}
