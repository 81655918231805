
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalStorageService } from '../../_services/local-storage.service';
import { AlertService } from '../../_services/alert.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { ExcelService } from 'src/app/_services/excel.service';
import { Recipe } from 'src/app/_models/Recipe';
import { RecipeService } from 'src/app/_services/recipe.service';
import { AddrecipeComponent } from './addrecipe/addrecipe.component';
import { ActionConfirmationService } from '../../_services/action-confirmation.service';
import { TrackEventsService } from '../../_services/track-events.service';


@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  providers: [ExcelService]
})
export class RecipeComponent implements OnInit {
  data$: Observable<Recipe[]>;
  oldData: Recipe[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "recipe";
  submit: boolean;
  excelData = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: RecipeService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-Recipe',type);    
  }
  getBrandData() {
    this.service.getRecipe(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddrecipeComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Recipe");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(recipe) {
    
    const modalRef = this.modalService.open(AddrecipeComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Recipe");
    modalRef.componentInstance.SelectedID = recipe.recipeID;
    modalRef.componentInstance.SelectedName = recipe.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Recipe has been deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  Deactive(id, rowVersion) {

    this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Recipe has been deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }

  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, recipe) {
    this.service.recipes.forEach((item, index) => {
      if (item.recipeID == recipe.recipeID) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {
          
          this.excelData.splice(index, 1);
        }
      }
    });
  }
  
  private getExportData() {
    let selected: Recipe[];

    selected = [];
    this.service.recipes.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.recipes : selected;
    return selected;
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Recipe has been deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
}

