import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorisation',
  templateUrl: './categorisation.component.html',
  styleUrls: ['./categorisation.component.css']
})
export class CategorisationComponent implements OnInit {
  active = "category";
  constructor() { }

  ngOnInit(): void {
  }

}
