<div class="modal-body ">
    <div class="container">
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 mt-5">
                <div class="jumbotron">
                    <div class="row form-group">
                        <div class="col-lg-6 text-start">
                          <!-- <img src="assets/images/marn-logo-po.png" alt="" /> -->
                          <img class="brandLogo mt-10" [src]="localStorageService.getSelectedBrand().BrandLogo" alt="" />
                        </div>
                        <div class="col-lg-6 text-end">
                          <h3 class="font-weight-bold ">{{'Transfer Order' | translate }}</h3>
                            <h5 class=" ponum-heading ">{{TO?.tsCode}}</h5>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 text-start">
                          <div [ngClass]="renderStatusClass(TO?.statusID)" class="btn btn-sm">
                            {{ (TO?.statusID ==600?'Created': TO?.statusID ==601?'Approved': TO?.statusID ==602?'Pending': TO?.statusID ==603?'Declined': TO?.statusID ==604?'Hold': TO?.statusID ==605?'Received': TO?.statusID ==605?'Returned': '-' ) | translate}}
                          </div>
                        </div>
                        <div class="col-lg-6 text-end">
                          <h6 class="font-weight-bold ">{{'Ref' | translate }}# {{TO?.referenceNo}}</h6>
                        </div>
                    </div>
                    <div class="my-4 divider"></div>
                    <div class="row mb-5">
                        <div class="col-lg-6 text-start">
                          <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                            <label>{{TO?.date | date}}</label>
                        </div>
                        <div class="col-lg-6 text-end">
                          <h6 class="font-weight-bold ">{{'Expected Date' | translate }}</h6>
                            <label>{{TO?.expectedDate | date}}</label>
                        </div>
                    </div>

                    <div class="row  mb-5">
                        <div class="col-lg-6 text-start">
                          <h6 class="font-weight-bold po-preview-blue ">{{'From' | translate }}</h6>
                          <h6 class="font-weight-bold ">{{TO.fromLocationName}}</h6>
                            <p>{{TO.fromLocationAddress}}</p>
                            <label>{{TO.fromLocationContact}}</label>
                        </div>
                        <div class="col-lg-6 text-end">
                          <h6 class="font-weight-bold po-preview-blue ">{{'Deliver to' | translate }}</h6>
                            <h6 class="font-weight-bold ">{{TO.toLocationName}}</h6>
                            <p>{{TO.toLocationAddress}}</p>
                            <label>{{TO.toLocationContact}}</label>
                        </div>
                    </div>

                    <div class="tile">
                      <table class="table table-striped ">
                        <thead class="thead-dark">
                          <tr>
                            <th width="45%" scope="col">{{'Item Name' | translate }}</th>
                            <th width="15%" scope="col">{{'Quantity' | translate }}</th>
                            <th width="15%" scope="col">{{'Unit Cost' | translate }}</th>
                            <th width="15%" scope="col">{{'Total Cost' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of TO.tsDetails">
                            <td scope="row">
                              
                              {{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}
                            </td>
                            <td>{{item.quantity}} {{item.unit}}</td>
                            <td>{{(item.cost || item.cost ==0)?(item.cost | number : '1.2-2'):'-'}} {{(item.cost || item.cost ==0) ? brandCurrency:''}}</td>
                            <td>{{(item.cost || item.cost ==0)?(item.quantity * item.cost | number : '1.2-2'):'-'}} {{(item.cost || item.cost ==0) ? brandCurrency:''}}</td>

                          </tr>

                        </tbody>
                      </table>
                      <br />
                      <br />
                      <table class="table w-50 move-to-end" *ngIf="totalCost || totalCost == 0">
                        <thead class="table-header">
                          <tr>
                            <th scope="col" width="40%" class="border-end-width-0 text-start rounded-0 align-middle px-4 fw-bold">{{'Total Cost' | translate }}</th>
                            <th scope="col" width="60%" class="border-start-width-0  rounded-0 align-middle px-4 fw-bold">{{totalCost | number : '1.2-2'}} {{brandCurrency}}</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
</div>
