import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { State } from '../_models/State';
import { PurchaseReturn } from '../_models/Purchaseorder';
import { InventoryReturn, PurchaseReturnDetails } from '../_models/purchase-return';



const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: InventoryReturn[], column: SortColumn, direction: string): InventoryReturn[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: InventoryReturn, term: string) {
  return data.code.toLowerCase().includes(term.toLowerCase())
}
interface SearchInventoryReturnResult {
  data: InventoryReturn[];
  total: number;
}

//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[]
}


function matcheHeader(data: InventoryReturn, filterValues: FilterHeaderState) {
  let status = false;
  let supplier = false
  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }



  if (status) {
    return data;
  }

}
//@@@ Header Filter Code End


@Injectable({
  providedIn: 'root'
})

export class PurchaseReturnService {

  constructor(private http: HttpClient) {
  }


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<InventoryReturn[]>([]);
  private _data$ = new BehaviorSubject<InventoryReturn[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public purchaseorders: InventoryReturn[];
  public purchaseOrder: PurchaseReturn;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchInventoryReturnResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.purchaseorders, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End

  get data$() {
    return this._data$.asObservable();
  }


  get allData$() {
    return this._allData$.asObservable();
  }


  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

  getItems(id) {
    return this.http.get<any[]>(`api/inventory/stock/all/${id}`);
  }
  public getPurchaseorders(brandId) {

    const url = `api/InventoryReturn/all/${brandId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<InventoryReturn[]>(url).subscribe(res => {
        this.purchaseorders = res;
        this._data$.next(this.purchaseorders);
        this._allData$.next(this.purchaseorders);


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End
      });
  }



  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }



  getById(poid, brandId) {

    return this.http.get<any[]>(`api/inventory/purchaseorder/${poid}/brand/${brandId}`);




  }
  getPOsDDL(brandID) {
    return this.http.get(`api/InventoryReturn/GetPOsDDL/${brandID}`);
  }

  insert(data) {

    return this.http.post(`api/InventoryReturn/`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }
  update(data) {
    return this.http.put(`api/InventoryReturn`, data)
      .pipe(map(res => {
        return res;
      }));
  }
  getReturnPO(id, brandID) {
    return this.http.get(`api/InventoryReturn/${id}/brand/${brandID}`);
  }

  private _search(): Observable<SearchInventoryReturnResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.purchaseorders, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
}
