import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { ReceivepurchaseorderComponent } from '../receivepurchaseorder/receivepurchaseorder.component';
import { poDetails, PurchaseOrder, taxes } from 'src/app/_models/Purchaseorder';
import { Observable } from 'rxjs';
import * as myGlobals from '../../../_helpers/globals';


@Component({
  selector: 'app-receivepreviewpo',
  templateUrl: './receivepreview-po.component.html',
  styleUrls: ['./receivepreview-po.component.css'],

})
export class ReceivepreviewpoComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  data$: Observable<PurchaseOrder[]>;
  oldData: PurchaseOrder[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  receivepreviewpoForm: UntypedFormGroup;
  loading = false;
  loadingPurchaseorder = false;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  Items = [];
  Units = [];
  arrItems: poDetails[] = [];
  arrtaxes: taxes[] = [];
  selectedLang = 'en';
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public ls: LocalStorageService,
    private alertService: AlertService,
    private purchaseorderService: PurchaseorderService,
    private modalService: NgbModal
  ) {
    this.createForm();
    this.selectedLang = this.ls.getSelectedLanguage();
  }

  ngOnInit() {
    this.setSelectedPurchaseorder();

    // this.f.countryID.setValue("SA");

  }
  get f() { return this.receivepreviewpoForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }
  private createForm() {

    this.receivepreviewpoForm = this.formBuilder.group({
      poCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      poid: 0,
      supplierID: 0,
      supplierName: [''],
      supplierContact: [''],
      supplierAddress: [''],
      subTotal: 0,
      taxAmount: 0,
      grandTotal: 0,
      expectedDate: [],
      date: [],
      locationID: 0,
      locationName: [''],
      locationContact: [''],
      locationAddress: [''],
      rowVersion: 0,
      poDetails: [],
      taxes: []
    });
  }

  private editForm(purchaseorder) {

    this.f.poCode.setValue(purchaseorder.poCode);
    this.f.date.setValue(purchaseorder.date);
    this.f.expectedDate.setValue(purchaseorder.expectedDate);
    this.f.referenceNo.setValue(purchaseorder.referenceNo);
    this.f.poDetails.setValue(purchaseorder.poDetails);
    this.arrItems = purchaseorder.poDetails;

    this.f.subTotal.setValue(purchaseorder.subTotal);
    this.f.taxAmount.setValue(purchaseorder.taxAmount);
    this.f.grandTotal.setValue(purchaseorder.grandTotal);

    this.f.notes.setValue(purchaseorder.notes);
    this.f.rowVersion.setValue(purchaseorder.rowVersion);
    this.f.statusID.setValue(purchaseorder.statusID);
    this.f.poid.setValue(purchaseorder.poid);

    this.loadLocation(purchaseorder.locationID);
    this.loadSupplier(purchaseorder.supplierID);

  }

  setSelectedPurchaseorder() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingPurchaseorder = true;
      this.f.poid.setValue(this.SelectedID);
      this.purchaseorderService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingPurchaseorder = false;
      });
    }
  }

  onSubmit(obj) {

    if (this.f.statusID.value == 605) {
      this.MarkasReceived(obj);
    }

  }

  CreateReceive(purchaseorder) {
    this.activeModal.dismiss();

    const modalRef = this.modalService.open(ReceivepurchaseorderComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = purchaseorder.poid.value;
    this.selectedBrand = this.f.brandID.value;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  getBrandData() {
    this.purchaseorderService.getPurchaseorders(this.selectedBrand);
    this.data$ = this.purchaseorderService.data$;

    this.total$ = this.purchaseorderService.total$;
    this.loading$ = this.purchaseorderService.loading$;

  }
  loadSupplier(id) {
    this.purchaseorderService.getSupplierById(id).subscribe((res: any) => {
      this.f.supplierID.setValue(res.supplierID);
      this.f.supplierName.setValue(res.name);
      this.f.supplierAddress.setValue(res.email);
      this.f.supplierContact.setValue(res.contactNo);
    });
  }
  loadLocation(id) {
    this.purchaseorderService.getLocationsById(id).subscribe((res: any) => {
      this.f.locationID.setValue(res.locationID);
      this.f.locationName.setValue(res.name);
      this.f.locationAddress.setValue(res.address);
      this.f.locationContact.setValue(res.contactNo);
    });
  }

  renderStatusClass(obj) {

    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"

    }
  }
  MarkasReceived(obj) {

    this.purchaseorderService.markasreceived(obj.brandID.value, obj.poid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Purchaseorder has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }
  selectedStatus(status) {

    if (status == "receive") {
      this.f.statusID.setValue(605);
    }
  }

  close() {
    this.activeModal.dismiss();
  }

}
