

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Devices } from '../../_models/Devices';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import { AlertService } from '../../_services/alert.service';
import { DevicesService } from 'src/app/_services/devices.service';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdddeviceComponent } from './adddevice/adddevice.component';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { TrackEventsService } from '../../_services/track-events.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class DevicesComponent implements OnInit {
  data$: Observable<Devices[]>;
  oldData: Devices[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;

  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: DevicesService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private locationService: LocationService,
    private trackEvents: TrackEventsService,
    private alertService: AlertService) {
      this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
      this.loading$ = service.loading$;
      this.submit = false;
  
      this.locationSubscription = this.locationService.locationIdChange.subscribe((id) => {
        
        if (id !== undefined && id !== 0 && id !== null) {
          
          this.getData();
  
        }
      });
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.service.getDevices(LocationService.locationId);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if(this.locationSubscription!=undefined)
      this.locationSubscription.unsubscribe();
  
  }


  open() {
    const modalRef = this.modalService.open(AdddeviceComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Location Device");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getData();
    });
  }

  Edit(device) {
    //DataService.Id = device.terminalID;
    const modalRef = this.modalService.open(AdddeviceComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Location Device");
    modalRef.componentInstance.SelectedID = device.terminalID;
    modalRef.componentInstance.SelectedName = device.terminalName;
    modalRef.result.then((result) => {

    }, (reason) => {
      this.getData();
    });
  }

  SetStatus(device) {
    this.service.getById(device.terminalID).subscribe((res: any) => {
      res.statusID = device.statusID === 1 ? 2 : 1;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Device has been " + (device.statusID === 1 ? "Deactived" : "Active"));
        this.getData();
      }, error => {
        this.alertService.error(error);
      });


    });
  }
  Deactive(id, rowVersion) {
    this.service.unlink(parseInt(id), rowVersion).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Device has been Deactived");
      this.getData();
    }, error => {
      this.alertService.error(error);
    });
  }

  Unlink(device) {
    this.service.getById(device.terminalID).subscribe((res: any) => {

      res.isUsed = false;
      this.service.update(res).subscribe(data => {
        this.alertService.success("Device has been Unlinked");
        this.getData();
      }, error => {
        this.alertService.error(error);
      });


    });





  }
}
