import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbdDatepickerRangePopup } from '../../daterangepicker/datepicker-range-popup';
import { LocalStorageService } from '../../_services/local-storage.service';

@Component({
  selector: 'app-item-sales-pdf',
  templateUrl: './item-sales-pdf.component.html',
  styleUrls: ['./item-sales-pdf.component.css']
})
export class ItemSalesPDFComponent implements OnInit {
  
  @Output() html = new EventEmitter<HTMLElement>();
  @Input() selectedLocation;
  @Input() startdate: any;
  @Input() enddate: any;
  @Input() selectedLocationName;
  @Input() itemSalesData: any;
  itemSales: any;
  rowCount = 0;
  SelectedBrand: any;
  elRef: ElementRef;
  constructor(private elementRef: ElementRef,
    private localStorageService: LocalStorageService  ) {
    this.SelectedBrand = this.localStorageService.getSelectedBrand();
    this.elRef = elementRef;
  }

  ngOnDestroy() {
    if (this.itemSalesData !== null && this.itemSalesData !== undefined) {
      this.html.emit(this.elementRef.nativeElement);
    }
  }
  ngOnChanges() {
    if (this.itemSalesData !== null && this.itemSalesData !== undefined) {
      this.loadItemSales();
    }
  }
  ngOnInit() {
    if (this.itemSalesData !== null && this.itemSalesData !== undefined) {
      this.loadItemSales();
    }
  }
  loadItemSales() {
    this.rowCount = this.itemSalesData.length;
    this.itemSales = this.itemSalesData;
  }

}
