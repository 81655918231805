import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { RecipeService } from 'src/app/_services/recipe.service';
import { RecipeIngeridients } from 'src/app/_models/Recipe';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Ingredient } from '../../../_models/ingredient';
import * as myGlobals from '../../../_helpers/globals';

@Component({
  selector: 'app-addrecipe',
  templateUrl: './addrecipe.component.html',
  styleUrls: ['./addrecipe.component.css']
})
export class AddrecipeComponent implements OnInit {
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  isAutoPrepared: boolean = true;
  submitted = false;
  recipeForm: UntypedFormGroup;
  loading = false;
  loadingRecipe = false;
  ButtonText = "Save";
  HiddenIngredients: Ingredient[] = new Array();
  Ingredients: Ingredient[];
  manualSKUChangeByUser = false;
  public arrRecipeIngerdients: RecipeIngeridients[] = [];

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private recipeService: RecipeService

  ) {
    this.createForm();
    this.loadIngredients();
    
  }

  ngOnInit() {
    this.setSelectedRecipe();
    this.generateSKU();
  }
  get f() { return this.recipeForm.controls; }
  get enableSKU() {
    if (this.f.autoPrepared.value === false && (!this.f.sku.value || this.f.sku.value === '' || this.manualSKUChangeByUser)) {
      return true;

    }
    else {
      return false;
    }
  }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }

  private createForm() {

    this.recipeForm = this.formBuilder.group({
      name: ['', Validators.required],
      sku: [''],
      life: [0],
      lifePeriod: [1],
      autoPrepared: [true],
      statusID: [true, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      recipeID: 0,
      rowVersion: 0,
      recipeIngredients: []
    });
  }

  getArraysIntersection(a1, a2) {
    return a1.filter(function (n) { return a2.indexOf(n) !== -1; });
  }

  operation(list1, list2, prop, isUnion = false) {
    list1.filter(
      (set => a => isUnion === set.has(a[prop]))(new Set(list2.map(b => b[prop]))))
  };

  private editForm(recipe) {

    this.f.name.setValue(recipe.name);
    this.f.sku.setValue(recipe.sku);
    this.f.life.setValue(recipe.life);
    this.f.lifePeriod.setValue(recipe.lifePeriod);
    this.f.rowVersion.setValue(recipe.rowVersion);
    this.f.statusID.setValue(recipe.statusID === 1 ? true : false);
    this.f.recipeID.setValue(recipe.recipeID);
    this.f.autoPrepared.setValue(recipe.autoPrepared);
    this.f.autoPrepared.disable();
    this.isAutoPrepared = recipe.autoPrepared;
    this.arrRecipeIngerdients = recipe.recipeIngredients;
    this.f.recipeIngredients.setValue(this.arrRecipeIngerdients);


    for (let i = 0; i < this.arrRecipeIngerdients.length; i++) {
      this.hideIngredient(this.arrRecipeIngerdients[i]);
    }
    this.getCost();
  }

  setSelectedRecipe() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingRecipe = true;
      this.f.recipeID.setValue(this.SelectedID);
      this.recipeService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingRecipe = false;
      });
    }
  }


  onSubmit() {

    this.recipeForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.recipeForm.invalid) { return; }
    this.loading = true;

    console.info(this.arrRecipeIngerdients);

    this.f.recipeIngredients.setValue(this.arrRecipeIngerdients);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.lifePeriod.setValue(parseInt(this.f.lifePeriod.value));
    if (parseInt(this.f.recipeID.value) === 0) {

      //Insert recipe
      this.recipeService.insert(this.recipeForm.value).subscribe(data => {
        this.alertService.success("Recipe has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update recipe
      this.recipeService.update(this.recipeForm.value).subscribe(data => {
        this.alertService.success("Recipe has been updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }

  close() {
    this.activeModal.dismiss();
  }
  chkAutoPrepared() {
    this.isAutoPrepared = this.f.autoPrepared.value;
    this.generateSKU();
  }
  private loadIngredients() {
    this.recipeService.getIngredients(this.f.brandID.value).subscribe((res: Ingredient[]) => {
      this.Ingredients = res;
    });
  }

  private generateSKU() {
    if (this.enableSKU) {
      this.loading = true;
      this.manualSKUChangeByUser = false;
      this.recipeService.generateSKU(this.f.brandID.value).subscribe((res: any) => {
        this.f.sku.setValue(res.sku);
        this.loading = false;
      });
    }
  }

  private selectedIngredient(ingredientID, obj) {
    this.selectedIngredient = obj.name;
  }
  deleteRow(obj: RecipeIngeridients) {
    this.unHideIngredient(obj);
    const index = this.arrRecipeIngerdients.indexOf(obj);
    this.arrRecipeIngerdients.splice(index, 1);
  }
  addRow(obj: RecipeIngeridients) {
    if (obj.ingredientID !== undefined && obj.ingredientID !== null) {
      this.arrRecipeIngerdients.push({
        recipeIngID: 0,
        ingredientID: obj.ingredientID,
        recipeID: this.SelectedID,
        name: obj.name,
        unitID: obj.unitID,
        unit: obj.unit,
        quantity: obj.quantity === undefined ? 0 : obj.quantity,
        cost: obj.cost,
        avgCost: obj.cost
      });
      this.hideIngredient(obj);
    }    
  }
  getCost() {
    return this.arrRecipeIngerdients.reduce(function (prev, cur) { return prev + (cur.cost * cur.quantity); }, 0);
  }
  private hideIngredient(obj) {
    if (this.Ingredients !== null && this.Ingredients !== undefined) {
      const hideIng = this.Ingredients.filter((ing) => { return ing.ingredientID === obj.ingredientID })[0];
      console.log(hideIng)
      console.info(this.HiddenIngredients)
      if (hideIng !== undefined)
        this.HiddenIngredients.push(hideIng);
      this.Ingredients = this.Ingredients.filter((ing) => { return ing.ingredientID !== obj.ingredientID });

      console.info(this.arrRecipeIngerdients);
    }
  }

  private unHideIngredient(obj) {
    if (this.HiddenIngredients.length > 0) {
      const hideIng = this.HiddenIngredients.filter((ing) => { return ing.ingredientID === obj.ingredientID })[0];

      console.info(hideIng)

      //ng-select find changes as per following code with push any element it will not find change
      if (hideIng !== undefined)
        this.Ingredients = [...this.Ingredients, obj] //this.Ingredients.push(hideIng);

      console.info(this.Ingredients)
      this.HiddenIngredients = this.HiddenIngredients.filter((ing) => { return ing.ingredientID !== obj.ingredientID });
    }
  }

  //UpdateQty(newItem) {    
  //  let updateItem = this.arrRecipeIngerdients.find(this.findIndexToUpdate, newItem.quantity);
  //  let index = this.arrRecipeIngerdients.indexOf(updateItem);
  //  this.arrRecipeIngerdients[index] = newItem;
  //}

  findIndexToUpdate(newItem) {
    return newItem.quantity === this;
  }
}
