import { Component, ViewChild } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepickerRangePopup } from '../../daterangepicker/datepicker-range-popup';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import * as myGlobals from '../../_helpers/globals';
import { AccountSummaryService } from '../../_services/account-summary.service';
import { ExcelService } from '../../_services/excel.service';
import { PermissionService } from '../../_services/permission.service';
@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrl: './account-summary.component.css'
})
export class AccountSummaryComponent {
  public selectedLocationName = 'All Locations';
  private selectedBrand;
  public selectedLocation;
  LocationsList = [];
  isGeneratingPDF: boolean = false;
  selectedLang = 'en';
  startdate: any;
  enddate: any;
  incomeStatement: any;
  taxes = [];
  VATDeclaration: any;
  loading = false;
  trial: any = null;
  @ViewChild(NgbdDatepickerRangePopup, { static: false }) dateComp: { fromDate: any; toDate: any; setFromDate(date); setToDate(date); };
  constructor(private localStorageService: LocalStorageService,
    private service: AccountSummaryService,
    private location: LocationService,
    public formatter: NgbDateParserFormatter,
    private excelService: ExcelService,
    private permissionService: PermissionService) {
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selectedLocation = this.localStorageService.getSelectedLocation().LocationID;
   
  }

  get brandCurrency() {
    return myGlobals.brandCurrency(this.localStorageService);
  }
  public setLocation(val) {
    if (val === null) {
      this.selectedLocation = null;
      this.selectedLocationName = 'All Locations';

    } else {
      this.selectedLocation = val.locationID;
      this.selectedLocationName = val.name;


    }
    this.loadReport();
  }
  ngOnInit() {
    this.checkTrial();   
  }
  ngAfterViewInit() {

  }
  loadLocations() {
    this.location.getAllLocationsddl(this.selectedBrand).subscribe((res: any) => {
      this.LocationsList = res;
      this.loadReport();
    });
  }
  checkTrial() {
    this.trial = this.permissionService.checkTrial(['Analytics', 'Accounting', 'Summary']);
    if (this.trial.Access){
      this.loadLocations();
    }
  }
  loadIncomeStatement() {
    if (this.dateComp.fromDate && this.dateComp.toDate && this.dateComp.fromDate !== '' && this.dateComp.toDate !== '') {
      this.loading = true;
      this.startdate = this.dateComp.fromDate.year + "-" + this.dateComp.fromDate.month + "-" + this.dateComp.fromDate.day;
      this.enddate = this.dateComp.toDate.year + "-" + this.dateComp.toDate.month + "-" + this.dateComp.toDate.day;
      this.service.GetIncomeStatement(this.selectedBrand, this.selectedLocation, this.startdate, this.enddate).subscribe((res: any) => {
        console.log(res);
        this.incomeStatement = res;
        this.taxes = res.taxes;
        this.loading = false;
      });
    }
  }
  loadVATDeclaration() {
    if (this.dateComp.fromDate && this.dateComp.toDate && this.dateComp.fromDate !== '' && this.dateComp.toDate !== '') {
      this.loading = true;
      this.startdate = this.dateComp.fromDate.year + "-" + this.dateComp.fromDate.month + "-" + this.dateComp.fromDate.day;
      this.enddate = this.dateComp.toDate.year + "-" + this.dateComp.toDate.month + "-" + this.dateComp.toDate.day;
      this.service.GetVATDeclaration(this.selectedBrand, this.selectedLocation, this.startdate, this.enddate).subscribe((res: any) => {
        console.log(res);
        this.VATDeclaration = res;
        this.loading = false;
      });
    }
  }
  loadReport() {
    this.loadIncomeStatement();
    this.loadVATDeclaration();
  }

  public export(type) {
    var wrapper = document.createElement('div');
    this.loading = true;
    let template = '<table>';
    template += '<thead>';
    template += '<tr colspan="9"> <th> <label class="fw-bold"> Income Statement </label></th></tr>';
    template += '<tr>';
    template += '<th>Sales with VAT</th>';
    template += '<th> Sales deductibles with VAT </th>';
    template += '<th> Total Sales with VAT </th>';
    template += '<th> Sales VAT </th>';
    template += '<th> Net Sales </th>';
    template += '<th> Sales Cost </th>';
    template += '<th> Total sales Profit </th>';
    template += '<th> Total Expenses </th>';
    template += '<th> Net Profit </th>';
    template += '</tr>';
    template += '</thead>';
    template += '<tbody>';
    template += '<tr>';
    template += '<td>' + this.incomeStatement.salesWithTaxes +'</td>';
    template += '<td>' + this.incomeStatement.salesDeductibleWithTaxes +' </td>';
    template += '<td>' + this.incomeStatement.netSalesWithTaxes +' </td>';
    template += '<td>' + this.incomeStatement.salesTaxes +' </td>';
    template += '<td>' + this.incomeStatement.netSalesWithoutTaxes +' </td>';
    template += '<td>' + this.incomeStatement.salesCostWithoutTaxes +' </td>';
    template += '<td>' + this.incomeStatement.totalSalesProfitWithoutTaxes +' </td>';
    template += '<td>' + this.incomeStatement.totalExpensesWithoutTaxes +' </td>';
    template += '<td>' + this.incomeStatement.netProfitWithoutTaxes +' </td>';
    template += '</tr>';
    template += '<tr colspan="9"> <td> </td></tr>';
    template += '<tr colspan="9"> <td> </td></tr>';
    template += '</tbody>';
    template += '</table>';
    template += '<table>';
    template += '<thead>';
    template += '<tr colspan="4"> <th> <label class="fw-bold"> VAT Return </label></th></tr>';
    template += '<tr>';
    template += '<th>Taxed Net Sales </th>';
    template += '<th> Taxed Sales VAT </th>';
    template += '<th> Taxed Purchases & Expenses </th>';
    template += '<th> Taxed Purchases & Expenses VAT </th>';
    template += '</tr>';
    template += '</thead>';
    template += '<tbody>';
    template += '<tr>';
    template += '<td>' + this.VATDeclaration.vatNetSales +'</td>';
    template += '<td>' + this.VATDeclaration.salesVAT +' </td>';
    template += '<td>' + this.VATDeclaration.sumVATPurchasesAndExpenses +' </td>';
    template += '<td>' + this.VATDeclaration.vatPurchasesAndExpenses +' </td>';
    template += '</tr>';
    template += '</tbody>';
    template += '</table>';
    wrapper.innerHTML = template;

    this.excelService.exportAsExcelFileFromHTML(wrapper, 'Export-Summary', type);
    this.loading = false;
  }


}
