import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html'
})
export class LoginHeaderComponent {

  selectedLang = 'ar';
  direction = 'ltr';
  constructor(public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document  ) {

    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      this.selectedLang = browserLang;
    }
    else {
      this.selectedLang = 'ar';
    }
    this.changeLang(this.selectedLang);
  }
  changeLang(language: string) {
    if (language === 'en') {
      this.direction = 'ltr';
    }
    else {
      this.direction = 'rtl';
    }

    this.selectedLang = language;
    this.document.documentElement.lang = language;
    this.document.documentElement.dir = this.direction;
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

}
