<div class="modal-header no-border">
  <h5 class="modal-title font-weight-bold" id="modal-title">{{'Renew Device Compliance' | translate}}</h5>
  <button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <p>{{'Are you sure you want to renew the compliance?' | translate}}</p>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary text-dark" (click)="cancel()">{{'Cancel' | translate}}</button>
  <button type="button" class="btn btn-primary cutom-button ms-3 no-hover" (click)="accept()">{{'Renew' | translate}} </button>
</div>
