import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { GeneratedReport } from '../_models/GeneratedReport';

interface SearchReportResult {
  data: GeneratedReport[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: GeneratedReport[], column: SortColumn, direction: string): GeneratedReport[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: GeneratedReport, term: string) {
  return data.reportName.toLowerCase().includes(term.toLowerCase()) ||
    data.locations.toLowerCase().includes(term.toLowerCase())
}

//@@@

//@@@
interface FilterHeaderState {
  generatedBy: string[],
  reportName: string[],

}


function matcheHeader(data: GeneratedReport, filterValues: FilterHeaderState) {
  let generatedBy = false;
  let reportName = false;

  // Gender
  if (filterValues.generatedBy !== null && filterValues.generatedBy.length > 0) {
    for (var i = 0, len = filterValues.generatedBy.length; i < len; i++) {
      if (data.generatedBy.toLowerCase().toString().includes(filterValues.generatedBy[i].toLowerCase())) {
        generatedBy = true;
      }
    }
  }
  else {
    generatedBy = true;
  }



  // ReportType
  if (filterValues.reportName !== null && filterValues.reportName.length > 0) {
    for (var i = 0, len = filterValues.reportName.length; i < len; i++) {
      if (data.reportName.toLowerCase().toString().includes(filterValues.reportName[i].toLowerCase())) {
        reportName = true;
      }
    }
  }
  else {
    reportName = true;
  }
  if (reportName && generatedBy) {
    return data;
  }

}




@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<GeneratedReport[]>([]);
  private _data$ = new BehaviorSubject<GeneratedReport[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public report: GeneratedReport[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  //@@ Customer Header Filter Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {

    generatedBy: null,
    reportName: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchReportResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@ Customer Header Filter End


  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {
  }

  public getReportList(brandId) {

    const url = `api/Report/all/${brandId}`;
    console.log(url);
    tap(() => this._loading$.next(true)),
      this.http.get<GeneratedReport[]>(url).subscribe(res => {
        this.report = res;

        this._data$.next(this.report);
        this._allData$.next(this.report);


        //  this._search$.pipe(
        //    switchMap(() => this._search()),
        //    tap(() => this._loading$.next(false))
        //  ).subscribe(result => {
        //    this._data$.next(result.data);
        //    this._total$.next(result.total);
        //  });
        //  this._search$.next();
        //});


        // @@@
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

      });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }

  getAllLocationsddl(id) {
    return this.http.get(`api/location/all/${id}`);
  }
  getAllUsersddl(id, locations) {
    return this.http.get(`api/GenerateSalesReport/ddl/subuser/${id}/${locations}`);
  }
  getAllTerminalsddl(id, locationIds) {
    return this.http.get(`api/GenerateSalesReport/ddl/terminal/${id}/${locationIds}`);
  }
  getAllPaymentTypesddl() {
    return this.http.get(`api/GenerateSalesReport/ddl/paymenttype`);
  }
  getAllPartnersddl(id) {
    return this.http.get(`api/GenerateSalesReport/ddl/partner/${id}`);
  }
  getPartnersForLocationsddl(brandId, locations) {
    return this.http.get(`api/GenerateSalesReport/ddl/getPartnerByLocationIds/${brandId}/${locations}`);
  }
  generateInventoryLogs(brandId, locations, users, reference, startDate, endDate,types) {
    var filterParams = `${brandId}/${locations}/${users}/${reference}/${startDate}/${endDate}/${types}`;

    console.log(`api/GenerateReport/inventory/log/` + filterParams)
    return this.http.get(`api/GenerateReport/inventory/log/` + filterParams);
  }

  getAllOrderTypes(brandId) {
    return this.http.get(`api/ordertype/GetAllOrderTypes/${brandId}/`);
  }

  getAllTablesByLocations(locationdIds) {
    return this.http.get(`api/table/all/ByLocations/${locationdIds}/`);
  }

  generateInventoryStock(brandId, locations, stocktype, startDate, endDate) {
    var filterParams = `${brandId}/${locations}/${stocktype}/${startDate}/${endDate}`;

    console.log(`api/GenerateReport/inventory/stock/` + filterParams)
    return this.http.get(`api/GenerateReport/inventory/stock/` + filterParams);
  }
  generateSalesDetail(startDate, endDate, locations, users, paymentTypes, minPrice, maxPrice, terminals, partners, taxes, orderTypes,tables, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${users}/${paymentTypes}/${minPrice}/${maxPrice}/${terminals}/${partners}/${taxes}/${orderTypes}/${tables}/${brandID}`;

    console.log(`api/GenerateSalesReport/sales/DetailsReport/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/DetailsReport/` + filterParams);
  }

  generateSalesOrderType(startDate, endDate, locations, users, paymentTypes, minPrice, maxPrice, terminals, brandID, isGroupedByLocation) {
    var filterParams = `${startDate}/${endDate}/${locations}/${users}/${paymentTypes}/${minPrice}/${maxPrice}/${terminals}/${brandID}/${isGroupedByLocation}`;

    console.log(`api/GenerateReport/sales/OrderTypeReport/` + filterParams)
    return this.http.get(`api/GenerateReport/sales/OrderTypeReport/` + filterParams);
  }

  generateSalesTransactionType(startDate, endDate, locations, users, paymentTypes, minPrice, maxPrice, terminals, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${users}/${paymentTypes}/${minPrice}/${maxPrice}/${terminals}/${brandID}`;

    console.log(`api/GenerateReport/sales/TransactionTypeReport/` + filterParams)
    return this.http.get(`api/GenerateReport/sales/TransactionTypeReport/` + filterParams);
  }


  generateDeletedItems(startDate, endDate, locations, users, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${users}/${brandID}`;
    console.log(`api/GenerateSalesReport/sales/SalesVoidedItemReport/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/SalesVoidedItemReport/` + filterParams);
  }


  generateRefunds(startDate, endDate, locations, users, RefundReasonID, orderTypeID, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${users}/${RefundReasonID}/${brandID}/${orderTypeID}`;
    console.log(`api/GenerateSalesReport/sales/GetSalesRefundOrderReport/` + filterParams);

    return this.http.get(`api/GenerateSalesReport/sales/GetSalesRefundOrderReport/` + filterParams);
  }


  generateDiscounts(startDate, endDate, locations, users, discounts, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${users}/${discounts}/${brandID}`;
    console.log(`api/GenerateSalesReport/sales/GetSalesDiscountOrderReport/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/GetSalesDiscountOrderReport/` + filterParams);
  }

  getAllRefundReasonsddl(brandId, reasonType) {
    return this.http.get(`api/reason/all/${brandId}/${reasonType}`);
  }
  getAllSubCategory(brandId) {
    return this.http.get(`api/subcategory/all/${brandId}`);
  }
  
  getAllDiscountsddl(brandId) {
    return this.http.get(`api/discount/all/${brandId}`);
  }

  getAlltaxesddl(brandId) {
    return this.http.get(`api/tax/ddl/GetTaxTypeDDL/${brandId}`);
  }
  generateTaxReport(startDate, endDate, locations, taxTypes, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${taxTypes}/${brandID}`;
    console.log(`api/tax/GetTaxReport/{startDate}/{endDate}/{locations}/{taxTypes}/{brandID}/` + filterParams)
    return this.http.get(`api/tax/GetTaxReport/` + filterParams);
  }
  generateDailySalesReport(startDate, endDate, locations, startTime, endTime, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${startTime}/${endTime}/${brandID}`;
    console.log(`api/GenerateSalesReport/sales/GetDailySalesReport/{startDate}/{endDate}/{locations}/{startTime}/{endTime}/{brandID}/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/GetDailySalesReport/` + filterParams);
  }
  generatePartnerSummaryReport(startDate, endDate, locations,partners, startTime, endTime, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${partners}/${startTime}/${endTime}/${brandID}`;
    console.log(`api/GenerateSalesReport/sales/GetDailyPartnerSummaryReport/{startDate}/{endDate}/{locations}/{partners}/{startTime}/{endTime}/{brandID}/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/GetDailyPartnerSummaryReport/` + filterParams);
  }
  generateStockMovementReport(startDate, endDate, locations,suppliers,types, items, brandID) {
    var filterParams = `${brandID}/${locations}/${suppliers}/${types}/${items}/${startDate}/${endDate}`;
    console.log(`inventory/stockMovement/{ brandId } /{locations}/{items} /{startDate}/{endDate}/` + filterParams)
    return this.http.get(`api/GenerateReport/inventory/stockMovement/` + filterParams);
  }
  generateDetailTaxReport(startDate, endDate, locations, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${brandID}`;
    console.log(`api/tax/GetDetailTaxReport/{brandId}/{locations}/{startDate}/{endDate}/` + filterParams)
    return this.http.get(`api/tax/GetDetailTaxReport/` + filterParams);
  }
  
  generateLocationSalesReport(startDate, endDate, locations, startTime, endTime, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${startTime}/${endTime}/${brandID}`;
    console.log(`api/GenerateSalesReport/sales/GetLocationSalesReport/{startDate}/{endDate}/{locations}/{startTime}/{endTime}/{brandID}/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/GetLocationSalesReport/` + filterParams);
  }
  generateProductMixReport(startDate, endDate, locations, subcategories, includeItemCost, isGroupedByLocation, brandID) {
    var filterParams = `${startDate}/${endDate}/${locations}/${subcategories}/${includeItemCost}/${isGroupedByLocation}/${brandID}`;
    console.log(`api/sales/GetProductMixReport/{startDate}/{endDate}/{locations}/{subcategories}/{includeItemCost}/{brandID}/` + filterParams)
    return this.http.get(`api/GenerateSalesReport/sales/GetProductMixReport/` + filterParams);
  }

  delete(id, version) {
    return this.http.delete<any[]>(`api/Report/${id}/${version}`);
  }

  getSuppliers(id) {
    return this.http.get<any[]>(`api/inventory/supplier/all/${id}`);
  }
  getItems(brandId, supplierIds, types) {
    return this.http.get<any[]>(`api/inventory/stock/GetStockBySupplierAndType/${brandId}/${supplierIds}/${types}`);
  }
}

