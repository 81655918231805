import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { TillAccountDto } from '../../../_models/TillAccountDto';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { LocationService } from '../../../_services/location.service';
import { PermissionService } from '../../../_services/permission.service';
import { TillService } from '../../../_services/till.service';
import { TrackEventsService } from '../../../_services/track-events.service';
import { TillAccountViewComponent } from './till-account-view/till-account-view.component';

@Component({
  selector: 'app-till-account-list',
  templateUrl: './till-account-list.component.html',
  styleUrls: ['./till-account-list.component.css']
})
export class TillAccountListComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  private locationSubscription: Subscription;
  data$: Observable<TillAccountDto[]>;
  oldData: TillAccountDto[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  excelData = [];
  submit: boolean;
  selectedLocation: number;
  minStock: boolean = false;
  selctedLang = 'en';


  //Computed page value
  totals: any = {};

  @Input() Date: Date = null;
  constructor(public service: TillService,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
    private excelService: ExcelService,
    private alertService: AlertService,
    private permissionService: PermissionService,
    private trackEvents: TrackEventsService,
    private modalService: NgbModal) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.loading$ = service.loading$;

    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.submit = false;
    this.locationSubscription = this.locationService.locationIdChange.subscribe((id) => {
      if (id !== undefined && id !== 0 && id !== null) {
        this.getData();

      }
    });

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnInit() {
    this.service.currentTotals$.subscribe(totals => {
      this.totals = totals; // Get the current page totals
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getData();
    //this.getData();
  }
  getData() {
    if (LocationService.locationId !== 0) {
      this.service.getTillAccounts(LocationService.locationId, this.selectedBrand, this.Date);
    }
  }
  open(id) {
    if (id) {
      const modalRef = this.modalService.open(TillAccountViewComponent, { size: 'lg', windowClass: 'full-modal', animation: false });
      this.trackEvents.Log_Screen("View Till");
      modalRef.componentInstance.SelectedID = id;
      modalRef.result.then((result) => {
        this.getData();
      }, (reason) => {
        this.getData();
      });
    } else {
      this.alertService.error('No Till found.');
    }
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    this.locationSubscription.unsubscribe();
  }

}
