<form [formGroup]="taxsettingForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title-lg">{{ ((SelectedName === '') ? 'Apply Tax' :SelectedName) | translate }} </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!--
  <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">{{Title}} </h4>
      <button [disabled]="loading" class="btn btn-primary cutom-button">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ButtonText}}
      </button>
    </div> -->
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" style="margin-top: 20px;">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Location">{{'Location' | translate }}</label>
              <select class="custom-select form-control" formControlName="locationID">
                <option [ngValue]="option.locationID" selected="option.locationID == locationID"
                        *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Tax Name' | translate }}</label>
              <ng-select [items]="Taxes" bindLabel="name" class="custom" bindValue="taxID" [multiple]="true"
                         placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedTaxes">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;"
                          (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="vatNo">{{'VAT No' | translate }}</label>
              <input type="text" class="form-control" disabled style="background-color: #e9ecef;" id="vatNo" placeholder="" formControlName="vatNo">
              <!-- <div *ngIf="f.vatNo.invalid && (f.vatNo.dirty || f.vatNo.touched)"
                  class="alert alert-danger">
                  <div *ngIf="f.vatNo.errors.required">
                      VAT is required.
                  </div>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Group Status">{{'Status' | translate }} </label>
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" checked id="statusID"
                       formControlName="statusID">
                <label class="form-check-label" for="statusID"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel cutom-button" style="color: unset; width: unset;min-width: unset;" (click)="activeModal.close('Close click')">{{'Cancel' | translate }}</button>
    <button [disabled]="loading" style="float: right; " class="btn btn-primary cutom-button">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> {{ButtonText | translate }}
    </button>
  </div>
</form>
