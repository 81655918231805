import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../../_directives/sortable.directive';
import { DetailTaxes, DetailTaxReport } from '../../../_models/GeneratedReport';
import { AlertService } from '../../../_services/alert.service';
import { ExcelService } from '../../../_services/excel.service';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { PdfService } from '../../../_services/pdf.service';
import { TobaccoTaxReportService } from '../../../_services/tobacco-tax-report.service';

@Component({
  selector: 'app-tobacco-tax',
  templateUrl: './tobacco-tax.component.html',
  styleUrl: './tobacco-tax.component.css'
})
export class TobaccoTaxComponent {
  data$: Observable<DetailTaxReport[]>;
  allData$: Observable<DetailTaxReport[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedLang = 'en';
  submit: boolean;
  selectedReport: any;
  selectAll: boolean = true;
  loading: boolean = false;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TobaccoTaxReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private pdfService: PdfService,
    private alertService: AlertService,
    public translate: TranslateService,
    private excelService: ExcelService) {

    this.loading$ = service.loading$;
    this.submit = false;
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.selectedReport = history.state;

  }

  ngOnInit() {

    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      //this.getReportData("https://marnpossastorage.blob.core.windows.net/marnpos-admin-reports/2021-01-30/stock-20210130T103754521");
    }, 1000);
  }
  getReportData(dataURL) {
    this.service.getSalesDetailReport(dataURL);
    this.data$ = this.service.data$;
    this.allData$ = this.service.allData$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();


  }

  public bulkSelection($event) {
    this.data$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
    this.allData$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
  }
  public generatePDF() {
    this.service.getPDF(this.selectedReport.pdfDataURL).subscribe(e => {
      let blob = new Blob([e], { type: 'application/pdf' });

      var downloadURL = window.URL.createObjectURL(e);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "DetailTax-PDF.pdf";
      link.click();

    });
  }

  export(type, data): void {

    if (data !== undefined && data !== null && data !== "") {
      this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-DetailTax', type);
    }
    else
      (data !== undefined) ? (data.length === 0 ? this.alertService.error("Data is not available.") : "") : "";
  }


  private getExportData(data) {


    let report = [];
    this.service.report.forEach(e => {
      let d = {
        "OrderNo": e.OrderNo,
        "OrderID": e.OrderID,
        "DeviceTransactionNo": e.DeviceTransactionNo,
        "OrderDetailID": e.OrderDetailID,
        "BrandID": e.BrandID,
        "BrandName": e.BrandName,
        "BrandLogo": e.BrandLogo,
        "LocationID": e.LocationID,
        "DateTime": e.DateTime,
        "CategoryName": e.CategoryName,
        "AltCategoryName": e.AltCategoryName,
        "SubCategoryName": e.SubCategoryName,
        "AltSubCategoryName": e.AltSubCategoryName,
        "Name": e.Name,
        "AltName": e.AltName,
        "PaymentType": this.translate.instant(e.PaymentType),
        "Quantity": e.Quantity,
        "SubTotal": e.SubTotal,
        "Discount": e.Discount,
        "DiscountPercentage": e.DiscountPercentage
      };
      e.TaxColumns.forEach(x => {
        let name = (this.selectedLang == 'en') ? x.Name : x.ArabicName;
        d[name] = this.ShowCustomTaxes(e.Taxes, name);
      });
      d["GrandTotal"] = e.GrandTotal;
      d["selected"] = e.selected;
      report.push(d);
    });




    let selected: DetailTaxReport[];
    selected = [];
    report.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? report : selected;
    return selected;
  }
  ShowCustomTaxes(Taxes: DetailTaxes[], taxName: string) {
    let val = null;
    if (Taxes && taxName)
      val = Taxes.find(e => e.Name == taxName);
    return val?.Amount ?? 0;
  }

}
