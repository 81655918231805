import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetails } from '../../_models/Orders';
import { AlertService } from '../../_services/alert.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { OrderService } from '../../_services/order.service';
import { ReasonService } from '../../_services/reason.service';
import { ZATCAService } from '../../_services/zatca.service';

@Component({
  selector: 'app-refund-pinpad-order',
  templateUrl: './refund-pinpad-order.component.html',
  styleUrls: ['./refund-pinpad-order.component.css']
})
export class RefundPinpadOrderComponent implements OnInit {
  allowPost: boolean = true;
  loading = false;
  ReasonType: any;
  @Input() currentOrder: OrderDetails;

  constructor(public activeModal: NgbActiveModal,
    private orderService: OrderService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    public reasonService: ReasonService,
    private zatcaService: ZATCAService  ) {
    var brandID = this.localStorageService.getSelectedBrand().BrandID;
    this.reasonService.getRefundReason(brandID).subscribe((res: any) => {
      this.ReasonType = res;
    });
  }

  ngOnInit(): void {
  }
  onSubmit() {
    if (!this.allowPost) {
      this.alertService.error("Please select to refund");
      return;
    }
    this.loading = true;
    this.alertService.clear();

    this.orderService.RefundPinpadOrder(this.currentOrder).subscribe(data => {
      this.alertService.success("Refunded Successfully");
      this.PostInvoiceToZATCA();
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });

  }
  PostInvoiceToZATCA() {
    this.loading = true;
    this.zatcaService.PostInvoice(this.currentOrder?.order.orderID).subscribe(data => {
      this.alertService.success("Successfully Posted to ZATCA");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error("Failed to POST to ZATCA");
      this.loading = false;
    });

  }
}
