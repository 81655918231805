import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { Ordertype } from '../_models/ordertype';
import { State } from '../_models/State';


interface SearchOrderTypeResult {
  data: Ordertype[];
  total: number;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Ordertype[], column: SortColumn, direction: string): Ordertype[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Ordertype, term: string) {
  return data.name.toLowerCase().includes(term.toLowerCase())
}



//@@@ Header Filter Code Start
interface FilterHeaderState {
  status: string[]
}


function matcheHeader(data: Ordertype, filterValues: FilterHeaderState) {
  let status = false;


  if (filterValues.status !== null && filterValues.status.length > 0) {
    for (var i = 0, len = filterValues.status.length; i < len; i++) {
      if (data.statusID.toString().includes(filterValues.status[i].toString())) {
        status = true;
      }
    }
  }
  else {
    status = true;
  }


  if (status) {
    return data;
  }

}
//@@@ Header Filter Code End

@Injectable({
  providedIn: 'root'
})
export class OrdertypeService {


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Ordertype[]>([]);
  private _data$ = new BehaviorSubject<Ordertype[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public orderTypes: Ordertype[];
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }



  //@@@ Header Filter Code Start
  private _searchHeader$ = new Subject<void>();
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: FilterHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }

  private _headerState: FilterHeaderState = {
    status: null
  }
  private _setHeader(headerFilter: FilterHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }


  private _searchByHeader(): Observable<SearchOrderTypeResult> {
    //debugger
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.orderTypes, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));


    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  //@@@ Header Filter Code End


  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }


  private _search(): Observable<SearchOrderTypeResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.orderTypes, sortColumn, sortDirection);
    ////// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._searchHeader$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
  constructor(private http: HttpClient) { }

  getAllowedOrderTypes(brandId, locationId) {
    return this.http.get<Ordertype[]>(`api/ordertype/allowed/${brandId}/location/${locationId}`);
  }
  getAllowedOrderTypesForLocation(brandId, locationId) {
    return this.http.get<Ordertype[]>(`api/ordertype/location/allowed/${brandId}/location/${locationId}`);
  }

  public getAll(brandID) {
    const url = `api/ordertype/GetAllOrderTypes/${brandID}/`;
    tap(() => this._loading$.next(true)),
      this.http.get<Ordertype[]>(url).subscribe(res => {
        this.orderTypes = res;
        this._allData$.next(this.orderTypes);



        //this._search$.pipe(
        //  switchMap(() => this._search()),
        //  tap(() => this._loading$.next(false))
        //).subscribe(result => {
        //  this._data$.next(result.data);
        //  this._total$.next(result.total);
        //});
        //this._search$.next();


        //@@@ Header Filter Code Start
        this._search$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });


        this._searchHeader$.pipe(
          switchMap(() => this._searchByHeader()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

        //@@@ Header Filter Code End



      });
  }


  public get(OrderTypeID) {
    return this.http.get<Ordertype[]>(`api/ordertype/GetOrderType/${OrderTypeID}`);
  }
  insert(data) {
    return this.http.post(`api/ordertype/InsertOrderType`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  update(data) {
    return this.http.put(`api/ordertype/UpdateOrderType`, data)
      .pipe(map(res => {
        return res;
      }));
  }
}
