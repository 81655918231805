import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { AlertService } from '../../../_services/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferreturnService } from '../../../_services/transferreturn.service';
import { Observable } from 'rxjs';
import { TransferReturnDetails } from '../../../_models/transfer-return';
import { TransferInventoryService } from '../../../_services/transfer-inventory.service';

@Component({
  selector: 'app-addtransferreturn',
  templateUrl: './addtransferreturn.component.html',
  styleUrls: ['./addtransferreturn.component.css']
})
export class AddtransferreturnComponent implements OnInit {

  treturnForm: UntypedFormGroup;
  ButtonText = "Create";
  loading: any;
  TRs: any;
  isItemNotSelected = true;
  Locations = [];
  returnitems: Observable<TransferReturnDetails[]>;

  constructor(private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    public activeModal: NgbActiveModal,
    private alertService: AlertService,
    public service: TransferreturnService,
    private transferService: TransferInventoryService) {
    this.createForm();
    this.getTransferDDL();
  }
  ngOnInit() {
    this.loadLocations();
  }
  get f() { return this.treturnForm.controls; }

  private createForm() {

    this.treturnForm = this.formBuilder.group({
      TSID: ['', Validators.required],
      statusID: [1, Validators.required],
      note: [''],
      location: 0,
      BrandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      returnDate:'',
      rowVersion: 0,
      tsDetails: null
    });
  }
  getTransferDDL() {
    this.service.getTransferDDL(this.f.BrandID.value).subscribe((res: any) => {
      //debugger;
      this.TRs = res;
    });

  }
  onTransferChange(event) {
    debugger;
    this.setSelectedTransfer();
  }
  setSelectedTransfer() {

    //this.ButtonText = "Update";

    this.service.getById(this.f.TSID.value, this.f.BrandID.value).subscribe(res => {
      //Set Forms
      this.editForm(res);

    });

  }
  private editForm(transfer) {

    this.f.tsDetails.setValue(transfer.tsDetails);
    this.returnitems = transfer.tsDetails;

    this.f.note.setValue(transfer.notes);
    this.f.rowVersion.setValue(transfer.rowVersion);
    this.f.statusID.setValue(transfer.statusID);
    this.f.TSID.setValue(transfer.tsid);
    this.f.location.setValue(transfer.fromLocationID);

    //this.f.toLocationID.setValue(transfer.toLocationID);
  }
  onReturnQtyChange(item) {
    debugger;
    if (item.selected === true && (item.returnQuantity === undefined || item.returnQuantity === '' || item.returnQuantity === null || item.returnQuantity > item.quantity || item.returnQuantity <= 0)) {
      this.treturnForm.setErrors({ 'invalid': true });
    }
    else {
      this.treturnForm.setErrors(null);
    }


  }
  loadLocations() {
    this.transferService.getAllLocationsddl(this.f.BrandID.value).subscribe((res: any) => {
      this.Locations = res;
      
    });
  }
  close() {
    this.activeModal.dismiss();
  }

  onSubmit() {
    this.treturnForm.markAllAsTouched();
    debugger;
    // reset alerts on submits
    this.alertService.clear();
    if (this.treturnForm.invalid) {
      return;
    }

    this.returnitems.forEach(x => {
      x["returnDate"] = new Date();//"2020-12-16";
      if (x["selected"] == undefined) {
        x["selected"] = false;
      }
    });
    this.returnitems.forEach(x => {
      if (x["selected"] === true) {
        this.isItemNotSelected = false;
        return;
      }
    });

   
    if (this.isItemNotSelected === true) {
      this.alertService.error("Please select item");
      return;
    }

    this.f.returnDate.setValue(new Date());
    this.f.tsDetails.setValue(this.returnitems);

    this.loading = true;
    this.service.insert(this.treturnForm.value).subscribe(() => {
      this.alertService.success("Stock Return has been saved.");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });


  }
}
