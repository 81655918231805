import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Partner } from 'src/app/_models/partner';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { MarketplaceService } from 'src/app/_services/marketplace.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { DeliverectComponent } from './deliverect/deliverect.component';
import { GrubTechComponent } from './grub-tech/grub-tech.component';
import { JahezIntegrationComponent } from './jahez-integration/jahez-integration.component';
import { MarnpayComponent } from './marnpay/marnpay.component';
import { MoyasarComponent } from './moyasar/moyasar.component';
import { MsegatComponent } from './msegat/msegat.component';
import { PartnersComponent } from './partners/partners.component';
import { StcpayComponent } from './stcpay/stcpay.component';
import { VomAccountingComponent } from './vom-accounting/vom-accounting.component';
import { ZatcaComponent } from './zatca/zatca.component';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {
  private selectedBrand;
  active = 'marketplace';
  constructor(
    private modalService: NgbModal,
    private services: MarketplaceService,
    private trackEvents: TrackEventsService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
   // document.getElementsByTagName('body')[0].setAttribute("style", "background-color:#f5f6f6 !important");

    this.loadMarketplaces();
  }

  public ngOnDestroy() {
    document.getElementsByTagName('body')[0].removeAttribute("style");
  }

  Partners: any;
  InstalledCount : 0;
  loadMarketplaces() {
    this.services.getMarketplaces(this.selectedBrand).subscribe((res: Partner[]) => {
      this.Partners = res;

      console.log(this.Partners);

      this.InstalledCount=this.Partners?.filter(r =>  r.install=='Installed').length;
    });
  }



  install(partner) {

    if (partner.type == "MSEGAT") {
      const modalRef = this.modalService.open(MsegatComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install MSEGAT");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });
    }
    else if (partner.type == "STCPAY") {
      const modalRef = this.modalService.open(StcpayComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install STC PAY");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });

    }
    else if (partner.type == "VOM") {
      const modalRef = this.modalService.open(VomAccountingComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install VOM");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });

    }
    else if (partner.type == "MOYASAR") {
      const modalRef = this.modalService.open(MoyasarComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install MOYASAR");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });

    }
    else if (partner.type == "JAHEZ") {
      const modalRef = this.modalService.open(JahezIntegrationComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install JAHEZ");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });

    }
    else if (partner.type == "MARN PAY") {
      const modalRef = this.modalService.open(MarnpayComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install MARN PAY");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.componentInstance.Partner = partner;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });

    }
    else if (partner.type == "DELIVERECT") {
      const modalRef = this.modalService.open(DeliverectComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install DELIVERECT");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.componentInstance.Partner = partner;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });

    }
    else if (partner.type == "GRUBTECH") {
      const modalRef = this.modalService.open(GrubTechComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install Grubtech");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.componentInstance.Partner = partner;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });
    }
    else if (partner.type == "ZATCA Phase 2") {
      const modalRef = this.modalService.open(ZatcaComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install Zatca Phase 2");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.componentInstance.Partner = partner;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });
    }
    else if (partner.type == "PARTNER") {

      const modalRef = this.modalService.open(PartnersComponent, { size: 'xl', windowClass: 'full-modal msegat' });
      this.trackEvents.Log_Screen("Install PARTNER");
      modalRef.componentInstance.PartnerID = partner.partnerID;
      modalRef.componentInstance.Partner = partner;
      modalRef.result.then((result) => {
      }, (reason) => {
        this.loadMarketplaces();

      });
    }
    
  }
}
