import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Order, OrderDetails } from '../../_models/Orders';
import { AlertService } from '../../_services/alert.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { OrderService } from '../../_services/order.service';
import { ReasonService } from '../../_services/reason.service';
import { ZATCAService } from '../../_services/zatca.service';

@Component({
  selector: 'app-refund-order',
  templateUrl: './refund-order.component.html',
  styleUrls: ['./refund-order.component.css']
})
export class RefundOrderComponent implements OnInit {
  submitted = false;
  RefundOrderForm: UntypedFormGroup;
  loading = false;
  industry = 0;
  ReasonType: any;
  count = 0;
  SelectedOrder: OrderDetails;
  @Input() currentOrder: OrderDetails;
  selctedLang = 'en';
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private orderService: OrderService,
    public reasonService: ReasonService,
    private zatcaService: ZATCAService) {
    this.industry = this.localStorageService.getSelectedUser().User.Industry;
    this.selctedLang = this.localStorageService.getSelectedLanguage();
  }

  ngOnInit() {
    var brandID = this.localStorageService.getSelectedBrand().BrandID;
    this.reasonService.getRefundReason(brandID).subscribe((res: any) => {
      this.ReasonType = res;
    });
  
    this.onChange();
  }
  sumModifiers(modifiers) {
    if (modifiers !== null && this.SelectedOrder.order.sumModifier) {
      let total = 0;
      for (let data of modifiers) {
        total += data.price * data.quantity;
      }
      return total;
    } else {
      return 0;
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  public bulkSelection($event) {
    this.SelectedOrder.item.forEach(e => {
      if (!e.isComplimentary) {
        e.selected = $event.target.checked;
      }
    });
    this.SelectedOrder.item.forEach(e =>
    {
      if (!e.isComplimentary) {
        e.changeRefundQuantity = (e.quantity)
      }
    });
    this.onChange();
  }
  onChange() {
    this.count = 0;

    this.SelectedOrder = Object.assign({}, this.currentOrder);
    //this.SelectedOrder.item = this.SelectedOrder.item.filter(function (ele) { return ele.isRefunded === false })

    this.SelectedOrder.item.forEach(item => {
      if (item.selected && item.isRefunded === false) {
        this.count = this.count + 1;
        if (!item.changeRefundQuantity || item.changeRefundQuantity == 0)
          item.changeRefundQuantity = (item.quantity);
      }
    })
  }
  onQtyChange(item) {
    if (item.selected === true && (item.changeRefundQuantity !== undefined && item.changeRefundQuantity !== '' && item.changeRefundQuantity !== null && item.changeRefundQuantity <= item.quantity && item.changeRefundQuantity >= 0)) {
      item.refundQuantity = item.changeRefundQuantity;
    }
  }
  getFormattedPrice(price: number) {
    var value = 0;
    price = parseFloat((price + 0.001).toFixed(3));
    var result = (price - Math.floor(price)) !== 0;

    if (result) {
      var sp = price.toString().split('.');
      value = parseFloat(sp[0] + '.' + sp[1].substring(0, 2));
    }
    else {
      //var sp = result.toString().split('.');
      value = parseFloat(price.toString());//parseFloat(sp[0] + sp[1].substring(0, 1));
    }
    return value;
  }
  onSubmit() {
    this.submitted = true;
    let errorOccured = false;
    let allowPost = false;
    let itemNotReturned = false;
    this.loading = true;
    this.alertService.clear();
    this.SelectedOrder.item.forEach(e => {
      if (e.selected === true) {
        allowPost = true;
        this.onQtyChange(e);
        if (e.quantity > 0 && (e.changeRefundQuantity === null || e.changeRefundQuantity === undefined || e.changeRefundQuantity <= 0)) {
          this.loading = false;
          errorOccured = true;
          return;
        }
        if (e.quantity > 0 && (e.changeRefundQuantity > e.quantity)) {
          this.loading = false;
          errorOccured = true;
          return;
        }
        if (e.refundReasonID === null || e.refundReasonID === undefined) {
          this.loading = false;
          this.alertService.error("Please select reason");
          errorOccured = true;
          return;
        }
        if (e.itemDetails) {
          e.itemDetails.forEach(m => {
            if (m.returnToInventory && !e.returnToInventory && e.trackInventory && m.trackInventory) {
              errorOccured = true;
              itemNotReturned = true;
              return;
            }
          });
        }
      }

      if (e.quantity === e.refundQuantity) {
        e.itemStatus = 107;
      }
      else if (e.refundQuantity > 0 && e.refundQuantity < e.quantity) {
        e.itemStatus = 110;
      }
      e.refundReason = this.ReasonType?.find(x => x.id === e.refundReasonID)?.name ?? '';

    });
    if (allowPost === false) {
      this.loading = false;
      this.alertService.error("Please select item");
      return;
    }
    if (itemNotReturned) {
      this.loading = false;
      this.alertService.error("Item must be return to Inventory");
      return;
    }
    if (errorOccured === false) {
      this.orderService.update(this.SelectedOrder).subscribe(data => {
        this.alertService.success("Refunded Successfully");
        this.PostInvoiceToZATCA();
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }

  }
  PostInvoiceToZATCA() {
    this.loading = true;
    this.zatcaService.PostInvoice(this.SelectedOrder?.order.orderID).subscribe(data => {
      this.alertService.success("Successfully Posted to ZATCA");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error("Failed to POST to ZATCA");
      this.loading = false;
    });

  }
}
