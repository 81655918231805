import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from '../_helpers/globals';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CountryService } from '../_services/country.service';
import { LocalStorageService } from '../_services/local-storage.service';
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})

export class UpdateProfileComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  activeSection = 'profile';
  passtext = 'show'
  isRestaurant: boolean;
  fieldTextType = false;
  profileForm: UntypedFormGroup;
  isPasswordVerified: boolean;
  oldPasswordVerification: boolean;
  loading = false;
  userType: number = 0;
  Countries: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private countryService: CountryService,
    private router: Router  ) {
    this.createForm();
  }

  ngOnInit() {
    this.isRestaurant = myGlobals.industryIsRestaurant(this.localStorageService);
    this.userType = this.localStorageService.getUser().userType;
    this.setSelectedUser();
    this.loadCountries();
  }
  get f() { return this.profileForm.controls; }
  private createForm() {

    this.profileForm = this.formBuilder.group({
      userID: 0,
      firstName: ['', Validators.required],
      lastName: [''],
      contact: ['', Validators.required],
      password: [''],
      currentPassword: [''],
      confirmPassword: [''],
      email: ['', [Validators.required, Validators.email]],
      updatePassword: false,
      countryID: ['', Validators.required],
      statusID: 1,
      rowVersion: 0
    });
  }
  setSelectedUser() {
    if (this.SelectedID !== 0) {
      this.loading = true;
      this.authenticationService.getUserByID(this.SelectedID, this.userType).subscribe(res => {
        //Set
        this.editForm(res);
        this.loading = false;
      });
    }
  }
  private loadCountries() {
    this.countryService.getCountries().subscribe((res: any) => {
      this.Countries = res;
    });
  }
  editForm(user) {
    this.f.userID.setValue(user.userID);
    this.f.firstName.setValue(user.firstName + ' ' + user.lastName);
    //this.f.lastName.setValue(user.lastName);
    this.f.countryID.setValue(user.countryID);
    this.f.contact.setValue(user.contactNo);
    this.f.email.setValue(user.email);
  }
  close() {
    this.activeModal.dismiss();
  }
  forgotPassword() {
    this.activeSection = "forgot";
  }

  Verify() {
    this.f.updatePassword.setValue(true);
    this.f.confirmPassword.setValidators([Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d~`!@#$%^&*()_+=[\]\{}|;:",.\/<>?]{8,}$/)]);
    this.f.password.setValidators([Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d~`!@#$%^&*()_+=[\]\{}|;:",.\/<>?]{8,}$/)]);
    this.authenticationService.verifyPassword(this.f.userID.value, this.f.currentPassword.value, this.localStorageService.getUser().token, this.userType).subscribe(data => {
      if (data.status === 1) {
        this.isPasswordVerified = true;
      }
      else {
        this.isPasswordVerified = false;
      }
    }, error => {
      this.isPasswordVerified = false;
    });
  }
  onSubmit() {
    this.profileForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();
    if (this.f.updatePassword.value === true) {
      if (this.f.password.value === this.f.confirmPassword.value && this.f.password.value !== this.f.currentPassword.value) {
        this.oldPasswordVerification = true;
      }
      else {
        this.oldPasswordVerification = false;
      }
    }
    
    if (this.profileForm.invalid || this.isPasswordVerified === false || this.oldPasswordVerification === false) { return; }
    this.loading = true;
    this.authenticationService.updateUserProfile(this.profileForm.value, this.userType).subscribe(data => {
      if (this.f.updatePassword.value === true) {
        this.alertService.success("User and Password have been updated");
      } else {
        this.alertService.success("User has been saved");
      }
      var user = this.localStorageService.getUser();
      user.firstName = this.f.firstName.value;
      var userbrand = JSON.parse(user.data);
      userbrand.User.FirstName = this.f.firstName.value;
      user.data = JSON.stringify(userbrand);
      this.localStorageService.setSelectedUser(user);     
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });

  }
}
