import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-datepicker-range',
  templateUrl: './datepicker-range-popup.html',
  styles: [`
    .form-group.hidden {
      width: 0;
      margin: 0;
      border: none;
      padding: 0;
    }
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
  `]
})
export class NgbdDatepickerRangePopup {

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  FromDate: any = null;
  ToDate: any = null;
  @Input() FromDateToBeStartOfMonth: boolean = false;
  @Input() ToDateToBeStartOfMonth: boolean = false;
  @Input() minDate = { year: 1950, month: 1, day: 1 };
  @Input() allowMinDate: boolean = false;
  @Output() OnHit: EventEmitter<string> = new EventEmitter<string>();

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();// calendar.getNext(calendar.getToday(), 'd', 10);
  }
  ngOnInit() {
    if (this.FromDateToBeStartOfMonth) {
      let date = NgbDate.from(this.formatter.parse((this.fromDate.year + '-' + this.fromDate.month + '-' + 1).toString()));
      this.fromDate = date;
    }
    if (this.ToDateToBeStartOfMonth) {
      let date = NgbDate.from(this.formatter.parse((this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day).toString()));
      this.toDate = date;
    }
  }
  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {

      this.toDate = date;
      datepicker.close();

    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.OnHit.emit('Click');
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {

    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


  changeEv() {
    if (this.FromDate === "") {
      this.fromDate = null;
    }
  }

  changeEvTodate() {
    if (this.ToDate === "") {
      this.toDate = null;
    }
  }
  fromModel(date: Date): NgbDateStruct {
    return date ? {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    } : null;
  }

}
