
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { TaxService } from 'src/app/_services/tax.service';
import { LocationService } from 'src/app/_services/location.service';
import { ItemService } from '../../../../_services/item.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Item } from '../../../../_models/Item';




@Component({
  selector: 'app-addtaxsetup',
  templateUrl: './addtaxsetup.component.html',
  styleUrls: ['./addtaxsetup.component.css']
})
export class AddtaxsetupComponent implements OnInit {
  @Input() name;
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  @Input() SelectedTaxReference: any = null;
  submitted = false;
  taxsetupForm: UntypedFormGroup;
  loading = false;
  loadingTax = false;
  ButtonText = "Save";
  Title = "New Tax";
  Locations: any;
  Items = [];
  selectedLocations = [];
  dropdownSelectedItems = [];
  selectedItems: Item[] = [];
  ApplicableOn = [
    { name: 'Locations', id: 'Locations' },
    { name: 'Items', id: 'Items' }
  ]
  CalculatedOn = [
    { name: 'SubTotal', id: 'SubTotal' },
    { name: 'Invoice', id: 'Invoice' },
  ];
  taxRefs = [
    { name: 'Saudi VAT 15% 2023', id: 1 },
    { name: 'Saudi Tobacco 100% 2023', id: 2 },
    { name: 'Bahrain VAT 5% 2023', id: 3 }
  ];

  selectedLang = 'en';
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private taxService: TaxService,
    private location: LocationService,
    private itemService: ItemService
  ) {
    this.createForm();
    this.selectedLang = this.localStorageService.getSelectedLanguage();
    this.loadItems();
  }

  ngOnInit() {
    this.setSelectedTax();
  }

  ngAfterViewInit() {

  }

  private createForm() {
    this.taxsetupForm = this.formBuilder.group({
      name: ['', Validators.required],
      arabicName: ['', Validators.required],
      percentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      applicableOn: ['Locations', Validators.required],
      calculationBy: ['SubTotal', Validators.required],
      values: ['', Validators.required],
      includedItemPrice: [0],
      statusID: [0],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      rowVersion: 0,
      taxID: 0,
      location: '',
      minTax: 0,
      taxRef: 3
    });
  }


  get f() { return this.taxsetupForm.controls; }

  setSelectedTax() {
    if (this.SelectedID !== 0) {
      this.loadLocations();
      this.ButtonText = "Save";
      this.loadingTax = true;

      this.taxService.getById(this.SelectedID).subscribe(res => {
        console.log(res);
        this.Title = "Edit Tax";
        //Set Forms
        this.editForm(res);
        this.loadingTax = false;
      });
    }
    else {
      if (this.SelectedTaxReference)
        this.selectTaxRef(this.SelectedTaxReference);
      else {
        this.loadLocations();
      }
    }
  }



  private selectTaxRef(taxRef) {
    this.f.name.setValue(taxRef.name);
    this.f.arabicName.setValue(taxRef.arabicName);
    this.f.percentage.setValue(taxRef.percentage);
    this.f.applicableOn.setValue(taxRef.applicableOn);
    this.f.calculationBy.setValue(taxRef.calculationBy);
    this.f.brandID.setValue(parseInt(this.localStorageService.getSelectedBrand().BrandID));

    this.f.minTax.setValue(taxRef.minTax);
    this.f.taxRef.setValue(taxRef.taxRef);


    if (taxRef.taxRef === 1) {
      this.loadLocations(true);
    }
    else {
      this.loadLocations();
    }
  }
  private editForm(value) {
    this.f.taxID.setValue(value.taxID);
    this.f.name.setValue(value.name);
    this.f.arabicName.setValue(value.arabicName);
    this.f.percentage.setValue(value.percentage);
    this.f.applicableOn.setValue(value.applicableOn);
    this.f.calculationBy.setValue(value.calculationBy);
    this.f.brandID.setValue(value.brandID);

    this.f.includedItemPrice.setValue(value.includedItemPrice === 1 ? true : false);
    this.f.statusID.setValue(value.statusID === 1 ? true : false);
    this.f.rowVersion.setValue(value.rowVersion);
    this.f.minTax.setValue(value.minTax);
    this.f.taxRef.setValue(value.taxRef);

    this.f.values.setValue(value.values);

    if (this.f.applicableOn.value === 'Locations') {
      this.selectedLocations = value.values.split(',').map(i => Number(i));
    }
    else {
      this.dropdownSelectedItems = value.values.split(',').map(i => Number(i));
      this.loadItems();
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  private loadLocations(selectAll: boolean = false) {
    this.loading = true;
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
      if (selectAll === true) {
        this.onSelectAll();
      }
      this.loading = false;
    });
  }

  private loadItems() {
    this.itemService.getAllItemsddl(parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe((res: any) => {
      this.Items = res;
      if (this.f.taxID.value !== 0 && this.f.applicableOn.value === 'Items') {
        this.selectedItems = [];
        var items = this.f.values.value.split(',').map(i => Number(i));
        items.forEach(e => {
          this.selectedItems.push(this.Items.find(x => e == x.itemID));
        });
      }
    });
  }
  public onSelectAll() {
    const _selectedLocations = this.Locations.map(item => item.locationID);
    this.taxsetupForm.get('location').patchValue(_selectedLocations);
  }
  closeSelect(select: NgSelectComponent) { select.close(); }
  onSubmit() {
    this.taxsetupForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();
    if (this.f.applicableOn.value === 'Items') {
      if (this.selectedItems.length == 0) {
        this.alertService.error("Items cannot be empty.");
        return;
      }
      this.f.values.setValue(this.selectedItems.map(e => e.itemID).toString());
    }
    else {
      this.f.values.setValue(this.selectedLocations.toString());
    }
    if (this.taxsetupForm.invalid) { return; }
    this.f.includedItemPrice.setValue(this.f.includedItemPrice.value === true ? 1 : 0);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.loading = true;
    if (parseInt(this.f.taxID.value) === 0) {
      this.taxService.insert(this.taxsetupForm.value).subscribe(data => {
        this.alertService.success("Tax has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    } else {
      this.taxService.update(this.taxsetupForm.value).subscribe(data => {
        this.alertService.success("Tax has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }

  closeItemSelect(select: NgSelectComponent) { select.close(); }
  isSelectAll = false;
  public onSelectAllItem(select) {
    this.selectedItems = [];
    this.isSelectAll = true;
    this.dropdownSelectedItems = this.Items.map(s => s.itemID);
    this.selectedItems = this.Items;
    this.closeSelect(select);
  }

  public onDeSelectAllItem(select) {
    this.isSelectAll = false;
    this.selectedItems = [];
    this.closeSelect(select);
  }
  deleteRow(obj) {
    const index = this.selectedItems.indexOf(obj);
    this.selectedItems.splice(index, 1);
    this.isSelectAll = false;
  }

  addRow(obj) {
    if (obj.name != undefined && obj.name != '' && obj.name != null) {
      if (!this.selectedItems.find(element => element.itemID == obj.itemID)) {
        this.selectedItems.push({
          itemID: obj.itemID,
          categoryID: obj.categoryID,
          subCatID: obj.subCatID,
          categoryName: obj.categoryName,
          altCategoryName: obj.altCategoryName,
          subCategoryName: obj.subCategoryName,
          altSubCategoryName: obj.altSubCategoryName,
          name: obj.name,
          nameOnReceipt: obj.nameOnReceipt,
          description: obj.description,
          itemImage: obj.itemImage,
          barcode: obj.barcode,
          price: obj.price,
          displayOrder: obj.displayOrder,
          unit: obj.unit,
          fixedPrice: obj.fixedPrice,
          unitID: obj.unitID,
          rowVersion: obj.rowVersion,
          statusID: obj.statusID,
          locations: obj.locations,
          modifiers: obj.modifiers,
          priceType: obj.priceType,
          uItemID: obj.uItemID,
          selected: obj.selected
        });
      }
      else {
        this.alertService.error("Item already added in list");
      }

    }
  }
}

