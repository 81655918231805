import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { TrackEventsService } from '../_services/track-events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAnnouncementComponent } from '../user-announcement/user-announcement.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [
    trigger('enabledStateChange', [
      state(
        'default',
        style({
          opacity: 1,
        })
      ),
      state(
        'disabled',
        style({
          opacity: 1,
        })
      ),
      // transition('* => *', [
      //    animate('300ms ease-in', 
      //     //style({ opacity: 1 })
      //    )

      // ]),

      transition('default => disabled', animate('500ms ease-in', keyframes([
        style({ opacity: .3 }),
        style({ opacity: .6 }),
        style({ opacity: 1 })
      ]))),
      transition('disabled => default', animate('500ms ease-in', keyframes([
        style({ opacity: .3 }),
        style({ opacity: .6 }),
        style({ opacity: 1 })
      ])))

    ]),

    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ])

  ],


})
export class HomeComponent {
  isRestaurant: boolean;
  path: string;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private trackService: TrackEventsService,
    private modalService: NgbModal) {
    this.isRestaurant = true;
    this.path = this.location.path(true);

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
      password: ['', { validators: [Validators.required], updateOn: 'blur' }]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = '/restaurant';
    this.setIndustryURL(this.path.toLowerCase());

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value, this.isRestaurant)
      .pipe(first())
      .subscribe(
        data => {
          const LoginData = JSON.parse(data.data);
          console.log(LoginData);
          this.trackService.LogIn();
          if (this.returnUrl !== '')
            this.router.navigate([this.returnUrl]);
          else
            this.router.navigate([this.isRestaurant === true ? '/restaurant' : '/retail']);
          if (LoginData.User.RequiredUserDetails)
            this.showUserAnnouncement();
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

  setIndustryURL(path: string) {
    switch (path) {
      case '#restaurant':
        this.setIndustry(true);
        break;
      case '#retail':
        this.setIndustry(false);
        break;
      default:
        this.setIndustry(true);
        break;
    }
  }
  stateOfElement: any = 'default';
  setIndustry(val: boolean) {
    this.loginForm.reset();
    this.isRestaurant = val;
    if (this.isRestaurant === true) {
      this.stateOfElement = 'default';
    } else {
      this.stateOfElement = 'disabled';
    }


  }


  activeSection = 'login';
  forgotPassword() {
    this.activeSection = "forgot";

  }
  backLogin() {
    this.activeSection = "login";
  }
  public showUserAnnouncement() {
    const modalRef = this.modalService.open(UserAnnouncementComponent, { size: 'lg', windowClass: 'small-modal', animation: false });
    this.trackService.Log_Screen("User Announcement");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      //this.getBrandData();
    });
  }
}
