<form [formGroup]="roleForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Role' :SelectedName) | translate }}  </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Setup','Users','Roles', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="roleName">{{'Name' | translate }}</label>
              <input type="text" class="form-control" id="roleName" placeholder="" formControlName="roleName">
              <div *ngIf="f.roleName.invalid && (f.roleName.dirty || f.roleName.touched)" class="alert alert-danger">
                <div *ngIf="f.roleName.errors.required">
                  {{'Name is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Type">{{'Role Type' | translate }}</label>
              <select id="type" formControlName="type" class="form-control" (change)="onTypeChange()">
                <option value="App">{{'App' | translate }}</option>
                <option value="Dashboard">{{'Dashboard' | translate }}</option>
              </select>
              <div *ngIf="f.type.invalid && (f.type.dirty || f.type.touched)" class="alert alert-danger">
                <div *ngIf="f.type.errors.required">
                  {{'Role Type is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="tiltle-bar form-group">
            <div class="row align-items-center">
              <div class="col-lg-9">
                <label for="Item Status">{{'Allow Full Access' | translate }}</label>
                <div>{{'Grant the user all access in the POS' | translate }}&nbsp;{{f.type.value}}</div>
              </div>
              <div class="col-3 text-right">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="fullAccess" formControlName="fullAccess">
                  <!--<label class="form-check-label" for="fullAccess"></label>-->
                </div>
              </div>
            </div>
          </div>
          <p>{{'Permissions' | translate }}</p>
          <section class="tile" *ngIf="f.fullAccess.value === true ? false:true">
            <a class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let accessList of accessLists" (click)="individualRole(accessList)">
              <div class="row">
                <div class="col-1">
                  <img [src]="accessList.icon" *ngIf="accessList.icon"/> 
                  <img src="/assets/images/icons/categories.svg" *ngIf="!accessList.icon"/>
                </div>
                <div class="col-8">
                  <span class="mb-1">{{accessList.formName | translate}}</span>
                </div>
                <div class="col-3">
                  <span class="mb-1">{{((accessList.access == true)?"Active" : "Off" ) | translate }}</span>
                </div>
              </div>
            </a>
          </section>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Type">{{'Select Color Tag' | translate }}</label>
              <div class="color-options">
                <div *ngFor="let color of colorOptions;" class="box {{color}}" role="button">
                  <span (click)="colorSelection(color)">
                    <img src="../../../assets/images/icons/WhiteTick.svg" *ngIf="f.cssClass.value == color" class="position-absolute ms-2" style="margin-top:10px"/>
                  </span>
                </div>
              </div>
              <div *ngIf="f.cssClass.invalid && (f.cssClass.dirty || f.cssClass.touched)" class="alert alert-danger">
                <div *ngIf="f.cssClass.errors.required">
                  {{'Color is Required' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
