<main class="views container">
  <div class="page">


    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Sales Transaction Type' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">

            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
            </div>


          </div>
        </div>
      </div>
    </div>


    <div class="page-body">
      <div class="preview-filter-data" dir="ltr">{{selectedReport?.dateRange}}</div>
      <div class="preview-filter-data hide-mobile"  dir="ltr">{{selectedReport?.locations}}</div>
      <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">

        <div class="tile-body p-0 table-responsive ">
          <table class="table table-striped table-custom">
            <thead>
              <tr class="table-header">
                <th width="50%" sortable="PaymentMode" (sort)="onSort($event)">{{'Payment Type' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="50%" sortable="PaymentMethod" (sort)="onSort($event)">{{'Total Pay' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data$ | async">
                <td> <div class="item-title"> {{item.PaymentMode | translate}}</div> </td>
                <td>  {{item.TotalPay}} </td>
              </tr>
              <tr>
                <td> <div class="item-title"> {{'Total Amount' | translate}}</div> </td>
                <td> {{totalAmount$ | async | number:'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row p-3">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{(total$ | async)}}</strong>
            {{'results' | translate }}
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
