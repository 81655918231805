import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: string) {
    this.hideChatBot("none");
    this.isPrinting = true;
    this.router.navigate([
      "/",
      {
        outlets: {
          print: ["print", documentName, documentData]
        }
      }
    ]);
  }

  printDocumentWithData(documentName: string, documentData: string, state: any) {
    this.isPrinting = true;
    this.router.navigate([
      "/",
      {
        outlets: {
          print: ["print", documentName, documentData]
        }
      }
    ],
      { state: state }
    );
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
      this.hideChatBot("block");
    }, 2000);
  }
  hideChatBot(prop: string) {
    try {
      document.getElementById("zsiq_float").style.display = prop;
    } catch (e) {

    }
  }
}
