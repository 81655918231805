<main class="views container">
  <div class="page-header tabheader">
    <!-- <div class="breadcrumb">
      Inventory
  </div> -->
    <!-- <div class="title"> -->
    <!-- <span> {{(active=="supplier" ?"Suppliers":active=="recipe"?"Recipes":active=="ingredient"?"Ingredients":active=="alert" ?"Alerts":active=="group"?"Groups": "-") | translate }}</span>-->
    <span> {{'Inventory Setup' | translate }}</span>
    <!-- </div> -->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">

          <li ngbNavItem="alert" id="alert" *hasPermission="['Inventory','Inventory Setup','Alerts']" (click)="active='alert';">
            <a ngbNavLink>{{'Alerts' | translate }}  </a>
            <ng-template ngbNavContent>
              <app-inventory-alert [LogEventType]="'screen'" [LogEvent]="'Inventory Alert'"></app-inventory-alert>
            </ng-template>
          </li>
          <li ngbNavItem="supplier" id="supplier" *hasPermission="['Inventory','Inventory Setup','Supplier']" (click)="active='supplier';">
            <a ngbNavLink>{{'Suppliers' | translate }}  </a>
            <ng-template ngbNavContent>
              <app-supplier [LogEventType]="'screen'" [LogEvent]="'Supplier'"></app-supplier>
            </ng-template>
          </li>
          <li ngbNavItem="ingredient" id="ingredient" *hasPermission="['Inventory','Inventory Setup','Ingredient']" (click)="active='ingredient';">
            <a ngbNavLink>{{'Ingredients' | translate }} </a>
            <ng-template ngbNavContent>
              <app-ingredient [LogEventType]="'screen'" [LogEvent]="'Ingredient'"></app-ingredient>
            </ng-template>
          </li>
          <li ngbNavItem="recipe" id="recipe" *hasPermission="['Inventory','Inventory Setup','Recipes']" (click)="active='recipe';">
            <a ngbNavLink>{{'Recipes' | translate }} </a>
            <ng-template ngbNavContent>
              <app-recipe [LogEventType]="'screen'" [LogEvent]="'Recipes'"></app-recipe>
            </ng-template>
          </li>



          <li ngbNavItem="group" id="group" *hasPermission="['Inventory','Inventory Setup','Groups']" (click)="active='group';">
            <a ngbNavLink>{{'Inventory Groups' | translate }} </a>
            <ng-template ngbNavContent>
              <app-group [LogEventType]="'screen'" [LogEvent]="'Groups'"></app-group>
            </ng-template>
          </li>



        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
