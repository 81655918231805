import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-uninstall-modal-confirmation',
  templateUrl: './uninstall-modal-confirmation.component.html',
  styleUrls: ['./uninstall-modal-confirmation.component.css']
})
export class UninstallModalConfirmationComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {}



cancel(){
  this.activeModal.dismiss();
}

accept(){
  this.activeModal.close(true);
}

  ngOnInit(): void {
  }

}
