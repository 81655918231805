export class TransferInventory {
  tsid: number;
  tsCode: string;
  referenceNo: string;
  fromLocationID: number;  
  fromLocationName: string;
  toLocationID: number;
  toLocationName: string;
  expectedDate: string;
  receivedDate: string;
  notes: string;
  date: string;
  status: string;
  brandID: number;
  statusID: number;
  selected: boolean;  
}

export class tsDetails {
  supplyID: number;
  type: string;
  name: string;
  alternateName: string;
  quantity: number;
  unit: string;
  approvedQty: number;
  receivedQty: number;
  cost: number;
  totalCost: number;
  unitID: number;
  statusID: number;
  itemImage: string;
  currentStock: number;
}
