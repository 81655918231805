<form [formGroup]="previewtransferReturnForm" class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title hide-mobile">{{'Transfer Return' | translate }} {{f.tsCode.value}}</h4>
    <div placement="bottom" [ngbPopover]="popContent" *ngIf="transferData?.returnStatusID !== 601 && transferData?.returnStatusID !== 603">
      <button class="btn btn-action m-0 me-2">
        {{'Action' | translate }}
      </button>
    </div>
    <ng-template #popContent>
      <p class="dropdown-heading-mini"> {{'RETURNS' | translate }}</p>
      <a (click)="selectedStatus('approve')">{{'Approve Transfer' | translate }}</a>
      <a (click)="selectedStatus('decline')">{{'Decline Transfer' | translate }}</a>
      <a class="selected-inner-bottom" *ngIf="f.statusID.value == 600|| f.statusID.value == 604" (click)="Edit(f)"><span class=" po-preview-action-text" style="color: #378E8D">{{'Edit Transfer' | translate }} </span></a>

    </ng-template>
    <button [disabled]="loading" class=" btn-save m-0" (click)="onSubmit()" *ngIf="transferData?.returnStatusID !== 601">
      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body ">
    <div class="container">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-1"></div>
        <div class="col-lg-8 mt-5">
          <div class="jumbotron po-preview-box">
            <div class="row form-group">
              <div class="col-lg-6 text-start">
                 <!-- <img src="/assets/images/marn-logo-po.png" alt="" /> -->
                 <img class="brandLogo" [src]="ls.getSelectedBrand().BrandLogo" alt="" />
              </div>
              <div class="col-lg-6 text-end">
                <h3 class="font-weight-bold ">{{'Transfer Return' | translate }}</h3>
                <h5 class=" ponum-heading ">{{f.tsCode.value}}</h5>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 text-start">
                <div [ngClass]="renderStatusClass(f.statusID.value)" class="btn btn-sm">
                  {{
(
 f.statusID.value == 600 ? 'Created' : f.statusID.value == 601 ? 'Approved' : f.statusID.value == 602 ? 'Pending': f.statusID.value == 603 ? 'Declined': f.statusID.value == 604 ? 'Hold' : f.statusID.value == 605 ? 'Received' : f.statusID.value == 605
                                    ? 'Returned' : '-') | translate
                  }}
                </div>
              </div>
              <div class="col-lg-6 text-end">
                <h6 class="font-weight-bold ">  {{'Ref' | translate }}# {{f.referenceNo.value}}</h6>
              </div>
            </div>
            <div class="my-4 divider"></div>
            <div class="row mb-5">
              <div class="col-lg-6 text-start">
                <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                <label>{{f.date.value |date}}</label>
              </div>
              <div class="col-lg-6 text-end">
                <h6 class="font-weight-bold ">{{'Expected Date' | translate }}</h6>
                <label>{{f.expectedDate.value | date}}</label>
              </div>
            </div>

            <div class="row  mb-5">
              <div class="col-lg-6 text-start">
                <h6 class="font-weight-bold po-preview-blue ">{{'From' | translate }}</h6>
                <h6 class="font-weight-bold ">{{f.fromLocationName.value}}</h6>
                <p>{{f.fromLocationAddress.value}}</p>
                <label>{{f.fromLocationContact.value}}</label>
              </div>
              <div class="col-lg-6 text-end">
                <h6 class="font-weight-bold po-preview-blue ">{{'Deliver to' | translate }}</h6>
                <h6 class="font-weight-bold ">{{f.toLocationName.value}}</h6>
                <p>{{f.toLocationAddress.value}}</p>
                <label>{{f.toLocationContact.value}}</label>
              </div>
            </div>
            <table class="table table-striped">
              <thead class="table-dark">
                <tr>
                  <th width="45%" scope="col">{{'Item Name' | translate }}</th>
                  <th width="15%" scope="col">{{'Quantity' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of arrItems">
                  <td scope="row">{{item.name}}</td>
                  <td>{{item.returnQuantity}} {{item.unit}}</td>
                </tr>

              </tbody>
            </table>

          </div>

        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>

</form>
