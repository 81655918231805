<main class="views container order-list" [ngClass]="{'lazy': (loading$ | async)}">
  <div class="page">
    <div class="page-header">
      <div class="tile-header tCustom-header device-header" *ngIf="service.devices?.length > 0">
        <div class="row">
          <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
            <h4>

              <a backLink>{{'Setup' | translate }} </a>&nbsp;/ {{'Devices' | translate }}

            </h4>
          </div>
          <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12  position-relative">
            <div class="top-bar-flex">
              <div class="filter-field">
                <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
              </div>
              <div class="create-btn">
                <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn ms-3" *hasPermission="['Setup','Devices', 'Insert']">
                  {{'+ Add Device' | translate }}
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
    <div class="page-body position-relative">
      <div *ngIf="(service.devices?.length > 0)">
        <div class="row">
          <div class="col-12">
            <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">

              <div class="tile-body p-0 table-responsive text-nowrap">
                <table class="table table-striped device table-custom text-start p-0">
                  <thead>
                    <tr class="table-header">
                      <th width="25%" sortable="terminalName" (sort)="onSort($event)">{{'Device Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
                      <th width="25%" sortable="code" (sort)="onSort($event)">{{'Device Code' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                      <th width="15%" sortable="refCode" (sort)="onSort($event)">{{'Ref Code' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
                      <th width="15%">

                        <!-- Device Type Filter -->
                        <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                          <button class="btn btn-outline-filter" id="ddlDeviceTypeFilter" ngbDropdownToggle>
                            <span class="text-center" style="width:100%"> {{'Device Type' | translate}} </span>
                            <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                          </button>
                          <div ngbDropdownMenu aria-labelledby="ddlDeviceTypeFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">

                            <div *ngFor="let item of DeviceTypeList; let i = index">
                              <div class="form-check" ngbDropdownToggle>
                                <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownDeviceType_{{i+1}}" [(ngModel)]="SelectedDeviceType" (change)="filters()">
                                <label class="form-check-label" for="dropdownDeviceType_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                                  {{item.name}}



                                </label>
                              </div>
                            </div>
                          </div>
                        </div>


                      </th>
                      <th width="20%">

                        <!-- Location Filter -->
                        <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                          <button class="btn btn-outline-filter" id="ddlLocationFilter" ngbDropdownToggle
                                  [ngClass]="{ 'active': setSelectedResult('location') > 0}">
                            <span>
                              <span class="badge" *ngIf="setSelectedResult('location')>0">{{setSelectedResult('location')}}</span>

                              {{'Location' | translate}}

                            </span>
                            <img class="down-aero" ngbDropdownOpen src="{{
                    (setSelectedResult('location') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'
                  }}">
                          </button>
                          <div ngbDropdownMenu aria-labelledby="ddlLocationFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                            <div>
                              <div class="form-check" ngbDropdownToggle>
                                <input type="checkbox" class="form-check-input" id="dropdownLocation_all" (change)="selectAllFilters($event,Locations)">
                                <label class="form-check-label font-blue" for="dropdownLocation_all" (click)="selectAllFilters($event,Locations)">
                                  {{'All Locations' | translate}}
                                </label>
                              </div>
                            </div>
                            <div *ngFor="let location of Locations; let i = index">
                              <div class="form-check" ngbDropdownToggle>
                                <input type="checkbox" class="form-check-input" id="dropdownLocation_{{i+1}}" [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                                <label class="form-check-label" for="dropdownLocation_{{i+1}}" (click)="filters()">
                                  {{location.name}}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>


                      </th>
                      <th width="10%">

                        <!-- Status Filter -->
                        <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                          <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                            <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                            <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                          </button>
                          <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                     name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                              <label class="form-check-label font-blue" for="dropdownStatus_all"
                                     (click)="selectAllFilters($event,StatusList)">
                                {{'All Status' | translate}}
                              </label>
                            </div>
                            <div *ngFor="let item of StatusList; let i = index">
                              <div class="form-check" ngbDropdownToggle>
                                <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                                <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                                  <!-- {{item.name}} -->

                                  <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                                 class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                                  </ngb-highlight>


                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                      </th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of data$ | async " editRow>
                      <td (click)="Edit(item)" >
                        <div class="">{{item.terminalName}}</div>
                        <!-- class="item-title" -->
                      </td>
                      <td (click)="Edit(item)" >
                        <ngb-highlight [ngClass]="{'btn-active': item.isUsed === false, 'btn-inactive':item.isUsed == true }" class="btn btn-lg w-auto device-code" [result]="item.code" [term]="service.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td (click)="Edit(item)">{{item.refCode}}</td>
                      <td (click)="Edit(item)">{{item.deviceType}}</td>
                      <td (click)="Edit(item)">{{item.locationName}}</td>
                      <td>
                        <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                        </ngb-highlight>
                      </td>
                      <td>


                        <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                          <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                        </div>
                        <ng-template #popContent >
                          <p class="dropdown-heading-mini">{{'DEVICES' | translate }}</p>
                          <a *hasPermission="['Setup','Devices', 'Edit']" (click)="Edit(item)">{{'Edit' | translate }}</a>
                          <a *hasPermission="['Setup','Devices', 'Unlink']" (click)="Unlink(item)">{{'Unlink Device' | translate }}</a>
                          <ng-container *ngIf="item.statusID === 1">
                            <a *hasPermission="['Setup','Devices', 'Deactivate']" (click)="SetStatus(item)">{{'Deactivate' | translate }}</a>
                          </ng-container>
                          <ng-container *ngIf="item.statusID === 2">
                            <a *hasPermission="['Setup','Devices', 'Deactivate']" (click)="SetStatus(item)">{{'Activate' | translate }}</a>
                          </ng-container>



                        </ng-template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <div class="row mt-10" *ngIf="((data$ | async).length > 0)">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <p class="pagination-count">
                  {{'Showing' | translate }}
                  <strong>{{(service.pageSize * service.page)-9}}</strong>{{'to' | translate }}
                  <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>{{'of' | translate }}
                  <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
                </p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
                  <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
                  <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
                  <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
                  <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="(service.devices?.length == 0) && ((data$ | async).length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Devices yet' | translate }}</strong>
          <p class="text-center width-375">{{'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.' | translate}}</p>
        </div>
        <div class="items-flex">
          <div class="create-btn mlmr-10">
            <div>
              <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
                {{'+ Create New Device' | translate }}
              </button>
            </div>
          </div>


        </div>
      </div>



      <div *ngIf="(service.devices?.length > 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Record Found' | translate }}</strong>
          <p class="text-center width-375">{{'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.' | translate}}</p>
        </div>
      </div>






    </div>
  </div>
</main>
