<main class="views container">
  <div class="page">


    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Refund Report' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">

            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">

              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                  <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <p class="dropdown-heading-mini">{{'Export' | translate }}</p>
                  <button ngbDropdownItem (click)="export('csv' ,allData$)">CSV</button>
                  <button ngbDropdownItem (click)="export('xlsx',allData$)">Excel</button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>




    <div class="page-body">

      <div class="preview-filter-data" dir="ltr">{{selectedReport.dateRange}}</div>
      <div class="preview-filter-data" dir="ltr">{{selectedReport.locations}}</div>

      <section class="tile" style="margin-top: 20px">

        <div class="tile-body p-0 table-responsive hrzScroll">
          @if(service.hasReportVersionV2  === false)
          {

          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <th width="10%" sortable="OrderNo" (sort)="onSort($event)">{{'Order' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="Location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="OrderTaker" (sort)="onSort($event)">{{'Order Taker' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="Reasons" (sort)="onSort($event)">{{'Reasons' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="Type" (sort)="onSort($event)">{{'Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="12%" sortable="Total" (sort)="onSort($event)">{{'Total' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="RefundTotal" (sort)="onSort($event)">{{'Refund Total' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="Date" (sort)="onSort($event)">{{'Date' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>

              </tr>
            </thead>
            <tbody>
              @for (item of data$ | async; track item.OrderNo) {
              <tr>
                <td>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                </td>
                <td>  {{item.OrderNo}} </td>
                <td> {{item.Location }} </td>
                <td> {{item.OrderTaker}} </td>
                <td> {{item.Reasons}} </td>
                <td> {{item.Type | translate}} </td>
                <td> {{item.TotalAmount | number:'1.2-2'}} </td>
                <td> {{item.RefundAmount | number:'1.2-2'}} </td>
                <td> {{item.BusinessDate | date}} </td>
              </tr>
              }
            </tbody>
          </table>
          }
          @else {
          <table class="table table-striped" *ngIf="service.hasReportVersionV2">
            <thead>
              <tr class="table-header">
                <th width="5%">
                  <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" />
                </th>
                <th width="10%" sortable="ItemName" (sort)="onSort($event)">{{'Item Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="OrderNo" (sort)="onSort($event)">{{'Order Number' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="Location" (sort)="onSort($event)">{{'Location' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="OrderTaker" (sort)="onSort($event)">{{'User' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="OrderType" (sort)="onSort($event)">{{'Order Type' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="Reason" (sort)="onSort($event)">{{'Reasons' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="15%" sortable="RefundAmount" (sort)="onSort($event)">{{'Refunded' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%" sortable="BusinessDate" (sort)="onSort($event)">{{'Date' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>

              </tr>
            </thead>
            <tbody>


              @for (item of data$ | async; track item.OrderNo) {
              <tr>
                <td>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="item.selected" />
                </td>
                <td>{{ item.ItemName }}</td>
                <td>{{ item.OrderNo }}</td>
                <td>{{ item.Location }}</td>
                <td>{{ item.OrderTaker }}</td>
                <td>{{ item.OrderType }}</td>
                <td>{{ item.Reason }}</td>
                <td>{{ item.RefundAmount | number:'1.2-2' }}</td>
                <td>{{ item.BusinessDate | date }}</td>
              </tr>
              }
              @if(service.totalRefundedRows){
              <tr class="total-row">
                <td></td>
                <td>
                  <label class="total-title fw-bold font-15">{{ 'Total of' | translate }} <strong> {{ (total$ | async)! }}</strong> {{ 'rows' | translate }}</label>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ service.totalRefundedRows | number: '1.2-2' }}</td>
                <td></td>
              </tr>
              }
            </tbody>
           

          </table>
          }
        </div>

      </section>
      <div class="row mt-10">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <p class="pagination-count">
            {{'Showing' | translate }}
            <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
            <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>&nbsp;{{'of' | translate }}
            <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</main>
