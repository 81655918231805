
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { AlertService } from 'src/app/_services/alert.service';
import { InventoryLogReportService } from 'src/app/_services/inventorylog.report.service';
import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import * as $ from 'jquery';
import { LocalStorageService } from '../../_services/local-storage.service';

@Component({
  selector: 'app-print-report-stock',
  templateUrl: './print-report-stock.component.html',
  styleUrls: ['./print-report-stock.component.css']
})
export class PrintReportStockComponent implements OnInit {


  loading$: Observable<boolean>;
  selectedReport: any;
  selectedBrand: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: InventoryLogReportService,
    private router:Router,
    private printService: PrintService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService) {
    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
    this.selectedBrand = this.localStorageService.getSelectedBrand();
  }

  ngOnInit() 
  {
   //setTimeout(() => {
   this.getReportData(this.selectedReport.dataURL);

   //}, 1000);
  }

  
  InvStock : any;
  getReportData(dataURL) {

    let _this = this;
    $.ajax({
      method: 'GET',
      url:  dataURL,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if(res){
        _this.InvStock = res;
          console.log(_this.InvStock);
          _this.printService.onDataReady();
      
        }else{
          
        }
      }
      ,error: function (data:any) {
          console.log(data);
      }
  })

    
    
  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
  
  }


}
