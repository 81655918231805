
<div class="modal-body ">
    <div class="container">
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 mt-5">
                <div class="jumbotron">
                    <div class="row form-group">
                        <div class="col-lg-6 text-start"> 
                            
                            <img [src]="selectedBrand.BrandLogo" style="width:50px" />
                        
                        </div>
                        <div class="col-lg-6 text-end" style="margin-top:10px">
                            <h3 class="font-weight-bold ">{{selectedBrand.BrandName}}</h3>
                            
                          </div>


                        <div class="col-lg-6 text-end" style="margin-top: 30px;">
                          <h3 class="font-weight-bold ">{{'Reconciliation' | translate }}</h3>
                            <h5 class=" ponum-heading ">{{Reconciliation?.rcnCode}}</h5>
                        </div>
                    </div>

                    <div class="row">
                      
                        <div class="col-lg-6 text-start">
                          <h6 class="font-weight-bold ">{{'Ref' | translate }}# {{Reconciliation.referenceNo}}</h6>
                        </div>
                    </div>
                    <div class="my-4 divider"></div>
                    <div class="row mb-5">
                        <div class="col-lg-6 text-start">
                          <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                            <label>{{Reconciliation?.date | date:'dd/MM/yyyy - hh:mm: a '}}</label>
                        </div>
                       
                    </div>

                    <div class="row  mb-5">
                        <div class="col-lg-6 text-end">
                          <h6 class="font-weight-bold po-preview-blue ">{{'Location' | translate }}</h6>
                            <h6 class="font-weight-bold ">{{Reconciliation.locationName}}</h6>
                        </div>
                    </div>
                 
                    <div class="tile">
                    <table class="table table-striped">
                        <thead class="thead-dark">
                            <tr>
                              <th width="50%" scope="col">{{'Item Name' | translate }}</th>
                              <th width="25%" scope="col">{{'Cost/Unit' | translate }}</th>
                              <th width="25%" scope="col">{{'Reason' | translate }}</th>
               
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Reconciliation?.rcnDetails">
                                <td scope="row">{{item.name}}</td>
                                <td>    {{item.quantity}} ({{item.cost}} {{'SAR' | translate }})</td>
                                <td>{{item.reason}}</td>
                             
                            </tr>

                          
                        </tbody>
                    </table>
                </div>
                </div>

            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
</div>
