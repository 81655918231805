<main class="mnt-header position-relative ingredient order-list product-list">

  <div class="row flex-row-reverse mb-4 tCustom-header margin-top-minus-30" *ngIf="(service.ingredient?.length > 0)">

    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Ingredients' | translate }} </h5>
    </div>

    <div class="col-md-auto" *hasPermission="['Inventory','Inventory Setup','Ingredient', 'Insert']">
      <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
        {{'+ New Ingredient' | translate }}
      </button>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Inventory','Inventory Setup','Ingredient', 'Export']">

          <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-auto">
      <div class="import-sec">
        <div class="" *hasPermission="['Inventory','Inventory Setup','Ingredient', 'Export']">
          <div ngbDropdown class="nav-item" placement="bottom">
            <button class="profile btn export-btn btn-lg form-control" (click)="import()">
              <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>



  <div *ngIf="(service.ingredient?.length > 0)">
    <div class="position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped text-start">
            <thead>
              <tr class="table-header">
                <!-- <th width="5%"> <input type="checkbox" (change)="bulkSelection($event)" /></th> -->

                <th sortable="name" (sort)="onSort($event)">{{'Ingredients' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>

                <th sortable="group" (sort)="onSort($event)">{{'Inventory Group' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="sku" (sort)="onSort($event)">{{'SKU' | translate }}  <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th sortable="supplierCount" (sort)="onSort($event)"> {{'Suppliers' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>



                  <!-- Unit Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlUnitFilter" ngbDropdownToggle>
                      <span>{{SelectedUnits | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlUnitFilter dropdown-header-filter-body"
                         class="dropdown-header-filter-body ddl-width-sm">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" [(ngModel)]="SelectedUnits" value="All Units"
                                 name="UnitOption" id="dropdownUnit_all" (change)="selectAllFilters($event,UnitList)">
                          <label class="form-check-label font-blue" for="dropdownUnit_all"
                                 (click)="selectAllFilters($event,GenderList)">
                            {{'All Units' | translate}}
                          </label>
                        </div>
                      </div>
                      <div *ngFor="let Unit of UnitList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="UnitOption" [value]="Unit.name"
                                 id="dropdownUnit_{{i+1}}" [(ngModel)]="SelectedUnits" (ngModelChange)="filters()">
                          <label class="form-check-label" for="dropdownUnit_{{i+1}}" (click)="filters()">
                            {{Unit.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                </th>
                <th class="text-center">

                  <!-- Status Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                      <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                               name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                        <label class="form-check-label font-blue" for="dropdownStatus_all"
                               (click)="selectAllFilters($event,StatusList)">
                          {{'All Status' | translate}}
                        </label>
                      </div>
                      <div *ngFor="let item of StatusList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                          <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                            <!-- {{item.name}} -->

                            <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                           class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                            </ngb-highlight>


                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                </th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ingredient of data$ | async " editRow>
                <!-- <td style="width: 10%">
                    <input type="checkbox" [(ngModel)]="ingredient.selected" />
                </td> -->
                <td (click)="Edit(ingredient)" class="name-blue"> {{ingredient.name}} </td>
                <td (click)="Edit(ingredient)"> {{ingredient.group}} </td>
                <td (click)="Edit(ingredient)"> {{ingredient.sku}} </td>
                <td (click)="Edit(ingredient)"> {{ingredient.supplierCount}} </td>
                <td (click)="Edit(ingredient)"> {{ingredient.unit}} </td>
                <td (click)="Edit(ingredient)" class="text-center">
                  <ngb-highlight [ngClass]="{'btn-active': ingredient.statusID === 1, 'btn-inactive':ingredient.statusID !== 1 }" class="btn btn-sm" [result]="(ingredient.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                  </ngb-highlight>
                </td>
                <td style="width: 5%">
                  <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                    <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                  </div>
                  <ng-template #popContent>
                    <p class="dropdown-heading-mini">{{'Ingredients' | translate }}</p>
                    <a *hasPermission="['Inventory','Inventory Setup','Ingredient', 'Edit']" (click)="Edit(ingredient)">{{'Edit' | translate }}</a>
                    <ng-container *ngIf="ingredient.statusID === 1">
                      <a *hasPermission="['Inventory','Inventory Setup', 'Ingredient', 'Delete']" (click)="SetStatus(ingredient)">{{'Deactivate' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="ingredient.statusID === 2">
                      <a *hasPermission="['Inventory','Inventory Setup', 'Ingredient', 'Delete']" (click)="SetStatus(ingredient)">{{'Activate' | translate }}</a>
                    </ng-container>
                    <a *hasPermission="['Inventory','Inventory Setup','Ingredient', 'Delete']" (click)="openConfirmationDialog(ingredient.ingredientID, ingredient.rowVersion,ingredient.name)" class="selected-inner-text selected-inner-bottom">{{'Delete' | translate }}</a>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <div class="row mt-10" *ngIf="((data$ | async)?.length > 0)">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong> {{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong> {{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>

  <div *ngIf="(service.ingredient?.length == 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Ingredient yet' | translate }}</strong>
      <p class="text-center width-375">{{'Create New ingredient.' | translate}}</p>
    </div>
    <div class="items-flex">
      <div class="create-btn mlmr-10">
        <div>
          <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
            {{'+ Create New ingredient' | translate }}
          </button>
        </div>
      </div>


    </div>
  </div>



  <div *ngIf="(service.ingredient?.length > 0) && ((data$ | async)?.length == 0)">
    <div class="blank-order">
      <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
      <strong>{{'No Record Found' | translate }}</strong>
      <p class="text-center width-375">{{'Create ingredient for item.' | translate}}</p>
    </div>
  </div>


</main>
