import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferreturnService } from '../../../_services/transferreturn.service';
import { AlertService } from '../../../_services/alert.service';
import { TransferInventoryService } from '../../../_services/transfer-inventory.service';

@Component({
  selector: 'app-preview-transfer-return',
  templateUrl: './preview-transfer-return.component.html',
  styleUrls: ['./preview-transfer-return.component.css']
})
export class PreviewTransferReturnComponent implements OnInit {
  @Input() SelectedID = 0;
  previewtransferReturnForm: UntypedFormGroup;
  ButtonText = "Save";
  loading= false;
  arrItems: any[] = [];
  transferData: any;
  constructor(private formBuilder: UntypedFormBuilder,
    public ls: LocalStorageService,
    public activeModal: NgbActiveModal,
    public service: TransferreturnService,
    private alertService: AlertService,
    private transferService: TransferInventoryService) {
    this.createForm();
  }
  ngOnInit() {
    this.setSelectedTransferReturn();
  }
  get f() { return this.previewtransferReturnForm.controls; }

  private createForm() {

    this.previewtransferReturnForm = this.formBuilder.group({
      tsCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      tsid: 0,
      expectedDate: [],
      date: [],
      returnDate:[],
      fromLocationID: 0,
      fromLocationName: [''],
      fromLocationContact: [''],
      fromLocationAddress: [''],
      toLocationID: 0,
      toLocationName: [''],
      toLocationContact: [''],
      toLocationAddress: [''],
      rowVersion: 0,
      tsDetails: [],
      taxes: []
    });
  }



  close() {
    this.activeModal.dismiss();
  }
  renderStatusClass(obj) {

    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"

    }
  }
  setLocations(fromLocationID, toLocationID) {
    this.transferService.getLocationsById(fromLocationID).subscribe((res: any) => {
      this.f.fromLocationID.setValue(res.locationID);
      this.f.fromLocationName.setValue(res.name);
      this.f.fromLocationAddress.setValue(res.address);
      this.f.fromLocationContact.setValue(res.contactNo);
    });
    this.transferService.getLocationsById(toLocationID).subscribe((res: any) => {

      this.f.toLocationID.setValue(res.locationID);
      this.f.toLocationName.setValue(res.name);
      this.f.toLocationAddress.setValue(res.address);
      this.f.toLocationContact.setValue(res.contactNo);
    });
  }
  setSelectedTransferReturn() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loading = true;
      this.f.tsid.setValue(this.SelectedID);
      this.service.getReturnTransfer(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.transferData = res;
        this.loading = false;
      });
    }
  }
  private editForm(transferReturn) {
    debugger;
    this.f.tsCode.setValue(transferReturn.tsCode);
    this.f.date.setValue(transferReturn.date);
    this.f.returnDate.setValue(transferReturn.date);
    this.f.expectedDate.setValue(transferReturn.expectedDate);
    this.f.referenceNo.setValue(transferReturn.referenceNo);
    this.f.tsDetails.setValue(transferReturn.tsDetails);
    this.arrItems = transferReturn.tsDetails;

    this.f.notes.setValue(transferReturn.notes);
    this.f.rowVersion.setValue(transferReturn.rowVersion);
    this.f.statusID.setValue(transferReturn.returnStatusID);
    this.f.tsid.setValue(transferReturn.tsid);

    this.setLocations(transferReturn.fromLocationID, transferReturn.toLocationID);

    //if (this.f.statusID.value == 605 || this.f.statusID.value == 601 || this.f.statusID.value == 606) {
    //  this.isShowActions = false;
    //}
  }
  selectedStatus(status) {

    if (status == "approve") {
      this.f.statusID.setValue(601);
    }
    else if (status == "decline") {
      this.f.statusID.setValue(603);
    }
  }
  onSubmit() {
    this.setStatus();
  }


  setStatus() {
    this.service.update(this.previewtransferReturnForm.value).subscribe((res: any) => {
      this.alertService.success("Purchase return has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }
}
