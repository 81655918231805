
import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-previewreport',
  templateUrl: './previewreport.component.html'
})
export class PreviewreportComponent implements OnInit {
  
  constructor( ){}

  ngOnInit() {
  }


}
