<main class="position-relative views container accounts-summary-report">
  <div class="row form px-2" *ngIf="trial?.ShowBanner || trial?.IsExpired">
    <div class="form-group col-lg-12">
      <div class="status-activebar row shadow">
        <div class="col-1 mt-1" style="width:55px">
          <label for="Status">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g style="mix-blend-mode:multiply">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 53.6668C41.7276 53.6668 53.6666 41.7278 53.6666 27.0002C53.6666 12.2726 41.7276 0.333496 27 0.333496C12.2724 0.333496 0.333313 12.2726 0.333313 27.0002C0.333313 41.7278 12.2724 53.6668 27 53.6668Z" fill="#FAF5FF" />
              </g>
              <g style="mix-blend-mode:multiply">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 47C38.0457 47 47 38.0457 47 27C47 15.9543 38.0457 7 27 7C15.9543 7 7 15.9543 7 27C7 38.0457 15.9543 47 27 47Z" fill="#F3E8FF" />
              </g>
              <g clip-path="url(#clip0_11064_338)">
                <path d="M27 31V27M27 23H27.01M37 27C37 32.5228 32.5228 37 27 37C21.4772 37 17 32.5228 17 27C17 21.4772 21.4772 17 27 17C32.5228 17 37 21.4772 37 27Z" stroke="#9333EA" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_11064_338">
                  <rect width="24" height="24" fill="white" transform="translate(15 15)" />
                </clipPath>
              </defs>
            </svg>
          </label>
        </div>
        <div class="col-11">
          <p class="inactive mb-0 mt-1 fw-bold" *ngIf="trial?.Access"> {{'Free Trial!' | translate}}</p>
          <p class="inactive mb-0 mt-1 fw-bold" *ngIf="trial?.IsExpired"> {{'Trial Expired!' | translate}}</p>
          <p class="inactive mb-0 text-transform-none"> {{'This feature will be on a free trial until' | translate}} {{ trial.EndDate | date:'dd MMMM'}}. {{'If you\'d like to continue using it after that period, please contact us at' | translate}} <span class="fw-bold">920006276</span>.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-row-reverse mb-3 custom-action-header margin-top-minus-10 align-items-center px-2">
    <div class="col-md-text-start hide-mobile">
      <h5 style="line-height: 45px; font-size: 17px !important;">  {{'Summary' | translate }} </h5>
    </div>
    <div class="col-md-auto">
      <div class="">
        <div ngbDropdown class="nav-item" placement="bottom" *hasPermission="['Analytics','Accounting','Summary', 'Export']">
          <button class="profile export-btn btn-lg" id="dropdownBasic1" ngbDropdownToggle [disabled]="loading">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
            <span class="text-dark ">{{'Export' | translate }} </span> &nbsp; <img src="../../../../../assets/images/icons/v2/export.svg" class="mr-1" />
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
            <button ngbDropdownItem (click)="export('csv')">CSV</button>
            <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-auto">
      <div class="p-0" *hasPermission="['Analytics','Accounting','Summary', 'Filter']">
        <ngbd-datepicker-range [FromDateToBeStartOfMonth]="true" (OnHit)="trial?.Access && loadReport()"></ngbd-datepicker-range>
      </div>
    </div>
    <div class="col-md-auto">
      <div class="p-0" *hasPermission="['Analytics','Accounting','Summary', 'Filter']">
        <div ngbDropdown>
          <a ngbDropdownToggle class="form-control text-center selected-location" style=" text-decoration: auto; color: #000; width: fit-content !important">
            <span>{{selectedLocationName | translate}}</span>
            <img class="down-aero" src="assets/images/down-aero.svg">
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropaero-menu">
            <a class="dropdown-item" [hidden]="selectedLocationName == 'All Locations' " (click)="setLocation(null)">{{'All Locations' | translate}} </a>
            <a class="dropdown-item" *ngFor="let location of LocationsList" [hidden]="location.name == selectedLocationName"
               (click)="setLocation(location)">{{location.name}} </a>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="page-body">
    <section>
      <div class="row stats-card">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="accounts-summary-box fixheight relative" [ngClass]="{'lazy': (loading)}">
            <div class="content accounts-summary">
              <div class="tabtitle">
                {{'Income Statement' | translate }}
              </div>
              <ul class="extra-padding-min">
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75">{{'Sales With Taxes' | translate}}</div>
                    <div class="title-right"> {{incomeStatement?.salesWithTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75">{{'Sales deductibles with Taxes' | translate}}</div>
                    <div class="title-right font-red">{{incomeStatement?.salesDeductibleWithTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75"> {{'Net Sales with Taxes' | translate}}</div>
                    <div class="title-right"> {{incomeStatement?.netSalesWithTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75"> {{'Sales Taxes' | translate}}</div>
                    <div class="title-right">
                      <img src="../../../assets/images/Info.svg" placement="bottom" [ngbTooltip]="taxSummary" />
                      &nbsp;
                      <span>
                        {{incomeStatement?.salesTaxes | number:'1.2-2'}}
                      </span>
                      <ng-template #taxSummary>
                        <ng-container *ngFor="let tax of taxes">
                          <label>
                            {{selectedLang=='en'?tax.name:tax.arabicName}} = {{tax?.amount| number:'1.2-2'}}
                          </label>
                        </ng-container>
                      </ng-template>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75"> {{'Net Sales without Taxes' | translate}}</div>
                    <div class="title-right">{{incomeStatement?.netSalesWithoutTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75"> {{'Sales Cost without Taxes' | translate}}</div>
                    <div class="title-right font-red">{{incomeStatement?.salesCostWithoutTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75"> {{'Total Sales Profit without Taxes' | translate}}</div>
                    <div class="title-right">{{incomeStatement?.totalSalesProfitWithoutTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between">
                    <div class="title-left w-75"> {{'Total Expenses without Taxes' | translate}}</div>
                    <div class="title-right">{{incomeStatement?.totalExpensesWithoutTaxes | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between fw-bold">
                    <div class="title-left w-75"> {{'Net Profit without Taxes' | translate}}</div>
                    <div class="title-right">{{incomeStatement?.netProfitWithoutTaxes | number:'1.2-2'}} {{brandCurrency}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="accounts-summary-box fixheight relative" [ngClass]="{'lazy': (loading)}">
            <div class="content accounts-summary">
              <div class="tabtitle">
                {{'VAT Declaration' | translate }}
              </div>
              <ul class="extra-padding-min">
                <li class="border-0 py-1">
                  <div class="d-flex justify-content-between grayBox px-2">
                    <div class="secondary-label font-15 float-start">{{'Sales VAT' | translate}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between px-2">
                    <div class="title-left w-75">{{'Standard VAT Sales' | translate}} ({{VATDeclaration?.salesVATPercentage}}%)</div>
                    <div class="title-right"> {{VATDeclaration?.vatNetSales | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li class="border-0">
                  <div class="d-flex justify-content-between px-2">
                    <div class="title-left w-75">{{'Sales VAT Amount' | translate}}</div>
                    <div class="title-right">{{VATDeclaration?.salesVAT | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li class="border-0 py-1">
                  <div class="d-flex justify-content-between grayBox px-2">
                    <div class="secondary-label font-15 float-start">{{'Purchases & Expenses VAT' | translate}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between px-2">
                    <div class="title-left w-75"> {{'Standard VAT Purchases & Expenses' | translate}} ({{VATDeclaration?.pevatPercentage}}%)</div>
                    <div class="title-right"> {{VATDeclaration?.sumVATPurchasesAndExpenses | number:'1.2-2'}}</div>
                  </div>
                </li>
                <li>
                  <div class="d-flex justify-content-between px-2">
                    <div class="title-left w-75"> {{'Purchases  & Expenses VAT Amount' | translate}}</div>
                    <div class="title-right">{{VATDeclaration?.vatPurchasesAndExpenses | number:'1.2-2'}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
