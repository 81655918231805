
import { DecimalPipe } from '@angular/common';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationService } from 'src/app/_services/location.service';
import { Location } from 'src/app/_models/Location';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as myGlobals from '../../_helpers/globals';


@Component(
  {
    selector: 'app-location',
    templateUrl: './location.component.html',
    providers: [LocationService]
  })
export class LocationComponent {
  locations$: Observable<Location[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  StatusList = [];
  CityList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: LocationService,
    private router: Router,
    private localStorageService: LocalStorageService) {
    LocationService.locationId = 0;
    service.getlocationsByBrand();

    this.locations$ = service.locations$;
    this.total$ = service.total$;
    this.loading$ = service.loading$;

    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      this.CityList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });

        new Set(res.map(val => val.city)).forEach(e => {
          this.CityList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  get industryURLName() {
    return myGlobals.industryName(this.localStorageService);
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }


  New(){
    //DataService.Id = 0;
    //DataService.Name = "";
    this.router.navigateByUrl(this.industryURLName +'/location/add');
  }
  Edit(id) {
    ///restaurant/location/edit
    //DataService.Id = id;
    //DataService.Name = name;
    this.service.setLocationId(id);
    this.router.navigateByUrl(this.industryURLName+'/location/edit');
  }
  EditbyType(id,type) {
    this.service.setLocationId(id);
    this.router.navigate([this.industryURLName +"/location/edit", type]);
  }

  SelectedStatus = '';
  SelectedCity = "All Cities";
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      city: (this.SelectedCity == 'All Cities') ? this.CityList.map(function (k) { return k.name }) : [this.SelectedCity]
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }
}
