<main class="views container">
    <div class="page-header tabheader">
        <!-- <div class="breadcrumb">
            Inventory
        </div> -->
        <!-- <div class="title"> -->
      <span> {{(active=="summary" ?"Summary":active=="inventory"?"Inventory":active=="itemssales"?"Item Sales": "-") | translate }}</span>
        <!-- </div> -->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
                    <li ngbNavItem="summary" id="summary" *hasPermission="['Analytics','Summary','Summary Tab']" (click)="active='summary';">
                      <a ngbNavLink>{{'Summary' | translate }}  </a>
                        <ng-template ngbNavContent>
                           <app-sales-summary-report [LogEventType]="'screen'" [LogEvent]="'Sales Summary'"></app-sales-summary-report>
                        </ng-template>
                    </li>
                    <li ngbNavItem="inventory" id="inventory" *hasPermission="['Analytics','Summary','Inventory Tab']" (click)="active='inventory';">
                      <a ngbNavLink>{{'Inventory Alerts' | translate }} </a>
                        <ng-template ngbNavContent>
                          <app-inventory-alert [LogEventType]="'screen'" [LogEvent]="'Inventory Alert'"></app-inventory-alert>
                        </ng-template>
                    </li>
                    <li ngbNavItem="itemssales" id="itemssales" *hasPermission="['Analytics','Summary','Item Sales Tab']" (click)="active='itemssales';">
                      <a ngbNavLink>{{'Item Sales' | translate }} </a>
                      <ng-template ngbNavContent>
                        <app-salesmonitor [LogEventType]="'screen'" [LogEvent]="'Item Sales'"></app-salesmonitor>
                      </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="page">
        <div class="page-body">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</main>
