import { Component, OnInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { IntegrationService } from 'src/app/_services/Integration.service';
import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';
import { Router } from '@angular/router';
import * as myGlobals from '../../../_helpers/globals';


@Component({
  selector: 'app-grub-tech',
  templateUrl: './grub-tech.component.html',
  styleUrl: './grub-tech.component.css'
})
export class GrubTechComponent {

  @Input() PartnerID = 0;
  @Input() Partner: any;
  ButtonText = "Install";
  submitted = false;
  partnerForm: UntypedFormGroup;
  private selectedBrand;
  loading = false;
  Locations: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private services: IntegrationService,
    private _modalService: NgbModal,
    private router: Router
  ) {
    //this.Locations = this.localStorageService.getSelectedBrand().Locations?.filter(e => e.Name !== 'All Locations');
    
    this.createForm();

  }

  ngOnInit() {
    this.GetGrubTechLocations(0, this.PartnerID, this.f.brandID.value);
    this.setSelectedPartner();
  }

  Rules: any;
  GetPartnerClaim() {
    this.services.GetPartnerClaim(this.PartnerID).subscribe((res: any) => {
      this.Rules = res;

    });
  }
  GetIntegrationClaim(integrationid) {
    this.services.GetIntegrationClaim(integrationid).subscribe((res: any) => {
      this.Rules = res;

    });
  }
  GetGrubTechLocations(integrationid, partnerId, brandId) {
    this.services.GetGrubTechLocations(integrationid, partnerId, brandId).subscribe((res: any) => {
      this.Locations = res;
    });
  }



  private createForm() {
    this.partnerForm = this.formBuilder.group({
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      integrationID: [0],
      isInstall: [0],
      partnerID: [this.PartnerID]
    });
  }

  setSelectedPartner() {
    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getIntegration(this.f.brandID.value, this.PartnerID).subscribe((res: any) => {
        //Set Forms
        console.log(res);
        if (res !== null) {
          this.GetIntegrationClaim(res.integrationID);
          this.GetGrubTechLocations(res.integrationID, this.PartnerID, this.f.brandID.value);
          this.editForm(res);

          this.ButtonText = "Save";
        } else {
          this.GetPartnerClaim();
        }
        this.loading = false;
      });
    }
  }

  private editForm(value) {
    this.f.integrationID.setValue(value.integrationID);
    this.f.brandID.setValue(parseInt(this.localStorageService.getSelectedBrand().BrandID));
    //this.f.integratedLocationsIDsList.setValue(value.integratedLocationsIDsList);
    this.f.isInstall.setValue(value.isInstall);
    this.f.partnerID.setValue(value.partnerID);
  }

  get f() { return this.partnerForm.controls; }

  onSubmit() {
    if (!this.Locations.find(e => e.isIntegrated == true)) {
      this.alertService.error("Please select location");
      return;
    }

    this.partnerForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.partnerID.setValue(this.PartnerID);

    if (this.partnerForm.invalid) { return; }
    console.log(this.partnerForm.value)
    this.loading = true;

    let partner = {
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      integratedLocationsIDsList: this.Locations.filter(e => e.isIntegrated == true).map(e => e.locationID),
      isInstall: 1,
      partnerID: this.PartnerID
    }

    this.services.SaveGrubTech(partner).subscribe(data => {
      this.alertService.success("Integration partner has save successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });


  }


  close() {
    this.activeModal.dismiss();
  }

  uninstall() {
    this.loading = true;
    const partner = {
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      integratedLocationsIDsList: [],
      isInstall: 0,
      partnerID: this.PartnerID
    }
    this.services.SaveGrubTech(partner).subscribe(data => {
      this.alertService.success("Integration partner has uninstalled successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
      this.loading = false;
    });

  }

  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true, windowClass: 'uninstall-modal', modalDialogClass: 'uninstall-modal-custom' });

    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
      }
    });

  }

}
