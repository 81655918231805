<form [formGroup]="moyasarForm" class="form " >

    <div class="modal-header">
      <div class="d-flex" style="width: 100%;">

        <div class="d-flex align-items-start justify-content-start flex-1">
          <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div class="d-flex align-items-center justify-content-center flex-1">
          <h4 class="modal-title">
            {{'MOYASAR' | translate }}
          </h4>
        </div>


        <div class="d-flex align-items-end justify-content-end flex-1">
          <a class="btn-default btn-grey" href="https://moyasar.com/en/" target="_blank">
            {{'Learn More' | translate }}
          </a>
          <button [disabled]="loading" class="btn-primary btn-save" (click)="onSubmit()" [hidden]="f.paymentIntegrationID.value >0 && f.integrationStatus.value ==1">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ButtonText  | translate }}
          </button>
        </div>

      </div>
    </div>



    <div class="modal-body integration">
      <div class="container">
        <div class="row">

          <div class="col-lg-12" style="margin-top: 40px;">




            <div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <div class="logo-placeholder">
                  <img class="" src="assets/images/marketplace/moyasar.png" style="width: 350px;" alt="VOM">
                </div>
              </div>
            </div>

            <div class="line"></div>

            <h6 class="heading">{{'Setup' | translate }}</h6>

            <!--<div class="row">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="url">{{'API URL' | translate }}</label>
                <input type="text" class="form-control" id=url placeholder="" formControlName="url">
                <div *ngIf="f.url.invalid && (f.url.dirty || f.url.touched)" class="alert alert-danger">
                  <div *ngIf="f.url.errors.required">
                    {{'URL is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>-->

            <!--<div class="row" [hidden]="true">
              <div class="form-group col-lg-12 col-sm-12">
                <label for="user">{{'User Name' | translate }}</label>
                <input type="text" class="form-control" id=user placeholder="" formControlName="user">
                <div *ngIf="f.user.invalid && (f.user.dirty || f.user.touched)" class="alert alert-danger">
                  <div *ngIf="f.user.errors.required">
                    {{'User Name is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>-->

            <div class="row">
              <div class="form-group col-lg-12 col-sm-12 mt-10">
                <label for="key">{{'Live Secret Key' | translate }} </label>
                <textarea type="text" class="form-control" rows="2" style="height:unset" id=key placeholder="" formControlName="key"></textarea>
                <div *ngIf="f.key.invalid && (f.key.dirty || f.key.touched)" class="alert alert-danger">
                  <div *ngIf="f.key.errors.required">
                    {{'Password is required' | translate }}.
                  </div>
                </div>
              </div>
            </div>



            <ng-container *hasPermission="['Marketplace','Uninstall']">
              <div class="line" *ngIf="f.paymentIntegrationID.value >0 && f.integrationStatus.value ==1"></div>

              <h6 class="heading" *ngIf="f.paymentIntegrationID.value >0 && f.integrationStatus.value ==1">{{'Danger zone' | translate }}</h6>

              <div class="row" style="margin-top: 30px;" *ngIf="f.paymentIntegrationID.value >0 && f.integrationStatus.value ==1">
                <div class="col-6">
                  <label>{{'Uninstall this App' | translate }} </label>
                </div>
                <div class="col-6 text-right">
                  <a class="btn btn-danger btn-uninstall" (click)="openConformation()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{'Uninstall' | translate }}
                  </a>
                </div>

              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </form>
