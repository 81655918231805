<main class="views container customer-list order-list">
  <div class="page">

    <div class="page-body position-relative">
      <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
        <div class="title-header tCustom-header customer-header">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
              <h4>{{'Customers' | translate }} </h4>
            </div>
            <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
              <div class="top-bar-flex">
                <div class="filter-field">
                  <input class="form-control  search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
                </div>
                <ul class="nav nav-inline exp-parent">
                  <li>
                    <div ngbDropdown class="nav-item" placement="bottom">
                      <button class="profile btn export-btn exp-btn  btn-lg" id="dropdownBasic1" ngbDropdownToggle>
                        <img src="assets/images/icons/v2/export.svg" class="mr-1" /> <span class=" ">{{'Export' | translate }} </span>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
                        <button ngbDropdownItem (click)="export('csv')">CSV</button>
                        <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="create-btn" *hasPermission="['Analytics','Customers', 'New']">
                  <button type="button" (click)="open()" class="btn btn-primary cutom-button custom-create-btn">
                    {{'+ New Customer' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tile-body p-0 table-responsive text-nowrap">
          <table class="table table-striped ">
            <thead>
              <tr class="table-header">
                <th width="5%"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                <th width="12%" sortable="fullName" (sort)="onSort($event)">{{'Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"/></th>
                <th width="5%" sortable="age" (sort)="onSort($event)">{{'Age' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%">

                  <!-- Gender Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlGenderFilter" ngbDropdownToggle>
                      <span> {{SelectedGender | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlGenderFilter dropdown-header-filter-body ddl-width-sm"
                         class="dropdown-header-filter-body ddl-width-sm">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" [(ngModel)]="SelectedGender" value="All Genders"
                                 name="genderOption" id="dropdownGender_all" (change)="selectAllFilters($event,GenderList)">
                          <label class="form-check-label font-blue" for="dropdownGender_all"
                                 (click)="selectAllFilters($event,GenderList)">
                            {{'All Genders' | translate}}
                          </label>
                        </div>
                      </div>
                      <div *ngFor="let gender of GenderList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="genderOption" [value]="gender.name"
                                 id="dropdownGender_{{i+1}}" [(ngModel)]="SelectedGender" (change)="filters()">
                          <label class="form-check-label" for="dropdownGender_{{i+1}}" click="filters()">
                            {{gender.name | translate}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th width="10%" sortable="mobileNumber" (sort)="onSort($event)">{{'Phone' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
                <th width="10%" sortable="email" (sort)="onSort($event)">{{'Email' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th width="10%">

                  <!-- City Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlCityFilter" ngbDropdownToggle>
                      <span>{{SelectedCity | translate}} </span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlCityFilter dropdown-header-filter-body"
                         class="dropdown-header-filter-body ddl-width-sm">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" [(ngModel)]="SelectedCity" value="All Cities"
                                 name="cityOption" id="dropdownCity_all" (change)="selectAllFilters($event,CityList)">
                          <label class="form-check-label font-blue" for="dropdownCity_all"
                                 (click)="selectAllFilters($event,CityList)">
                            {{'All Cities' | translate}}
                          </label>
                        </div>
                      </div>
                      <div *ngFor="let city of CityList; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="radio" class="form-check-input" name="cityOption" [value]="city.name"
                                 id="dropdownCity_{{i+1}}" [(ngModel)]="SelectedCity" (ngModelChange)="filters()">
                          <label class="form-check-label" for="dropdownCity_{{i+1}}" (click)="filters()">
                            {{city.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </th>



                <th width="10%">
                  <!-- Location Filter -->
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                    <button class="btn btn-outline-filter" id="ddlLocationFilter" ngbDropdownToggle
                            [ngClass]="{ 'active': setSelectedResult('location') > 0}">
                      <span>
                        <span class="badge"
                              *ngIf="setSelectedResult('location')>0">{{setSelectedResult('location')}}</span>

                        {{'Location' | translate}}

                      </span>
                      <img class="down-aero" ngbDropdownOpen src="{{
                              (setSelectedResult('location') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg'
                            }}">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="ddlLocationFilter dropdown-header-filter-body"
                         class="dropdown-header-filter-body">
                      <div>
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdownLocation_all"
                                 (change)="selectAllFilters($event,Locations)">
                          <label class="form-check-label font-blue" for="dropdownLocation_all"
                                 (click)="selectAllFilters($event,Locations)">
                            {{'All Locations' | translate}}
                          </label>
                        </div>
                      </div>
                      <div *ngFor="let location of Locations; let i = index">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" id="dropdownLocation_{{i+1}}"
                                 [(ngModel)]="location.isChecked" (ngModelChange)="filters()">
                          <label class="form-check-label" for="dropdownLocation_{{i+1}}" (click)="filters()">
                            {{location.name}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>

                <th width="10%" sortable="orders" (sort)="onSort($event)">
                  {{'Orders' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"/>
                </th>
                <th width="10%" sortable="lastVisit" (sort)="onSort($event)">
                  {{'Last Visit' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" />
                </th>
                <th width="3%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of data$ | async " editRow>
                <td>
                  <input type="checkbox" class="form-check-input" (change)="chkSelection($event,customer)"
                         [(ngModel)]="customer.selected" />
                </td>

                <td class="name-blue"
                    (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{customer.fullName}}
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{customer.age}}
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{(customer.gender =='0') ? 'Female': customer.gender | translate}}
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{checkMobile(customer.mobileNumber)==true?'':'-- --'}}
                  <svg width="22" height="22" *ngIf="checkMobile(customer.mobileNumber)==true" viewBox="0 0 22 22"
                       fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0002 20.1666C5.93741 20.1666 1.8335 16.0627 1.8335 11C1.8335 5.93723 5.93741 1.83331 11.0002 1.83331C16.0629 1.83331 20.1668 5.93723 20.1668 11C20.1668 16.0627 16.0629 20.1666 11.0002 20.1666ZM11.0002 18.3333C12.9451 18.3333 14.8103 17.5607 16.1856 16.1854C17.5609 14.8102 18.3335 12.9449 18.3335 11C18.3335 9.05506 17.5609 7.1898 16.1856 5.81453C14.8103 4.43926 12.9451 3.66665 11.0002 3.66665C9.05524 3.66665 7.18998 4.43926 5.81471 5.81453C4.43945 7.1898 3.66683 9.05506 3.66683 11C3.66683 12.9449 4.43945 14.8102 5.81471 16.1854C7.18998 17.5607 9.05524 18.3333 11.0002 18.3333ZM10.0862 14.6666L6.19683 10.7772L7.493 9.48106L10.0862 12.0743L15.2709 6.88873L16.568 8.1849L10.0862 14.6666Z"
                          fill="#056D69" />
                  </svg>

                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{checkEmail(customer.email)==true?'':'-- --'}}
                  <svg *ngIf="checkEmail(customer.email)==true" width="22" height="22" viewBox="0 0 22 22" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0002 20.1666C5.93741 20.1666 1.8335 16.0627 1.8335 11C1.8335 5.93723 5.93741 1.83331 11.0002 1.83331C16.0629 1.83331 20.1668 5.93723 20.1668 11C20.1668 16.0627 16.0629 20.1666 11.0002 20.1666ZM11.0002 18.3333C12.9451 18.3333 14.8103 17.5607 16.1856 16.1854C17.5609 14.8102 18.3335 12.9449 18.3335 11C18.3335 9.05506 17.5609 7.1898 16.1856 5.81453C14.8103 4.43926 12.9451 3.66665 11.0002 3.66665C9.05524 3.66665 7.18998 4.43926 5.81471 5.81453C4.43945 7.1898 3.66683 9.05506 3.66683 11C3.66683 12.9449 4.43945 14.8102 5.81471 16.1854C7.18998 17.5607 9.05524 18.3333 11.0002 18.3333ZM10.0862 14.6666L6.19683 10.7772L7.493 9.48106L10.0862 12.0743L15.2709 6.88873L16.568 8.1849L10.0862 14.6666Z"
                          fill="#056D69" />
                  </svg>
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{customer.city}}
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{customer.locationName}}
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{(customer.orders !== 0?customer.orders:'-')}}
                </td>
                <td (click)="(customer.statusID === 1 || customer.statusID === 2 ) ? Edit(customer):false">
                  {{customer.lastVisit}}
                </td>
                <td>
                  <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                    <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                  </div>
                  <ng-template #popContent class="tettt">
                    <p class="dropdown-heading-mini">{{'CUSTOMER' | translate }} </p>
                    <ng-container *ngIf="(customer.statusID === 1 || customer.statusID === 2)">
                      <a *hasPermission="['Analytics','Customers', 'Edit']" (click)="Edit(customer)">
                        {{'Edit' |translate}}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="(customer.statusID !== 1)">
                      <a *hasPermission="['Analytics','Customers', 'Deactivate']"
                         (click)="UpdateStatus(customer,1)">{{'Active' | translate }}</a>
                    </ng-container>
                    <ng-container *ngIf="(customer.statusID === 1)">
                      <a *hasPermission="['Analytics','Customers', 'Deactivate']"
                         (click)="UpdateStatus(customer,2)">{{'Deactive' | translate }}</a>
                    </ng-container>
                    <ng-container>
                      <a *hasPermission="['Analytics','Customers', 'Delete']"
                         (click)="openConfirmationDialog(customer,3)" class="color-danger">
                        {{'Delete' | translate }}
                        <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                      </a>
                    </ng-container>

                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>


      <div class="row mt-10">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <p class="pagination-count">
            <strong class="p-4">

              {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ |  async)!}} {{'results' | translate }}
            </strong>
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!"
                          [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
            <ng-template ngbPaginationFirst>
              <img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" />
            </ng-template>
            <ng-template ngbPaginationLast>
              <img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" />
            </ng-template>
            <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
            <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>

      </div>

    </div>
  </div>
</main>
