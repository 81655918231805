import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { partnerItems } from '../_models/partnerItems';

@Injectable({
  providedIn: 'root'
})
export class LocationItemSettingService {

  public items: partnerItems[];
  constructor(private http: HttpClient) { }

  get(brandId: number, locationId: number, itemId:number) {
    return this.http.get<partnerItems[]>(`api/LocationItem/GetPartnersForProductPrice/${brandId}/${locationId}/${itemId}`);
  }
}
