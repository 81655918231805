
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DevicesService } from 'src/app/_services/devices.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ReportfilterComponent } from '../reportfilter/reportfilter.component';

@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrls: ['./salesreport.component.css']
})
export class SalesReportComponent implements OnInit {

  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  industry = 0;
  Reports = [];

  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: DevicesService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private alertService: AlertService) {
    //this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;

    this.industry = this.localStorageService.getSelectedUser().User.Industry;

    console.log(this.industry);

    this.Reports = [
      {
        title: "Sales Report",
        isAvailable: 1,
        list: [
          {
            title: "Daily Summary",
            detail: "Generate Location Summary reports for  range of days",
            icon: "assets/images/icons/reports/daily-summary.svg",
            color: "#D8F8EB",
            reportEvent: "dailySales",
            eventTitle: 'Daily Summary',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Daily Summary'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Sales Details",
            detail: "Details of orders and items",
            icon: "assets/images/icons/reports/sales-detail.svg",
            color: "#9BCED1",
            reportEvent: "salesDetails",
            eventTitle: 'Sales Detail',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Sales Detail'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Order Types",
            detail: "Summary of all orders against order types",
            icon: "assets/images/icons/reports/sales-ordertype.svg",
            color: "#FEF4CB",
            reportEvent: "salesOrderType",
            eventTitle: 'Sales Order Type',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Order Type'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Sales Transaction Type",
            detail: "Shows complete sales details thru payment modes",
            icon: "assets/images/icons/reports/sales-transactiontype.svg",
            color: "#CAB5D8",
            reportEvent: "salesTransactionType",
            eventTitle: 'Sales Transaction Type',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Transaction type Sales'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Tax Report",
            detail: "Showing complete results for received and paid taxes",
            icon: "assets/images/icons/reports/tax.svg",
            color: "#BFCFDD",
            reportEvent: "salesTax",
            eventTitle: 'Tax Report',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Tax'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Details Tax Report",
            detail: "Get the details of the taxes for each item sale",
            icon: "assets/images/icons/reports/TaxDetails.svg",
            color: "#BAD9B8",
            reportEvent: "tobaccoTax",
            eventTitle: 'Details Tax Report',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Tax'],
            isNew: 0,
            isAvailable: 1//this.industry === 1 ? 1 : 0
          },
          {
            title: "Partner Orders",
            detail: "Basic publication details and site metadata",
            icon: "assets/images/icons/reports/partner.svg",
            color: "#DFF1F2",
            reportEvent: "",
            eventTitle: "",
            permission: ['Analytics', 'Reports', 'Report Tab'],
            isNew: 0,
            isAvailable: 0
          },
          {
            title: "Refunds",
            detail: "Summary of all refunded orders and their details",
            icon: "assets/images/icons/reports/refund.svg",
            color: "#FCE7D5",
            reportEvent: "salesRefund",
            eventTitle: 'Refund Report',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Refund'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Discounts",
            detail: "Summarized details of all discounted orders",
            icon: "assets/images/icons/reports/void.svg",
            color: "#BAD9B8",
            reportEvent: "salesDiscount",
            eventTitle: 'Discount Report',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Discounts'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Voided items",
            detail: "Showing complete results of voided orders or items",
            icon: "assets/images/icons/reports/void.svg",
            color: "#FCE7D5",
            reportEvent: "deletedItems",
            eventTitle: 'Voided Cart Items',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Voided Items'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Location Sales Summary",
            detail: "Get summarized sales details of all selected locations",
            icon: "assets/images/icons/reports/LocationSalesSummary.svg",
            color: "#DFF1F2",
            reportEvent: "locationSalesSummary",
            eventTitle: 'Location Sales Summary',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Location Sales Summary'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Products Mix",
            detail: "Basic insights on each Product's sales, cost, margin, and profit",
            icon: "assets/images/icons/reports/productMix.svg",
            color: "#D8F8EB",
            reportEvent: "productMix",
            eventTitle: 'Products Mix',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Products Mix'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Partners Summary",
            detail: "Get an overview of Partner orders Summary reports for a range of days.",
            icon: "assets/images/icons/reports/PartnerSummary.svg",
            color: "#DFF1F2",
            reportEvent: "partnerDailySummary",
            eventTitle: 'Partners Summary',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Partners Summary'],
            isNew: 0,
            isAvailable: 1
          }
        ]
      },
      {
        title: "Inventory Reports",
        isAvailable: 1,
        list: [
          {
            title: "Stock Status Report",
            detail: "A report that would show the quantities of ingredients and items in the inventory at a given time",
            icon: "assets/images/icons/reports/stock.svg",
            color: "#FCE7D5",
            reportEvent: "stock",
            eventTitle: 'Stock Status Report',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Stock'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Inventory Action Logs Report",
            detail: "Summary of Quantities for ingredients and items",
            icon: "assets/images/icons/reports/invlogs.svg",
            color: "#CAB5D8",
            reportEvent: "inventorylogs",
            eventTitle: 'Inventory Action Logs Report',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Inventory Log'],
            isNew: 0,
            isAvailable: 1
          },
          {
            title: "Stock Movements",
            detail: "Get Insights on The Product movements and actions on certain locations",
            icon: "assets/images/icons/reports/StockMovement.svg",
            color: "#DFF1F2",
            reportEvent: "stockMovement",
            eventTitle: 'Stock Movements',
            permission: ['Analytics', 'Reports', 'Report Tab', 'Stock Movements'],
            isNew: 0,
            isAvailable: 1
          }
        ]
      },
      {
        title: "Users Reports",
        isAvailable: 0,
        list: [
          {
            title: "Till Managment",
            detail: "Basic publication details and site metadata",
            icon: "assets/images/icons/reports/till.svg",
            color: "#9BCED1",
            reportEvent: "",
            eventTitle: "",
            permission: ['Analytics', 'Reports', 'Report Tab'],
            isNew: 0,
            isAvailable: 0
          },
          {
            title: "Attendance Reports",
            detail: "Basic publication details and site metadata",
            icon: "assets/images/icons/reports/attendence.svg",
            color: "#BAD9B8",
            reportEvent: "",
            eventTitle: "",
            permission: ['Analytics', 'Reports', 'Report Tab'],
            isNew: 0,
            isAvailable: 0
          }
        ]
      }
    ];
  }


 

  ngOnInit() {
  }
  open(rtype, rheading) {
    const modalRef = this.modalService.open(ReportfilterComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.ReportType = { type: rtype, title: rheading };
  }


  //        <div class="col-md-4"(click) = "open('salesDetails', 'Sales Detail')" * hasPermission="['Analytics','Reports','Report Tab', 'Sales Detail']" >
  //  <div class="card" >
  //    <div class="card-body" >
  //      {{ 'Sales Details' | translate }}
  //<img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
  //  </div>
  //  < /div>
  //  < /div>


}



