import { Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { AccessRights } from '../_models/access-rights';
import { LocalStorageService } from '../_services/local-storage.service';
import { PermissionService } from '../_services/permission.service';

@Directive({
  selector: '[hasPermission]'
})
export class PermissionAccessDirective {

  private permissions = [];
  constructor(private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private persmissionService:PermissionService  ) {
    
  }

  ngOnInit() {
    //this.updateView();
  }
  @Input()
  set hasPermission(permissions: string[]) {
    this.permissions = permissions;
    this.updateView();
  }
  private updateView() {
    if (this.persmissionService.checkPermission(this.permissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
