<form [formGroup]="prepsForm" class="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">  {{'New Prep' | translate }} </h4>
    <button [disabled]="loading" class="btn-primary cutom-button" *hasPermission="['Inventory','Inventory Action','Preps', 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 mt-5">
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Recipe">{{'Choose Recipe' | translate }}</label>
              <select class="custom-select form-control" formControlName="supplyID">
                <option [ngValue]="option.recipeID" selected="option.recipeID == supplyID"
                        *ngFor="let option of Recipes">
                  {{option.name}}
                </option>
              </select>
              <div *ngIf="f.supplyID.invalid && (f.supplyID.dirty || f.supplyID.touched)" class="alert alert-danger">
                <div *ngIf="f.supplyID.errors.required">
                  {{'Recipe is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="preparedQty">{{'Units made' | translate }}</label>
              <input type="number" class="form-control" id=preparedQty placeholder="" formControlName="preparedQty">
              <div *ngIf="f.preparedQty.invalid && (f.preparedQty.dirty || f.preparedQty.touched)" class="alert alert-danger">
                <div *ngIf="f.preparedQty.errors.required">
                  {{'Unit is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="locationID">{{'From Location' | translate }}</label>
              <!-- <select class="custom-select" formControlName="fromLocation">
          <option>
              Alhamra
          </option>
      </select> -->
              <select class="custom-select form-control" formControlName="locationID">

                <option [ngValue]="option.locationID" selected="option.locationID == locationID"
                        *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
              <div *ngIf="f.locationID.invalid && (f.locationID.dirty || f.locationID.touched)" class="alert alert-danger">
                <div *ngIf="f.locationID.errors.required">
                  {{'From Location is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="tiltle-bar form-group">
            <div class="row">
              <div class="col-8">
                <label for="Item Status">{{'To the same Location' | translate }}</label>
              </div>
              <div class="col-4 text-right">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="sameLocation" (change)="chksameLocation()" formControlName="sameLocation">
                  <label class="form-check-label" for="sameLocation"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!isSameLocation">
            <div class="form-group col-lg-12 col-sm-12" style="margin-bottom: 0;">
              <label for="fromLocation">{{'To Location' | translate }}</label>
            </div>
          </div>
          <div class="recipe-box form-group" *ngIf="!isSameLocation">
            <ng-select [items]="Locations" class="form-control recipe-box-autocomplete" placeholder="{{'Add Locations' | translate }}" bindLabel="name" bindValue="locationID" (change)="addRow($event)">
            </ng-select>
            <div class="row p-1" *ngFor="let obj of arrRecipeIngerdients">
              <div class="col-6">
                <h6 for="name" class="black pt-3">{{obj.name}}</h6>
              </div>
              <div class="col-4">
                <div class="input-group" [ngClass]="{red : obj.transferQty < 1}">
                  <input type="number" value="1" min="1"  [(ngModel)]="obj.transferQty" [ngModelOptions]="{standalone: true}" (change)="UpdateQty(obj)"  class="form-control rounded-0" placeholder="" aria-label="" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-0" id="basic-addon2"><label for="unit" class="unit">{{'UNIT' | translate }}</label></span>
                  </div>
                </div>
              </div>
              <div class="col-2 text-center">
                <a><img class=" pt-3" src="/assets/images/delete-icon.png" (click)="deleteRow(obj)" /></a>
              </div>
            </div>
            <div class="row p-1" *ngIf="arrRecipeIngerdients.length==0">
              <div class="col-12 text-center">
                <h6 for="name" class="pt-3">{{'No Location Added!' | translate }}</h6>
              </div>
            </div>
          </div>
          <div class="row form-group" *ngIf="!isSameLocation" style="margin-top:-25px">
            <div class="alert alert-danger" *ngIf="!arrRecipeIngerdients || arrRecipeIngerdients.length === 0">
              {{'Location is required' | translate }}.
            </div>
          </div>
        </div>
          <div class="col-lg-4"></div>
        </div>
    </div>
  </div>
</form>
