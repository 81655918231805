<div class="modal-header">
  <h4 class="modal-title-lg">
    {{ 'Refund Items' | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form">
  <div class="container preview-order">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-1">
            <input type="checkbox" (change)="bulkSelection($event)" />
          </div>
          <div class="col-8">
            <label class="item-title"> {{'Select all items' | translate}} </label>
          </div>
          <div class="col-3">
            <label class="item-title float-end"> {{'Amount' | translate}} </label>
          </div>
        </div>
        <div class="order-items line-seprator overflow-scroll" style="max-height:300px">
          <div *ngFor="let item of  SelectedOrder?.item">
            <div class="row d-flex py-2" *ngIf="item.isRefunded === false">
              <div class="col-2 col-lg-1 col-sm-2 col-md-1 col-xxl-1 align-self-center">
                <input type="checkbox" [(ngModel)]="item.selected" (ngModelChange)="onChange()" *ngIf="!item.isComplimentary" />
              </div>
              <div class="col-3 col-lg-2 col-sm-2 col-md-2 col-xxl-2 p-0 align-self-center">
                <img [src]="item.image" *ngIf="(item.image !== '' && item.image !== null)" class="item-image" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                <img *ngIf="(item.image === '' || item.image === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="item-image" alt="" />
              </div>
              <div class="col-7 col-lg-9 col-sm-9 col-md-9 col-xxl-9 p-0 align-self-center">
                <div class="row items align-items-center">
                  <div class="col-8">
                    <label class="item-title text-truncate">
                      <span ngbTooltip="{{item.isComplimentary ? 'Complimentory item can not be refunded' : '' | translate}}">{{item.quantity}}x {{selctedLang=='en'?item.name:item.altName}}</span>
                    </label>
                  </div>
                  <div class="col-4 text-end">
                    <label class="item-price" [ngClass]="{'text-decoration-line-through': item.isComplimentary}">{{((item.price*item.quantity) + sumModifiers(item.itemDetails)) | number: '1.2-2'}}    </label>
                    <img *ngIf="item.isComplimentary" src="../../../assets/images/icons/v2/complementary_Badge.svg" />
                  </div>
                </div>
                <div *ngIf="item.itemDetails !=null && !item.selected">
                  <ng-container *ngFor="let mod of  item.itemDetails">
                    <div class="row modifiers">
                      <div class="col-8">  <label class="modifier-title">{{mod.quantity}}x {{selctedLang=='en'?mod.name:mod.altName}}</label></div>
                      <div class="col-4 text-end">
                        <label class="modifier-price">+{{(mod.price) | number: '1.2-2'}} </label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row d-flex py-2" *ngIf="item.selected === true && item.quantity >0 && item.isRefunded === false">
              <div class="col-12 col-lg-1 col-sm-1 col-md-1 col-xxl-1"></div>
              <div class="col-12 col-lg-5 col-sm-5 col-md-5 col-xxl-5 border rounded-0" style="background-color: #eff3f5;">
                <label class="py-3 px-3">{{'Quantity Refund' | translate }}</label>
              </div>
              <div class="col-12 col-lg-6 col-sm-6 col-md-6 col-xxl-6 border rounded-0"
                   [ngClass]="{'border-red' : (item.selected === true && item.quantity>0 && (item.changeRefundQuantity > item.quantity  || item.changeRefundQuantity === null || item.changeRefundQuantity === '' || item.changeRefundQuantity === undefined || item.changeRefundQuantity <= 0))}">
                <input type="number" min="0" class="form-control border-0 bg-transparent shadow-none" oninput="this.value =this.value = Math.abs(this.value) > 0 ? Math.abs(this.value) : null"
                       [(ngModel)]="item.changeRefundQuantity" [ngModelOptions]="{standalone: true}" [attr.required]="item.selected" />
              </div>
              <div class="col-12 col-lg-1 col-sm-1 col-md-1 col-xxl-1"></div>
              <div class="col-12 col-lg-5 col-sm-5 col-md-5 col-xxl-5 border rounded-0" style="background-color: #eff3f5;">
                <label class="py-3 px-3">
                  {{'Refund Reason' | translate }}
                </label>
              </div>
              <div class="col-12 col-lg-6 col-sm-6 col-md-6 col-xxl-6 border rounded-0">
                <select class="form-control custom-select-2 bg-white border-0 shadow-none" [(ngModel)]="item.refundReasonID">
                  <option [ngValue]="option.id" selected="option.id === id" *ngFor="let option of ReasonType">
                    {{option.name | translate}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-lg-1 col-sm-1 col-md-1 col-xxl-1"></div>
              <div class="col-12 col-lg-5 col-sm-5 col-md-5 col-xxl-5 border rounded-0 pb-2" style="background-color: #eff3f5;">
                <label class="py-3">
                  {{'Return to Inventory' | translate }}
                </label>
              </div>
              <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xxl-6 border rounded-0 mb-0">
                <div class="form-check form-switch float-clear pt-2" [ngClass]="{'cursor-not-allowed': !item.trackInventory}">
                  <input type="checkbox" class="form-check-input" id="returnToInventory" [(ngModel)]="item.returnToInventory" [disabled]="!item.trackInventory" [ngClass]="{'disabled' : !item.trackInventory}">
                </div>
              </div>
              <ng-container *ngIf="item.itemDetails !=null">
                <ng-container *ngFor="let mod of item.itemDetails">
                  <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xxl-1"></div>
                  <div class="col-2 col-lg-2 col-sm-2 col-md-2 col-xxl-2"></div>
                  <div class="col-7 col-lg-5 col-sm-5 col-md-5 col-xxl-5 pt-3">
                    <label class="modifier-title">{{mod.quantity}}x {{selctedLang=='en'?mod.name:mod.altName}}</label>
                  </div>
                  <div class="col-2 col-lg-4 col-sm-4 col-md-4 col-xxl-4 pt-3 text-end">
                    <label class="modifier-price">+{{(mod.price) | number: '1.2-2'}} </label>
                  </div>
                  <div class="col-12 col-lg-1 col-sm-1 col-md-1 col-xxl-1"></div>
                  <div class="col-12 col-lg-5 col-sm-5 col-md-5 col-xxl-5 border rounded-0 pb-2" style="background-color: #eff3f5;">
                    <label class="py-3">
                      {{'Return to Inventory' | translate }}
                    </label>
                  </div>
                  <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xxl-6 border rounded-0 mb-0">
                    <div class="form-check form-switch float-clear pt-2" [ngClass]="{'cursor-not-allowed': !mod.trackInventory}">
                      <input type="checkbox" class="form-check-input" id="returnToInventory" [(ngModel)]="mod.returnToInventory" [disabled]="!mod.trackInventory" [ngClass]="{'disabled' : !mod.trackInventory,'cursor-not-allowed': !mod.trackInventory}">
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-5">
            <label class="modifier-title">{{count}}{{' items selected' | translate }}</label>
          </div>
          <div class="col-lg-7">
            <button [disabled]="loading" class="btn btn-danger btn-uninstall float-end" (click)="onSubmit()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>   {{'Refund Items'  | translate }}
            </button>
            <button type="button" class="btn btn-cancel float-end mx-2" (click)="activeModal.close('Close click')">{{'Dismiss' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

