import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbdDatepickerRangePopup } from '../../daterangepicker/datepicker-range-popup';
import { LocalStorageService } from '../../_services/local-storage.service';
import * as myGlobals from '../../_helpers/globals';
@Component({
  selector: 'app-sales-summary-pdf',
  templateUrl: './sales-summary-pdf.component.html',
  styleUrls: ['./sales-summary-pdf.component.css']
})
export class SalesSummaryPDFComponent implements OnInit {
  @Output() html = new EventEmitter<HTMLElement>();
  @Input() selectedLocation;
  @Input() startdate: any;
  @Input() enddate: any;
  @Input() selectedLocationName;
  @Input() salesSummaryData: any;
  private selectedBrand;
  salesSummary: any;
  refundReason = [];
  orderTakers = [];
  mostSoldItems = [];
  inventoryAlerts = [];
  cardTypes = [];
  Currency: any;
  loading = true;
  SelectedBrand: any;
  elRef: ElementRef;
  selctedLang = 'en';
  brandCurrency: string = '';
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) dateComp: { fromDate: any; toDate: any; };
  constructor(private localStorageService: LocalStorageService,
    private elementRef: ElementRef) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;
    this.elRef = elementRef;
    this.SelectedBrand = this.localStorageService.getSelectedBrand();
    this.selctedLang = this.localStorageService.getSelectedLanguage();
    this.brandCurrency = myGlobals.brandCurrency(this.localStorageService);
  }
  ngOnDestroy() {
    if (this.salesSummaryData !== null && this.salesSummaryData !== undefined) {
      this.html.emit(this.elementRef.nativeElement);
      //this.pdfService.SaveAsPDFFile(this.elementRef.nativeElement, 'Sales-Summary', JSON.stringify(this.salesSummaryData).length);
    }
  }
  ngAfterViewInit() {
    
  }
  ngOnChanges() {
    if (this.salesSummaryData !== null && this.salesSummaryData !== undefined) {
      this.loadSalesSummary();
    }
  }
  ngOnInit() {
    if (this.salesSummaryData !== null && this.salesSummaryData !== undefined) {
      this.loadSalesSummary();
    }
  }
  loadSalesSummary() {
    //this.dateComp.fromDate = { day: new Date(this.startdate).getDate(), month: new Date(this.startdate).getMonth() + 1, year: new Date(this.startdate).getFullYear() };
    //this.dateComp.toDate = { day: new Date(this.enddate).getDate(), month: new Date(this.enddate).getMonth() + 1, year: new Date(this.enddate).getFullYear() };
    this.salesSummary = this.salesSummaryData.salesSummary;
    this.refundReason = this.salesSummaryData.refundReason;
    this.orderTakers = this.salesSummaryData.orderTakers;
    this.mostSoldItems = this.salesSummaryData.mostSoldItems;
    this.inventoryAlerts = this.salesSummaryData.inventoryAlerts;
    this.cardTypes = this.salesSummaryData.cardTypes;

    //if (this.dateComp.fromDate !== null && this.dateComp.toDate !== null && this.dateComp.fromDate !== undefined && this.dateComp.toDate !== undefined && this.dateComp.fromDate !== '' && this.dateComp.toDate !== '') {
    //  // this.loading = true;
    //  this.startdate = this.dateComp.fromDate.year + "-" + this.dateComp.fromDate.month + "-" + this.dateComp.fromDate.day;
    //  this.enddate = this.dateComp.toDate.year + "-" + this.dateComp.toDate.month + "-" + this.dateComp.toDate.day;
    //  this.service.getSalesSummary(this.selectedBrand, this.selectedLocation, this.startdate, this.enddate).subscribe((res: any) => {
    //    console.log(res);
    //    this.salesSummaryData = res;
    //    this.salesSummary = res.salesSummary;
    //    this.refundReason = res.refundReason;
    //    this.orderTakers = res.orderTakers;
    //    this.mostSoldItems = res.mostSoldItems;
    //    this.inventoryAlerts = res.inventoryAlerts;
    //    this.cardTypes = res.cardTypes;
    //    //this.loading = false;
    //  });
    //}
  }
}
