import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class paymentLinkService {

  constructor(private http: HttpClient) {
  }


  getPaymentLink(brandId: number, partnerId: number) {
    return this.http.get(`api/PaymentLink/brand/${brandId}/partner/${partnerId}`);
  }


  insert(data) {
    return this.http.post(`api/PaymentLink/`, data)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


  update(updateData) {
    return this.http.put(`api/PaymentLink`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  Uninstall(id) {
    return this.http.patch(`api/PaymentLink/uninstall/${id}`, null);

  }


  //Payment Link Setup
  //Get List of Integrated Providers

  getPaymentLinkProviders(brandId: number) {
    return this.http.get(`api/PaymentLink/integrated/providers/brand/${brandId}`);
  }

  setPaymentLinkProvidersDefault(updateData) {
    return this.http.put(`api/PaymentLink/defaultproviders`, updateData)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }


}
