<div class="modal-header">
  <h4 class="modal-title">{{title | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{'Do you want to delete' | translate }} {{name | translate }}{{'?' | translate}}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="decline()">{{'Cancel' | translate }}</button>
  <button class="btn btn-danger btn-uninstall float-end" (click)="accept()">
    {{'Confirm' | translate }}
  </button>
</div>
