<main class="login justify-content-center d-flex flex-column justify-content-center align-items-center">
  <!-- .row -->
  <img class="marn-logo" src="/assets/images/marn-logo.svg" alt="Marn" />

  <div class="container login-container">
    <div class="col-12">
      <!-- Heading -->
      <!-- Form -->
      <form [formGroup]="loginForm" @fadeSlideInOut *ngIf="activeSection =='login'" (ngSubmit)="onSubmit()" dir="ltr">
        <!-- Industry Selection -->
        <div class="form-group">
          <div class="form-control selection">
            <a id="select-restaurant" href="#Restaurant" (click)="setIndustry(true)" [class.active]="isRestaurant===true">{{'Restaurant' | translate }}</a>
            <a id="select-retail" href="#Retail" (click)="setIndustry(false)" [class.active]="isRestaurant===false">{{'Retail' | translate }} </a>
          </div>
        </div>
        <div id="restaurant" [@enabledStateChange]="stateOfElement" [class.show]="isRestaurant===true" [class.hide]="isRestaurant===false">
          <!-- Email address -->
          <div class="form-group">
            <!-- Input -->
            <!--Old Pattern for email
            pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,24}$"-->
            <input type="email" autocomplete="false" formControlName="username" [class.is-valid]="f.username.invalid===false" name=res-email class="form-control" placeholder="{{'Email Address' | translate }}">

            <div *ngIf="f.username.invalid && (f.username.dirty)" >
              <label *ngIf="f.username.errors.required" class="error">
                {{'Username is required' | translate }}.
              </label>
              <label *ngIf="f.username.invalid" class="error">
                {{'Invalid username format' | translate }}.
              </label>
            </div>
          </div>
          <!-- Password -->
          <div class="form-group">
            <!-- Input -->
            <input type="password" formControlName="password" id="res-pass" name="res-pass"
                   class="form-control form-control-appended" placeholder="{{'Password' | translate }}"
                   appPassword
                   [class.input-error]="f.password.invalid && (f.password.dirty)" />
            <div *ngIf="f.password.invalid && (f.password.dirty)">
              <label *ngIf="f.password.errors.required" class="error">
                {{'Password is required' | translate }}.
              </label>
            </div>
          </div>
        </div>
        <div id="retail" [@enabledStateChange]="stateOfElement" [class.show]="isRestaurant===false" [class.hide]="isRestaurant===true">
          <!-- Email address -->
          <div class="form-group">
            <!-- Input -->
            <!--Old Pattern for email
            pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,24}$" -->
            <input type="email" formControlName="username" [class.is-valid]="f.username.invalid===false" name=res-email class="form-control" placeholder="{{'Email Address' | translate }}">

            <div *ngIf="f.username.invalid && (f.username.dirty)" class="input-error">
              <label class="error" *ngIf="f.username.errors.required">
                {{'Username is required' | translate }}.
              </label>
              <label class="error" *ngIf="f.username.invalid">
                {{'Invalid username format' | translate }}.
              </label>
            </div>

          </div>
          <!-- Password -->
          <div class="form-group">
            <!-- Input -->
            <input type="password" formControlName="password" id="ret-pass" name="ret-pass" class="form-control form-control-appended"
                   placeholder="Password" appPassword
                   [class.input-error]="f.password.invalid && (f.password.dirty)" />
            <div *ngIf="f.password.invalid && (f.password.dirty)">
              <label class="error" *ngIf="f.password.errors.required">
                {{'Password is required' | translate }}.
              </label>
            </div>
          </div>
        </div>
        <!-- Submit -->
        <button type="submit" [disabled]="loading || f.username.invalid"
                class="btn btn-primary btn-lg btn-block">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{'Sign In' | translate }}
        </button>
        <!-- Link -->
        <div class="text-center">
          <a class="forget-label" href="#forgot-password" (click)="forgotPassword()">
            {{'Forgot Password?' | translate }}
          </a>
        </div>
        <div class="text-center box-signup">
          <div>
            <label class="signup" *ngIf="activeSection =='login'">{{'Don’t have an Account' | translate }}? <a target="_self" href="signup/index.html">{{'Sign up' | translate }}  </a>  </label>
          </div>
        </div>
      </form>

      <app-forgotpassword *ngIf="activeSection =='forgot'" [isRestaurant]="isRestaurant"></app-forgotpassword>

      <div class="text-center box-signup">
        <div>
          <label class="signup" *ngIf="activeSection =='forgot'" (click)="backLogin()">  <a href="#Restaurant">{{'Back to Sign in' | translate }}</a> </label>
        </div>
      </div>
    </div>
  </div>
  <!-- / .row -->





</main>
