<form [formGroup]="previewtransferForm" class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" [ngClass]="{'hide-mobile': isShowActions === true}">{{'Transfer Order' | translate }} {{f.tsCode.value}}</h4>


    <div placement="bottom" [ngbPopover]="popContent" *ngIf="isShowActions">
      <button class="btn btn-action m-0 me-2">
        {{'Action' | translate }}
      </button>
    </div>
    <ng-template #popContent>
      <p class="dropdown-heading-mini">{{'TRANSFER' | translate }}</p>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Approve']" (click)="selectedStatus('approve')">{{'Approve Transfer' | translate }}</a>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Decline']" (click)="selectedStatus('decline')">{{'Decline Transfer' | translate }}</a>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Hold']" (click)="selectedStatus('hold')">{{'Hold Transfer' | translate }}</a>
      <ng-container *ngIf="f.statusID.value == 600|| f.statusID.value == 604" >
        <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Edit']" class="selected-inner-bottom" (click)="Edit(f)"><span class=" po-preview-action-text">{{'Edit Transfer' | translate }} </span></a>
      </ng-container>
    </ng-template>
    <button [disabled]="loading" *ngIf="isShowActions" class="btn btn-save m-0" (click)="onSubmit(f)">
      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body ">
    <div class="container">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-1"></div>
        <div class="col-lg-8 mt-5">
          <div class="jumbotron po-preview-box">
            <div class="row form-group">
              <div class="col-lg-6 text-start">
                <!-- <img src="/assets/images/marn-logo-po.png" alt="" /> -->
                <img class="brandLogo" [src]="ls.getSelectedBrand().BrandLogo" alt="" />
              </div>
              <div class="col-lg-6 text-end">
                <h3 class="font-weight-bold ">{{'Transfer Order' | translate }}</h3>
                <h5 class=" ponum-heading ">{{f.tsCode.value}}</h5>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 text-start">
                <div [ngClass]="renderStatusClass(f.statusID.value)" class="btn btn-sm">
                  {{
 (f.statusID.value == 600 ? 'Created' : f.statusID.value == 601 ? 'Approved' : f.statusID.value == 602 ? 'Pending': f.statusID.value == 603 ? 'Declined': f.statusID.value == 604 ? 'Hold' : f.statusID.value == 605 ? 'Received' : f.statusID.value == 605
                                    ? 'Returned' : '-') | translate
                  }}
                </div>
              </div>
              <div class="col-lg-6 text-end">
                <h6 class="font-weight-bold ">{{'Ref' | translate }}# {{f.referenceNo.value}}</h6>
              </div>
            </div>
            <div class="my-4 divider"></div>
            <div class="row mb-5">
              <div class="col-lg-6 text-start">
                <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                <label>{{f.date.value |date}}</label>
              </div>
              <div class="col-lg-6 text-end">
                <h6 class="font-weight-bold ">{{'Expected Date' | translate }}</h6>
                <label>{{f.expectedDate.value | date}}</label>
              </div>
            </div>

            <div class="row  mb-5">
              <div class="col-lg-6 text-start">
                <h6 class="font-weight-bold po-preview-blue ">{{'From' | translate }}</h6>
                <h6 class="font-weight-bold ">{{f.fromLocationName.value}}</h6>
                <p>{{f.fromLocationAddress.value}}</p>
                <label>{{f.fromLocationContact.value}}</label>
              </div>
              <div class="col-lg-6 text-end">
                <h6 class="font-weight-bold po-preview-blue ">{{'Deliver to' | translate }}</h6>
                <h6 class="font-weight-bold ">{{f.toLocationName.value}}</h6>
                <p>{{f.toLocationAddress.value}}</p>
                <label>{{f.toLocationContact.value}}</label>
              </div>
            </div>
            <div class="table-V2">
              <table class="table">
                <thead class="table-header">
                  <tr>
                    <th width="35%" scope="col" class="text-center">{{'Item Name' | translate }}</th>
                    <th width="15%" scope="col" class="text-center">{{'Received' | translate }}</th>
                    <th width="15%" scope="col" class="text-center">{{'Quantity' | translate }}</th>
                    <th width="15%" scope="col" class="text-center">{{'Unit Cost' | translate }}</th>
                    <th width="15%" scope="col" class="text-center">{{'Total Cost' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of arrItems">
                    <td class="text-center">
                     
                      {{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}
                    </td>
                    <td class="text-center">{{item.receivedQty}} {{item.unit}}</td>
                    <td class="text-center">{{item.quantity}} {{item.unit}}</td>
                    <td class="text-center">{{(item.cost || item.cost ==0)?(item.cost | number : '1.2-2'):'-'}} {{(item.cost || item.cost ==0) ? brandCurrency:''}}</td>
                    <td class="text-center">{{(item.cost || item.cost ==0)?(item.quantity * item.cost | number : '1.2-2'):'-'}} {{(item.cost || item.cost ==0) ? brandCurrency:''}}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table class="table w-25 move-to-end" *ngIf="totalCost || totalCost == 0">
                <thead class="table-header">
                  <tr>
                    <th scope="col" width="40%" class="text-center rounded-0 align-middle">{{'Total Cost' | translate }}</th>
                    <th scope="col" width="60%" class="text-center rounded-0 align-middle">{{totalCost | number : '1.2-2'}} {{brandCurrency}}</th>
                  </tr>
                </thead>
              </table>
              <br />
              <br />
            </div>
            </div>

          </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>

</form>
