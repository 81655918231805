import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Receipt } from '../_models/receipt';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  constructor(private http: HttpClient) { }

  public get(locationID, brandID) {
    return this.http.get<Receipt>(`api/Receipt/${locationID}/${brandID}`);
  }

  insert(data) {
    return this.http.post(`api/Receipt`, data)
      .pipe(map(res => {
        return res;
      }));
  }
}
