<main>
  <div class="page">
    <div class="page-header">


      <div class="row">
        <div class="col-md-12" style="padding-right:1px">
          <img [src]="selectedBrand.BrandLogo" style="width:50px" />
        </div>
        <div class="col-md-12 text-right" style="padding-left:1px; font-size:20px">
          {{selectedBrand.BrandName}}
        </div>
      </div>

    </div>
    <div class="page-body">

      <section class="tile" style="margin-top: 20px">

        <div class="row text-center" style="background-color: #ebedee !important">
          <div class="col-lg-12">
            {{selectedReport.locations}}
          </div>
          <div class="col-lg-12">
            {{selectedReport.dateRange}}
          </div>
          <div class="col-lg-12">
            Generated By: {{selectedReport.generatedBy}}
          </div>
          <div class="col-md-12">
            {{selectedReport.reportName}}
          </div>
          <div class="col-md-12">
            Generated On: {{selectedReport.generatedOn | date:'MM dd, yyyy'}}
          </div>
        </div>


        <div class="tile-body p-0 table-responsive ">
          <table class="table table-striped">
            <thead>
              <tr class="table-header">
                <th width="10%">{{'Order' | translate }} </th>
                <th width="15%">{{'Location' | translate }} </th>
                <th width="15%">{{'Order Taker' | translate }} </th>
                <th width="10%">{{'Discount' | translate }} </th>
                <th width="12%">{{'Total Amount' | translate }} </th>
                <th width="15%">{{'Discount Amount' | translate }} </th>
                <th width="10%">{{'Date' | translate }} </th>


              </tr>
            </thead>
            <tbody>


              <tr *ngFor="let item of ReportData">
                <td>  {{item.OrderNo}} </td>
                <td> {{item.Location }} </td>
                <td> {{item.OrderTaker}} </td>
                <td> {{item.Discount}} </td>
                <td> {{item.TotalAmount}} </td>
                <td> {{item.DiscountAmount}} </td>
                <td> {{item.BusinessDate | date}} </td>

              </tr>
            </tbody>
          </table>
        </div>

        <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
          <div class="col-lg-12" style="margin-top:1%">
            Powered By marn.com
          </div>
        </div>
      </section>

    </div>
  </div>
</main>
