<div id="htmlData">
  <div class="modal-header ps-4 pe-4" style="height: 120px;background-color: #e6eaeb">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <img class="img-responsive img-rounded" src="assets/images/logo-full.svg" alt="User picture" />
          <img class="img-responsive img-rounded float-end" style="border: 2px solid #FFF; width: 42px; height: 42px; border-radius: 10px; margin-top: -4px;" [src]="SelectedBrand?.BrandLogo" alt="User picture" />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-lg-12">
          <h5 class="fw-bold">
            <span>{{"Item Sales" | translate }}</span>
            <span class="float-end">{{SelectedBrand?.BrandName}}</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div class="row item-sales sales-summary-report" dir="ltr">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 pt-3">
      <a class="dropdown-toggle text-center selected-location float-end " style="    text-decoration: auto;
    color: #000;
    width: fit-content() !important;
    font-size: 1rem;
    left:50px;
" >
        <span class="px-2 pb-1 float-end">{{selectedLocationName | translate}}</span>
      </a>
    </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-10 pt-2">
        <div class="input-group" style="max-width: 290px;">
          <div class="form-control" style="margin: 6px auto;text-align: center;height: 34px;border-radius: 14px 0px 0px 14px">{{startdate}}</div>
          <div class="form-control" style="margin: 6px auto;text-align: center;height: 34px;border-radius: 0px 14px 14px 0px">{{enddate}}</div>
        </div>
      </div>
      <section class="tile">
        <div class="tile-body p-0 table-responsive hrzScroll">
          <table class="table table-striped text-wrap">
            <thead>
              <tr class="table-header">
                <th>{{'ITEM NAME' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'ALTERNATE NAME' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'SUB-CATEGORY' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'ALTERNATE SUB-CATEGORY' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'BARCODE' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'QUANTITY' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'SALE WITH TAX' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                <th>{{'SALES' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of itemSales">
                <td>{{item.name }}</td>
                <td>{{item.altName }}</td>
                <td>{{item.subCategoryName }}</td>
                <td>{{item.altSubCategoryName }}</td>
                <td>{{item.barcode }}</td>
                <td> {{item.itemCount }} </td>
                <td>  {{item.salesWithTax | number:'1.2-2'}} </td>
                <td> {{item.sales | number:'1.2-2'}} </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="background-color: #ebedee !important;height:50px;text-align:center">
          <div class="col-lg-12" style="margin-top:1%">
            {{'Showing' | translate }}
            <strong>{{rowCount}}</strong>
            {{'results' | translate }}
          </div>
        </div>
      </section>
    </div>
</div>
