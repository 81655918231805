import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { RoleRightDto, Role } from '../../_models/Role';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from '../../_services/role.service';
import { AlertService } from '../../_services/alert.service';
import { UserRolesComponent } from '../user-roles.component';

@Component({
  selector: 'app-individualrole',
  templateUrl: './individualrole.component.html',
  styleUrls: ['./individualrole.component.css']
})
export class IndividualroleComponent implements OnInit {
  SaveButtonText = "Save";
  @Input() SelectedRight = null;
  updateRights: RoleRightDto=null;
  loading = false;
  submitted = false;
  userRole: UserRolesComponent;
  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit() {
    this.onLoad();
  }

  close() {
    this.activeModal.close();
  }
  onLoad() {
    console.log(this.SelectedRight);
    this.updateRights = JSON.parse(JSON.stringify(this.SelectedRight));
    this.SaveButtonText = "Save";

  }
  checkAll() {
    this.updateRights.child.forEach(e => {
      e.access = this.updateRights.access;
    });
  }
  enableAccess() {
    let access: boolean = false;
    this.updateRights.child.forEach(e => {
      if (e.access) {
        access = true;
      }
    });
    this.updateRights.access = access;
  }
  onSubmit() {
    this.activeModal.close(this.updateRights);
  }

}
