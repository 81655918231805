import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { AddtransferComponent } from '../addtransfer/addtransfer.component';
import { Observable } from 'rxjs';
import { TransferInventory, tsDetails } from 'src/app/_models/TransferInventory';
import { TransferInventoryService } from 'src/app/_services/transfer-inventory.service';
import * as myGlobals from '../../../_helpers/globals';
import { CreateTransferComponent } from '../create-transfer/create-transfer.component';


@Component({
  selector: 'app-previewtransfer',
  templateUrl: './previewtransfer.component.html',
  styleUrls: ['./previewtransfer.component.css']
})
export class PreviewtransferComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  data$: Observable<TransferInventory[]>;
  oldData: TransferInventory[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  previewtransferForm: UntypedFormGroup;
  loading = false;
  loadingTransfer = false;
  isShowActions = true;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  arrItems: tsDetails[] = [];
  totalCost: number | null = null;
  selectedLang = 'en';
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public ls: LocalStorageService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private transferService: TransferInventoryService
  ) {
    this.createForm();
    this.selectedBrand = this.ls.getSelectedBrand().BrandID;
    this.selectedLang = this.ls.getSelectedLanguage();
  }

  ngOnInit() {
    this.setSelectedTransfer();
  }
  get f() { return this.previewtransferForm.controls; }
  get brandCurrency() {
    return myGlobals.brandCurrency(this.ls);
  }
  private createForm() {

    this.previewtransferForm = this.formBuilder.group({
      tsCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      tsid: 0,
      expectedDate: [],
      date: [],
      fromLocationID: 0,
      fromLocationName: [''],
      fromLocationContact: [''],
      fromLocationAddress: [''],
      toLocationID: 0,
      toLocationName: [''],
      toLocationContact: [''],
      toLocationAddress: [''],
      rowVersion: 0,
      tsDetails: [],
      taxes: []
    });
  }

  private editForm(transfer) {

    this.f.tsCode.setValue(transfer.tsCode);
    this.f.date.setValue(transfer.date);
    this.f.expectedDate.setValue(transfer.expectedDate);
    this.f.referenceNo.setValue(transfer.referenceNo);
    this.f.tsDetails.setValue(transfer.tsDetails);
    this.arrItems = transfer.tsDetails;

    this.f.notes.setValue(transfer.notes);
    this.f.rowVersion.setValue(transfer.rowVersion);
    this.f.statusID.setValue(transfer.statusID);
    this.f.tsid.setValue(transfer.tsid);

    this.setLocations(transfer.fromLocationID, transfer.toLocationID);

    if (this.f.statusID.value == 605 || this.f.statusID.value == 601 || this.f.statusID.value == 606) {
      this.isShowActions = false;
    }
    transfer.tsDetails.forEach(item => {
      if (item.cost || item.cost == 0)
        this.totalCost += item.quantity * item.cost;
    });
  }

  setSelectedTransfer() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingTransfer = true;
      this.f.tsid.setValue(this.SelectedID);
      this.transferService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingTransfer = false;
      });
    }
  }

  Edit(transfer) {
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(CreateTransferComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = transfer.tsid.value;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  getBrandData() {
    this.transferService.getTransferData(this.selectedBrand);
    this.data$ = this.transferService.data$;

    this.total$ = this.transferService.total$;
    this.loading$ = this.transferService.loading$;

  }
  onSubmit(obj) {

    if (this.f.statusID.value == 601) {
      this.Approve(obj);
    }
    else if (this.f.statusID.value == 603) {
      this.Decline(obj);
    }
    else if (this.f.statusID.value == 604) {
      this.Hold(obj);
    }
  }

  setLocations(fromLocationID, toLocationID) {
    this.transferService.getLocationsById(fromLocationID).subscribe((res: any) => {
      this.f.fromLocationID.setValue(res.locationID);
      this.f.fromLocationName.setValue(res.name);
      this.f.fromLocationAddress.setValue(res.address);
      this.f.fromLocationContact.setValue(res.contactNo);
    });
    this.transferService.getLocationsById(toLocationID).subscribe((res: any) => {

      this.f.toLocationID.setValue(res.locationID);
      this.f.toLocationName.setValue(res.name);
      this.f.toLocationAddress.setValue(res.address);
      this.f.toLocationContact.setValue(res.contactNo);
    });
  }

  close() {
    this.activeModal.dismiss();
  }
  renderStatusClass(obj) {

    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"
      case 602:
        return "btn-badge-grey"

    }
  }
  selectedStatus(status) {

    if (status == "approve") {
      this.f.statusID.setValue(601);
    }
    else if (status == "hold") {
      this.f.statusID.setValue(604);
    }
    else if (status == "decline") {
      this.f.statusID.setValue(603);
    }
  }
  Approve(obj) {

    this.transferService.approve(obj.brandID.value, obj.tsid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Transfer has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }
  Hold(obj) {
    this.transferService.hold(obj.brandID.value, obj.tsid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Transfer has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }
  Decline(obj) {
    this.transferService.decline(obj.brandID.value, obj.tsid.value, obj.rowVersion.value).subscribe((res: any) => {
      this.alertService.success("Transfer has been updated");
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }

}
