import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { tsDetails } from 'src/app/_models/TransferInventory';
import { TransferInventoryService } from 'src/app/_services/transfer-inventory.service';

@Component({
  selector: 'app-receivetransfer',
  templateUrl: './receivetransfer.component.html',
  styleUrls: ['./receivetransfer.component.css']
})
export class ReceivetransferComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  receivetsForm: UntypedFormGroup;
  loading = false;
  loadingReceivetransfer = false;
  ButtonText = "Save";
  Locations = [];
  Items = [];
  arrItems: tsDetails[] = [];

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private receivetsService: TransferInventoryService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedReceivetransfer();

  }
  get f() { return this.receivetsForm.controls; }

  private createForm() {

    this.receivetsForm = this.formBuilder.group({
      tsCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      tsid: 0,
      expectedDate: [],
      receivedDate: [],
      date: [],
      rowVersion: 0,
      tsDetails: [],
      fromLocationID: 0,
      toLocationID: 0,
    });
  }

  private editForm(receivetransfer) {
    
    this.f.tsCode.setValue(receivetransfer.tsCode);
    this.f.date.setValue(receivetransfer.date);
    this.f.expectedDate.setValue(receivetransfer.expectedDate);
    this.f.referenceNo.setValue(receivetransfer.referenceNo);
    this.f.tsDetails.setValue(receivetransfer.tsDetails);

    if (receivetransfer.tsDetails.length > 0) {
      receivetransfer.tsDetails.forEach(element => {
        element.receivedQty = element.receivedQty == null ? 0 : element.receivedQty;
      });
    }
    this.arrItems = receivetransfer.tsDetails;

    this.f.notes.setValue(receivetransfer.notes);
    this.f.rowVersion.setValue(receivetransfer.rowVersion);
    this.f.statusID.setValue(receivetransfer.statusID);
    this.f.tsid.setValue(receivetransfer.tsid);
    this.f.fromLocationID.setValue(receivetransfer.fromLocationID);
    this.f.toLocationID.setValue(receivetransfer.toLocationID);

  }

  setSelectedReceivetransfer() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Receive";
      this.loadingReceivetransfer = true;
      this.f.tsid.setValue(this.SelectedID);
      this.receivetsService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingReceivetransfer = false;
      });
    }
  }


  onSubmit() {

    this.receivetsForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.receivetsForm.invalid) { return; }
    this.loading = true;

    this.f.statusID.setValue(605);

    if (parseInt(this.f.tsid.value) !== 0) {
      //Update receivetransfer
      this.receivetsService.receiveTO(this.receivetsForm.value).subscribe(data => {
        this.alertService.success("Transfer order has been received");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });

    }
  }

  close() {
    this.activeModal.dismiss();
  }

  UpdateQty(newItem) {
    
    if (newItem.receivedQty > newItem.quantity || newItem.receivedQty < 0)
      newItem.receivedQty = newItem.quantity;

    let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.receivedQty);
    
    let index = this.arrItems.indexOf(updateItem);
    this.arrItems[index] = newItem;
  }

  findIndexToUpdate(newItem) {
    return newItem.receivedQty === this;
  }
}
