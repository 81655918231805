
<ng-container *ngFor="let report of Reports">
  <div class="row report-list p-2" *ngIf="report.isAvailable === 1">
    <div class="row pb-4">
      <div class="title">
        <span>{{report.title | translate }} </span>
      </div>
    </div>
    <div class="page-body report-cards">
      <div class="row mb-4">
        <ng-container *ngFor="let list of report?.list">
          <ng-container *ngIf="list.isAvailable === 1">
            <div class="col-sm-12 col-lg-4 col-md-6 col-lg-4 col-xl-4" *hasPermission="list.permission">
              <div class="card" (click)="open(list.reportEvent, list.eventTitle)"  [ngClass]="{ 'disable': list.isAvailable == 0}">
                <div class="card-horizontal">
                  <div class="img-round" [ngStyle]="{'background': list.color}">
                    <img src={{list.icon}} />
                    <!--<img src='assets/images/icons/reports/new-badge.svg' class="w-100 m-0 mb-5" />-->
                    <ngb-highlight class="btn btn-sm btn-badge-purple m-0 rounded" [result]="'NEW' | translate"  *ngIf="list.isNew === 1" style="background-color: #F2E3FF !important; border-color: #8628D6 !important;">
                    </ngb-highlight>
                    <span class="report-soon" *ngIf="list.isAvailable == 0">{{'SOON' | translate}}</span>
                  </div>
                  <div class="card-body">
                    <h4 class="card-title">{{list.title | translate}}</h4>
                    <p class="text-mute ">{{list.detail | translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </div>
</ng-container>

<!--<div class="row">
  <div class="row pb-2">
    <div class="title">
      <span>{{'Sales Report' | translate }} </span>
    </div>
  </div>


  <div class="page-body report-box">

    <div class="mb-5">
      <div class="row form-group">

        <div class="col-md-4" (click)="open('salesDetails', 'Sales Detail')" *hasPermission="['Analytics','Reports','Report Tab', 'Sales Detail']">
          <div class="card">
            <div class="card-body">
              {{'Sales Details' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
        <div class="col-md-4" (click)="open('salesOrderType', 'Sales Order Type')" *hasPermission="['Analytics','Reports','Report Tab', 'Order Type']">
          <div class="card">
            <div class="card-body">
              {{'Order Types' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
        <div class="col-md-4" (click)="open('salesTransactionType', 'Sales Transaction Type')" *hasPermission="['Analytics','Reports','Report Tab', 'Transaction type Sales']">
          <div class="card">
            <div class="card-body">
              {{'Sales Transaction Types' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
      </div>




      <div class="row form-group">
        <div class="col-md-4" (click)="open('salesTax', 'Tax Report')" *hasPermission="['Analytics','Reports','Report Tab', 'Tax']">
          <div class="card">
            <div class="card-body">
              {{'Tax Report' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
        <div class="col-md-4" (click)="open('salesRefund', 'Refund Report')" *hasPermission="['Analytics','Reports','Report Tab', 'Refund']">
          <div class="card">
            <div class="card-body">
              {{'Refunds' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
        <div class="col-md-4" (click)="open('deletedItems', 'Voided Cart Items')" *hasPermission="['Analytics','Reports','Report Tab', 'Voided Items']">
          <div class="card">
            <div class="card-body">
              {{'Voided Items' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4" (click)="open('salesDiscount', 'Discount Report')" *hasPermission="['Analytics','Reports','Report Tab', 'Discounts']">
          <div class="card">
            <div class="card-body">
              {{'Discounts' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row pb-2">
    <div class="title">
      <span>{{'Inventory Report' | translate }} </span>
    </div>
  </div>
  <div class="page-body report-box">
    <div class="mb-5">
      <div class="row form-group">
        <div class="col-md-4" (click)="open('stock', 'Inventory Stock')" *hasPermission="['Analytics','Reports','Report Tab', 'Stock']">
          <div class="card">
            <div class="card-body">
              {{'Stock Report' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
        <div class="col-md-4" (click)="open('inventorylogs', 'Inventory Logs')" *hasPermission="['Analytics','Reports','Report Tab', 'Inventory Log']">
          <div class="card">
            <div class="card-body">
              {{'Inventory Logs' | translate }}
              <img src="../../assets/images/icons/v2/right-arrow.svg" class="pt-1 imgPosition" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
