import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { PurchaseorderService } from 'src/app/_services/purchaseorder.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';

@Component({
  selector: 'app-print-po',
  templateUrl: './print-po.component.html',
  styleUrls: ['./print-po.component.css']
})
export class PrintPoComponent implements OnInit {

  poid: number;
  PO : any = [];

  constructor(private route: ActivatedRoute, public localStorageService:LocalStorageService,
     private printService: PrintService,private service:PurchaseorderService) {
      this.poid = parseInt(this.route.snapshot.params["poid"]);
   }

 ngOnInit() {
  this.poid = parseInt(this.route.snapshot.params["poid"]);
  this.service.getById(this.poid,  parseInt(this.localStorageService.getSelectedBrand().BrandID)).subscribe(res => {

    this.PO = res;
    this.loadSupplier(this.PO.supplierID);
    this.loadLocation(this.PO.locationID);

   this.printService.onDataReady();
  });

  }


  loadSupplier(id) {
    this.service.getSupplierById(id).subscribe((res: any) => {
      this.PO.supplierID=res.supplierID;
      this.PO.supplierName=res.name;
      this.PO.supplierAddress=res.email;
      this.PO.supplierContact=res.contactNo;
    });
  }
  loadLocation(id) {
    this.service.getLocationsById(id).subscribe((res: any) => {
      this.PO.locationID=res.locationID;
      this.PO.locationName=res.name;
      this.PO.locationAddress=res.address;
      this.PO.locationContact=res.contactNo;
    });
  }


  renderStatusClass(obj) {
    switch (obj) {
      case 600:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
      case 604:
        return "btn-badge-purple"
      case 605:
        return "btn-badge-blue"

    }
  }

}
