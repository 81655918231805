<div class="modal-header">
  <h4 class="modal-title-lg">
    {{ 'New Tax' | translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body tax-reference">
  <div class="container">
    <div class="row text-center pt-3">
      <div class="col-12">
        <label>
          {{'Select one of the created tax templates' | translate}}
        </label>
        <br />
        <label>{{'or create your own tax:' | translate}}</label>
      </div>
    </div>
    <div class="row pt-4 pb-5">
      <div class="col-1">
      </div>
      <div class="col-10">
        <div class="row align-items-center border border-2 tax-list mb-2" role="button"
             [ngClass]="{'border-primary':taxRef==item.taxRef}"
             (click)="taxRef=item.taxRef"
             *ngFor="let item of taxRefList">
          <div class="col-1" *ngIf="item.img">
            <img [src]="item.img" class="item-image" alt="image" />
          </div>
          <div class="col-7 px-4" *ngIf="item.name">
            <label class="small" [ngClass]="{'fw-bold font-12':item.isCustom === true, 'font-10':item.isCustom === false}">
              {{item.name | translate}}
            </label>
            <br />
            <label class="small font-15 fw-bold" *ngIf="item.value">{{item.value}}</label>
          </div>
          <div class="col-4 move-to-end applicable-on" *ngIf="item.applicableOn">
            <label class="small font-10">{{item.applicableOn | translate}}</label>
          </div>
        </div>
      </div>
      <div class="col-1">
      </div>
    </div>
  </div>
  <hr />
  <div class="row d-flex align-items-center">
    <div class="col-12">
      <button class="btn btn-primary cutom-button form-control custom-create-btn move-to-end" (click)="selectTemplate()">
        {{'Next'  | translate }}
      </button>
    </div>
  </div>
</div>
