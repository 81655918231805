import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'app-confirmation-dialogue',
  templateUrl: './confirmation-dialogue.component.html',
  styleUrl: './confirmation-dialogue.component.css'
})
export class ConfirmationDialogueComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() buttonText: string;
  @Input() deleteDialogue: boolean;
  reason: string = '';

  constructor(private activeModal: NgbActiveModal,
    private alertService: AlertService) { }

  ngOnInit() {
  }
  public decline() {
    this.activeModal.close({ status: false, text: null });
  }

  public accept() {
    if ((this.deleteDialogue && this.reason && this.reason.length >= 3 && this.reason.length <= 140) || !this.deleteDialogue)
      this.activeModal.close({ status: true, text: this.reason });
    else {
      this.alertService.error("Reason is required");
    }
  }

  public dismiss() {
    this.activeModal.dismiss({ status: false, text: null });
  }
}
