import { Component, ViewChild } from '@angular/core';
import { ChartOptions } from '../dashboard.component';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-today-summary',
  templateUrl: './today-summary.component.html'
})
export class TodaySummaryComponent{
  @ViewChild('chart', { static: true }) chart: ChartComponent;
  public todaySummaryLineOptions: Partial<ChartOptions>;

  constructor() {
    this.todaySummaryLineOptions = {
      series: [{
        name: "Today",
        data: [24000, 50000, 45000, 12000, 30000, 12000, 30000, 20000, 23000, 12000, 123123, 24234, 24000, 50000, 45000, 12000, 30000, 12000, 30000, 20000, 23000, 12000, 123123, 24234]
      },
      {
        name: "Last",
        data: [35000, 12000, 35000, 50000, 30000, 24000, 15000, 40000, 11500, 24234, 12000, 123123, 35000, 12000, 35000, 50000, 30000, 24000, 15000, 40000, 11500, 24234, 12000, 123123]
      }
      ],
      chart: {
        height: 300,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      markers: {
        size: 5,
        strokeWidth: 0,
        colors: ['#fff'],
        strokeColors: ['#576cf5', '#00d7e0'],
      },
      colors: ['#576cf5', '#00d7e0'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        width: 3,
      },
      grid: {
        row: {

          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM', '12 PM', '01 PM',
          '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM', '12 AM', '01 AM', '02 AM',
          '03 AM', '04 AM'],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        labels: {
          style: {
            fontSize: '14px',
            fontFamily: 'Montserrat-SemiBold'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '15px',
            fontFamily: 'Montserrat-SemiBold'
          },
          formatter: function (val) {
            return (val / 1000) + 'K';
          }
        }
      }
    };
  }


}
