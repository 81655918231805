import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-brand-creation-confirmation',
  templateUrl: './brand-creation-confirmation.component.html',
  styleUrls: ['./brand-creation-confirmation.component.css']
})
export class BrandCreationConfirmationComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }


  ngOnInit(): void {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  accept() {
    this.activeModal.dismiss(true);
  }

}
