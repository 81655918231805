<main class="views container order-list">
  <div class="page-header tabheader ">
    <span>
      <!--{{(active=="userlist" ?"User List":active=="role"?"Role":"-") | translate}}-->

      <a backLink>{{'Setup' | translate }} </a>&nbsp;/ {{(active=="userlist" ?"User":active=="role"?"Role":"-") | translate }}
    </span>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
          <li ngbNavItem="userlist" id="userlist" *hasPermission="['Setup','Users','Users']" (click)="active='userlist';">
            <a ngbNavLink> {{'Users' | translate }} </a>
            <ng-template ngbNavContent>
              <app-userlist [LogEventType]="'screen'" [LogEvent]="'Users'"></app-userlist>
            </ng-template>
          </li>
          <li ngbNavItem="role" id="role" *hasPermission="['Setup','Users','Roles']" (click)="active='role';">
            <a ngbNavLink>{{'Roles' | translate }}  </a>
            <ng-template ngbNavContent>
              <app-roles [LogEventType]="'screen'" [LogEvent]="'User Roles'"></app-roles>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="page-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</main>
