<main class="views container order-list product-list">
  <div class="page">
    <div class="page-body">
      <div class="page-header" *ngIf="(service.items?.length > 0)">
        <div class="tile-header tCustom-header items">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12">
              <h4> {{"Items" | translate }}</h4>
            </div>
            <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
              <div class="top-bar-flex">
                <div class="filter-field">
                  <div class=" position-relative" style="margin-left:10px !important;">
                    <!-- <span class="show-filter-icon float-left">
                      <img class="dots-list" src="assets/images/search.svg" />
                    </span> -->
                    <input class="form-control search-text" placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
                  </div>
                </div>
                <div class="export-sec">
                  <div class="" *hasPermission="['Products','Items', 'Export']">
                    <div ngbDropdown class="nav-item" placement="bottom">
                      <button class="profile btn export-btn btn-lg form-control" id="dropdownBasic1" ngbDropdownToggle>
                        <img src="assets/images/icons/v2/export.svg" class="mr-1" />&nbsp; <span>{{'Export' | translate }} </span>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <p class="dropdown-heading-mini">{{'Export' | translate }} </p>
                        <button ngbDropdownItem (click)="export('csv')">CSV</button>
                        <button ngbDropdownItem (click)="export('xlsx')">Excel</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="import-sec">
                  <div class="" *hasPermission="['Products','Items', 'Import']">
                    <div ngbDropdown class="nav-item" placement="bottom">
                      <button class="profile btn export-btn btn-lg form-control" (click)="import()">
                        <img src="assets/images/icons/v2/export.svg" class="mr-1 rotate-180" />&nbsp; <span>{{'Import' | translate }} </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="create-btn">
                  <div *hasPermission="['Products','Items', 'New']">
                    <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
                      {{'+ Create Item' | translate }}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="(service.items?.length > 0) && ((data$ | async)?.length > 0)">
        <div class="position-relative">
          <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
            <div class="tile-body p-0 table-responsive text-nowrap">
              <table class="table table-striped item text-start">
                <thead>
                  <tr class="table-header">
                    <th width="5%" class="text-center"> <input type="checkbox" class="form-check-input" (change)="bulkSelection($event)" /></th>
                    <th width="5%" sortable="name" (sort)="onSort($event)" class="text-center"><img src="/assets/images/icons/camera-icon.svg" alt=""></th>
                    <th width="20%" sortable="name" (sort)="onSort($event)">{{'Item Name' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="15%" sortable="subCategoryName" (sort)="onSort($event)">{{'Sub Category' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="15%" sortable="categoryName" (sort)="onSort($event)">{{'Category' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="13%" sortable="barcode" (sort)="onSort($event)">{{'Barcode' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
                    <th width="9%" sortable="displayOrder" (sort)="onSort($event)">{{'Order' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
                    <th width="9%" sortable="price" (sort)="onSort($event)">{{'Price' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png"  /></th>
                    <th width="8%" class="text-center">
                      <!-- {{'Status' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /> -->
                      <!-- Status Filter -->
                      <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                        <button class="btn btn-outline-filter" id="ddlStatusFilter" ngbDropdownToggle>
                          <span> {{((!SelectedStatus || SelectedStatus === '' || SelectedStatus === 'All Status') ?'Status':(SelectedStatus == '1' ?'Active':'Inactive')) | translate}} </span>
                          <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                        </button>
                        <div ngbDropdownMenu aria-labelledby="ddlStatusFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                          <div class="form-check" ngbDropdownToggle>
                            <input type="radio" class="form-check-input" [(ngModel)]="SelectedStatus" value="All Status"
                                   name="cityOption" id="dropdownStatus_all" (change)="selectAllFilters($event,StatusList)">
                            <label class="form-check-label font-blue" for="dropdownStatus_all"
                                   (click)="selectAllFilters($event,StatusList)">
                              {{'All Status' | translate}}
                            </label>
                          </div>
                          <div *ngFor="let item of StatusList; let i = index">
                            <div class="form-check" ngbDropdownToggle>
                              <input type="radio" class="form-check-input" name="itemOption" [value]="item.name" id="dropdownStatus_{{i+1}}" [(ngModel)]="SelectedStatus" (change)="filters()">
                              <label class="form-check-label" for="dropdownStatus_{{i+1}}" (click)="filters()" ngbDropdownToggle>
                                <!-- {{item.name}} -->

                                <ngb-highlight [ngClass]="{'btn-active': item.name === 1, 'btn-inactive':item.name !== 1 }"
                                               class="btn btn-sm" [result]="(item.name ==1?'Active':'Inactive') | translate">
                                </ngb-highlight>


                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </th>




                    <th width="5%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data$ | async " editRow>
                    <td class="text-center">
                      <input type="checkbox" class="form-check-input" (change)="chkSelection($event,item)" [(ngModel)]="item.selected" />
                    </td>
                    <td (click)="Edit(item)">
                      <img [src]="item.itemImage+'?refreshCache='+breakCache" *ngIf="(item.itemImage !== '' && item.itemImage !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                      <img *ngIf="(item.itemImage === '' || item.itemImage === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="table-img" alt="" />
                    </td>
                    <td class="name-blue" (click)="Edit(item)"> {{selctedLang=='en'?item.name:item.nameOnReceipt}} </td>
                    <td (click)="Edit(item)">  {{selctedLang=='en'?item.subCategoryName:item.altSubCategoryName}}  </td>
                    <td (click)="Edit(item)"> {{selctedLang=='en'?item.categoryName:item.altCategoryName}} </td>
                    <td (click)="Edit(item)">{{item.barcode}}</td>
                    <td (click)="Edit(item)">{{item.displayOrder}}</td>
                    <td (click)="Edit(item)">{{item.price}}</td>
                    <td class="text-center">
                      <ngb-highlight [ngClass]="{'btn-active': item.statusID === 1, 'btn-inactive':item.statusID !== 1 }" class="btn btn-sm" [result]="(item.statusID ==1?'Active':'Inactive') | translate" [term]="service.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td class="text-center">
                      <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                        <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
                      </div>
                      <ng-template #popContent>
                        <p class="dropdown-heading-mini">{{'Items' | translate }}</p>
                        <a *hasPermission="['Products','Items', 'Edit']" (click)="Edit(item)">{{'Edit' | translate }}</a>
                        <ng-container *ngIf="item.statusID === 1">
                          <a *hasPermission="['Products','Items', 'Deactivate']" (click)="SetStatus(item.itemID,2, item.rowVersion)">{{'Deactivate' | translate }}</a>
                        </ng-container>
                        <ng-container *ngIf="item.statusID === 2">
                          <a *hasPermission="['Products','Items', 'Deactivate']" (click)="SetStatus(item.itemID,1, item.rowVersion)">{{'Activate' | translate }}</a>
                        </ng-container>
                        <a *hasPermission="['Products','Items', 'Delete']" class="selected-inner-text color-danger" (click)="openConfirmationDialog(item.itemID, item.rowVersion,selctedLang=='en' ? item.name : item.nameOnReceipt)">

                          {{'Delete' | translate }}
                          <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                        </a>
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <div class="row mt-10">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <p class="pagination-count">
                <strong class="p-4">
                  {{'Showing' | translate }} {{(service.pageSize * service.page)-9}} {{'of' | translate }} {{(total$ | async)!}} {{'results' | translate }}
                </strong>
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
                <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
                <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
                <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
                <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>

          </div>
        </div>
      </div>



      <div *ngIf="(service.items?.length == 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Product Item yet' | translate }}</strong>
          <p class="text-center width-375">{{'Create items for order.' | translate}}</p>
        </div>
        <div class="items-flex">
          <div class="create-btn mlmr-10">
            <div *hasPermission="['Products','Items', 'New']">
              <button type="button" (click)="open()" class="btn btn-primary cutom-button form-control custom-create-btn">
                {{'+ Create New Item' | translate }}
              </button>
            </div>
          </div>
          <div class="import-sec">
            <div class="" *hasPermission="['Products','Items', 'Import']">
              <div ngbDropdown class="nav-item" placement="bottom">
                <button class="profile btn export-btn btn-lg form-control" (click)="import()">
                  <img src="assets/images/icons/v2/export.svg" class="mr-1" />&nbsp; <span>{{'Import' | translate }} </span>
                </button>


              </div>
            </div>
          </div>

        </div>
      </div>




      <div *ngIf="(service.items?.length > 0) && ((data$ | async)?.length == 0)">
        <div class="blank-order">
          <img src="../../assets/images/icons/no-orders.svg" alt="" class="img-fluid ">
          <strong>{{'No Record Found' | translate }}</strong>
          <p class="text-center width-375">{{'Create items for order.' | translate}}</p>
        </div>
      </div>


    </div>
  </div>
</main>
