import { Component, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { InventoryCount } from '../../_models/InventoryCount';
import { AlertService } from '../../_services/alert.service';
import { ExcelService } from '../../_services/excel.service';
import { InventoryCountService } from '../../_services/inventory-count.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { PrintService } from '../../_services/print.service';
import { TrackEventsService } from '../../_services/track-events.service';
import { AddInventoryCountComponent } from './add-inventory-count/add-inventory-count.component';

@Component({
  selector: 'app-inventory-count',
  templateUrl: './inventory-count.component.html',
  styleUrl: './inventory-count.component.css'
})
export class InventoryCountComponent {
  data$: Observable<InventoryCount[]>;
  oldData: InventoryCount[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "count";
  submit: boolean;
  excelData = [];
  StatusList = [];
  LocationList = [];
  CreatedByList = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  constructor(public service: InventoryCountService,
    private excelService: ExcelService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    public printService: PrintService) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-InventoryCount', type);
  }
  getBrandData() {
    this.service.headerFilter = { status: [''], locationName: [''], createdBy: [''] };
    this.service.getInventoryCounts(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;


    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];
      this.LocationList = [];
      this.CreatedByList = [];
      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


        new Set(res.map(invAlert => invAlert.locationName)).forEach(e => {
          this.LocationList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
        new Set(res.map(invAlert => invAlert.createdBy)).forEach(e => {
          this.CreatedByList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  add() {
    const modalRef = this.modalService.open(AddInventoryCountComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Inventory Count");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(inventoryCount) {
    const modalRef = this.modalService.open(AddInventoryCountComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Inventory Count");
    modalRef.componentInstance.SelectedID = inventoryCount.icid;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  Print(inventoryCount) {
    this.printService.printDocument('ic', inventoryCount.icid);
  }

  

  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  public chkSelection($event, inventoryCount) {
    this.service.inventoryCounts.forEach((item, index) => {
      if (item.icid == inventoryCount.icid) {
        if ($event.target.checked == true) {
          this.excelData.push(item);
        } else {

          this.excelData.splice(index, 1);
        }
      }
    });
  }

  private getExportData() {
    let selected: any = [];
    let all: any = []; 

    this.service.inventoryCounts.forEach(d => {
      let data = {
        icCode: d.icCode,
        createdOn: d.createdOn,
        locationName: d.locationName,
        createdBy: d.createdBy,
        totalItems: d.totalItems,
        totalDifference: d.totalDifference,
        statusID: (d.statusID === 601) ? 'Approved' : (d.statusID === 602) ? 'Pending':'Declined'
      };
      if (d.selected) {
        selected.push(data);
      }
      else {
        all.push(data);
      }
    });
    return selected.length == 0 ? all : selected;
  }
  renderStatusClass(obj) {
    switch (obj) {
      case 602:
        return "btn-badge-yellow"
      case 601:
        return "btn-active"
      case 603:
        return "btn-inactive"
    }
  }

  SelectedStatus = '';
  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus],
      locationName: this.LocationList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
      createdBy: this.CreatedByList.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }

  setSelectedResult(val) {


    if (val == 'location') {
      return this.LocationList.filter(k => k.isChecked == true).length;
    }
    if (val == 'createdBy') {
      return this.CreatedByList.filter(k => k.isChecked == true).length;
    }

  }
}
