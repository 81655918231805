<form [formGroup]="transferForm" class="form">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{f.tsCode.value}} </h4>
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown" *ngIf="SelectedID === 0">
      <button [disabled]="loading" type="button" class="btn-primary btn-sm cutom-button custom-GroupButton" (click)="onSubmit('create')" *hasPermission="['Inventory','Inventory Action','Transfers', 'New']">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ButtonText | translate}}
      </button>
      <div class="btn-group" role="group">
        <button [disabled]="loading" id="btnGroupDrop1" type="button" class="btn-primary btn-sm custom-SubButton dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngbPopover]="popActions">
          <i [disabled]="loading" class="downArrow-white"></i>
        </button>
      </div>
    </div>
    <ng-template #popActions>
      <p class="dropdown-heading-mini">{{'TRANSFER' | translate }}</p>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Create & Approve']" (click)="onSubmit('approve')">{{'Create & Approve' | translate }}</a>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Create & Receive']" (click)="onSubmit('receive')">{{'Create & Receive' | translate }}</a>
    </ng-template>
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown" *ngIf="SelectedID !== 0">
      <button [disabled]="loading" type="button" class="btn-primary btn-sm cutom-button" (click)="onSubmit('approve')" *hasPermission="['Inventory','Inventory Action','Transfers', 'Approve']">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{'Approve' | translate}}
      </button>
      <div class="btn-group" role="group">
        <button [disabled]="loading" id="btnGroupDrop1" type="button" class="btn-primary btn-sm custom-SubButton dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngbPopover]="popUpdateStatusActions">
          <i [disabled]="loading" class="downArrow-white"></i>
        </button>
      </div>
    </div>
    <ng-template #popUpdateStatusActions>
      <p class="dropdown-heading-mini">{{'TRANSFER' | translate }}</p>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Hold']" (click)="updateStatus('hold')">{{'Hold' | translate }}</a>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Receive']" (click)="onSubmit('receive')">{{'Receive' | translate }}</a>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Receive']" (click)="onSubmit('update')">{{'Update' | translate }}</a>
      <a *hasPermission="['Inventory','Inventory Action','Transfers', 'Decline']" (click)="updateStatus('decline')" style="color: #8B0000 !important">{{'Decline Transfer' | translate }}</a>
    </ng-template>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6 mt-5">
          <div class="row text-center" *ngIf="SelectedID !== 0">
            <div class="col-lg-12 col-sm-12">
              <label for="name">{{'State' | translate }}</label>
            </div>
            <div class="col-lg-12 col-sm-12">
              <ngb-highlight [ngClass]="renderStatusClass(f.statusID.value)" class="btn btn-sm" [result]="(
                                                            f.statusID.value ==600?'Created':
                                                            f.statusID.value ==601?'Approved':
                                                            f.statusID.value ==602?'Pending':
                                                            f.statusID.value ==603?'Declined':
                                                            f.statusID.value ==604?'Hold':
                                                            f.statusID.value ==605?'Received':
                                                            f.statusID.value ==606?'Returned':'-') | translate">
              </ngb-highlight>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="name">{{'Reference No' | translate }}</label>
              <input type="text" class="form-control" id=name placeholder="" formControlName="referenceNo">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'From' | translate }}</label>
              <select class="custom-select form-control" formControlName="fromLocationID" (change)='reloadItems(f.fromLocationID.value)'>
                <option [ngValue]="option.locationID" [disabled]=option.maped selected="option.locationID == locationID" *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'To' | translate }}</label>
              <select class="custom-select form-control" formControlName="toLocationID">
                <option [ngValue]="option.locationID" [disabled]=option.maped selected="option.locationID == locationID" *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
              <div *ngIf="f.toLocationID.value === f.fromLocationID.value" class="alert alert-danger">
                {{'From and To location can not be same' | translate }}.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="expDate">{{'Expected Date' | translate }}</label>
              <ngbd-datepicker-adapter></ngbd-datepicker-adapter>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Notes">{{'Notes' | translate }}</label>
              <textarea class="form-control mh-80 h-75" formControlName="notes"></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-12 col-sm-12">
              <hr />
            </div>
          </div>
          <div class="row">
            <label for="Conversion">{{'Add Items or Ingredients' | translate }}</label>
            <div class="input-group form-group">
              <div class="input-group-append w-100">
                <ng-select [items]="Items" class="po-box-autocomplete border-right-radius-unset " placeholder="{{'Click here to add Item or Ingredient' | translate }}" [bindLabel]="(selectedLang == 'en' ? 'name' : 'alternateName')"
                 bindValue="supplyID" (change)="selectedInvItem($event)"
                           [(ngModel)]="tsDetails.supplyID" [ngModelOptions]="{standalone: true}" [searchFn]="SearchItem">
                </ng-select>
                <!--<input type="number" class="input-group-text w-25 quantity" id=quantity value="1" [(ngModel)]="tsDetails.quantity" [ngModelOptions]="{standalone: true}">-->
              </div>
            </div>
          </div>
          <section class="tile" style="border:none;box-shadow:none">
            <div class="p-0 table-responsive ">
              <table class="table table-striped">
                <thead>
                  <tr class="table-header">
                    <th scope="col" width="5%"><img src="/assets/images/icons/v2/camera.svg" /></th>
                    <th scope="col" width="37%" class="border-radius-topleft-10">{{'Item Name' | translate }}</th>
                    <th scope="col" width="10%" class="border-start-width-0 border-end-width-0">{{'Quantity' | translate }}</th>
                    <th scope="col" width="5%" class="border-start-width-0 border-end-width-0"></th>
                    <th scope="col" width="5%" class="border-start-width-0 "></th>
                    <th scope="col" width="10%" class="border-end-width-0">{{'Unit Cost' | translate }}</th>
                    <th scope="col" width="5%" class="border-start-width-0 border-end-width-0"></th>
                    <th scope="col" width="15%" class="border-end-width-0">{{'Total Cost' | translate }}</th>
                    <th scope="col" width="3%" class="border-start-width-0 border-end-width-0"></th>
                    <th scope="col" width="5%" class="border-radius-topright-10 border-start-width-0 border-end-width-0"> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of arrItems">
                    <td>
                      <img [src]="item.itemImage" *ngIf="(item.itemImage !== '' && item.itemImage !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                      <img *ngIf="(item.itemImage === '' || item.itemImage === null)" src="https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG" class="table-img" alt="" />
                    </td>
                    <td scope="row" class="font-weight-bold">
                      {{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}
                      
                    </td>
                    <td class="text-center">
                      <input type="number" [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}" class="form-control-price"
                           (ngModelChange)="UpdateQty(item)"  [ngClass]="{red : (item.quantity <= 0 || item.quantity > item.currentStock)}">
                      <!--<span class="inv-amountbox">{{item.quantity}}</span>-->
                    </td>
                    <td>
                      <span>{{item.unit | translate }}</span>
                    </td>
                    <td>
                      <a><img src="/assets/images/icons/v2/BlueSetting.svg" alt="image" (click)="open(item)" /></a>
                    </td>
                    <td>
                      <input type="number" [(ngModel)]="item.cost" [ngModelOptions]="{standalone: true}" (ngModelChange)="UpdateQty(item)" class="form-control-price" />
                    </td>
                    <td>
                      <span>{{brandCurrency | translate }}</span>
                    </td>
                    <td>
                      <span>{{item.quantity * item.cost | number : '1.2-2'}}</span>
                    </td>
                    <td>
                      {{brandCurrency | translate }}
                    </td>
                    <td>
                      <a class="float-end"><img src="/assets/images/icons/v2/Delete.svg" (click)="deleteRow(item)" /></a>
                    </td>
                  </tr>
                  <tr *ngIf="arrItems.length == 0">
                    <td colspan="10">
                      <div class="row p-1">
                        <div class="col-12 text-center">
                          <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <table class="table w-50 move-to-end" *ngIf="totalCost || totalCost == 0">
                <thead class="table-header">
                  <tr>
                    <th scope="col" width="40%" class="border-end-width-0 text-start rounded-0 align-middle px-4 fw-bold">{{'Total Cost' | translate }}</th>
                    <th scope="col" width="60%" class="border-start-width-0  rounded-0 align-middle px-4 fw-bold">{{totalCost | number : '1.2-2'}} {{brandCurrency}}</th>
                  </tr>
                </thead>
              </table>

            </div>
            <div class="row p-2" *ngIf="logs !== null && (logs?.createdBy !== '' || logs?.approvedBy !== '' || logs?.receivedBy !== '')">
              <div class="col-lg-6">
                <label>{{'Logs' | translate }} </label>
              </div>
              <div class="row py-3" *ngIf="logs?.createdBy !== ''">
                <div class="col-lg-6 col-6">
                  <label>{{'Created By' | translate }} </label>
                </div>
                <div class="col-lg-6 col-6">
                  <span class="float-end">{{logs?.createdOn | date:'shortTime'}} </span>
                </div>
                <div class="col-lg-6 col-6">
                  <span>{{logs?.createdBy }} </span>
                </div>
                <div class="col-lg-6 col-6">
                  <span class="float-end">{{logs?.createdOn | date:'dd/MM/yyyy'}} </span>
                </div>
              </div>
              <div class="row py-3" *ngIf="logs?.approvedBy !== ''">
                <div class="col-lg-6 col-6">
                  <label>{{'Approved By' | translate }} </label>
                </div>
                <div class="col-lg-6 col-6">
                  <span class="float-end">{{logs?.approvedDate | date:'shortTime'}} </span>
                </div>
                <div class="col-lg-6 col-6">
                  <span>{{logs?.approvedBy }} </span>
                </div>
                <div class="col-lg-6 col-6">
                  <span class="float-end">{{logs?.approvedDate | date:'dd/MM/yyyy'}} </span>
                </div>
              </div>
              <div class="row py-3" *ngIf="logs?.receivedBy !== ''">
                <div class="col-lg-6 col-6">
                  <label>{{'Received By' | translate }} </label>
                </div>
                <div class="col-lg-6 col-6">
                  <span class="float-end">{{logs?.receivedDate | date:'shortTime'}} </span>
                </div>
                <div class="col-lg-6 col-6">
                  <span>{{logs?.receivedBy }} </span>
                </div>
                <div class="col-lg-6 col-6">
                  <span class="float-end">{{logs?.receivedDate | date:'dd/MM/yyyy'}} </span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </div>
</form>
