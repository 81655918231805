import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { Taxes } from 'src/app/_models/Tax';
import { TaxService } from 'src/app/_services/tax.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { LocationService } from 'src/app/_services/location.service';
import { AlertService } from 'src/app/_services/alert.service';
import { AdddeviceComponent } from '../../devices/adddevice/adddevice.component';
import { ExcelService } from 'src/app/_services/excel.service';
import { AddtaxsetupComponent } from './addtaxsetup/addtaxsetup.component';
import { ActionConfirmationService } from '../../../_services/action-confirmation.service';
import { ManagetaxesComponent } from './managetaxes/managetaxes.component';
import { TaxReferenceComponent } from '../tax-reference/tax-reference.component';
import { TrackEventsService } from '../../../_services/track-events.service';


@Component({
  selector: 'app-taxsetup',
  templateUrl: './taxsetup.component.html',
  styleUrls: ['./taxsetup.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class TaxsetupComponent implements OnInit {
  data$: Observable<Taxes[]>;
  oldData: Taxes[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  active = "tax";
  submit: boolean;
  excelData = [];
  StatusList = [];



  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TaxService,
    private localStorageService: LocalStorageService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private alertService: AlertService,
    private trackEvents: TrackEventsService,
    private confirmationDialogService: ActionConfirmationService   ) {
    this.selectedBrand = this.localStorageService.getSelectedBrand().BrandID;

    this.loading$ = service.loading$;
    this.submit = false;
    this.getBrandData();
  }

  ngOnInit() {
  }


  getBrandData() {
    this.service.headerFilter = {status:['']};
    this.service.getTaxes(this.selectedBrand);
    this.data$ = this.service.data$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    //Header Dropdown
    this.service.allData$.subscribe(res => {

      this.StatusList = [];

      if (res != null) {
        new Set(res.map(val => val.statusID)).forEach(e => {
          this.StatusList.push(
            {
              name: e,
              isChecked: false
            }
          )
        });


      }
    });
  

  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();
    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }


  open() {
    const modalRef = this.modalService.open(AddtaxsetupComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Create Tax");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Edit(tax) {
    
    const modalRef = this.modalService.open(AddtaxsetupComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    modalRef.componentInstance.SelectedID = tax.taxID;
    this.trackEvents.Log_Screen("Edit Tax");
    modalRef.componentInstance.SelectedName = tax.name;
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }
  selectTemplate() {
    const modalRef = this.modalService.open(TaxReferenceComponent, { size: 'lg', windowClass: 'small-modal' });
    modalRef.result.then((result) => {
    }, (reason) => {
      this.getBrandData();
    });
  }

  Delete(id, rowVersion) {
    this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Tax has been Deleted");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
 
  Disable(id, rowVersion){
    this.service.disable(parseInt(id), rowVersion).subscribe((res: any) => {
      this.alertService.success("Tax has been Disabled");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  SetStatus(id, statusID, rowVersion) {
    this.service.SetStatus(parseInt(id), parseInt(statusID),rowVersion).subscribe((res: any) => {
      if (parseInt(statusID) === 1)
        this.alertService.success("Tax has been Actived");
      else
        this.alertService.success("Tax has been Deactived");
      this.getBrandData();
    }, error => {
      this.alertService.error(error);
    });
  }
  
  public bulkSelection($event) {
    this.service.allData$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

  private getExportData() {
    let selected: Taxes[];
    let returnList = [];
    selected = [];
    this.service.taxes.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });
    selected = selected.length == 0 ? this.service.taxes : selected;
    selected = selected.filter(function (tax) {
      var data =
      {
        TaxID: tax.taxID,
        Name: tax.name,
        ArabicName: tax.arabicName,
        Percentage: tax.percentage,
        ApplicableOn: tax.applicableOn,
        Status: tax.statusID == 1 ? 'Active' :'Deactive'
      };
      returnList.push(data);
      return true;
    });
    return returnList;
  }

  export(type): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-taxes', type);
  }
  public openConfirmationDialog(id, rowVersion, name) {
    this.confirmationDialogService.confirm('Please confirm..', name)
      .then((confirmed) => {
        if (confirmed) {
          this.service.delete(parseInt(id), rowVersion).subscribe((res: any) => {
            this.alertService.success("Tax has been Deleted");
            this.getBrandData();
          }, error => {
            this.alertService.error(error);
          });
        }
        console.log('User confirmed:', confirmed)
      })
      .catch(() => console.log('User dismissed the dialog.'));
  }
  SelectedStatus = '';

  filters() {
    let headerFilter = {
      status: (this.SelectedStatus == 'All Status') ? this.StatusList.map(function (k) { return k.name }) : [this.SelectedStatus]

    }

    this.service.headerFilter = headerFilter;


  }

  public selectAllFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event.target.checked);
    this.filters();
  }


  openManageTaxes() {
    const modalRef = this.modalService.open(ManagetaxesComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Manage Taxes");
    modalRef.componentInstance.SelectedID = 0;
    modalRef.componentInstance.SelectedName = '';
    modalRef.result.then((result) => {
    }, (reason) => {
     // this.getBrandData();
    });
  }
}








