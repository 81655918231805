
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { MarketplaceService } from 'src/app/_services/marketplace.service';
import { AccountingIntegration } from 'src/app/_models/marketplace';
import { IntegrationService } from '../../../_services/Integration.service';

import { UninstallModalConfirmationComponent } from '../uninstall-modal-confirmation/uninstall-modal-confirmation.component';
import { paymentLinkService } from '../../../_services/paymentLink.service';


@Component({
  selector: 'app-moyasar',
  templateUrl: './moyasar.component.html',
  styleUrls: ['./moyasar.component.css']
})
export class MoyasarComponent implements OnInit {
  @Input() PartnerID = 0;



  ButtonText = "Install";
  submitted = false;
  moyasarForm: UntypedFormGroup;
  private selectedBrand;
  loading = false;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private services: paymentLinkService,
    private integrationServices: IntegrationService,
    private _modalService:NgbModal

  ) {
    this.createForm();

  }

  ngOnInit() {
    this.setSelectedPartner();
  }




  setSelectedPartner() {

    if (this.PartnerID !== 0) {
      this.loading = true;
      this.services.getPaymentLink(this.f.brandID.value, this.PartnerID).subscribe((res: any[]) => {
        //Set Forms
        console.log(res);
        if (res !== null) {
          this.editForm(res);
          this.ButtonText = "Save";
        }
        this.loading = false;
      });
    }
  }



  private createForm() {

    this.moyasarForm = this.formBuilder.group({
      url: ['https://api.moyasar.com/v1/', Validators.required],
      user: [''],
      key: ['', Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      paymentIntegrationID: 0,
      integrationStatus:1,
      partnerID: this.PartnerID,
      authToken:'',
      payLink:'Moyasar',
      integrationID :0

    });
  }



  private editForm(value) {
    console.log(value);
    debugger
    this.f.url.setValue(value.url);
    this.f.user.setValue(value.user);
    this.f.key.setValue(value.key);
    this.f.paymentIntegrationID.setValue(value.paymentIntegrationID);
    this.f.integrationStatus.setValue(value.integrationStatus);
    this.f.integrationID.setValue(value.integrationID);

  }



  get f() { return this.moyasarForm.controls; }

  ngAfterViewInit() {
    // document.getElementById("terminalName").focus();
  }


  onSubmit() {
    debugger
    this.moyasarForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    //this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.partnerID.setValue(this.PartnerID);
    console.log(this.moyasarForm.value);
    if (this.moyasarForm.invalid) { return; }
    this.loading = true;


    if (parseInt(this.f.paymentIntegrationID.value) === 0) {

      //   //Insert
      console.log(this.moyasarForm.value);
      this.services.insert(this.moyasarForm.value).subscribe(data => {
        console.log(data);
        this.alertService.success("MOYASAR has installed successfully");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });


    } else {
      //   //Update
      this.services.update(this.moyasarForm.value).subscribe(data => {
        this.alertService.success("MOYASAR Account has saved successfully");
        this.loading = false;
        this.PartnerID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }


  close() {
    this.activeModal.dismiss();
  }

  uninstall() {
    this.loading = true;
    this.services.Uninstall(parseInt(this.f.integrationID.value)).subscribe((res: any) => {
      console.log(res);
      this.alertService.success("Moyasar  has uninstalled successfully");
      this.loading = false;
      this.activeModal.dismiss();
    }, error => {
      this.alertService.error(error);
    });
  }


  openConformation() {
    const modalRef = this._modalService.open(UninstallModalConfirmationComponent, { centered: true,windowClass: 'uninstall-modal',modalDialogClass: 'uninstall-modal-custom' });
    modalRef.result.then((result) => {
      if (result) {
        this.uninstall();
        }
      });
  }
}
