
<main class="container order-list">
  <div class="row flex-row-reverse mt-2 mb-4">

    <div class="col-md-auto">
      <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
    </div>
  </div>



  <section class="tile" [ngClass]="{'lazy': (loading$ | async)}">
    <div class="tile-body p-0 table-responsive hrzScroll text-nowrap">
      <table class="table table-striped">
        <thead>
          <tr class="table-header">
            <th width="10%" sortable="reportName" (sort)="onSort($event)">{{'Report ID' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <!--<th width="20%" sortable="reportName" class="td-sorting-icon" (sort)="onSort($event)">{{'Report Types' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>-->

            <th width="15%">

              <!-- Report Types Filter -->
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                <button class="btn btn-outline-filter" id="ddlReportTypeFilter" ngbDropdownToggle [ngClass]="{ 'active': setSelectedResult('reportTypes') > 0}">
                  <span>
                    <span class="badge" *ngIf="setSelectedResult('reportTypes')>0">{{setSelectedResult('reportTypes')}}</span> {{'Report Types' | translate}}
                  </span>
                  <img class="down-aero" ngbDropdownOpen src="{{  (setSelectedResult('reportTypes') > 0)?'assets/images/down-aero-white.svg':'assets/images/down-aero.svg' }}">
                </button>
                <div ngbDropdownMenu aria-labelledby="ddlReportTypeFilter dropdown-header-filter-body" class="dropdown-header-filter-body">
                  <!--<div>
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownReportType_all" (change)="selectAllFilters($event,ReportTypeList)">
                      <label class="form-check-label font-blue" for="dropdownReportType_all" (click)="selectAllFilters($event,ReportTypeList)">
                        {{'All Reports' | translate}}
                      </label>
                    </div>
                  </div>-->
                  <div *ngFor="let data of ReportTypeList; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="checkbox" class="form-check-input" id="dropdownReportType_{{i+1}}" [(ngModel)]="data.isChecked" (ngModelChange)="filters()">
                      <label class="form-check-label" for="dropdownReportType_{{i+1}}" (click)="filters()">
                        {{data.name | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>




            <th width="25%" sortable="dateRange" (sort)="onSort($event)">{{'Date Range' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /> </th>
            <th width="20%" sortable="locations" (sort)="onSort($event)">{{'Locations' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th width="12%">

              <!-- GeneratedBy Filter -->
              <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="(data$ | async)?.length <=5 ? 'body':'table'">
                <button class="btn btn-outline-filter" id="ddlGeneratedByFilter" ngbDropdownToggle>
                  <span>   {{SelectedGeneratedBy | translate}}  </span>
                  <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                </button>
                <div ngbDropdownMenu aria-labelledby="ddlGeneratedByFilter dropdown-header-filter-body ddl-width-sm" class="dropdown-header-filter-body ddl-width-sm">
                  <!--<div>
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" [(ngModel)]="SelectedGeneratedBy" value="All Generated Users" name="generatedUserOption" id="dropdownGeneratedBy_all" (change)="selectAllFilters($event,GeneratedUserList)">
                      <label class="form-check-label font-blue" for="dropdownGeneratedBy_all" (click)="selectAllFilters($event,GeneratedUserList)">
                        {{'All Generated Users' | translate}}
                      </label>
                    </div>
                  </div>-->
                  <div *ngFor="let data of GeneratedUserList; let i = index">
                    <div class="form-check" ngbDropdownToggle>
                      <input type="radio" class="form-check-input" name="generatedUserOption" [value]="data.name" id="dropdownGeneratedBy_{{i+1}}" [(ngModel)]="SelectedGeneratedBy" (change)="filters()">
                      <label class="form-check-label" for="dropdownGeneratedBy_{{i+1}}" (click)="filters()">
                        {{data.name}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </th>

            <th width="13%" sortable="generatedOn" (sort)="onSort($event)">{{'Generated On' | translate }} <img class="d-inline" src="assets/images/icons/sorting-icon.png" /></th>
            <th width="5%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of data$ | async " editRow>
            <td (click)="report.statusID === 1 && Preview(report)" class="name-blue">{{report.generatedReportID}}</td>
            <td (click)="report.statusID === 1 && Preview(report)">
              <img class="m-0 d-inline" src="assets/images/icons/v2/Progress.gif" *ngIf="report.statusID === 17" />
              <img class="m-0 d-inline" src="assets/images/icons/v2/No_Info.svg" *ngIf="report.statusID === 18" ngbTooltip="No Data" />
              <img class="m-0 d-inline" src="assets/images/icons/v2/running_with_errors.svg" *ngIf="report.statusID === 19" ngbTooltip="Error" />
              
              {{report.reportName | translate}}
            </td>
            <td (click)="report.statusID === 1 && Preview(report)"> <div class="tbl-daterange-ex">{{report.dateRange}} </div></td>
            <td (click)="report.statusID === 1 && Preview(report)"> {{settLocationData(report.locations)}} </td>
            <td (click)="report.statusID === 1 && Preview(report)"> {{report.generatedBy}} </td>
            <!-- <td> {{report.generatedOn |  date: "dd MMM yyyy hh:mm a"}} </td> -->
            <td (click)="report.statusID === 1 && Preview(report)"> {{getTimeDiff(report.generatedOn).value | translate}}&nbsp;{{getTimeDiff(report.generatedOn).text | translate}} </td>
            <td>
              <div class="doted-list-action" [placement]="['left', 'right']" [ngbPopover]="popContent">
                <img class="dots-list rotate-90" src="/assets/images/dots-list.svg" />
              </div>
              <ng-template #popContent>
                <p class="dropdown-heading-mini">{{'REPORT' | translate }}</p>
                <ng-container *ngIf="report.statusID === 1">
                  <!--<a *hasPermission="['Analytics','Reports','Generated Reports', 'Print']" (click)="Print(report)">{{'Print Report' | translate }}</a>-->
                  <a *hasPermission="['Analytics','Reports','Generated Reports', 'Preview']" (click)="Preview(report)">{{'Preview Report' | translate }}</a>
                  <a *hasPermission="['Analytics','Reports','Generated Reports', 'Export']" (click)="export('csv',report)">{{'Export CSV' | translate }}</a>
                  <a *hasPermission="['Analytics','Reports','Generated Reports', 'Export']" (click)="export('xlsx',report)">{{'Export Excel' | translate }}</a>
                  <a *ngIf="showPDFOption(report)" (click)="generateDetailTaxPDF(report)">{{'Export pdf' | translate }}</a>
                  <!--<a *hasPermission="['Analytics','Reports','Generated Reports', 'Delete']" class="selected-inner-text color-danger"
                     (click)="openConfirmationDialog(report.generatedReportID, report.rowVersion,report.reportName)">
                    {{'Delete Report' | translate }}
                    <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                  </a>-->
                </ng-container>
                <ng-container *ngIf="report.statusID === 1 || report.statusID === 18 || report.statusID === 19">
                  <a *hasPermission="['Analytics','Reports','Generated Reports', 'Delete']" class="selected-inner-text color-danger"
                     (click)="openConfirmationDialog(report.generatedReportID, report.rowVersion,report.reportName)">
                    {{'Delete Report' | translate }}
                    <img class="dots-list" src="assets/images/icons/v2/trash.svg" />
                  </a>
                </ng-container>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <div class="row mt-10">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <p class="pagination-count">
        {{'Showing' | translate }}
        <strong>{{(service.pageSize * service.page)-9}}</strong> {{'to' | translate }}
        <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>{{'of' | translate }}
        <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
      </p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
        <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
        <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
        <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
</main>



