<form [formGroup]="receivepreviewpoForm" class="form">
    <div class="modal-header">

        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'Purcahse Order' | translate }} {{f.poCode.value}} </h4>

        <button [disabled]="loading" class="btn btn-save m-0" (click)="onSubmit(f)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
          {{ButtonText | translate}}
        </button>


    </div>
    <div class="modal-body pr-0 pl-0">
        <nav class="navbar sticky-top navbar-light bg-white  sticky-header-preview p-3">
            <div class="row w-100">
                <div class="col-lg-6 d-flex">
                  <button type="button" class=" btn btn-secondary m-1">{{'Purchase Order' | translate }}</button>
                    <!-- <button type="button" class="btn btn-light m-1">Purchase History</button>
                    <button type="button" class="btn btn-light m-1">Receives</button> -->
                </div>
                <div class="col-lg-6 text-end">
                    <div class=" d-inline-flex">
                        <div placement="bottom" [ngbPopover]="popContent">
                            <button class="btn sticky-header-preview-drpbtn m-0 me-2"><b>{{'Recieve' | translate }}</b></button>
                        </div>
                        <ng-template #popContent>
                          <p class="dropdown-heading-mini">{{'PURCHASE ORDER' | translate }}</p>
                          <a (click)="selectedStatus('receive')">{{'Marked as Received' | translate }}</a>
                          <a (click)="CreateReceive(f)">{{'Create Receive' | translate }}</a>
                        </ng-template>

                        <!-- <div placement="bottom" [ngbPopover]="popContent1">
                            <button class="btn sticky-header-preview-drpbtn  m-0 me-2"><b> Export</b></button>
                        </div> -->
                        <ng-template #popContent1>
                            <!-- <p class="dropdown-heading-mini">PURCHASE ORDER</p>
                            <a (click)="Edit(purchaseorder)">Approve Purchase</a>
                            <a (click)="Edit(purchaseorder)">Decline Purchase</a>
                            <a (click)="Edit(purchaseorder)">Hold Purchase</a>
                            <a class="selected-inner-bottom"><span class=" po-preview-action-text"> Edit Purchase  </span></a> -->

                        </ng-template>
                        <!-- <div placement="bottom" [ngbPopover]="popContent2">
                            <button class="btn sticky-header-preview-drpbtn m-0 me-2"><b> More</b></button>
                        </div>
                        <ng-template #popContent2>
                            <p class="dropdown-heading-mini">PURCHASE ORDER</p>
                            <a (click)="Edit(purchaseorder)">Approve Purchase</a>
                            <a (click)="Edit(purchaseorder)">Decline Purchase</a>
                            <a (click)="Edit(purchaseorder)">Hold Purchase</a>
                            <a class="selected-inner-bottom"><span class=" po-preview-action-text"> Edit Purchase  </span></a>
                        </ng-template> -->
                    </div>
                </div>
            </div>
        </nav>

        <div class="container">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-1"></div>
            <div class="col-lg-8 mt-5">
              <div class="jumbotron po-preview-box">
                <div class="row form-group">
                  <div class="col-lg-6 text-start">
                     <!-- <img src="/assets/images/marn-logo-po.png" alt="" /> -->
                     <img class="brandLogo" [src]="ls.getSelectedBrand().BrandLogo" alt="" />
                  </div>
                  <div class="col-lg-6 text-end">
                    <h3 class="font-weight-bold ">{{'Purchase Order' | translate }}</h3>
                    <h5 class=" ponum-heading ">{{f.poCode.value}}</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 text-start">
                    <div [ngClass]="renderStatusClass(f.statusID.value)" class="btn btn-sm">
                      {{ (f.statusID.value ==600?'Created': f.statusID.value ==601?'Approved': f.statusID.value ==602?'Pending': f.statusID.value ==603?'Declined': f.statusID.value ==604?'Hold': f.statusID.value ==605?'Received': f.statusID.value ==605?'Returned': '-') | translate}}
                    </div>
                  </div>
                  <div class="col-lg-6 text-end">
                    <h6 class="font-weight-bold ">{{'Ref' | translate }}# {{f.referenceNo.value}}</h6>
                  </div>
                </div>
                <div class="my-4 divider"></div>
                <div class="row mb-5">
                  <div class="col-lg-6 text-start">
                    <h6 class="font-weight-bold ">{{'Date' | translate }}</h6>
                    <label>{{f.date.value |date}}</label>
                  </div>
                  <div class="col-lg-6 text-end">
                    <h6 class="font-weight-bold ">{{'Expected Date' | translate }}</h6>
                    <label>{{f.expectedDate.value | date}}</label>
                  </div>
                </div>

                <div class="row  mb-5">
                  <div class="col-lg-6 text-start">
                    <h6 class="font-weight-bold po-preview-blue ">{{'Supplier Address' | translate }}</h6>
                    <h6 class="font-weight-bold ">{{f.supplierName.value}}</h6>
                    <p>{{f.supplierAddress.value}}</p>
                    <label>{{f.supplierContact.value}}</label>
                  </div>
                  <div class="col-lg-6 text-end">
                    <h6 class="font-weight-bold po-preview-blue ">{{'Deliver to' | translate }}</h6>
                    <h6 class="font-weight-bold ">{{f.locationName.value}}</h6>
                    <p>{{f.locationAddress.value}}</p>
                    <label>{{f.locationContact.value}}</label>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead class="table-dark">
                    <tr>
                      <th width="45%" scope="col">{{'Item Name' | translate }}</th>
                      <th width="15%" scope="col">{{'Quantity' | translate }}</th>
                      <th width="20%" scope="col">{{'Unit Price' | translate }}</th>
                      <th width="20%" scope="col">{{'Total' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of arrItems">
                      <td scope="row">{{(selectedLang == 'en' ? item.name : ((item.alternateName !== '')? item.alternateName:item.name))}}</td>
                      <td>{{item.quantity}}</td>
                      <td>{{item.cost | number : '1.2-2'}} {{brandCurrency | translate }}</td>
                      <td class="font-weight-bold text-end">{{item.quantity * item.cost | number : '1.2-2'}} {{brandCurrency | translate }}</td>
                    </tr>

                    <tr class="po-summary">
                      <td colspan="2" scope="row">
                        <h6 class="font-weight-light" *ngIf="f.notes.value!=''">{{'Notes' | translate }}:</h6>
                        <p>{{f.notes.value}}</p>
                      </td>

                      <td colspan="2">
                        <br />
                        <div class="row form-group">
                          <div class="col-lg-6 text-start">
                            <h6 class="font-weight-bold ">{{'Sub Total' | translate }}</h6>
                          </div>
                          <div class="col-lg-6 text-end">
                            <h6 class="font-weight-bold ">{{f.subTotal.value | number : '1.2-2'}} {{brandCurrency | translate }}</h6>
                          </div>
                        </div>
                        <div class="row form-group">
                          <div class="col-lg-6 text-start">
                            <h6 class="font-weight-bold ">{{'Tax' | translate }} 15%</h6>
                          </div>
                          <div class="col-lg-6 text-end">
                            <h6 class="font-weight-bold ">{{f.taxAmount.value | number : '1.2-2'}} {{brandCurrency | translate }}</h6>
                          </div>
                        </div>
                        <div class="row form-group po-summary-total">
                          <div class="col-lg-6 text-start">
                            <h6 class="font-weight-bold ">{{'Total Amount' | translate }}</h6>
                          </div>
                          <div class="col-lg-6 text-end">
                            <h6 class="font-weight-bold ">{{f.grandTotal.value | number : '1.2-2'}} {{brandCurrency | translate }}</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
        </div>
    </div>

</form>
