import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-zatca-modal',
  templateUrl: './confirmation-zatca-modal.component.html',
  styleUrl: './confirmation-zatca-modal.component.css'
})
export class ConfirmationZatcaModalComponent {
  constructor(public activeModal: NgbActiveModal) {}



cancel(){
  this.activeModal.dismiss();
}

accept(){
  this.activeModal.close(true);
}

  ngOnInit(): void {
  }
}



