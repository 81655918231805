import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { UnitService } from 'src/app/_services/unit.service';
import { NgbdDatepickerAdapter } from 'src/app/datepicker/datepicker-adapter';
import { tsDetails } from 'src/app/_models/TransferInventory';
import { TransferInventoryService } from 'src/app/_services/transfer-inventory.service';

@Component({
  selector: 'app-addtransfer',
  templateUrl: './addtransfer.component.html',
  styleUrls: ['./addtransfer.component.css']
})
export class AddtransferComponent implements OnInit {
  @Input() SelectedID = 0;
  submitted = false;
  transferForm: UntypedFormGroup;
  loading = false;
  loadingAddtransfer = false;
  ButtonText = "Save";
  Locations = [];
  Suppliers = [];
  Items = [];
  arrItems: tsDetails[] = [];
  @ViewChild(NgbdDatepickerAdapter, { static: true }) dateComp;
  public tsDetails = new tsDetails();
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private ls: LocalStorageService,
    private alertService: AlertService,
    private unitService: UnitService,
    private transferService: TransferInventoryService

  ) {
    this.createForm();
  }

  ngOnInit() {

    this.setSelectedAddtransfer();
    this.loadLocations();
    this.refreshDetailObj();

    if (this.f.tsid.value == 0)
      this.generateTSCode();
  }

  get f() { return this.transferForm.controls; }

  private createForm() {

    this.transferForm = this.formBuilder.group({
      tsCode: [''],
      referenceNo: [''],
      notes: [''],
      statusID: [600, Validators.required],
      brandID: parseInt(this.ls.getSelectedBrand().BrandID),
      tsid: 0,
      fromLocationID: 0,
      toLocationID: 0,
      expectedDate: [],
      date: [],
      rowVersion: 0,
      tsDetails: []
    });
  }

  private editForm(transfer) {

    this.f.tsCode.setValue(transfer.tsCode);
    this.f.date.setValue(transfer.date);
    this.dateComp.setDate(transfer.expectedDate);
    this.f.expectedDate.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day));

    this.f.referenceNo.setValue(transfer.referenceNo);

    this.f.tsDetails.setValue(transfer.tsDetails);
    this.arrItems = transfer.tsDetails;

    this.f.notes.setValue(transfer.notes);
    this.f.rowVersion.setValue(transfer.rowVersion);
    this.f.statusID.setValue(transfer.statusID);
    this.f.tsid.setValue(transfer.tsid);
    
    this.f.fromLocationID.setValue(transfer.fromLocationID);
    this.f.toLocationID.setValue(transfer.toLocationID);
  }

  setSelectedAddtransfer() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Update";
      this.loadingAddtransfer = true;
      this.f.tsid.setValue(this.SelectedID);
      this.transferService.getById(this.SelectedID, this.f.brandID.value).subscribe(res => {
        //Set Forms
        this.editForm(res);
        this.loadingAddtransfer = false;
      });
    }
  }

  onSubmit() {

    this.transferForm.markAllAsTouched();
    this.submitted = true;
    // reset alerts on submits
    this.alertService.clear();

    if (this.arrItems.length == 0) {
      this.alertService.error("Items cannot be empty.");
      return;
    }
    if (this.f.fromLocationID.value === this.f.toLocationID.value) {
      this.alertService.success("From and To location can not be same");
      return;
    }

    if (this.transferForm.invalid) { return; }
    this.loading = true;
    
    this.f.statusID.setValue(this.f.statusID.value);
    this.f.expectedDate.setValue(new Date(this.dateComp.dateModel.year + "/" + this.dateComp.dateModel.month + "/" + this.dateComp.dateModel.day + ", " + new Date().toTimeString()));
    this.f.date.setValue(new Date());
    this.f.tsDetails.setValue(this.arrItems);
    if (parseInt(this.f.tsid.value) === 0) {

      //Insert transfer
      this.transferService.insert(this.transferForm.value).subscribe(data => {
        this.alertService.success("Addtransfer has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update transfer
      this.transferService.update(this.transferForm.value).subscribe(data => {
        this.alertService.success("Addtransfer has been updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }

  loadLocations() {
    this.transferService.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
      if (this.Locations.length > 0) {

        if (this.f.tsid.value == 0) {
          this.f.fromLocationID.setValue(res[0].locationID);
          this.f.toLocationID.setValue(res[0].locationID);
        }

        this.loadItems(res[0].locationID);
      }
    });
  }

  loadItems(locationID) {
    this.transferService.getItems(locationID).subscribe((res: any) => {
      this.Items = res;
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  selectedInvItem(obj) {

    this.tsDetails.type = obj.type;
    this.tsDetails.name = obj.name;
    this.tsDetails.cost = obj.cost;
    this.tsDetails.unit = obj.unit;
    this.tsDetails.unitID = obj.unitID;
    this.tsDetails.currentStock = obj.currentStock;
    // var ele = this.Units.find(element => element.unit == obj.unit);
    // if (ele) {
    //   this.tsDetails.conversionUnitID = ele.unitID;
    //   this.tsDetails.conversionUnit = ele.unit;
    //   this.tsDetails.unitID = ele.unitID;
    // }
  }

  deleteRow(obj) {

    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);
  }

  addRow(obj) {
    if (obj.name != undefined && obj.name != '' && obj.name != null && obj.quantity > 0) {
      if (!this.arrItems.find(element => element.supplyID == obj.supplyID)) {
        this.arrItems.push({
          supplyID: obj.supplyID,
          cost: obj.cost,
          totalCost: obj.quantity * obj.cost,
          name: obj.name,
          alternateName: obj.alternateName,
          type: obj.type,
          statusID: 600,
          quantity: obj.quantity == null ? 1 : obj.quantity,
          unitID: obj.unitID,
          unit: obj.unit,
          approvedQty: 0,
          receivedQty: 0,
          itemImage: null,
          currentStock: obj.currentStock
        });
      }
      else {
        alert("Item already added in list")
      }
    }
    else {
      if (obj.name == undefined || obj.name == '' || obj.name == null) {
        this.alertService.error("Please select item.");
        return;
      }
      else if  (obj.quantity <= 0) {
        this.alertService.error("Item quantity must be greater than 0.");
        return;
      }
    }
  }




  UpdateQty(newItem) {
    let updateItem = this.arrItems.find(this.findIndexToUpdate, newItem.Cost);
    let index = this.arrItems.indexOf(updateItem);
    this.arrItems[index] = newItem;

  }

  findIndexToUpdate(newItem) {
    return newItem.cost === this;
  }

  generateTSCode() {
    this.transferService.generateTSCode(this.f.brandID.value).subscribe((res: any) => {
      this.f.tsCode.setValue(res.code);
    });
  }

  refreshDetailObj() {
    this.tsDetails.cost = 0;
    this.tsDetails.quantity = 1;
  }
  SearchItem(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.barcode.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.sku.toLocaleLowerCase().indexOf(term) > -1;
  }
}
