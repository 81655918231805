

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { GroupService } from 'src/app/_services/group.service';


@Component({
  selector: 'app-addgroup',
  templateUrl: './addgroup.component.html',
  styleUrls: ['./addgroup.component.css']
})
export class AddgroupComponent implements OnInit {
  @Input() name;
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  groupForm: UntypedFormGroup;
  loading = false;
  loadingFloor = false;
  ButtonText = "Save";
  Title = "Inventory Group";
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private service: GroupService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedGroup();
  }

  ngAfterViewInit() {

  }


  private createForm() {
    this.groupForm = this.formBuilder.group({
      name: ['', Validators.required],
      statusID: [0, Validators.required],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      groupID: 0,
      rowVersion: 0
    });
  }


  get f() { return this.groupForm.controls; }

  setSelectedGroup() {
    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loadingFloor = true;
      this.f.groupID.setValue(this.SelectedID);
      this.service.getById(this.SelectedID, this.f.brandID.value ).subscribe(res => {

        this.Title = "Edit Inventory Group";
        //Set Forms
        this.editForm(res);
        this.loadingFloor = false;


      });
    }
  }


  private editForm(group) {
    this.f.groupID.setValue(group.groupID);
    this.f.name.setValue(group.name);
    this.f.statusID.setValue(group.statusID === 1 ? true : false);
    this.f.rowVersion.setValue(group.rowVersion);
    //this.f.brandID.setValue(group.brandID);
  }


  close() {
    this.activeModal.dismiss();
  }



  onSubmit() {
    this.groupForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();

    this.f.name.setValue(this.f.name.value);
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);


    if (this.groupForm.invalid) { return; }
    this.loading = true;

    if (parseInt(this.f.groupID.value) === 0) {

      //Insert Location
      console.log(this.groupForm.value);
      this.service.insert(this.groupForm.value).subscribe(data => {
        this.alertService.success("Group has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });

    } else {
      //Update Location
      this.service.update(this.groupForm.value).subscribe(data => {
        this.alertService.success("Group has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
        this.activeModal.dismiss();
      });
    }
  }



}
