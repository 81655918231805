<div class="modal-header ">
  <h4 class="modal-title-lg">
    {{ Item.locationName}} > {{ (selectedLang == 'en')?Item.name:Item.nameOnReceipt}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row form">
    <label class="d-flex justify-content-center">{{'Customize item availability and price per partner' | translate}}</label>
    <!--<div *ngIf="(service.items?.length > 0) && ((data$ | async)?.length > 0)">-->
      <div class="position-relative">
        <section class="tile">
          <!--[ngClass]="{'lazy': (loading$ | async)}"-->
          <div class="tile-body p-0 table-responsive">
            <table class="table table-striped item text-start">
              <thead>
                <tr class="table-header">
                  <th width="10%">{{'Partner' | translate }}</th>
                  <th width="30%"></th>
                  <th width="25%">{{'Price' | translate }}</th>
                  <th width="30%">{{'Availabilty' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let itemPartner of data">
                  <td>
                    <img [src]="itemPartner.logo" class="d-inline" width="40" height="40" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                  </td>
                  <td>
                    {{itemPartner.partnerName}}
                  </td>
                  
                  <td>
                    <div class="d-flex justify-content-center">
                      <div>
                        <input type="number" pattern="/^\d*\.?\d{0,2}$/g" class="form-control-price float-start" [ngClass]="{red : (itemPartner.tempPrice && itemPartner.tempPrice <= 0)}"
                               placeholder="{{(Item.price)?Item.price:Item.originalPrice}}" [(ngModel)]="itemPartner.tempPrice" />
                        <span class="px-1">
                          <img class="d-inline my-2 " role="button" src="assets/images/icons/v2/locationItemPriceIcon.svg" (click)="itemPartner.tempPrice=null"/>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <div class="form-check form-switch float-start">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="itemPartner.status">
                        <!--<label class="form-check-label" for="statusID"></label>-->
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  <!--</div>-->
</div>
<div class="modal-footer">
  <button [disabled]="loading" style="float: right; " class="btn btn-primary cutom-button custom-create-btn ms-2" (click)="SaveItem()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>   {{'Save' | translate}}
  </button>
</div>
