<section class="tile">
  <div class="tile-header">
    <h6>Today's Summary</h6>
  </div>
  <div class="tile-body" #chart>
    <apx-chart [series]="todaySummaryLineOptions.series"
               [chart]="todaySummaryLineOptions.chart"
               [markers]="todaySummaryLineOptions.markers"
               [colors]="todaySummaryLineOptions.colors"
               [dataLabels]="todaySummaryLineOptions.dataLabels"
               [stroke]="todaySummaryLineOptions.stroke"
               [title]="todaySummaryLineOptions.title"
               [grid]="todaySummaryLineOptions.grid"
               [xaxis]="todaySummaryLineOptions.xaxis"
               [yaxis]="todaySummaryLineOptions.yaxis"></apx-chart>
  </div>
</section>
