import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbdSortableHeader } from '../../_directives/sortable.directive';
import { AlertService } from '../../_services/alert.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { PrintService } from '../../_services/print.service';
import { ProductMixService } from '../../_services/product-mix.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-print-product-mix',
  templateUrl: './print-product-mix.component.html',
  styleUrls: ['./print-product-mix.component.css']
})
export class PrintProductMixComponent implements OnInit {

  loading$: Observable<boolean>;
  selectedReport: any;
  selectedBrand: any;
  report: any;
  inventoryPurchase: string = '';
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: ProductMixService,
    private router: Router,
    private printService: PrintService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService) {
    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
  }


  ngOnInit(): void {
    if (this.selectedReport !== null && this.selectedReport !== undefined) {
      this.getReportData(this.selectedReport.dataURL);
      this.selectedBrand = this.localStorageService.getSelectedBrand();
      if (!this.selectedReport?.filters.split('|')[2] === false) {
        this.inventoryPurchase = this.selectedReport?.filters.split('|')[2].trim();
      }
    }
  }

  getReportData(dataURL) {

    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataURL,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {
          _this.report = res;
          _this.printService.onDataReady();

        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })



  }

  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }
}
